import { styled, css } from 'styles';
import { withTheme } from 'components/core';

const buttonContentWrapper = css`
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
`;

const sizes = {
  xs: {
    height: 19,
    width: 19,
    padding: 16,
  },
  sm: {
    height: 32,
    width: 32,
    padding: 20,
  },
  md: {
    height: 40,
    width: 40,
    padding: 20,
  },
};

const colors = (theme) => ({
  primary: {
    filled: {
      color: theme.colors.white,
      backgroundColor: theme.colors.blue,
    },
    outlined: {
      color: theme.colors.blue,
      border: `1px solid ${theme.colors.blue}`,
      backgroundColor: 'transparent',
    },
    none: {
      color: theme.colors.blue,
      backgroundColor: 'transparent',
    },
  },
  secondary: {
    filled: {
      backgroundColor: theme.colors.white,
    },
  },
  warning: {
    filled: {
      color: theme.colors.white,
      backgroundColor: theme.colors.red,
    },
    outlined: {
      color: theme.colors.red,
      border: `1px solid ${theme.colors.red}`,
      backgroundColor: 'transparent',
    },
    none: {
      color: theme.colors.red,
      backgroundColor: 'transparent',
    },
  },
  dark: {
    filled: {
      color: theme.colors.white,
      backgroundColor: theme.colors.text1,
    },
    outlined: {
      color: theme.colors.text1,
      border: `1px solid ${theme.colors.text1}`,
      backgroundColor: 'transparent',
    },
    none: {
      color: theme.colors.text1,
      backgroundColor: 'transparent',
    },
  },
});

const generateShape = (shape, size, width) => {
  switch (shape) {
    case 'circle':
      return css`
        height: ${sizes[size].height}px;
        width: ${sizes[size].width}px;
        border-radius: 50%;
      `;
    case 'rectangular':
      return css`
        height: ${sizes[size].height}px;
        width: ${width};
        padding: 0 ${sizes[size].padding}px;
        border-radius: 4px;
      `;
    case 'pill':
    default:
      return css`
        height: ${sizes[size].height}px;
        width: ${width};
        padding: 0 ${sizes[size].padding}px;
        border-radius: 20px;
      `;
  }
};

const BaseStyledButton = withTheme(styled('button')`
  appearance: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  vertical-align: middle;

  ${({ flat }) => (flat ? undefined : 'box-shadow: 0 4px 12px 0 rgba(0,0,0,0.23);')}

  ${({ color = 'primary', theme, filling }) => colors(theme)[color][filling]}
  ${({ disabled }) => (disabled ? 'opacity: 0.3;' : undefined)}
  
  ${({ shape, size, width }) => generateShape(shape, size, width)}
`);

export default BaseStyledButton;

export { buttonContentWrapper };
