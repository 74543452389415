import { memo, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { createSelector } from 'reselect';
import moment from 'moment';
import { identify, group } from 'utils/tracking';
import { getCurrentUser, getCurrentUserIntercomUserHash } from 'selectors/getCurrentUser';
import {
  getCurrentVenueId,
  getCurrentVenueCountrySegmentKey,
  getCurrentVenueDetailedMonitoringEnabled,
  getUpdatedSinceByVenue,
  getCurrentVenueCountryId,
} from 'selectors/getCurrentVenue';
import { NOTIFICATION } from 'models';
import { modalClose, modalOpen } from 'actions/modals';
import { getCurrentVenueCountry, getVenueLocale, getCurrentVenue } from 'selectors/getCurrentVenue';
import { getUserVenuesIds, isUserMultiVenue } from 'selectors/getUserMultiVenue';
import { initPushNotifications } from 'utils/native/pushNotifications';
import { API_BASE } from 'actions/data_providers/api';

const DISCONNECTION_BANNER_ID = 'disconnection-banner-id';

const usePushNotifications = ({ goToPage, venueDefaultLocale, auth_token }) => {
  const handleClickNotification = useCallback(
    (data) => {
      if (data.type === NOTIFICATION.APPOINTMENT && data.date) {
        const date = moment(data.date).format('YYYY-MM-DD');
        goToPage(`/agenda/${date}`);
      }
      if (data.type === NOTIFICATION.DEBT && data.customer_id) {
        goToPage(`/customers/${data.customer_id}`);
      }
    },
    [goToPage]
  );

  useEffect(() => {
    initPushNotifications({
      apiEndpoint: API_BASE(),
      session: { auth_token },
      onClickNotification: handleClickNotification,
      venueDefaultLocale,
    });
  }, []);
};

const VenueProvider = (
  /** @type {VenueProviderProps} */
  {
    currentUser,
    venue_id,
    updatedSinceByVenue,
    currentVenueSegmentKey,
    detailed_monitoring_enabled,
    children,
    goToPage,
    dispatchModalOpen,
    dispatchModalClose,
    currentVenueCountryId,
    country,
    venueDefaultLocale,
    venueIds,
    venue,
    isMultiVenue,
    intercom_user_hash,
  }
) => {
  usePushNotifications({ goToPage, venueDefaultLocale, auth_token: currentUser.auth_token });

  useEffect(() => {
    if (currentUser && currentVenueSegmentKey) {
      identify(currentUser, country, venueIds, venue);

      if (isMultiVenue) {
        group(venue, country);
      }
    }
  }, [currentUser, currentVenueSegmentKey, currentVenueCountryId]);

  useEffect(() => {
    window.venue_id = venue_id;
    // set globally detailed_monitoring_enabled
    window.detailed_monitoring_enabled = detailed_monitoring_enabled;
  }, [detailed_monitoring_enabled, venue_id]);

  useEffect(() => {
    window.intercomSettings = {
      ...window.intercomSettings,
      user_id: currentUser.id,
      user_hash: intercom_user_hash,
    };
  }, [currentUser.id, intercom_user_hash]);

  const [disconnected, setDisconnected] = useState(false);

  useEffect(() => {
    const updatedSince = updatedSinceByVenue[venue_id];
    if (moment().diff(updatedSince, 'seconds') > 60) {
      if (!disconnected) {
        dispatchModalOpen({
          id: DISCONNECTION_BANNER_ID,
          config: {
            component: 'DisconnectionBanner',
            contentValues: {
              date_time: moment(updatedSince).format('LT'),
            },
          },
        });
        setDisconnected(true);
      }
    } else {
      if (disconnected) {
        dispatchModalClose({ id: DISCONNECTION_BANNER_ID });
        setDisconnected(false);
      }
    }
  }, [updatedSinceByVenue, venue_id, dispatchModalOpen, dispatchModalClose, disconnected]);

  return children;
};

const mapStateToProps = () =>
  createSelector(
    getCurrentUser,
    getCurrentVenueId,
    getCurrentVenueCountrySegmentKey,
    getCurrentVenueDetailedMonitoringEnabled,
    getUpdatedSinceByVenue,
    getCurrentVenueCountryId,
    getCurrentVenueCountry,
    getVenueLocale,
    getUserVenuesIds,
    getCurrentVenue,
    isUserMultiVenue,
    getCurrentUserIntercomUserHash,
    (
      currentUser,
      venue_id,
      currentVenueSegmentKey,
      detailed_monitoring_enabled,
      updatedSinceByVenue,
      currentVenueCountryId,
      venueCountry,
      venueLocale,
      venueIds,
      venue,
      isMultiVenue,
      intercom_user_hash
    ) => {
      return {
        currentUser,
        venue_id,
        currentVenueSegmentKey,
        detailed_monitoring_enabled,
        updatedSinceByVenue,
        currentVenueCountryId,
        country: venueCountry.code,
        venueDefaultLocale: venueLocale ?? venueCountry.default_locale,
        venueIds,
        venue,
        isMultiVenue,
        intercom_user_hash,
      };
    }
  );
/**
 * @typedef {ReturnType<ReturnType<mapStateToProps>>} VenueProviderProps
 */

const mapDispatchToProps = {
  goToPage: replace,
  dispatchModalOpen: modalOpen,
  dispatchModalClose: modalClose,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(VenueProvider));
