import { css, styled, treatwellProTheme } from '../theme';
import { Text } from 'components/core';

const {
  colors,
  typography: { fontFamilies, fontSizes },
} = treatwellProTheme;

const Input = styled('input')(
  () => css`
    transition: all 0.2s ease;
    position: relative;
    margin: 0;
    border: 1px solid ${colors['navy.400']};
    outline-offset: 2px;
    outline: none;
    appearance: none;
    border-radius: var(--_base-spacer);
    height: calc(var(--_base-spacer) * 6);
    width: 100%;
    padding: 12px calc(var(--_base-spacer) * 2);
    font-size: ${fontSizes.body_md}px;
    font-family: ${fontFamilies.default};
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 0.2s;

    &:read-only {
      box-shadow: none !important;
      user-select: text;
    }

    &[aria-invalid='true'] {
      border-color: ${colors['coral.600']};
      box-shadow: 0 2px 10px 0 ${colors['navy.300']};
    }

    &:focus:not(:read-only) {
      border-color: ${colors['blue.600']};
      box-shadow: 0 2px 10px 0 ${colors['navy.300']};
    }

    &:disabled {
      background-color: ${colors['navy.100']};
      border-color: ${colors['navy.100']};
      opacity: 1;
    }

    &:disabled ~ span {
      background-color: transparent;
    }

    .left-icon ~ & {
      padding-left: 40px;
    }

    .right-icon ~ & {
      padding-right: 40px;
    }

    &[type='number'] {
      appearance: textfield;
    }

    /* //hide placeholder */
    ::-webkit-input-placeholder {
      /* WebKit browsers */
      color: transparent;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: transparent;
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: transparent;
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: transparent;
    }
  `
);

const handleFloatingLabel = (value: boolean, hasLeftIcon?: boolean): string => {
  switch (true) {
    // with icon
    case value && hasLeftIcon:
      return 'translate(-10%,-50%) scale(0.75)';
    case !value && hasLeftIcon:
      return 'translate(25px,50%)';

    // without icon
    case value && !hasLeftIcon:
      return 'translate(-10%,-50%) scale(0.75)';
    case !value && !hasLeftIcon:
      return 'translate(1px,50%)';

    default:
      return 'inherit';
  }
};

const Label = styled(Text)<{
  readonly value?: string | number;
  readonly hasLeftIcon?: boolean;
}>(
  ({ value, hasLeftIcon }) => css`
    display: inline-block;
    border: none;
    background-color: ${colors['white']};
    color: ${colors['navy.500']};
    font-size: ${fontSizes.body_md}px;
    font-family: ${fontFamilies.default};
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 8px;
    z-index: 5;
    width: fit-content;
    height: fit-content;
    max-width: ${!value ? 'calc(100% - (var(--_base-spacer) * 2))' : '100%'};
    padding: 0 8px;
    pointer-events: none;
    transition:
      transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
      color 150ms cubic-bezier(0.4, 0, 0.2, 1);
    transform: ${handleFloatingLabel(Boolean(value === 0 || value), hasLeftIcon)};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    [aria-invalid='true'] ~ & {
      color: ${colors['coral.600']};
    }

    input:focus:not(:read-only) ~ & {
      color: ${colors['blue.600']};
    }

    input:focus ~ & {
      transform: translate(-10%, -50%) scale(0.75);
      max-width: 100%;
    }
  `
);

const InputIcon = styled('div')<{ readonly placement: 'left' | 'right' }>(
  ({ placement }) => css`
    ${placement}: 0;
    width: 48px;
    height: 48px;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    z-index: 2;
    pointer-events: none;
    color: ${colors['navy.500']};
    overflow: hidden;
    opacity: 0.5;
  `
);

const ErrorMessage = styled('span')(
  () => css`
    color: ${colors['coral.600']};
    font-size: ${fontSizes.body_xs}px;
    font-family: ${fontFamilies.default};
    align-self: flex-start;
    margin-left: 16px;
  `
);

const InputGroup = styled('div')<{ readonly inputWidth: string | number | undefined }>(
  ({ inputWidth }) => css`
    --_base-spacer: 8px;
    width: ${inputWidth ? `${inputWidth}px` : '100%'};
    display: flex;
    position: relative;
  `
);

export { Input, Label, InputIcon, InputGroup, ErrorMessage };
