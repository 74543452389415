import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import {
  FETCH_LOOKS_REQUEST,
  FETCH_LOOKS_RESPONSE,
  DELETE_LOOK_MEDIA_ATTACHMENT_RESPONSE,
  UPDATE_MEDIA_ATTACHMENT_REQUEST,
  UPDATE_MEDIA_ATTACHMENT_RESPONSE,
  CREATE_MEDIA_ATTACHMENT_RESPONSE,
  CREATE_MEDIA_ATTACHMENT_REQUEST,
  CREATE_LOOK_RESPONSE,
  DELETE_LOOK_MEDIA_ATTACHMENT_REQUEST,
} from 'actionTypes';

const looks = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case FETCH_LOOKS_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case FETCH_LOOKS_RESPONSE:
      if (!action.looks || !action.looks.length) {
        return {
          ...state,
          ...reducerValuesOnResponse([].concat(state.items || [])),
        };
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.looks || []), {
          overrideDuplicateIds: 1,
        }),
      };
    case CREATE_LOOK_RESPONSE:
      if (!action.look) {
        return {
          ...state,
          ...reducerValuesOnResponse([].concat(state.items || [])),
        };
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.look || [])),
      };
    case CREATE_MEDIA_ATTACHMENT_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case CREATE_MEDIA_ATTACHMENT_RESPONSE: {
      if (!action.look_media_attachment) {
        return {
          ...state,
          ...reducerValuesOnResponse([].concat(state.items || [])),
        };
      }

      const { look_media_attachment, lookId } = action;

      const lookToUpdate = state.items.find((item) => item.id === lookId);
      const looks = state.items.filter((item) => item.id !== lookId);

      const updatedLook = {
        ...lookToUpdate,
        media_attachments: [].concat(lookToUpdate.media_attachments || []).concat(look_media_attachment),
      };

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(looks || []).concat(updatedLook)),
      };
    }
    case DELETE_LOOK_MEDIA_ATTACHMENT_REQUEST:
    case UPDATE_MEDIA_ATTACHMENT_REQUEST: {
      const { lookId, attachmentId } = action;

      // Search the look and the attachment to update
      const lookToUpdate = state.items.find((item) => item.id === lookId);

      if (!lookToUpdate) {
        return state;
      }

      const attachmentToUpdate = {
        ...lookToUpdate.media_attachments.find((mediaAttachment) => mediaAttachment.id === attachmentId),
        // The `isEditing` flag allows to disable actions from the UI for each attachment and prevent multiple clicks from happening, while
        // giving the perception of "something is happening" to the final user.
        // Notice that we don't care about resetting the value to false since this property exists only during the request processing.
        // Once the request is consumed, the media attachment will be either deleted or updated with a new one provided by the API.
        isEditing: true,
      };

      // Temporary remove the look to update from the looks list
      const looks = state.items.filter((item) => item.id !== lookId);

      // Calculate new media attachments
      const media_attachments = (
        lookToUpdate.media_attachments.filter((mediaAttachment) => mediaAttachment.id !== attachmentId) || []
      )
        .concat(attachmentToUpdate)
        .sort((a, b) => (b.created_at < a.created_at ? 1 : -1));

      // Recompose the state back
      const updatedLook = {
        ...lookToUpdate,
        media_attachments,
      };

      return {
        ...state,
        items: (looks || []).concat(updatedLook),
      };
    }
    case DELETE_LOOK_MEDIA_ATTACHMENT_RESPONSE:
    case UPDATE_MEDIA_ATTACHMENT_RESPONSE: {
      if (!action.look_media_attachment) {
        return {
          ...state,
          ...reducerValuesOnResponse([].concat(state.items || [])),
        };
      }

      const { look_media_attachment, lookId } = action;

      // Find the look to update
      const lookToUpdate = state.items.find((item) => item.id === lookId);

      if (!lookToUpdate) {
        return state;
      }

      // Exclude the look to update from looks, since we're going to overwrite it anyway
      const looks = state.items.filter((item) => item.id !== lookId);

      // Exclude the media attachment to update from the attachments list
      const updatedMediaAttachments =
        lookToUpdate.media_attachments.filter((mediaAttachment) => mediaAttachment.id !== look_media_attachment.id) ||
        [];

      // Calculate new media attachments.
      // When updating, we need to push back the updated media attachment, and sort the array back by creation date (ASC).
      const media_attachments =
        action.type === UPDATE_MEDIA_ATTACHMENT_RESPONSE
          ? []
              .concat(updatedMediaAttachments || [])
              .concat(look_media_attachment)
              .sort((a, b) => (b.created_at < a.created_at ? 1 : -1))
          : [].concat(updatedMediaAttachments || []);

      // Recompose the updated look
      const updatedLook = {
        ...lookToUpdate,
        media_attachments,
      };

      // Recompose the state
      return {
        ...state,
        ...reducerValuesOnResponse([].concat(looks || []).concat(updatedLook)),
      };
    }
    default:
      return state;
  }
};

const looksByVenue = (state = {}, action) => {
  switch (action.type) {
    case FETCH_LOOKS_REQUEST:
    case FETCH_LOOKS_RESPONSE:
    case CREATE_LOOK_RESPONSE:
    case CREATE_MEDIA_ATTACHMENT_REQUEST:
    case CREATE_MEDIA_ATTACHMENT_RESPONSE:
    case UPDATE_MEDIA_ATTACHMENT_REQUEST:
    case UPDATE_MEDIA_ATTACHMENT_RESPONSE:
    case DELETE_LOOK_MEDIA_ATTACHMENT_REQUEST:
    case DELETE_LOOK_MEDIA_ATTACHMENT_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }
      const refState = looks(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

export default looksByVenue;
