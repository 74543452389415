import { ValueOf } from 'utils/utilityTypes';

export type ContentTypes =
  | 'application/json'
  | 'text/html; charset=UTF-8'
  | 'multipart/form-data'
  | 'application/x-www-form-urlencoded';

export interface HttpMethod {
  headers?: Headers;
}

export interface HttpGetMethod<S, P = never> extends HttpMethod {
  searchParams?: S;
  Authorization?: string;
  body?: P;
}

export interface HttpPostMethod<S, P extends Record<string, unknown> | FormData> extends HttpMethod {
  body: P;
  Authorization?: string;
  searchParams?: S;
}

export const HTTP_METHODS = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  //PATCH: "PATCH",
  //TRACE: "TRACE",
  //OPTION: "OPTION",
  //CONNECT: "CONNECT",
} as const;

type DefaultHandlerParams = {
  path: string;
  mediaType?: ContentTypes;
};

type RequestHandlerParams_GET_DELETE<S> = {
  method: Extract<ValueOf<typeof HTTP_METHODS>, 'GET' | 'DELETE'>;
} & DefaultHandlerParams &
  HttpGetMethod<S>;

type RequestHandlerParams_PUT_POST<S, P extends Record<string, unknown> | FormData> = {
  method: Extract<ValueOf<typeof HTTP_METHODS>, 'POST' | 'PUT'>;
} & DefaultHandlerParams &
  HttpPostMethod<S, P>;

export type RequestHandlerParams<S, P extends Record<string, unknown> | FormData> =
  | RequestHandlerParams_GET_DELETE<S>
  | RequestHandlerParams_PUT_POST<S, P>;

export type SearchParams = Readonly<Record<string, unknown>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Payload = Record<string, any> | FormData | never;
