import { IntlShape } from 'react-intl';

export const stringToTestId = (value: string): string =>
  typeof value !== 'string'
    ? ''
    : value
        .replace(/[\\?=/]/g, ' ')
        .replace(/([^A-Za-z0-9\s-_])/g, '')
        .trim()
        .replace(/([-]+)$/g, '')
        .replace(/^([_-]+)/g, '')
        .trim()
        .toLowerCase()
        .replace(/\s[\s]+/g, ' ')
        .trim()
        .replace(/\s/g, '-')
        .replace(/[--]+/g, '-')
        .replace(/[__]+/g, '_');

export const intlIdToTestIdBuilder =
  (intl: IntlShape) =>
  (intlId: string): string =>
    stringToTestId(intl.formatMessage({ id: intlId }));
