import { css, styled, treatwellProTheme } from '../theme';
import { ButtonProps } from '.';
import { VARIANT } from './variants.theme';

function getButtonHeight(size: 'md' | 'lg'): string {
  switch (size) {
    case 'md':
      return '32px';
    case 'lg':
    default:
      return '48px';
  }
}

const {
  colors,
  typography: { fontFamilies, fontSizes, fontWeights },
} = treatwellProTheme;

export const Button = styled('button')<ButtonProps>(({ size = 'lg', width = '100%', variant, baseColor, kind }) => {
  return css`
    width: ${width};
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 0;
    padding: ${kind === 'link' ? '0' : '12px 16px'};
    background-color: ${colors[VARIANT(baseColor)[variant]?.backgroundColor.default]};
    color: ${colors[VARIANT(baseColor)[variant]?.color.default]};
    border-radius: 8px;
    border: ${kind === 'link'
      ? '0px none transparent'
      : `1px solid ${colors[VARIANT(baseColor)[variant]?.border.default]}`};
    outline-offset: 2px;
    outline: none;
    appearance: none;
    height: ${getButtonHeight(size)};
    font-size: ${fontSizes.body_md}px;
    font-family: ${fontFamilies.default};
    font-weight: ${fontWeights.bold};
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 0.2s;
    &:hover {
      background-color: ${colors[VARIANT(baseColor)[variant]?.backgroundColor.hover]};
      color: ${colors[VARIANT(baseColor)[variant]?.color.hover]};
    }
    &:active,
    &[aria-active='true'] {
      background-color: ${colors[VARIANT(baseColor)[variant]?.backgroundColor.active]};
      color: ${colors[VARIANT(baseColor)[variant]?.color.active]};
    }
    &:disabled,
    &[aria-disabled='true'] {
      background-color: ${colors[VARIANT(baseColor)[variant]?.backgroundColor.disabled]};
      color: ${colors[VARIANT(baseColor)[variant]?.color.disabled]};
      cursor: default;
      border-color: ${colors[VARIANT(baseColor)[variant]?.backgroundColor.disabled]};
    }
  `;
});

export const ButtonIcon = styled.div`
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  top: 0px;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
`;
