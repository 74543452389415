import { number, func } from 'prop-types';

import { Icon } from 'components/core';

import { FloatingActionButton, NotificationBadge } from './WizardAssistant.theme';

const WizardFloatingButton = ({ notificationsCount, onClick }) => (
  <FloatingActionButton onClick={onClick}>
    {notificationsCount > 0 && <NotificationBadge>{notificationsCount}</NotificationBadge>}
    <Icon type="compass" color="blue" size={25} />
  </FloatingActionButton>
);

WizardFloatingButton.displayName = 'WizardFloatingButton';

WizardFloatingButton.propTypes = {
  notificationsCount: number,
  onClick: func,
};

WizardFloatingButton.defaultProps = {
  notificationsCount: 0,
  onClick: () => {},
};

export default WizardFloatingButton;
