export const hasPushNotificationsCapabilities = (): boolean => {
  return Boolean(window?.androidHandler?.getDeviceToken);
};

type InitArgs = {
  onTokenUpdated: (deviceToken: string) => void;
  onClickNotification: <A>(data: A) => void;
};

export const initPushNotifications = ({ onTokenUpdated, onClickNotification }: InitArgs): void => {
  if (hasPushNotificationsCapabilities()) {
    /**
     * Setup window method to receive deviceToken
     * Android wrapper will call this method later:
     * `window.deviceTokenUpdated('the device token from Android wrapper')`
     */
    window.deviceTokenUpdated = (deviceToken) => {
      onTokenUpdated(deviceToken);
      return;
    };

    /**
     * Setup window method to manage custom data from push notifications
     */
    window.pushNotificationOpened = (data) => {
      try {
        if (data && data.custom && data.custom.a) {
          onClickNotification(data.custom.a);
        }
      } catch (e) {
        console.error(e);
      }
      return;
    };

    window.androidHandler!.getDeviceToken(); // Initialising Push Notifications for Android
  }
};

export const stopPushNotifications = async () => {};
