import {
  VENUE_VACANCIES_REQUEST,
  VENUE_VACANCIES_RESPONSE,
  VENUE_CUSTOM_TIME_TABLES_REQUEST,
  VENUE_CUSTOM_TIME_TABLES_RESPONSE,
  VENUE_EXTRA_OPENINGS_REQUEST,
  VENUE_EXTRA_OPENINGS_RESPONSE,
  VENUE_CREATE_EXTRA_OPENINGS_BULK_RESPONSE,
  VENUE_CREATE_EXTRA_OPENINGS_RESPONSE,
  VENUE_UPDATE_EXTRA_OPENINGS_RESPONSE,
  VENUE_DELETE_EXTRA_OPENINGS_RESPONSE,
} from 'actionTypes';

import { apiGet, apiPost } from './data_providers/api';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { actionShouldFetchData } from 'utils/reducersUtils';
import moment from 'moment';
import { updateVenueWizardIfNeeded } from './venueWizards';

const venueVacanciesRequestAction = ({ venue_id, auth }) => ({
  type: VENUE_VACANCIES_REQUEST,
  venue_id,
  auth,
});

const venueVacanciesResponseAction = ({ error, venue_id, vacancies }) => ({
  type: VENUE_VACANCIES_RESPONSE,
  venue_id,
  vacancies,
  error,
});

const venueExtraOpeningsRequestAction = ({ venue_id, auth }) => ({
  type: VENUE_EXTRA_OPENINGS_REQUEST,
  venue_id,
  auth,
});

const venueExtraOpeningsResponseAction = ({ error, venue_id, extra_openings }) => ({
  type: VENUE_EXTRA_OPENINGS_RESPONSE,
  venue_id,
  extra_openings,
  error,
});

const venueCustomTimeTablesRequestAction = ({ venue_id, auth }) => ({
  type: VENUE_CUSTOM_TIME_TABLES_REQUEST,
  venue_id,
  auth,
});

const venueCustomTimeTablesResponseAction = ({ error, venue_id, custom_time_tables }) => ({
  type: VENUE_CUSTOM_TIME_TABLES_RESPONSE,
  venue_id,
  custom_time_tables,
  error,
});

const venueCreateExtraOpeningsBulkResponseAction = ({ error, venue_id, extra_openings }) => ({
  type: VENUE_CREATE_EXTRA_OPENINGS_BULK_RESPONSE,
  venue_id,
  extra_openings,
  error,
});

const venueCreateExtraOpeningsResponseAction = ({ error, venue_id, extra_opening }) => ({
  type: VENUE_CREATE_EXTRA_OPENINGS_RESPONSE,
  venue_id,
  extra_opening,
  error,
});

const venueUpdateExtraOpeningsResponseAction = ({ error, venue_id, extra_opening }) => ({
  type: VENUE_UPDATE_EXTRA_OPENINGS_RESPONSE,
  venue_id,
  extra_opening,
  error,
});

const venueDeleteExtraOpeningsResponseAction = ({ error, venue_id, extra_opening }) => ({
  type: VENUE_DELETE_EXTRA_OPENINGS_RESPONSE,
  venue_id,
  extra_opening,
  error,
});

export const fetchVenueVacancies = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(venueVacanciesRequestAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/vacancies
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/vacancies.json`,
        data: {
          from_time: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        },
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(venueVacanciesResponseAction({ error: response.info && response.info[0] }));
          }
          dispatch(venueVacanciesResponseAction({ venue_id, vacancies: response.data.vacancies }));
        },
        onError(error) {
          dispatch(venueVacanciesResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchVenueVacanciesIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().venueVacanciesByVenue[venue_id])) {
      return dispatch(fetchVenueVacancies());
    }
  };
};

export const fetchVenueExtraOpenings = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(venueExtraOpeningsRequestAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/extra_openings
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/extra_openings.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(venueExtraOpeningsResponseAction({ error: response.info && response.info[0] }));
          }
          dispatch(venueExtraOpeningsResponseAction({ venue_id, extra_openings: response.data.extra_openings }));
        },
        onError(error) {
          dispatch(venueExtraOpeningsResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchVenueExtraOpeningsIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().venueExtraOpeningsByVenue[venue_id])) {
      return dispatch(fetchVenueExtraOpenings());
    }
  };
};

export const fetchVenueCustomTimeTables = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(venueCustomTimeTablesRequestAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/custom_time_tables
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/custom_time_tables.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(venueCustomTimeTablesResponseAction({ error: response.info && response.info[0] }));
          }
          dispatch(
            venueCustomTimeTablesResponseAction({ venue_id, custom_time_tables: response.data.custom_time_tables })
          );
        },
        onError(error) {
          dispatch(venueCustomTimeTablesResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchVenueCustomTimeTablesIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().venueCustomTimeTablesByVenue[venue_id])) {
      return dispatch(fetchVenueCustomTimeTables());
    }
  };
};

export const fetchVenueTimeTables = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(venueVacanciesRequestAction({ venue_id, auth }));
    dispatch(venueExtraOpeningsRequestAction({ venue_id, auth }));
    dispatch(venueCustomTimeTablesRequestAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/vacancies
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/vacancies.json`,
        data: {
          from_time: moment().subtract(2, 'days').format('YYYY-MM-DD'),
        },
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(venueVacanciesResponseAction({ error: response.info && response.info[0] }));
          }
          dispatch(venueVacanciesResponseAction({ venue_id, vacancies: response.data.vacancies }));
        },
        onError(error) {
          dispatch(venueVacanciesResponseAction({ error }));
        },
      })
    );

    // http://docs.uala.it/api/v1/extra_openings
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/extra_openings.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(venueExtraOpeningsResponseAction({ error: response.info && response.info[0] }));
          }
          dispatch(venueExtraOpeningsResponseAction({ venue_id, extra_openings: response.data.extra_openings }));
        },
        onError(error) {
          dispatch(venueExtraOpeningsResponseAction({ error }));
        },
      })
    );

    // http://docs.uala.it/api/v1/custom_time_tables
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/custom_time_tables.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(venueCustomTimeTablesResponseAction({ error: response.info && response.info[0] }));
          }
          dispatch(
            venueCustomTimeTablesResponseAction({ venue_id, custom_time_tables: response.data.custom_time_tables })
          );
        },
        onError(error) {
          dispatch(venueCustomTimeTablesResponseAction({ error }));
        },
      })
    );
  };
};

/**
 * Every day must set as: start_time,end_time,start_break,end_break\n
 *
 * @param time_tables
 * @returns {function(*, *): Promise<any>}
 */
export const updateVenueTimetables =
  ({ time_tables }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth) {
        return reject({ code: 400 });
      }

      const formattedTimetable = [];

      Object.keys(time_tables).forEach((day) => {
        formattedTimetable[day] = [];

        Object.keys(time_tables[day].timetable).forEach((timetableLabel) => {
          const timetableItem = time_tables[day].timetable[timetableLabel];

          if (timetableItem instanceof moment) {
            formattedTimetable[day][timetableLabel] = timetableItem.format('HH:mm');
          }

          if (!timetableItem) {
            formattedTimetable[day][timetableLabel] = '';
          }
        });
      });

      const sunday = formattedTimetable[0];
      const monday = formattedTimetable[1];
      const tuesday = formattedTimetable[2];
      const wednesday = formattedTimetable[3];
      const thursday = formattedTimetable[4];
      const friday = formattedTimetable[5];
      const saturday = formattedTimetable[6];

      // https://docs.uala.it/api/v1/venues#put-venuesidjson-update-time-table
      dispatch(
        apiPost({
          path: `/venues/${venue_id}.json`,
          method: 'PUT',
          data: {
            venue: {
              time_table:
                `${sunday.start_time},${sunday.end_time},${sunday.start_break},${sunday.end_break}\n` +
                `${monday.start_time},${monday.end_time},${monday.start_break},${monday.end_break}\n` +
                `${tuesday.start_time},${tuesday.end_time},${tuesday.start_break},${tuesday.end_break}\n` +
                `${wednesday.start_time},${wednesday.end_time},${wednesday.start_break},${wednesday.end_break}\n` +
                `${thursday.start_time},${thursday.end_time},${thursday.start_break},${thursday.end_break}\n` +
                `${friday.start_time},${friday.end_time},${friday.start_break},${friday.end_break}\n` +
                `${saturday.start_time},${saturday.end_time},${saturday.start_break},${saturday.end_break}\n`,
            },
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(venueUpdateExtraOpeningsResponseAction({ venue: response.data.venue }));

            dispatch(updateVenueWizardIfNeeded('timetables_incomplete'));

            resolve({ venue: response.data.venue });
          },
          onError(error) {
            dispatch(venueUpdateExtraOpeningsResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const deleteTimetableExtraOpenings = (id) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return reject({ code: 400 });
    }

    // https://docs.uala.it/api/v1/extra_openings
    dispatch(
      apiPost({
        method: 'DELETE',
        path: `/venues/${venue_id}/extra_openings/${id}.json`,
        data: {},
        auth,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          dispatch(
            venueDeleteExtraOpeningsResponseAction({ venue_id, extra_opening: response.data.extra_opening, response })
          );
          resolve({ extra_opening: response.data.extra_opening, response });
        },
        onError(error) {
          dispatch(venueDeleteExtraOpeningsResponseAction({ error }));
          reject({ error });
        },
      })
    );
  });

export const updateTimetableExtraOpenings = (payload) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return reject({ code: 400 });
    }

    // https://docs.uala.it/api/v1/extra_openings
    dispatch(
      apiPost({
        method: 'PUT',
        path: `/venues/${venue_id}/extra_openings/${payload.id}.json`,
        data: {
          extra_opening: payload,
        },
        auth,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          dispatch(
            venueUpdateExtraOpeningsResponseAction({ venue_id, extra_opening: response.data.extra_opening, response })
          );
          resolve({ extra_opening: response.data.extra_opening, response });
        },
        onError(error) {
          dispatch(venueUpdateExtraOpeningsResponseAction({ error }));
          reject({ error });
        },
      })
    );
  });

export const saveTimetableExtraOpenings = (payload) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return reject({ code: 400 });
    }

    // https://docs.uala.it/api/v1/extra_openings
    dispatch(
      apiPost({
        path: `/venues/${venue_id}/extra_openings.json`,
        data: {
          extra_opening: payload,
        },
        auth,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          dispatch(
            venueCreateExtraOpeningsResponseAction({ venue_id, extra_opening: response.data.extra_opening, response })
          );
          resolve({ extra_opening: response.data.extra_opening, response });
        },
        onError(error) {
          dispatch(venueCreateExtraOpeningsResponseAction({ error }));
          reject({ error });
        },
      })
    );
  });

export const saveTimetableExtraOpeningsBulkCreate = (payload) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return reject({ code: 400 });
    }

    // https://docs.uala.it/api/v1/extra_openings
    dispatch(
      apiPost({
        path: `/venues/${venue_id}/extra_openings/bulk_create.json`,
        data: {
          extra_opening: payload,
        },
        auth,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          dispatch(
            venueCreateExtraOpeningsBulkResponseAction({
              venue_id,
              extra_openings: response.data.extra_openings,
              response,
            })
          );
          resolve({ extra_openings: response.data.extra_openings, response });
        },
        onError(error) {
          dispatch(venueCreateExtraOpeningsBulkResponseAction({ error }));
          reject({ error });
        },
      })
    );
  });
