import { connect } from 'react-redux';
import { createSelector, OutputParametricSelector, ParametricSelector } from 'reselect';
import VenueButton from './VenueButton';
import { getCurrentVenueId, getUpdatedSinceByVenue } from 'selectors/getCurrentVenue';
import { State } from 'reducers/types';
import { switchCurrentVenue } from 'actions/sessions';
import { Venue } from 'models';
import { Session } from 'reducers/sessions';
import { toggleMultiVenueSidebar } from 'actions/multiVenueSidebarSettings';
import { UnknownFunction } from 'utils/utilityTypes';

type VenueButtonContainerProps = {
  venueId: number | null;
  imageSrc?: string | null;
  venueShortName?: string | null;
  venueFullName?: Venue['name'];
  isVenueSelected?: boolean;
  isShortNameHidden?: boolean;
};

type VenueButtonStateProps = {
  updatedSinceByVenue: Session['updatedSinceByVenue'];
  isVenueSelected: boolean;
};

const getVenueId: ParametricSelector<State, VenueButtonContainerProps, number | null> = (_state, props) =>
  props.venueId || null;

const mapStateToProps = (): OutputParametricSelector<
  State,
  VenueButtonContainerProps,
  VenueButtonStateProps,
  UnknownFunction
> =>
  createSelector(
    getUpdatedSinceByVenue,
    getVenueId,
    getCurrentVenueId,
    (updatedSinceByVenue, venueId, currentVenueId) => {
      return {
        updatedSinceByVenue,
        isVenueSelected: currentVenueId === venueId,
      };
    }
  );

const mapDispatchToProps = {
  dispatchSwitchCurrentVenue: switchCurrentVenue,
  dispatchToggleMultiVenueSidebar: toggleMultiVenueSidebar,
};
export const VenueButtonConnector = connect(mapStateToProps, mapDispatchToProps);
export default VenueButtonConnector(VenueButton);
