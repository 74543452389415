// {
//   to: "/agenda", <- link
//   title: "Agenda",
//   titleIntl: "agenda.title", <- if title not set
//   icon: "calendar"
//   iconColor: "text2", <- default text2
//   activePaths: ["/agenda", "..."] <- list of paths that make item displayed as "active",
//   isVisible: ({ venue, checkFeature, checkPermission }) => {}, <- strategy that returns true if item enabled (children will be disabled if parent not enabled)
//   isDisabledWhenBlockedPeriod: <- disable/enable page when venue.billing_details.state = 'blocked'
//   children: [{
//     to: '/warehouse/product',
//     titleIntl: 'warehouse.menu-title',
//     activePaths: ['/warehouse/product/:id?'],
//     isVisible: () => true,
//   }]
// }
import { UALA_FEATURE_FLAG } from 'env';
import { Venue, VENUE_ACTIVE_FEATURE_FLAG, ACL_PERMISSION, WHITELABEL } from 'models';
import { CheckCurrentVenueActiveFlag } from 'selectors/getCurrentVenue';
import isEmpty from 'utils/isEmpty';
import { isMobileNativeWrapper } from 'utils/native/cash/getCurrentNativeWrapper';
import { isAccountInBlockPeriod } from 'utils/selfprovisioning';
import { containsEnabledBrand, getActiveBrandName, isSectionEnabledByBrand } from 'utils/venueBrands';
import { getProfileUri } from 'utils/profile/getProfileUri';
import { isFeatureFlagEnabled } from 'feature-flags/unleash';
import { ACTIVE_FLAGS } from 'feature-flags/ACTIVE_FLAGS';

type CheckFeature = (feature: string) => boolean;

type CheckPermission = (permission: string) => string;

type IsVisible = ({
  checkFeature,
  checkPermission,
  venue,
}: {
  checkFeature: CheckFeature;
  checkPermission: CheckPermission;
  venue: Venue;
}) => boolean;

type IsDisabled = ({ venue }: { venue: Venue }) => boolean;

type NavBarItemBadge = (args: { checkCurrentVenueActiveFlag: CheckCurrentVenueActiveFlag }) => string | null;

type To = ({ venue }: { venue: Venue }) => string | null;

type TitleIntl = ({ venue }: { venue: Venue }) => string | null;

type TitleIntlValues = ({ venue }: { venue: Venue }) => Record<string, unknown>;

const shouldUpsell = (flag: VENUE_ACTIVE_FEATURE_FLAG): NavBarItemBadge | null => {
  if (!flag) {
    return null;
  }

  return ({ checkCurrentVenueActiveFlag }): string | null => (!checkCurrentVenueActiveFlag(flag) ? 'upgrade' : null);
};

const shouldBeDisabledInBlockedPeriod: IsDisabled = ({ venue }) => {
  if (!venue || isAccountInBlockPeriod(venue)) {
    return true;
  }

  return false;
};

export interface NavBarItem {
  icon?: string;
  children?: NavBarItem[];
  isExternal?: () => boolean;
  to?: string | To;
  title?: string;
  titleIntl?: string | TitleIntl;
  titleIntlValues?: TitleIntlValues;
  activePaths?: string[];
  isVisible?: IsVisible;
  isDisabled?: IsDisabled;
  badgeIntl?: NavBarItemBadge | null;
}

export type SeparatorItem = {
  separator: boolean;
};

const isMobile = (): boolean => window.innerWidth < 768;

export const items: (NavBarItem | SeparatorItem)[] = [
  {
    to: '/agenda',
    titleIntl: 'agenda.title',
    icon: 'calendar',
    activePaths: ['/agenda'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.AGENDA) && checkPermission(ACL_PERMISSION.CALENDAR) !== 'none',
    isDisabled: shouldBeDisabledInBlockedPeriod,
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA),
  },
  {
    to: '/checkout',
    titleIntl: 'checkout.title',
    icon: 'cassa',
    activePaths: ['/checkout'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.CHECKOUT) && checkPermission(ACL_PERMISSION.CASH_REGISTER) !== 'none',
    isDisabled: shouldBeDisabledInBlockedPeriod,
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_CASH_REGISTER),
  },
  {
    to: '/customers',
    titleIntl: 'customers.title',
    icon: 'customers',
    activePaths: ['/customers'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.CUSTOMERS) && checkPermission(ACL_PERMISSION.CUSTOMERS) !== 'none',
    isDisabled: shouldBeDisabledInBlockedPeriod,
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA),
  },
  {
    to: '/promotions',
    titleIntl: 'promotions.title',
    icon: 'promotions',
    activePaths: ['/promotions'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.PROMOTIONS) && checkPermission(ACL_PERMISSION.MARKETING) !== 'none',
    isDisabled: shouldBeDisabledInBlockedPeriod,
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_PROMOTIONS),
  },
  {
    to: '/apps/twstore?utm_source=menu',
    titleIntl: 'twstore.title',
    icon: 'cart-store',
    activePaths: ['/apps/twstore'],
    isVisible: ({ checkPermission }) =>
      isFeatureFlagEnabled(ACTIVE_FLAGS.TWPRO_STORE) && checkPermission(ACL_PERMISSION.STORE_ACCESS) !== 'none',
    badgeIntl: () => (isFeatureFlagEnabled(ACTIVE_FLAGS.TWPRO_STORE_NEW_BADGE) ? 'new' : null),
  },
  {
    titleIntl: 'warehouse.title',
    icon: 'warehouse',
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.WAREHOUSE) && checkPermission(ACL_PERMISSION.WAREHOUSE) !== 'none',
    children: [
      {
        to: '/warehouse_v2/product',
        titleIntl: 'warehouse.menu-title',
        activePaths: ['/warehouse_v2/product/:id(d+)?'],
        isVisible: (): boolean => true,
      },
      {
        to: '/warehouse/discharge',
        titleIntl: 'warehouse.discharge.title',
        activePaths: ['/warehouse/discharge'],
        isVisible: isMobile,
      },
      {
        to: '/warehouse/sent-order',
        titleIntl: 'warehouse.order.title',
        activePaths: ['/warehouse/sent-order/:id?'],
        isVisible: (): boolean => true,
      },
      {
        to: '/warehouse/tickler/expiring',
        titleIntl: 'warehouse.tickler.title',
        activePaths: ['/warehouse/tickler/:type?/:id?/:item?'],
        isVisible: (): boolean => true,
      },
    ],
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE),
    isDisabled: shouldBeDisabledInBlockedPeriod,
  },
  {
    to: '/marketing',
    titleIntl: 'marketing.title',
    icon: 'megaphone',
    activePaths: ['/marketing'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.MARKETING) && checkPermission(ACL_PERMISSION.MARKETING) !== 'none',
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_MARKETING),
    isDisabled: shouldBeDisabledInBlockedPeriod,
  },
  {
    to: '/costs',
    titleIntl: 'costs.title',
    icon: 'fiche',
    activePaths: ['/costs'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.COSTS) && checkPermission(ACL_PERMISSION.EXPENSES) !== 'none',
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_EXPENSES),
    isDisabled: shouldBeDisabledInBlockedPeriod,
  },
  {
    separator: true,
  },
  {
    titleIntl: 'timetables.title',
    icon: 'clock',
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.TIMETABLE) && checkPermission(ACL_PERMISSION.BUSINESS_HOURS) !== 'none',
    children: [
      {
        to: '/timetables',
        titleIntl: 'timetables.title',
        activePaths: ['/timetables', '/timetables/day/:name'],
        isVisible: (): boolean => true,
      },
      {
        to: '/timetables/extra-openings',
        titleIntl: 'timetables.extraopenings.title',
        activePaths: ['/timetables/extra-openings/:id?'],
        isVisible: (): boolean => true,
      },
      {
        to: '/timetables/extra-closing',
        titleIntl: 'timetables.extraclosing.title',
        activePaths: ['/timetables/extra-closing/:id?'],
        isVisible: (): boolean => true,
      },
    ],
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA),
    isDisabled: shouldBeDisabledInBlockedPeriod,
  },
  {
    to: '/treatments',
    titleIntl: 'treatments.title',
    icon: 'scissors',
    activePaths: ['/treatments'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.TREATMENTS) && checkPermission(ACL_PERMISSION.SERVICES) !== 'none',
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA),
    isDisabled: shouldBeDisabledInBlockedPeriod,
  },
  {
    to: '/staff_members',
    titleIntl: ({ venue }): string => (venue.feature_room_staff_member ? 'staff.title_rooms' : 'staff.title'),
    icon: 'staff',
    activePaths: ['/staff_members'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.STAFF_MEMBERS) && checkPermission(ACL_PERMISSION.STAFF) !== 'none',
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA),
    isDisabled: shouldBeDisabledInBlockedPeriod,
  },
  {
    to: '/workstations',
    titleIntl: 'workstations.title',
    icon: 'desk',
    activePaths: ['/workstations'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.STAFF_MEMBERS) && checkPermission(ACL_PERMISSION.STAFF) !== 'none',
    badgeIntl: shouldUpsell(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_AGENDA),
    isDisabled: shouldBeDisabledInBlockedPeriod,
  },
  {
    separator: true,
  },
  // HINT: we should render manufacturers if active_cash_register = false but active_warehouse = true
  {
    to: ({ venue }): string | null => (venue.active_expenses && !venue.active_warehouse ? '/supplier' : null),
    activePaths: ['/supplier/:id?', '/manufacturer/:id?'],
    titleIntl: ({ venue }): string | null =>
      venue && venue.active_expenses
        ? venue.active_warehouse
          ? 'warehouse.supplier_manufacturer.title'
          : 'warehouse.supplier.title'
        : null,
    icon: 'shipping',
    isVisible: ({ checkFeature, checkPermission, venue }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.COSTS) &&
      checkPermission(ACL_PERMISSION.EXPENSES) !== 'none' &&
      checkPermission(ACL_PERMISSION.WAREHOUSE) !== 'none' &&
      venue &&
      venue.active_expenses,
    isDisabled: shouldBeDisabledInBlockedPeriod,
    children: [
      {
        to: '/supplier',
        titleIntl: 'warehouse.supplier.title',
        activePaths: ['/supplier/:id?'],
        isVisible: ({ venue }): boolean => venue.active_expenses && venue.active_warehouse,
      },
      {
        to: '/manufacturer',
        titleIntl: 'warehouse.manufacturer.title',
        activePaths: ['/manufacturer/:id?'],
        isVisible: ({ venue }): boolean => venue.active_expenses && venue.active_warehouse,
      },
      {
        to: '/warehouse/shipping-option',
        titleIntl: 'warehouse.ecommerce.shipping_option.title',
        activePaths: ['/warehouse/shipping-option/:id?'],
        isVisible: ({ venue }): boolean => venue.active_expenses && venue.active_warehouse,
      },
    ],
  },
  {
    separator: true,
  },
  {
    titleIntl: 'manager',
    icon: 'linechart',
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.MANAGER_TREND) && checkPermission(ACL_PERMISSION.MANAGER) !== 'none',
    isDisabled: shouldBeDisabledInBlockedPeriod,
    children: [
      {
        to: '/manager/trend',
        titleIntl: 'manager.title',
        activePaths: ['/manager/trend'],
        isVisible: ({ checkFeature, checkPermission }): boolean =>
          checkFeature(UALA_FEATURE_FLAG.MANAGER_TREND) &&
          checkPermission(ACL_PERMISSION.MANAGER_TREND_ANALYSIS) !== 'none',
      },
      {
        to: '/manager/company-report',
        titleIntl: 'manager.company_report.title',
        activePaths: [
          '/manager/company-report',
          '/manager/company-report/profitability-analysis',
          '/manager/company-report/appointments-analysis',
        ],
        isVisible: ({ checkFeature, checkPermission }): boolean =>
          checkFeature(UALA_FEATURE_FLAG.MANAGER_COMPANY_REPORT) &&
          checkPermission(ACL_PERMISSION.MANAGER_COMPANY_REPORT) !== 'none',
      },
      {
        to: '/manager/company-report/sales-proceeds',
        titleIntl: 'manager.company_report.sales_proceeds.title',
        activePaths: ['/manager/company-report/sales-proceeds'],
        isVisible: ({ checkFeature, checkPermission }): boolean =>
          checkFeature(UALA_FEATURE_FLAG.MANAGER_COMPANY_REPORT) &&
          checkPermission(ACL_PERMISSION.MANAGER_SALES_PROCEEDS) !== 'none',
      },
      {
        to: '/manager/collaborators-report',
        titleIntl: 'manager.collaborators_report.title',
        activePaths: [
          '/manager/collaborators-report',
          '/manager/collaborators-report/profitability-analysis',
          '/manager/collaborators-report/reviews-and-loyalty-analysis',
        ],
        isVisible: ({ checkFeature, checkPermission }): boolean =>
          checkFeature(UALA_FEATURE_FLAG.MANAGER_COLLABORATORS_REPORT) &&
          checkPermission(ACL_PERMISSION.MANAGER_COLLABORATORS_REPORT) !== 'none',
      },
      {
        to: '/manager/customers-report',
        titleIntl: 'manager.customers_report.title',
        activePaths: [
          '/manager/customers-report',
          '/manager/customers-report/customer-analysis',
          '/manager/customers-report/customer-frequency-analysis',
          '/manager/customers-report/customer-debts-analysis',
        ],
        isVisible: ({ checkFeature, checkPermission }): boolean =>
          checkFeature(UALA_FEATURE_FLAG.MANAGER_CUSTOMERS_REPORT) &&
          checkPermission(ACL_PERMISSION.MANAGER_CUSTOMERS_REPORT) !== 'none',
      },
      {
        to: '/manager/stock-report',
        titleIntl: 'manager.stock_report.title',
        activePaths: ['/manager/stock-report', '/manager/stock-report/handling-analysis'],
        isVisible: ({ checkFeature, checkPermission }): boolean =>
          checkFeature(UALA_FEATURE_FLAG.MANAGER_STOCK_REPORT) &&
          checkPermission(ACL_PERMISSION.MANAGER_STOCK_REPORT) !== 'none',
      },
      {
        to: '/manager/inventory-report',
        titleIntl: 'manager.inventory_report.title',
        activePaths: ['/manager/inventory-report'],
        isVisible: ({ checkFeature, checkPermission }): boolean =>
          checkFeature(UALA_FEATURE_FLAG.MANAGER_INVENTORY_REPORT) &&
          checkPermission(ACL_PERMISSION.MANAGER_INVENTORY_REPORT) !== 'none',
      },
      // Brand sections
      {
        to: ({ venue }): string | null => (venue ? `/manager/diary/${getActiveBrandName(venue.brands)}` : null),
        titleIntl: 'manager.brand_diary',
        titleIntlValues: ({ venue }): Record<string, string | number | undefined> => ({
          brand: getActiveBrandName(venue.brands),
        }),
        isVisible: ({ checkFeature, checkPermission, venue }): boolean =>
          checkFeature('UALA_FEATURE_MANAGER_COMPANY_REPORT') &&
          checkPermission('manager_company-report') !== 'none' &&
          !isEmpty(venue.brands) &&
          containsEnabledBrand(venue?.brands) &&
          isSectionEnabledByBrand(getActiveBrandName(venue.brands), 'diary'),
      },
      {
        to: ({ venue }): string | null => (venue ? `/manager/benchmark/${getActiveBrandName(venue.brands)}` : null),
        titleIntl: 'manager.brand_benchmark',
        titleIntlValues: ({ venue }): Record<string, string | number | undefined> => ({
          brand: getActiveBrandName(venue.brands),
        }),
        isVisible: ({ checkFeature, checkPermission, venue }): boolean =>
          checkFeature('UALA_FEATURE_MANAGER_COMPANY_REPORT') &&
          checkPermission('manager_company-report') !== 'none' &&
          !isEmpty(venue.brands) &&
          containsEnabledBrand(venue?.brands) &&
          isSectionEnabledByBrand(getActiveBrandName(venue.brands), 'benchmark'),
      },
      {
        to: ({ venue }): string | null =>
          venue ? `/manager/brand/${getActiveBrandName(venue.brands)}/salon-analysis` : null,
        titleIntl: 'manager.brand_salon',
        titleIntlValues: ({ venue }): Record<string, string | number | undefined> => ({
          brand: getActiveBrandName(venue.brands),
        }),
        isVisible: ({ checkFeature, checkPermission, venue }): boolean =>
          checkFeature('UALA_FEATURE_MANAGER_COMPANY_REPORT') &&
          checkPermission('manager_company-report') !== 'none' &&
          !isEmpty(venue.brands) &&
          containsEnabledBrand(venue?.brands) &&
          isSectionEnabledByBrand(getActiveBrandName(venue.brands), 'salon'),
      },
      {
        to: ({ venue }): string | null =>
          venue ? `/manager/brand/${getActiveBrandName(venue.brands)}/staff-members-analysis` : null,
        titleIntl: 'manager.brand_staff_members',
        titleIntlValues: ({ venue }): Record<string, string | number | undefined> => ({
          brand: getActiveBrandName(venue.brands),
        }),
        isVisible: ({ checkFeature, checkPermission, venue }): boolean =>
          checkFeature('UALA_FEATURE_MANAGER_COMPANY_REPORT') &&
          checkPermission('manager_company-report') !== 'none' &&
          !isEmpty(venue.brands) &&
          containsEnabledBrand(venue?.brands) &&
          isSectionEnabledByBrand(getActiveBrandName(venue.brands), 'staff_members'),
      },
      {
        to: ({ venue }): string | null =>
          venue ? `/manager/brand/${getActiveBrandName(venue.brands)}/income-statement-analysis` : null,
        titleIntl: 'manager.brand_income_statement',
        titleIntlValues: ({ venue }): Record<string, string | number | undefined> => ({
          brand: getActiveBrandName(venue.brands),
        }),
        isVisible: ({ checkFeature, checkPermission, venue }): boolean =>
          checkFeature('UALA_FEATURE_MANAGER_COMPANY_REPORT') &&
          checkPermission('manager_company-report') !== 'none' &&
          !isEmpty(venue.brands) &&
          containsEnabledBrand(venue?.brands) &&
          isSectionEnabledByBrand(getActiveBrandName(venue.brands), 'income_statement'),
      },
    ],
  },
  {
    to: '/reviews',
    titleIntl: 'reviews.title',
    icon: 'empty-star',
    activePaths: ['/reviews'],
    isVisible: ({ checkPermission, venue }): boolean =>
      checkPermission(ACL_PERMISSION.REVIEWS) !== 'none' && venue?.whitelabel !== WHITELABEL.UALA,
  },
  {
    separator: true,
  },
  {
    to: getProfileUri,
    titleIntl: 'account.title',
    icon: 'profile',
    activePaths: ['/account/:section?/:tab?'],
    isVisible: ({ checkFeature, checkPermission }): boolean =>
      checkFeature(UALA_FEATURE_FLAG.PROFILE) && checkPermission(ACL_PERMISSION.ACCOUNT) !== 'none',
    isExternal: isMobileNativeWrapper,
  },
  {
    to: '/assistant',
    title: 'Assistant',
    icon: 'compass',
    activePaths: ['/assistant'],
    isVisible: ({ checkFeature }): boolean => checkFeature(UALA_FEATURE_FLAG.ASSISTANT),
  },
];

export default items;
