import { captureMessage } from '@sentry/core';
import { apiGetResponseAction } from 'actions/data_providers/api';
import { updatedSinceReceived } from 'actions/sessions';
import { executeSynchHooks } from 'actions/synch-hooks';
import { Venue } from 'models';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { getCurrentVenueActiveMQTT } from 'selectors/getCurrentVenue';
import { isUserMultiVenue } from 'selectors/getUserMultiVenue';
import getMQTTOption from './env';
import { httpSynch } from './http';
import connectToMQTT from './mqtt';
import { startPolling, stopPolling } from './polling';
import {
  AddToStack,
  InitSynch,
  OnMQTTFailure,
  RemoveFromStack,
  MQTTClient,
  SynchDataReceived,
  SubscribeToVenueCountryChannel,
  SubscribeToVenueChannel,
  UnsubscribeFromVenueChannel,
  ControlPollingVenueSynch,
  StopSynch,
  ControlFallbackWatchdog,
} from './types';
import { devLogger, getVenueDBGId } from 'utils/synch/utils';
import { ActionDispatch } from 'actions/types';

const venues: Venue[] = [];
const countryIDs: number[] = [];
type WatchdogData = {
  interval: ReturnType<typeof setInterval>;
  isUsingFallback: boolean;
};
const watchDogByVenueId: Record<number, WatchdogData> = {};

const MAX_VENUE_SYNC_ALLOWED = 4;
const DEFAULT_API_REFRESH_RATE = 30;

let initializedSynch = false;
let mqttClient: MQTTClient;

export const initSynch: InitSynch =
  () =>
  (dispatch, getState): Promise<MQTTClient | void> =>
    new Promise((resolve, reject) => {
      devLog('initSynch');

      /**
       * Check if Synch is already initialized
       */
      if (initializedSynch) {
        devLog('Already initialized');
        return resolve();
      }

      /**
       * Get info about user
       */
      const auth_token = getCurrentUserToken(getState());
      if (!auth_token) {
        return reject('Missing auth_token');
      }

      const is_multi_venue = isUserMultiVenue(getState());
      if (!is_multi_venue) {
        /**
         * If user is single Venue, look for `active_mqtt` flag
         */
        const active_mqtt = getCurrentVenueActiveMQTT(getState());
        if (!active_mqtt) {
          /**
           * Nothing to do, Synch is through HTTP polling
           */
          initializedSynch = true;
          devLog('Sync through HTTP Polling (via Venue AA Config)');
          return resolve();
        }
      }

      /**
       * Check ENV config, fallback on HTTP Synch:
       */
      if (!getMQTTOption('HOST')) {
        devLog('Missing config');
        initializedSynch = true;
        return resolve();
      }

      /**
       * User is MQTT-enabled OR multi-venue (MQTT-forced)
       */
      dispatch(connectToMQTT()).then((client) => {
        mqttClient = client;

        /**
         * MQTT is connected
         */
        devLog('Subscribing to MQTT Channel [/api/ping]');
        mqttClient.subscribe('/api/ping', {
          onFailure: () => dispatch(onMQTTFailure('subscribe /api/ping')),
          onMessage: () => {
            venues.forEach((venue) => {
              dispatch(updatedSinceReceived({ venue_id: venue.id, timestamp: new Date().toISOString() }));
            });
          },
        });

        initializedSynch = true;
        resolve(client);
      });
    });

const addToStack: AddToStack =
  ({ venue }) =>
  async (dispatch): Promise<void> => {
    if (!venue.id) {
      return;
    }

    if (!initializedSynch) {
      try {
        await dispatch(initSynch());
      } catch (error) {
        devLog(error);
        return;
      }
    }

    devLog('addToStack', getVenueDBGId(venue));

    // const existingVenue = venues.find(({ id }) => id === venue.id);
    const isVenueInStack = venues.indexOf(venue) !== -1;
    if (isVenueInStack) {
      /**
       * Venue is already in the stack,
       * Move to the end of the list and exit
       */
      venues.splice(venues.indexOf(venue), 1);
      venues.push(venue);
      devLog('Already in stack', getVenueDBGId(venue));
      return;
    }

    dispatch(maybeStartFallbackWatchdog(venue));

    venues.push(venue);

    if (mqttClient) {
      devLog('✅ MQTT available');

      await startMQTTSubscription(dispatch, venue);
    } else {
      devLog('❌ NO MQTT');
      /**
       * If MQTT is not enabled/available, START POLLING
       */
      await startLongPollSubscription(dispatch, venue);
    }

    /**
     * FIFO Queue for synched venues
     */
    if (venues.length > MAX_VENUE_SYNC_ALLOWED) {
      dispatch(removeFromStack({ venue: venues[0] }));
    }
  };

const removeFromStack: RemoveFromStack =
  ({ venue }) =>
  (dispatch): void => {
    devLog('removeFromStack', getVenueDBGId(venue));

    if (!venue.id || !venues.find(({ id }) => id === venue.id)) {
      /**
       * Venue is NOT in the synch stack, exit
       */
      devLog('NOT in stack', getVenueDBGId(venue));
      return;
    }

    const venuesIndex = venues.indexOf(venue);
    clearInterval(watchDogByVenueId[venuesIndex].interval);
    delete watchDogByVenueId[venuesIndex];

    if (mqttClient) {
      dispatch(unsubscribeFromVenueChannel(venue));
    } else {
      stopPollingVenueSynch(venue);
    }

    venues.splice(venuesIndex, 1);
  };

export { addToStack, removeFromStack };

export const stopSynch: StopSynch = () => (): void => {
  if (mqttClient) {
    mqttClient.reset();
  } else {
    stopPolling();
  }

  /**
   * Reset local data
   */
  initializedSynch = false;
  venues.splice(0, venues.length);
  countryIDs.splice(0, countryIDs.length);
};

/**
 * Internal utils
 * subscribeToVenueCountryChannel
 * subscribeToVenueChannel
 * unsubscribeFromVenueChannel
 * startPollingVenueSynch
 */
const subscribeToVenueCountryChannel: SubscribeToVenueCountryChannel =
  (venue) =>
  (dispatch): void => {
    if (venue.country_id && !~countryIDs.indexOf(venue.country_id)) {
      const countryIndex = countryIDs.push(venue.country_id) - 1;
      mqttClient.subscribe('/api/countries/' + venue.country_id + '/sync', {
        onSuccess: () => {
          devLog('Subscribed to Country Channel', venue.country_id);
        },
        onMessage: (response) => {
          devLog('Message Received [/api/countries]', response);
          dispatch(synchDataReceived({ venue, response }));
        },
        onFailure: () => {
          /**
           * Remove country cached at `countryIndex` from country list
           */
          countryIDs.splice(countryIndex, 1);
          dispatch(onMQTTFailure('subscribeToVenueCountryChannel'));
        },
      });
    }
  };

const subscribeToVenueChannel: SubscribeToVenueChannel =
  (venue) =>
  (dispatch): Promise<void> =>
    new Promise((resolve) => {
      if (!mqttClient) return;
      const venueIndex = venues.indexOf(venue);
      devLog(`Subscribing to Venues ${getVenueDBGId(venue)}...`);
      mqttClient.subscribe(`/api/venues/${venue.id}/sync`, {
        onSuccess: () => {
          devLog('Subscribed to Venue Channel', getVenueDBGId(venue));

          resolve();
        },
        onSubscribed: () => {
          /**
           * Always call HTTP Synch to fetch the first batch of data
           */
          if (venue.id) {
            devLog(`MQTT Subscribed to ${getVenueDBGId(venue)}, Fetching first bulk trough HTTP`, getVenueDBGId(venue));
            dispatch(httpSynch({ venue_id: venue.id }));
          }
        },
        onMessage: (response) => {
          devLog(`Message Received [/api/venues] ${getVenueDBGId(venue)}`, response);
          dispatch(synchDataReceived({ venue, response }));
        },
        onFailure: () => {
          venues.splice(venueIndex, 1);
          dispatch(onMQTTFailure('subscribeToVenueChannel'));
        },
      });
    });

const unsubscribeFromVenueChannel: UnsubscribeFromVenueChannel =
  (venue) =>
  (dispatch): void => {
    if (!mqttClient) return;
    const venueIndex = venues.indexOf(venue);
    mqttClient.unsubscribe('/api/venues/' + venue.id + '/sync', {
      onSuccess: () => {
        venues.splice(venueIndex, 1);
        devLog('venue unsubscribed', getVenueDBGId(venue));
      },
      onFailure: () => {
        /**
         * Dispatch MQTT failure
         */
        dispatch(onMQTTFailure('unsubscribeFromVenueChannel'));
      },
    });
  };

const startPollingVenueSynch: ControlPollingVenueSynch =
  (venue) =>
  (dispatch): Promise<void> =>
    new Promise((resolve) => {
      devLog('start polling', getVenueDBGId(venue));
      const key = getPollingKeyFromVenue(venue);
      startPolling(key, {
        timeout: (venue.api_refresh_rate || DEFAULT_API_REFRESH_RATE) * 1000,
        callback: async () => {
          try {
            if (venue.id) {
              await dispatch(httpSynch({ venue_id: venue.id }));
            }
            return;
          } catch (error) {
            return;
          }
        },
      });
      resolve();
    });

const stopPollingVenueSynch: ControlPollingVenueSynch = (venue) => (): void => {
  devLog('stop polling', getVenueDBGId(venue));
  const key = getPollingKeyFromVenue(venue);
  stopPolling(key);
};

const onMQTTFailure: OnMQTTFailure = (msg) => (): void => {
  captureMessage('MQTT Failure: ' + msg);
};

const synchDataReceived: SynchDataReceived =
  ({ venue, response }) =>
  (dispatch): void => {
    dispatch(
      apiGetResponseAction({
        venue_id: venue ? venue.id : null,
        path: '/mqtt',
        response,
      })
    );

    if (venue) {
      dispatch(executeSynchHooks({ venue_id: venue.id, response }));
    }
  };

const getPollingKeyFromVenue = (venue: Venue): string => 'http-synch-' + venue.id;

const devLog = devLogger();
const maybeStartFallbackWatchdog: ControlFallbackWatchdog = (venue: Venue) => (dispatch: ActionDispatch) => {
  const venueId = venue.id as number;
  if (!(venueId in watchDogByVenueId)) {
    devLog(`🐶 Starting Watchdog for ${getVenueDBGId(venue)}`);
    watchDogByVenueId[venueId] = {
      isUsingFallback: false,
      interval: setInterval(() => {
        if (!mqttClient || (mqttClient && !mqttClient.isConnected())) {
          if (watchDogByVenueId[venueId].isUsingFallback) return;

          devLog(`🐶 Watchdog switching Fallback 🟢 ON for ${getVenueDBGId(venue)}`);
          startLongPollSubscription(dispatch, venue);
          watchDogByVenueId[venueId].isUsingFallback = true;
        } else {
          if (!watchDogByVenueId[venueId].isUsingFallback) return;

          devLog(`🐶 Watchdog switching Fallback 🔴 OFF for ${getVenueDBGId(venue)}`);
          dispatch(stopPollingVenueSynch(venue));
          startMQTTSubscription(dispatch, venue);
          watchDogByVenueId[venueId].isUsingFallback = false;
        }
      }, 1000),
    };
  }
};

async function startLongPollSubscription(dispatch: ActionDispatch, venue: Venue) {
  await dispatch(startPollingVenueSynch(venue));
}

async function startMQTTSubscription(dispatch: ActionDispatch, venue: Venue) {
  dispatch(subscribeToVenueCountryChannel(venue));
  await dispatch(subscribeToVenueChannel(venue));
}
