import { useCallback, useEffect, useState } from 'react';
import * as Beep from './beep.mp3';

interface BufferCharacter {
  time: number;
  char: string;
}

interface BarcodeScannerConfig {
  /** Time to wait from last character to then trigger an evaluation of the buffer. */
  readonly timeToEvaluate?: number;
  /** Average time between characters in milliseconds. Used to determine if input is from keyboard or a scanner. Defaults to 50ms.*/
  readonly averageWaitTime?: number;
  /** Character that barcode scanner prefixes input with.*/
  readonly startCharacter?: ReadonlyArray<string>;
  /** Character that barcode scanner suffixes input with. Defaults to line return.*/
  readonly endCharacter?: ReadonlyArray<string>;
  /** Callback to use on complete scan input.*/
  readonly onComplete: (code: string) => void;
  /** Callback to use on error. */
  readonly onError?: (error: string) => void;
  /** Minimum length a scanned code should be. Defaults to 0.*/
  readonly minLength?: number;
  /** Ignore scan input if this node is focsed.*/
  readonly ignoreIfFocusOn?: Node;
  /** Stop propagation on keydown event. Defaults to false.*/
  readonly stopPropagation?: boolean;
  /** Prevent default on keydown event. Defaults to false.*/
  readonly preventDefault?: boolean;
  /** Emit an acoustic signal. Defaults to true.*/
  readonly acousticSignal?: boolean;
}

/**
 * @info
 * https://it.wikipedia.org/wiki/Global_Trade_Item_Number
 */
export const useBarcodeScanner = ({
  timeToEvaluate = 100,
  averageWaitTime = 50,
  startCharacter = [],
  endCharacter = ['escape', 'enter'],
  onComplete,
  onError,
  minLength = 1,
  ignoreIfFocusOn,
  stopPropagation = false,
  preventDefault = false,
  acousticSignal = false,
}: BarcodeScannerConfig): void => {
  const [buffer, setBuffer] = useState<ReadonlyArray<BufferCharacter>>([]);
  const [timeoutState, setTimeoutState] = useState<number>(0);

  const clearBuffer = (): void => {
    setBuffer([]);
  };
  const evaluateBuffer = useCallback((): void => {
    clearTimeout(timeoutState);

    const sum = buffer
      .map(({ time }, k, arr) => (k > 0 ? time - arr[k - 1].time : 0))
      .slice(1)
      .reduce((total, delta) => total + delta, 0);

    const avg = sum / (buffer.length - 1);

    const code = buffer
      .slice(startCharacter.length > 0 ? 1 : 0)
      .map(({ char }) => char)
      .join('');

    const bufferLength = buffer.slice(startCharacter.length > 0 ? 1 : 0).length;

    if (avg <= averageWaitTime && bufferLength >= minLength) {
      if (isNaN(code as unknown as number)) return onError?.(code);

      if (acousticSignal) {
        const audio = new Audio(Beep);
        audio.play();
      }
      onComplete(code);
    } else {
      if (avg <= averageWaitTime && !!onError) onError(code);
    }
    clearBuffer();
  }, [acousticSignal, averageWaitTime, buffer, minLength, onComplete, onError, startCharacter.length, timeoutState]);

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.currentTarget === ignoreIfFocusOn) return; // REF ?

      const kbdKey = event.key.toLowerCase();
      const isLastChar = endCharacter.includes(kbdKey);

      if (isLastChar) evaluateBuffer();

      if (!isLastChar && (buffer.length > 0 || startCharacter.includes(kbdKey) || startCharacter.length === 0)) {
        clearTimeout(timeoutState);
        setTimeoutState(setTimeout(evaluateBuffer, timeToEvaluate) as unknown as number);
        setBuffer((prev) => [...prev, { time: performance.now(), char: kbdKey }]);
      }

      if (stopPropagation) event.stopPropagation();
      if (preventDefault) event.preventDefault();
    },
    [
      ignoreIfFocusOn,
      endCharacter,
      buffer,
      startCharacter,
      stopPropagation,
      preventDefault,
      evaluateBuffer,
      timeoutState,
      timeToEvaluate,
    ]
  );

  useEffect(
    () => (): void => {
      clearTimeout(timeoutState);
    },
    [timeoutState]
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown as EventListener);
    return (): void => {
      document.removeEventListener('keydown', onKeyDown as EventListener);
    };
  }, [onKeyDown]);
};
