import { styled, css } from 'styles';

const getMaxWidth = (size: 'small' | 'large' | number | undefined): number => {
  switch (size) {
    case 'small':
      return 380;
    case 'large':
      return 890;
    case undefined:
      return 380;
    default:
      return size;
  }
};

const DEFAULT_SPACING = 30;

export const ModalOverlay = styled('div')`
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  background-color: rgba(0, 0, 0, 0.4);
`;

type ModalWrapperProps = {
  size?: 'small' | 'large' | number;
  fullHeight?: boolean;
  isFullscreen?: boolean;
  height?: string;
  centered?: boolean;
  maxHeight?: number;
  defaultSpacing?: number;
};

export const ModalWrapper = styled('div')<ModalWrapperProps>(
  ({
    theme: { media },
    size,
    fullHeight,
    isFullscreen,
    centered,
    maxHeight,
    height,
    defaultSpacing = DEFAULT_SPACING,
  }) => css`
    position: relative;
    margin: ${defaultSpacing}px auto;
    width: 100%;
    max-width: ${getMaxWidth(size)}px;
    ${maxHeight && `max-height: ${maxHeight}px`};

    ${fullHeight &&
    `
        height: calc(100% - ${2 * defaultSpacing}px);
        overflow-y: auto;

        .Card {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      `}

    ${isFullscreen &&
    `
        height: 100%;
        max-width: 100%;
        background-color: white;
        margin: 0;
      `}

    ${media.tablet`

        ${
          centered &&
          `
          top: 50%;
          transform: translateY(-50%);
          margin: auto;
        `
        }
      `};
    ${height && `height: ${height}`};
  `
);
