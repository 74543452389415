import { cloneElement, Children, Component, isValidElement, ReactNode } from 'react';
import StyledRadioGroup from './theme';

export interface RadioGroupProps<V extends string = string> {
  value: V | null;
  direction: string;
  onChange: (value: V, order: number | null) => void;
  flex?: boolean;
  disabled?: boolean;
}

interface RadioGroupState<V extends string = string> {
  selected: V | null;
  order: number | null;
}

class RadioGroup<V extends string = string> extends Component<RadioGroupProps<V>, RadioGroupState<V>> {
  static defaultProps: Partial<RadioGroupProps> = {
    value: '',
    direction: 'row',
    onChange: () => {},
  };

  state: RadioGroupState<V> = {
    selected: this.props.value,
    order: null,
  };
  static displayName: string;

  componentDidUpdate(prevProps: { value: V | null }): void {
    const { value } = this.props;
    const { selected, order } = this.state;

    if (!value) return;

    if (prevProps.value !== value && selected !== value) {
      this.handleChange(value, order);
    }
  }

  handleChange = (value: V, order: number | null): void => {
    this.setState({ selected: value, order }, () => this.props.onChange(value, order));
  };

  renderChildren = (): ReactNode => {
    const { selected } = this.state;
    const { children, disabled } = this.props;
    return Children.map(children, (child, index) => {
      if (!isValidElement(child)) return null;
      return cloneElement(child, {
        ...child.props,
        checked: selected === child?.props.value,
        onChange: this.handleChange,
        group: true,
        order: index,
        disabled: disabled || child.props.disabled,
      });
    });
  };

  render(): JSX.Element {
    const { onChange: _, ...restOfProps } = this.props;
    return (
      <StyledRadioGroup className="RadioGroup" {...restOfProps}>
        {this.renderChildren()}
      </StyledRadioGroup>
    );
  }
}

RadioGroup.displayName = 'Radio.Group';

export default RadioGroup;
