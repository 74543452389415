import {
  Children,
  cloneElement,
  FunctionComponent,
  ReactElement,
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldSelect, Icon, List, Option, Selected, Toggle } from './theme';
import { useWindowListener } from 'utils/hooks';
import { stringToTestId } from 'utils/tests/stringToTestId';

type SelectProps = {
  value?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (v: any) => void;
  textRight?: boolean;
  readOnly?: boolean;
  error?: boolean;
  disabled?: boolean;
  size?: string;
  name?: string;
  children: ReactElement<OptionProps>[] | ReactElement<OptionProps>;
  collapsed?: boolean;
  width?: number;
  separator?: 'left' | 'right';
  className?: string;
  testId?: string;
  testClass?: string;
};

type OptionProps = {
  children: ReactElement | string | number | ReactText[] | ReactText | (string | ReactElement)[];
  value: string | number | null;
  selected?: boolean;
  disabled?: boolean;
  onChange?: () => void;
  'data-testid'?: string;
};

type IconProps = {
  open: boolean;
};

type SelectComponent = FunctionComponent<SelectProps> & {
  Option: SelectOptionComponent;
  Icon: FunctionComponent<IconProps>;
};

type SelectOptionComponent = FunctionComponent<OptionProps>;

/**
 *@deprecated
 *
 *@example
 *```ts
 * // use this instead
 * import { Select } from 'ui-elements/form'
 *```
 */
const Select: SelectComponent = ({
  value,
  onChange = (): void => {},
  size = '',
  disabled = false,
  readOnly,
  textRight,
  error,
  name,
  children,
  collapsed,
  width,
  separator,
  className,
  testId = 'custom-select',
  testClass = 'custom-select',
}: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState<OptionProps['children'] | null>(null);
  const [open, setOpen] = useState(false);

  const onChangeHandler = useCallback(
    (optionValue) => (): void => {
      if (onChange) {
        onChange(optionValue as never);
      }
    },
    [onChange]
  );

  const options = useMemo(
    () =>
      Children.map(children, (option) => {
        if (!option) {
          return;
        }
        const { value: optionValue, children: optionChildren, 'data-testid': optionTestId } = option.props;
        const selected = optionValue === value;
        if (selected) {
          setSelectedOption(optionChildren);
        }
        return cloneElement(option, {
          onChange: onChangeHandler(optionValue),
          'data-testid': optionTestId,
          selected,
        });
      }),
    [children, value, onChangeHandler]
  );

  const toggleHandler = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const closeHandler = useCallback(() => {
    if (open) {
      setOpen(false);
    }
  }, [open]);

  useWindowListener('click', closeHandler);

  useEffect(() => {
    if (!value && value !== 0) {
      setSelectedOption(null);
    }
  }, [value]);

  return (
    <FieldSelect
      className={`Select ${open ? 'isOpen' : 'isClose'} ${separator ? `${separator}Separator` : ''}${
        className ? ` ${className}` : ''
      }`}
      onClick={readOnly || disabled ? null : toggleHandler}
      disabled={disabled}
      size={size}
      textRight={textRight}
      error={error}
      collapsed={collapsed}
      width={width}
      data-testid={stringToTestId(testId)}
      data-testclass={testClass}
    >
      <input type="hidden" name={name} value={value} data-testid={stringToTestId(`${testId}-input`)} />
      <Toggle>
        <Selected className="Selected" value={value} data-testid={stringToTestId(`${testId}-selected-value`)}>
          {selectedOption}
        </Selected>
        <Icon className="Icon" open={open} type="select" size={11} />
        {open && <List className="Select__List">{options}</List>}
      </Toggle>
    </FieldSelect>
  );
};

const SelectOption: SelectOptionComponent = ({
  children,
  selected,
  onChange,
  disabled = false,
  value,
}: OptionProps): ReactElement => {
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (ref && ref.current && selected) {
      try {
        ref.current.scrollIntoView({
          behavior: 'auto',
          block: 'center',
        });
      } catch (e) {
        ref.current.scrollIntoView(false);
      }
    }
  }, [selected]);

  return (
    <div ref={ref} data-testid={stringToTestId(`select-option-list`)}>
      <Option
        selected={selected}
        onClick={onChange}
        disabled={disabled}
        data-testid={stringToTestId(`select-option-${value}`)}
      >
        {children}
      </Option>
    </div>
  );
};

Select.Option = SelectOption;

const SelectIcon = (props: IconProps): ReactElement => <Icon {...props} />;
Select.Icon = SelectIcon;

export default Select;
