import { isElectron, getOperativeSystemType, getRelease } from './electron';
import { compareVersion } from 'utils/compareVersion';
import { PRINTER_TYPE, PrinterType } from 'models';

type EvaluateDeviceAndOSResult = {
  isOperativeSystemSupported: boolean;
  isDeviceSupported: boolean;
};

export type OperativeSystemInfo = {
  name: string;
  minVersion?: string;
  maxVersion?: string;
};

function* isSupportedOS(supportedOS: OperativeSystemInfo[]): IterableIterator<boolean> {
  for (const os of supportedOS) {
    const currentOS = getOperativeSystemType();
    const currentRelease = getRelease();

    if (os.name === currentOS) {
      const checkMinVersion = os.minVersion
        ? currentRelease
          ? compareVersion(currentRelease, os.minVersion) >= 0
          : false
        : true;

      const checkMaxVersion = os.maxVersion
        ? currentRelease
          ? compareVersion(currentRelease, os.maxVersion) <= 0
          : false
        : true;

      yield checkMaxVersion && checkMinVersion;
    }
  }

  yield false;
}

function* isSupportedDevice(supportedDevices: string[]): IterableIterator<boolean> {
  for (const device of supportedDevices) {
    if (device === 'ELECTRON') yield isElectron();

    // Here can be added other supported devices/platforms
  }

  yield false;
}

function evaluateDeviceAndOS(
  supportedDevices?: string[],
  supportedOS?: OperativeSystemInfo[]
): EvaluateDeviceAndOSResult {
  let isOperativeSystemSupported = false;
  let isDeviceSupported = false;

  if (supportedDevices && supportedDevices.length !== 0) {
    isDeviceSupported = isSupportedDevice(supportedDevices).next().value;
  } else {
    isDeviceSupported = typeof supportedDevices === 'undefined';
  }

  if (supportedOS && supportedOS.length !== 0) {
    isOperativeSystemSupported = isSupportedOS(supportedOS).next().value;
  } else {
    isOperativeSystemSupported = typeof supportedOS === 'undefined';
  }

  return { isOperativeSystemSupported, isDeviceSupported };
}

const isPrinterTypeAllowedByClient = (printerType: PrinterType): boolean => {
  switch (printerType) {
    case PRINTER_TYPE.FISCAL_GR: {
      /**
       * OS Types and Releases: https://en.wikipedia.org/wiki/Uname#Examples
       */
      const permissionEvaluation = evaluateDeviceAndOS(['ELECTRON'], [{ name: 'Windows_NT' }]);
      const { isDeviceSupported, isOperativeSystemSupported } = permissionEvaluation;
      return isDeviceSupported && isOperativeSystemSupported;
    }
    default:
      return true;
  }
};

export default isPrinterTypeAllowedByClient;
export { evaluateDeviceAndOS };
