import { useEffect, FunctionComponent, ReactNode, ReactElement } from 'react';
import { withRouter } from 'react-router-dom';
import { History } from 'history';

interface Props {
  children?: ReactNode;
  history: History;
}

const ScrollToTopProvider: FunctionComponent<Props> = ({ history, children }) => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return (): void => {
      unlisten();
    };
  }, [history]);

  return children as ReactElement;
};

export default withRouter(ScrollToTopProvider);
