import { MODALS_OPEN, MODALS_CLOSE } from 'actionTypes';

export const modals = (state = [], action) => {
  switch (action.type) {
    case MODALS_CLOSE:
      if (!action.id) {
        return state;
      }
      return [...state.filter((modal) => modal.type !== action.id)];

    case MODALS_OPEN:
      if (!action.id) {
        return state;
      }
      return [...state.filter((modal) => modal.type !== action.id), { type: action.id, config: action.config }];

    default:
      return state;
  }
};
