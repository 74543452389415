import { FunctionComponent, ReactNode } from 'react';
import { OpacityLayerWrapper, OpacityLayerWrapperProps } from './theme';

type OpacityLayerProps = Partial<OpacityLayerWrapperProps> & {
  children: ReactNode;
  onClick?: () => void;
  showCursor?: boolean;
};

const OpacityLayer: FunctionComponent<OpacityLayerProps> = ({
  display,
  alignItems,
  opacity = 0.6,
  showCursor = false,
  isDisabled = false,
  onClick = (): void => {},
  children,
}) => (
  <OpacityLayerWrapper
    display={display}
    alignItems={alignItems}
    isDisabled={isDisabled}
    opacity={opacity}
    onClick={onClick}
    showCursor={showCursor}
  >
    {children}
  </OpacityLayerWrapper>
);

export default OpacityLayer;
