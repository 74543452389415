import { VENUE_LOCAL_CUSTOMER_HIDE_TERMS } from 'actionTypes';

// hideTerms = {
//   17265712635: {
//     hide_until: 1542277542310
//   }
// }
// // in selectors you check hide_until to show terms and conditions popup

export const hideTerms = (state = {}, action) => {
  switch (action.type) {
    case VENUE_LOCAL_CUSTOMER_HIDE_TERMS:
      if (!action.id) {
        return state;
      }
      return {
        ...state,
        [action.id]: {
          hide_until: new Date().getTime() + 24 * 60 * 60 * 1000,
        },
      };

    default:
      return state;
  }
};

export const hideTermsByVenue = (state = {}, action) => {
  switch (action.type) {
    case VENUE_LOCAL_CUSTOMER_HIDE_TERMS:
      if (!action.venue_id || !action.id) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: hideTerms(state[action.venue_id], action),
      };

    default:
      return state;
  }
};
