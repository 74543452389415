import { styled, css } from 'styles';
import Field, { fieldSizes } from '../Field/theme';
import { withTheme } from '../Context';

export const FieldShape = withTheme(
  styled(Field)(
    ({ theme: { sizes }, size }) => css`
      box-sizing: border-box;
      background-color: #ffffff;
      padding: ${((fieldSizes.height[size] || fieldSizes.height.default) - 22) / 2}px ${sizes.padding}px;
      line-height: 20px;

      > .Text,
      > .Icon {
        height: 20px;
        line-height: 20px;
      }

      > .Icon:first-of-type {
        margin-right: 10px;
      }

      > .Icon:last-child {
        margin-left: 10px;
      }
    `
  )
);
