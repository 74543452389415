export enum VENUTE_TRANSFERS_STATUS {
  DISPOSED = 'disposed',
  NOTIFIED = 'notified',
}

export interface VenueTransfers {
  id: number;
  code: string;
  disposable_amount: number;
  disposed_at: string;
  from_time: string;
  notified_at?: string | null;
  pdf_url?: string | null;
  pending_notitications: boolean;
  status: VENUTE_TRANSFERS_STATUS;
  to_time: string;
}
