import { useEffect, useRef } from 'react';
import { modalOpen } from 'actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import { ActionDispatch } from 'store';
import { useFlag } from 'feature-flags/unleash';
import { ACTIVE_FLAGS } from 'feature-flags/ACTIVE_FLAGS';
import { getModals } from 'selectors/getModals';
import { useRemote } from 'utils/useRemote';
import { API } from 'API';
import { getPermissionChecker } from 'selectors/venue/getVenuePermissions';
import { ACL_PERMISSION, ACL_PERMISSIONS_VALUES } from 'models';
import { useQueryString } from 'utils/hooks/useQueryString';
import { useLocation } from 'react-router';
import { KYC_VERIFICATION_REFRESH_URL_PATHNAME, KYC_VERIFICATION_REQUESTED } from './kycConstants';

const ONE_HOUR = 3600 * 1000;
const FOUR_HOURS = 4 * ONE_HOUR;

const FLASH_MESSAGE_ID = 'kyc-verification-flash-message';
const MODAL_ID = 'kyc-verification';

const pagesWithoutKycMap = ['print_fiche'];

export const useCheckKyc = (): void => {
  const { pathname } = useLocation();
  const page = (pathname || '').split('/').reverse()[0];
  const checkPermission = useSelector(getPermissionChecker);
  const isCurrentUserBillingOwner = checkPermission(ACL_PERMISSION.ACCOUNT_BILLING) === ACL_PERMISSIONS_VALUES.FULL;
  const dispatch: ActionDispatch = useDispatch();
  const kycFFEnabled = useFlag(ACTIVE_FLAGS.TEF_666);
  const modals = useSelector(getModals);
  const [KYCVerificationCheckQuery, { data: kycVerificationData, loading: kycVerificationLoading }] = useRemote(
    API.KYC_VERIFICATION._CHECK
  );
  const bannerInterval = useRef<NodeJS.Timeout | undefined>();
  const lastCheckedDate = useRef<number>(new Date().getTime());
  const query = useQueryString();
  const alreadyRequestedVerification =
    query.get(KYC_VERIFICATION_REQUESTED) !== null || pathname === KYC_VERIFICATION_REFRESH_URL_PATHNAME;
  const shouldShowPopup =
    !pagesWithoutKycMap.includes(page) &&
    (kycVerificationData?.data.kyc_verification_needed ||
      kycVerificationData?.data.bank_account_synchronized === false);

  const showKycFlashMessage = (): void => {
    clearInterval(bannerInterval.current);
    if (modals?.some((m) => m.type === FLASH_MESSAGE_ID)) return;
    dispatch(
      modalOpen({
        id: FLASH_MESSAGE_ID,
        config: {
          component: 'payments/KYCAlertFlashMessage',
          onConfirm: startFlashMessageInterval,
          onClose: startFlashMessageInterval,
          bankAccountSynchronisationNeeded:
            !kycVerificationData?.data.kyc_verification_needed &&
            kycVerificationData?.data?.bank_account_synchronized === false,
          isNewSupplier: kycVerificationData?.data?.is_new_supplier,
        },
      })
    );
  };

  const startFlashMessageInterval = (): void => {
    bannerInterval.current = setInterval(() => {
      const currentTimestamp = new Date().getTime();
      if (currentTimestamp >= lastCheckedDate.current + FOUR_HOURS) {
        lastCheckedDate.current = currentTimestamp;
        KYCVerificationCheckQuery({ variables: {} });
      }
    }, 5000);
  };

  const showKycModal = (): void => {
    if (alreadyRequestedVerification) {
      return startFlashMessageInterval();
    }
    dispatch(
      modalOpen({
        id: MODAL_ID,
        config: {
          component: 'payments/KYCAlert',
          onConfirm: startFlashMessageInterval,
          onClose: startFlashMessageInterval,
          bankAccountSynchronisationNeeded:
            !kycVerificationData?.data.kyc_verification_needed &&
            kycVerificationData?.data?.bank_account_synchronized === false,
          isNewSupplier: kycVerificationData?.data?.is_new_supplier,
        },
      })
    );
  };

  useEffect(() => {
    if (!shouldShowPopup || kycVerificationLoading) return;
    return bannerInterval.current !== undefined ? showKycFlashMessage() : showKycModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowPopup, kycVerificationLoading]);

  useEffect(() => {
    if (!kycFFEnabled || !isCurrentUserBillingOwner) return;
    KYCVerificationCheckQuery({ variables: {} });

    return () => {
      clearInterval(bannerInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycFFEnabled, isCurrentUserBillingOwner]);
};
