import { isTestDataEnabled } from './isTestDataEnabled';
import { stringToTestId } from './stringToTestId';

export type TestProperties<TestData extends object> = {
  testId?: string;
  testClass?: string;
  testData?: TestData;
};
export type TestAttributes = {
  'data-testid'?: string;
  'data-testclass'?: string;
  'data-testdata'?: string;
};

export const maybeGetTestAttributes = <TestData extends object>(
  testProperties: TestProperties<TestData>
): TestAttributes => {
  if (!isTestDataEnabled()) return {};

  const testAttributes: TestAttributes = {};
  if ('testId' in testProperties && !!testProperties.testId)
    testAttributes['data-testid'] = stringToTestId(testProperties.testId);
  if ('testClass' in testProperties && !!testProperties.testClass)
    testAttributes['data-testclass'] = stringToTestId(testProperties.testClass);
  if ('testData' in testProperties && !!testProperties.testData)
    testAttributes['data-testdata'] = JSON.stringify(testProperties.testData);

  return testAttributes;
};
