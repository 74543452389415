import PropTypes from 'prop-types';
import { Component, createRef } from 'react';

class Expandible extends Component {
  state = {
    did_collapse: true,
  };

  myRef = createRef();

  componentDidMount() {
    if (this.props.onInit) {
      this.props.onInit({ toggle: this.toggle });
    }
  }

  onStateChange() {
    if (this.props.onStateChange) {
      this.props.onStateChange({
        toggle: this.toggle,
        expanded: this.state.expanded,
        will_collapse: this.state.will_collapse,
        is_collapsing: this.state.is_collapsing,
        did_collapse: this.state.did_collapse,
        will_expand: this.state.will_expand,
        is_expanding: this.state.is_expanding,
        did_expand: this.state.did_expand,
      });
    }
  }

  expand({ child_left, child_right, child_top, child_bottom }) {
    this.setState(
      {
        expanded: true,
        will_expand: false,
        is_expanding: true,
        did_expand: false,

        child_left,
        child_right,
        child_top,
        child_bottom,

        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      () => {
        this.onStateChange();
        setTimeout(() => {
          this.setState(
            {
              expanded: true,
              will_expand: false,
              is_expanding: false,
              did_expand: true,
            },
            this.onStateChange
          );
        }, this.props.transition_duration);
      }
    );
  }

  collapse({ child_left, child_right, child_top, child_bottom }) {
    this.setState(
      {
        expanded: false,
        will_collapse: false,
        is_collapsing: true,
        did_collapse: false,

        child_left,
        child_right,
        child_top,
        child_bottom,

        left: child_left,
        top: child_top,
        right: child_right,
        bottom: child_bottom,
      },
      () => {
        this.onStateChange();
        setTimeout(() => {
          this.setState(
            {
              expanded: false,
              will_collapse: false,
              is_collapsing: false,
              did_collapse: true,
            },
            this.onStateChange
          );
        }, this.props.transition_duration);
      }
    );
  }

  transition() {
    if (!this.props.parentRef.current || !this.myRef.current) {
      return;
    }

    const parentRect = this.props.parentRef.current.getBoundingClientRect();
    const thisRect = this.myRef.current.getBoundingClientRect();

    const child_left = thisRect.left - parentRect.left;
    const child_top = thisRect.top - parentRect.top;
    const child_right = parentRect.width - thisRect.width - child_left;
    const child_bottom = parentRect.height - thisRect.height - child_top;

    const { did_collapse, did_expand } = this.state;

    if (did_collapse) {
      this.setState(
        {
          expanded: false,
          will_collapse: false,
          is_collapsing: false,
          did_collapse: false,
          will_expand: true,
          is_expanding: false,
          did_expand: false,

          child_left,
          child_right,
          child_top,
          child_bottom,

          left: child_left,
          top: child_top,
          right: child_right,
          bottom: child_bottom,
        },
        () => {
          this.onStateChange();
          setTimeout(() => {
            this.expand({
              child_left,
              child_right,
              child_top,
              child_bottom,
            });
          }, 10);
        }
      );
    } else if (did_expand) {
      this.setState(
        {
          expanded: true,
          will_collapse: true,
          is_collapsing: false,
          did_collapse: false,
          will_expand: false,
          is_expanding: false,
          did_expand: false,
        },
        () => {
          this.onStateChange();
          setTimeout(() => {
            this.collapse({
              child_left,
              child_right,
              child_top,
              child_bottom,
            });
          }, 10);
        }
      );
    }
  }

  toggle = () => {
    if (!this.props.parentRef.current || !this.myRef.current) {
      return;
    }

    this.transition();
  };

  renderExpanded() {
    return this.props.renderExpanded({
      ...this.state,
      toggle: this.toggle,
    });
  }

  className() {
    let className = 'Expandible';

    if (this.state.expanded) {
      className += ' Expandible-expanded';
    }

    if (this.state.will_expand) {
      className += ' Expandible-will_expand';
    }
    if (this.state.is_expanding) {
      className += ' Expandible-is_expanding';
    }
    if (this.state.did_expand) {
      className += ' Expandible-did_expand';
    }
    if (this.state.will_collapse) {
      className += ' Expandible-will_collapse';
    }
    if (this.state.is_collapsing) {
      className += ' Expandible-is_collapsing';
    }
    if (this.state.did_collapse) {
      className += ' Expandible-did_collapse';
    }

    return className;
  }

  render() {
    return (
      <div ref={this.myRef} className={this.className()}>
        {this.renderExpanded()}
        {this.props.children({ toggle: this.toggle })}
      </div>
    );
  }
}

Expandible.propTypes = {
  expanded: PropTypes.bool,
  onStateChange: PropTypes.func,
  transition_duration: PropTypes.number,
  renderExpanded: PropTypes.func.isRequired,
};

Expandible.defaultProps = {
  transition_duration: 300,
};

export default Expandible;
