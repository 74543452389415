import { styled, css } from 'styles';
import Field, { fieldSizes } from 'components/core/Field/theme';
import { withTheme } from '../Context';

export const FieldCheckbox = styled(Field)(
  ({ size, disabled }) => css`
    display: flex;
    height: ${fieldSizes.height[size] || fieldSizes.height.default}px;
    border: 0;
    border-radius: 0;
    margin-left: 10px;
    ${disabled &&
    css`
      background-color: transparent;
      border-color: transparent;
    `};
  `
);

export const FieldCheckboxLabel = withTheme(
  styled('div')(
    ({ theme: { colors, sizes }, size }) => css`
      height: ${fieldSizes.height[size] || fieldSizes.height.default}px;
      flex: 1;
      margin-right: 10px;

      > .Text:first-of-type:last-child {
        line-height: ${fieldSizes.height[size] || fieldSizes.height.default}px;
      }
    `
  )
);

// LEGACY: Ensure a 58px * 32px checkbox (width * height)
const CHECKBOX_WIDTH_ADJUSTMENT = 6;

export const FieldCheckboxInput = withTheme(
  styled('div')(
    ({ theme: { colors, utils, sizes }, size, checked, toggle }) => css`
      box-sizing: border-box;
      position: relative;
      display: block;
      flex: none;

      height: ${fieldSizes.height[size] || fieldSizes.height.default}px;

      ${toggle
        ? css`
            transition: ${utils.transition};
            width: ${(fieldSizes.height[size] || fieldSizes.height.default) +
            sizes.padding +
            CHECKBOX_WIDTH_ADJUSTMENT}px;
            border-radius: ${fieldSizes.height[size] || fieldSizes.height.default}px;
            background-color: ${checked ? colors.blue : colors.inputBorder};
          `
        : css`
            width: ${fieldSizes.height[size] || fieldSizes.height.default}px;
            border-radius: 100%;
            border: 1px solid ${colors.inputBorder};
            background-color: ${colors.bgWhite};
          `};

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        border-radius: 100%;
        transition: ${utils.transition};
        transform-origin: 0 0;

        ${toggle
          ? css`
              box-sizing: border-box;
              width: ${(fieldSizes.height[size] || fieldSizes.height.default) - 8}px;
              height: ${(fieldSizes.height[size] || fieldSizes.height.default) - 8}px;
              transform: translateY(-50%);
              background: ${colors.bgWhite};
              left: ${4 + (checked ? sizes.padding + CHECKBOX_WIDTH_ADJUSTMENT : 0)}px;
            `
          : css`
              left: 50%;
              width: 50%;
              height: 50%;
              background: ${colors.blue};
              transform: ${checked ? 'scale(1) translate(-50%, -50%)' : 'scale(0) translate(-50%, -50%)'};
            `};
      }
    `
  )
);
