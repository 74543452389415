const supportedPrefixes = ['feature', 'release', 'fix', 'hotfix', 'tag', 'commit', 'chore'];

/**
 * This function adapt `basename` value matching against the prefixes
 * supported by the infrastructure on envx-manager.uala.com.
 * Outside that specific ENV, everything will be redirect to root "/"
 */
const getBaseFromPathName = (pathname: string): string => {
  const pieces = pathname.split('/');
  if (pieces[1] === 'version') {
    for (let index = 0; index < supportedPrefixes.length; index++) {
      const type = supportedPrefixes[index];
      if (pieces[2] === type) {
        return '/' + pieces[1] + '/' + pieces[2] + '/' + pieces[3];
      }
    }
  }
  return '/';
};

export default getBaseFromPathName;
