/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { VenueProductEditQuery, VenueProductEditResponse, VenueProductEditPayload } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const EDIT =
  ({ variables: { product_id, ...body } }: V<VenueProductEditQuery & VenueProductEditPayload>) =>
  (): Promise<VenueProductEditResponse> =>
    Http()
      .put<VenueProductEditResponse, VenueProductEditQuery, VenueProductEditPayload>(
        ENDPOINT.VENUE_PRODUCTS._EDIT(product_id)
      )({
        searchParams: { product_id },
        body: { ...body },
      })
      .then(maybeResponseToJSON);
