import { SET_AGENDA_SETTINGS } from 'actionTypes';
import { AgendaSettings } from 'models';
import { AgendaSettingsAction } from 'actions/agendaSettings';

/**
 * This is a copy of the context of AgendaContext,
 * in this way we can store and keep the same agenda settings(eg. view_mode, template)
 * throughout the whole app even when the user leaves the agenda page.
 */

const AGENDA_Y_OFFSET = 60;

export const defaultAgendaSettings: AgendaSettings = {
  view_mode: 'daily',
  template: process.env.REACT_APP_AGENDA_TEMPLATE || 'calendar',
  cell_height: 37,
  cell_border: 1,
  /**
   * By default margin_left is the same as y_axis_width.
   * It refers to the space from the left side of the viewport to the start of the agenda grid.
   * Consider to add the sidebar width to it when the user is multi venue.
   */
  margin_left: AGENDA_Y_OFFSET,
  // y_axis_width is the width of the y-axis(timeline)
  y_axis_width: AGENDA_Y_OFFSET,
  column_offset: 0,

  staffMemberIdByVenue: {},
  preferredStaffMemberIdsByVenue: {},
};

export const agendaSettings = (
  state: Partial<AgendaSettings> = defaultAgendaSettings,
  action: AgendaSettingsAction
): Partial<AgendaSettings> => {
  switch (action.type) {
    case SET_AGENDA_SETTINGS:
      return { ...state, ...action.context };
    default:
      return state;
  }
};
