import { useEffect } from 'react';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { fetchVenueWizards } from 'actions/venueWizards';
import { getCurrentUserValidVenueWizardId } from 'selectors/getCurrentUser';
import { getVenueWizardsById } from 'selectors/venueWizards/getVenueWizards';

import WizardAssistant from 'components/lib/wizard-assistant';

const WizardAssistantContainer = ({ venue_wizard_id, venue_wizard, fetchVenueWizards, pushTo }) => {
  const { staff_members_incomplete, timetables_incomplete, treatments_incomplete } = venue_wizard;

  useEffect(() => {
    if (venue_wizard_id) {
      fetchVenueWizards();
    }
  }, [fetchVenueWizards, venue_wizard_id]);

  if (!venue_wizard_id) {
    return null;
  }

  if (!staff_members_incomplete && !timetables_incomplete && !treatments_incomplete) {
    return null;
  }

  return (
    <WizardAssistant
      timetables_incomplete={timetables_incomplete}
      staff_members_incomplete={staff_members_incomplete}
      treatments_incomplete={treatments_incomplete}
      onTreatmentsSet={() => pushTo('/treatments')}
      onTimeTablesSet={() => pushTo('/timetables')}
      onStaffSet={() => pushTo('/staff_members')}
    />
  );
};

const mapStateToProps = () =>
  createSelector(getCurrentUserValidVenueWizardId, getVenueWizardsById, (venue_wizard_id, venueWizardsById) => {
    return {
      venue_wizard_id,
      venue_wizard: (venue_wizard_id && venueWizardsById && venueWizardsById[venue_wizard_id]) || {},
    };
  });

const dispatchToProps = {
  fetchVenueWizards,
  pushTo: push,
};

export default connect(mapStateToProps, dispatchToProps)(WizardAssistantContainer);
