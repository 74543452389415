/**
 * @deprecated For future implementations please use successfulResponseToJSON instead. This function only returns response data for responses with HTTP code 200 and 201, which doesn't cover all successful HTTP codes. https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 */
export const maybeResponseToJSON = <A extends Response>(response: A): Promise<A> | A => {
  if (response.status !== 200 && response.status !== 201) return response;
  return response.json();
};

export const successfulResponseToJSON = <A extends Response>(response: A): Promise<A> | A => {
  if (response.status < 200 || response.status > 299) return response;
  return response.json();
};

export const responseToJSON = <A extends Response>(response: A): Promise<A> => response.json();

export function getHeaderEntries<A extends Response>(response: A): Record<string, string> {
  const headerEntries: Record<string, string> = {};

  for (const header of response.headers.entries()) {
    const [key, value] = header;
    headerEntries[key] = value;
  }

  return headerEntries;
}

/**
 * @deprecated For future implementations please use successfulExternalResponseToJSON instead. This function only returns response data for responses with HTTP code 200 and 201, which doesn't cover all successful HTTP codes. https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
 * @description This is a compatibility layer for external APIs that are have no success field in the response body, since success is needed to handle the response correctly in `useRemote`
 */
export const maybeExternalAPIResponseToJSON = async <A extends Response>(response: A): Promise<A> => {
  if (response.status !== 200 && response.status !== 201) return response;
  const json = await response.json();

  return {
    ...json,
    success: true,
  };
};

export const successfulExternalResponseToJSON = async <A extends Response>(response: A): Promise<A> => {
  if (response.status < 200 || response.status > 299) return response;
  const json = await response.json();

  return {
    ...json,
    success: true,
  };
};
