import { styled, css } from 'styles';
import { withTheme } from '../Context';
import Field from 'components/core/Field/theme';

const sizeStyles = {
  default: css`
    padding: 10px 15px;
  `,
  small: css`
    padding: 4px 12px;
  `,
};

const FieldTextArea = withTheme(
  styled(Field)(
    ({ theme: { colors }, size }) => css`
      ${(size && sizeStyles[size]) || sizeStyles.default};
      height: auto;
      border-radius: 8px;
      background-color: ${colors.bgWhite};

      textarea {
        width: 100%;
        border: 0;
        resize: none;
        font-family: Lato;
        font-size: 1.6rem;
        outline: none;

        ::placeholder {
          color: ${colors.inputPlaceHolder};
        }
      }
    `
  )
);

export default FieldTextArea;
