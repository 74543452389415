import { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Icon, Section, Text } from 'components/core';
import SpinnerCentered from 'components/lib/SpinnerCentered';
import { getCurrentVenue, getCurrentVenueActiveMQTT, getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getCustomersIsFetchingAll } from 'selectors/getCustomers';
import { getStaffMembersLoading } from 'selectors/getStaffMembers';
import { getVenueVacanciesLoading } from 'selectors/getVacancies';
import { getVenueExtraOpeningsLoading } from 'selectors/getVenueExtraOpenings';
import { getVenueCustomTimeTablesLoading } from 'selectors/getVenueCustomTimeTables';
import { getVacancyTypologiesLoading } from 'selectors/getVacancyTypologies';
import { getVenueTreatmentsLoading } from 'selectors/getVenueTreatments';
import { getPromotionsLoading } from 'selectors/promotions/getPromotions';
import {
  fetchVenueCustomTimeTablesIfNeeded,
  fetchVenueExtraOpeningsIfNeeded,
  fetchVenueVacanciesIfNeeded,
} from 'actions/timeTables';
import { fetchCustomersIfNeeded } from 'actions/customers';
import { fetchVenueTreatmentsIfNeeded } from 'actions/treatments';
import { fetchCustomerTagsIfNeeded } from 'actions/customerTags';
import { fetchStaffMembersIfNeeded } from 'actions/staffMembers';
import { fetchVacancyTypologiesIfNeeded } from 'actions/vacancyTypologies';
import { fetchPromotionsIfNeeded } from 'actions/promotions/promotions';
import { notificationOrderDeliveryNotes } from 'actions/orderDeliveryNotes';
import { addToStack } from 'utils/synch';
import canPersistCustomers from 'utils/check/canPersistCustomers';
import { useTriggerActionIfDesktop } from 'utils/hooks';
// eslint-disable-next-line no-restricted-imports
import { useUnleashContext } from '@unleash/proxy-client-react';
import { fetchVTGIfNeeded } from 'actions/venueTreatmentGroup';
import { fetchAdvancedPackagesIfNeeded } from 'actions/packages';
import { useCheckKyc } from 'utils/payments/useCheckKyc';

const DataProvider = ({
  venue_id,
  currentVenue,
  countryCode,
  fetching_endpoints,
  venue_active_mqtt,
  dispatchFetchCustomersIfNeeded,
  dispatchFetchStaffMembersIfNeeded,
  dispatchFetchVacancyTypologiesIfNeeded,
  dispatchFetchVenueVacanciesIfNeeded,
  dispatchFetchVenueExtraOpeningsIfNeeded,
  dispatchFetchVenueCustomTimeTablesIfNeeded,
  dispatchAddToStack,
  dispatchFetchCustomerTagsIfNeeded,
  dispatchNotificationOrderDeliveryNotes,
  dispatchFetchPromotionsIfNeeded,
  dispatchFetchVenueTreatmentsIfNeeded,
  dispatchFetchAdvancedPackagesIfNeeded,
  dispatchFetchVTGIfNeeded,
  children,
}) => {
  const updateContext = useUnleashContext();
  useCheckKyc();

  useTriggerActionIfDesktop(dispatchNotificationOrderDeliveryNotes, [dispatchNotificationOrderDeliveryNotes, venue_id]);

  useEffect(() => {
    if (venue_id) {
      if (canPersistCustomers()) {
        dispatchFetchCustomersIfNeeded();
      }
      dispatchFetchStaffMembersIfNeeded();
      dispatchFetchVacancyTypologiesIfNeeded();
      dispatchFetchVenueVacanciesIfNeeded();
      dispatchFetchVenueExtraOpeningsIfNeeded();
      dispatchFetchVenueCustomTimeTablesIfNeeded();
      dispatchFetchCustomerTagsIfNeeded();
      dispatchFetchPromotionsIfNeeded();
      dispatchFetchVTGIfNeeded();
      dispatchFetchVenueTreatmentsIfNeeded();
      dispatchFetchAdvancedPackagesIfNeeded();
    }
  }, [
    venue_id,
    dispatchFetchCustomersIfNeeded,
    dispatchFetchStaffMembersIfNeeded,
    dispatchFetchVacancyTypologiesIfNeeded,
    dispatchFetchVenueVacanciesIfNeeded,
    dispatchFetchVenueExtraOpeningsIfNeeded,
    dispatchFetchVenueCustomTimeTablesIfNeeded,
    dispatchFetchCustomerTagsIfNeeded,
    dispatchFetchPromotionsIfNeeded,
    dispatchFetchVTGIfNeeded,
    dispatchFetchVenueTreatmentsIfNeeded,
    dispatchFetchAdvancedPackagesIfNeeded,
  ]);

  useEffect(() => {
    if (currentVenue) {
      dispatchAddToStack({ venue: currentVenue });
    }
  }, [dispatchAddToStack, currentVenue, venue_active_mqtt]);

  // update unleash context when there are updates to the current venue
  useEffect(() => {
    updateContext({ venueId: venue_id, countryCode });
  }, [venue_id, countryCode, updateContext]);

  const preload_list = ['staff_members', 'time_tables', 'customers', 'treatments'];

  return (
    <div className="data-provider">
      {fetching_endpoints.length > 0 ? (
        <Section displayFlex alignItems="center" justifyContent="center" style={{ height: '100vh' }}>
          <Section relative>
            {preload_list.map((type) => (
              <Section key={type} displayFlex paddingBottom={0.5} alignItems="center">
                <Icon size={20} type="tick-circle" color={fetching_endpoints.indexOf(type) >= 0 ? 'text2' : 'green'} />
                <Text size={16} block padding={0.5} intl={`loading.${type}`} />
              </Section>
            ))}
            <Section relative paddingTop={2}>
              <SpinnerCentered />
            </Section>
          </Section>
        </Section>
      ) : (
        children
      )}
    </div>
  );
};

const mapStateToProps = () =>
  createSelector(
    getCurrentVenueId,
    getCurrentVenue,
    getCustomersIsFetchingAll,
    getStaffMembersLoading,
    getVacancyTypologiesLoading,
    getVenueVacanciesLoading,
    getVenueExtraOpeningsLoading,
    getVenueCustomTimeTablesLoading,
    getCurrentVenueActiveMQTT,
    getPromotionsLoading,
    getVenueTreatmentsLoading,
    (
      venue_id,
      currentVenue,
      isFetchingCustomer,
      isFetchingStaffMembers,
      isFetchingVacancyTypologies,
      isFetchingVacancies,
      isFetchingExtraOpenings,
      isFetchingCustomTimeTables,
      venue_active_mqtt,
      isFetchingPromotions,
      isFetchingVenueTreatments
    ) => {
      const fetching_endpoints = [];

      if (canPersistCustomers() && isFetchingCustomer) {
        fetching_endpoints.push('customers');
      }

      if (isFetchingStaffMembers) {
        fetching_endpoints.push('staff_members');
      }

      if (isFetchingVacancyTypologies || isFetchingVacancies || isFetchingExtraOpenings || isFetchingCustomTimeTables) {
        fetching_endpoints.push('time_tables');
      }

      if (isFetchingPromotions || isFetchingVenueTreatments) {
        fetching_endpoints.push('treatments');
      }

      return {
        venue_id,
        currentVenue,
        countryCode: currentVenue?.country?.code,
        fetching_endpoints,
        venue_active_mqtt,
      };
    }
  );

const mapDispatchToProps = {
  dispatchFetchCustomersIfNeeded: fetchCustomersIfNeeded,
  dispatchFetchStaffMembersIfNeeded: fetchStaffMembersIfNeeded,
  dispatchFetchVacancyTypologiesIfNeeded: fetchVacancyTypologiesIfNeeded,
  dispatchFetchVenueVacanciesIfNeeded: fetchVenueVacanciesIfNeeded,
  dispatchFetchVenueExtraOpeningsIfNeeded: fetchVenueExtraOpeningsIfNeeded,
  dispatchFetchVenueCustomTimeTablesIfNeeded: fetchVenueCustomTimeTablesIfNeeded,
  dispatchAddToStack: addToStack,
  dispatchFetchCustomerTagsIfNeeded: fetchCustomerTagsIfNeeded,
  dispatchNotificationOrderDeliveryNotes: notificationOrderDeliveryNotes,
  dispatchFetchPromotionsIfNeeded: fetchPromotionsIfNeeded,
  dispatchFetchVenueTreatmentsIfNeeded: fetchVenueTreatmentsIfNeeded,
  dispatchFetchAdvancedPackagesIfNeeded: fetchAdvancedPackagesIfNeeded,
  dispatchFetchVTGIfNeeded: fetchVTGIfNeeded,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(DataProvider));
