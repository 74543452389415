/**
 * Add authorization to request headers
 * @param myHeaders - existing headers
 * @param auth - the auth token as string
 *
 * @returns The headers injected within the authentication token.
 */
const addAuthorizationHeader = (myHeaders: Headers, auth?: string): void => {
  if (auth) myHeaders.append('Authorization', `Token token="${auth}"`);
};

export { addAuthorizationHeader };
