import {
  FunctionComponent,
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useReducer,
  Dispatch,
  useCallback,
} from 'react';
import { ACTION_TYPE, State, IntlReducer, IntlReducerAction } from './IntlContext.reducer';
import { rootMessages } from './IntlProvider.data';
import { AvailableLocales, DEFAULT_LOCALE } from './LocaleProvider';

type IntlProviderProps = {
  children: ReactNode;
};

type UseIntlContextShape = {
  state: State;
  addMessages: (messages: State['messages']) => void;
  switchLanguage: (locale: AvailableLocales) => void;
};

const defaultState: State = {
  messages: rootMessages[DEFAULT_LOCALE],
  locale: DEFAULT_LOCALE,
};

const IntlContext = createContext<{ state: State; dispatch: Dispatch<IntlReducerAction> } | undefined>(undefined);

const IntlProvider: FunctionComponent<IntlProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(IntlReducer, defaultState);
  const value = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );
  return <IntlContext.Provider value={value}>{children}</IntlContext.Provider>;
};

const useIntlContext = (): UseIntlContextShape => {
  const context = useContext(IntlContext);
  if (context === undefined) {
    throw new Error('useIntl must be used within a IntlProvider');
  }
  const { state, dispatch } = context;

  const addMessages = useCallback(
    (messages: State['messages']): void => dispatch({ type: ACTION_TYPE.ADD_MESSAGE, payload: { messages } }),
    [dispatch]
  );

  const switchLanguage = useCallback(
    (locale: AvailableLocales): void => dispatch({ type: ACTION_TYPE.SWITCH_LANGUAGE, payload: { locale } }),
    [dispatch]
  );

  return {
    state,
    addMessages,
    switchLanguage,
  };
};

export { IntlProvider, useIntlContext };
