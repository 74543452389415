import { State } from 'reducers/types';
import { Modal } from 'models';
import { createSelector } from 'reselect';
import { ReachedLimitModalId } from 'actions/reachedLimits';

export const getModals = (state: State): Modal[] | null => state.modals || null;

export const getReachedLimitModal = createSelector(
  [getModals],
  (modals) => (modals && modals.find((modal: Modal) => modal.type === ReachedLimitModalId)) || null
);
