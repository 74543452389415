import React, {
  FunctionComponent,
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { List, AutoSizer, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
import TableView, { Header, OuterScroller, InnerScroller } from './theme';
import { TextInput, FieldIcon } from 'components/core';
import changeItemPosition from 'utils/changeItemPosition';

import Spinner from '../Spinner';
import stopScrollPropagation from 'utils/stopScrollPropagation';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggableProvidedDragHandleProps,
  DraggableProvidedDraggableProps,
  DropResult,
} from 'react-beautiful-dnd';
import TableViewRemoteModeAlert from './TableViewRemoteModeAlert';
import TableViewSectionTitle from './TableViewSectionTitle';
import { TS_FIXME } from 'utils/utilityTypes';

const MIN_QUERY_LENGTH = 3;

export type SectionSeparator = { sectionSeparator: boolean; title: string };

type OnSelectItem<T> = (e: MouseEvent, item: T, index: number, items: T[]) => void;

export type TableViewItemProps<T = unknown> = {
  query: string | null;
  item: T;
  onSelectItem: OnSelectItem<T>;
  innerRef?: React.Ref<HTMLElement>;
  draggableProps?: DraggableProvidedDraggableProps;
  dragHandleProps?: DraggableProvidedDragHandleProps;
};

export type TableViewSectionIndexItem = {
  itemIndex: number;
  title: string | number;
  onClick: () => void;
};

export type TableViewSectionIndexProps = {
  sectionsList: TableViewSectionIndexItem[];
  activeSectionIndex?: number;
};

export type SectionIndexStrategy<Item> = (
  items: Item[],
  handleSectionIndexClick: (index: number) => void
) => [(Item | SectionSeparator)[], TableViewSectionIndexItem[]];

type Props<Item> = {
  items: Item[];
  itemComponent: unknown;
  onSelectItem?: OnSelectItem<Item>;
  onQueryChange?: (args: { query: string }) => void;
  onReachEnd?: () => void;
  filterStrategy?: (query: string, item: Item, k: number) => boolean;
  keyStrategy: (item: Item, k: number) => string | number;
  sortStrategy?: (a: Item, b: Item) => 1 | -1 | 0;
  onSortItems?: (items: (SectionSeparator | Item)[]) => Promise<void>;
  resetScrollOnChange?: boolean;
  setQueryOnItems?: boolean;
  defaultHeight?: number;

  leftButton?: { [key: string]: unknown };
  searchable?: boolean;
  addButton?: () => void;
  filterDisabled?: boolean;
  filterPlaceholder?: string;
  loading?: boolean;
  isRemote?: boolean;
  sortable?: boolean;
  sectionIndexComponent?: FunctionComponent<TableViewSectionIndexProps>;
  sectionIndexStrategy?: SectionIndexStrategy<Item>;
  sectionIndexMinimumRowCount?: number;
  dataTestId?: string;
  autoFocus?: boolean;
};

const noop = (): void => {};

type TableViewComponentShape = <T extends object>(props: PropsWithChildren<Props<T>>) => ReactElement | null;

const TableViewComponent: TableViewComponentShape = ({
  items,
  itemComponent,
  onSelectItem = noop,
  onQueryChange,
  onReachEnd = noop,
  filterStrategy,
  keyStrategy = (_, k): number => k,
  sortStrategy,
  onSortItems,
  resetScrollOnChange = true,
  setQueryOnItems,
  defaultHeight = 30,

  leftButton,
  searchable = true,
  addButton,
  filterDisabled,
  filterPlaceholder,
  loading,
  isRemote,
  sortable,
  autoFocus = false,

  sectionIndexStrategy,
  sectionIndexComponent: SectionIndex,
  sectionIndexMinimumRowCount,
  dataTestId,
}) => {
  const [query, setQuery] = useState('');
  const [fullInput, setFullInput] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  /**
   * local representation of items (useful during sorting ops.)
   */
  const [localItems, setLocalItems] = useState<((typeof items)[number] | SectionSeparator)[] | null>(null);

  const Item = itemComponent as FunctionComponent<TableViewItemProps>;

  const outerScrollerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<List>(null);
  const cellMeasureCache = useRef(
    new CellMeasurerCache({
      defaultHeight,
      fixedWidth: true,
    })
  );

  useEffect(() => {
    if (outerScrollerRef.current) {
      if (listRef.current) {
        // stop scroll propagation on scrollable react-virtualized inner element
        const virtualizedScrollableEl = outerScrollerRef.current.querySelector('.ReactVirtualized__List');
        if (virtualizedScrollableEl) {
          stopScrollPropagation(virtualizedScrollableEl);
        }
      } else {
        stopScrollPropagation(outerScrollerRef.current);
      }
    }
  }, []);

  useEffect(() => {
    if (outerScrollerRef.current && outerScrollerRef.current.scrollTo && resetScrollOnChange) {
      outerScrollerRef.current.scrollTo(0, 0);
    }
  }, [items, resetScrollOnChange]);

  const handleTextInputChange = useCallback(
    (text) => {
      setQuery(text);
      if (onQueryChange) {
        onQueryChange({ query: text });
      }
    },
    [onQueryChange]
  );

  const handleTextInputFocus = useCallback(() => setFullInput(true), []);

  const handleTextInputBlur = useCallback(() => setFullInput(query.length > 0), [query]);

  const resetQuery = useCallback(
    (e) => {
      e.stopPropagation();
      setFullInput(false);
      handleTextInputChange('');
    },
    [handleTextInputChange]
  );

  const [isScrolledBySection, setIsScrolledBySection] = useState<boolean>(false);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);

  const handleSectionIndexClick = (index: number): void => {
    listRef?.current?.scrollToRow(index);
    setIsScrolledBySection(true);
    setActiveSectionIndex(index);
  };

  const [processedItems, processedItemsSectionsList] = useMemo(() => {
    if (localItems) {
      return [localItems, []];
    }

    let list = items;
    const sectionsList: TableViewSectionIndexItem[] = [];

    if (filterStrategy) {
      list = list.filter((item, k) => filterStrategy(query, item, k));
    }
    if (sortStrategy) {
      list = list.sort(sortStrategy);
    }

    if (sectionIndexStrategy) {
      return sectionIndexStrategy(list, handleSectionIndexClick);
    }

    return [list, sectionsList];
  }, [localItems, items, filterStrategy, sortStrategy, query, sectionIndexStrategy]);

  const isSectionIndexVisible = Boolean(
    sectionIndexStrategy && processedItems.length > (sectionIndexMinimumRowCount || 0) && SectionIndex
  );

  useEffect(() => {
    if (listRef.current) {
      cellMeasureCache.current.clearAll();
      listRef.current.forceUpdateGrid();
    }
  }, [processedItems]);

  // FIXME [2022-05-30]: remove "as" TypeAssertion by improving item typing
  const handleSortItems = useCallback(
    async (result: DropResult) => {
      if (
        result &&
        result.source &&
        result.destination &&
        result.source.index !== result.destination.index &&
        onSortItems
      ) {
        const sortedItems = changeItemPosition(processedItems, result.source.index, result.destination.index);

        setLocalItems(sortedItems);
        await onSortItems(sortedItems);

        setLocalItems(null);
      }
    },
    [onSortItems, processedItems]
  );

  const hasAlreadyScrolled = useRef<boolean>(false);
  const scrollTimer = useRef<ReturnType<typeof setTimeout>>();
  const END_THRESHOLD = 200;
  const handleScroll = useCallback(
    ({ clientHeight, scrollHeight, scrollTop }: { clientHeight: number; scrollHeight: number; scrollTop: number }) => {
      const hasReachedEnd = scrollTop + clientHeight >= scrollHeight - END_THRESHOLD;
      if (hasReachedEnd) {
        onReachEnd();
      }

      if (hasAlreadyScrolled.current) {
        if (!isScrolling) {
          setIsScrolling(true);
        }
        if (scrollTimer.current) {
          clearTimeout(scrollTimer.current);
        }
        scrollTimer.current = setTimeout(() => setIsScrolling(false), 1000);
      }
      hasAlreadyScrolled.current = true;
    },
    [isScrolling, onReachEnd]
  );

  const onRowsRendered = useCallback(
    ({ startIndex }) => {
      for (let i = processedItemsSectionsList.length - 1; i >= 0; i--) {
        if (processedItemsSectionsList[i].itemIndex <= startIndex) {
          if (!isScrolledBySection) {
            setActiveSectionIndex(processedItemsSectionsList[i].itemIndex);
            return;
          }
        }
      }

      if (isScrolledBySection && isScrolling) {
        setIsScrolledBySection(false);
      }
    },
    [processedItemsSectionsList, isScrolledBySection, isScrolling]
  );

  // FIXME [2022-05-30]: remove "as" TypeAssertion by improving item typing
  const itemRenderer = useCallback(
    ({ key, index, style, parent }) => {
      const item = processedItems[index];
      return (
        <CellMeasurer cache={cellMeasureCache.current} columnIndex={0} key={key} parent={parent} rowIndex={index}>
          <div style={style} data-testid="package-list-item">
            {(item as SectionSeparator).sectionSeparator ? (
              <TableViewSectionTitle item={item as SectionSeparator} />
            ) : (
              <Item
                query={setQueryOnItems ? query : null}
                item={item}
                onSelectItem={(e: MouseEvent): void => onSelectItem(e, item as TS_FIXME, index, items)}
              />
            )}
          </div>
        </CellMeasurer>
      );
    },
    [Item, items, onSelectItem, processedItems, query, setQueryOnItems]
  );

  const showHeader = leftButton || searchable || addButton;

  // FIXME [2022-05-30]: remove "as" TypeAssertion by improving item typing
  return (
    <TableView className="TableView" data-testid={dataTestId}>
      {showHeader && (
        <Header className="TableView__header" fullInput={fullInput} withSectionIndex={isSectionIndexVisible}>
          {leftButton && <FieldIcon iconSize={19} {...leftButton} />}
          {searchable && (
            <TextInput
              testId="search-item"
              autoFocus={autoFocus}
              value={query}
              disabled={filterDisabled}
              placeholder={filterPlaceholder}
              onChange={handleTextInputChange}
              onFocus={handleTextInputFocus}
              onBlur={handleTextInputBlur}
              icon={
                fullInput
                  ? { size: 12, color: 'text1', type: 'close', onClick: resetQuery }
                  : { size: 12, color: 'text2', type: 'search' }
              }
            />
          )}
          {addButton && <FieldIcon button onClick={addButton} type="plus" iconSize={12} data-testid="add-new-item" />}
        </Header>
      )}

      <OuterScroller ref={outerScrollerRef} withSectionIndex={isSectionIndexVisible}>
        {!processedItems.length && query.length >= MIN_QUERY_LENGTH && !loading && (
          <TableViewRemoteModeAlert intl="no.results.for" intlValues={{ query }} iconType="search" />
        )}
        {isRemote && !processedItems.length && query.length < MIN_QUERY_LENGTH && !loading && (
          <TableViewRemoteModeAlert
            intl="start.digit.to.search"
            intlValues={{ count: MIN_QUERY_LENGTH }}
            iconType="customers"
          />
        )}
        {sortable && !query ? (
          <DragDropContext onDragEnd={handleSortItems}>
            <Droppable droppableId="droppable">
              {(droppableProvided): ReactElement => (
                <InnerScroller ref={droppableProvided.innerRef} className="TableView__scroller">
                  {loading ? (
                    <div className="TableView__loader">
                      <Spinner centered loading />
                    </div>
                  ) : null}
                  <AutoSizer>
                    {({ height, width }): ReactElement => {
                      return (
                        <div className="Autosizer__child" style={{ height, width }}>
                          {processedItems.map((item, k) => (
                            <Draggable
                              key={keyStrategy(item as TS_FIXME, k)}
                              draggableId={keyStrategy(item as TS_FIXME, k).toString()}
                              index={k}
                            >
                              {(draggableProvided): ReactElement => (
                                <div>
                                  <Item
                                    // do not change this innerRef to ref due to react-beautiful-dnd dependencies
                                    innerRef={draggableProvided.innerRef}
                                    draggableProps={draggableProvided.draggableProps}
                                    dragHandleProps={draggableProvided.dragHandleProps}
                                    query={setQueryOnItems ? query : null}
                                    item={item}
                                    onSelectItem={(e): void => onSelectItem(e, item as TS_FIXME, k, items)}
                                  />
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                        </div>
                      );
                    }}
                  </AutoSizer>
                </InnerScroller>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <InnerScroller
            className={`TableView__scroller TableView__autosizer ${isScrolling ? `TableView__scrolling` : ''}`}
          >
            {loading ? (
              <div className="TableView__loader">
                <Spinner centered loading />
              </div>
            ) : null}
            <AutoSizer>
              {({ height, width }): ReactElement => (
                <List
                  data-testid="table-view-elem"
                  ref={listRef}
                  deferredMeasurementCache={cellMeasureCache.current}
                  width={width}
                  height={height}
                  rowCount={processedItems.length}
                  rowHeight={cellMeasureCache.current.rowHeight}
                  rowRenderer={itemRenderer}
                  onRowsRendered={onRowsRendered}
                  overscanRowCount={0}
                  onScroll={handleScroll}
                  scrollToAlignment="start"
                />
              )}
            </AutoSizer>
          </InnerScroller>
        )}
      </OuterScroller>
      {isSectionIndexVisible && SectionIndex && (
        <SectionIndex sectionsList={processedItemsSectionsList} activeSectionIndex={activeSectionIndex} />
      )}
    </TableView>
  );
};

export default TableViewComponent;

export { Cell, CellTitle, CellInfo, CellInfoSeparator, CellBadge } from './theme';
