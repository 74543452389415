import { styled, css } from 'styles';

export const SelectInputGroup = styled('div')(
  ({ block, flex }) => css`
    display: flex;
    flex: ${flex ? '1' : 'none'};
    ${block && `width: 100%;`};

    > .Select {
      margin: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    > .Select + .TextInput {
      border-left-width: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  `
);

export default SelectInputGroup;
