import { FunctionComponent } from 'react';
import { Section, Icon, Text } from 'components/core';
import { PaymentMethodState, PAYMENT_METHOD_STATE } from 'models';

type DefaultMethodProps = {
  isDefault: boolean;
  isPending: boolean;
  state: PaymentMethodState;
  onClick?: () => void;
};

const DefaultMethod: FunctionComponent<DefaultMethodProps> = ({ isDefault, state, onClick, isPending }) => {
  if (isDefault) {
    return (
      <Section displayFlex alignItems="center" className="DefaultMethod" data-testid="payment_methods_default_card">
        <Section paddingRight={0.5}>
          <Icon type="tick-full" size={14} color="text2" />
        </Section>
        <Text size={14} color="text2" bold intl="account.payment_methods.default" />
      </Section>
    );
  }

  if (state === PAYMENT_METHOD_STATE.EXPIRED || state === PAYMENT_METHOD_STATE.IS_EXPIRING || isPending) {
    return null;
  }

  return (
    <Section onClick={onClick} className="DefaultMethod" data-testid="payment_methods_card_set_default_button">
      <Text size={14} color="text2" bold intl="account.payment_methods.set_default" />
    </Section>
  );
};

export default DefaultMethod;
