import { useEffect } from 'react';

const useWindowListener = (eventName: string, listener: () => void): void => {
  useEffect(() => {
    window.addEventListener(eventName, listener);

    return (): void => {
      window.removeEventListener(eventName, listener);
    };
  }, [eventName, listener]);
};

export default useWindowListener;
