import { styled, css } from 'styles';
import { withTheme } from 'components/core';
// eslint-disable-next-line no-restricted-imports
import { theme } from 'styles';

export const COLUMN_WIDTHS = ['45%', '20%', '30%', '5%'];

type CellProps = {
  width: string;
  isHeading?: string;
  desktopOnly?: boolean;
};

export const Table = styled('div')`
  display: flex;
  flex-wrap: wrap;
`;

const CellCss = css`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 25px 10px;
  overflow: hidden;
  word-break: break-all;
  border-bottom: 1px solid ${theme.colors.inputBorder};
`;

export const Cell = withTheme(
  styled('div')<CellProps>(
    ({ theme: { media }, width, isHeading, desktopOnly }) => css`
      ${CellCss};
      width: ${width};

      ${isHeading &&
      css`
        padding: 0 10px 5px;
        border-bottom: 0;
      `}

      ${media.mobileOrSmaller`
        align-items: flex-start;
        ${
          desktopOnly &&
          css`
            display: none;
          `
        }
      `};
    `
  )
);

export const CellCard = withTheme(
  styled('div')<CellProps>(
    ({ theme: { media }, width, isHeading, desktopOnly }) => css`
      ${CellCss};
      width: ${width};

      ${isHeading &&
      css`
        padding: 0 10px 5px;
        border-bottom: 0;
      `}

      ${media.mobileOrSmaller`
        align-items: flex-start;
        width: 85%;
        ${
          desktopOnly &&
          css`
            display: none;
          `
        }
      `};
    `
  )
);

export const InfoSection = withTheme(
  styled('div')(
    ({ theme: { media } }) => css`
      .DefaultMethod {
        padding-top: 15px;
      }

      .ExpirationDate {
        padding-top: 4px;
        font-size: ${12 / 10}rem;
      }

      ${media.tablet`
        display: none;
      `}
    `
  )
);
