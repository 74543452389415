import { Fragment } from 'react';
import PropTypes from 'prop-types';
import StyledProgress from './theme';

const WIDTH = 128;
const HALF_WIDTH = WIDTH / 2;

const _renderCircle = ({ width, value, label, thickness, color, bgColor }) => {
  const stroke = (thickness * WIDTH) / width / 2;
  const radius = HALF_WIDTH - stroke / 2;
  const circumference = radius * 2 * Math.PI;
  const progress = (value / 100) * circumference;

  return (
    <Fragment>
      {label && (
        <StyledProgress.Value
          size={typeof label === typeof true ? Math.floor(width * 0.28) : label}
          className="Progress__Value--Circle"
        >
          {value}%
        </StyledProgress.Value>
      )}
      <StyledProgress.Circle className="Progress--Circle">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${WIDTH} ${WIDTH}`}>
          <g strokeWidth={stroke}>
            <StyledProgress.CircleBg
              bgColor={bgColor}
              r={radius}
              cx={HALF_WIDTH}
              cy={HALF_WIDTH}
              fill="none"
              className="Progress__CircleBg"
            />
            <StyledProgress.CircleMeter
              color={color}
              r={radius}
              cx={HALF_WIDTH}
              cy={HALF_WIDTH}
              fill="none"
              strokeDasharray={`${progress}, ${circumference - progress}`}
              className="Progress__CircleMeter"
            />
          </g>
        </svg>
      </StyledProgress.Circle>
    </Fragment>
  );
};

const _renderBar = ({ width, value, label, color, bgColor }) => (
  <Fragment>
    {label && (
      <StyledProgress.Value bold size={Math.floor(width * 0.28)} className="Progress__Value--Bar">
        {value}%
      </StyledProgress.Value>
    )}
    <StyledProgress.Bar className="Progress--Bar" bgColor={bgColor} color={color}>
      <div />
    </StyledProgress.Bar>
  </Fragment>
);

const renderType = (props) => {
  const { circle } = props;
  if (circle) {
    return _renderCircle(props);
  }
  return _renderBar(props);
};

const Progress = (props) => (
  <StyledProgress className="Progress" {...props}>
    {renderType(props)}
  </StyledProgress>
);

Progress.propTypes = {
  /** A value between 0 and 100 */
  value: PropTypes.number.isRequired,

  /** The color of the Bar, a color in theme (es. `red`, `text1`) ora a RGB HEX (es. `#77FF77`) */
  color: PropTypes.string,

  /** This is not the value color, this is the background color of the progress bar, pick a color from the theme (es. `red`, `text1`), default is grey */
  bgColor: PropTypes.string,

  /** thickness of the bar */
  thickness: PropTypes.number,

  /** Type of Progress, if true will be displayed a Circle Progress */
  circle: PropTypes.bool,

  /** Display or hide the value */
  label: PropTypes.bool,

  /** The width of the component, if `circle` is true this is also the height */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Progress.defaultProps = {
  thickness: 3,
};

export default Progress;
