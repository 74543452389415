import { useEffect, useRef } from 'react';
import { isElectron } from 'utils/native/cash';

type UseIpcListener = (props: { eventName: string; handler: (ipcEvent: unknown, response: string) => void }) => void;

export const useIpcListener: UseIpcListener = ({ eventName, handler }) => {
  const ipcRenderer = useRef(isElectron() && window.require('electron').ipcRenderer);
  const storedHandler = useRef(handler);

  useEffect(() => {
    storedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const ipc = ipcRenderer.current;
    if (!ipc) return;

    ipc.on(eventName, storedHandler.current);

    return (): void => {
      ipc.removeListener(eventName, storedHandler.current);
    };
  }, [eventName]);
};
