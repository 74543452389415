import { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldSelectNative as StyledFieldSelectNative } from './theme';

class FieldSelectNative extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value || '' });
    }
  }

  componentDidMount() {
    if (this.props.autofocus) {
      this.refs.input.focus();
    }
  }

  onChange = (event) => {
    this.setState({ value: event.target.value }, () => this.props.onChange(this.state.value));
  };

  render() {
    const { children, options } = this.props;

    return (
      <StyledFieldSelectNative {...this.props}>
        <select ref="input" value={this.state.value} onChange={this.onChange}>
          <option value="">select...</option>
          {options.map((o) => (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          ))}
        </select>
        {children}
      </StyledFieldSelectNative>
    );
  }
}

FieldSelectNative.displayName = 'FieldSelectNative';

FieldSelectNative.defaultProps = {
  onChange: () => {},
  disabled: false,
  type: '',
  size: '',
  options: [],
};

FieldSelectNative.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  size: PropTypes.string,
  options: PropTypes.array,
  children: PropTypes.node,
};

export default FieldSelectNative;
