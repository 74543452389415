import { captureMessage } from '@sentry/core';
import { getCurrentUserEmail, getCurrentUserToken } from 'selectors/getCurrentUser';
import chaosMonkey from 'utils/chaos-monkey';
import getMQTTOption from './env';
import { ConnectToMQTT, MQTTClient, MQTTOnMessage } from './types';
import { areMQTTAuthCredentialsExpired, devLogger } from './utils';

type Subscription = {
  topic: string;
  onMessage: MQTTOnMessage;
  onSubscribed?: () => void;
};

let subscriptions: Subscription[] = [];
let isConnecting = false;

const noop = (): void => {};

const connectToMQTT: ConnectToMQTT =
  () =>
  async (_dispatch, getState): Promise<MQTTClient> => {
    const email = getCurrentUserEmail(getState());
    const auth = getCurrentUserToken(getState());

    const Paho = window.Paho;
    const client_id = 'WEBB2B_' + email + '_' + new Date().getTime() + '_' + Math.random() * 100000;
    const mqttClient = new Paho.MQTT.Client(getMQTTOption('HOST'), Number(getMQTTOption('PORT')), '/mqtt', client_id);

    let mqttHeartbeatTrigger: number;

    const resetHeartbeat = (): void => {
      window.clearInterval(mqttHeartbeatTrigger);
      mqttHeartbeatTrigger = window.setInterval(() => {
        client.reconnect();
      }, 20000);
    };

    mqttClient.onMessageArrived = ({
      destinationName,
      payloadString,
    }: {
      destinationName: string;
      payloadString: string;
    }): void => {
      resetHeartbeat();

      const subscription = subscriptions.find((s) => s.topic === destinationName);
      if (!subscription) {
        return;
      }
      try {
        const response = JSON.parse(new Buffer(payloadString).toString('utf8'));
        subscription.onMessage(response);
      } catch (e) {
        return;
      }
    };

    const client: MQTTClient = {
      isConnected: () => mqttClient.isConnected(),
      connect: () =>
        new Promise((resolve) => {
          devLog(`connect() ${JSON.stringify({ isConnecting, isConnected: mqttClient.isConnected() })}`);
          if (mqttClient.isConnected() || isConnecting) {
            resolve(client);
            return;
          }

          isConnecting = true;
          resetHeartbeat();

          /**
           * wrap `mqttClient.connect()` in try catch
           * because internally it throws exception ignoring onFailure callback
           */
          try {
            mqttClient.connect({
              useSSL: Boolean(getMQTTOption('SSL')),
              onSuccess: () => {
                devLog('🟢 Successfully Connected');
                isConnecting = false;
                resetHeartbeat();

                subscriptions.forEach((subscription) => {
                  client.subscribe(subscription.topic, {
                    onMessage: subscription.onMessage,
                    onSubscribed: subscription.onSubscribed,
                  });
                });
                resolve(client);
              },
              onFailure: (e) => {
                devLog('🔴 Fail to connect');
                isConnecting = false;
                if (areMQTTAuthCredentialsExpired(e)) {
                  devLog('🔑 mqtt auth credentials are expired, disconnecting the client');
                  // disconnecting the client because the credentials are expired, and it is impossible to reconnect
                  client.disconnect();
                }
              },
              userName: email,
              password: auth,
            });
          } catch (e) {
            isConnecting = false;
          }
        }),
      disconnect: () => {
        /**
         * disconnect MQTT
         */
        if (mqttClient.isConnected()) {
          mqttClient.disconnect();
        }
        window.clearInterval(mqttHeartbeatTrigger);
        return client;
      },
      reconnect: () => {
        /**
         * trigger MQTT reconnect
         */

        // if the session is not authenticated avoid retrying to reconnect mqtt (this usually happens when the connection falls after inactivity and the token is expired)
        const stillAuthenticated = Boolean(getCurrentUserToken(getState()));
        if (!stillAuthenticated) {
          return client.disconnect();
        }

        devLog('MQTT reconnect() attempt');

        client.disconnect();
        client.connect();
        return client;
      },
      reset: () => {
        /**
         * reset local subscriptions
         */
        subscriptions = [];
        client.disconnect();
        return client;
      },
      subscribe: (topic, { onSuccess = noop, onFailure = noop, onMessage, onSubscribed }) => {
        devLog(`subscribe(${topic})`);
        /**
         * update local subscriptions
         */
        subscriptions = [...subscriptions.filter((s) => s.topic !== topic)];
        subscriptions.push({
          topic,
          onMessage,
          onSubscribed,
        });
        /**
         * update MQTT connection
         */
        if (mqttClient.isConnected()) {
          mqttClient.subscribe(topic, {
            onSuccess: () => {
              onSuccess();
              if (onSubscribed) {
                onSubscribed();
              }
            },
            onFailure,
          });
        } else {
          client.connect();
        }
      },
      unsubscribe: (topic, { onSuccess = noop, onFailure = noop }) => {
        /**
         * update local subscriptions
         */
        subscriptions = [...subscriptions.filter((s) => s.topic !== topic)];
        /**
         * update MQTT connection
         */
        if (mqttClient.isConnected()) {
          mqttClient.unsubscribe(topic, {
            onSuccess,
            onFailure,
          });
        }
      },
    };

    document.addEventListener('visibilitychange', () => {
      switch (document.visibilityState) {
        case 'hidden':
          break;
        case 'visible':
          if (!mqttClient.isConnected() && !isConnecting) {
            devLog('page visible, attempting reconnection');
            client.reconnect();
          }
          break;
      }
    });

    chaosMonkey({
      task: () => {
        mqttClient.disconnect();
        setTimeout(() => {
          if (!mqttClient.isConnected()) {
            captureMessage("A monkey entered the room, and client didn't manage it.");
          }
        }, 30 * 1000);
      },
      ratePercentage: 5,
      minTimeout: 80000,
      maxTimeout: 160000,
    });

    return client.connect();
  };

export default connectToMQTT;

const devLog = devLogger('MQTT');
