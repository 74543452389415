import { Box, Button, Text } from 'components/core';
import { MobilePasswordManagerForm } from './MobilePasswordManagerForm';
import { MobilePasswordsManagerEditCredentialProps } from './types';

export const MobilePasswordsManagerEditCredential = ({
  credentials,
  onUpdateCredential,
  onDeleteCredential,
}: MobilePasswordsManagerEditCredentialProps): JSX.Element => {
  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap="24px" width="100%">
      <MobilePasswordManagerForm
        credentials={credentials}
        isNewPassword={false}
        onSaveCredential={onUpdateCredential}
      />
      <Box>
        <Button unstyled onClick={onDeleteCredential} dataTestId="mobile_password_manager_delete_password_button">
          <Text uppercase color="red" bold block intl="signin.mobile_password_manager.delete_password" />
        </Button>
      </Box>
    </Box>
  );
};
