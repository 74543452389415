import { getCurrentVenue, getCurrentVenueCountryCode, getCurrentVenueId } from 'selectors/getCurrentVenue';
import { TWPROTrackParameters } from './aspectTracking';
import { getPageNameByPathname, isTrackingServiceUp } from './trackingService.utils';
import { getEmployeeTitle } from 'selectors/employee';
import { StoreContext, WindowDataContext, AnalyticsContext } from './types';
import { electronGetGlobal } from 'utils/native/electronUtils';
import RELEASE_CONFIG from 'version.json';
import { getDevice, getOS } from 'utils/tracking/getUserAgentParsed';
import { getPlatform } from './getPlatform';
import { getEnvironment } from './getEnvironment';

const getTrackDefaultProperties = ({ getStore, getWindowData }: StoreContext & WindowDataContext) => {
  return {
    country: getCurrentVenueCountryCode(getStore().getState()),
    name: getPageNameByPathname(getWindowData().pathname),
    path: getWindowData().pathname,
    platform: getPlatform(),
    environment: getEnvironment(),
    referrer: getWindowData().referrer,
    search: getWindowData().search,
    title: getWindowData().title,
    trackingVersion: 'v2',
    url: getWindowData().href,
    venue_id: getCurrentVenueId(getStore().getState()),
    app_version: RELEASE_CONFIG.version,
    wrapper_version: electronGetGlobal('version'),
    OS: getOS(),
    device: getDevice(),
    employee_title: getEmployeeTitle(getStore().getState()),
    venue_plan: getCurrentVenue(getStore().getState())!.plan,
  };
};
type TrackContext = WindowDataContext & AnalyticsContext & StoreContext;

export const createTrack =
  ({ getAnalyticsBrowser, getStore, getWindowData }: TrackContext) =>
  (eventName: string, properties: TWPROTrackParameters = {}): void => {
    const analytics = getAnalyticsBrowser();

    if (!isTrackingServiceUp(analytics)) return;

    analytics.track(eventName, {
      ...getTrackDefaultProperties({ getStore, getWindowData }),
      ...properties,
    });
  };
