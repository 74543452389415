import { Component, createRef } from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-restricted-imports
import { Box } from '@uala/ui-core';

import StyledField from './theme';
import Text from '../Text';
import FieldIcon from '../FieldIcon';
import Section from '../Section';

class FieldSelectMulti extends Component {
  state = {};

  constructor(props) {
    super(props);

    this.queryFilter = createRef();
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.closeSelectWhenClickOutside);
  }

  toggleOpen = (e) => {
    if (!this.props.disabled) {
      window.removeEventListener('click', this.closeSelectWhenClickOutside);
      this.setState(
        ({ open }) => {
          if (!open) {
            setTimeout(
              () =>
                window.addEventListener(
                  'click',
                  (this.closeSelectWhenClickOutside = () => {
                    this.setState({ open: false });
                  })
                ),
              10
            );
          }
          return { open: !open, query: '' };
        },
        () => {
          if (this.state.open && this.queryFilter && this.queryFilter.current) {
            this.queryFilter.current.focus();
          }
        }
      );
    }
  };

  recursivelyCheckIfAllChildrenAreSelected = (option) => {
    const active = (this.props.value || []).find(
      ({ category, value }) => category === option.category && value === option.value
    );

    return (
      active ||
      (option.children &&
        option.children.length > 0 &&
        option.children.every(this.recursivelyCheckIfAllChildrenAreSelected))
    );
  };

  recursivelyCheckIfSomeChildIsSelected = (option) => {
    const active = (this.props.value || []).find(
      ({ category, value }) => category === option.category && value === option.value
    );

    return (
      active ||
      (option.children &&
        option.children.length > 0 &&
        option.children.some(this.recursivelyCheckIfSomeChildIsSelected))
    );
  };

  recursivelyCheckIfSomeChildMatchQueryFilter = (option, query) => {
    return (
      (option.label || '').toLowerCase().indexOf(query) >= 0 ||
      (option.children &&
        option.children.length > 0 &&
        option.children.some((option) => this.recursivelyCheckIfSomeChildMatchQueryFilter(option, query)))
    );
  };

  renderOptions = (options, deepLevel = 0, gotSelectedAncestor = false) => {
    const { onOptionSelect, isAutoClosedOnSelect } = this.props;
    const _this = this;
    const lowerCaseQuery = (this.state.query || '').toLowerCase();

    return options
      .filter((option) => this.recursivelyCheckIfSomeChildMatchQueryFilter(option, lowerCaseQuery))
      .map((option, k) => {
        const { disabled = undefined } = option;

        const onOptionClick = function (e) {
          e.stopPropagation();
          if (!disabled) {
            onOptionSelect(option);
            if (isAutoClosedOnSelect) {
              _this.setState({ open: false });
            }
          }
        };
        const onToggleOption = function (e) {
          e.stopPropagation();
          if (!disabled) {
            _this.setState((state) => ({
              [option.category + '.' + option.value]: !state[option.category + '.' + option.value],
            }));
          }
        };
        const selected = gotSelectedAncestor || this.recursivelyCheckIfAllChildrenAreSelected(option);
        const iconType = selected ? 'tick' : this.recursivelyCheckIfSomeChildIsSelected(option) ? 'partial' : null;
        const active = iconType !== null;
        const gotChildren = option.children && option.children.length;

        return [
          <StyledField.Option
            key={deepLevel + '.' + k}
            level={deepLevel}
            className="FieldSelectMulti__option"
            onClick={gotChildren ? onToggleOption : onOptionClick}
            data-testclass="field-select-multi-option"
          >
            <Section displayFlex justifyContent="space-between" alignItems="center">
              <Text size={16} color="text1">
                {option.label}
              </Text>
              <Box display="flex" alignItems="right" justifyContent="center">
                {option?.extraInfo}
                <FieldIcon
                  circle
                  empty
                  type={iconType}
                  color="blue"
                  active={active}
                  disabled={disabled}
                  onClick={gotChildren ? onOptionClick : null}
                />
              </Box>
            </Section>
          </StyledField.Option>,
          gotChildren && (this.state[option.category + '.' + option.value] || lowerCaseQuery)
            ? this.renderOptions(option.children, deepLevel + 1, selected)
            : null,
        ];
      });
  };

  render() {
    const { onOptionSelect, ...props } = this.props;
    const { label, queryFilter, options, size, shape } = props;
    const { open } = this.state;

    return (
      <StyledField {...props} onClick={this.toggleOpen} open={open} data-testid="field-select-multi-wrapper">
        {label ? (
          <StyledField.Label open={open}>
            <Text flex="1" size={16} color="text1" truncate>
              {label}
            </Text>
          </StyledField.Label>
        ) : null}
        {queryFilter ? (
          <StyledField.QueryFilter open={open}>
            <input
              ref={this.queryFilter}
              type="text"
              placeholder={queryFilter}
              value={this.state.query || ''}
              onClick={(e) => e.stopPropagation()}
              onChange={(event) => this.setState({ query: event.target.value })}
              data-testid="query-filter-input"
            />
          </StyledField.QueryFilter>
        ) : null}
        <StyledField.Icon open={open} type="select" size={11} />
        <StyledField.List
          className="FieldSelectMulti__list"
          open={open}
          size={size}
          shape={shape}
          queryfilter={queryFilter}
          style={{ maxHeight: Math.min(10, [].concat(options).length) * 36 }}
          data-testid="field-select-multi-list"
        >
          {open ? this.renderOptions(options) : null}
        </StyledField.List>
      </StyledField>
    );
  }
}

FieldSelectMulti.displayName = 'FieldSelectMulti';

FieldSelectMulti.defaultProps = {
  options: [],
  value: [],
  onOptionSelect: () => {},
};

FieldSelectMulti.propTypes = {
  options: PropTypes.array,
  value: PropTypes.array,
  isAutoClosedOnSelect: PropTypes.bool,
  onOptionSelect: PropTypes.func,
};

export default FieldSelectMulti;
