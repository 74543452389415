/* eslint-disable no-fallthrough */

import {
  VENUE_LOCAL_APPOINTMENT_CREATE,
  VENUE_LOCAL_APPOINTMENT_DELETE,
  VENUE_START_LOCAL_APPOINTMENT_EDIT,
  VENUE_LEAVE_LOCAL_APPOINTMENT_EDIT,
  VENUE_LOCAL_APPOINTMENT_EDIT,
} from 'actionTypes';
import createOneShotCommand from 'utils/oneShotCommand';
import { invalidateAllWithLocalChangesRefChecks } from 'utils/hoc/withLocalChanges';
import { CancellationProtectionSetupStatus } from 'models';

const localAppointments = (state = { items: [] }, action) => {
  switch (action.type) {
    case VENUE_LOCAL_APPOINTMENT_CREATE:
      const now = new Date();
      const id = action.id || 'local-appointment-' + now.getTime();

      return {
        ...state,
        items: [
          ...state.items,
          {
            __isLocal: true,
            __isDraft: true,
            whenFirstTimeDisplayed: createOneShotCommand(),
            id,
            customer_id: action.customer_id || null,
            time: action.time ? action.time.format() : null,
            time_no_tz: action.time ? action.time.format('YYYY-MM-DDTHH:mm:ss') : null,
            staff_member_id: action.staff_member_id || null,
            cancellation_protection: {
              stripe_setup_intent_confirmed: false,
              max_chargeable_amount_cents: 0,
              setup_status: CancellationProtectionSetupStatus.NOT_CONFIGURED,
            },
          },
        ],
      };

    case 'VENUE_APPOINTMENT_CREATE':
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.id ? { ...item, remote_id: action.appointment.id } : item
        ),
      };

    case 'VENUE_APPOINTMENT_DELETE_RESPONSE':
      const deleted_appointments_ids = []
        .concat(action.appointment ? action.appointment.id : [])
        .concat(action.appointments ? action.appointments.map(({ id }) => id) : []);
      if (state.items.find(({ remote_id }) => deleted_appointments_ids.indexOf(remote_id) >= 0)) {
        return {
          ...state,
          items: state.items.filter(({ remote_id }) => !remote_id || deleted_appointments_ids.indexOf(remote_id) < 0),
        };
      }

      return state;

    case VENUE_LOCAL_APPOINTMENT_DELETE:
      return {
        ...state,
        items: [...state.items.filter((item) => item.id !== action.id)],
      };

    case VENUE_LOCAL_APPOINTMENT_EDIT:
      return {
        ...state,
        items: state.items.map((item) => (item.id === action.id ? { ...item, ...action.changes } : item)),
      };

    case VENUE_START_LOCAL_APPOINTMENT_EDIT:
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.id
            ? invalidateAllWithLocalChangesRefChecks({
                ...item,
                customer_id: action.customer_id || item.customer_id,
                pendingCancel: null,
              })
            : item
        ),
      };

    case VENUE_LEAVE_LOCAL_APPOINTMENT_EDIT:
      if (!state.items.find((item) => item.id === action.id && item.__isDraft)) {
        return state;
      }

      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.id
            ? invalidateAllWithLocalChangesRefChecks({
                ...item,
                /**
                 * Remember customer_id when leaving a draft appointment
                 */
                customer_id: action.customer_id || item.customer_id,
                pendingCancel: new Date().getTime() + (action.pendingTimeout || 15000),
              })
            : item
        ),
      };

    default:
      return state;
  }
};

export const localAppointmentsByVenue = (state = {}, action) => {
  switch (action.type) {
    /**
     * VENUE_LOCAL_APPOINTMENT_CREATE
     * viene generato un nuovo appointment in locale
     */
    case VENUE_LOCAL_APPOINTMENT_CREATE:
      if (!action.venue_id || !action.time) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: localAppointments(state[action.venue_id], action),
      };

    /**
     * VENUE_APPOINTMENT_CREATE
     * viene generato un nuovo appointment sul backend, associa l'ID remoto all'appointment locale
     */
    case 'VENUE_APPOINTMENT_CREATE':
      if (!action.venue_id || !action.id || !action.appointment) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: localAppointments(state[action.venue_id], action),
      };

    /**
     * VENUE_APPOINTMENT_DELETE_RESPONSE
     * viene eliminato un appointment sul backend, si deve eliminare la sua istanza sul client
     */
    case 'VENUE_APPOINTMENT_DELETE_RESPONSE':
      if (!action.venue_id || (!action.appointment && !action.appointments)) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: localAppointments(state[action.venue_id], action),
      };

    case VENUE_LOCAL_APPOINTMENT_DELETE:
    case VENUE_LOCAL_APPOINTMENT_EDIT:
    case VENUE_START_LOCAL_APPOINTMENT_EDIT:
    case VENUE_LEAVE_LOCAL_APPOINTMENT_EDIT:
      if (!action.venue_id || !action.id) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: localAppointments(state[action.venue_id], action),
      };

    default:
      return state;
  }
};
