import { ModalProps } from 'actions/modals';
import { Toast, TOAST_TYPE } from 'ui-treatwell-pro';
import { treatwellProTheme } from 'components/ui-treatwell-pro';
import { ReactElement } from 'react';
import { styled } from 'styles';
import { ValueOf } from 'utils/utilityTypes';

export const ToastMessage = styled.div`
  position: absolute;
  width: 100%;
  font-family: ${treatwellProTheme.typography.fontFamilies.default}!important;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 48px;
  & :first-child {
    z-index: 99999;
  }
  @media only screen and (max-width: 600px) {
    position: absolute;
    top: unset;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    border-radius: unset;
  }
`;

const ToastNotification = (
  rest: Omit<
    ModalProps<{ type: ValueOf<typeof TOAST_TYPE>; content: ReactElement; onClose: () => void; fixed?: boolean }>,
    'closeModal'
  >
): JSX.Element => {
  !rest?.config.fixed && setTimeout(() => rest?.config?.onClose(), 4000);

  return (
    <ToastMessage data-testid="toast_message">
      <Toast {...rest} />
    </ToastMessage>
  );
};

//default for lazy load
export default ToastNotification;
