import PropTypes from 'prop-types';
import { Text } from 'components/core';
import Currency from './Currency';

const PriceAmount = (props) => {
  const { numberProps, currencyColor, value, prefix, suffix, ...textProps } = props;
  return (
    <Currency>
      {(currencySymbol) => (
        <Text
          className="PriceAmount Text"
          {...textProps}
          number={value}
          numberProps={numberProps}
          currency={currencySymbol}
          currencyColor={currencyColor}
          prefix={prefix}
          suffix={suffix}
          data-testid={props['data-testid']}
          data-testclass={props['data-testclass']}
        />
      )}
    </Currency>
  );
};

PriceAmount.propTypes = {
  value: PropTypes.number,
  numberProps: PropTypes.object,
  currencyColor: PropTypes.string,
  color: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  size: PropTypes.number,
  bold: PropTypes.bool,
  right: PropTypes.bool,
  'data-testid': PropTypes.string,
  'data-testclass': PropTypes.string,
};

PriceAmount.defaultProps = {
  'data-testid': undefined,
  'data-testclass': undefined,
  numberProps: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
};

export default PriceAmount;
