import { FunctionComponent, useEffect, useMemo, useRef } from 'react';
import { HostStore } from 'host/store';
import { RemoteModule } from 'host/types';
import { createEventEmitter, EventEmitter } from 'host/eventEmitter';
import getBaseFromPathName from 'utils/upgrade/getBaseFromPathName';

type Props = {
  module: RemoteModule<EventEmitter>;
  store: HostStore;
  payload?: unknown;
};

export const RemoteModuleRenderer: FunctionComponent<Props> = ({ module, store, payload }) => {
  const ref = useRef<HTMLDivElement>(null);

  const eventEmitter = useMemo(() => {
    if (!store) return null;
    // Creates eventEmitter and subscribe it to store
    return createEventEmitter(store, module.remote?.scope);
  }, [store, module]);

  useEffect(() => {
    const element = ref.current;
    if (!element || !eventEmitter) return;

    // Extract `homepage` path to be passed to the remote component
    const homepage =
      getBaseFromPathName(window.location.pathname).replace(/\/+$/, '') + '/apps/' + module.remote?.scope;

    // Creates an HTML node and attach to parent element
    const rootNode = document.createElement('div');
    element.appendChild(rootNode);

    try {
      module.render({ eventEmitter, rootNode, homepage, basename: homepage, payload });
    } catch (error) {
      console.error('Remote `' + module.remote?.scope + '` render() has generated the following error:', error);
    }

    return () => {
      // Emit an `unmount` event targeting the eventEmitter
      const event = {
        source: null,
        target: eventEmitter,
        scope: module.remote?.scope,
        type: 'unmount',
        value: 'unmount',
      };
      store.dispatchEvent<string>(event);

      // Execute detach() method on eventEmitter
      if (eventEmitter) {
        eventEmitter.detach();
      }

      // Empties the HTML node (if exists)
      if (rootNode) {
        element.removeChild(rootNode);
        rootNode.remove();
      }
    };
  }, [ref, module, store, eventEmitter, payload]);

  return <div className="remote-module-renderer" ref={ref} />;
};
