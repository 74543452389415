import { FunctionComponent, ReactElement, ComponentProps } from 'react';
import Spinner from 'components/core/Spinner';

type SpinnerCenteredProps = ComponentProps<typeof Spinner> & {
  // backwards compatibility...
  isLoading?: boolean;
  pastDelay?: boolean;
  timedOut?: boolean;
  error?: unknown;
  retry?: CallableFunction;
};

const SpinnerCentered: FunctionComponent<SpinnerCenteredProps> = (props): ReactElement => (
  <Spinner loading={props.isLoading} centered={true} {...props} />
);

export default SpinnerCentered;
