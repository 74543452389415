import { StandardAction } from 'actions/types';
import { APIResponseAction } from 'actions/data_providers/data_providers_api.types';

const isStandardAction = <Type extends string, Payload = undefined>(
  action: StandardAction<Type, Payload> | APIResponseAction
): boolean => Object.prototype.hasOwnProperty.call(action, 'payload');

const standardiseAction = <Type extends string, Payload = undefined>(
  action: StandardAction<Type, Payload> | APIResponseAction
): StandardAction<Type, Payload> => {
  switch (true) {
    case isStandardAction(action):
      return action as StandardAction<Type, Payload>;
    default: {
      const { response, type, venue_id } = action as APIResponseAction;
      const payload = { ...response?.data, venue_id } as unknown as Payload;

      return { type, payload } as StandardAction<Type, Payload>;
    }
  }
};

export { standardiseAction };
