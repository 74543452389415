const FullStar = ({ color }: { color?: string }): JSX.Element => (
  <svg width="27" height="25" viewBox="0 0 27 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.288 0.756023L17.3699 7.9325L25.2043 8.63077V8.62864C25.5363 8.65632 25.8275 8.81812 26.0289 9.05655C26.1318 9.17746 26.2097 9.31734 26.2582 9.46821C26.3067 9.61907 26.3249 9.77795 26.3116 9.93578C26.2983 10.0936 26.3116 9.93791 26.3116 9.93791C26.2864 10.2262 26.158 10.4959 25.9496 10.6979C25.9239 10.7256 25.8961 10.7511 25.8661 10.7746L19.9721 15.8903L21.7262 23.5181C21.8011 23.8438 21.7369 24.1695 21.5698 24.4314C21.4853 24.5661 21.3748 24.6829 21.2447 24.7749C21.1146 24.867 20.9674 24.9326 20.8117 24.9678L20.7817 24.9742C20.6296 25.004 20.4754 25.0062 20.3276 24.9785V24.9806C20.167 24.9529 20.0128 24.8912 19.8757 24.8018L19.865 24.7954L13.1615 20.8123L6.40442 24.8273C6.26743 24.9093 6.1155 24.9635 5.95736 24.9869C5.79922 25.0103 5.63799 25.0023 5.48294 24.9636C5.32789 24.9248 5.18208 24.8559 5.0539 24.7609C4.92572 24.6659 4.81769 24.5467 4.73603 24.4101C4.65465 24.2738 4.60325 24.1269 4.57969 23.98C4.55827 23.8459 4.56041 23.7097 4.58397 23.5777C4.58825 23.55 4.59254 23.5202 4.6011 23.4925L6.34874 15.8924C4.3698 14.1744 2.33518 12.4649 0.388372 10.7192L0.371238 10.7022C0.153855 10.4945 0.0224579 10.2136 0.00286531 9.91449C-0.0185517 9.6058 0.0799667 9.29073 0.302704 9.03739L0.328404 9.00972L0.345538 8.99268C0.558537 8.7737 0.846972 8.6429 1.15296 8.62652H1.16581L8.94876 7.9325L12.0414 0.736863C12.172 0.430303 12.4183 0.204641 12.7075 0.0875522C12.9966 -0.0274077 13.3286 -0.0337943 13.637 0.0960677C13.7847 0.157805 13.9154 0.247219 14.0225 0.355792C14.136 0.468623 14.2238 0.600614 14.2838 0.743249L14.288 0.756023Z"
      fill={color || '#4A93F9'}
    />
  </svg>
);

export default FullStar;
