import { Venue, Package } from 'models';
import {
  RECEIVE_PACKAGE,
  RECEIVE_PACKAGE_LIST,
  REQUEST_PACKAGE,
  REQUEST_PACKAGE_LIST,
  RECEIVE_NEW_PACKAGE,
  RECEIVE_DELETED_PACKAGE,
  RECEIVE_UPDATED_PACKAGE,
} from 'actionTypes';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { apiGet, apiPost, METHOD } from './data_providers/api';
import {
  StandardAction,
  ParametricActionCreator,
  DefaultPayload,
  ActionCreator,
  ActionDispatch,
  GetState,
} from './types';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { actionShouldFetchData } from 'utils/reducersUtils';

// http://docs.uala.it/api/v1/packages
const buildPackagePathByVenueId = (venueId: Venue['id'], entityId?: Package['id']): string => {
  switch (true) {
    case !!entityId:
      return `/venues/${venueId}/packages/${entityId}`;
    default:
      return `/venues/${venueId}/packages`;
  }
};

// === MUTATIONS === //
/* Create */
type ReceiveNewPackagePayload = { package: Package } & DefaultPayload;
type ReceiveNewPackage = StandardAction<typeof RECEIVE_NEW_PACKAGE, ReceiveNewPackagePayload>;

const receiveNewPackage = (payload: ReceiveNewPackagePayload): ReceiveNewPackage => ({
  type: RECEIVE_NEW_PACKAGE,
  payload,
});

const createPackage: ParametricActionCreator<Package, Promise<{ payload: Package }>> = (pkg) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());

    dispatch(
      apiPost({
        path: buildPackagePathByVenueId(venue_id),
        data: {
          package: pkg,
        },
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          dispatch(receiveNewPackage({ venue_id, package: response.data.package }));
          resolve({ payload: response.data.package });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

/* Update */
type ReceiveUpdatedPackagePayload = { package: Package } & DefaultPayload;
type ReceiveUpdatedPackage = StandardAction<typeof RECEIVE_UPDATED_PACKAGE, ReceiveUpdatedPackagePayload>;

const receiveUpdatedPackage = (payload: ReceiveUpdatedPackagePayload): ReceiveUpdatedPackage => ({
  type: RECEIVE_UPDATED_PACKAGE,
  payload,
});

const updatePackage: ParametricActionCreator<Package, Promise<{ payload: Package }>> = (pkg) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    if (!venue_id) {
      return;
    }

    // http://docs.uala.it/api/v1/venue_treatments
    dispatch(
      apiPost({
        path: buildPackagePathByVenueId(venue_id, pkg.id),
        method: METHOD.PUT,
        data: {
          package: pkg,
        },
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }
          dispatch(receiveUpdatedPackage({ venue_id, package: response.data.package }));
          resolve({ payload: response.data.package });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

/* Delete */
type ReceiveDeletePackagePayload = { package: Package } & DefaultPayload;
type ReceiveDeletePackage = StandardAction<typeof RECEIVE_DELETED_PACKAGE, ReceiveDeletePackagePayload>;

const receiveDeletedPackage = (payload: ReceiveDeletePackagePayload): ReceiveDeletePackage => ({
  type: RECEIVE_DELETED_PACKAGE,
  payload,
});

const deletePackage: ParametricActionCreator<{ id: Package['id'] }, Promise<{ payload: Package }>> =
  ({ id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      dispatch(
        apiPost({
          method: METHOD.DELETE,
          path: buildPackagePathByVenueId(venue_id, id),
          data: {},
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              receiveDeletedPackage({
                venue_id,
                package: response.data.package,
              })
            );

            resolve({ payload: response.data.package });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

// === QUERIES === //
type RequestPackageList = StandardAction<typeof REQUEST_PACKAGE_LIST, DefaultPayload>;
const requestPackageList = (payload: DefaultPayload): RequestPackageList => ({
  type: REQUEST_PACKAGE_LIST,
  payload,
});

type PackageListPayload = {
  packages: ReadonlyArray<Package>;
} & DefaultPayload;
type ReceivePackageList = StandardAction<typeof RECEIVE_PACKAGE_LIST, PackageListPayload>;

const receivePackageList = (payload: PackageListPayload): ReceivePackageList => ({
  type: RECEIVE_PACKAGE_LIST,
  payload,
});

const fetchPackageList: ActionCreator<Promise<{ payload: ReadonlyArray<Package> }>> = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());

    dispatch(requestPackageList({ venue_id }));

    dispatch(
      apiGet({
        path: buildPackagePathByVenueId(venue_id),
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          dispatch(receivePackageList({ venue_id, packages: response.data.packages }));

          resolve({ payload: response.data.packages });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

export const fetchAdvancedPackagesIfNeeded = () => {
  return (dispatch: ActionDispatch, getState: GetState): Nullable<Promise<{ payload: ReadonlyArray<Package> }>> => {
    const state = getState();
    const venue_id = getCurrentVenueId(state);
    const auth = getCurrentUserToken(state);
    if (venue_id && auth && actionShouldFetchData(state.packagesByVenue[venue_id])) {
      return dispatch(fetchPackageList());
    }
    return null;
  };
};

type RequestPackage = StandardAction<typeof REQUEST_PACKAGE, DefaultPayload>;
const requestPackage = (payload: DefaultPayload): RequestPackage => ({
  type: REQUEST_PACKAGE,
  payload,
});

type ReceivePackagePayload = { package: Package } & DefaultPayload;
type ReceivePackage = StandardAction<typeof RECEIVE_PACKAGE, ReceivePackagePayload>;
const receivePackage = (payload: ReceivePackagePayload): ReceivePackage => ({
  type: RECEIVE_PACKAGE,
  payload,
});

const fetchPackage: ParametricActionCreator<Package['id'], Promise<{ payload: Package }>> =
  (packageId) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      dispatch(requestPackage({ venue_id }));

      dispatch(
        apiGet({
          path: buildPackagePathByVenueId(venue_id, packageId),
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(receivePackage({ venue_id, package: response.data.package }));

            resolve({ payload: response.data.package });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export { createPackage, updatePackage, deletePackage, fetchPackage, fetchPackageList };
export type PackagesActions =
  | ReceiveDeletePackage
  | ReceiveNewPackage
  | ReceiveUpdatedPackage
  | RequestPackage
  | ReceivePackage
  | RequestPackageList
  | ReceivePackageList;
