import { Country } from 'models';
import officialDomains from './officialSaaSDomains.json';

const getApplicationHostname = (
  currentHostname: string,
  countries: ReadonlyArray<Country>,
  currentCountryId: number
): string => {
  const currentDomain = currentHostname.split('.').slice(0, 2).join('.');

  const isOfficial = officialDomains.find((officialDomain) => officialDomain === currentDomain);

  if (isOfficial) {
    return countries.find((country) => country.id === currentCountryId)?.default_domain?.manager_url ?? currentHostname;
  }

  return currentHostname;
};

export { getApplicationHostname };
