import { CSSProperties, MouseEventHandler } from 'react';
import { Colors, COLORS, css, styled } from 'styles';
import { LiteralUnion } from 'utils/utilityTypes';

type Color = LiteralUnion<Colors>;

const getColor = (color: Color): string => {
  if (color in COLORS) {
    return COLORS[color as Colors];
  }

  return color;
};

export type StyledSectionProps = {
  absolute?: boolean;
  relative?: boolean;
  displayFlex?: boolean;
  flex?: CSSProperties['flex'] | boolean;
  flexDirection?: CSSProperties['flexDirection'];
  flexBasis?: CSSProperties['flexBasis'];
  flexWrap?: CSSProperties['flexWrap'];
  flexGrow?: CSSProperties['flexGrow'];
  flexShrink?: CSSProperties['flexShrink'];
  flexFlow?: CSSProperties['flexFlow'];
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  alignSelf?: CSSProperties['alignSelf'];
  whiteSpace?: CSSProperties['whiteSpace'];
  maxWidth?: CSSProperties['maxWidth'];
  minHeight?: CSSProperties['minHeight'];
  overflow?: CSSProperties['overflow'];
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  inline?: boolean;
  center?: boolean;
  right?: boolean;
  background?: Colors;
  color?: Color;
  shape?: 'card' | 'field';
  borderRadius?: boolean;
  borderStyle?: CSSProperties['borderStyle'];
  grid?: boolean;
  gridTemplateColumns?: boolean;
  hideIfEmpty?: boolean;
  zIndex?: CSSProperties['zIndex'];
  padding?: number;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingTop?: number;
  paddingRight?: number;
  margin?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
  marginTop?: number;
  borderTop?: boolean;
  borderRight?: boolean;
  borderBottom?: boolean;
  borderLeft?: boolean;
  cursor?: CSSProperties['cursor'];
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export const Section = styled('div')<StyledSectionProps>(
  ({
    theme: { colors, sizes, media },
    onClick,
    cursor,
    absolute,
    relative,
    displayFlex,
    flex,
    flexDirection,
    flexBasis,
    flexWrap,
    flexGrow,
    flexFlow,
    flexShrink,
    justifyContent,
    alignSelf,
    alignItems,
    whiteSpace,
    maxWidth,
    minHeight,
    inline,
    center,
    right,
    background,
    shape,
    color,
    overflow,
    width,
    height,
    padding,
    paddingBottom,
    paddingTop,
    paddingLeft,
    paddingRight,
    margin,
    marginBottom,
    marginRight,
    marginTop,
    marginLeft,
    borderTop,
    borderRight,
    borderBottom,
    borderLeft,
    borderRadius,
    borderStyle = 'solid',
    grid,
    gridTemplateColumns,
    hideIfEmpty,
    zIndex,
  }) => css`
    ${flex === true || displayFlex
      ? css`
          display: flex;
          flex-direction: ${flexDirection || 'row'};
          flex-basis: ${flexBasis || 'auto'};
          align-items: ${alignItems || 'stretch'};
        `
      : inline
      ? { display: 'inline' }
      : { display: 'block' }};

    ${flex && flex !== true && { flex: flex }};
    ${justifyContent && { justifyContent }};
    ${alignSelf && { alignSelf }};
    ${alignItems && { alignItems }};
    ${flexWrap && { flexWrap }};
    ${flexGrow && { flexGrow }};
    ${flexShrink && { flexShrink }};
    ${flexFlow && { flexFlow }};
    ${maxWidth && { maxWidth }};
    ${minHeight && { minHeight }};
    ${whiteSpace && { whiteSpace }};

    ${onClick && { cursor: 'pointer' }};
    ${cursor && { cursor: 'cursor' }};
    ${center && { textAlign: 'center' }};
    ${right && { textAlign: 'right' }};
    ${absolute && { position: 'absolute' }};
    ${relative && { position: 'relative' }};
    ${overflow && { overflow: overflow }};
    ${background && { background: COLORS[background] || 'transparent' }};
    ${color && { color: getColor(color) }};
    ${width && { width: width }};
    ${height && { height: height }};
    ${zIndex && `z-index: ${zIndex}`};

    ${borderTop && { borderTop: `1px ${borderStyle} ${colors.blockBorder}` }};
    ${borderRight && { borderRight: `1px ${borderStyle} ${colors.blockBorder}` }};
    ${borderBottom && { borderBottom: `1px ${borderStyle} ${colors.blockBorder}` }};
    ${borderLeft && { borderLeft: `1px ${borderStyle} ${colors.blockBorder}` }};
    ${borderRadius && { borderRadius: `${sizes.borderRadiusMedium}px;` }};

    ${grid &&
    css`
      display: grid;

      ${gridTemplateColumns &&
      `
            -ms-grid-columns: ${gridTemplateColumns};
            grid-template-columns: ${gridTemplateColumns};
          `};
    `};

    ${shape === 'card'
      ? css`
          border: 1px solid ${colors.blockBorder};
          border-radius: ${sizes.borderRadiusMedium}px;
        `
      : shape === 'field'
      ? css`
          border: 1px solid ${colors.inputBorder};
          border-radius: 21px;
        `
      : ''};

    ${hideIfEmpty
      ? css`
          &:empty {
            display: none;
          }
        `
      : ''};

    ${(padding || padding === 0) && { padding: `${padding * sizes.padding}px ${(padding * sizes.padding) / 2}px` }};
    ${(paddingBottom || paddingBottom === 0) && { paddingBottom: paddingBottom * sizes.padding }};
    ${(paddingTop || paddingTop === 0) && { paddingTop: paddingTop * sizes.padding }};
    ${(paddingLeft || paddingLeft === 0) && { paddingLeft: (paddingLeft * sizes.padding) / 2 }};
    ${(paddingRight || paddingRight === 0) && { paddingRight: (paddingRight * sizes.padding) / 2 }};

    ${(margin || margin === 0) && { margin: margin * sizes.padding }};
    ${(marginBottom || marginBottom === 0) && { marginBottom: marginBottom * sizes.padding }};
    ${(marginRight || marginRight === 0) && { marginRight: marginRight * sizes.padding }};
    ${(marginTop || marginTop === 0) && { marginTop: marginTop * sizes.padding }};
    ${(marginLeft || marginLeft === 0) && { marginLeft: marginLeft * sizes.padding }};

    ${media.tablet`
        ${(padding || padding === 0) && { padding: padding * sizes.padding }};
        ${(paddingBottom || paddingBottom === 0) && { paddingBottom: paddingBottom * sizes.padding }};
        ${(paddingTop || paddingTop === 0) && { paddingTop: paddingTop * sizes.padding }};
        ${(paddingLeft || paddingLeft === 0) && { paddingLeft: paddingLeft * sizes.padding }};
        ${(paddingRight || paddingRight === 0) && { paddingRight: paddingRight * sizes.padding }};
      `};
  `
);
