import moment from 'moment';
import {
  AGENDA_ITEM_CUT,
  AGENDA_RESET_CUT_ITEMS,
  AGENDA_SET_ACTIVE_CUSTOMER,
  AGENDA_RESET_ACTIVE_CUSTOMER,
  AGENDA_UPDATE_NEW_CUSTOMER_DATA,
  AGENDA_RESET_NEW_CUSTOMER_DATA,
} from 'actionTypes';
import { createLocalAppointment } from './appointments';
import { getAgendaCutItems, getAgendaActiveCustomer } from 'selectors/getAgendaGrid';
import { getPermissionChecker } from 'selectors/venue/getVenuePermissions';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';

const agendaCutItemAction = ({ id, venue_id }) => ({
  type: AGENDA_ITEM_CUT,
  id,
  venue_id,
});

export const agendaCutItem =
  ({ id }) =>
  (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    dispatch(agendaCutItemAction({ id, venue_id }));
  };

const agendaResetCutItemsAction = ({ venue_id }) => ({
  type: AGENDA_RESET_CUT_ITEMS,
  venue_id,
});

export const agendaResetCutItems = () => (dispatch, getState) => {
  const venue_id = getCurrentVenueId(getState());
  dispatch(agendaResetCutItemsAction({ venue_id }));
};

/**
 * activeCustomer by venue
 */
const agendaSetActiveCustomerAction = ({ customer, venue_id }) => ({
  type: AGENDA_SET_ACTIVE_CUSTOMER,
  customer,
  venue_id,
});

const agendaResetActiveCustomerAction = ({ venue_id }) => ({
  type: AGENDA_RESET_ACTIVE_CUSTOMER,
  venue_id,
});

const activeCustomerTimer = {};

export const agendaSetActiveCustomer =
  ({ customer }) =>
  (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    clearTimeout(activeCustomerTimer[venue_id]);
    dispatch(agendaSetActiveCustomerAction({ customer, venue_id }));
  };

export const agendaResetActiveCustomer = (now) => (dispatch, getState) => {
  const venue_id = getCurrentVenueId(getState());
  clearTimeout(activeCustomerTimer[venue_id]);
  if (now) {
    return dispatch(agendaResetActiveCustomerAction({ venue_id }));
  }
  activeCustomerTimer[venue_id] = setTimeout(() => {
    dispatch(agendaResetActiveCustomerAction({ venue_id }));
  }, 15000);
};

/**
 * newCustomer by venue
 */
const agendaUpdateNewCustomerDataAction = ({ customer, venue_id }) => ({
  type: AGENDA_UPDATE_NEW_CUSTOMER_DATA,
  customer,
  venue_id,
});

export const agendaUpdateNewCustomerData =
  ({ customer }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      dispatch(agendaUpdateNewCustomerDataAction({ customer, venue_id }));
      resolve();
    });

const agendaResetNewCustomerDataAction = ({ venue_id }) => ({
  type: AGENDA_RESET_NEW_CUSTOMER_DATA,
  venue_id,
});

export const agendaResetNewCustomerData = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    dispatch(agendaResetNewCustomerDataAction({ venue_id }));
    resolve();
  });

export const createNewItem =
  ({ rowValue, staff_member_id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      /**
       * ACL_CHECK
       */
      const checkPermission = getPermissionChecker(getState());
      if (checkPermission('calendar_appointment-list_inserting-new-appointment') !== 'full') {
        return;
      }

      const cutItems = getAgendaCutItems(getState());
      if (cutItems.length > 0) {
        return;
      }

      const activeCustomer = getAgendaActiveCustomer(getState());
      const customer_id = activeCustomer ? activeCustomer.id : null;

      dispatch(
        createLocalAppointment({
          customer_id,
          staff_member_id,
          time: moment.unix(rowValue),
        })
      );
      resolve();
    });
