const DELTA_TREND_COLOR_VALUES = ['green', 'red', 'text1'] as const;

type DeltaTrendColorValues = (typeof DELTA_TREND_COLOR_VALUES)[number];

const DELTA_TREND_COLORS = {
  POSITIVE: DELTA_TREND_COLOR_VALUES[0],
  NEGATIVE: DELTA_TREND_COLOR_VALUES[1],
  NEUTRAL: DELTA_TREND_COLOR_VALUES[2],
} as const;

export const DELTA_TREND_VALUE_TYPES = {
  income: 'income',
  cost: 'cost',
} as const;

type ValueType = keyof typeof DELTA_TREND_VALUE_TYPES;

/**
 * Evaluates an input value and returns the matching color. Negative values are considered as a positive
 * trend whenever they refer to a cost.
 *
 * @param {number} value - the value to be checked.
 * @param {ValueType} type - the type of value, either 'income' or 'cost'.
 * @returns {DeltaTrendColorValues} the matching delta trend color for the given input value.
 */
const getDeltaTrendColor = (
  value: number | undefined,
  type: ValueType = DELTA_TREND_VALUE_TYPES.income
): DeltaTrendColorValues => {
  if (value && value > 0) {
    return type === 'cost' ? DELTA_TREND_COLORS.NEGATIVE : DELTA_TREND_COLORS.POSITIVE;
  }

  if (value && value < 0) {
    return type === 'cost' ? DELTA_TREND_COLORS.POSITIVE : DELTA_TREND_COLORS.NEGATIVE;
  }

  return DELTA_TREND_COLORS.NEUTRAL;
};

export { getDeltaTrendColor, DELTA_TREND_COLORS };
