import PropTypes from 'prop-types';
import { Action, Card, Fieldset, Header, Right, Section, Title } from './theme';

const CardComponent = (props) => <Card className="Card" {...props} />;

CardComponent.displayName = 'Card';

CardComponent.defaultProps = {
  noShadow: false,
};

CardComponent.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.any,
  onClick: PropTypes.func,
  background: PropTypes.string,
  borderless: PropTypes.bool,
  absolute: PropTypes.bool,
  noShadow: PropTypes.bool,
  className: PropTypes.string,
  height: PropTypes.string,
  border: PropTypes.string,
};

/**
 * Vars
 */

const propTypes = {
  section: {
    collapse: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    background: PropTypes.string,
    borderless: PropTypes.bool,
    fontSize: PropTypes.number,
    relative: PropTypes.bool,
    onClick: PropTypes.func,
    overflow: PropTypes.string,
    bold: PropTypes.bool,
    uppercase: PropTypes.bool,
  },
};

const defaultProps = {
  section: {
    collapse: '',
    background: '',
  },
};

/**
 * <Card.Section collapse="all" background="grey" />
 */
Section.displayName = 'Card.Section';

Section.defaultProps = {
  ...defaultProps.section,
};

Section.propTypes = {
  ...propTypes.section,
};

CardComponent.Section = (props) => <Section className="CardSection" {...props} />;

/**
 * <Card.Header collapse="all" background="grey" font={1} />
 */
Header.displayName = 'Card.Header';

Header.defaultProps = {
  ...defaultProps.section,
};

Header.propTypes = {
  ...propTypes.section,
};

CardComponent.Header = (props) => <Header className="CardHeader" {...props} />;

/**
 * <Card.Title collapse="all" background="grey" font={1} />
 */
Title.displayName = 'Card.Title';

Title.defaultProps = {
  ...defaultProps.section,
};

Title.propTypes = {
  ...propTypes.section,
};

CardComponent.Title = Title;

/**
 * <Card.Action collapse="all" background="grey" />
 */
Action.displayName = 'Card.Action';

Action.defaultProps = {
  ...defaultProps.section,
};

Action.propTypes = {
  ...propTypes.section,
};

CardComponent.Action = Action;

/**
 * <Card.Fieldset />
 */
Fieldset.displayName = 'Card.Fieldset';

Fieldset.defaultProps = {
  ...defaultProps.section,
};

Fieldset.propTypes = {
  ...propTypes.section,
};

CardComponent.Fieldset = (props) => <Fieldset className="CardFieldset" {...props} />;

/**
 * <Card.Right />
 */
Right.displayName = 'Card.Right';

Right.defaultProps = {
  ...defaultProps.section,
};

Right.propTypes = {
  ...propTypes.section,
};

CardComponent.Right = (props) => <Right className="CardRight" {...props} />;

export default CardComponent;
