import {
  VENUE_APPOINTMENTS_LIST_REQUEST,
  VENUE_APPOINTMENTS_LIST_RESPONSE,
  VENUE_APPOINTMENT_EDIT_REQUEST,
  VENUE_APPOINTMENT_EDIT_RESPONSE,
  VENUE_LOCAL_APPOINTMENT_CREATE,
  VENUE_LOCAL_APPOINTMENT_DELETE,
  VENUE_LOCAL_APPOINTMENT_EDIT,
  VENUE_START_LOCAL_APPOINTMENT_EDIT,
  VENUE_LEAVE_LOCAL_APPOINTMENT_EDIT,
} from 'actionTypes';

export const appointmentsRequestAction = ({ venue_id, auth }) => ({
  type: VENUE_APPOINTMENTS_LIST_REQUEST,
  venue_id,
  auth,
});

export const appointmentsResponseAction = ({ error, venue_id, appointments, response }) => ({
  type: VENUE_APPOINTMENTS_LIST_RESPONSE,
  venue_id,
  appointments,
  response,
  error,
});

export const appointmentEditRequestAction = ({ appointment, venue_id, auth }) => ({
  type: VENUE_APPOINTMENT_EDIT_REQUEST,
  venue_id,
  auth,
  appointment,
});

export const appointmentEditResponseAction = ({ error, venue_id, response }) => ({
  type: VENUE_APPOINTMENT_EDIT_RESPONSE,
  venue_id,
  response,
  error,
});

export const appointmentDeleteRequestAction = ({ id, state, venue_id, auth }) => ({
  type: 'VENUE_APPOINTMENT_DELETE_REQUEST',
  venue_id,
  auth,
  id,
  state,
});

export const appointmentDeleteResponseAction = ({ error, venue_id, id, appointment, response }) => ({
  type: 'VENUE_APPOINTMENT_DELETE_RESPONSE',
  venue_id,
  id,
  appointment,
  response,
  error,
});

export const createLocalAppointmentAction = ({
  venue_id,
  id,
  customer_id,
  staff_member_id,
  time,
  whenFirstTimeDisplayed,
}) => ({
  type: VENUE_LOCAL_APPOINTMENT_CREATE,
  venue_id,
  id,
  customer_id,
  staff_member_id,
  time,
  whenFirstTimeDisplayed,
});

export const createAppointmentAction = ({ venue_id, appointment, id }) => ({
  type: 'VENUE_APPOINTMENT_CREATE',
  venue_id,
  appointment,
  id,
});

export const editLocalAppointmentAction = ({ venue_id, id, remote, changes }) => ({
  type: VENUE_LOCAL_APPOINTMENT_EDIT,
  venue_id,
  id,
  remote,
  changes,
});

export const deleteLocalAppointmentAction = ({ venue_id, id }) => ({
  type: VENUE_LOCAL_APPOINTMENT_DELETE,
  venue_id,
  id,
});

export const startLocalAppointmentEditAction = ({ venue_id, id, customer_id }) => ({
  type: VENUE_START_LOCAL_APPOINTMENT_EDIT,
  venue_id,
  id,
  customer_id,
});

export const leaveLocalAppointmentEditAction = ({ venue_id, id, pendingTimeout, customer_id }) => ({
  type: VENUE_LEAVE_LOCAL_APPOINTMENT_EDIT,
  venue_id,
  id,
  pendingTimeout,
  customer_id,
});

export const appointmentBulkRepeatRequestAction = ({ appointment_ids, venue_id, auth }) => ({
  type: 'VENUE_APPOINTMENT_BULK_REPEAT_REQUEST',
  venue_id,
  auth,
  appointment_ids,
});

export const appointmentBulkRepeatResponseAction = ({ error, venue_id, appointment_ids, appointments, response }) => ({
  type: 'VENUE_APPOINTMENT_BULK_REPEAT_RESPONSE',
  venue_id,
  appointment_ids,
  appointments,
  response,
  error,
});
