import { FunctionComponent, ReactNode } from 'react';
import CallerBox from 'containers/agenda/CallerBox';
import { HeaderWrapper } from './Header.theme';
import HeaderTriggerMultiVenueSidebar from './HeaderTriggerMultiVenueSidebar.container';

type HeaderComponentProps = {
  minimizeCallerBox?: boolean;
  children?: ReactNode;
};

const HeaderComponent: FunctionComponent<HeaderComponentProps> = ({ minimizeCallerBox = false, children }) => (
  <HeaderWrapper className="Header">
    <HeaderTriggerMultiVenueSidebar />
    <div className="Header__inner">{children}</div>

    <CallerBox minimized={minimizeCallerBox} />
  </HeaderWrapper>
);

HeaderComponent.displayName = 'Header';

export default HeaderComponent;
