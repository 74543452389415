import { styled, css } from 'styles';
import { withTheme } from 'components/core';

type VenueImageProps = {
  src: string;
  isSelected: boolean;
};

const imageSize = 54;

export const VenueImage = withTheme(
  styled('div')<VenueImageProps>(
    ({ theme: { colors, media }, isSelected, src }) => css`
      position: relative;
      width: ${imageSize}px;
      height: ${imageSize}px;
      border-radius: 8px;
      z-index: 2;

      ${src
        ? css`
            background-position: center;
            background-image: url(${src});
            background-repeat: no-repeat;
            background-size: cover;
          `
        : css`
            background-color: ${colors.inputBorder};
            display: flex;
            justify-content: center;
            align-items: center;
          `}

      ${isSelected &&
      css`
        border: 3px solid ${colors.text2};
        width: ${imageSize + 3}px;
        height: ${imageSize + 3}px;

        ${media.tabletOrSmaller`
            border: 3px solid ${colors.white};
          `}

        &::before {
          content: '';
          position: absolute;
          border-radius: 8px;
          width: calc(100% + 12px);
          height: calc(100% + 12px);
          left: -6px;
          top: -6px;
          border: 3px solid ${colors.white};
          border-radius: 11px;

          ${media.tabletOrSmaller`
              border: 3px solid ${colors.blue};
            `}
        }
      `}
    `
  )
);

export const VenueText = withTheme(
  styled('span')(
    ({ theme: { colors, media } }) => css`
      size: ${16 / 10}rem;
      color: ${colors.white};
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;

      ${media.tabletOrSmaller`
        color: ${colors.text2};
      `}
    `
  )
);

type SynchedItemProps = {
  isActive?: boolean;
};

export const SynchedItem = withTheme(
  styled('div')<SynchedItemProps>(
    ({ theme: { colors, media }, isActive }) => css`
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: -9px;
      right: -9px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      background: ${colors.text2};
      z-index: 2;

      ${media.tabletOrSmaller`
        background: ${colors.white};
      `}

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: ${isActive ? colors.green : colors.text2};
        ${!isActive &&
        css`
          border: 2px solid ${colors.inputPlaceHolder};
        `}
        transform: scale(0.6666);
        transform-origin: center;
      }
    `
  )
);

type WrapperProps = {
  isSelected?: boolean;
  isDisabled?: boolean;
};

export const Wrapper = withTheme(
  styled('div')<WrapperProps>(
    ({ theme: { colors }, isSelected, isDisabled }) => css`
      position: relative;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      width: ${imageSize}px;
      cursor: pointer;

      ${isDisabled &&
      !isSelected &&
      css`
        opacity: 0.5;
        pointer-events: none;
        cursor: default;
      `}

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      ${!isSelected &&
      css`
          .VenueButton__Image {
            &::after {
              content: '';
              position: absolute;
              width: calc(100% + 6px);
              height: calc(100% + 6px);
              left: -3px;
              top: -3px;
              border-radius: 11px;
              border: 3px solid ${colors.inputPlaceHolder};
              opacity: 0;
              transition: 0.15s ease-in;
            }
          }

          &:hover {
            .VenueButton__Image {
            &::after {
              opacity: 1;
            }
          }
        `}
    `
  )
);
