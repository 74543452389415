/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { ProductShowResponse, ProductShowQuery } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const SHOW =
  ({ variables: { product_id } }: V<ProductShowQuery>) =>
  (): Promise<ProductShowResponse> =>
    Http().get<ProductShowResponse, never>(ENDPOINT.PRODUCTS._SHOW(product_id))({}).then(maybeResponseToJSON);
