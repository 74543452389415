import { getCurrentUserToken, getCurrentUserValidVenueWizardId } from '../selectors/getCurrentUser';
import { apiGet, apiPost } from './data_providers/api';
import { getVenueWizardsById } from 'selectors/venueWizards/getVenueWizards';

export const fetchVenueWizards = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_wizard_id = getCurrentUserValidVenueWizardId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_wizard_id || !auth) {
      return reject({ code: 400 });
    }

    // https://docs.uala.it/api/v1/venue_wizards
    dispatch(
      apiGet({
        path: `/venue_wizards/${venue_wizard_id}.json`,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          const { venue_wizard } = response.data;

          resolve({ venue_wizard });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

export const updateVenueWizard = (changes) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_wizard_id = getCurrentUserValidVenueWizardId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_wizard_id || !auth) {
      return reject({ code: 400 });
    }

    // https://docs.uala.it/api/v1/venue_wizards
    dispatch(
      apiPost({
        method: 'PUT',
        path: `/venue_wizards/${venue_wizard_id}.json`,
        data: {
          venue_wizard: changes,
        },
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve({ venue_wizard: response.data.venue_wizard });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

export const updateVenueWizardIfNeeded = (flag) => (dispatch, getState) =>
  new Promise((resolve) => {
    const venue_wizard_id = getCurrentUserValidVenueWizardId(getState());
    if (!venue_wizard_id) {
      return resolve();
    }

    const venueWizardsById = getVenueWizardsById(getState());
    const venue_wizard = venueWizardsById && venueWizardsById[venue_wizard_id];

    if (venue_wizard[flag]) {
      dispatch(updateVenueWizard({ [flag]: false }))
        .then(resolve)
        .catch(() => {});
    } else {
      resolve();
    }
  });
