// Input variants
const INPUT_INLINE = 'inline';
const INPUT_BLOCK = 'block';
const INPUT_STACKED = 'stacked';
const INPUT_SIZE_MEDIUM = 'md';
const INPUT_SIZE_LARGE = 'lg';
const INPUT_SIZE_SMALL = 'sm';
const INPUT_SIZES = [INPUT_SIZE_LARGE, INPUT_SIZE_MEDIUM, INPUT_SIZE_SMALL];

// Defaults
const AUTOCOMPLETE = 'off';
const HELPER_POSITION = 'top';
const LABEL_POSITION = INPUT_INLINE;
const TEXTAREA_ROWS = 2;
const INPUT_SIZE = INPUT_SIZE_MEDIUM;

const DefaultSettings = {
  AUTOCOMPLETE,
  HELPER_POSITION,
  TEXTAREA_ROWS,
  LABEL_POSITION,
  INPUT_SIZE,
};

export {
  AUTOCOMPLETE,
  HELPER_POSITION,
  INPUT_INLINE,
  INPUT_BLOCK,
  INPUT_STACKED,
  TEXTAREA_ROWS,
  LABEL_POSITION,
  INPUT_SIZES,
  INPUT_SIZE_SMALL,
  INPUT_SIZE_MEDIUM,
  INPUT_SIZE_LARGE,
  DefaultSettings,
};
