import { EXTRA_CUSTOMER_FIELDS_LIST_REQUEST, EXTRA_CUSTOMER_FIELDS_LIST_RESPONSE } from 'actionTypes';

// This is a CUSTOM Reducer

const initialState = {};

export const extraCustomerFields = (state = initialState, action) => {
  switch (action.type) {
    case EXTRA_CUSTOMER_FIELDS_LIST_REQUEST:
      return {
        ...initialState,
        isFetching: true,
      };

    case EXTRA_CUSTOMER_FIELDS_LIST_RESPONSE:
      if (!action.extra_customer_fields) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        extra_customer_fields: action.extra_customer_fields || {},
      };

    default:
      return state;
  }
};
