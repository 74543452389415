import { FunctionComponent } from 'react';
import { Text } from 'components/core';
import { PaymentMethodState, PAYMENT_METHOD_STATE } from 'models';

type ExpirationDateProps = {
  state: PaymentMethodState;
  expirationMonth: number;
  expirationYear: number;
};

const ExpirationDate: FunctionComponent<ExpirationDateProps> = ({ state, expirationMonth, expirationYear }) => {
  if (state === PAYMENT_METHOD_STATE.EXPIRED) {
    return (
      <Text size={14} color="red" className="ExpirationDate">
        Scaduta {expirationMonth}/{expirationYear}
      </Text>
    );
  }

  return (
    <Text size={14} className="ExpirationDate">
      {expirationMonth}/{expirationYear}
    </Text>
  );
};

export default ExpirationDate;
