import { State } from 'reducers/types';
import { AcceptanceForm } from 'models/acceptanceForm';

const empty_list: AcceptanceForm[] = [];

export const getCurrentVenueAcceptanceForms = (state: State): AcceptanceForm[] =>
  (state.sessions &&
    state.sessions.currentVenue &&
    state.sessions.currentVenue.id &&
    state.sessions.acceptanceFormsByVenue[state.sessions.currentVenue.id]) ||
  empty_list;
