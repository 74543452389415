import {
  VENUE_STAFF_MEMBER_TREATMENTS_BULK_DELETE,
  VENUE_TREATMENT_GROUP_CREATE_RESPONSE,
  VENUE_TREATMENT_GROUP_DELETE_RESPONSE,
  VENUE_TREATMENT_GROUP_LIST_REQUEST,
  VENUE_TREATMENT_GROUP_LIST_RESPONSE,
  VENUE_TREATMENT_GROUP_REQUEST,
  VENUE_TREATMENT_GROUP_RESPONSE,
  VENUE_TREATMENT_GROUP_UPDATE_RESPONSE,
} from 'actionTypes';
import { LocalStaffMemberTreatment, Venue, VenueTreatment, VenueTreatmentGroup } from 'models';
import { getCurrentVenueId, getCurrentVenueTimeSlot } from 'selectors/getCurrentVenue';
import { actionShouldFetchData } from 'utils/reducersUtils';
import { apiDataSet, apiGet, apiPost, METHOD } from './data_providers/api';
import { createVenueTreatmentResponseAction } from './treatments';
import { ActionCreator, ParametricActionCreator, StandardAction } from './types';
import { getAllDeletedStaffMemberTreatmentsInVenueTreatments } from 'utils/treatments/filterStaffMemberTreatments';
import { vtgToVenueTreatments } from 'utils/vtgToVenueTreatments';

const DEFAULT_PRICE = 10;
type DefaultPayload = { venue_id: Venue['id'] };

/* Create */
type CreateVTGRequest = { name: VenueTreatmentGroup['name']; treatment_id: VenueTreatmentGroup['treatment_id'] };
type CreateVTGResponsePayload = { venue_treatment_group: VenueTreatmentGroup } & DefaultPayload;
type CreateVTGResponse = StandardAction<typeof VENUE_TREATMENT_GROUP_CREATE_RESPONSE, CreateVTGResponsePayload>;

const createVTGResponse = (payload: CreateVTGResponsePayload): CreateVTGResponse => ({
  type: VENUE_TREATMENT_GROUP_CREATE_RESPONSE,
  payload,
});

const createVenueTreatmentGroup: ParametricActionCreator<CreateVTGRequest, Promise<{ payload: VenueTreatmentGroup }>> =
  (venueTreatmentGroup) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const venue_time_slot = getCurrentVenueTimeSlot(getState());

      dispatch(
        apiPost({
          path: `/venues/${venue_id}/venue_treatment_groups.json`,
          data: {
            create_staff_member_treatments: true,
            venue_treatment_group: {
              ...venueTreatmentGroup,
              venue_treatments_attributes: [
                {
                  price: DEFAULT_PRICE,
                  duration: venue_time_slot,
                  venue_id,
                  treatment_id: venueTreatmentGroup.treatment_id,
                },
              ],
            },
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            const venue_treatment_group = response.data.venue_treatment_group;

            dispatch(createVTGResponse({ venue_id, venue_treatment_group }));
            dispatch(
              createVenueTreatmentResponseAction({
                venue_id,
                venue_treatment: venue_treatment_group.venue_treatments[0],
                response,
                error: undefined,
              })
            );
            dispatch(
              apiDataSet({
                object_type: 'venue_treatment',
                object: {
                  ...response.data.venue_treatment_group.venue_treatments[0],
                  treatment_id: venue_treatment_group.treatment_id,
                },
              })
            );
            resolve({ payload: response.data.venue_treatment_group });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

/* Read */
type FetchVTGRequest = StandardAction<typeof VENUE_TREATMENT_GROUP_REQUEST, DefaultPayload>;
const fetchVTGRequest = (payload: DefaultPayload): FetchVTGRequest => ({
  payload,
  type: VENUE_TREATMENT_GROUP_REQUEST,
});

type FetchVTGResponse = StandardAction<typeof VENUE_TREATMENT_GROUP_RESPONSE, FetchVTGResponsePayload>;
type FetchVTGResponsePayload = { venue_treatment_group: VenueTreatmentGroup } & DefaultPayload;
const fetchVTGResponse = (payload: FetchVTGResponsePayload): FetchVTGResponse => ({
  payload,
  type: VENUE_TREATMENT_GROUP_RESPONSE,
});

const fetchVTGIfNeeded: ActionCreator<Promise<{ payload: VenueTreatmentGroup[] }> | undefined> =
  () => (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());

    if (venue_id && actionShouldFetchData(getState().venueTreatmentGroupsByVenue[venue_id])) {
      return dispatch(fetchVenueTreatmentGroups());
    }

    return;
  };

const fetchVenueTreatmentGroup: ParametricActionCreator<
  { id: VenueTreatmentGroup['id'] },
  Promise<{ payload: VenueTreatmentGroup }>
> =
  ({ id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      dispatch(fetchVTGRequest({ venue_id }));

      // http://docs.uala.it/api/v1/venue_treatments
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/venue_treatment_groups/${id}.json`,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(fetchVTGResponse({ venue_id, venue_treatment_group: response.data.venue_treatment_group }));

            resolve({ payload: response.data.venue_treatment_group });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

type FetchVTGListRequest = StandardAction<typeof VENUE_TREATMENT_GROUP_LIST_REQUEST, DefaultPayload>;
const fetchVTGListRequest = (payload: DefaultPayload): FetchVTGListRequest => ({
  type: VENUE_TREATMENT_GROUP_LIST_REQUEST,
  payload,
});

type FetchVTGListResponsePayload = { venue_treatment_groups: VenueTreatmentGroup[] } & DefaultPayload;
type FetchVTGListResponse = StandardAction<typeof VENUE_TREATMENT_GROUP_LIST_RESPONSE, FetchVTGListResponsePayload>;
const fetchVTGListResponse = (payload: FetchVTGListResponsePayload): FetchVTGListResponse => ({
  payload,
  type: VENUE_TREATMENT_GROUP_LIST_RESPONSE,
});

const fetchVenueTreatmentGroups: ActionCreator<Promise<{ payload: VenueTreatmentGroup[] }>> =
  () => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      dispatch(fetchVTGListRequest({ venue_id }));

      // http://docs.uala.it/api/v1/venue_treatments
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/venue_treatment_groups.json`,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(fetchVTGListResponse({ venue_id, venue_treatment_groups: response.data.venue_treatment_groups }));

            resolve({ payload: response.data.venue_treatment_groups });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

/* Update */
type UpdateVTGRequest = { id: VenueTreatmentGroup['id'] } & Partial<Omit<VenueTreatmentGroup, 'id'>>;
type UpdateVTGResponsePayload = { venue_treatment_group: VenueTreatmentGroup } & DefaultPayload;
type UpdateVTGResponse = StandardAction<typeof VENUE_TREATMENT_GROUP_UPDATE_RESPONSE, CreateVTGResponsePayload>;
type BuldDeleteStaffMemberTreatmentsPayload = {
  staff_member_treatments: LocalStaffMemberTreatment[];
  venue_id: number;
};

const updateVTGResponse = (payload: UpdateVTGResponsePayload): UpdateVTGResponse => ({
  type: VENUE_TREATMENT_GROUP_UPDATE_RESPONSE,
  payload,
});

const updateVTsResponse = (venue_treatments: VenueTreatment[], venue_id: number) => ({
  type: 'VENUE_TREATMENTS_UPDATE',
  venue_treatments,
  venue_id,
});

const deleteStaffMemberTreatments = ({
  staff_member_treatments,
  venue_id,
}: BuldDeleteStaffMemberTreatmentsPayload) => ({
  type: VENUE_STAFF_MEMBER_TREATMENTS_BULK_DELETE,
  venue_id,
  staff_member_treatments,
});

const updateVenueTreatmentGroup: ParametricActionCreator<UpdateVTGRequest, Promise<{ payload: VenueTreatmentGroup }>> =
  (venueTreatmentGroup) => (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) {
        return;
      }
      const { venue_treatments, id, ...rest } = venueTreatmentGroup;
      const deletedStaffMemberTreatments = getAllDeletedStaffMemberTreatmentsInVenueTreatments(venue_treatments);
      const venueTreatmentsPayload = venue_treatments?.map((vt) => ({
        ...vt,
        staff_member_treatments_attributes: vt.staff_member_treatments,
      }));

      // http://docs.uala.it/api/v1/venue_treatments
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/venue_treatment_groups/${id}.json`,
          method: METHOD.PUT,
          data: {
            venue_treatment_group: {
              ...rest,
              venue_treatments_attributes: venueTreatmentsPayload,
            },
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(deleteStaffMemberTreatments({ venue_id, staff_member_treatments: deletedStaffMemberTreatments }));
            dispatch(updateVTGResponse({ venue_id, venue_treatment_group: response.data.venue_treatment_group }));
            dispatch(updateVTsResponse(vtgToVenueTreatments(response.data.venue_treatment_group), venue_id));
            resolve({ payload: response.data.venue_treatment_group });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

/* Delete */
type DeleteVTGResponsePayload = { venue_treatment_group: VenueTreatmentGroup } & DefaultPayload;
type DeleteVTGResponse = StandardAction<typeof VENUE_TREATMENT_GROUP_DELETE_RESPONSE, DeleteVTGResponsePayload>;

const deleteVTGResponse = (payload: DeleteVTGResponsePayload): DeleteVTGResponse => ({
  payload,
  type: VENUE_TREATMENT_GROUP_DELETE_RESPONSE,
});
const deleteVenueTreatmentGroup: ParametricActionCreator<
  { id: VenueTreatmentGroup['id'] },
  Promise<{ payload: VenueTreatmentGroup }>
> =
  ({ id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      dispatch(
        apiPost({
          method: METHOD.DELETE,
          path: `/venues/${venue_id}/venue_treatment_groups/${id}.json`,
          data: {},
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              deleteVTGResponse({
                venue_id,
                venue_treatment_group: response.data.venue_treatment_group,
              })
            );

            resolve({ payload: response.data.venue_treatment_group });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export {
  fetchVenueTreatmentGroup,
  fetchVenueTreatmentGroups,
  createVenueTreatmentGroup,
  deleteVenueTreatmentGroup,
  updateVenueTreatmentGroup,
  fetchVTGIfNeeded,
};

export type VTGActions =
  | CreateVTGResponse
  | DeleteVTGResponse
  | FetchVTGListResponse
  | FetchVTGListRequest
  | FetchVTGRequest
  | FetchVTGResponse
  | UpdateVTGResponse;
