import { styled, css } from 'styles';
import { withTheme } from '../Context';
import { SIZES, MEDIA } from 'styles';

const bgStyles = ({ background, colors }) => {
  switch (background) {
    default:
      return css`
        background-color: ${background && colors[background] ? colors[background] : colors.bgWhite};
      `;
    case 'grey':
      return css`
        background-color: ${colors.bgLightGray};
      `;
    case 'blue':
      return css`
        background-color: ${colors.blue};
      `;
  }
};

const collapseStyle = ({ collapse, jumbo }) => {
  switch (collapse) {
    default:
      return css`
        padding: 25px;
      `;
    case 'bottom':
      return css`
        padding: 25px 25px 0;
      `;
    case 'top':
      return css`
        padding: 0 25px 25px;
      `;
    case 'half':
      return css`
        padding: 12px 10px;

        ${MEDIA.tablet`
          padding: 12px 20px;
          ${jumbo && { 'padding-top': '30px', 'padding-bottom': '30px' }};
        `};
      `;
    case 'narrow':
      return css`
        padding: 20px 10px;

        ${MEDIA.tablet`
          padding: 20px;
        `};
      `;
    case 'narrowBottom':
      return css`
        padding: 20px 10px 0;

        ${MEDIA.tablet`
          padding: 20px 20px 0;
        `};
      `;
    case 'all':
      return css`
        padding: 0;
      `;
  }
};

const firstAndLastBordeRadius = `
  &:first-of-type {
    border-radius: ${SIZES.borderRadiusMedium}px ${SIZES.borderRadiusMedium}px 0 0;
  }

  &:last-child {
    border-radius: 0 0 ${SIZES.borderRadiusMedium}px ${SIZES.borderRadiusMedium}px;
  }

  &:first-of-type:last-child {
    border-radius: ${SIZES.borderRadiusMedium}px;
  }
`;

export const Card = withTheme(
  styled('div')(
    ({
      theme: { colors, boxShadow, sizes, utils },
      noShadow,
      width,
      height,
      background,
      borderless,
      dashed,
      disabled,
      absolute,
    }) => css`
      display: block;
      font-family: ${utils.fontFamily};
      border: ${borderless ? 0 : dashed ? `1px dashed ${colors.red}` : `1px solid ${colors.blockBorder}`};
      border-radius: ${sizes.borderRadiusMedium}px;
      ${!noShadow && `box-shadow: ${boxShadow.block};`} ${width && `width:${width}${!/%$/.test(width) ? 'px' : ''};`};
      ${height && `height:${height}${!/%$/.test(height) ? 'px' : ''};`};
      ${bgStyles({ background, colors })};
      ${disabled && `opacity: 0.3;`};
      ${absolute &&
      `
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      `};

      > .Section {
        ${firstAndLastBordeRadius};
      }
    `
  )
);

const BaseCardSection = withTheme(
  styled('div')(
    ({
      theme: { colors, sizes },
      relative,
      collapse,
      background,
      color,
      onClick,
      overflow,
      flex,
      bold,
      uppercase,
      fontSize,
      padding,
      paddingBottom,
      paddingTop,
      paddingLeft,
      paddingRight,
      jumbo,
      hideIfEmpty,
    }) => css`
      display: block;
      ${relative && { position: 'relative' }};
      ${overflow && { overflow: overflow }};
      ${flex && { flex }};
      ${firstAndLastBordeRadius};
      ${collapse && collapseStyle({ collapse, jumbo })};
      ${bgStyles({ background, colors })};
      ${onClick && { cursor: 'pointer' }};
      ${bold && `font-weight: 700;`};
      ${uppercase && `text-transform: uppercase;`};
      ${fontSize && { fontSize: fontSize / 10 + 'rem' }};
      color: ${(color && colors[color]) || colors.black};

      ${padding && { padding: padding * sizes.padding }};
      ${paddingBottom && { paddingBottom: paddingBottom * sizes.padding }};
      ${paddingTop && { paddingTop: paddingTop * sizes.padding }};
      ${paddingLeft && { paddingLeft: paddingLeft * sizes.padding }};
      ${paddingRight && { paddingRight: paddingRight * sizes.padding }};

      ${hideIfEmpty &&
      `
        &:empty {
          display: none;
        }
      `};
    `
  )
);

const BaseHeader = styled(BaseCardSection)`
  display: flex;

  * {
    flex: 1;
  }

  > .Badge {
    right: 35px;
  }
`;

export const Section = withTheme(
  styled(BaseCardSection)(
    ({ theme: { colors }, height, bordered }) => css`
      ${height && { height: `${height}px` }};
      ${bordered &&
      `
          border-bottom: 1px solid ${colors.blockBorder};
          &:last-child {
            border-bottom: 0;
          }
        `};
    `
  )
);

export const Header = withTheme(
  styled(BaseHeader)(
    ({ theme: { colors, media }, fontSize, color, withAction, borderBottom }) => css`
      font-size: ${(fontSize || 12) / 10}rem;
      color: ${(color && colors[color]) || colors.black};
      text-transform: uppercase;
      letter-spacing: 1px;
      text-align: center;
      ${borderBottom && { borderBottom: `1px solid ${colors.blockBorder}` }};

      > .Icon {
        align-self: center;
        flex: none;
        order: 1;
        margin-left: 25px;
      }

      ${withAction &&
      css`
        > .Button {
          position: absolute;
          top: 50%;
          right: 25px;
          transform: translateY(-50%);
          padding-left: 0;
          padding-right: 0;

          .Text {
            display: inline-block;
          }

          ${media.tablet`
              right: auto;
            `};
        }

        > .Text {
          width: 55%;
          text-align: left;

          ${media.tablet`
              width: 100%;
              text-align: center;
            `};
        }
      `};
    `
  )
);

export const Title = withTheme(
  styled(BaseHeader)(
    ({ theme: { colors, sizes }, fontSize, color, jumbo, bordered }) => css`
      font-size: ${(fontSize || 16) / 10}rem;
      color: ${(color && colors[color]) || colors.text1};
      ${jumbo && { 'padding-top': '30px', 'padding-bottom': '30px' }};
      ${bordered && `border-bottom: 1px solid ${colors.gray}`};
      border-bottom: 1px solid ${colors.inputBorder};

      > .Icon {
        align-self: center;
        flex: none;
        order: 1;
        margin-left: 25px;
      }
    `
  )
);

export const Action = withTheme(
  styled(BaseHeader)(
    ({ theme: { colors }, color, fontSize }) => css`
      font-size: ${(fontSize || 12) / 10}rem;
      color: ${(color && colors[color]) || colors.blue};

      > .Icon {
        align-self: center;
        flex: none;
        margin-right: 10px;
      }
    `
  )
);

export const Right = withTheme(
  styled('div')(
    ({ fontSize, big }) => css`
      text-align: right;
      font-size: ${big ? `font-size:2rem;` : fontSize ? `font-size:${fontSize / 10}rem;` : `font-size:1.6rem;`};
    `
  )
);

export const Fieldset = withTheme(
  styled('div')(
    ({ justify, scrollable, nowrap, center }) => css`
      display: flex;
      ${justify && 'justify-content: space-between;'};
      ${center && 'align-items: center;'};
      flex-wrap: ${nowrap ? 'nowrap' : 'wrap'};
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      ${scrollable &&
      `
        white-space: nowrap;
        overflow: auto;
    `};
    `
  )
);
