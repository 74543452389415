import PropTypes from 'prop-types';
import { Shape, Background, Wrapper, Content, Close, Title, Header, HeaderAction } from './theme';
import Icon from '../Icon';
import Text from '../Text';

const Overlay = ({ scrollable, title, onClose, children, extraHeaderAction, dataTestid }) => (
  <Shape className="Overlay" scrollable={scrollable} {...(dataTestid ? { 'data-testid': dataTestid } : {})}>
    <Background scrollable={scrollable} />
    <Wrapper>
      {title ? (
        <Header>
          {extraHeaderAction && (
            <HeaderAction>
              {extraHeaderAction}
              {}
            </HeaderAction>
          )}
          <Title>
            <Text uppercase bold size={12} spacing={1}>
              {title}
            </Text>
          </Title>
          {onClose ? (
            <Close onClick={onClose} data-testid="modal-close-button">
              <Icon type="close" size={11} color="text1" />
            </Close>
          ) : null}
        </Header>
      ) : null}
      <Content>{children}</Content>
    </Wrapper>
  </Shape>
);

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  onClose: PropTypes.func,
  scrollable: PropTypes.bool,
  extraHeaderAction: PropTypes.node,
  dataTestid: PropTypes.string,
};

Overlay.defaultProps = {
  scrollable: false,
  title: undefined,
  onClose: () => {},
  extraHeaderAction: undefined,
  dataTestid: undefined,
};

export default Overlay;
