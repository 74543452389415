import { Venue } from 'models';
import { PahoMQTTError } from './types';

export const PAHO_MQTT_AUTH_ERROR_MESSAGE =
  'AMQJS0006E Bad Connack return code:4 Connection Refused: bad user name or password.';
export const PAHO_MQTT_AUTH_ERROR_CODE = 6;

/**
 * this function checks the auth error code and message passed as an argument of the onFailure callback of paho mqtt client
 */
export const areMQTTAuthCredentialsExpired = (e: PahoMQTTError): boolean => {
  return e.errorMessage === PAHO_MQTT_AUTH_ERROR_MESSAGE && e.errorCode === PAHO_MQTT_AUTH_ERROR_CODE;
};

type LoggerFn = typeof console.log;
export const devLogger: (prefix?: string) => LoggerFn =
  (prefix) =>
  (...args) => {
    if (process.env.NODE_ENV === 'development' || window.DEBUG) {
      console.log(
        `%c@SYNC>${prefix ? prefix + '>' : ''}`,
        'color: cyan; background: black; font-style: bold;',
        ...args
      );
    }
  };

export const getVenueDBGId = (venue?: Venue) => `Venue(${venue?.id ?? '?'})[${venue?.name ?? 'UNKNOWN'}]`;
