import { styled, css } from 'styles';
import { withTheme } from 'components/core';
import hexToRgba from 'utils/hexToRgba';

const Message = withTheme(
  styled('div')(
    ({
      theme: { colors, boxShadow, sizes },
      collapsible,
      width,
      show,
      type,
      position,
      hasShadow,
      relative,
      padding,
      maxWidth,
    }) => css`
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      width: ${width ? width : 'calc(100% - 40px)'};
      padding: 16px 0;
      overflow: hidden;
      background-color: ${hexToRgba(colors[type], 0.9)};
      transition: all 0.5s;
      ${hasShadow && `box-shadow: ${boxShadow.default};`}
      border-radius: ${sizes.borderRadiusMedium}px;
      visibility: hidden;
      opacity: 0;
      transition:
        visibility 0s linear 300ms,
        opacity 300ms;
      z-index: 9;
      ${collapsible && 'cursor: pointer;'}

      ${show && 'visibility: visible; opacity: 1; transition: visibility 0s linear 0s, opacity 300ms;'};

      ${(!position || position === 'bottom') && `bottom: 0;`}
      ${(!position || position === 'bottom') && `margin-bottom: 15px;`}
      
      ${position === 'top' && `top: 0;`}
      ${position === 'top' && `margin-top: 15px;`}

      position: ${relative ? 'relative' : 'absolute'};
      ${maxWidth && `max-width: ${maxWidth}px`};
    `
  )
);

export default Message;
