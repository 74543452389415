import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import { createSelector } from 'reselect';
import ENV from 'env';
import Loadable from '@loadable/component';
import LoadingCodeChunkFallback from 'components/lib/LoadingCodeChunkFallback';
import DocumentTitle from 'components/lib/DocumentTitle';
import {
  initUserData,
  logoutRedirect,
  sessionsLogout,
  sessionsPasswordReset,
  sessionsPasswordResetRequest,
  sessionsSignin,
} from 'actions/sessions';
import { getCurrentUser, getCurrentUserToken } from 'selectors/getCurrentUser';
import { configureScope } from '@sentry/core';
import { modalOpen } from 'actions/modals';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { MobilePasswordsManager } from 'components/sessions/mobilePasswordManager/MobilePasswordsManager';
import { MobilePasswordManagerStorePassword } from 'components/sessions/mobilePasswordManager/MobilePasswordManagerStorePassword';
import { usePasswordManager } from 'utils/hooks/usePasswordManager';

const SigninForm = Loadable(() => import('components/sessions/SigninForm'), { fallback: LoadingCodeChunkFallback });
const ResetPassword = Loadable(() => import('components/sessions/ResetPassword'), {
  fallback: <LoadingCodeChunkFallback />,
});

const KNOWN_TOKENS = {
  AUTH_TOKEN: 'auth_token',
  RESET_PWD_TOKEN: 'reset_password_token',
  ADMIN_TOKEN: 'admin_token',
};

const WAIT_MESSAGE = 'authenticating...';

const SessionsProvider = ({
  venueId,
  currentUser,
  gotPermissions,
  initUserData,
  sessionsSignin,
  sessionsLogout,
  logoutRedirect,
  sessionsPasswordResetRequest,
  sessionsPasswordReset,
  goToPage,
  modalOpen,
  isLoading,
  error,
  children,
}) => {
  const [autoLoginToken, setAutoLoginToken] = useState(window.location.href.split(`${KNOWN_TOKENS.AUTH_TOKEN}=`)[1]);
  const [adminToken, setAdminToken] = useState(window.location.href.split(`${KNOWN_TOKENS.ADMIN_TOKEN}=`)[1]);
  const [resetPasswordToken, setResetPasswordToken] = useState(
    window.location.href.split(`${KNOWN_TOKENS.RESET_PWD_TOKEN}=`)[1]
  );

  const {
    showPasswordManager,
    keyChainCredentials,
    showPasswordStorer,
    passwordStorerMode,
    togglePasswordManager,
    deletePasswordManagerCredentials,
    updatePasswordManagerCredentials,
    showPasswordManagerStorer,
    cancelPasswordStorer,
  } = usePasswordManager({ shouldFetchKeychainCredentials: !currentUser || isLoading });

  const startSessionByToken = useCallback(
    async ({ token, setToken, impersonate = false }) => {
      const pathname = window.location.pathname.replace('/', '');
      setToken(WAIT_MESSAGE);
      // Ensure venue is not set by logging out
      logoutRedirect();

      try {
        await sessionsSignin({ auth_token: token, impersonate });
        setToken(null);
        goToPage(`/${pathname || ENV.UALA_HOMEPAGE || 'agenda'}`);
      } catch {
        setToken(null);
      }
    },
    [goToPage, sessionsSignin, logoutRedirect]
  );

  useEffect(() => {
    switch (true) {
      // Auto Login flow
      case autoLoginToken && autoLoginToken !== WAIT_MESSAGE: {
        startSessionByToken({ token: autoLoginToken.split('&')[0], setToken: setAutoLoginToken });
        break;
      }
      // Reset password flow
      case resetPasswordToken: {
        setResetPasswordToken(resetPasswordToken.split('&')[0]);
        break;
      }
      // Admin login (impersonate) flow
      case adminToken && adminToken !== WAIT_MESSAGE: {
        startSessionByToken({ token: adminToken, setToken: setAdminToken, impersonate: true });
        break;
      }
      default:
        break;
    }
  }, [startSessionByToken, adminToken, resetPasswordToken, autoLoginToken]);

  const initSessions = useCallback(() => {
    if (!currentUser) {
      return;
    }

    if (!gotPermissions) {
      sessionsLogout();
      return;
    }

    initUserData();

    if (venueId) {
      configureScope((scope) => {
        scope.setTag('venue_id', venueId);
      });
    }
  }, [currentUser, gotPermissions, venueId, initUserData, sessionsLogout]);

  useEffect(() => {
    if (autoLoginToken || resetPasswordToken || adminToken || !currentUser) {
      return;
    }
    initSessions();
  }, [autoLoginToken, resetPasswordToken, currentUser, initSessions, adminToken]);

  const onResetPasswordRequest = ({ email }) =>
    new Promise((resolve, reject) => {
      sessionsPasswordResetRequest({ email })
        .then(() => {
          modalOpen({
            id: 'reset-password-error',
            config: {
              type: 'green',
              component: 'Banner',
              content: 'check-your-email',
            },
          });
          resolve();
        })
        .catch((msg) => {
          modalOpen({
            id: 'reset-password-error',
            config: {
              component: 'ServerError',
              content: msg,
            },
          });
        });
    });

  const onResetPassword = ({ password }) =>
    new Promise((resolve, reject) => {
      sessionsPasswordReset({ password, reset_password_token: resetPasswordToken })
        .then(() => {
          goToPage(`/${ENV.UALA_HOMEPAGE || 'agenda'}`);
          setResetPasswordToken('');
          resolve();
        })
        .catch((msg) => {
          modalOpen({
            id: 'reset-password-error',
            config: {
              component: 'ServerError',
              content: msg,
            },
          });
        });
    });

  const handleSignIn = async (credentials) => {
    try {
      await sessionsSignin(credentials);
      showPasswordManagerStorer(credentials);
    } catch (e) {
      console.debug(e);
    }
  };

  return autoLoginToken || adminToken ? null : resetPasswordToken ? (
    <>
      <DocumentTitle />
      <ResetPassword onSubmit={onResetPassword} />
    </>
  ) : !currentUser || isLoading ? (
    <>
      <DocumentTitle />
      <SigninForm
        onSubmit={handleSignIn}
        onResetPassword={onResetPasswordRequest}
        modalOpen={modalOpen}
        isLoading={isLoading}
        error={error}
        showKeyChainLogin={Boolean(keyChainCredentials)}
        togglePasswordManager={togglePasswordManager}
      />
      {showPasswordManager && (
        <MobilePasswordsManager
          credentials={keyChainCredentials}
          onClose={togglePasswordManager}
          onClickCredential={handleSignIn}
          onUpdateCredential={updatePasswordManagerCredentials}
          onDeleteCredential={deletePasswordManagerCredentials}
        />
      )}
    </>
  ) : (
    <>
      {children}
      {showPasswordStorer && (
        <MobilePasswordManagerStorePassword
          credentials={keyChainCredentials}
          onCancel={cancelPasswordStorer}
          onSaveCredential={updatePasswordManagerCredentials}
          passwordStorerMode={passwordStorerMode}
        />
      )}
    </>
  );
};

const getSessions = (state) => state.sessions || null;

const mapStateToProps = () =>
  createSelector(
    getCurrentUser,
    getCurrentUserToken,
    getSessions,
    getCurrentVenueId,
    (currentUser, auth_token, sessions, venueId) => {
      const { lastRequest, permissionsByVenue } = sessions || {};

      const permissions = currentUser && permissionsByVenue && permissionsByVenue[venueId];
      return {
        venueId,
        currentUser,
        auth_token,
        gotPermissions: permissions && permissions.length > 0,
        isLoading: lastRequest?.timestamp > new Date().getTime() - 10000,
        error: lastRequest?.error,
      };
    }
  );

const mapDispatchToProps = {
  sessionsSignin,
  sessionsPasswordResetRequest,
  sessionsPasswordReset,
  initUserData,
  goToPage: replace,
  sessionsLogout,
  logoutRedirect,
  modalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionsProvider);
