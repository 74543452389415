export * from 'actions/customerCartItems/actionTypes';

// API
export const API_GET_REQUEST = 'API_GET_REQUEST';
export const API_GET_RESPONSE = 'API_GET_RESPONSE';
export const API_POST_REQUEST = 'API_POST_REQUEST';
export const API_POST_RESPONSE = 'API_POST_RESPONSE';
export const API_DATA_SET = 'API_DATA_SET';

// STATISTICS
export const STATISTICS_GET_REQUEST = 'STATISTICS_GET_REQUEST';
export const STATISTICS_GET_RESPONSE = 'STATISTICS_GET_RESPONSE';
export const STATISTICS_POST_REQUEST = 'STATISTICS_POST_REQUEST';
export const STATISTICS_POST_RESPONSE = 'STATISTICS_POST_RESPONSE';
export const STATISTICS_DATA_SET = 'STATISTICS_DATA_SET';

// TRANSIENT
export const VENUE_TRANSIENT_DATA_SET = 'VENUE_TRANSIENT_DATA_SET';
export const VENUE_TRANSIENT_DATA_RESET = 'VENUE_TRANSIENT_DATA_RESET';

// SESSIONS
export const SESSIONS_LOGOUT = 'SESSIONS_LOGOUT';
export const SESSIONS_SIGNIN_REQUEST = 'SESSIONS_SIGNIN_REQUEST';
export const SESSIONS_SIGNIN_RESPONSE = 'SESSIONS_SIGNIN_RESPONSE';
export const SESSIONS_RESET_VENUE_DATA = 'SESSIONS_RESET_VENUE_DATA';
export const UPDATED_SINCE_RECEIVED = 'UPDATED_SINCE_RECEIVED';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const USER_ACL_PERMISSIONS_RESPONSE = 'USER_ACL_PERMISSIONS_RESPONSE';
export const SWITCH_CURRENT_VENUE = 'SWITCH_CURRENT_VENUE';

// VENUES
export const VENUES_BY_ID_RESPONSE = 'VENUES_BY_ID_RESPONSE';

// STAFF MEMBERS JOB TITLES
export const STAFF_MEMBER_JOB_TITLES_LIST_REQUEST = 'STAFF_MEMBER_JOB_TITLES_LIST_REQUEST';
export const STAFF_MEMBER_JOB_TITLES_LIST_RESPONSE = 'STAFF_MEMBER_JOB_TITLES_LIST_RESPONSE';

// STAFF MEMBERS
export const STAFF_MEMBERS_LIST_REQUEST = 'STAFF_MEMBERS_LIST_REQUEST';
export const STAFF_MEMBERS_LIST_RESPONSE = 'STAFF_MEMBERS_LIST_RESPONSE';
export const STAFF_MEMBERS_UPDATE_RESPONSE = 'STAFF_MEMBERS_UPDATE_RESPONSE';
export const STAFF_MEMBERS_DELETE_RESPONSE = 'STAFF_MEMBERS_DELETE_RESPONSE';
export const STAFF_MEMBERS_CREATE_RESPONSE = 'STAFF_MEMBERS_CREATE_RESPONSE';

// BILLING
export const BILLING_COMPANY_LIST_REQUEST = 'BILLING_COMPANY_LIST_REQUEST';
export const BILLING_COMPANY_LIST_RESPONSE = 'BILLING_COMPANY_LIST_RESPONSE';
export const BILLING_COMPANY_PRODUCTS_LIST_REQUEST = 'BILLING_COMPANY_PRODUCTS_LIST_REQUEST';
export const BILLING_COMPANY_PRODUCTS_LIST_RESPONSE = 'BILLING_COMPANY_PRODUCTS_LIST_RESPONSE';
export const BILLING_ADDONS_LIST_REQUEST = 'BILLING_ADDONS_LIST_REQUEST';
export const BILLING_ADDONS_LIST_RESPONSE = 'BILLING_ADDONS_LIST_RESPONSE';
export const BILLING_COMPANY_INVOICES_LIST_REQUEST = 'BILLING_COMPANY_INVOICES_LIST_REQUEST';
export const BILLING_COMPANY_INVOICES_LIST_RESPONSE = 'BILLING_COMPANY_INVOICES_LIST_RESPONSE';
export const BILLING_COMPANY_UPDATE_REQUEST = 'BILLING_COMPANY_UPDATE_REQUEST';
export const BILLING_COMPANY_UPDATE_RESPONSE = 'BILLING_COMPANY_UPDATE_RESPONSE';
export const VENUES_TRANSFERS_LIST_REQUEST = 'VENUES_TRANSFERS_LIST_REQUEST';
export const VENUES_TRANSFERS_LIST_RESPONSE = 'VENUES_TRANSFERS_LIST_RESPONSE';

// USERS ACCESS
export const FETCH_USERS_ACCESSES_RESPONSE = 'FETCH_USERS_ACCESSES_RESPONSE';

// MARKETING
export const NEWSLETTERS_LIST_REQUEST = 'NEWSLETTERS_LIST_REQUEST';
export const NEWSLETTERS_LIST_RESPONSE = 'NEWSLETTERS_LIST_RESPONSE';
export const NEWSLETTERS_CREATE_RESPONSE = 'NEWSLETTERS_CREATE_RESPONSE';
export const NEWSLETTERS_UPDATE_RESPONSE = 'NEWSLETTERS_UPDATE_RESPONSE';
export const NEWSLETTERS_DELETE_RESPONSE = 'NEWSLETTERS_DELETE_RESPONSE';

export const NEWSLETTER_TEMPLATES_LIST_REQUEST = 'NEWSLETTER_TEMPLATES_LIST_REQUEST';
export const NEWSLETTER_TEMPLATES_LIST_RESPONSE = 'NEWSLETTER_TEMPLATES_LIST_RESPONSE';
export const NEWSLETTER_LAYOUTS_LIST_REQUEST = 'NEWSLETTER_LAYOUTS_LIST_REQUEST';
export const NEWSLETTER_LAYOUTS_LIST_RESPONSE = 'NEWSLETTER_LAYOUTS_LIST_RESPONSE';
export const ESTIMATE_CUSTOMERS_REQUEST = 'ESTIMATE_CUSTOMERS_REQUEST';
export const ESTIMATE_CUSTOMERS_RESPONSE = 'ESTIMATE_CUSTOMERS_RESPONSE';
export const ESTIMATE_CUSTOMERS_LOADED = 'ESTIMATE_CUSTOMERS_LOADED';

// EXTRA_CUSTOMER_FIELDS
export const EXTRA_CUSTOMER_FIELDS_LIST_REQUEST = 'EXTRA_CUSTOMER_FIELDS_LIST_REQUEST';
export const EXTRA_CUSTOMER_FIELDS_LIST_RESPONSE = 'EXTRA_CUSTOMER_FIELDS_LIST_RESPONSE';

// TREATMENT TYPOLOGIES
export const TREATMENT_TYPOLOGIES_LIST_REQUEST = 'TREATMENT_TYPOLOGIES_LIST_REQUEST';
export const TREATMENT_TYPOLOGIES_LIST_RESPONSE = 'TREATMENT_TYPOLOGIES_LIST_RESPONSE';

// VENUE_CUSTOM_TIME_TABLES
export const VENUE_CUSTOM_TIME_TABLES_REQUEST = 'VENUE_CUSTOM_TIME_TABLES_REQUEST';
export const VENUE_CUSTOM_TIME_TABLES_RESPONSE = 'VENUE_CUSTOM_TIME_TABLES_RESPONSE';
export const VENUE_CREATE_CUSTOM_TIME_TABLES_REQUEST = 'VENUE_CREATE_CUSTOM_TIME_TABLES_REQUEST';
export const VENUE_CREATE_CUSTOM_TIME_TABLES_RESPONSE = 'VENUE_CREATE_CUSTOM_TIME_TABLES_RESPONSE';
export const VENUE_UPDATE_CUSTOM_TIME_TABLES_REQUEST = 'VENUE_UPDATE_CUSTOM_TIME_TABLES_REQUEST';
export const VENUE_UPDATE_CUSTOM_TIME_TABLES_RESPONSE = 'VENUE_UPDATE_CUSTOM_TIME_TABLES_RESPONSE';
export const VENUE_DELETE_CUSTOM_TIME_TABLES_REQUEST = 'VENUE_DELETE_CUSTOM_TIME_TABLES_REQUEST';
export const VENUE_DELETE_CUSTOM_TIME_TABLES_RESPONSE = 'VENUE_DELETE_CUSTOM_TIME_TABLES_RESPONSE';

// VENUE_EXTRA_OPENINGS
export const VENUE_EXTRA_OPENINGS_REQUEST = 'VENUE_EXTRA_OPENINGS_REQUEST';
export const VENUE_EXTRA_OPENINGS_RESPONSE = 'VENUE_EXTRA_OPENINGS_RESPONSE';
export const VENUE_CREATE_EXTRA_OPENINGS_BULK_RESPONSE = 'VENUE_CREATE_EXTRA_OPENINGS_BULK_RESPONSE';
export const VENUE_CREATE_EXTRA_OPENINGS_RESPONSE = 'VENUE_CREATE_EXTRA_OPENINGS_RESPONSE';
export const VENUE_UPDATE_EXTRA_OPENINGS_RESPONSE = 'VENUE_UPDATE_EXTRA_OPENINGS_RESPONSE';
export const VENUE_DELETE_EXTRA_OPENINGS_RESPONSE = 'VENUE_DELETE_EXTRA_OPENINGS_RESPONSE';

// VENUE_VACANCIES
export const VENUE_VACANCIES_REQUEST = 'VENUE_VACANCIES_REQUEST';
export const VENUE_VACANCIES_RESPONSE = 'VENUE_VACANCIES_RESPONSE';
export const VENUE_CREATE_VACANCY_REQUEST = 'VENUE_CREATE_VACANCY_REQUEST';
export const VENUE_CREATE_VACANCY_RESPONSE = 'VENUE_CREATE_VACANCY_RESPONSE';
export const VENUE_UPDATE_VACANCY_REQUEST = 'VENUE_UPDATE_VACANCY_REQUEST';
export const VENUE_UPDATE_VACANCY_RESPONSE = 'VENUE_UPDATE_VACANCY_RESPONSE';
export const VENUE_DELETE_VACANCY_REQUEST = 'VENUE_DELETE_VACANCY_REQUEST';
export const VENUE_DELETE_VACANCY_RESPONSE = 'VENUE_DELETE_VACANCY_RESPONSE';

// VENUE_TREATMENTS
export const VENUE_TREATMENTS_LIST_REQUEST = 'VENUE_TREATMENTS_LIST_REQUEST';
export const VENUE_TREATMENTS_LIST_RESPONSE = 'VENUE_TREATMENTS_LIST_RESPONSE';
export const VENUE_TREATMENTS_CREATE_RESPONSE = 'VENUE_TREATMENTS_CREATE_RESPONSE';
export const VENUE_TREATMENTS_DELETE_RESPONSE = 'VENUE_TREATMENTS_DELETE_RESPONSE';
export const VENUE_TREATMENTS_UPDATE = 'VENUE_TREATMENTS_UPDATE';

// VENUE_PRODUCTS
export const VENUE_PRODUCTS_LIST_REQUEST = 'VENUE_PRODUCTS_LIST_REQUEST';
export const VENUE_PRODUCTS_LIST_RESPONSE = 'VENUE_PRODUCTS_LIST_RESPONSE';

// PRODUCTS
export const PRODUCTS_LIST_REQUEST = 'PRODUCTS_LIST_REQUEST';
export const PRODUCTS_LIST_RESPONSE = 'PRODUCTS_LIST_RESPONSE';

// VENUE_MARKETING_PROMOTIONS
export const VENUE_MARKETING_PROMOTIONS_LIST_REQUEST = 'VENUE_MARKETING_PROMOTIONS_LIST_REQUEST';
export const VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE = 'VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE';
export const VENUE_MARKETING_PROMOTIONS_TYPES_LIST_REQUEST = 'VENUE_MARKETING_PROMOTIONS_TYPES_LIST_REQUEST';
export const VENUE_MARKETING_PROMOTIONS_TYPES_LIST_RESPONSE = 'VENUE_MARKETING_PROMOTIONS_TYPES_LIST_RESPONSE';
export const VENUE_CUSTOMER_MARKETING_PROMOTIONS_LIST_REQUEST = 'VENUE_CUSTOMER_MARKETING_PROMOTIONS_LIST_REQUEST';
export const VENUE_CUSTOMER_MARKETING_PROMOTIONS_LIST_RESPONSE = 'VENUE_CUSTOMER_MARKETING_PROMOTIONS_LIST_RESPONSE';
export const VENUE_APPLIABLE_MARKETING_PROMOTIONS_REQUEST = 'VENUE_APPLIABLE_MARKETING_PROMOTIONS_REQUEST';
export const VENUE_APPLIABLE_MARKETING_PROMOTIONS_RESPONSE = 'VENUE_APPLIABLE_MARKETING_PROMOTIONS_RESPONSE';

// MANUFACTURERS
export const VENUE_MANUFACTURERS_LIST_REQUEST = 'VENUE_MANUFACTURERS_LIST_REQUEST';
export const VENUE_MANUFACTURERS_LIST_RESPONSE = 'VENUE_MANUFACTURERS_LIST_RESPONSE';
export const VENUE_MANUFACTURERS_CREATE_RESPONSE = 'VENUE_MANUFACTURERS_CREATE_RESPONSE';

// SUPPLIERS
export const VENUE_SUPPLIERS_LIST_REQUEST = 'VENUE_SUPPLIERS_LIST_REQUEST';
export const VENUE_SUPPLIERS_LIST_RESPONSE = 'VENUE_SUPPLIERS_LIST_RESPONSE';
export const VENUE_SUPPLIERS_CREATE_RESPONSE = 'VENUE_SUPPLIERS_CREATE_RESPONSE';

// WORKSTATIONS
export const VENUE_WORKSTATIONS_LIST_REQUEST = 'VENUE_WORKSTATIONS_LIST_REQUEST';
export const VENUE_WORKSTATIONS_LIST_RESPONSE = 'VENUE_WORKSTATIONS_LIST_RESPONSE';
export const VENUE_WORKSTATIONS_UPDATE_RESPONSE = 'VENUE_WORKSTATIONS_UPDATE_RESPONSE';
export const VENUE_WORKSTATIONS_DELETE_RESPONSE = 'VENUE_WORKSTATIONS_DELETE_RESPONSE';
export const VENUE_WORKSTATIONS_CREATE_RESPONSE = 'VENUE_WORKSTATIONS_CREATE_RESPONSE';

// WORKSTATIONS TREATMENTS
export const VENUE_WORKSTATION_TREATMENTS_LIST_REQUEST = 'VENUE_WORKSTATION_TREATMENTS_LIST_REQUEST';
export const VENUE_WORKSTATION_TREATMENTS_LIST_RESPONSE = 'VENUE_WORKSTATION_TREATMENTS_LIST_RESPONSE';
export const VENUE_WORKSTATION_TREATMENTS_DELETE_RESPONSE = 'VENUE_WORKSTATIONS_TREATMENTS_DELETE_RESPONSE';
export const VENUE_WORKSTATION_TREATMENTS_CREATE_RESPONSE = 'VENUE_WORKSTATIONS_TREATMENTS_CREATE_RESPONSE';

// TRANSACTIONS
export const VENUE_TRANSACTIONS_LIST_REQUEST = 'VENUE_TRANSACTIONS_LIST_REQUEST';
export const VENUE_TRANSACTIONS_LIST_RESPONSE = 'VENUE_TRANSACTIONS_LIST_RESPONSE';

// ORDERS
export const VENUE_ORDERS_LIST_REQUEST = 'VENUE_ORDERS_LIST_REQUEST';
export const VENUE_ORDERS_LIST_RESPONSE = 'VENUE_ORDERS_LIST_RESPONSE';
export const VENUE_ORDERS_CREATE_RESPONSE = 'VENUE_ORDERS_CREATE_RESPONSE';

// DEADLINES
export const DEADLINES_LIST_REQUEST = 'DEADLINES_LIST_REQUEST';
export const DEADLINES_LIST_RESPONSE = 'DEADLINES_LIST_RESPONSE';

// VENUE_STAFF_MEMBER_TREATMENTS
export const VENUE_STAFF_MEMBER_TREATMENTS_LIST_REQUEST = 'VENUE_STAFF_MEMBER_TREATMENTS_LIST_REQUEST';
export const VENUE_STAFF_MEMBER_TREATMENTS_LIST_RESPONSE = 'VENUE_STAFF_MEMBER_TREATMENTS_LIST_RESPONSE';
export const VENUE_STAFF_MEMBER_TREATMENTS_CREATE_RESPONSE = 'VENUE_STAFF_MEMBER_TREATMENTS_CREATE_RESPONSE';
export const VENUE_STAFF_MEMBER_TREATMENTS_UPDATE_RESPONSE = 'VENUE_STAFF_MEMBER_TREATMENTS_UPDATE_RESPONSE';
export const VENUE_STAFF_MEMBER_TREATMENTS_DELETE_RESPONSE = 'VENUE_STAFF_MEMBER_TREATMENTS_DELETE_RESPONSE';
export const VENUE_STAFF_MEMBER_TREATMENTS_BULK_DELETE = 'VENUE_STAFF_MEMBER_TREATMENTS_BULK_DELETE';

// VENUE APPOINTMENTS
export const VENUE_APPOINTMENTS_LIST_REQUEST = 'VENUE_APPOINTMENTS_LIST_REQUEST';
export const VENUE_APPOINTMENTS_LIST_RESPONSE = 'VENUE_APPOINTMENTS_LIST_RESPONSE';
export const VENUE_CUSTOMERS_WITH_APPOINTMENTS_REQUEST = 'VENUE_CUSTOMERS_WITH_APPOINTMENTS_REQUEST';
export const VENUE_CUSTOMERS_WITH_APPOINTMENTS_RESPONSE = 'VENUE_CUSTOMERS_WITH_APPOINTMENTS_RESPONSE';
export const VENUE_CUSTOMER_CHECKIN_REQUEST = 'VENUE_CUSTOMER_CHECKIN_REQUEST';
export const VENUE_CUSTOMER_CHECKIN_RESPONSE = 'VENUE_CUSTOMER_CHECKIN_RESPONSE';
export const VENUE_CUSTOMER_CHECKOUT_REQUEST = 'VENUE_CUSTOMER_CHECKOUT_REQUEST';
export const VENUE_CUSTOMER_CHECKOUT_RESPONSE = 'VENUE_CUSTOMER_CHECKOUT_RESPONSE';
export const VENUE_CUSTOMER_DEBTS = 'VENUE_CUSTOMER_DEBTS';
export const VENUE_APPOINTMENT_EDIT_REQUEST = 'VENUE_APPOINTMENT_EDIT_REQUEST';
export const VENUE_APPOINTMENT_EDIT_RESPONSE = 'VENUE_APPOINTMENT_EDIT_RESPONSE';
export const VENUE_LOCAL_APPOINTMENT_CREATE = 'VENUE_LOCAL_APPOINTMENT_CREATE';
export const VENUE_LOCAL_APPOINTMENT_DELETE = 'VENUE_LOCAL_APPOINTMENT_DELETE';
export const VENUE_LOCAL_APPOINTMENT_EDIT = 'VENUE_LOCAL_APPOINTMENT_EDIT';
export const VENUE_START_LOCAL_APPOINTMENT_EDIT = 'VENUE_START_LOCAL_APPOINTMENT_EDIT';
export const VENUE_LEAVE_LOCAL_APPOINTMENT_EDIT = 'VENUE_LEAVE_LOCAL_APPOINTMENT_EDIT';

// VENUE CHECKOUTS
export const VENUE_CHECKOUTS_LIST_REQUEST = 'VENUE_CHECKOUTS_LIST_REQUEST';
export const VENUE_CHECKOUTS_LIST_RESPONSE = 'VENUE_CHECKOUTS_LIST_RESPONSE';
export const VENUE_LINE_ITEMS_LIST_REQUEST = 'VENUE_LINE_ITEMS_LIST_REQUEST';
export const VENUE_LINE_ITEMS_LIST_RESPONSE = 'VENUE_LINE_ITEMS_LIST_RESPONSE';
export const VENUE_CHECKOUTS_CREATE_REQUEST = 'VENUE_CHECKOUTS_CREATE_REQUEST';
export const VENUE_CHECKOUTS_CREATE_RESPONSE = 'VENUE_CHECKOUTS_CREATE_RESPONSE';

// VENUE CUSTOMER CARTS
export const VENUE_CUSTOMER_CART_CREATE = 'VENUE_CUSTOMER_CART_CREATE';
export const VENUE_CUSTOMER_CART_UPDATE = 'VENUE_CUSTOMER_CART_UPDATE';
export const VENUE_CUSTOMER_CART_APPLY_PROMOTIONS = 'VENUE_CUSTOMER_CART_APPLY_PROMOTIONS';
export const VENUE_CUSTOMER_CART_APPLY_GIFT_CARDS = 'VENUE_CUSTOMER_CART_APPLY_GIFT_CARDS';
export const VENUE_CUSTOMER_CARTS_LIST_REQUEST = 'VENUE_CUSTOMER_CARTS_LIST_REQUEST';
export const VENUE_CUSTOMER_CARTS_LIST_RESPONSE = 'VENUE_CUSTOMER_CARTS_LIST_RESPONSE';
export const VENUE_CUSTOMER_CART_ADD_LAST_STAFF_MEMBER_BY_CART = 'VENUE_CUSTOMER_CART_ADD_LAST_STAFF_MEMBER_BY_CART';
export const VENUE_CUSTOMER_CART_FLUSH_LAST_STAFF_MEMBER_BY_CART =
  'VENUE_CUSTOMER_CART_FLUSH_LAST_STAFF_MEMBER_BY_CART';
export const VENUE_CUSTOMER_CART_REMOVE_LAST_STAFF_MEMBER_BY_CART =
  'VENUE_CUSTOMER_CART_REMOVE_LAST_STAFF_MEMBER_BY_CART';

// VENUE CUSTOMERS
export const VENUE_LOCAL_CUSTOMER_HIDE_TERMS = 'VENUE_LOCAL_CUSTOMER_HIDE_TERMS';

// VENUE FISCAL DAYS
export const VENUE_FISCAL_DAYS_LIST_REQUEST = 'VENUE_FISCAL_DAYS_LIST_REQUEST';
export const VENUE_FISCAL_DAYS_LIST_RESPONSE = 'VENUE_FISCAL_DAYS_LIST_RESPONSE';

export const VENUE_NOTIFICATION_CREDIT_REQUEST = 'VENUE_NOTIFICATION_CREDIT_REQUEST';
export const VENUE_NOTIFICATION_CREDIT_RESPONSE = 'VENUE_NOTIFICATION_CREDIT_RESPONSE';

// VENUE CHART DATA
export const VENUE_CHART_QUERY_PARAMS = 'VENUE_CHART_QUERY_PARAMS';
export const VENUE_CHART_DATA_REQUEST = 'VENUE_CHART_DATA_REQUEST';
export const VENUE_CHART_DATA_RESPONSE = 'VENUE_CHART_DATA_RESPONSE';

// VENUE INVENTORY DATA
export const VENUE_INVENTORY_QUERY_PARAMS = 'VENUE_INVENTORY_QUERY_PARAMS';
export const VENUE_INVENTORY_DATA_REQUEST = 'VENUE_INVENTORY_DATA_REQUEST';
export const VENUE_INVENTORY_DATA_RESPONSE = 'VENUE_INVENTORY_DATA_RESPONSE';

// VENUE REPORT DATA
export const VENUE_REPORT_DATA_REQUEST = 'VENUE_TREND_REPORT_REQUEST';
export const VENUE_REPORT_DATA_RESPONSE = 'VENUE_TREND_REPORT_RESPONSE';

// MODALS
export const MODALS_OPEN = 'MODALS_OPEN';
export const MODALS_CLOSE = 'MODALS_CLOSE';

// DATE
export const GET_CURRENT_DATE = 'GET_CURRENT_DATE';

// VACANCY TYPOLOGIES
export const VACANCY_TYPOLOGIES_LIST_REQUEST = 'VACANCY_TYPOLOGIES_LIST_REQUEST';
export const VACANCY_TYPOLOGIES_LIST_RESPONSE = 'VACANCY_TYPOLOGIES_LIST_RESPONSE';

// AGENDA
export const AGENDA_NEW_ITEM_CREATED = 'AGENDA_NEW_ITEM_CREATED';
export const AGENDA_ITEM_CUT = 'AGENDA_ITEM_CUT';
export const AGENDA_RESET_CUT_ITEMS = 'AGENDA_RESET_CUT_ITEMS';
export const AGENDA_SET_ACTIVE_CUSTOMER = 'AGENDA_SET_ACTIVE_CUSTOMER';
export const AGENDA_RESET_ACTIVE_CUSTOMER = 'AGENDA_RESET_ACTIVE_CUSTOMER';
export const AGENDA_UPDATE_NEW_CUSTOMER_DATA = 'AGENDA_UPDATE_NEW_CUSTOMER_DATA';
export const AGENDA_RESET_NEW_CUSTOMER_DATA = 'AGENDA_RESET_NEW_CUSTOMER_DATA';
export const AGENDA_CLOSE_CALLER_BOX = 'AGENDA_CLOSE_CALLER_BOX';

// LOOKS
export const FETCH_LOOKS_REQUEST = 'FETCH_LOOKS_REQUEST';
export const FETCH_LOOKS_RESPONSE = 'FETCH_LOOKS_RESPONSE';
export const DELETE_LOOK_MEDIA_ATTACHMENT_REQUEST = 'DELETE_LOOK_MEDIA_ATTACHMENT_REQUEST';
export const DELETE_LOOK_MEDIA_ATTACHMENT_RESPONSE = 'DELETE_LOOK_MEDIA_ATTACHMENT_RESPONSE';
export const CREATE_LOOK_REQUEST = 'CREATE_LOOK_REQUEST';
export const CREATE_LOOK_RESPONSE = 'CREATE_LOOK_RESPONSE';
export const CREATE_MEDIA_ATTACHMENT_REQUEST = 'CREATE_MEDIA_ATTACHMENT_REQUEST';
export const CREATE_MEDIA_ATTACHMENT_RESPONSE = 'CREATE_MEDIA_ATTACHMENT_RESPONSE';
export const UPDATE_MEDIA_ATTACHMENT_REQUEST = 'UPDATE_MEDIA_ATTACHMENT_REQUEST';
export const UPDATE_MEDIA_ATTACHMENT_RESPONSE = 'UPDATE_MEDIA_ATTACHMENT_RESPONSE';
export const FILTER_LOOKS_BY_DATE_RANGE = 'FILTER_LOOKS_BY_DATE_RANGE';
export const FILTER_LOOK_BY_APPOINTMENT = 'FILTER_LOOK_BY_APPOINTMENT';

// CUSTOMER TAGS
export const VENUE_FETCH_CUSTOMER_TAGS_START = 'VENUE_FETCH_CUSTOMER_TAGS_START';

// BRAND BENCHMARK TARGETS
export const FETCH_BRAND_BENCHMARK_REQUEST = 'FETCH_BRAND_BENCHMARK_REQUEST';
export const FETCH_BRAND_BENCHMARK_RESPONSE = 'FETCH_BRAND_BENCHMARK_RESPONSE';
export const UPDATE_BRAND_BENCHMARK_REQUEST = 'UPDATE_BRAND_BENCHMARK_REQUEST';
export const UPDATE_BRAND_BENCHMARK_RESPONSE = 'UPDATE_BRAND_BENCHMARK_RESPONSE';

// GRACE PERIOD
export const LOCAL_GRACE_PERIOD = 'LOCAL_GRACE_PERIOD';

// ECOMMERCE
export const VENUE_FETCH_ECOMMERCE_ORDERS_REQUEST = 'VENUE_FETCH_ECOMMERCE_ORDERS_REQUEST';
export const VENUE_FETCH_ECOMMERCE_ORDERS_RESPONSE = 'VENUE_FETCH_ECOMMERCE_ORDERS_RESPONSE';

// AGENDA SETTINGS
export const SET_AGENDA_SETTINGS = 'SET_AGENDA_SETTINGS';

// SHIPPING OPTIONS
export const REQUEST_SHIPPERS = 'REQUEST_SHIPPERS';
export const RESPONSE_SHIPPERS = 'RESPONSE_SHIPPERS';
export const REQUEST_SHIPPING_OPTIONS = 'REQUEST_SHIPPING_OPTIONS';
export const RESPONSE_SHIPPING_OPTIONS = 'RESPONSE_SHIPPING_OPTIONS';
export const REQUEST_SHIPPING_OPTION_BY_ID = 'REQUEST_SHIPPING_OPTION_BY_ID';
export const RESPONSE_SHIPPING_OPTION_BY_ID = 'RESPONSE_SHIPPING_OPTION_BY_ID';

// MULTIVENUE SIDEBAR
export const MULTI_VENUE_SIDEBAR_DISABLE = 'MULTI_VENUE_SIDEBAR_DISABLE';
export const MULTI_VENUE_SIDEBAR_ENABLE = 'MULTI_VENUE_SIDEBAR_ENABLE';
export const MULTI_VENUE_SIDEBAR_TOGGLE = 'MULTI_VENUE_SIDEBAR_TOGGLE';
export const MULTI_VENUE_SIDEBAR_HIDE = 'MULTI_VENUE_SIDEBAR_HIDE';
export const MULTI_VENUE_SIDEBAR_SHOW = 'MULTI_VENUE_SIDEBAR_SHOW';

// FISCAL PRINTER
export const FISCAL_PRINTER_SET_FEATURES = 'FISCAL_PRINTER_SET_FEATURES';

// PATCH TESTS
export const REQUEST_PATCH_TESTS = 'REQUEST_PATCH_TESTS';
export const RESPONSE_PATCH_TESTS = 'RESPONSE_PATCH_TESTS';
export const UPDATE_PATCH_TEST = 'UPDATE_PATCH_TEST';

// VENUE TREATMENT GROUPS
export const VENUE_TREATMENT_GROUP_LIST_REQUEST = 'VENUE_TREATMENT_GROUP_LIST_REQUEST';
export const VENUE_TREATMENT_GROUP_LIST_RESPONSE = 'VENUE_TREATMENT_GROUP_LIST_RESPONSE';
export const VENUE_TREATMENT_GROUP_REQUEST = 'VENUE_TREATMENT_GROUP_REQUEST';
export const VENUE_TREATMENT_GROUP_RESPONSE = 'VENUE_TREATMENT_GROUP_RESPONSE';
export const VENUE_TREATMENT_GROUP_CREATE_RESPONSE = 'VENUE_TREATMENT_GROUP_CREATE_RESPONSE';
export const VENUE_TREATMENT_GROUP_DELETE_RESPONSE = 'VENUE_TREATMENT_GROUP_DELETE_RESPONSE';
export const VENUE_TREATMENT_GROUP_UPDATE_RESPONSE = 'VENUE_TREATMENT_GROUP_UPDATE_RESPONSE';

// PACKAGES
export const RECEIVE_UPDATED_PACKAGE = '@package/RECEIVE_UPDATED_PACKAGE';
export const RECEIVE_DELETED_PACKAGE = '@package/RECEIVE_DELETED_PACKAGE';
export const RECEIVE_NEW_PACKAGE = '@package/RECEIVE_NEW_PACKAGE';
export const RECEIVE_PACKAGE = '@package/RECEIVE_PACKAGE';
export const REQUEST_PACKAGE = '@package/REQUEST_PACKAGE';
export const REQUEST_PACKAGE_LIST = '@package/REQUEST_PACKAGE_LIST';
export const RECEIVE_PACKAGE_LIST = '@package/RECEIVE_PACKAGE_LIST';

// TREATMENT CATEGORIES
export const TREATMENT_CATEGORIES_LIST_REQUEST = '@treatmentCategories/REQUEST_CATEGORIES_LIST';
export const TREATMENT_CATEGORIES_LIST_RESPONSE = '@treatmentCategories/RESPONSE_CATEGORIES_LIST';
