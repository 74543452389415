import { styled, css } from 'styles';
import { withTheme } from '../Context';
import Text from 'components/core/Text';

const Bar = styled('div')(
  ({ color, bgColor, theme: { colors } }) => css`
    background-color: ${colors[bgColor] || bgColor || colors.progressBg};

    > div {
      background-color: ${colors[color] || color || colors.progressColor};
    }
  `
);

const Circle = styled('div')`
  width: inherit;
  height: inherit;

  > svg {
    display: block;
  }
`;

const CircleBg = styled('circle')(
  ({ bgColor, theme: { colors } }) => css`
    stroke: ${colors[bgColor] || bgColor || colors.progressBg};
  `
);

const CircleMeter = styled('circle')(
  ({ color, theme: { colors } }) => css`
    stroke: ${colors[color] || color || colors.progressColor};
    stroke-linecap: round;
    transform-origin: 50% 50%;
    transform: rotate(-90deg);
  `
);

const Value = styled(Text)`
  &.Progress__Value--Bar {
  }

  &.Progress__Value--Circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const Progress = styled('div')(
  ({ width, circle, value, thickness }) => css`
    position: relative;
    ${width ? `width:${width}${!/%$/.test(width) ? 'px' : ''};` : 'auto'};
    ${width && circle ? `height:${width}${!/%$/.test(width) ? 'px' : ''};` : 'auto'};

    .Progress--Bar {
      border-radius: ${thickness / 2}px;
    }

    .Progress--Bar > div {
      /* prevent to have values higher than 100% */
      width: ${value > 100 ? 100 : value}%;
      height: ${thickness}px;
      border-radius: ${thickness / 2}px;
    }
  `
);

Progress.Circle = Circle;
Progress.Bar = withTheme(Bar);

Progress.CircleBg = withTheme(CircleBg);
Progress.CircleMeter = withTheme(CircleMeter);

Progress.Value = Value;

export default Progress;
