import { ComponentProps, forwardRef, FunctionComponent, ReactElement } from 'react';
import { StyledHeading, StyledHeadingProps } from './theme';
import { FormattedMessage, FormattedNumber } from 'react-intl';

type IntlValues = ComponentProps<typeof FormattedMessage>['values'];

type HeadingProps = StyledHeadingProps & {
  /** If is setup react-intl Renderer, you can pass the translation id and values to show the localized string without importing FormattedMessage */
  intl?: string;
  intlValues?: IntlValues;
  /** If is setup react-intl Renderer, you can pass the number without importing FormattedNumber */
  number?: number;
  numberProps?: Record<string, unknown>;
  title?: string;
};

const Heading: FunctionComponent<HeadingProps> = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ editable = false, intl, intlValues, number, numberProps, ...rest }, ref) => {
    if (intl) {
      return (
        <StyledHeading className="Heading" ref={ref} {...rest}>
          <FormattedMessage id={intl} values={intlValues || {}}>
            {(txt) => txt as unknown as ReactElement}
          </FormattedMessage>
        </StyledHeading>
      );
    }

    if (number) {
      return (
        <StyledHeading className="Heading" ref={ref} {...rest}>
          <FormattedNumber value={number} {...numberProps}>
            {(txt: string): string => txt}
          </FormattedNumber>
        </StyledHeading>
      );
    }

    return (
      <StyledHeading
        className="Heading"
        ref={ref}
        {...rest}
        {...(editable && { type: 'text', autoComplete: 'nope' })}
      />
    );
  }
);

Heading.displayName = 'Heading';

export default Heading;
