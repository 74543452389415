/* eslint-disable no-restricted-imports */
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeExternalAPIResponseToJSON } from 'API/http.utils';
import { BillingBalanceShowVariables, BillingBalanceResponse } from 'API/types';

export const BILLING_BALANCE_SHOW = () => (): Promise<BillingBalanceResponse> =>
  Http()
    .get<BillingBalanceResponse, BillingBalanceShowVariables>(ENDPOINT.BILLING_BALANCE._SHOW())({})
    .then(maybeExternalAPIResponseToJSON);
