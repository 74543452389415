import {
  MULTI_VENUE_SIDEBAR_ENABLE,
  MULTI_VENUE_SIDEBAR_DISABLE,
  MULTI_VENUE_SIDEBAR_TOGGLE,
  MULTI_VENUE_SIDEBAR_HIDE,
  MULTI_VENUE_SIDEBAR_SHOW,
} from 'actionTypes';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { MultiVenueSidebarSettings } from 'models';
import { MultiVenueSidebarSettingsActionTypes } from 'actions/multiVenueSidebarSettings';

// prevent number to be lower than 0
const decreaseStateValue = (value: number): number => Math.max(0, value - 1);

const defaultMultiVenueSidebarState: MultiVenueSidebarSettings = {
  /**
   * isSwitchingVenueDisabled is a number because in the same page MULTI_VENUE_SIDEBAR_ENABLE or MULTI_VENUE_SIDEBAR_DISABLE actions could be dispatched several times,
   * in this way the sidebar is disabled untile all components dispatch MULTI_VENUE_SIDEBAR_DISABLE action
   */
  isSwitchingVenueDisabled: 0,
  isOpen: false,
  isHidden: 0,
};

const multiVenueSidebarSettings = (
  state: MultiVenueSidebarSettings = defaultMultiVenueSidebarState,
  action: MultiVenueSidebarSettingsActionTypes | LocationChangeAction
): MultiVenueSidebarSettings => {
  switch (action.type) {
    case MULTI_VENUE_SIDEBAR_ENABLE:
      return { ...state, isSwitchingVenueDisabled: decreaseStateValue(state.isSwitchingVenueDisabled) };

    case MULTI_VENUE_SIDEBAR_DISABLE:
      return { ...state, isSwitchingVenueDisabled: state.isSwitchingVenueDisabled + 1 };

    case MULTI_VENUE_SIDEBAR_TOGGLE:
      return { ...state, isOpen: action.isOpen || !state.isOpen };

    case MULTI_VENUE_SIDEBAR_HIDE:
      return { ...state, isHidden: state.isHidden + 1 };

    case MULTI_VENUE_SIDEBAR_SHOW:
      return { ...state, isHidden: decreaseStateValue(state.isHidden) };

    case LOCATION_CHANGE: // always close sidebar when changing page
      return { ...state, isOpen: false };

    default:
      return state;
  }
};

export { multiVenueSidebarSettings };
