import { FunctionComponent } from 'react';
import { Consumer } from 'providers/GlobalsProvider';

const Currency: FunctionComponent = ({ children }) => {
  if (typeof children === 'function') {
    return <Consumer>{(v) => children(v.currencySymbol)}</Consumer>;
  }
  return <Consumer>{(v): string => v.currencySymbol}</Consumer>;
};

export default Currency;
