import { styled, css } from 'styles';
import { withTheme } from '../Context';

export const Shape = styled('div')(
  ({ scrollable }) => css`
    position: ${scrollable ? 'absolute' : 'fixed'};
    overflow-y: ${scrollable ? 'scroll' : 'unset'};
    z-index: 10000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  `
);

export const Background = styled('div')(
  ({ scrollable }) => css`
    position: ${scrollable ? 'fixed' : 'absolute'};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.4;
  `
);

export const Wrapper = withTheme(styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  border-radius: 0;

  ${({ theme: { media, sizes } }) => media.tablet`
      margin: ${sizes.padding}px;
      border-radius: ${sizes.borderRadiusMedium}px;
    `};

  background-color: ${({ theme }) => theme.colors.bgLightGray};
  box-shadow: ${({ theme }) => theme.boxShadow.block};
  display: flex;
  flex-direction: column;
`);

export const Header = withTheme(styled('div')`
  position: relative;
  width: 100%;
  height: 63px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.blockBorder};
`);

export const Content = styled('div')`
  position: relative;
  flex: 1;
`;

export const Close = styled('div')`
  position: absolute;
  right: 0;
  top: 0;
  width: 63px;
  height: 63px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled('div')`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderAction = styled('div')`
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 20px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
`;
