import { FunctionComponent, ReactElement } from 'react';

import { Section, Select, Text } from 'components/core';
import { discountOptions } from './utils';

type DiscountSelectPropTypes = {
  promoFlash: {
    included_resources_attributes: ReadonlyArray<{
      discount_percent_amount: string;
    }>;
  };
  onGlobalDiscountChange: () => void;
};

const PromoFlashEditDiscountSelect: FunctionComponent<DiscountSelectPropTypes> = ({
  promoFlash,
  onGlobalDiscountChange,
}): ReactElement => (
  <Section displayFlex alignItems="center" paddingBottom={1.5} paddingTop={1.5} flexDirection="column" borderBottom>
    <Text flex={1} size={12} color="text2" intl="checkout.discount" uppercase spacing={1.2} />
    <Section marginTop={1.5} width={121}>
      <Select
        value={promoFlash.included_resources_attributes[0].discount_percent_amount}
        onChange={onGlobalDiscountChange}
      >
        {[
          <Select.Option value={0}>-</Select.Option>,
          ...discountOptions.map((v) => (
            <Select.Option key={v} value={v / 100}>
              {v}%
            </Select.Option>
          )),
        ]}
      </Select>
    </Section>
  </Section>
);

export default PromoFlashEditDiscountSelect;
