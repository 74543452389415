export enum CurrencyISOCodes {
  EUR = 'EUR',
  CHF = 'CHF',
  GBP = 'GBP',
}

export interface ClientCurrency {
  currencyCode: CurrencyISOCodes;
  currencySymbol: string;
}

/**
 * Currencies
 */

const eur = {
  currencyCode: CurrencyISOCodes.EUR,
  currencySymbol: '€',
};

const chf = {
  currencyCode: CurrencyISOCodes.CHF,
  currencySymbol: 'CHF',
};

const gbp = {
  currencyCode: CurrencyISOCodes.GBP,
  currencySymbol: '£',
};

export const availableCurrencies: ClientCurrency[] = [eur, chf, gbp];

export const indexedAvailableCurrencies: { [key in keyof typeof CurrencyISOCodes]: ClientCurrency } = {
  EUR: eur,
  CHF: chf,
  GBP: gbp,
};
