export const MULTI_VENUE_SIDEBAR_WIDTH = 80;

export const SIZES = {
  goldenRatio: 1.618,
  padding: 20,
  spacing: 20,
  borderRadiusMedium: 8,

  mobileHeaderHeight: 60,
  desktopHeaderHeight: 90,
  appointmentHeaderHeight: 90,

  mobileBottomNavigationHeight: 80,
  desktopBottomNavigationHeight: 0,

  sidebarWidth: 308,
  mobileBreakpoint: 768,
  tabletBreakpoint: 1024,

  inputHeight: 46,

  multiVenueSidebarWidth: 0, // default value is 0, if user is multivenue it should be the same as MULTI_VENUE_SIDEBAR_WIDTH instead

  styles_2024: {
    h2FontSize: 16,
    padding: 16,
    buttonFontSize: 12,
  },
} as const;

export type Sizes = keyof typeof SIZES;
