const getTrend = (value, base, percentage = true) => {
  if (!base) return null;
  const delta = value - base;
  const trend = percentage && base ? (delta / base) * 100 : delta;
  const sign = delta >= 0 ? '+' : '-';
  const symbol = percentage ? '%' : '';
  return `${sign}${Math.round(Math.abs(trend))}${symbol}`;
};

export default getTrend;
