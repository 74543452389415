import { Selector, createSelector } from 'reselect';
import { getCurrentVenueId } from './getCurrentVenue';
import { EntitiesDictionaryByVenueSelector, EntityById, State } from 'reducers/types';
import {
  AcceptanceForm,
  Appointment,
  EcommerceOrder,
  FiscalDay,
  Order,
  OrderDeveliveryNote,
  StaffMemberTreatment,
  VenueTreatment,
  VenueTreatmentGroup,
} from 'models';

const emptyEntitiesResponse: EntityById<any> = {};

const getEntitiesByVenue =
  <Entity>(): EntitiesDictionaryByVenueSelector<Entity> =>
  (state) =>
    state.entitiesDictionaryByVenue;

const createEntitySelectorById = <Entity>(type: string): Selector<State, EntityById<Entity>> =>
  createSelector([getCurrentVenueId, getEntitiesByVenue<Entity>()], (venue_id, entitiesByVenue) => {
    return (venue_id && entitiesByVenue[venue_id] && entitiesByVenue[venue_id][type]) || emptyEntitiesResponse;
  });

export const getEntities = {
  customer: createEntitySelectorById('customer'),
  internal_customer_details: createEntitySelectorById('internal_customer_details'),
  checkout: createEntitySelectorById('checkout'),
  line_item: createEntitySelectorById('line_item'),
  venue_product: createEntitySelectorById('venue_product'),
  venue_treatment: createEntitySelectorById<VenueTreatment>('venue_treatment'),
  venue_treatment_group: createEntitySelectorById<VenueTreatmentGroup>('venue_treatment_group'),
  staff_member_treatment: createEntitySelectorById<StaffMemberTreatment>('staff_member_treatment'),
  staff_member: createEntitySelectorById('staff_member'),
  marketing_promotion: createEntitySelectorById('marketing_promotion'),
  appointment: createEntitySelectorById<Appointment>('appointment'),
  internal_appointment_details: createEntitySelectorById<Appointment>('internal_appointment_details'),
  supplier: createEntitySelectorById('supplier'),
  workstation: createEntitySelectorById('workstation'),
  workstation_treatment: createEntitySelectorById('workstation_treatment'),
  manufacturer: createEntitySelectorById('manufacturer'),
  fiscal_day: createEntitySelectorById<FiscalDay>('fiscal_day'),
  extra_opening: createEntitySelectorById('extra_opening'),
  custom_time_table: createEntitySelectorById('custom_time_table'),
  vacancy: createEntitySelectorById('vacancy'),
  order: createEntitySelectorById<Order>('order'),
  transactions: createEntitySelectorById('transactions'),
  venue_wizard: createEntitySelectorById('venue_wizard'),
  acceptance_form: createEntitySelectorById<AcceptanceForm>('acceptance_form'),
  ecommerce_order: createEntitySelectorById<EcommerceOrder>('ecommerce_order'),
  order_delivery_note: createEntitySelectorById<OrderDeveliveryNote>('order_delivery_note'),
};

export default getEntities;
