import { bool, func } from 'prop-types';

import { Section, Button, Text, Card, Icon } from 'components/core';

import { configurationIconsMarginRight, WizardModalWrapper } from './WizardAssistant.theme';

const WizardModal = ({
  staff_members_incomplete,
  timetables_incomplete,
  treatments_incomplete,
  onTimeTablesSet,
  onTreatmentsSet,
  onStaffSet,
}) => (
  <WizardModalWrapper>
    <Card>
      <Section paddingTop={1.25} paddingBottom={1.25} displayFlex flexDirection="column">
        <Text size={12} uppercase center intl="wizard_assistant.modal_title" />
        <Section displayFlex padding={1.25} paddingTop={2} alignItems="center" justifyContent="space-between">
          <Icon
            type="alarm"
            size={16}
            color={timetables_incomplete ? 'red' : 'green'}
            className={`${configurationIconsMarginRight}`}
          />
          <Text size={16} intl="loading.time_tables" />
          <Section displayFlex flex={1} />
          {timetables_incomplete && (
            <Button basic primary size="small" onClick={onTimeTablesSet}>
              <Text size={12} bold uppercase intl="set" />
            </Button>
          )}
          {!timetables_incomplete && <Icon type="tick-circle-2" color="green" size={32} />}
        </Section>
        <Section marginBottom={0.1} borderBottom />
        <Section displayFlex padding={1.25} alignItems="center" justifyContent="space-between">
          <Icon
            type="scissors"
            size={16}
            color={treatments_incomplete ? 'red' : 'green'}
            className={`${configurationIconsMarginRight}`}
          />
          <Text size={16} intl="manager.services" />
          <Section displayFlex flex={1} />
          {treatments_incomplete && (
            <Button basic primary size="small" onClick={onTreatmentsSet}>
              <Text size={12} bold uppercase intl="set" />
            </Button>
          )}
          {!treatments_incomplete && <Icon type="tick-circle-2" color="green" size={32} />}
        </Section>
        <Section marginBottom={0.1} borderBottom />
        <Section displayFlex padding={1.25} paddingBottom={1} alignItems="center" justifyContent="space-between">
          <Icon
            type="customers"
            size={16}
            color={staff_members_incomplete ? 'red' : 'green'}
            className={`${configurationIconsMarginRight}`}
          />
          <Text size={16} intl="loading.staff_members" />
          <Section displayFlex flex={1} />
          {staff_members_incomplete && (
            <Button basic primary size="small" onClick={onStaffSet}>
              <Text size={12} bold uppercase intl="set" />
            </Button>
          )}
          {!staff_members_incomplete && <Icon type="tick-circle-2" color="green" size={32} />}
        </Section>
      </Section>
    </Card>
  </WizardModalWrapper>
);

WizardModal.displayName = 'WizardModal';

WizardModal.propTypes = {
  staff_members_incomplete: bool,
  timetables_incomplete: bool,
  treatments_incomplete: bool,
  onTimeTablesSet: func,
  onTreatmentsSet: func,
  onStaffSet: func,
};

WizardModal.defaultProps = {
  staff_members_incomplete: false,
  timetables_incomplete: false,
  treatments_incomplete: false,
  onTimeTablesSet: () => {},
  onTreatmentsSet: () => {},
  onStaffSet: () => {},
};

export default WizardModal;
