import { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchCountries } from 'actions/countries';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { getCurrentVenueCountryId } from 'selectors/getCurrentVenue';
import { getApplicationHostname } from 'utils';

const MANAGER_PROTOCOL = window.location.protocol + '//';
const MANAGER_PORT = window.location.port && ':' + window.location.port;

const DomainProvider = ({ fetchCountries, auth_token, currentVenueCountryId, children }) => {
  const [countries, setCountries] = useState(null);
  const [appEnabled, setAppEnabled] = useState(false);

  useEffect(() => {
    if (auth_token && !countries) {
      fetchCountries().then(({ countries }) => {
        setCountries(countries);
      });
    }
  }, [auth_token, countries, fetchCountries]);

  useEffect(() => {
    if (auth_token && countries && currentVenueCountryId) {
      const currentHostname = window.location.hostname;
      const expectedApplicationHostname = getApplicationHostname(currentHostname, countries, currentVenueCountryId);

      if (currentHostname !== expectedApplicationHostname) {
        window.location.href = `${MANAGER_PROTOCOL}${expectedApplicationHostname}${MANAGER_PORT}?auth_token=${encodeURIComponent(
          auth_token
        )}`;
      } else {
        setAppEnabled(true);
      }
    }
  }, [auth_token, countries, currentVenueCountryId]);

  /**
   * Only if domain is checked, render app
   */
  if (appEnabled) {
    return children;
  }

  return null;
};

DomainProvider.displayName = 'DomainProvider';

const mapStateToProps = () =>
  createSelector(getCurrentUserToken, getCurrentVenueCountryId, (auth_token, currentVenueCountryId) => {
    return {
      auth_token,
      currentVenueCountryId,
    };
  });

const mapDispatchToProps = {
  fetchCountries,
};

export default connect(mapStateToProps, mapDispatchToProps)(memo(DomainProvider));
