export const onWheel = (e) => {
  e.stopPropagation();
  const { deltaY, deltaX } = e;
  const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

  const delta = deltaY === 0 ? deltaX : deltaY;

  if ((scrollTop === 0 && delta < 0) || (scrollTop >= scrollHeight - offsetHeight && delta > 0)) {
    e.preventDefault();
    return false;
  }
};

export const onTouchStart = (e) => {
  e.target.__touchStartY = e.touches[0].pageY;
};

export const onTouchMove = (e) => {
  e.stopPropagation();

  const delta = e.target.__touchStartY - e.touches[0].pageY;
  const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

  if ((scrollTop === 0 && delta < 0) || (scrollTop >= scrollHeight - offsetHeight && delta > 0)) {
    e.preventDefault();
    return false;
  }
};

export const stopScrollPropagation = (el) => {
  el.addEventListener('wheel', onWheel, { passive: false });
  el.addEventListener('touchstart', onTouchStart, { passive: false });
  el.addEventListener('touchmove', onTouchMove, { passive: false });

  return () => {
    el.removeEventListener('wheel', onWheel);
    el.removeEventListener('touchstart', onTouchStart);
    el.removeEventListener('touchmove', onTouchMove);
  };
};

export default stopScrollPropagation;
