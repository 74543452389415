import React, { ReactElement, useState, CSSProperties, ReactNode, useEffect } from 'react';
import Message from './theme';
import { Section, Text, Button, Icon } from 'components/core';

export enum Type {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  ORANGE = 'orange2',
  RED_LIGHT = 'red3',
  BLACK = 'black',
  BLUE = 'blue',
  LIGHT_BLUE = 'lightBlue2',
}

export enum Position {
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface AlertActionProps {
  actionMessage?: string;
  onActionClick?: () => void;
}

export interface AlertProps {
  width?: string;
  type: Type;
  position: Position;
  message?: string;
  messageValues?: object;
  show: boolean;
  onBannerClick: (show: boolean) => void;
  collapsible: boolean;
  uppercase: boolean;
  justifyContent: CSSProperties['justifyContent'];
  iconType?: string;
  children?: ReactNode;
  hasShadow?: boolean;
  forwardRef?: React.RefObject<unknown>;
  autoClose?: boolean;
  relative?: boolean;
  maxWidth?: string;
}

type Props = AlertProps & AlertActionProps;

const AlertBannerAction = ({ actionMessage, onActionClick }: AlertActionProps): ReactElement | null =>
  actionMessage ? (
    <Button onClick={onActionClick} basic={true} color={'white'} size={'small'}>
      <Text size={11} center={true} uppercase={true} bold intl={actionMessage} />
    </Button>
  ) : null;

const AlertBanner = ({
  width,
  type,
  position,
  message,
  messageValues,
  show,
  onBannerClick,
  collapsible,
  uppercase,
  actionMessage,
  onActionClick,
  justifyContent,
  iconType,
  children,
  hasShadow,
  forwardRef,
  autoClose = true,
  relative = false,
  maxWidth,
}: Props): ReactElement => {
  const [showState, setShowState] = useState(show);

  const PrefixIcon = iconType ? <Icon type={iconType} color={'white'} size={26} marginRight /> : null;

  useEffect(() => setShowState(show), [show]);

  return (
    <Message
      ref={forwardRef}
      className="AlertBanner"
      data-testid="alert-banner"
      show={showState}
      width={width}
      type={type}
      position={position}
      hasShadow={hasShadow}
      collapsible={collapsible}
      relative={relative}
      maxWidth={maxWidth}
      onClick={(): void => {
        if (!collapsible) return;
        const toggledShowState = !showState;
        if (autoClose) {
          setShowState(toggledShowState);
        }
        onBannerClick(toggledShowState);
      }}
    >
      <Section flex justifyContent={justifyContent} alignItems="center" height="100%" paddingRight={1} paddingLeft={1}>
        {children ? (
          children
        ) : (
          <>
            <Text
              size={14}
              color="white"
              center={true}
              uppercase={uppercase}
              bold
              intl={message}
              intlValues={messageValues}
              prefix={PrefixIcon}
            />
            <AlertBannerAction actionMessage={actionMessage} onActionClick={onActionClick} />
          </>
        )}
      </Section>
    </Message>
  );
};

AlertBanner.defaultProps = {
  show: false,
  type: Type.RED,
  position: Position.BOTTOM,
  onBannerClick: (): void => {},
  collapsible: true,
  uppercase: true,
  justifyContent: 'center',
  hasShadow: true,
};

export default AlertBanner;
