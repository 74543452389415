/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { FullTextSearchQuery, ManufacturersResponse } from 'API/types';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';
import { V } from 'API';

export const SEARCH =
  ({ variables }: V<FullTextSearchQuery>) =>
  (): Promise<ManufacturersResponse> =>
    Http()
      .get<ManufacturersResponse, FullTextSearchQuery>(ENDPOINT.MANUFACTURERS._SEARCH())({
        searchParams: variables,
      })
      .then(maybeResponseToJSON);
