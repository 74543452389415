import { styled, css } from 'styles';
import { withTheme } from '../Context';

const bgStyles = ({ background, colors }) => {
  switch (background) {
    default:
      return css`
        background-color: ${colors[background] || 'transparent'};
      `;
    case 'grey':
      return css`
        background-color: ${colors.bgLightGray};
      `;
  }
};

export const Container = withTheme(
  styled('div')(
    ({ theme: { colors, sizes, media }, paddingTop, paddingBottom, background, borderBottom }) => css`
      position: relative;
      padding: 0 ${sizes.padding / 2}px;
      ${(paddingBottom || paddingBottom === 0) && { paddingBottom: paddingBottom * sizes.padding }};
      ${(paddingTop || paddingTop === 0) && { paddingTop: paddingTop * sizes.padding }};
      ${bgStyles({ background, colors })};
      ${borderBottom && { borderBottom: `1px solid ${colors.blockBorder}` }};

      ${media.tablet`
        padding: 0 ${sizes.padding}px;
        ${(paddingBottom || paddingBottom === 0) && { paddingBottom: paddingBottom * sizes.padding }};
        ${(paddingTop || paddingTop === 0) && { paddingTop: paddingTop * sizes.padding }};
        ${bgStyles({ background, colors })};
        ${borderBottom && { borderBottom: `1px solid ${colors.blockBorder}` }};
      `};
    `
  )
);

export const Row = withTheme(
  styled('div')(
    ({ theme: { sizes, media }, paddingTop, paddingBottom }) => css`
      display: block;
      margin: 0;
      ${(paddingBottom || paddingBottom === 0) && { paddingBottom: paddingBottom * sizes.padding }};
      ${(paddingTop || paddingTop === 0) && { paddingTop: paddingTop * sizes.padding }};

      ${media.tablet`
        margin: 0 ${-sizes.padding / 2}px;
      `};

      &:after {
        content: '';
        display: block;
        width: 100%;
        height: 0;
        clear: both;
      }
    `
  )
);

export const Column = withTheme(
  styled('div')(
    ({ theme: { sizes, media }, width }) => css`
      display: block;
      float: left;
      width: 100%;
      padding: 0 0 ${sizes.padding}px;

      ${media.tablet`
        padding: 0 ${sizes.padding / 2}px ${sizes.padding}px;
        ${width && { width: width }};
      `};
    `
  )
);

export const FlexRow = withTheme(
  styled('div')(
    ({ theme: { media, sizes }, paddingTop, paddingBottom, hideOnMobile, center }) => css`
      display: flex;
      flex-direction: column;
      ${center
        ? `
        justify-content: center;
      `
        : `
        justify-content: space-between;
      `};
      ${(paddingBottom || paddingBottom === 0) && { paddingBottom: paddingBottom * sizes.padding }};
      ${(paddingTop || paddingTop === 0) && { paddingTop: paddingTop * sizes.padding }};

      ${hideOnMobile &&
      media.mobileOrSmaller`
        display: none;
      `};

      ${media.tablet`
        flex-direction: row;
      `};

      > .Card {
        flex: 1;
      }
    `
  )
);

export const FlexColumn = withTheme(
  styled('div')(
    ({ theme: { media, sizes }, flex, width }) => css`
      flex: ${flex || 1};
      margin-bottom: ${sizes.padding}px;

      ${media.tablet`
        margin-right: ${sizes.padding}px;

        &:last-child {
          margin-right: 0;
        }
      `};

      ${width && { width: width }};
    `
  )
);
