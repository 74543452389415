import { styled, css } from 'styles';
import { Box } from 'components/core';
import { treatwellProTheme } from '@uala/ui-treatwell-pro';

export const CounterWrapper = styled(Box)<{ overMaxDischargeableValue: boolean }>(
  ({ overMaxDischargeableValue, theme: { colors } }) => css`
    button.Button {
      padding: 0px !important;
      height: 32px !important;
      width: 32px !important;
      border-radius: 8px;
      background: ${colors['navy.100']};
      border: none;
      > span > svg > path {
        fill: ${colors['navy.800']};
      }
    }
    input {
      color: ${overMaxDischargeableValue ? '#ff0000' : '#000'};
      text-align: center;
      font-size: 24px;
      -webkit-appearance: none;
      outline: none;
      width: 80px !important;
      border: none !important;
      font-weight: 700;
      font-family: ${treatwellProTheme.typography.fontFamilies.default};
    }
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    input[type='number'] {
      -moz-appearance: textfield;
    }
  `
);
