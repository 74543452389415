import { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connectFormElement, getErrorByName } from '@uala/react-forms';

import { Icon } from 'components/core';

import { INPUT_INLINE, DefaultSettings, INPUT_SIZES } from '../../config';
import { BaseInputWrapper, FormControl, FormControlInput, Label, Helper, elementPropTypes } from '../../shared';

import {
  OptionsScrollArea,
  Options,
  StyledSelectWrapper,
  StyledSelectedValue,
  OptionsFilterWrapper,
  SelectPlaceholder,
} from './Select.theme';
import Option from './Option';
import { StyledInputTag } from '../text/InputText.theme';

export const SelectComponent = ({
  disabled,
  options,
  name,
  label,
  labelPosition,
  intl,
  emitChange,
  emitDidChange,
  values,
  errors,
  helper,
  size,
  stretched,
  helperPosition,
  tooltip,
  scrollerRef,
  filter,
  filterPlaceholder,
  ...inputProps
}) => {
  const value = values ? values[name] : null;
  const selectRef = useRef(null);
  const fieldError = getErrorByName(errors, name);
  const error = !!fieldError;
  helper = fieldError || helper || null;

  const [selectedValue, setSelectedValue] = useState(value);
  const [showOptions, setShowOptions] = useState(false);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  const selectValue = useCallback(
    ({ target: { value } }) => {
      setSelectedValue(value);

      // Programmatically hide the options
      setShowOptions(false);
      emitChange(name, value);
    },
    [setShowOptions, emitChange, setSelectedValue, name]
  );

  const displayOptions = useCallback(() => {
    const lowerCaseFilterValue = (filterValue || '').toLowerCase();
    return (
      availableOptions &&
      availableOptions
        .filter(
          (option) => !lowerCaseFilterValue || (option.label || '').toLowerCase().indexOf(lowerCaseFilterValue) >= 0
        )
        .map((option) => (
          <Option
            key={option.value}
            onClick={selectValue}
            value={option.value}
            className={selectedValue === option.value ? 'Option--selected' : undefined}
          >
            {option.label}
          </Option>
        ))
    );
  }, [availableOptions, selectValue, selectedValue, filterValue]);

  /* Click outside */
  const toggleOptions = useCallback(
    (event) => {
      /*
       * Toggle if:
       * - component is not disabled
       * - click from component while menu is closed (=> open);
       * - click from outside component while menu is opened (=> close);
       */
      if (
        !disabled &&
        ((!selectRef.current.contains(event.target) && showOptions) ||
          (selectRef.current.contains(event.target) && !showOptions))
      ) {
        setShowOptions(!showOptions);
      }
    },
    [disabled, showOptions]
  );

  const getSelectedValue = useCallback(() => {
    if (selectedValue && availableOptions && availableOptions.length > 0) {
      const { label } = availableOptions.find((option) => option.value === selectedValue) || {};

      return label;
    }

    return null;
  }, [selectedValue, availableOptions]);

  const getLongestOption = useCallback(() => {
    if (availableOptions && availableOptions.length > 0) {
      const sorted = availableOptions.sort((a, b) => a.label.length - b.label.length);
      return sorted.slice(-1)[0].label;
    }
  }, [availableOptions]);

  /* Register toggle to allow click-outside */
  useEffect(() => {
    document.addEventListener('mousedown', toggleOptions);

    return () => {
      document.removeEventListener('mousedown', toggleOptions);
    };
  }, [toggleOptions]);

  useEffect(() => {
    setSelectedValue(value);

    return () => {};
  }, [value]);

  useEffect(() => {
    setAvailableOptions(options);
  }, [options]);

  return (
    <FormControl labelPosition={labelPosition} className="Select">
      {label && (
        <Label htmlFor={name} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput labelPosition={labelPosition}>
        <BaseInputWrapper error={error} size={size}>
          <StyledSelectWrapper id={name} name={name} ref={selectRef} {...inputProps}>
            <StyledSelectedValue onClick={toggleOptions}>
              {getSelectedValue() || <span dangerouslySetInnerHTML={{ __html: '&#8203;' }} />}
              <Icon type="select" color="black" className="Ctrl__icon" size={11} />
            </StyledSelectedValue>
            {stretched && (
              <SelectPlaceholder>
                {getLongestOption()} <Icon type="select" color="black" className="Ctrl__icon" size={11} />
              </SelectPlaceholder>
            )}
            <Options className={`Options ${showOptions ? 'Options--visible' : 'Options--hidden'}`} size={size}>
              {filter && (
                <OptionsFilterWrapper>
                  <StyledInputTag
                    type="text"
                    placeholder={filterPlaceholder}
                    value={filterValue}
                    onChange={(e) => setFilterValue(e.target.value)}
                  />
                </OptionsFilterWrapper>
              )}
              <OptionsScrollArea ref={scrollerRef}>{displayOptions()}</OptionsScrollArea>
            </Options>
          </StyledSelectWrapper>
        </BaseInputWrapper>
        {helper && <Helper position={helperPosition} tooltip={tooltip} error={error} text={helper} intl={intl} />}
      </FormControlInput>
    </FormControl>
  );
};

const Select = connectFormElement(SelectComponent);

Select.displayName = 'Select';

Select.propTypes = {
  ...elementPropTypes,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(INPUT_SIZES),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ),
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  stretched: PropTypes.bool,
  placeholder: PropTypes.string,
  filter: PropTypes.bool,
  filterPlaceholder: PropTypes.string,
};

Select.defaultProps = {
  value: null,
  disabled: false,
  stretched: false,
  size: DefaultSettings.INPUT_SIZE,
  labelPosition: INPUT_INLINE,
  intl: true,
  filter: false,
  filterPlaceholder: 'Search...',
};

export default Select;
