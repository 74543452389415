import { styled, css } from 'styles';
import { withTheme } from 'components/core';

const minimizedWidthDesktop = 280;
const minimizedWidthMobile = 160;

const CallerBox = withTheme(
  styled('div')(
    ({ theme: { colors, sizes, boxShadow, utils, media }, minimized }) => css`
      position: absolute;
      z-index: 5;
      display: flex;
      justify-content: center;
      width: max-content;
      right: ${sizes.desktopHeaderHeight}px;
      top: 0;
      padding: 7px;
      height: ${sizes.desktopHeaderHeight}px;
      transition: ${utils.transition};

      /**
        * days button width is equal to sizes.desktopHeaderHeight and they ALWAYS HAVE TO BE VISIBLE
        */
      ${media.desktop`
        right: unset; 
        left: ${sizes.desktopHeaderHeight * 3}px;
      `}

      ${media.largeDesktop`
        left: ${sizes.desktopHeaderHeight * 6}px;
      `}

      @media screen and (max-width: 1280px) {
        right: 0px;
        left: unset;
      }

      ${minimized &&
      media.tablet`
        left: unset;
        right: ${sizes.desktopHeaderHeight}px;
      `}

      .CallerBox__inner {
        max-width: 100%;
        min-width: ${minimizedWidthDesktop - 14}px;

        ${media.mobileOrSmaller`
          min-width: ${minimizedWidthMobile - 14}px;
        `};

        @media screen and (max-width: 1280px) {
          width: 100%;
        }

        height: 100%;
        border-radius: ${sizes.borderRadiusMedium}px;
        border: 1px solid ${colors.green};
        background: ${colors.bgWhite};
        box-shadow: ${boxShadow.block};
      }

      .CallerBox_phone_icon {
        display: block;
        margin-left: 10px;
        flex: none;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        background: ${colors.green};
        text-align: center;
        line-height: 45px;

        ${media.mobileOrSmaller`
          width: 30px;
          height: 30px;
          line-height: 30px;
        `};
      }

      .TextStaffMember {
        display: inline-block;
        margin: 0 2px;
        padding: 0 4px;
        background: #d1e4fe;
        border-radius: 8px;
      }

      .CallerBox__info {
        ${minimized &&
        `
          flex: 1;
          min-width: 0;
        `};
        ${media.mobileOrSmaller`
          flex: 1;
          min-width: 0;
        `};

        margin-left: 10px;
        line-height: 18px;
      }

      .CallerBox__group {
        ${minimized && `display: none;`};
        margin-left: 30px;
      }

      .hide_on_tablet {
        ${media.tabletOrSmaller`
          display: none;
        `};
      }

      .hide_on_mobile {
        ${media.mobileOrSmaller`
          display: none;
        `};
      }

      .CallerBox_close {
        display: block;
        flex: none;
        width: ${sizes.desktopHeaderHeight - 16}px;
        height: ${sizes.desktopHeaderHeight - 16}px;
        line-height: ${sizes.desktopHeaderHeight - 16}px;
        text-align: center;
      }
    `
  )
);

export default CallerBox;
