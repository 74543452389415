import { styled, css } from 'styles';
import { withTheme } from '../Context';

export const fieldSizes = {
  height: {
    default: 32,
    small: 28,
    large: 42,
    mini: 22,
  },
  borderSize: {
    default: 1,
    small: 1,
    large: 1,
  },
  innerHeight: {
    default() {
      return fieldSizes.height.default - 2 * fieldSizes.borderSize.default;
    },
    small() {
      return fieldSizes.height.small - 2 * fieldSizes.borderSize.small;
    },
    large() {
      return fieldSizes.height.large - 2 * fieldSizes.borderSize.large;
    },
  },
  font: {
    default: `font-size: 1.6rem;`,
    small: `font-size: 1.2rem;`,
    large: `font-size: 1.6rem;`,
  },
};

const sizeStyles = ({ size, colors }) => {
  switch (size) {
    case 'small':
      return css`
        height: ${fieldSizes.height.small}px;
        border-radius: ${fieldSizes.height.small / 2}px;
        border-width: ${fieldSizes.borderSize.small}px;
      `;
    case 'large':
      return css`
        height: ${fieldSizes.height.large}px;
        border-radius: ${fieldSizes.height.large / 2}px;
        border-width: ${fieldSizes.borderSize.large}px;
      `;
    default:
      return css`
        height: ${fieldSizes.height.default}px;
        border-radius: ${fieldSizes.height.default / 2}px;
        border-width: ${fieldSizes.borderSize.default}px;
      `;
  }
};

export const Field = withTheme(
  styled('div')(
    ({
      theme: { colors, utils, sizes, media },
      hideOnMobile,
      hideOnTablet,
      hideOnDesktop,
      flex,
      disabled,
      collapsed,
      size,
      width,
      minWidth,
      maxWidth,
      onClick,
      borderColor,
    }) => css`
      display: ${flex === true ? 'flex' : 'block'};
      ${hideOnMobile
        ? css`
            ${hideOnTablet
              ? media.tabletOrSmaller`
                display: none;
              `
              : media.mobileOrSmaller`
                display: none;
              `};
          `
        : hideOnDesktop
        ? css`
            ${hideOnTablet
              ? media.tablet`
                  display: none;
                `
              : media.desktop`
                  display: none;
                `};
          `
        : hideOnTablet
        ? css`
            display: none;
            ${media.mobileOrSmaller`
                  display: ${flex === true ? 'flex' : 'block'};
                `};
            ${media.desktop`
                  display: ${flex === true ? 'flex' : 'block'};
                `};
          `
        : css``};

      position: relative;
      box-sizing: border-box;
      ${width
        ? `width:${width}${!/%$/.test(width) ? 'px' : ''};`
        : flex && flex !== true
        ? { flex: flex }
        : { flex: 1 }}
      ${minWidth && `min-width:${minWidth}${!/%$/.test(minWidth) ? 'px' : ''};`}
    ${maxWidth && `max-width:${maxWidth}${!/%$/.test(maxWidth) ? 'px' : ''};`}

    border-style: solid;
      ${borderColor
        ? css`
            border-color: ${colors[borderColor] || borderColor};
          `
        : css`
            border-color: ${colors.inputBorder};
          `};

      color: ${colors.text1};
      font-family: ${utils.fontFamily};
      font-weight: 400;

      ${sizeStyles({ size, colors })};
      ${disabled &&
      css`
        background-color: ${colors.bgGray};
        border-color: ${colors.bgGray};
        opacity: 0.3;
        pointer-events: none;
      `};

      ${onClick && `cursor: pointer;`}

      margin-right: ${sizes.spacing / 2}px;
      :last-child {
        margin-right: 0;
      }

      ${collapsed &&
      css`
        margin: 0;
        padding: 0;
        border-color: transparent;
        border-radius: 0;
      `};
    `
  )
);

export default Field;
