const capitalize = (string: string): string =>
  string
    ? string
        .toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
    : '';

export default capitalize;
