import { EventType } from 'react-hook-form';
import { ExtraOptions, ProperEvent, TrackingRule } from './types';
import { debugEvent } from './utils';
import { eventsToWatch, matchingInteractionChecker, sanitizeError } from './utils';

export const startAspectTracking = <CustomEvents = EventType>(
  document: Document,
  rules: TrackingRule<CustomEvents>[],
  extraOptions: ExtraOptions = {}
) => {
  const onException = extraOptions.onException ?? ((_: Error) => {});
  const logger = extraOptions.logger ?? console;
  const customEvents = extraOptions.customEvents ?? [];

  const eventHandler = (event: Event): void => {
    const isMatchingInteraction = matchingInteractionChecker<CustomEvents>(event);
    logger.debug(`AspectTracking> Event Received ${debugEvent(event as ProperEvent)}`, event.target);
    rules.forEach((rule) => {
      try {
        rule(isMatchingInteraction);
      } catch (e) {
        if (e instanceof Error) onException(e);
        else onException(new Error(sanitizeError(e)));
      }
    });
  };

  eventsToWatch //
    .concat(customEvents)
    .map((eventType) => document.addEventListener(eventType, eventHandler));
  logger.debug('AspectTracking> Watching: ', eventsToWatch.join(', '));
};
