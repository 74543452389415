/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';
import { VenueKYCVerificationCheckResponse } from 'API/types';

export const KYC_VERIFICATION_CHECK = () => (): Promise<VenueKYCVerificationCheckResponse> =>
  Http()
    .get<VenueKYCVerificationCheckResponse, Record<string, never>>(ENDPOINT.KYC_VERIFICATION._CHECK())({})
    .then(maybeResponseToJSON);
