import PropTypes from 'prop-types';
import { styled } from 'styles';
import { withTheme } from 'components/core';

const StyledWrappingTag = withTheme(styled('p')`
  color: ${({ theme, error }) => (error ? theme.colors.red : theme.colors.blue)};
  position: relative;
  background-color: inherit;
  margin: 10px;
  font-size: 10px;
`);

const propTypes = {
  error: PropTypes.bool,
  classNames: PropTypes.string,
  children: PropTypes.node,
};

const HelperText = ({ error, children, classNames, dataTestId }) => (
  <StyledWrappingTag error={error} data-testid={dataTestId} {...classNames}>
    {children}
  </StyledWrappingTag>
);

HelperText.displayName = 'HelperText';
HelperText.props = propTypes;

export default HelperText;
