import { Section, Text, Button } from 'components/core';
import AlertModal from 'components/lib/AlertModal';

const ServerError = ({ closeModal, config: { content = '', onConfirm = null } }) => (
  <AlertModal
    title={<Text bold color="red" intl="attention" />}
    color="red"
    size="large"
    centered
    onClose={closeModal}
    onConfirm={onConfirm}
  >
    <Section paddingRight={1} paddingTop={0.25} paddingBottom={0.75} paddingLeft={1}>
      {[].concat(content).map((piece, k) => (
        <Section key={k} paddingBottom={0.5}>
          <Text as="div" bold center color="text1" size={16} whiteSpace="pre-line">
            {piece}
          </Text>
        </Section>
      ))}
      <Section flex justifyContent="center" paddingTop={1.25}>
        <Button
          onClick={closeModal}
          color="blue"
          width="50%"
          shadow="light"
          dataTestId="modal-confirm-button"
          productTourId="modal-confirm-button"
        >
          <Text bold color="white" spacing={1} uppercase intl={'confirm'} />
        </Button>
      </Section>
    </Section>
  </AlertModal>
);

export default ServerError;
