import { styled, css } from 'styles';
import Field, { fieldSizes } from 'components/core/Field/theme';
import { COLORS } from 'styles';

const sizeStyles = {
  default: css`
    padding: 5px 15px;

    > select {
      height: ${fieldSizes.innerHeight.default() - 10}px;
      line-height: ${fieldSizes.innerHeight.default() - 10}px;
      ${fieldSizes.font.default};
    }
  `,
  small: css`
    padding: 5px 15px;

    > select {
      height: ${fieldSizes.innerHeight.small() - 10}px;
      line-height: ${fieldSizes.innerHeight.small() - 10}px;
      ${fieldSizes.font.small};
    }
  `,
  large: css`
    padding: 10px 20px;

    > select {
      height: ${fieldSizes.innerHeight.large() - 20}px;
      line-height: ${fieldSizes.innerHeight.large() - 20}px;
      ${fieldSizes.font.large};
    }
  `,
};

const StyledField = styled(Field)(
  ({ disabled, type, size }) => css`
    ${(size && sizeStyles[size]) || sizeStyles.default};
    background-color: ${COLORS.white};

    > select {
      display: block;
      width: 100%;
      border: 0;
      appearance: none;
      outline: none;
      background-color: transparent;
    }
  `
);

export const FieldSelectNative = ({ children, disabled, type, size, width }) => (
  <StyledField {...{ children, disabled, type, size, width }} />
);

export default FieldSelectNative;
