import { captureException } from '@sentry/core';

const INTERCOM_LUNCHER_SELECTOR = '.uala-chat-support';

class IntercomError extends Error {
  public intercomStatus: Window['intercomSettings'] & { booted: boolean };

  public constructor(message: string, intercomStatus: Window['intercomSettings'] & { booted: boolean }) {
    super(message);
    this.intercomStatus = intercomStatus;
  }
}

const trackIntercomError = (error: IntercomError) => {
  console.error(error);
  captureException(error);
};
const buildIntercomError = (message: string) =>
  new IntercomError(message, {
    ...window.intercomSettings,
    booted: window.Intercom.booted,
  });

const trackIntercomNotPresent = () => trackIntercomError(buildIntercomError('Intercom not present!'));
const trackIntercomNotBooted = () => trackIntercomError(buildIntercomError('Intercom not booted!'));
const trackIntercomNotOpening = () => trackIntercomError(buildIntercomError('Intercom not opening!'));

const checkForIntercomOpening = (() => {
  let timerId: NodeJS.Timeout;
  return () => {
    clearInterval(timerId);
    timerId = setTimeout(() => {
      try {
        const intercomFrameElm = document.querySelector('.intercom-messenger-frame');
        if (!intercomFrameElm) return trackIntercomNotOpening();
        const boundingRect = intercomFrameElm.getBoundingClientRect();

        if (boundingRect.width === 0 || boundingRect.height === 0) return trackIntercomNotOpening();
      } catch (e) {
        captureException(e);
      }
    }, 1000);
  };
})();

window.document.addEventListener('click', (event) => {
  if (!event.target) return;
  if (!(<HTMLElement>event.target).closest(INTERCOM_LUNCHER_SELECTOR)) return;

  if (!('Intercom' in window)) return trackIntercomNotPresent();
  if (!Intercom.booted) trackIntercomNotBooted(); // NO RETURN WE T

  window.Intercom('show');

  checkForIntercomOpening();
});
console.log('@HERE>fixIntercom>Initiated!!!');
