import { FieldValues, useForm as reactUseForm, UseFormReturn } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnyObjectSchema } from 'yup';

/**
 *@description Just an import middleware for `react-hook-form` with `yup` validation
 *
 **/
export const useForm = <A extends FieldValues>(schema: AnyObjectSchema): UseFormReturn<A> =>
  reactUseForm<A>({
    resolver: yupResolver(schema),
  });
