import { styled, css } from 'styles';
import { RadioGroupProps } from './index';

export const RadioGroup = styled('div')(
  ({ flex, direction }: Pick<RadioGroupProps, 'direction' | 'flex'>) => css`
    display: inline-block;

    ${flex &&
    css`
      display: flex;
      width: 100%;

      > div:last-child {
        text-align: right;
      }
    `};

    ${direction === 'column' &&
    css`
      display: flex;
      flex-direction: ${direction};

      .Radio {
        margin: 5px 0;

        label {
          width: 100%;

          .Radio__Value {
            margin-left: auto;
          }
        }
      }
    `}
  `
);

export default RadioGroup;
