import moment from 'moment';
export const getMarketingPromotionData = (changes) => {
  /**
   * Transform attributes for backend
   * _cents
   * _attributes
   */
  const cents_suffix_list = ['redemption_amount_possible', 'selling_price'];
  const attributes_suffix_list = ['included_resources', 'excluded_resources', 'marketing_promotion_prizes'];

  return {
    ...changes,
    ...cents_suffix_list
      .filter((key) => key in changes)
      .reduce(
        (reduced, key) => ({
          ...reduced,
          [key]: undefined,
          [key + '_cents']: Math.round((changes[key] || 0) * 100),
        }),
        {}
      ),
    ...attributes_suffix_list
      .filter((key) => key in changes)
      .reduce(
        (reduced, key) => ({
          ...reduced,
          [key]: undefined,
          [key + '_attributes']: changes[key].map((resource) => {
            if (key === 'included_resources') {
              const discount_type =
                parseFloat(resource.discount_absolute_amount || 0) > 0 ||
                parseFloat(resource.discount_absolute_amount_cents || 0) > 0 ||
                parseFloat(resource.discount_percent_amount || 0) > 0
                  ? resource.discount_type
                  : undefined;

              return {
                ...resource,
                discount_type,
                /**
                 * Send discount amount only if relative discount_type is set
                 */
                discount_absolute_amount: discount_type === 'absolute' ? resource.discount_absolute_amount : undefined,
                discount_absolute_amount_cents:
                  discount_type === 'absolute' ? resource.discount_absolute_amount_cents : undefined,
                discount_percent_amount: discount_type === 'percent' ? resource.discount_percent_amount : undefined,
                valid_in_time_table: resource.valid_in_time_table
                  ? resource.valid_in_time_table
                      .map(({ ranges }) => ranges.map((range) => range.start_time + ',' + range.end_time).join(','))
                      .join('\n') + '\n'
                  : null,
                created_at: undefined,
              };
            }

            if (key === 'marketing_promotion_prizes') {
              return {
                ...resource,
                description: resource.prize_type
                  ? resource.prize_type === 'custom'
                    ? resource.description || resource.name
                    : null
                  : resource.description,
                gifting_marketing_promotion_id:
                  resource.prize_type && resource.prize_type !== 'promo'
                    ? null
                    : resource.gifting_marketing_promotion_id ||
                      (resource.gifting_marketing_promotion && resource.gifting_marketing_promotion.id),
                gifting_marketing_promotion: undefined,
                prize_type: undefined,
              };
            }

            return {
              ...resource,
              created_at: undefined,
            };
          }),
        }),
        {}
      ),
    ...(changes.valid_in_time_table
      ? {
          valid_in_time_table:
            changes.valid_in_time_table
              .map(({ ranges }) => ranges.map((range) => range.start_time + ',' + range.end_time).join(','))
              .join('\n') + '\n',
        }
      : null),
  };
};

export const addStartDateInPromotionIfMissing = (marketing_promotion) => {
  if (!marketing_promotion.redeemable_from) {
    marketing_promotion.redeemable_from = moment().format('YYYY-MM-DD');
  }
  return marketing_promotion;
};
