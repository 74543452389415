import { withTheme as withTheme_ } from 'styles';

/**
 *
 * @deprecated
 * Remove this function wherever you see it, if you really need the withTheme HOC use the one exported from the styles folder, this function is not type-safe.
 *
 * @description
 * There are components/styled-components using an old custom withTheme HOC which was exported from this file.
 * We decided to deprecated it because we migrated our theme provider to emotion.
 * In order to guarantee backward compatibility this function is **NOT TYPE-SAFE** and that's the only reason why it is a wrapper around emotion withTheme.
 */
function withTheme(Component) {
  return withTheme_(Component);
}

export { withTheme };
