import { ReactNode } from 'react';

type Key = number | string | symbol;
type PrintableCell = string | ReactNode;

export type Column<K extends Key> = {
  key: K;
  label: PrintableCell;
  isHidden?: boolean;
};

export type Cell<K extends Key> = {
  key: K;
  value: PrintableCell;
  isHidden?: boolean;
};

export type Row<K extends Key> = {
  id: number;
  cells: Readonly<Cell<K>[]>;
};

type UseTableProps<K extends Key, E extends K> = {
  columns: Readonly<Column<K>[]>;
  data: Readonly<Record<E, PrintableCell>[]>;
};

type UseTable = <K extends Key, E extends K>(
  props: UseTableProps<K, E>
) => {
  columns: Readonly<Column<K>[]>;
  rows: Readonly<Row<K>[]>;
};

/**
 * Setup tabular data and return a list of columns and rows,
 * matching data values by column keys.
 *
 * Columns order is used to sort cells, ensuring the correct pairing between cell value and column.
 * Data transformation (array flattering, additional filtering, etc.) is out of this hook responsibility
 * and should be obtained by using a dedicated helper.
 *
 * @param {Object} params
 * @param {Object} params.columns - the preformatted column list
 * @param {Object} params.data - the data entries
 *
 * @returns - An object containing columns and rows as property
 */
const useTable: UseTable = ({ columns, data }) => {
  const rows = data.map((entry, index) => {
    const cells = columns.map((column) => {
      const cell = {
        key: column.key,
        // Type assertion here is necessary, because we cannot guaratee at compile time that columns and data keys will match 1:1
        value: column.key in entry ? entry[column.key as keyof typeof entry] : null,
        isHidden: Boolean(column.isHidden),
      };

      return cell;
    });

    return {
      id: index,
      cells,
    };
  });

  return { columns, rows };
};

export { useTable };
