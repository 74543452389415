import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import HeaderActionSupport from './HeaderActionSupport';
import { getCurrentVenueCountrySegmentKey } from 'selectors/getCurrentVenue';

const mapStateToProps = () =>
  createSelector(getCurrentVenueCountrySegmentKey, (segment_key) => {
    return {
      segment_key,
    };
  });

export default connect(mapStateToProps)(HeaderActionSupport);
