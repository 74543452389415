/**
 * OneSignal doesn't support electron but there is a workaround with Firebase + Android
 * The process was implemented following this github issue:
 * https://github.com/OneSignal/OneSignal-Website-SDK/issues/98#issuecomment-644880273
 *
 * Steps:
 * 1 - get app_id(OneSignal App ID) & sender_id(Firebase sender ID) from /one_signal_players/app_info.json
 * 2 - get player_id(unique for each device) from https://onesignal.com/api/v1/players (https://documentation.onesignal.com/reference/add-a-device)
 * 3 - sign user to notification => POST - /one_signal_players.json
 */

import { NODE_ENV } from 'env';

// Event to be sent from renderer process to trigger service start
const START_NOTIFICATION_SERVICE = 'PUSH_RECEIVER:::START_NOTIFICATION_SERVICE';
// Event sent to the renderer process once the service is up
const NOTIFICATION_SERVICE_STARTED = 'PUSH_RECEIVER:::NOTIFICATION_SERVICE_STARTED';
// Event sent to the renderer process if an error has occured during the starting process
const NOTIFICATION_SERVICE_ERROR = 'PUSH_RECEIVER:::NOTIFICATION_SERVICE_ERROR';
// Event sent to the renderer processs when a notification has been received
const NOTIFICATION_RECEIVED = 'PUSH_RECEIVER:::NOTIFICATION_RECEIVED';
// Event sent to the renderer processs when the FCM token has been updated
const TOKEN_UPDATED = 'PUSH_RECEIVER:::TOKEN_UPDATED';

export const hasPushNotificationsCapabilities = () => {
  if (!window.require) {
    return false;
  }

  const electron = window.require('electron');
  if (!electron) {
    return false;
  }

  const ipcRenderer = electron.ipcRenderer;
  if (!ipcRenderer) {
    return false;
  }

  return true;
};

export const initPushNotifications = async ({ sender_id, onTokenUpdated, onClickNotification, onError }) => {
  if (!hasPushNotificationsCapabilities()) {
    return false;
  }

  if (process.env.NODE_ENV === NODE_ENV.DEV) {
    console.log('initPushNotifications Electron', { sender_id });
  }

  const ipcRenderer = window.require('electron').ipcRenderer;

  // Listen for service successfully started
  ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, (_, token) => {
    onTokenUpdated(token);
  });

  // Handle notification errors
  ipcRenderer.on(NOTIFICATION_SERVICE_ERROR, (_, error) => {
    onError(error);
  });

  // Send FCM token to backend
  ipcRenderer.on(TOKEN_UPDATED, (_, token) => {
    onTokenUpdated(token);
  });

  // Display notification
  ipcRenderer.on(NOTIFICATION_RECEIVED, (_, serverNotificationPayload) => {
    // check to see if payload contains a body string, if it doesn't consider it a silent push
    if (
      serverNotificationPayload &&
      serverNotificationPayload.notification &&
      serverNotificationPayload.notification.body
    ) {
      // payload has a body, so show it to the user
      new Notification(serverNotificationPayload.notification.title, {
        body: serverNotificationPayload.notification.body,
      });
    } else {
      // payload has no body, so consider it silent (and just consider the data portion)
      if (
        serverNotificationPayload.data &&
        serverNotificationPayload.data.title &&
        serverNotificationPayload.data.alert
      ) {
        const notification = new Notification(serverNotificationPayload.data.title, {
          body: serverNotificationPayload.data.alert,
        });

        if (serverNotificationPayload.data.custom) {
          const data = JSON.parse(serverNotificationPayload.data.custom);
          if (data && data.a) {
            notification.onclick = () => {
              onClickNotification(data.a);
            };
          }
        }
      }
    }
  });

  ipcRenderer.send(START_NOTIFICATION_SERVICE, sender_id);

  return true;
};

export const stopPushNotifications = () => {
  if (!hasPushNotificationsCapabilities()) {
    return;
  }

  const ipcRenderer = window.require('electron').ipcRenderer;

  ipcRenderer.removeAllListeners(START_NOTIFICATION_SERVICE);
  ipcRenderer.removeAllListeners(NOTIFICATION_SERVICE_STARTED);
  ipcRenderer.removeAllListeners(NOTIFICATION_SERVICE_ERROR);
  ipcRenderer.removeAllListeners(NOTIFICATION_RECEIVED);
  ipcRenderer.removeAllListeners(TOKEN_UPDATED);
};
