import { VENUE_CUSTOMER_CART_SYNC_END, VENUE_CUSTOMER_CART_SYNC_START } from 'actionTypes';
import { Action, Reducer } from 'redux';

export type CheckoutState = {
  isSyncing: boolean;
};

export const checkout: Reducer<CheckoutState, Action> = (state, action) => {
  switch (action.type) {
    case VENUE_CUSTOMER_CART_SYNC_START:
      return {
        ...state,
        isSyncing: true,
      };
    case VENUE_CUSTOMER_CART_SYNC_END:
      return {
        ...state,
        isSyncing: false,
      };
  }

  return state ?? { isSyncing: false };
};
