export interface HeadersEntries {
  [key: string]: string;
}

export interface Request {
  path: string;
  data?: Readonly<Record<string, unknown>> | FormData;
  auth: string;
}

interface RequestPool {
  [uniqueId: string]: Request | boolean;
}

interface Response {
  success?: boolean;
  error?: string;
  info?: string[];
  data?: Record<string, unknown> | [];
}

export interface ErrorResponse {
  success?: boolean;
  error?: {
    code: number;
    data?: {
      latest_fiscal_day?: {
        id: number;
      };
    };
  };
  info?: string[];
}

export type OnError = (obj?: { error: string; info: string[]; response?: object }) => void | object;

export type OnResponse = (response: Response, headers?: HeadersEntries) => void | object;

const requests_pool: RequestPool = {};

const requestsPoolContains = (uniqueId: keyof RequestPool): Request | boolean => {
  return requests_pool[uniqueId] || false;
};

const addToRequestsPool = (uniqueId: keyof Request, request: Request): void => {
  requests_pool[uniqueId] = { ...request };
};

const registerResponseInRequestsPool = (uniqueId: keyof RequestPool): void => {
  requests_pool[uniqueId] = true;
};

const removeFromRequestsPool = (uniqueId: keyof RequestPool): void => {
  if (requests_pool[uniqueId]) {
    delete requests_pool[uniqueId];
  }
};

export {
  requests_pool,
  requestsPoolContains,
  addToRequestsPool,
  registerResponseInRequestsPool,
  removeFromRequestsPool,
};
