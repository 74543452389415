import { API_BASE } from 'actions/data_providers/api';
import { getStringUrlOrigin } from './getStringUrlOrigin';

export const getApiOrigin = (): string | undefined => {
  try {
    return getStringUrlOrigin(API_BASE());
  } catch (e) {
    return undefined;
  }
};
