import ENV from 'env';
import { Box, Button, Text } from 'components/core';
import { MobilePasswordsManagerProps, PasswordManagerCredential } from './types';
import { CredentialsAppImage } from './MobilePasswordManager.theme';

type MobilePasswordsManagerCredentialsProps = Pick<MobilePasswordsManagerProps, 'credentials' | 'onClickCredential'> & {
  onManagePasswordsClick: (credential: PasswordManagerCredential) => void;
};

const obfuscatePassword = (pass: string): string =>
  pass
    .split('')
    .map(() => '·')
    .join('');

export const MobilePasswordsManagerCredentials = ({
  credentials,
  onClickCredential,
  onManagePasswordsClick,
}: MobilePasswordsManagerCredentialsProps): JSX.Element => {
  const appLogo = `/${ENV.UALA_FAVICON_PATH}/favicon.ico`;

  const handleClickCredential = (credential: PasswordManagerCredential): void => {
    onClickCredential(credential);
  };

  return (
    <>
      <Box display="flex" width="100%" alignItems="center" gap="8px" key={credentials.email}>
        <CredentialsAppImage src={appLogo} alt="credentials_app_image" />
        <Box display="flex" flexDirection="column" gap="1px">
          <Text size={16} data-testid="mobile_password_manager_email_text">
            {credentials.email}
          </Text>
          <Text size={16} data-testid="mobile_password_manager_password_text">
            {obfuscatePassword(credentials.password)}
          </Text>
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" width="100%" gap="13px">
        <Button
          primary
          onClick={(): void => handleClickCredential(credentials)}
          block
          dataTestId="mobile_password_manager_credentials_button"
        >
          <Text uppercase color="white" bold block intl="signin.login" />
        </Button>
        <Button
          unstyled
          onClick={onManagePasswordsClick}
          block
          dataTestId="mobile_password_manager_manage_passwords_button"
        >
          <Text uppercase color="blue" bold block intl="signin.mobile_password_manager.manage_passwords" />
        </Button>
      </Box>
    </>
  );
};
