import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { RemoteModule, RemoteNamespace } from 'host/types';
import { HostStore } from 'host/store';
import { getInitializedRemoteModules, initRemoteModulesByNamespace } from '../registry';
import { ModuleFederationContext } from 'host/RegistryProvider/RegistryProvider';
import RemoteModuleRenderer from 'host/RemoteModuleRenderer';
import './RemoteRenderer.css';

//
// React Component

type Props = {
  scope?: string;
  namespace: RemoteNamespace;
  payload?: unknown;
  wrapperComponent?: FunctionComponent<{ children: any }>;
};

export const RemoteRenderer: FunctionComponent<Props> = ({ scope, namespace, payload, wrapperComponent: Wrapper }) => {
  const [availableModules, store] = useInitRemoteModules({ scope, namespace });
  if (!store) return null;

  return (
    <>
      {availableModules.map((module, k) => {
        if (Wrapper) {
          return (
            <Wrapper key={k}>
              <RemoteModuleRenderer module={module} store={store} payload={payload} />
            </Wrapper>
          );
        } else {
          return <RemoteModuleRenderer key={k} module={module} store={store} payload={payload} />;
        }
      })}
    </>
  );
};

//
// Hooks

type InitRemoteModules = (args: {
  namespace: RemoteNamespace;
  scope?: string;
}) => [RemoteModule[], HostStore | undefined];

const useInitRemoteModules: InitRemoteModules = ({ scope, namespace }) => {
  const { remotes, store } = useContext(ModuleFederationContext);
  // For all the matching remote, load and initialise all modules
  const [availableModules, setAvailableModules] = useState<RemoteModule[]>([]);

  useEffect(() => {
    if (remotes) {
      initRemoteModulesByNamespace({ remotes, scope, namespace }, () => {
        const matchingModules = getInitializedRemoteModules({ scope, namespace });
        setAvailableModules(matchingModules);
      });
    }
  }, [remotes, scope, namespace]);

  return [availableModules, store];
};
