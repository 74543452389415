import {
  API_GET_RESPONSE,
  API_POST_RESPONSE,
  CHANGE_LOCALE,
  VENUE_STAFF_MEMBER_TREATMENTS_BULK_DELETE,
  VENUE_STAFF_MEMBER_TREATMENTS_CREATE_RESPONSE,
  VENUE_STAFF_MEMBER_TREATMENTS_DELETE_RESPONSE,
  VENUE_STAFF_MEMBER_TREATMENTS_LIST_REQUEST,
  VENUE_STAFF_MEMBER_TREATMENTS_LIST_RESPONSE,
  VENUE_STAFF_MEMBER_TREATMENTS_UPDATE_RESPONSE,
  VENUE_TREATMENTS_CREATE_RESPONSE,
  VENUE_TREATMENTS_DELETE_RESPONSE,
  VENUE_TREATMENTS_LIST_REQUEST,
  VENUE_TREATMENTS_LIST_RESPONSE,
  VENUE_TREATMENTS_UPDATE,
} from 'actionTypes';
import { reducerValuesOnInit, reducerValuesOnRequest, reducerValuesOnResponse } from 'utils/reducersUtils';
import { filterStaffMemberTreatments } from 'utils/treatments/filterStaffMemberTreatments';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const venueTreatments = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...reducerValuesOnInit(),
      };
    case VENUE_TREATMENTS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case VENUE_TREATMENTS_LIST_RESPONSE:
      if (!action.venue_treatments) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.venue_treatments).map((item) => ({ id: item.id }))),
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (!action?.response?.data?.venue_treatments?.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(state?.items?.concat(action.response.data.venue_treatments) ?? [], {
          avoidDuplicateIds: true,
          onlyIds: true,
        }),
      };

    case VENUE_TREATMENTS_UPDATE:
      if (!action?.venue_treatments?.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(state?.items?.concat(action?.venue_treatments) ?? [], {
          avoidDuplicateIds: true,
          onlyIds: true,
        }),
      };

    case VENUE_TREATMENTS_CREATE_RESPONSE:
      if (!action.venue_treatment) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.venue_treatment || [])
            .map((item) => ({ id: item.id }))
        ),
      };

    case VENUE_TREATMENTS_DELETE_RESPONSE:
      if (!action.venue_treatment) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .filter(({ id }) => id !== action.venue_treatment.id)
            .map((item) => ({ id: item.id }))
        ),
      };

    default:
      return state;
  }
};

export const venueTreatmentsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case CHANGE_LOCALE:
    case VENUE_TREATMENTS_LIST_REQUEST:
    case VENUE_TREATMENTS_LIST_RESPONSE:
    case VENUE_TREATMENTS_CREATE_RESPONSE:
    case VENUE_TREATMENTS_DELETE_RESPONSE:
    case VENUE_TREATMENTS_UPDATE:
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = venueTreatments(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

/**
 * Staff Member Treatments
 * A staff_member can be associated with one or more venue_treatment in order to create a staff_member_treatment.
 * The association is meant to represent the fact that the staff member can offer those treatments.
 * related to:
 *    actions/treatments.js
 *    fetchStaffMemberTreatments
 *    fetchStaffMemberTreatmentsIfNeeded
 */

const staffMemberTreatments = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_STAFF_MEMBER_TREATMENTS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_STAFF_MEMBER_TREATMENTS_LIST_RESPONSE:
      if (!action.staff_member_treatments) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          [].concat(action.staff_member_treatments).map((item) => ({ id: item.id })),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    case VENUE_STAFF_MEMBER_TREATMENTS_CREATE_RESPONSE:
    case VENUE_STAFF_MEMBER_TREATMENTS_UPDATE_RESPONSE:
      if (!action.staff_member_treatment) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.staff_member_treatment || [])
            .map((item) => ({ id: item.id })),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    case VENUE_STAFF_MEMBER_TREATMENTS_DELETE_RESPONSE:
      if (!action.staff_member_treatment) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .filter(({ id }) => id !== action.staff_member_treatment.id)
            .map((item) => ({ id: item.id })),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    case VENUE_STAFF_MEMBER_TREATMENTS_BULK_DELETE:
      if (!action.staff_member_treatments) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(filterStaffMemberTreatments(state.items, action.staff_member_treatments), {
          avoidDuplicateIds: 1,
        }),
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        (!action.response.data.staff_member_treatment &&
          (!action.response.data.staff_member_treatments || !action.response.data.staff_member_treatments.length))
      ) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.response.data.staff_member_treatment || [])
            .concat(action.response.data.staff_member_treatments || []),
          {
            onlyIds: 1,
            avoidDuplicateIds: 1,
          }
        ),
      };

    default:
      return state;
  }
};

export const staffMemberTreatmentsByVenue = (state = {}, action) => {
  let refState;
  switch (action.type) {
    case VENUE_STAFF_MEMBER_TREATMENTS_LIST_REQUEST:
    case VENUE_STAFF_MEMBER_TREATMENTS_LIST_RESPONSE:
    case VENUE_STAFF_MEMBER_TREATMENTS_CREATE_RESPONSE:
    case VENUE_STAFF_MEMBER_TREATMENTS_UPDATE_RESPONSE:
    case VENUE_STAFF_MEMBER_TREATMENTS_DELETE_RESPONSE:
    case VENUE_STAFF_MEMBER_TREATMENTS_BULK_DELETE:
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = staffMemberTreatments(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
