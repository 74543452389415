/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { FullTextSearchQuery, SearchProductsResponse } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { successfulResponseToJSON } from 'API/http.utils';

export const SEARCH =
  ({ variables }: V<FullTextSearchQuery & { next: boolean }>) =>
  (): Promise<SearchProductsResponse> =>
    Http()
      .get<SearchProductsResponse, FullTextSearchQuery & { next: boolean }>(ENDPOINT.PRODUCTS._SEARCH())({
        searchParams: variables,
      })
      .then(successfulResponseToJSON);
