import { createSelector } from 'reselect';

import { VenueTreatmentGroup } from 'models';
import { ItemsByVenueSelector, State } from 'reducers/types';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import filterDeletedItems from 'utils/filterDeletedItems';

const getVTGByVenue: ItemsByVenueSelector<VenueTreatmentGroup> = (state) => state.venueTreatmentGroupsByVenue;

const getVTGIsFetching = createSelector(
  [getCurrentVenueId, getVTGByVenue],
  (venue_id, venueTreatmentGroups) =>
    Boolean(venue_id && venueTreatmentGroups[venue_id] && venueTreatmentGroups[venue_id].isFetching) || false
);

const EMPTY_LIST: VenueTreatmentGroup[] = [];

const getVTGList = createSelector([getCurrentVenueId, getVTGByVenue], (venue_id, vtgByVenue) => {
  const vtgList = (venue_id && vtgByVenue[venue_id] && vtgByVenue[venue_id].items) || EMPTY_LIST;

  if (vtgList === EMPTY_LIST) {
    return EMPTY_LIST;
  }

  return vtgList.filter(filterDeletedItems);
});

const getFavouriteVTGCount = createSelector([getVTGList], (venueTreatmentList) => {
  const favourites = venueTreatmentList.filter((treatment) => treatment.favorite === true);

  return favourites.length;
});

const getVGTById = createSelector([getVTGList, (_state: State, vtgId: number): number => vtgId], (vtgList, vtgId) =>
  vtgList.find((vtg) => vtg.id === vtgId)
);

export { getVTGByVenue, getVTGIsFetching, getVTGList, getFavouriteVTGCount, getVGTById };
