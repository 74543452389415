import { FunctionComponent } from 'react';
import { Section, Text } from 'components/core';
import { getLastFourDigits } from '../PaymentMethods.utils';
import { PaymentBrand, BRAND_NAMES } from 'models';

type CreditCardLabelProps = {
  cardBrand: PaymentBrand;
  cardNumber: string;
};

const CreditCardLabel: FunctionComponent<CreditCardLabelProps> = ({ cardBrand, cardNumber }) => (
  <Section>
    <Text size={16} bold>
      {BRAND_NAMES[cardBrand]}{' '}
    </Text>
    <Text size={16} intl="stripe.last_credit_card_digits" />
    <Text size={16} bold>
      {' '}
      {getLastFourDigits(cardNumber)}
    </Text>
  </Section>
);

export default CreditCardLabel;
