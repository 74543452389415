import { css, styled } from 'styles';

export const SortableItemWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    position: absolute;
    height: 100%;
    justify-content: center;
    max-height: 100%;

    & > span {
      padding: 0.5rem;
    }

    &.ArrowsOnLeft {
      left: -1.75rem;
    }
    &.ArrowsOnRight {
      left: calc(100% + 0.75rem);
    }
  `
);
