import { Children, ComponentType, FunctionComponent, PropsWithChildren } from 'react';

export const customizeComponent = <T extends PropsWithChildren<object>, B extends Omit<T, 'children'>>(
  FC: ComponentType<T>,
  burnProps: B
): FunctionComponent<T> => {
  return ({ ...props }) =>
    Children.count(props.children) > 0 ? (
      <FC {...props} {...burnProps}>
        {props.children}
      </FC>
    ) : (
      <FC {...props} {...burnProps} />
    );
};
