import { FunctionComponent, ReactElement, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getCurrentUser } from 'selectors/getCurrentUser';
import { useSelector } from 'react-redux';
import Hotjar from '@hotjar/browser';
import ENV from 'env';
import { getCurrentVenueCountryCode } from 'selectors/getCurrentVenue';

const getHotjarIdByEnv = (venueCountryCode: string) => ENV[`UALA_HOTJAR_ID_${venueCountryCode?.toUpperCase()}`] || null;

const HotjarProvider: FunctionComponent<RouteComponentProps> = ({ children, location }) => {
  const user = useSelector(getCurrentUser);
  const countryCode = useSelector(getCurrentVenueCountryCode);

  useEffect(() => {
    if (!user || !countryCode || !getHotjarIdByEnv(countryCode.toString())) return;
    Hotjar.init(Number(getHotjarIdByEnv(countryCode.toString())), 6, {
      debug: true,
    });
    Hotjar.identify(String(user.id), {
      email: user.email,
    });
  }, []);

  useEffect(() => {
    if (!Hotjar.isReady()) return;
    Hotjar.stateChange(location.pathname);
  }, [location.pathname]);

  return children as ReactElement;
};

export default withRouter<RouteComponentProps, any>(HotjarProvider);
