import { useSelector } from 'react-redux';
import { State } from 'reducers/types';
import { getHelpdeskUrl } from 'utils/urls';

export const KYC_VERIFICATION_REQUESTED = 'kyc_verification_requested';
export const KYC_VERIFICATION_REFRESH_URL_PATHNAME = `/stripe_kyc_onboarding`;
export const KYC_VERIFICATION_REFRESH_URL = `${window.location.origin}${KYC_VERIFICATION_REFRESH_URL_PATHNAME}`;
export const KYC_VERIFICATION_RETURN_URL = `${window.location.origin}?${KYC_VERIFICATION_REQUESTED}`;

const getKycFindOutMoreUrl = (): string => `${getHelpdeskUrl()}/articles/8548017-account-verification-on-stripe`;

export const useGetKycUrls = (): {
  refreshUrl: string;
  returnUrl: string;
  findOutMoreUrl: string;
} => {
  // TODO: there is a circular dependency in actions and is not possible to import the getCurrentUserToken selector without having issues in cypress. We need to figure out how to fix this.
  const token = useSelector((state: State) => state?.sessions?.currentUser?.auth_token);

  return {
    refreshUrl: `${KYC_VERIFICATION_REFRESH_URL}?auth_token=${token}`,
    returnUrl: `${KYC_VERIFICATION_RETURN_URL}`,
    findOutMoreUrl: getKycFindOutMoreUrl(),
  };
};
