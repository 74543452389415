import { styled, css } from 'styles';
import { Card, withTheme } from 'components/core';
import { Theme } from 'styles';

export const Loading = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
`;

export const CardContainer = styled(Card)`
  padding: 20px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
`;

export const CheckboxWrapper = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: auto;
  width: auto;
`;

export const StripeCardElement = withTheme(
  styled('div')(
    ({ theme: { colors } }: { theme: Theme }) => css`
      margin: 5px 0 10px 0;

      > div {
        padding: 10px;
        background: ${colors.white};
        border-radius: 4px;
        box-shadow:
          inset 0 1px 3px rgba(0, 0, 0, 0.1),
          0 2px 4px rgba(0, 0, 0, 0.1);
      }
    `
  )
);

export const StipeError = styled('div')`
  margin-bottom: -20px;
  padding-top: 20px;
`;
