import { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connectFormElement, getErrorByName } from '@uala/react-forms';

import { DefaultSettings, INPUT_INLINE, INPUT_SIZE_MEDIUM } from '../../config';
import { StyledTextArea } from './TextArea.theme';
import { BaseInputWrapper, FormControl, FormControlInput, Label, Helper, elementPropTypes } from '../../shared';

const TextArea = ({
  name,
  label,
  size,
  labelPosition,
  intl,
  borderStyle,
  emitChange,
  emitDidChange,
  values,
  errors,
  helper,
  helperPosition,
  tooltip,
  background,
  rows = DefaultSettings.TEXTAREA_ROWS,
  ...inputProps
}) => {
  const fieldError = getErrorByName(errors, name);
  helper = fieldError || helper || null;
  const error = !!fieldError;

  const inputRef = useRef();
  const height = 'auto';

  /*
   * Resize the textarea accordingly to its content.
   */
  const handleResize = useCallback(() => {
    const { current: textAreaDOM } = inputRef;

    textAreaDOM.style.height = height;
    textAreaDOM.style.height = textAreaDOM.scrollHeight + 'px';
  }, [inputRef]);

  const value = values[name] || '';

  useEffect(() => {
    handleResize();
    return () => {};
  }, [handleResize, value]);

  return (
    <FormControl labelPosition={labelPosition} className="TextArea" align="flex-start">
      {label && (
        <Label htmlFor={name} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput labelPosition={labelPosition}>
        <BaseInputWrapper error={error} borderStyle={borderStyle} size={size} background={background}>
          <StyledTextArea
            {...inputProps}
            name={name}
            value={value}
            onChange={(e) => {
              handleResize();
              emitChange(name, e.target.value);
            }}
            onBlur={(e) => {
              emitDidChange(name, e.target.value);
            }}
            rows={rows}
            ref={inputRef}
          />
        </BaseInputWrapper>
        {helper && <Helper position={helperPosition} tooltip={tooltip} error={error} text={helper} intl={intl} />}
      </FormControlInput>
    </FormControl>
  );
};

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
  ...elementPropTypes,
  value: PropTypes.string,
  rows: PropTypes.number,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  borderStyle: PropTypes.string,
  background: PropTypes.string,
};

TextArea.defaultProps = {
  value: '',
  labelPosition: INPUT_INLINE,
  intl: true,
  size: INPUT_SIZE_MEDIUM,
  borderStyle: 'rounded',
};

export default connectFormElement(TextArea);
