/**
 * This component provides access to unauthenticated users to specific sections of the manager.
 * For example it can be used to give access to the Reset Password form, or Payment Method Management
 * authenticated through a temporary token
 */
import { FunctionComponent } from 'react';
import Loadable from '@loadable/component';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'store';
import { PaymentMethodsTemplate } from 'components/payment_methods_next';
import LoadingCodeChunkFallback from 'components/lib/LoadingCodeChunkFallback';

const FacebookAuthComponent = Loadable(() => import('components/facebook_auth_next'), {
  fallback: <LoadingCodeChunkFallback />,
});

const PrivacyFormComponent = Loadable(() => import('components/privacy_form_next'), {
  fallback: <LoadingCodeChunkFallback />,
});

const InformedConsentFormComponent = Loadable(() => import('components/informed_consent_form_next'), {
  fallback: <LoadingCodeChunkFallback />,
});

const AppointmentReviewsComponent = Loadable(() => import('components/appointment_reviews_next'), {
  fallback: <LoadingCodeChunkFallback />,
});

const CancellationProtectionSMSComponent = Loadable(() => import('components/cancellation_protection_sms'), {
  fallback: <LoadingCodeChunkFallback />,
});

const Views = {
  BILLING_COMPANY_SETUP: '/billing_company_setup',
  FB_AUTH: '/fb_auth',
  PRIVACY_FORM: '/privacy/:token',
  INFORMED_CONSENT_FORM: '/informed_consent/:token',
  APPOINTMENT_REVIEWS: '/appointment_reviews/:step?',
  CANCELLATION_PROTECTION_SMS: '/cancellation_protection_sms/:step?',
} as const;

const GuestAccessProvider: FunctionComponent = ({ children }) => {
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={Views.BILLING_COMPANY_SETUP} component={PaymentMethodsTemplate} />
        <Route path={Views.FB_AUTH} component={FacebookAuthComponent} />
        <Route path={Views.PRIVACY_FORM} component={PrivacyFormComponent} />
        <Route path={Views.INFORMED_CONSENT_FORM} component={InformedConsentFormComponent} />
        <Route path={Views.APPOINTMENT_REVIEWS} component={AppointmentReviewsComponent} />
        <Route path={Views.CANCELLATION_PROTECTION_SMS} component={CancellationProtectionSMSComponent} />
        <Route path="*">{children}</Route>
      </Switch>
    </ConnectedRouter>
  );
};

export default GuestAccessProvider;
