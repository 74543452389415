// eslint-disable-next-line no-restricted-imports
import { IToggle } from '@unleash/proxy-client-react';

// eslint-disable-next-line no-restricted-imports
export * from '@unleash/proxy-client-react';
export const UNLEASH_LOCAL_STORAGE_KEY = 'unleash:repository:repo';

/**
 * @description
 * return the status of a certain feature flag from unleash proxy
 *
 */
export function isFeatureFlagEnabled(flagName: string): boolean {
  try {
    const isCypressRunning = Boolean(window.Cypress); // Boolean required as window.Cypress is a optional namespace, and typescript complains is not a boolean
    const isActive = Boolean(
      (JSON.parse(localStorage.getItem(UNLEASH_LOCAL_STORAGE_KEY) as string) as ReadonlyArray<IToggle>)?.filter(
        (x) => x.name === flagName
      )?.[0]?.enabled
    );
    return isCypressRunning || isActive;
  } catch (error) {
    return false;
  }
}

/**
 * @description
 * return a certain feature flags from unleash proxy
 *
 */
export function getFeatureFlag(flagName: string): IToggle | undefined {
  try {
    return (JSON.parse(localStorage.getItem(UNLEASH_LOCAL_STORAGE_KEY) as string) as ReadonlyArray<IToggle>)?.filter(
      (x) => x.name === flagName
    )?.[0];
  } catch (error) {
    return undefined;
  }
}

/**
 * @description
 * return all feature flags from unleash proxy
 *
 */
export function getFeatureFlags(): ReadonlyArray<IToggle> {
  try {
    return JSON.parse(localStorage.getItem(UNLEASH_LOCAL_STORAGE_KEY) as string);
  } catch (error) {
    return [];
  }
}
