import { FunctionComponent, createContext, useCallback, useEffect, useState } from 'react';
import ENV from 'env';

import { createStore, HostStore } from 'host/store';
import { Remote } from 'host/types';

type Context = {
  store?: HostStore;
  remotes?: Remote[];
  addRemotes?: (remotes: Remote[]) => void;
};

export const ModuleFederationContext = createContext<Context>({});

const defaultState: Context = {};

// any better idea to override is welcome
// localStorage.setItem('REMOTE_REGISTRY_URL', '/registry.json');

function getRegistryURL(registryURL?: string): string | undefined {
  if (!ENV.UALA_ALLOW_PARAMS_CUSTOMIZATION) {
    return registryURL;
  }
  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const URLParamsRegistryURL = urlParams.get('registryURL');
    if (URLParamsRegistryURL) {
      window.localStorage.setItem('REMOTE_REGISTRY_URL', URLParamsRegistryURL);
      return URLParamsRegistryURL;
    }
  } catch (error) {
    console.error('Error getting a registered Remote URL, original error:', error);
    return registryURL;
  }

  return window.localStorage.getItem('REMOTE_REGISTRY_URL') || registryURL;
}

export const RegistryProvider: FunctionComponent<{ registryURL?: string }> = ({ registryURL, children }) => {
  const [contextValue, setContextValue] = useState(defaultState);
  const addRemotes = useCallback((remotes: Context['remotes']): void => {
    setContextValue((value) => {
      const existingRemotes = value.remotes || [];
      const newRemotes = (remotes || []).filter((nr) => !existingRemotes.some((er) => er.scope === nr.scope));
      return {
        ...value,
        remotes: [...existingRemotes, ...newRemotes],
      };
    });
  }, []);
  useEffect(() => {
    const store = createStore();
    const t = new Date().getTime();
    const anyValidRegistryURL = getRegistryURL(registryURL);
    if (anyValidRegistryURL) {
      fetch(anyValidRegistryURL + '?t=' + t)
        .then((response) => response.json())
        .then((remotes) => {
          setContextValue({ remotes, store, addRemotes });
        })
        .catch((error) => {
          console.log({ error });
          setContextValue({ remotes: [], store, addRemotes });
        });
    } else {
      setContextValue({ remotes: [], store, addRemotes });
    }
  }, []);

  return <ModuleFederationContext.Provider value={contextValue}>{children}</ModuleFederationContext.Provider>;
};
