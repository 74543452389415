import { Action } from 'redux';
import { AppointmentsResponse } from 'API/types';

export const FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_RESPONSE =
  'FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_RESPONSE';
export const FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_REQUEST =
  'FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_REQUEST';

export interface FetchPendingProtectionAppointmentsForCurrentWeekResponseAction
  extends Action<typeof FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_RESPONSE> {
  success: boolean;
  appointments: AppointmentsResponse['data']['appointments'];
  venueId: number | null;
  error?: string;
}
export type FetchPendingProtectionAppointmentsForCurrentWeekResponseActionCreator = (
  arg: Omit<FetchPendingProtectionAppointmentsForCurrentWeekResponseAction, 'type'>
) => FetchPendingProtectionAppointmentsForCurrentWeekResponseAction;

export type FetchPendingProtectionAppointmentsForCurrentWeekRequestAction = Action<
  typeof FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_REQUEST
> & { venueId: number | null };

export type FetchPendingProtectionAppointmentsForCurrentWeekRequestActionCreator = (
  arg: Omit<FetchPendingProtectionAppointmentsForCurrentWeekRequestAction, 'type'>
) => FetchPendingProtectionAppointmentsForCurrentWeekRequestAction;
