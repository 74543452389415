import { ReactElement } from 'react';
import AlertBanner, { Type } from 'components/lib/AlertBanner';
import { styled } from 'styles';

const BannerWrapper = styled('div')`
  .AlertBanner {
    position: fixed;
    bottom: 0;
    left: 15px;
    right: 15px;
    z-index: 10000;
  }
`;

interface Props {
  config: {
    contentValues: object;
  };
}

const DisconnectionBanner = ({ config }: Props): ReactElement => (
  <BannerWrapper>
    <AlertBanner
      show
      type={Type.ORANGE}
      message="offline.alert"
      messageValues={config.contentValues}
      collapsible={false}
      uppercase={false}
      justifyContent={'space-between'}
      iconType={'no-signal'}
      actionMessage="retry.now"
      onActionClick={(): void => window.location.reload()}
    />
  </BannerWrapper>
);

export default DisconnectionBanner;
