import { Action, Store } from 'redux';
import { getAgendaAllCollaboratorsWorkCollaboratorsViewRules } from './AgendaAllCollaboratorsSingleCollaboratorView';
import { calculateDatePickerVariant } from './agendaCustomViewRules';
import { getAgendaDailyWeeklyViewRules } from './agendaNextDailyWeeklyViewRules';
import { AgendaPageRulesMemory, createAgendaPageRulesMemory } from './agendaPageRulesMemory';
import { getAgendaSubPagesRules } from './agendaSubPagesRules';
import { PageRulePlugin } from 'utils/tracking/trackingObservations/pageRules';
import { State } from 'reducers/types';

type Context = {
  store: Store<State, Action>;
  agendaPageRulesMemory: AgendaPageRulesMemory;
};

const NONE = undefined;
export const getCustomAgendaPageTrackingPageRulePlugins = ({
  agendaPageRulesMemory,
  store,
}: Context): PageRulePlugin[] => {
  const plugins: PageRulePlugin[] = [
    (url) => {
      calculateDatePickerVariant({ agendaPageRulesMemory, store });
      const mem = agendaPageRulesMemory.get();
      return url.includes('/agenda')
        ? {
            agenda_custom_datepicker_view: mem.datepicker,
            agenda_view_mode: mem.dailyWeeklyView,
            selection: mem.selection,
            agenda_view_collaborators: mem.allOnlyWorkView,
          }
        : NONE;
    },
  ];
  return plugins;
};

export const getCustomPageRulesAndPagePlugins = (context: Omit<Context, 'agendaPageRulesMemory'>) => {
  const agendaPageRulesMemory = createAgendaPageRulesMemory();

  return {
    agendaRules: [
      ...getAgendaSubPagesRules({
        agendaPageRulesMemory,
      }),
      ...getAgendaDailyWeeklyViewRules({
        agendaPageRulesMemory,
      }),
      ...getAgendaAllCollaboratorsWorkCollaboratorsViewRules({
        agendaPageRulesMemory,
      }),
    ],
    agendaPlugins: getCustomAgendaPageTrackingPageRulePlugins({ ...context, agendaPageRulesMemory }),
  };
};
