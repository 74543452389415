import ENV from 'env';

export const UnleashConfig = {
  url: ENV.UALA_UNLEASH_PROXY as string,
  clientKey: ENV.UALA_UNLEASH_CLIENT_KEY as string,
  refreshInterval: 3600, // set to 1h
  metricsInterval: 3600, // set to 1h
  appName: 'default',
  environment: 'default',
} as const;
