import { useContext, useEffect } from 'react';
import { ModuleFederationContext } from 'host/RegistryProvider/RegistryProvider';
import { useFlag } from 'feature-flags/unleash';
import { ACTIVE_FLAGS } from 'feature-flags/ACTIVE_FLAGS';
import { useSelector } from 'react-redux';
import { getPermissionChecker } from '../selectors/venue/getVenuePermissions';
import { ACL_PERMISSION, ACL_PERMISSIONS_VALUES } from '../models';

export const StoreFeatureFlag = (): null => {
  const { addRemotes } = useContext(ModuleFederationContext);
  const isStoreFlagActive = useFlag(ACTIVE_FLAGS.TWPRO_STORE);
  const checkPermission = useSelector(getPermissionChecker);
  const hasUserAccessToStore = checkPermission(ACL_PERMISSION.STORE_ACCESS) !== ACL_PERMISSIONS_VALUES.NONE;

  useEffect(() => {
    if (isStoreFlagActive && addRemotes && hasUserAccessToStore) {
      const t = new Date().getTime();
      fetch('https://tw-store.netlify.app/registry.json?t=' + t)
        .then((response) => response.json())
        .then((remotes) => {
          addRemotes(remotes);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }, [addRemotes, hasUserAccessToStore, isStoreFlagActive]);

  return null;
};
