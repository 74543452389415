import { styled, css } from 'styles';
import Field, { fieldSizes } from 'components/core/Field/theme';
import { COLORS } from 'styles';

const sizeStyles = {
  mini: css`
    input + span {
      width: ${fieldSizes.height.mini}px;
      height: ${fieldSizes.height.mini}px;
      border-radius: ${fieldSizes.height.mini}px;
      flex: 0 0 ${fieldSizes.height.mini}px;

      &:before {
        border-radius: ${fieldSizes.height.mini}px;
      }
    }
  `,
  small: css`
    input + span {
      width: ${fieldSizes.height.small}px;
      height: ${fieldSizes.height.small}px;
      border-radius: ${fieldSizes.height.small}px;
      flex: 0 0 ${fieldSizes.height.small}px;

      &:before {
        border-radius: ${fieldSizes.height.small}px;
      }
    }
  `,
  default: css`
    input + span {
      width: ${fieldSizes.height.default}px;
      height: ${fieldSizes.height.default}px;
      border-radius: ${fieldSizes.height.default}px;
      flex: 0 0 ${fieldSizes.height.default}px;

      &:before {
        border-radius: ${fieldSizes.height.default}px;
      }
    }
  `,
  large: css`
    input + span {
      width: ${fieldSizes.height.large - 4}px;
      height: ${fieldSizes.height.large - 4}px;
      border-radius: ${fieldSizes.height.large}px;
      flex: 0 0 ${fieldSizes.height.large - 4}px;

      &:before {
        border-radius: ${fieldSizes.height.large}px;
      }
    }
  `,
};

export const CheckboxRadio = styled(Field)(
  ({ disabled, size, inverted }) => css`
    ${size && sizeStyles[size]};
    display: inline-block;
    margin-right: 25px;
    border: 0;
    background-color: transparent;

    input + span {
      margin-left: 10px;

      ${inverted &&
      css`
        order: -1;
        margin-left: 0;
        margin-right: 10px;
      `};
      ${disabled &&
      css`
        background-color: lightgray;
        border-color: lightgray;
        opacity: 0.3;
        pointer-events: none;

        &:before {
          background-color: gray;
        }
      `};
    }
  `
);

export const Label = styled('label')`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const Input = styled('input')`
  left: -9999px;
  opacity: 0;
  position: absolute;

  &:checked + span:before {
    background-color: ${COLORS.blue};
    transform: scale(1) translate(-50%, -50%);
  }
`;

export const Value = styled('span')`
  position: relative;
  border: 1px solid ${COLORS.inputBorder};
  background-color: ${COLORS.white};
  cursor: pointer;
  pointer-events: none;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    background-color: transparent;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: 0 0;
    transition: transform 0.3s;
  }
`;

export default CheckboxRadio;
