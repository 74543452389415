import { CheckPermissionCallback, Country, Venue } from 'models';
import { connect } from 'react-redux';
import { State } from 'reducers/types';
import { createSelector, OutputParametricSelector } from 'reselect';
import { getCurrentVenue, getCurrentVenueCountry } from 'selectors/getCurrentVenue';
import { getPermissionChecker } from 'selectors/venue/getVenuePermissions';
import { UnknownFunction } from 'utils/utilityTypes';
import { ProvisioningRouteComponent } from './ProvisioningRoute';

type ProvisioningRouteStateProps = {
  venue: Venue | null;
  country: Country | null;
  checkPermission: CheckPermissionCallback;
};

const mapStateToProps = (): OutputParametricSelector<
  State,
  Record<string, unknown>,
  ProvisioningRouteStateProps,
  UnknownFunction
> =>
  createSelector(getCurrentVenue, getCurrentVenueCountry, getPermissionChecker, (venue, country, checkPermission) => {
    return {
      venue,
      country,
      checkPermission,
    };
  });

export const ProvisioningRoute = connect(mapStateToProps)(ProvisioningRouteComponent);
