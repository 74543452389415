/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';
import { withLegacyHeaderPagination } from 'API/utils';
import { Paginated, PaginationVariables, VenueProductsListResponse } from 'API/types';

const maybeLegacyHeaderPaginationToJSON = withLegacyHeaderPagination(maybeResponseToJSON);

type VenueProductFiltersPayload = {
  readonly sort_by?: 'name' | 'manufacturer' | 'selling_price' | 'stock';
  readonly sort_dir?: 'asc' | 'desc';
  readonly manufacturers_in?: ReadonlyArray<string>;
  readonly categories_in?: ReadonlyArray<string>;
  readonly stock_status?: ReadonlyArray<string>;
};

export const LIST =
  ({ variables }: V<Partial<PaginationVariables> & VenueProductFiltersPayload>) =>
  (): Promise<Paginated<VenueProductsListResponse>> =>
    Http()
      .get<Paginated<VenueProductsListResponse>, Partial<PaginationVariables> & VenueProductFiltersPayload>(
        ENDPOINT.VENUE_PRODUCTS._LIST()
      )({
        searchParams: variables,
      })
      .then(maybeLegacyHeaderPaginationToJSON);
