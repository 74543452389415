import { FunctionComponent, ReactChild, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotificationCenter from 'components/lib/NotificationCenter';
import { useDocumentTitle } from 'utils/hooks';
import { Text } from 'components/core';
import { Header, HeaderTitle, HeaderAction } from 'components/header';
import NavBar from 'components/navbar';
import { ProvisioningUpsellingButton } from 'components/provisioning_next';
import RemoteRenderer from 'host/RemoteRenderer';
import Remotes from 'host/Remotes';
import { PageWrapper } from './RemoteHomepage.theme';

const DocumentTitle: FunctionComponent<{ children: string }> = ({ children }) => {
  useDocumentTitle(children);
  return null;
};

const RemoteHeaderAction: FunctionComponent<{ children: ReactChild }> = ({ children }) => (
  <HeaderAction mobile={true} tablet={true} desktop={true} fluid>
    {children}
  </HeaderAction>
);

export const RemoteHomepage: FunctionComponent = () => {
  const params = useParams<{ scope: string }>();
  // TODO: to define a more solid approach to basename

  const [isNavBarOpen, setIsNavBarOpen] = useState(false);

  return (
    <PageWrapper>
      <Remotes scope={params.scope}>
        {(remote) => {
          return <DocumentTitle key={remote.scope}>{remote.name}</DocumentTitle>;
        }}
      </Remotes>
      <NotificationCenter />

      <Header>
        <Remotes scope={params.scope}>
          {(remote) => {
            return (
              <HeaderTitle
                key={remote.scope}
                isRoot
                rootTitle={
                  <Text size={12} bold uppercase spacing={1}>
                    {remote.name}
                  </Text>
                }
                rootIcon={remote.config?.appIcon || 'compass'}
              />
            );
          }}
        </Remotes>

        <HeaderAction mobile={false} tablet={false} smallDesktop={false} desktop={true} fluid>
          <ProvisioningUpsellingButton />
        </HeaderAction>

        <RemoteRenderer
          namespace={`remote.homepage.header.widgets.$.${params.scope}`}
          wrapperComponent={RemoteHeaderAction}
        />

        <HeaderAction
          mobile
          tablet
          desktop
          icon={{ type: 'menu', color: 'blue' }}
          onClick={() => setIsNavBarOpen((v) => !v)}
        />
      </Header>
      {/* NAVBAR SECTION */}
      <NavBar isOpen={isNavBarOpen} onClick={() => setIsNavBarOpen(false)} />

      <div className="remote-homepage-wrapper">
        <RemoteRenderer namespace={`remote.homepage.$.${params.scope}`} />
      </div>
    </PageWrapper>
  );
};
