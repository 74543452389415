import { FunctionComponent, Fragment, useCallback, useEffect, useMemo } from 'react';
import { Icon, Section, Text, Modal, Spinner } from 'components/core';
import { Table, Cell, CellCard, InfoSection, COLUMN_WIDTHS } from './PaymentMethodsList.theme';
import { BRAND_ICONS, PAYMENT_METHOD_STATE } from 'models';
import { DefaultMethod, ExpirationDate, CreditCardLabel, AddNewCard, AddPaymentMethod } from './partials';
import { ModalActionDispatch } from 'actions/modals';
import { Machine, PmUtils, PM_INPUT_SYMBOL, Current, AvailableCreditCard } from '@uala/web-payments';
import { sortCreditCardsBtId } from './PaymentMethods.utils';
import { injectIntl, WrappedComponentProps } from 'react-intl';

type PaymentMethodsProps = {
  paymentMethods: AvailableCreditCard[];
  machine: Machine;
  modalOpen: ModalActionDispatch;
  currentState?: Current;
  venueId?: number | null;
};

type HeadingProps = {
  intl: string;
};

const HeadingText: FunctionComponent<HeadingProps> = ({ intl }) => <Text size={10} uppercase intl={intl} />;

const PaymentMethodsList: FunctionComponent<PaymentMethodsProps & WrappedComponentProps> = ({
  paymentMethods,
  machine,
  modalOpen,
  currentState,
  intl,
  venueId,
}) => {
  const sortedCreditCards = useMemo(() => {
    return paymentMethods ? sortCreditCardsBtId(paymentMethods) : [];
  }, [paymentMethods]);

  const showModal =
    PmUtils.isLoadingStripe(machine) ||
    PmUtils.canRenderStripe(machine) ||
    PmUtils.isSettingDefault(machine) ||
    PmUtils.isDeleting(machine);

  const hasPaymentMethods = paymentMethods && paymentMethods.length > 0;

  useEffect(() => {
    if (PmUtils.canRenderDefaultSuccessAlert(machine)) {
      modalOpen({
        id: 'success-payment-method-default',
        config: {
          component: 'Success',
          message: intl.formatMessage({ id: 'account.payment_methods.default_card_success' }),
          dismissLabel: 'close',
          onClose: (): Promise<void> => machine.input(PM_INPUT_SYMBOL.CONFIRM),
        },
      });
    }

    if (PmUtils.canRenderError(machine)) {
      modalOpen({
        id: 'general-error',
        config: {
          component: 'Confirm',
          title: intl.formatMessage({ id: 'attention' }),
          heading: intl.formatMessage({ id: 'error_occurred' }),
          content: intl.formatMessage({ id: 'please_retry' }),
          confirmIntl: 'retry',
          cancelIntl: 'cancel',
          onClose: (): Promise<void> => machine.input(PM_INPUT_SYMBOL.HANDLED),
          onConfirm: (): Promise<void> => machine.input(PM_INPUT_SYMBOL.RETRY),
        },
      });
    }

    if (PmUtils.canRenderCreditCardAddedAlert(machine)) {
      modalOpen({
        id: 'success-payment-method-added',
        config: {
          component: 'Success',
          message: intl.formatMessage({ id: 'account.payment_methods.payment_method_added' }),
          dismissLabel: 'close',
          onClose: (): Promise<void> => machine.input(PM_INPUT_SYMBOL.CONFIRM),
        },
      });
    }

    if (PmUtils.canRenderCreditCardDidDeleteAlert(machine)) {
      modalOpen({
        id: 'payment-method-deleted',
        config: {
          component: 'Success',
          message: intl.formatMessage({ id: 'account.payment_methods.delete_payment_method_success' }),
          dismissLabel: 'close',
          onClose: (): Promise<void> => machine.input(PM_INPUT_SYMBOL.CONFIRM),
        },
      });
    }
  }, [currentState, intl, machine, modalOpen, venueId]);

  const deletePaymentMethod = useCallback(
    (card: AvailableCreditCard) => {
      machine.input(PM_INPUT_SYMBOL.DELETE, card);
      modalOpen({
        id: 'confirm-payment-method-delete',
        config: {
          component: 'Confirm',
          onConfirm: (): void => {
            machine.input(PM_INPUT_SYMBOL.CONFIRM);
          },
          onClose: (): Promise<void> => machine.input(PM_INPUT_SYMBOL.CANCEL),
          heading: intl.formatMessage({ id: 'account.payment_methods.delete_card_confirm' }),
          content: card?.card_brand && <CreditCardLabel cardBrand={card.card_brand} cardNumber={card.card_number} />,
        },
      });
    },
    [intl, machine, modalOpen, venueId]
  );

  const setDefaultPaymentMethod = useCallback(
    (card: AvailableCreditCard) => {
      machine.input(PM_INPUT_SYMBOL.DEFAULT, { ...card, default: true });
      modalOpen({
        id: 'confirm-payment-method-delete',
        config: {
          component: 'Confirm',
          onConfirm: (): Promise<void> => machine.input(PM_INPUT_SYMBOL.CONFIRM),
          onClose: (): Promise<void> => machine.input(PM_INPUT_SYMBOL.CANCEL),
          heading: intl.formatMessage({ id: 'account.payment_methods.default_card_confirm' }),
          content: card?.card_brand && <CreditCardLabel cardBrand={card.card_brand} cardNumber={card.card_number} />,
        },
      });
    },
    [intl, machine, modalOpen]
  );

  return (
    <Section>
      {showModal && (
        <Modal centered>
          {PmUtils.canRenderStripe(machine) ? (
            <AddPaymentMethod machine={machine} canChangeDefaultMethodCheckbox={hasPaymentMethods} venueId={venueId} />
          ) : (
            <Section displayFlex alignItems="center" justifyContent="center" padding={1} width="100%" height="100%">
              <Spinner />
            </Section>
          )}
        </Modal>
      )}
      {hasPaymentMethods && (
        <Table>
          <CellCard width={COLUMN_WIDTHS[0]} isHeading desktopOnly>
            <HeadingText intl="account.payment_methods.card" />
          </CellCard>
          <Cell width={COLUMN_WIDTHS[1]} isHeading desktopOnly>
            <HeadingText intl="account.payment_methods.expiration" />
          </Cell>
          <Cell width={COLUMN_WIDTHS[2]} isHeading desktopOnly></Cell>
          <Cell width={COLUMN_WIDTHS[3]} isHeading desktopOnly></Cell>
          {sortedCreditCards.map(
            (card, index) =>
              card.state !== PAYMENT_METHOD_STATE.DELETED && (
                <Fragment key={`payment-method-${index}`}>
                  <CellCard data-testid={`payment_methods_card-${card.card_number}`} width={COLUMN_WIDTHS[0]}>
                    {card.state === PAYMENT_METHOD_STATE.PENDING && (
                      <Text size={16} color="orange2" intl="account.payment_methods.pending" />
                    )}
                    {card.card_brand && card.card_number && (
                      <>
                        <Section paddingRight={0.75} displayFlex alignItems="flex-start">
                          <Icon type={BRAND_ICONS[card.card_brand]} size={30} />
                        </Section>
                        <Section displayFlex flexDirection="column">
                          <CreditCardLabel cardBrand={card.card_brand} cardNumber={card.card_number} />
                          <InfoSection>
                            {card.expiry_date_month && card.expiry_date_year && (
                              <ExpirationDate
                                state={card.state}
                                expirationMonth={card.expiry_date_month}
                                expirationYear={card.expiry_date_year}
                              />
                            )}
                            <DefaultMethod
                              state={card.state}
                              isPending={card.state === PAYMENT_METHOD_STATE.PENDING}
                              isDefault={card.default}
                              onClick={(): void => setDefaultPaymentMethod(card)}
                            />
                          </InfoSection>
                        </Section>
                      </>
                    )}
                  </CellCard>
                  <Cell width={COLUMN_WIDTHS[1]} desktopOnly>
                    {card.expiry_date_month && card.expiry_date_year && (
                      <ExpirationDate
                        state={card.state}
                        expirationMonth={card.expiry_date_month}
                        expirationYear={card.expiry_date_year}
                      />
                    )}
                  </Cell>
                  <Cell width={COLUMN_WIDTHS[2]} desktopOnly>
                    <DefaultMethod
                      state={card.state}
                      isPending={card.state === PAYMENT_METHOD_STATE.PENDING}
                      isDefault={card.default}
                      onClick={(): void => setDefaultPaymentMethod(card)}
                    />
                  </Cell>
                  <Cell width={COLUMN_WIDTHS[3]}>
                    {!card.default && paymentMethods.length > 1 && (
                      <Section
                        width="100%"
                        displayFlex
                        justifyContent="center"
                        style={{ cursor: 'pointer' }}
                        onClick={(): void => deletePaymentMethod(card)}
                        data-testid={`payment_methods_card-${card.card_number}_delete_button`}
                      >
                        <Icon type="trash" size={16} color="red" />
                      </Section>
                    )}
                  </Cell>
                </Fragment>
              )
          )}
        </Table>
      )}
      <Section width="100%" paddingBottom={hasPaymentMethods ? 0.5 : 1.25}>
        <AddNewCard onClick={(): Promise<void> => machine.input(PM_INPUT_SYMBOL.STRIPE)} />
      </Section>
    </Section>
  );
};

export default injectIntl(PaymentMethodsList);
