import { Box, Icon, Text, Button } from 'components/core';
import { useSelector } from 'react-redux';
import { getCurrentVenue, getCurrentVenueCurrencySymbol } from 'selectors/getCurrentVenue';
import { getCurrentUser } from 'selectors/getCurrentUser';
import { Country } from 'models';

// TODO: uk is returned as 'gb' by the APIs, but is referenced in the code as UK in some cases.. check what is the correct code
type ReferralCountryCodes = Country['code'] | 'gb';
export const referralPageLinkByCountry: Record<ReferralCountryCodes, string | null> = {
  gb: 'https://www.treatwell.co.uk/partners/refer-a-salon-partner/',
  es: 'https://www.treatwell.es/partners/recomendar-un-salon-partner/',
  it: 'https://www.treatwell.it/partners/consigliaci-un-salone-partner/',
  fr: 'https://www.treatwell.fr/partenaires/recommander-un-salon-partner/',
  pt: 'https://www.treatwell.pt/partners/recomendam-um-saloes-partner/',
  ch: 'https://www.treatwell.ch/partners/empfehle-einen-salon-partner/',
  gr: 'https://www.treatwell.gr/partners/refer-a-salon-partner/',
  be: 'https://www.treatwell.be/partners/een-salonpartner-aanbevelen/',
  de: 'https://www.treatwell.de/partners/empfehle-einen-salon-partner/',
  ie: 'https://www.treatwell.ie/partners/refer-a-salon-partner/',
  at: 'https://www.treatwell.at/partners/empfehle-einen-salon-partner/',
  lt: 'https://www.treatwell.lt/partneriai/rekomenduokite-salono-partneri/',
  nl: 'https://www.treatwell.nl/partners/een-salonpartner-aanbevelen/',
  uk: 'https://www.treatwell.co.uk/partners/refer-a-salon-partner/',
};

export const ReferralLink = (): JSX.Element | null => {
  const venue = useSelector(getCurrentVenue);
  const country = venue?.country?.code;
  const currentUser = useSelector(getCurrentUser);
  const currencySymbol = useSelector(getCurrentVenueCurrencySymbol);

  if (!country || !referralPageLinkByCountry[country]) return null;

  const handleReferralButtonClick = (): void => {
    if (!venue) return;
    const queryParams = new URLSearchParams({
      venue_id: String(venue.id),
      venue_name: venue.name ?? '',
      referrer_email: currentUser.email ?? '',
      referrer_id: String(currentUser.id ?? ''),
    }).toString();
    window.open(`${referralPageLinkByCountry[country]}?${queryParams}`);
  };

  return (
    <Box backgroundColor="#E8F1FF" marginTop="35px" padding="18px 25px 25px 25px">
      <Box display="flex" flexDirection="row" gap="16px">
        <Icon type="fiche" color="blue" size={16} />
        <Text intl="agenda.referral_link.refer_salon" uppercase size={12} />
      </Box>
      <Box padding="15px 0">
        <Text
          intl="agenda.referral_link.description"
          intlValues={{
            currency: currencySymbol,
          }}
          color="darkBlue2"
        />
      </Box>
      <Box>
        <Button primary onClick={handleReferralButtonClick} width="100%">
          <Text intl="agenda.referral_link.start_referral" color="white" uppercase />
        </Button>
      </Box>
    </Box>
  );
};
