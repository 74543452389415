import { connect } from 'react-redux';
import { useEffect } from 'react';
import {
  disableMultiVenueSidebar,
  enableMultiVenueSidebar,
  hideMultiVenueSidebar,
  showMultiVenueSidebar,
} from 'actions/multiVenueSidebarSettings';

type MultiVenueSidebarHandlerProps = {
  isDisabled?: boolean;
  isHidden?: boolean;
  dispatchDisableMultiVenueSidebar?: () => void;
  dispatchEnableMultiVenueSidebar?: () => void;
  dispatchHideMultiVenueSidebar?: () => void;
  dispatchShowMultiVenueSidebar?: () => void;
};

/**
 * This component is used to disable the multi venue sidebar and prevent the user to switch venue.
 * The following cases should be covered:
 * [x] create appointment
 * [x] cut/past appointment
 * [x] new promotion
 * [x] new product
 * [x] new order
 * [x] new extra opening
 * [x] new extra closing
 * [x] new staff
 * [x] new room
 * [x] new workstation
 * [x] new supplier
 * [x] new manufacturer
 * [x] new shipping-option
 * [x] upgrade plan
 */

/**
 * Hide the sidebar when printing
 */

const MultiVenueSidebarHandler = ({
  isDisabled = false,
  isHidden = false,
  dispatchDisableMultiVenueSidebar = (): void => {},
  dispatchEnableMultiVenueSidebar = (): void => {},
  dispatchHideMultiVenueSidebar = (): void => {},
  dispatchShowMultiVenueSidebar = (): void => {},
}: MultiVenueSidebarHandlerProps): null => {
  useEffect(() => {
    if (isDisabled) {
      dispatchDisableMultiVenueSidebar();
      return (): void => dispatchEnableMultiVenueSidebar();
    }

    if (isHidden) {
      dispatchHideMultiVenueSidebar();
      return (): void => dispatchShowMultiVenueSidebar();
    }

    return (): void => {};
  }, [
    isDisabled,
    isHidden,
    dispatchDisableMultiVenueSidebar,
    dispatchEnableMultiVenueSidebar,
    dispatchHideMultiVenueSidebar,
    dispatchShowMultiVenueSidebar,
  ]);

  return null;
};

const mapDispatchToProps = {
  dispatchDisableMultiVenueSidebar: disableMultiVenueSidebar,
  dispatchEnableMultiVenueSidebar: enableMultiVenueSidebar,
  dispatchHideMultiVenueSidebar: hideMultiVenueSidebar,
  dispatchShowMultiVenueSidebar: showMultiVenueSidebar,
};

export default connect(null, mapDispatchToProps)(MultiVenueSidebarHandler);
