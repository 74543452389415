import { ReactNode } from 'react';
import StyledCheckboxRadio, { Input, Label, Value } from 'components/core/CheckboxRadio/theme';
import { RadioProps } from './index';

export const Radio = ({
  children,
  disabled,
  size,
  inverted,
  className,
}: Pick<RadioProps, 'disabled' | 'size' | 'inverted'> & {
  className: string;
  children: ReactNode;
}): JSX.Element => <StyledCheckboxRadio {...{ children, disabled, size, inverted, className }} />;

Radio.Input = Input;
Radio.Label = Label;
Radio.Value = Value;

export default Radio;
