import { styled, css } from 'styles';
import { withTheme } from '../Context';
import { Button } from 'components/core';

const tabsSize = {
  default: `
    cursor: pointer;
    padding: 4px 0;
    line-height: 12px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  `,
  big: `
    cursor: pointer;
    padding: 16px 0 14px;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  `,
};

export const Tabs = styled('div')(
  ({ justify, scrollable, size, fontSize, bold, itemPadding }) => css`
    position: relative;
    width: 100%;

    > .Tabs_item {
      font-size: ${(fontSize || 10) / 10}rem;
      ${(size && tabsSize[size]) || tabsSize.default};
      ${bold ? `font-weight: 700` : ''};
      ${itemPadding ? `padding: ${itemPadding * 20}px 0;` : ``};
    }

    ${scrollable
      ? css`
          display: block;
          white-space: nowrap;
          overflow-x: auto;

          > .Tabs_item {
            display: inline-block;
            text-align: center;
          }
        `
      : css`
          display: flex;
        `};

    ${justify &&
    css`
      justify-content: space-between;
    `};
  `
);

export const TabsItem = withTheme(
  styled('div')(
    ({ theme: { colors }, active, disabled, withVerticalSeparator }) => css`
      ${active
        ? css`
            border-bottom: 1px solid ${colors.blue};
            color: ${colors.blue};
          `
        : css`
            color: ${colors.inputPlaceHolder};
          `};

      ${disabled && 'pointer-events: none;'};
      ${withVerticalSeparator && `border-right: 1px solid ${colors.inputBorder}`}
    `
  )
);

export const BadgeButton = withTheme(
  styled(Button)(
    ({ theme: { sizes }, active }) => css`
      ${!active && `background-color: transparent; border-color: transparent;`}
      min-height: 32px;
      height: auto;

      &:not(:last-child) {
        margin-right: ${sizes.padding}px;
      }
    `
  )
);

export default Tabs;
