import moment from 'moment';
import { AGENDA_CLOSE_CALLER_BOX, API_GET_RESPONSE } from 'actionTypes';

/**
 * callerbox reducer
 */
export const callerBox = (state = {}, action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case AGENDA_CLOSE_CALLER_BOX:
      return {
        ...state,
        popup: null,
      };

    case API_GET_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        !action.response.data.venue_actions ||
        !action.response.data.venue_actions.length
      ) {
        return state;
      }

      const venue_actions = action.response.data.venue_actions.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));
      for (let index = 0; index < venue_actions.length; index++) {
        const venue_action = venue_actions[index];
        const { created_at, action_object, action_type, show_before, show_for, data } = venue_action;

        if (action_object === 'caller_popup') {
          if (action_type === 'open') {
            const valid_until = moment(created_at).add(show_before + show_for, 'seconds');
            if (valid_until.isAfter(new Date())) {
              return {
                ...state,
                popup: {
                  data,
                },
              };
            }
          }
          if (action_type === 'close') {
            return {
              ...state,
              popup: null,
            };
          }
        }
      }
      return state;

    default:
      return state;
  }
};
