import { createSelector } from 'reselect';
import { getHideTermsByCustomerId } from 'selectors/customers/getHideTerms';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getEntities } from 'selectors/getEntities';
import filterDeletedItems from 'utils/filterDeletedItems';
import { memoize } from 'utils/memoize';
import toTitleCase from 'utils/stringToTitleCase';

export const getCustomersByVenue = (state) => state.customersByVenue;

export const getCustomersLoading = createSelector(
  [getCurrentVenueId, getCustomersByVenue],
  (venue_id, customersByVenue) => {
    return customersByVenue[venue_id] && customersByVenue[venue_id].isFetching ? true : false;
  }
);

export const getCustomersIsFetchingAll = createSelector(
  [getCurrentVenueId, getCustomersByVenue],
  (venue_id, customersByVenue) => {
    return customersByVenue[venue_id] && customersByVenue[venue_id].isFetchingAllCustomers ? true : false;
  }
);

export const getCustomersNeedFetch = createSelector(
  [getCurrentVenueId, getCustomersIsFetchingAll, getCustomersByVenue],
  (venue_id, isFetchingAll, customersByVenue) => {
    return !customersByVenue[venue_id] ||
      !customersByVenue[venue_id].lastCompleteFetch ||
      (customersByVenue[venue_id].lastCompleteFetch < new Date().getTime() - 7 * 24 * 3600 * 1000 && !isFetchingAll)
      ? true
      : false;
  }
);

const memoToLowerCase = memoize((f) => f.toLowerCase());
const memoToTitleCase = memoize(toTitleCase);

const emptyCustomersList = [];

/**
 * @type {import('reducers/types').AppSelector<import('models').Customer[]>}
 */
export const getCustomersList = createSelector(
  [getCurrentVenueId, getCustomersByVenue, getHideTermsByCustomerId, getEntities.customer],
  (venue_id, customersByVenue, hideTermsByCustomerId, customerById) => {
    const customers = (customersByVenue[venue_id] && customersByVenue[venue_id].items) || emptyCustomersList;

    if (customers === emptyCustomersList) {
      return customers;
    }

    return customers
      .reduce((accumulator, item) => {
        const newCustomer =
          customerById[item.id] && customerById[item.id].data
            ? Object.assign({}, item, customerById[item.id].data)
            : {};
        if (newCustomer.id && filterDeletedItems(newCustomer)) {
          accumulator[accumulator.length] = Object.assign({}, newCustomer, {
            terms_of_services_hide_until: hideTermsByCustomerId[newCustomer.id]
              ? hideTermsByCustomerId[newCustomer.id].hide_until
              : null,
            phone: newCustomer.phone || '',
            full_name: memoToTitleCase(newCustomer.full_name || ''),
            search_first_name: memoToLowerCase(newCustomer.first_name || ''),
            search_last_name: memoToLowerCase(newCustomer.last_name || ''),
            search_full_name: memoToLowerCase(newCustomer.full_name || ''),
            search_phone: memoToLowerCase(newCustomer.phone || ''),
          });
        }
        return accumulator;
      }, [])
      .sort((a, b) => (b.full_name > a.full_name ? -1 : 1));
  }
);

/** @typedef {import('models').Customer} Customer **/
/** @typedef {import('reducers/types').State} State **/
/** @type {(state: State) => { [key: number]: Customer } **/
export const getCustomersById = createSelector([getCustomersList], (customersList) => {
  return customersList.reduce((customersById, customer) => {
    if (customer.id) {
      customersById[customer.id] = customer;
    }
    return customersById;
  }, {});
});
