import PropTypes from 'prop-types';
import StyledSelectInputGroup from './theme';

const SelectInputGroup = (props) => <StyledSelectInputGroup {...props} />;

SelectInputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  flex: PropTypes.bool,
};

export default SelectInputGroup;
