import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import MultiVenueSidebar from './HeaderTriggerMultiVenueSidebar';
import { toggleMultiVenueSidebar } from 'actions/multiVenueSidebarSettings';
import { getCurrentVenue } from 'selectors/getCurrentVenue';
import { isUserMultiVenue } from 'selectors/getUserMultiVenue';

const mapStateToProps = () =>
  createSelector(getCurrentVenue, isUserMultiVenue, (currentVenue, isMultiVenue) => {
    return {
      isMultiVenue,
      currentVenue,
    };
  });

const mapDispatchToProps = {
  dispatchToggleMultiVenueSidebar: toggleMultiVenueSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiVenueSidebar);
