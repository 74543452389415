import * as React from 'react';
import { useTooltip } from './useTooltip.hook';
import { Box } from 'components/core';
import { useOnClickOutside } from 'utils/hooks';
import { TOOLTIP_TYPE } from './TooltipTypes';
import { TOOLTIP_POSITION } from 'ui-treatwell-pro/tooltip/Tooltip';

const defaultInfoIcon = (
  <svg
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    data-testid="tooltip-info-icon"
    viewBox="0 0 17 17"
    fill="currentColor"
  >
    <path d="M8.5 17a8.5 8.5 0 1 1 0-17 8.5 8.5 0 0 1 0 17Zm0-1a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Zm0-8.5a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0v-3a1 1 0 0 1 1-1Zm0-3a1 1 0 1 1 0 2 1 1 0 1 1 0-2Z" />
  </svg>
);

export const InfoTooltip: React.FC<{
  readonly content: string;
  readonly type?: string;
  readonly defaultPosition: string;
}> = ({ content, type = TOOLTIP_TYPE.INFO, defaultPosition = TOOLTIP_POSITION.BOTTOM, children = defaultInfoIcon }) => {
  const TOOLTIP_DISTANCE = 10;
  const ref: React.RefObject<HTMLDivElement> = React.useRef(null);
  const { showTooltip, hideTooltip, color } = useTooltip({ type });
  const handleTooltip = React.useCallback(
    (elRef: React.RefObject<HTMLDivElement>, distance: number): void => {
      const boundingClient = elRef?.current?.getBoundingClientRect();
      if (!boundingClient) return;

      const xPosition =
        defaultPosition === TOOLTIP_POSITION.BOTTOM
          ? boundingClient.width / 2 - distance
          : boundingClient.width + distance;

      const yPosition =
        defaultPosition === TOOLTIP_POSITION.BOTTOM ? boundingClient.height + distance : -(distance * 2);

      showTooltip({
        topPosition: boundingClient.y + window.scrollY + yPosition,
        leftPosition: boundingClient.x + window.scrollX + xPosition,
        tooltipContent: content,
        color,
        defaultPosition,
      });
    },
    [content, showTooltip, type, color, defaultPosition]
  );

  useOnClickOutside(ref, hideTooltip);

  return !content?.length ? null : (
    <Box cursor="pointer" textAlign="center" onClick={(): void => handleTooltip(ref, TOOLTIP_DISTANCE)} ref={ref}>
      {children}
    </Box>
  );
};
