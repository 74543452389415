import { DAY_PICKER_VARIANTS } from 'models';
import { State } from 'reducers/types';
import { Action, Store } from 'redux';
import { getCurrentVenueCalendarMode } from 'selectors/getCurrentVenue';
import { AgendaPageRulesMemory } from './agendaPageRulesMemory';

type Context = {
  agendaPageRulesMemory: AgendaPageRulesMemory;
  store: Store<State, Action>;
};

export const calculateDatePickerVariant = ({ agendaPageRulesMemory, store }: Context) => {
  const datePickerVariant = getCurrentVenueCalendarMode(store.getState());
  const datePickerVariantToStore = datePickerVariant === DAY_PICKER_VARIANTS.SWIPE ? 'swipe' : 'monthly';
  agendaPageRulesMemory.set('datepicker', datePickerVariantToStore);
};
