import { createSelector } from 'reselect';
import { getCurrentVenueId } from './getCurrentVenue';
import getEntities from './getEntities';
import filterDeletedItems from 'utils/filterDeletedItems';

export const getVenueExtraOpeningsByVenue = (state) => state.venueExtraOpeningsByVenue;

const emptyVenueExtraOpenings = [];

export const getVenueExtraOpeningsList = createSelector(
  getCurrentVenueId,
  getVenueExtraOpeningsByVenue,
  getEntities.extra_opening,
  (venue_id, venueExtraOpeningsByVenue, venueExtraOpeningById) => {
    const venueExtraOpenings =
      (venueExtraOpeningsByVenue[venue_id] && venueExtraOpeningsByVenue[venue_id].items) || emptyVenueExtraOpenings;
    if (venueExtraOpenings === emptyVenueExtraOpenings) {
      return venueExtraOpenings;
    }
    return venueExtraOpenings
      .map((extra_opening) => ({
        ...extra_opening,
        ...(venueExtraOpeningById[extra_opening.id] ? venueExtraOpeningById[extra_opening.id].data : {}),
      }))
      .filter(filterDeletedItems);
  }
);

export const getVenueExtraOpeningsById = createSelector([getVenueExtraOpeningsList], (venueExtraOpeningsList) => {
  return venueExtraOpeningsList.reduce((venueExtraOpeningsById, venueExtraOpenings) => {
    if (venueExtraOpenings.id) {
      venueExtraOpeningsById[venueExtraOpenings.id] = venueExtraOpenings;
    }
    return venueExtraOpeningsById;
  }, {});
});

export const getVenueExtraOpeningsLoading = createSelector(
  [getCurrentVenueId, getVenueExtraOpeningsByVenue],
  (venue_id, venueExtraOpeningsByVenue) => {
    return (venueExtraOpeningsByVenue[venue_id] && venueExtraOpeningsByVenue[venue_id].isFetching) || false;
  }
);
