import { FETCH_USERS_ACCESSES_RESPONSE } from 'actionTypes';
import { UserAccessesResponse } from 'models/usersManagement';
const DEFAULT_USER_ACCESSES_BY_VENUE_STATE = {};

interface AccessesReducerAction {
  type: typeof FETCH_USERS_ACCESSES_RESPONSE;
  response: UserAccessesResponse;
}
export interface UserAccessesReducer {
  (state: unknown, action: AccessesReducerAction): void;
}
export const userAccessesByVenue: UserAccessesReducer = (state = DEFAULT_USER_ACCESSES_BY_VENUE_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS_ACCESSES_RESPONSE:
      return action.response.data;
    default:
      return state;
  }
};
