import { styled, css } from 'styles';
import { COLORS } from 'styles';
import Field, { fieldSizes } from 'components/core/Field/theme';

const transitionFunc = 'cubic-bezier(0.78, 0.14, 0.15, 0.86)';

const sizeStyles = {
  small: css`
    > label {
      width: 50px;
      height: ${fieldSizes.height.small}px;
    }

    .Switch__Slider {
      border-radius: ${fieldSizes.height.small}px;

      :before {
        left: 4px;
        width: ${fieldSizes.innerHeight.small() - 6}px;
        height: ${fieldSizes.innerHeight.small() - 6}px;
        border-radius: ${fieldSizes.innerHeight.small() - 6}px;
      }
    }
  `,
  default: css`
    > label {
      width: 58px;
      height: ${fieldSizes.height.default}px;
    }

    .Switch__Slider {
      border-radius: ${fieldSizes.height.default}px;

      :before {
        left: 4px;
        width: ${fieldSizes.innerHeight.default() - 6}px;
        height: ${fieldSizes.innerHeight.default() - 6}px;
        border-radius: ${fieldSizes.innerHeight.default() - 6}px;
      }
    }
  `,
  large: css`
    > label {
      width: 76px;
      height: ${fieldSizes.height.large}px;
    }

    .Switch__Slider {
      border-radius: ${fieldSizes.height.large}px;

      :before {
        left: 8px;
        width: ${fieldSizes.innerHeight.large() - 10}px;
        height: ${fieldSizes.innerHeight.large() - 10}px;
        border-radius: ${fieldSizes.innerHeight.large() - 10}px;
      }
    }
  `,
};

const StyledSwitch = styled(Field)(
  ({ disabled, size }) => css`
    ${(size && sizeStyles[size]) || sizeStyles.default};

    display: block;
    border: 0;
    border-radius: 0;
    background-color: transparent;

    > label {
      display: inline-block;
      position: relative;
    }
  `
);

const Input = styled('input')`
  display: none;

  :checked,
  :focus {
    + .Switch__Slider {
      background-color: ${COLORS.blue};
    }

    + .Switch__Slider:before {
      transform: translate(calc(100% + 2px), -50%);
    }
  }
`;

const Slider = styled('span')`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  background-color: ${COLORS.gray};
  transition: background-color 0.05s ${transitionFunc};

  :before {
    content: '';
    position: absolute;
    top: 50%;
    background-color: ${COLORS.white};
    transform: translate(0, -50%);
    transition: transform 0.05s ${transitionFunc};
  }
`;

const Switch = ({ children, disabled, size }) => <StyledSwitch {...{ children, disabled, size }} />;

Switch.Input = Input;
Switch.Slider = Slider;

export default Switch;
