/* eslint-disable no-fallthrough */

import {
  API_GET_RESPONSE,
  API_POST_RESPONSE,
  VENUE_LINE_ITEMS_LIST_REQUEST,
  VENUE_LINE_ITEMS_LIST_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

const lineItems = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_LINE_ITEMS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_LINE_ITEMS_LIST_RESPONSE:
      if (!action.line_items) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          [].concat(action.line_items).map((item) => ({ id: item.id })),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (!action.line_items || !action.line_items.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.line_items)
            .map((item) => ({ id: item.id })),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    default:
      return state;
  }
};

export const lineItemsListByVenue = (state = {}, action) => {
  const responseData = (action.response && action.response.data) || {};
  let refState;

  switch (action.type) {
    case VENUE_LINE_ITEMS_LIST_REQUEST:
    case VENUE_LINE_ITEMS_LIST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = lineItems(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    /**
     * add the newly created lineItems to the list of lineItems (keep sync)
     * add the line items coming from synch
     * if path === `/venues/${action.venue_id}/line_items.json`, is handled by previuos case VENUE_LINE_ITEMS_LIST_RESPONSE
     */
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id || action.path === `/venues/${action.venue_id}/line_items.json`) {
        return state;
      }

      refState = lineItems(state[action.venue_id], {
        ...action,
        line_items: [].concat(responseData.line_item || []).concat(responseData.line_items || []),
      });

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
