import {
  STAFF_MEMBERS_LIST_RESPONSE,
  STAFF_MEMBERS_LIST_REQUEST,
  STAFF_MEMBERS_UPDATE_RESPONSE,
  STAFF_MEMBERS_DELETE_RESPONSE,
  STAFF_MEMBERS_CREATE_RESPONSE,
  API_GET_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const staffMembers = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case STAFF_MEMBERS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case STAFF_MEMBERS_LIST_RESPONSE:
      if (!action.staff_members) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.staff_members).map((item) => ({ id: item.id }))),
      };

    case API_GET_RESPONSE:
    case STAFF_MEMBERS_UPDATE_RESPONSE:
      const updatedStaffMembers = action.staffMember ? [action.staffMember] : action.response?.data?.staff_members;
      if (!updatedStaffMembers) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(updatedStaffMembers || [])
            .map((item) => ({ id: item.id })),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    case STAFF_MEMBERS_DELETE_RESPONSE:
      if (!action.staff_member) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .filter(({ id }) => id !== action.staff_member.id)
            .map((item) => ({ id: item.id }))
        ),
      };

    case STAFF_MEMBERS_CREATE_RESPONSE:
      if (!action.staff_member) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.staff_member || [])
            .map((item) => ({ id: item.id }))
        ),
      };

    default:
      return state;
  }
};

export const staffMembersByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case API_GET_RESPONSE:
    case STAFF_MEMBERS_LIST_REQUEST:
    case STAFF_MEMBERS_LIST_RESPONSE:
    case STAFF_MEMBERS_UPDATE_RESPONSE:
    case STAFF_MEMBERS_DELETE_RESPONSE:
    case STAFF_MEMBERS_CREATE_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = staffMembers(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
