import ENV from 'env';
import { User } from 'models';

export type APISession = {
  auth_token: User['auth_token'];
};

export const createAPI =
  (method: string, session: APISession, url: string) =>
  async <A>(body: A): Promise<void> => {
    if (!session.auth_token) {
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Authorization', `Token token="${session.auth_token}"`);
    if (ENV.UALA_API_KEY) {
      myHeaders.append('X-Client-Auth', ENV.UALA_API_KEY);
    }

    return fetch(url, {
      method,
      body: JSON.stringify(body),
      headers: myHeaders,
    }).then((res) => res.json());
  };
