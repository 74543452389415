import { FunctionComponent, useState, useEffect } from 'react';
import { connectFormElement, ConnectedFormProps } from '@uala/react-forms';

import { Icon } from 'components/core';
import Button from '../../../button';
import { SwapFieldsCtrlWrapper } from './SwapFieldsCtrl.theme';

type SwapFieldsCtrlProps = {
  names: [string, string];
  onSwap: () => void;
} & ConnectedFormProps;

const SwapFieldsCtrl: FunctionComponent<SwapFieldsCtrlProps> = ({ names, emitChange, values = {}, onSwap }) => {
  const firstValue = names[0];
  const lastValue = names[1];
  const isSwapDisabled = !(values[names[0]] && values[names[1]]);
  const [tmpValue, setTmpValue] = useState<string>();

  const handleSwapClick = (): void => {
    if (names.length !== 2) {
      return;
    }

    // FIXME: this may be improved, but needs support from @uala/react-forms lib (aync/chainable events)
    // Start the swap, saving the tmp value prevents from accidental ref overwriting
    setTmpValue(values[names[0]] as unknown as string);

    emitChange(firstValue, values[lastValue]);
  };

  useEffect(() => {
    // Complete the swap. The ref is now up to date, so there's no risk of overwriting
    if (tmpValue) {
      emitChange(lastValue, tmpValue);
      setTmpValue(undefined);
      onSwap?.();
    }
  }, [emitChange, lastValue, tmpValue, onSwap]);

  return (
    <SwapFieldsCtrlWrapper>
      <Button
        filling="outlined"
        shape="circle"
        flat
        dataTestId="swap-fields-ctrl"
        onClick={handleSwapClick}
        disabled={isSwapDisabled}
      >
        <Icon type="transfer" color="blue" />
      </Button>
    </SwapFieldsCtrlWrapper>
  );
};

export default connectFormElement(SwapFieldsCtrl);
