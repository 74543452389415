import { styled, css } from 'styles';
import { withTheme } from 'components/core';

export const Wrapper = withTheme(
  styled('div')(
    ({ theme: { media } }) => css`
      .VenueButton {
        width: 59px;

        ${media.desktop`
          display: none;
        `}

        &:hover {
          .VenueButton__Image {
            &::after {
              opacity: 0;
            }
          }
        }

        .VenueButton__Image {
          ${media.mobileOrSmaller`
            width: 42px;
            height: 42px;
            margin: 0 auto;
          `}
        }
      }
    `
  )
);
