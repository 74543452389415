import { pathGet, PathGetPath } from './pathGet';

function getGroupKey<T>(appointment: T, groupFields: PathGetPath<T>[]): string {
  return groupFields.map((field) => pathGet(appointment, field)).join('_');
}

export function groupArrayByFields<T>(appointments: T[], groupFields: PathGetPath<T>[]): T[][] {
  return Object.values(
    appointments.reduce<Record<string, T[]>>((groupedByFields, appointment) => {
      const groupKey = getGroupKey(appointment, groupFields);
      return {
        ...groupedByFields,
        [groupKey]: [...(groupedByFields[groupKey] || []), appointment],
      };
    }, {})
  );
}
