import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { getCurrentVenueCurrencyISOCode, getCurrentVenueCurrencySymbol } from 'selectors/getCurrentVenue';
import { createContext } from 'react';

const defaultContextValue = {
  currencyCode: '',
  currencySymbol: '',
};

export const Context = createContext(defaultContextValue);
export const { Provider, Consumer } = Context;

const GlobalsProvider = ({ contextValue, children }) => {
  return <Provider value={contextValue}>{children}</Provider>;
};

const mapStateToProps = () =>
  createSelector(getCurrentVenueCurrencyISOCode, getCurrentVenueCurrencySymbol, (currencyCode, currencySymbol) => {
    const contextValue = {
      currencyCode,
      currencySymbol,
    };

    return {
      contextValue,
    };
  });

export default connect(mapStateToProps)(GlobalsProvider);
