/* eslint-disable no-restricted-imports */
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeResponseToJSON } from 'API/http.utils';
import {
  CancellationProtectionConfirmPathParams,
  CancellationProtectionConfirmPayload,
  CancellationProtectionConfirmQuery,
  CancellationProtectionConfirmResponse,
} from 'API/types';

export const CANCELLATION_PROTECTION_CONFIRM =
  ({
    variables: { booking_token, stripe_setup_intent_confirmed, secret_token, venue_id },
  }: V<
    CancellationProtectionConfirmPayload & CancellationProtectionConfirmQuery & CancellationProtectionConfirmPathParams
  >) =>
  (): Promise<CancellationProtectionConfirmResponse> =>
    Http()
      .post<
        CancellationProtectionConfirmResponse,
        CancellationProtectionConfirmQuery,
        CancellationProtectionConfirmPayload
      >(ENDPOINT.CANCELLATION_PROTECTIONS._CONFIRM(venue_id))({
        body: { booking_token, stripe_setup_intent_confirmed, secret_token },
      })
      .then(maybeResponseToJSON);
