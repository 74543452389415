import { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connectFormElement, getErrorByName } from '@uala/react-forms';

import { DayPickerSingleDateController } from 'react-dates';
import { DefaultSettings, INPUT_INLINE, INPUT_SIZES } from '../../config';
import { StyledDatePickerValue, StyledDatePickerWrapper } from './DatePicker.theme';
import {
  BaseInputWrapper,
  FormControl,
  FormControlInput,
  Label,
  Helper,
  AdornmentWrapper,
  elementPropTypes,
} from '../../shared';
import moment from 'moment';
import { Icon } from 'components/core';

const DatePickerComponent = ({
  name,
  label,
  labelPosition,
  placeholder,
  dateLabelFormat,
  dateValueFormat,
  emitChange,
  emitDidChange,
  values,
  errors,
  helper,
  helperPosition,
  intl,
  size,
  tooltip,
  withInlineValidation,
  extraAdornment: { prefix, suffix },
}) => {
  const fieldError = getErrorByName(errors, name);
  helper = (withInlineValidation && fieldError) || helper || null;
  const error = !!fieldError;
  const withPrefix = typeof prefix !== 'undefined';
  const withSuffix = typeof suffix !== 'undefined';

  const value = values[name];

  const date = useMemo(() => {
    return moment(value || {});
  }, [value]);

  const textValue = value ? date.format(dateLabelFormat) : placeholder;

  const handleChange = (name, date) => {
    emitChange(name, date.format(dateValueFormat));
  };

  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef();

  return (
    <FormControl labelPosition={labelPosition} className="DatePicker">
      {label && (
        <Label htmlFor={name} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput labelPosition={labelPosition}>
        <BaseInputWrapper
          ref={inputRef}
          error={error}
          size={size}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {withPrefix && <AdornmentWrapper paddingRight="12px">{prefix}</AdornmentWrapper>}
          <StyledDatePickerValue>{textValue}</StyledDatePickerValue>
          <Icon type="select" color="black" size={11} />
          {withSuffix && <AdornmentWrapper paddingLeft="12px">{suffix}</AdornmentWrapper>}
        </BaseInputWrapper>
        {helper && <Helper position={helperPosition} tooltip={tooltip} error={error} text={helper} intl={intl} />}
        {isOpen && (
          <StyledDatePickerWrapper>
            <DayPickerSingleDateController
              hideKeyboardShortcutsPanel
              numberOfMonths={1}
              date={date}
              focused
              onDateChange={(date) => {
                handleChange(name, date);
                setIsOpen(false);
              }}
              onOutsideClick={(e) => {
                /**
                 * Be sure click is not on the field, to avoid double `setIsOpen` execution
                 */
                if (!inputRef.current || !inputRef.current.contains(e.target)) {
                  setIsOpen(false);
                }
              }}
            />
          </StyledDatePickerWrapper>
        )}
      </FormControlInput>
    </FormControl>
  );
};

const DatePicker = connectFormElement(DatePickerComponent);

DatePicker.displayName = 'DatePicker';

DatePicker.propTypes = {
  ...elementPropTypes,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  dateValueFormat: PropTypes.string,
  dateLabelFormat: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  withInlineValidation: PropTypes.bool,
  size: PropTypes.oneOf(INPUT_SIZES),
  extraAdornment: PropTypes.shape({
    prefix: PropTypes.node,
    suffix: PropTypes.node,
  }),
};

DatePicker.defaultProps = {
  value: '',
  autoComplete: DefaultSettings.AUTOCOMPLETE,
  required: false,
  placeholder: '',
  dateValueFormat: 'YYYY-MM-DD',
  dateLabelFormat: 'l',
  labelPosition: INPUT_INLINE,
  size: DefaultSettings.INPUT_SIZE,
  withInlineValidation: true,
  intl: true,
  extraAdornment: {
    prefix: undefined,
    suffix: undefined,
  },
};

export default DatePicker;
