import { createSelector } from 'reselect';
import {
  Venue,
  VENUE_ACTIVE_FEATURE_FLAG,
  VENUE_REACHED_LIMIT,
  Country,
  availableCurrencies,
  CurrencyISOCodes,
  DefaultDomain,
  COUNTRY_FEATURE_FLAG,
  CountryCodes,
  PRINTER_TYPE,
  PrinterType,
  DAY_PICKER_VARIANTS,
  WHITELABEL,
} from 'models';
import { State } from 'reducers/types';
import { Session } from 'reducers/sessions';
import { getCountries } from './countries/getCountries';
import { isAccountActive, isAccountInBlockPeriod, isAccountInGracePeriod } from 'utils/selfprovisioning';
import { isFiscalPrinter } from 'utils/fiscal-printer/fiscal-printers.utils';
import { isFrenchFiscalityCompliant, isGermanFiscalityCompliant, isPortugueseFiscalityCompliant } from 'utils/country';
import { applyOverrideParamsToVenue } from 'utils/venue/applyOverrideParamsToVenue';
import { ValueOf } from 'utils/utilityTypes';

const getSessionsCurrentVenue = (state: State): Venue | null => state?.sessions?.currentVenue;

export const getCurrentVenue = createSelector(getSessionsCurrentVenue, (currentVenue) => {
  return applyOverrideParamsToVenue(currentVenue);
});

export type StateWithCurrentVenueId = {
  sessions?: {
    currentVenueId?: State['sessions']['currentVenueId'];
  };
};
export const getCurrentVenueId = (state: StateWithCurrentVenueId): Venue['id'] =>
  state?.sessions?.currentVenueId || null;

export const getCurrentVenueUpdatedAt = (state: State): Venue['updated_at'] | null =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.updated_at) || null;

export const getCurrentVenueCountryId = (state: State): Venue['country_id'] =>
  state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.country_id;

export const getVenueLocale = (state: State): Venue['locale'] =>
  state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.locale;

export const getCurrentVenueGracePeriodDismissByVenue = (
  state: State
): Session['gracePeriodBannerDismissDateByVenue'] | null =>
  (state.sessions && state.sessions.gracePeriodBannerDismissDateByVenue) || null;

export const getCurrentVenueGracePeriodDismiss = createSelector(
  getCurrentVenueId,
  getCurrentVenueGracePeriodDismissByVenue,
  (
    currentVenueId: Venue['id'],
    currentVenueGracePeriodDismissByVenue: Session['gracePeriodBannerDismissDateByVenue'] | null
  ): number | null =>
    (currentVenueGracePeriodDismissByVenue &&
      currentVenueId &&
      currentVenueGracePeriodDismissByVenue[currentVenueId]) ||
    null
);

export const getCurrentVenueBillingDetails = (state: State): Venue['billing_details'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.billing_details) || null;

export const isCurrentVenueActive = createSelector(getCurrentVenue, (venue: Venue | null): boolean =>
  isAccountActive(venue)
);

export const isCurrentVenueInBlockPeriod = createSelector(getCurrentVenue, (venue: Venue | null): boolean =>
  isAccountInBlockPeriod(venue)
);

export const isCurrentVenueInGracePeriod = createSelector(getCurrentVenue, (venue: Venue | null): boolean =>
  isAccountInGracePeriod(venue)
);

export const getCurrentVenueCountry = createSelector(
  [getCountries, getCurrentVenueCountryId],
  (items: Country[], countryId: Venue['country_id']): Country | null =>
    items.find((item) => item.id === countryId) || null
);

export const getCurrentVenueCountryDomainUrl = createSelector(
  [getCurrentVenueCountry],
  (country: Country | null): DefaultDomain['url'] | string =>
    country && country.default_domain ? country.default_domain.url : 'uala.it'
);

export const getCurrentVenueLocale = (state: State): string =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.locale) || 'it';

export const getCurrentVenueTimeSlot = (state: State): number =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.time_slot) || 900;

export const getCurrentVenueUpdatedSince = (state: State): Session['updated_since'] =>
  (state.sessions && state.sessions.updated_since) || new Date().toISOString();

export const getUpdatedSinceByVenue = (state: State): Session['updatedSinceByVenue'] =>
  (state.sessions && state.sessions.updatedSinceByVenue) || null;

export const getCurrentVenueActiveCashRegister = (state: State): Venue['active_cash_register'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.active_cash_register) || false;

export const getCurrentVenueMainPrinterAddress = (state: State): Venue['main_printer_ip_address'] =>
  getCurrentVenue(state)?.main_printer_ip_address || null;

export const getCurrentVenueMainPrinterType = (state: State): PrinterType | null | undefined =>
  getCurrentVenue(state)?.main_printer_type || null;

export const getCurrentVenueCurrencyISOCode = (state: State): CurrencyISOCodes =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.currency_iso_code) ||
  CurrencyISOCodes.EUR;

export const getCurrentVenueCurrencySymbol = createSelector(getCurrentVenueCurrencyISOCode, (currencyISOCode) => {
  const currency = availableCurrencies.find((a) => a.currencyCode === currencyISOCode) || availableCurrencies[0];

  return currency.currencySymbol;
});

export const isCurrentVenuePrinterFiscal = (state: State): boolean =>
  (state.sessions?.currentVenue && isFiscalPrinter(state.sessions.currentVenue.main_printer_type)) || false;

export const isCurrentVenuePortugueseFinancialAuthorityCompliant = createSelector(getCurrentVenueCountry, (country) => {
  return isPortugueseFiscalityCompliant(country);
});

export const isCurrentVenueNF525Compliant = createSelector(getCurrentVenueCountry, (country) => {
  return isFrenchFiscalityCompliant(country);
});

export const isCurrentVenueGermanFiscalityCompliant = createSelector(getCurrentVenueCountry, (country) => {
  return isGermanFiscalityCompliant(country);
});

export const isCurrentVenueDebtEnabled = createSelector(getCurrentVenueCountry, getCurrentVenue, (country, venue) => {
  // Greek Receipts
  if (country?.code === CountryCodes.GR && venue?.main_printer_type === PRINTER_TYPE.FISCAL_GR) {
    return false;
  }
  return true;
});

export const isMultipleCardPaymentMethodsEnabled = createSelector(
  isCurrentVenuePortugueseFinancialAuthorityCompliant,
  (isPortugueseFiscalityCompilant) => {
    if (isPortugueseFiscalityCompilant) {
      return true;
    }
    return false;
  }
);

export const isCancellationCountersSeparated = (state: State): Country['separated_cancellation_counters'] =>
  (state.sessions &&
    state.sessions.currentVenue &&
    state.sessions.currentVenue.country &&
    state.sessions.currentVenue.country.separated_cancellation_counters) ||
  false;

export const isCheckoutReprintEnabled = (state: State): Country['checkout_reprint_enabled'] =>
  (state.sessions &&
    state.sessions.currentVenue &&
    state.sessions.currentVenue.country &&
    state.sessions.currentVenue.country.checkout_reprint_enabled) ||
  false;

export const getCurrentVenueCountrySegmentKey = (state: State): string | null => {
  const venue = (state.sessions && state.sessions.currentVenue) || null;
  if (!venue) {
    return null;
  }

  if (venue.venue_groups) {
    const venueGroupKey = venue.venue_groups.map((g) => g.b2b_event_tracking_key).filter((g) => g)[0];

    if (venueGroupKey) {
      return venueGroupKey;
    }
  }

  if (venue.country && venue.country.b2b_event_tracking_key) {
    return venue.country.b2b_event_tracking_key;
  }

  return null;
};

export const getCurrentVenueSecondaryPrinterAddress = (state: State): Venue['secondary_printer_ip_address'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.secondary_printer_ip_address) || null;

export const getCurrentVenueActiveWarehouse = (state: State): Venue['active_warehouse'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.active_warehouse) || false;

export const getCurrentVenueActiveMQTT = (state: State): Venue['active_mqtt'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.active_mqtt) || false;

export const getCurrentVenueActivePromotions = (state: State): Venue['active_promotions'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.active_promotions) || false;

export const getCurrentVenueCanPrintReceipt = (state: State): Venue['can_print_receipt'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.can_print_receipt) || false;

export const getCurrentVenueCanUseWidget = (state: State): Venue['can_print_receipt'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.can_use_widget) || false;

export const getCurrentVenueCanEmailReceipt = (state: State): Venue['can_email_receipt'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.can_email_receipt) || false;

export const getCurrentVenueCanByPassPrint = (state: State): Venue['can_bypass_print'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.can_bypass_print) || false;

export const getCurrentVenueActiveCustomerPrivacyManagement = (
  state: State
): Venue['active_customer_privacy_management'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.active_customer_privacy_management) ||
  false;

export const getCurrentVenueTreatmentResponsibleNominatedAt = (
  state: State
): Venue['treatment_responsible_nominated_at'] =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.treatment_responsible_nominated_at) ||
  null;

export const getCurrentVenueTreatmentResponsibleNominatedDeadline = (
  state: State
): Venue['treatment_responsible_nominated_deadline'] =>
  (state.sessions &&
    state.sessions.currentVenue &&
    state.sessions.currentVenue.treatment_responsible_nominated_deadline) ||
  null;

export const getCurrentVenueFiscalDayRecapEmail = (state: State): string | null =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.fiscal_day_recap_email) || null;

export const getCurrentVenueMaxDurationValue = (state: State): number | null =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.max_duration_value) || 3600;

export const getCurrentVenueMaxPriceValue = (state: State): number | null =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.max_price_value) || 2000;

export const getCurrentVenueVatPercentage = (state: State): number | null =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.vat) || 0;

export const getCurrentVenueVatId = (state: State): number | null =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.vat_id) || null;

export const getCurrentVenueSkipVatValidation = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.skip_vat_validation_on_invoice) ||
  false;

export const getCurrentVenueElectronicInvoicingEnabled = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.electronic_invoicing_enabled) || false;

export const getCurrentVenueCustomersFirstLastName = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.customers_first_last_name) || false;

export const getCurrentVenueUnlockPin = (state: State): string | null =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.unlock_pin) || null;

export const getCurrentVenueCubeMacAddress = (state: State): string | null =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.cube_mac_address) || null;

export const getCurrentVenueDetailedMonitoringEnabled = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.detailed_monitoring_enabled) || false;

export const getCurrentVenueFeatureRoomStaffMember = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.feature_room_staff_member) || false;

export const getCurrentVenueFeatureMultiBooking = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.feature_multi_booking) || false;

export const getCurrentVenueShowBundleName = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.show_bundle_name) || false;

export const getCurrentVenueMassCheckInAllowed = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.mass_checkin_allowed) || false;

export const getCurrentVenueMassCheckOutAllowed = (state: State): boolean =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.mass_checkout_allowed) || false;

export type CheckCurrentVenueActiveFlag = (venue_active_flag: VENUE_ACTIVE_FEATURE_FLAG) => boolean;

// FIXME: dome find a way to memo these values
export const getCheckCurrentVenueActiveFlag =
  (state: State): CheckCurrentVenueActiveFlag =>
  (venue_active_flag): boolean =>
    Boolean(state.sessions && state.sessions.currentVenue && state.sessions.currentVenue[venue_active_flag]);

export type CheckCurrentVenueReachedLimits = (reached_limit: VENUE_REACHED_LIMIT) => boolean;

export const getCurrentVenueHygienicActivitiesDescription = (state: State): string =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue.hygienic_activities_description) || '';

export const getCurrentVenueCountrySelfProvisioningEnabled = createSelector(
  [getCurrentVenueCountry],
  (country: Country | null): boolean => (country && country[COUNTRY_FEATURE_FLAG.SELF_PROVISIONING_ENABLED]) || false
);

export const getCurrentVenueCalendarMode = (state: State): string =>
  (state.sessions && state.sessions.currentVenue && state.sessions.currentVenue?.ui_settings?.calendar_mode) ||
  DAY_PICKER_VARIANTS.SWIPE;

export const getCurrentVenueCanViewPettyCash = createSelector([getCurrentVenue], (currentVenue) =>
  Boolean(currentVenue?.petty_cash_enabled)
);

type WhiteLabel = ValueOf<typeof WHITELABEL>;
export const getCurrentVenueWhiteLabel: (state: State) => WhiteLabel | undefined = createSelector(
  [getCurrentVenue],
  (currentVenue) => currentVenue?.whitelabel as WhiteLabel
);

export const isATCUDRequiredForCurrentVenue = createSelector(
  [isCurrentVenuePortugueseFinancialAuthorityCompliant],
  (isVenuePortugueseFiscalityCompliant) => Boolean(isVenuePortugueseFiscalityCompliant)
);

export const getCurrentVenueATCUD = createSelector([getCurrentVenue], (currentVenue) => currentVenue?.venue_atcud_code);

export const getCurrentVenueCountryCode = createSelector([getCurrentVenueCountry], (country) => country?.code);

export const getCurrentVenueCountryReceiptCancellationMode = createSelector(
  [getCurrentVenueCountry],
  (country) => country?.checkout_cancellation_mode
);

export const getCurrentVenueShowVatOnLineItems = createSelector(
  [getCurrentVenue],
  (currentVenue) => currentVenue?.show_vat_on_products_and_services
);

export const isCurrentVenueItalianFinancialAuthorityCompliant = createSelector(getCurrentVenueCountry, (country) => {
  return country?.code === CountryCodes.IT;
});
