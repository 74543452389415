import { styled, css } from 'styles';
import Field, { fieldSizes } from 'components/core/Field/theme';
import { withTheme } from '../Context';
import Icon from '../Icon';
import Scrollable from '../Scrollable';

const StyledField = withTheme(
  styled(Field)(
    ({ theme: { colors, sizes }, shape, queryFilter, open }) => css`
      position: relative;
      background-color: ${colors.bgWhite};

      ${shape === 'card' &&
      `
        border-radius: ${sizes.borderRadiusMedium}px;
      `};

      ${open &&
      queryFilter &&
      `
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `};
    `
  )
);

StyledField.Label = styled('div')`
  display: ${({ open }) => (open ? 'none' : 'flex')};
  height: 100%;
  margin-left: 10px;
  margin-right: 30px;
  align-items: center;

  .Text {
    height: 20px;
    line-height: 20px;
  }
`;

StyledField.QueryFilter = withTheme(
  styled('div')(
    ({ theme: { utils }, open }) => `
      display: ${open ? 'flex' : 'none'};
      height: 100%;
      margin-left: 10px;
      margin-right: 10px;
      align-items: center;

      input {
        display: block;
        flex: 1;
        margin: 0;
        padding: 0;
        border: 0;
        appearance: none;
        outline: none;
        background-color: transparent;
        height: 20px;
        line-height: 20px;
        font-family: ${utils.fontFamily};
        font-size: 16px;

        &::-ms-clear {
          display: none;
        }
      }
    `
  )
);

StyledField.Icon = styled(Icon)`
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  pointer-events: none;
  display: ${({ open }) => (open ? 'none' : 'block')};
`;

StyledField.List = withTheme(
  styled(Scrollable)(({ theme: { colors, boxShadow, sizes }, size = 'default', shape, open, queryfilter }) => {
    const borderRadius = fieldSizes.innerHeight[size]
      ? shape === 'card' || open
        ? sizes.borderRadiusMedium
        : fieldSizes.innerHeight[size]() / 2
      : 0;

    return css`
      position: absolute;
      top: ${queryfilter ? '100%' : '-1px'};
      right: -1px;
      width: calc(100% + 2px);
      min-width: 150px;
      z-index: 1;
      display: ${open ? 'block' : 'none'};
      min-height: 100px;
      max-height: ${180}px;
      border: 1px solid ${colors.inputBorder};

      border-radius: ${borderRadius}px;
      ${queryfilter &&
      `
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      `};

      background-color: #fff;
      overflow-y: auto;
      box-shadow: ${boxShadow.block};

      > .FieldSelectMulti__option:first-of-type {
        border-radius: ${borderRadius}px ${borderRadius}px 0 0;
      }
      > .FieldSelectMulti__option:last-child {
        border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
      }
    `;
  })
);

StyledField.Option = withTheme(
  styled('div')(
    ({ theme: { colors }, level = 0 }) => css`
      padding: 8px 12px;
      padding-left: ${12 + level * 12}px;
      line-height: 20px;
      cursor: pointer;

      &:hover {
        background: ${colors.bgLightGray};
      }
    `
  )
);

export default StyledField;
