import { Component, ReactNode, CSSProperties } from 'react';
import moize from 'moize';
import { Icon as Styled } from './theme';

export interface IconProps {
  type: string | null;
  size: number | string;
  flex?: number | string;
  children?: ReactNode;
  targetSize?: number;
  fillMode?: 'outline' | 'filled';
  color?: string | null;
  className?: string;
  noResize?: boolean;
  padding?: string | number;
  marginLeft?: string | number | boolean;
  marginRight?: string | number | boolean;
  margin?: number;
  style?: CSSProperties;
  dataTestId?: string;
  productTourId?: string;
  onForceClick?: () => void;
  onClick?: (e: MouseEvent) => void;

  // ARIA WAI
  role?: string;
  title?: string;
}

interface IconState {
  IconSvg: (() => JSX.Element) | null;
  iconType: string | null;
}

class Icon extends Component<IconProps, IconState> {
  static defaultProps: Partial<IconProps> = {
    noResize: false,
  };

  state: IconState = {
    IconSvg: null,
    iconType: null,
  };

  private __unmounted = false;
  private __force_click_timer: NodeJS.Timeout | null = null;
  private __click_enabled = false;

  loadIcon(): void {
    if (!this.props.type || this.props.type === this.state.iconType) return;

    import(`svg/${this.props.type}`)
      .then((IconSvg) => {
        if (!this.__unmounted && IconSvg.default) {
          this.setState({
            iconType: this.props.type,
            IconSvg: moize.react(IconSvg.default, { maxSize: 3 }),
          });
        }
      })
      .catch((e) => console.log('error', e));
  }

  componentDidMount(): void {
    this.loadIcon();
  }

  componentWillUnmount(): void {
    this.__unmounted = true;
    this.resetForceClickTimeout();
  }

  componentDidUpdate(_prevProps: IconProps, _prevState: IconState): void {
    this.loadIcon();
  }

  resetForceClickTimeout = (): void => {
    if (this.__force_click_timer) clearTimeout(this.__force_click_timer);
    this.__force_click_timer = null;
  };

  onTouchStart = (): void => {
    this.__click_enabled = true;
    this.resetForceClickTimeout();

    if (this.props.onForceClick) {
      this.__force_click_timer = setTimeout(() => {
        this.__click_enabled = false;
        if (this.props.onForceClick) this.props.onForceClick();
      }, 2000);
    }
  };

  onTouchEnd = (): void => {
    this.resetForceClickTimeout();
  };

  onMouseDown = (e: MouseEvent): void => {
    if (e.button !== 0 || this.__force_click_timer) return;

    this.__click_enabled = true;
    this.resetForceClickTimeout();

    if (this.props.onForceClick) {
      this.__force_click_timer = setTimeout(() => {
        this.__click_enabled = false;
        if (this.props.onForceClick) this.props.onForceClick();
      }, 2000);
    }
  };

  onMouseUp = (): void => {
    this.resetForceClickTimeout();
  };

  onClick = (e: MouseEvent): void => {
    if (this.__click_enabled && this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render(): JSX.Element {
    const { IconSvg } = this.state;
    const { children, onClick, onForceClick, dataTestId, productTourId, className, ...props } = this.props;
    const { onMouseDown, onMouseUp, onTouchStart, onTouchEnd } = this;

    const forceTouchListeners = {
      onMouseDown,
      onMouseUp,
      onTouchStart,
      onTouchEnd,
    };

    return (
      <Styled
        className={className ?? 'Icon'}
        data-testid={dataTestId}
        data-product-tour-id={productTourId}
        {...props}
        onClick={onClick ? this.onClick : null}
        {...forceTouchListeners}
      >
        {IconSvg && <IconSvg />}
        {children}
      </Styled>
    );
  }
}

export default Icon;
