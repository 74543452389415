import { Box, Icon, Text } from 'components/core';
import { MobilePasswordsManagerProps, PasswordManagerCredential } from './types';
import { MobilePasswordsManagerCredentials } from './MobilePasswordsManagerCredentials';
import { useState } from 'react';
import { MobilePasswordsManagerEditCredential } from './MobilePasswordManagerEditCredential';
import { MobilePasswordManagerWrapper } from './MobilePasswordManager.theme';
import { ValueOf } from 'utils/utilityTypes';

export const MOBILE_PASSWORD_MANAGER_STEPS = {
  showCredentials: 'show_credentials',
  managePasswords: 'manage_passwords',
} as const;

type MobilePasswordsManagerContext = Pick<
  MobilePasswordsManagerProps,
  'credentials' | 'onClickCredential' | 'onDeleteCredential'
> & {
  goToManagePasswords: () => void;
  handleUpdateCredential: (credential: PasswordManagerCredential) => Promise<void>;
};

const StepsComponents = {
  [MOBILE_PASSWORD_MANAGER_STEPS.showCredentials]: (context: MobilePasswordsManagerContext): JSX.Element => (
    <MobilePasswordsManagerCredentials
      credentials={context.credentials}
      onClickCredential={context.onClickCredential}
      onManagePasswordsClick={context.goToManagePasswords}
    />
  ),
  [MOBILE_PASSWORD_MANAGER_STEPS.managePasswords]: (context: MobilePasswordsManagerContext): JSX.Element => (
    <MobilePasswordsManagerEditCredential
      credentials={context.credentials}
      onUpdateCredential={context.handleUpdateCredential}
      onDeleteCredential={context.onDeleteCredential}
    />
  ),
};

export const MobilePasswordsManager = ({
  onClose,
  credentials,
  onClickCredential,
  onUpdateCredential,
  onDeleteCredential,
}: MobilePasswordsManagerProps): JSX.Element => {
  // This component has two steps, showCredentials and managePasswords
  const [step, setStep] = useState<ValueOf<typeof MOBILE_PASSWORD_MANAGER_STEPS>>(
    MOBILE_PASSWORD_MANAGER_STEPS.showCredentials
  );

  const isEditingCredentials = step === MOBILE_PASSWORD_MANAGER_STEPS.managePasswords;

  const handleUpdateCredential = async (credential: PasswordManagerCredential): Promise<void> => {
    await onUpdateCredential(credential);
    setStep(MOBILE_PASSWORD_MANAGER_STEPS.showCredentials);
  };

  const goToManagePasswords = (): void => setStep(MOBILE_PASSWORD_MANAGER_STEPS.managePasswords);
  const goToShowCredentials = (): void => setStep(MOBILE_PASSWORD_MANAGER_STEPS.showCredentials);

  const context = {
    credentials,
    onClickCredential,
    goToManagePasswords,
    handleUpdateCredential,
    onDeleteCredential,
  };

  const CurrentStep = StepsComponents[step] ?? <></>;

  return (
    <MobilePasswordManagerWrapper onClose={onClose} data-testid="mobile_password_manager">
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Box visibility={isEditingCredentials ? 'visible' : 'hidden'}>
          <Icon type="arrow-left" size={14} color="blue" onClick={goToShowCredentials} />
        </Box>
        <Text
          size={14}
          uppercase
          bold
          intl={
            isEditingCredentials
              ? 'signin.mobile_password_manager.edit_credentials'
              : 'signin.mobile_password_manager.login_as'
          }
          textAlign="center"
        />
        <Icon type="close" size={14} color="gray10" onClick={onClose} />
      </Box>
      <CurrentStep {...context} />
    </MobilePasswordManagerWrapper>
  );
};
