import { Box, Button, Text } from 'components/core';
import { PASSWORD_STORER_MODES } from 'utils/hooks/usePasswordManager';
import { ValueOf } from 'utils/utilityTypes';
import { MobilePasswordManagerWrapper } from './MobilePasswordManager.theme';
import { MobilePasswordManagerForm } from './MobilePasswordManagerForm';
import { PasswordManagerCredential } from './types';

export type MobilePasswordManagerStorePasswordProps = {
  credentials: PasswordManagerCredential;
  onCancel: () => void;
  passwordStorerMode: ValueOf<typeof PASSWORD_STORER_MODES>;
  onSaveCredential: (credential: PasswordManagerCredential) => void;
};

export const MobilePasswordManagerStorePassword = ({
  credentials,
  onCancel,
  passwordStorerMode,
  onSaveCredential,
}: MobilePasswordManagerStorePasswordProps): JSX.Element => {
  const isNewPassword = passwordStorerMode === PASSWORD_STORER_MODES.newPassword;

  return (
    <MobilePasswordManagerWrapper onClose={onCancel}>
      <Box display="flex" width="100%" justifyContent="center">
        <Text
          size={14}
          uppercase
          bold
          intl={
            isNewPassword
              ? 'signin.mobile_password_manager.save_password'
              : 'signin.mobile_password_manager.update_password'
          }
          textAlign="center"
        />
      </Box>
      <MobilePasswordManagerForm
        credentials={credentials}
        onSaveCredential={onSaveCredential}
        isNewPassword={isNewPassword}
      />
      <Box display="flex" flexDirection="column" width="100%" justifyContent="center">
        <Button unstyled onClick={onCancel} dataTestId="mobile_password_manager_not_now_button">
          <Text uppercase color="blue" bold block intl="signin.mobile_password_manager.not_now" />
        </Button>
      </Box>
    </MobilePasswordManagerWrapper>
  );
};
