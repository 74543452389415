// eslint-disable-next-line no-restricted-imports
import { BaseProps, css, styled, Text } from '@uala/ui-core';
import { treatwellProTheme } from '@uala/ui-treatwell-pro';
import { Input } from 'ui-treatwell-pro/input';

const ButtonReset = `
  border: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  background-color: transparent;
`;

const {
  colors,
  typography: { fontSizes, fontFamilies },
} = treatwellProTheme;

const InputSelect = styled(Input)(
  () => css`
    ${ButtonReset}
    position: relative;
    margin: 0;
    border: 1px solid ${colors['navy.400']};
    border-radius: 8px;
    background-color: ${colors['white']};
    color: ${colors['navy.500']};
    outline-offset: 2px;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    appearance: none;
    text-align: left;
    height: 48px;
    width: 100%;
    padding: 12px 16px;
    font-size: ${fontSizes.body_md}px;
    font-family: ${fontFamilies.default};
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 0.2s;
    cursor: pointer;

    &[aria-readonly='true'] {
      box-shadow: none !important;
      cursor: auto;
    }

    &[aria-invalid='true'] {
      border-color: ${colors['coral.600']};
      box-shadow: 0 2px 10px 0 ${colors['navy.300']};
    }

    &:focus:not([aria-readonly='true']) {
      border-color: ${colors['blue.600']};
      box-shadow: 0 2px 10px 0 ${colors['navy.300']};
    }

    &:disabled {
      background-color: ${colors['navy.100']};
      border-color: ${colors['navy.100']};
      opacity: 1;
      cursor: not-allowed;
    }

    &:disabled ~ span {
      background-color: transparent;
    }

    .left-icon ~ & {
      padding-left: 40px;
    }

    .right-icon ~ & {
      padding-right: 40px;
    }
  `
);
const Select = styled('button')(
  () => css`
    /* hidden  FIXME */
    opacity: 0;
    ${ButtonReset}
    position: relative;
    margin: 0;
    border: 1px solid ${colors['navy.400']};
    border-radius: 8px;
    background-color: ${colors['white']};
    color: ${colors['navy.500']};
    outline-offset: 2px;
    outline: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    appearance: none;
    text-align: left;
    height: 48px;
    width: 100%;
    padding: 12px 16px;
    font-size: ${fontSizes.body_md}px;
    font-family: ${fontFamilies.default};
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 0.2s;
    cursor: pointer;

    &[aria-readonly='true'] {
      box-shadow: none !important;
      cursor: auto;
    }

    &[aria-invalid='true'] {
      border-color: ${colors['coral.600']};
      box-shadow: 0 2px 10px 0 ${colors['navy.300']};
    }

    &:focus:not([aria-readonly='true']) {
      border-color: ${colors['blue.600']};
      box-shadow: 0 2px 10px 0 ${colors['navy.300']};
    }

    &:disabled {
      background-color: ${colors['navy.100']};
      border-color: ${colors['navy.100']};
      opacity: 1;
      cursor: not-allowed;
    }

    &:disabled ~ span {
      background-color: transparent;
    }

    .left-icon ~ & {
      padding-left: 40px;
    }

    .right-icon ~ & {
      padding-right: 40px;
    }
  `
);

const SelectLabel = styled('span')<{ isDisabled?: boolean }>(
  ({ isDisabled }) => css`
    color: ${isDisabled ? colors['navy.500'] : colors['blue.600']};
    background-color: ${isDisabled ? colors['navy.300'] : colors['purple.100']};
    font-weight: 700;
    font-size: 10px;
    border-radius: 50%;
    text-align: center;
    min-width: 22px;
    padding: 4px 6px;
    line-height: 130%;
  `
);

const Label = styled(Text)<{ isDisabled: boolean }>(
  ({ isDisabled }) => css`
    border: none;
    background-color: ${!isDisabled ? colors['white'] : colors['navy.100']};
    color: ${isDisabled ? colors['navy.500'] : colors['navy.800']};
    font-weight: 400;
    font-size: ${fontSizes.body_md}px;
    font-family: ${fontFamilies.default};
    left: 8px;
    z-index: 5;
    width: fit-content;
    height: fit-content;
    padding: 0 8px;
    pointer-events: none;

    button:focus:not([aria-readonly='true']) ~ & {
      color: ${colors['blue.600']};
    }
  `
);

const SelectIcon = styled('div')<Pick<BaseProps, 'theme'> & { readonly placement: 'left' | 'right' }>(
  ({ placement }) => css`
    ${placement}: 0;
    width: 48px;
    height: 48px;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    z-index: 2;
    pointer-events: none;
    color: ${colors['navy.500']};
    overflow: hidden;
    opacity: 0.5;
  `
);

const ErrorMessage = styled('span')(
  () => css`
    color: ${colors['coral.600']};
    font-size: ${fontSizes.body_xs}px;
    font-family: ${fontFamilies.default};
    align-self: flex-start;
    margin-left: 16px;
  `
);

const SelectGroup = styled('div')<{ readonly width: string | number | undefined }>(
  ({ width }) => css`
    width: ${width ? `${width}px` : '100%'};
    display: flex;
    position: relative;

    /* This fix default sanitize code that adds :before content in lists.  */

    *::before {
      content: none;
    }
  `
);

const SelectDropdown = styled('div')<
  Pick<BaseProps, 'theme'> & {
    readonly width: string | number | undefined;
    readonly position?: 'top' | 'bottom';
    readonly maxHeight?: number;
  }
>(
  ({ position, maxHeight }) => css`
    --_dropdown-margin: 8px;
    --_bottom-shadow: 0 10px 10px rgba(230, 232, 237, 0.5);
    position: absolute;
    top: ${position === 'bottom' ? '100%' : 'auto'};
    bottom: ${position === 'top' ? '100%' : 'auto'};
    left: var(--_dropdown-margin);
    z-index: 10;
    width: calc(100% - calc(var(--_dropdown-margin) * 2));
    max-height: ${`${maxHeight}px`};
    margin-top: 4px;
    margin-bottom: 16px;
    border-radius: 8px;
    overflow: auto;
    font-size: ${fontSizes.body_md}px;
    font-family: ${fontFamilies.default};
    box-shadow: var(--_bottom-shadow);
    visibility: visible;
    opacity: 1;
    transition:
      visibility 0s,
      opacity 0.15s;

    .select__list {
      overflow: scroll;
      list-style: none;
      margin: 0;
      padding: 4px 0;

      li {
        position: relative;
        padding-right: 51px;
        background-color: ${colors['white']};

        &[aria-selected='true'] {
          color: ${colors['black']};
          background-color: ${colors['navy.200']};
        }
      }
    }
  `
);

const SelectOption = styled('button')(css`
  ${ButtonReset}
  display: block;
  align-items: center;
  position: relative;
  width: 100%;
  height: 51px;
  padding: 12px 16px;
  appearance: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  transition: 0.15s;
`);

export { Select, SelectLabel, Label, SelectGroup, SelectIcon, ErrorMessage, SelectDropdown, SelectOption, InputSelect };
