import { styled, css, Colors } from 'styles';

import {
  INPUT_INLINE,
  INPUT_STACKED,
  INPUT_SIZE_SMALL,
  INPUT_SIZE_MEDIUM,
  INPUT_BLOCK,
  // LABEL_POSITION,
} from '../config';

const borderRadius = {
  [INPUT_SIZE_SMALL]: '16px',
  [INPUT_SIZE_MEDIUM]: '21px',
};

const selectOptions = {
  [INPUT_SIZE_SMALL]: {
    padding: '6px 0px 11px 11px',
    offsetTop: '-7px',
  },
  [INPUT_SIZE_MEDIUM]: {
    padding: '11px 0px 11px 11px',
    offsetTop: '-12px',
  },
};

const inputPadding = {
  [INPUT_SIZE_SMALL]: '5.5px 12px',
  [INPUT_SIZE_MEDIUM]: '10.5px  11px',
};

interface BaseInputWrapperProps {
  size: 'sm' | 'md';
  error?: boolean;
  borderStyle?: 'rounded' | 'none';
  background?: Colors;
}

const BaseInputWrapper = styled('div')<BaseInputWrapperProps>`
  position: relative;
  padding: ${({ size }): string => inputPadding[size]};
  ${({ borderStyle, theme, error }) =>
    borderStyle !== 'none' ? `border: 1px solid ${error ? theme.colors.red : theme.colors.inputBorder};` : undefined}
  border-radius: ${({ borderStyle, size }) => {
    switch (borderStyle) {
      case 'rounded':
        return '8px';
      case 'none':
        return '0';
      default:
        return borderRadius[size];
    }
  }};
  background-color: ${({ theme, background }): string =>
    background ? theme.colors[background] || background : theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

interface FormControlProps {
  align?: string;
  labelPosition: string;
}

const FormControl = styled('div')<FormControlProps>`
  justify-content: ${({ align }): string => align || 'flex-start'};
  width: 100%;

  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }

  .Label--inline {
    margin-right: 5px;
  }

  display: flex;

  &.TextArea {
    .Label > label {
      justify-content: flex-start;
      margin-top: 12px;
    }
  }

  ${({ labelPosition }) =>
    (labelPosition === INPUT_INLINE || labelPosition === INPUT_BLOCK) &&
    css`
      flex-direction: row;
      align-items: stretch;
    `}

  ${({ labelPosition }) =>
    labelPosition === INPUT_STACKED &&
    css`
      flex-direction: column;
      align-items: flex-start;

      .Label {
        margin-bottom: 9px;
      }
    `}
`;

interface FormControlInputProps {
  inline?: boolean;
  labelPosition: string;
}

const FormControlInput = styled('div')<FormControlInputProps>`
  display: flex;
  flex-direction: column;
  ${({ inline }) => !inline && `flex: 1 1 auto;`}

  ${({ labelPosition }) =>
    labelPosition === INPUT_STACKED &&
    css`
      width: 100%;
    `}
`;

interface AdornmentWrapperProps {
  paddingLeft: string;
  paddingRight: string;
}

const AdornmentWrapper = styled('span')<AdornmentWrapperProps>`
  height: 100%;
  ${({ paddingLeft, theme }): { paddingLeft: string; borderLeft?: string } => ({
    paddingLeft,
    borderLeft: paddingLeft ? `1px solid ${theme.colors.inputBorder}` : undefined,
  })};
  ${({ paddingRight, theme }): { paddingRight: string; borderRight?: string } => ({
    paddingRight,
    borderRight: paddingRight ? `1px solid ${theme.colors.inputBorder}` : undefined,
  })};
`;

export { borderRadius, BaseInputWrapper, FormControl, FormControlInput, AdornmentWrapper, selectOptions };
