import { apiGet } from './data_providers/api';
import { actionShouldFetchData } from 'utils/reducersUtils';
import { getCurrentUserToken } from 'selectors/getCurrentUser';

const fetchVacancyTypologiesRequestAction = () => ({
  type: 'VACANCY_TYPOLOGIES_LIST_REQUEST',
});

const fetchVacancyTypologiesResponseAction = ({ error, vacancy_typologies, response }) => ({
  type: 'VACANCY_TYPOLOGIES_LIST_RESPONSE',
  vacancy_typologies,
  response,
  error,
});

export const fetchVacancyTypologies = () => {
  return (dispatch, getState) => {
    const auth = getCurrentUserToken(getState());

    if (!auth) {
      return;
    }

    dispatch(fetchVacancyTypologiesRequestAction());

    // https://docs.uala.it/api/v1/vacancy_typologies
    dispatch(
      apiGet({
        path: `/vacancy_typologies.json`,
        data: {},
        auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(fetchVacancyTypologiesResponseAction({ error: response.info && response.info[0] }));
          }

          dispatch(
            fetchVacancyTypologiesResponseAction({
              vacancy_typologies: response.data.vacancy_typologies,
              response,
            })
          );
        },
        onError(error) {
          dispatch(fetchVacancyTypologiesResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchVacancyTypologiesIfNeeded = () => {
  return (dispatch, getState) => {
    const auth = getCurrentUserToken(getState());

    if (!auth) {
      return;
    }

    if (actionShouldFetchData(getState().vacancyTypologies)) {
      return dispatch(fetchVacancyTypologies());
    }
  };
};
