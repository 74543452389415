import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeDispatchComponent } from 'utils/hoc/DispatchComponent';
import { getCurrentVenue, getCurrentVenueId, getCurrentVenueCustomersFirstLastName } from 'selectors/getCurrentVenue';
import { createSelector } from 'reselect';
import { getCustomersLoading, getCustomersList, getCustomersById } from 'selectors/getCustomers';
// eslint-disable-next-line
import { fetchCustomersIfNeeded, searchCustomer } from 'actions/customers';
import replaceChars from 'utils/replaceChars';
import { Section, Text, Badge } from 'components/core';
import ModelDropdown from 'components/lib/ModelDropdown';
import ModelDropdownRemote from 'components/lib/ModelDropdown/ModelDropdownRemote';
import canPersistCustomers from 'utils/check/canPersistCustomers';
import CustomerDropdownItem from './CustomerDropdownItem';
import { getHighlightedString } from 'utils/string/highlight';

const itemMap = ({ queryPieces = null }, item, index, list) => {
  const { full_name = '', search_full_name = '' } = item;
  const highlightedFullName = getHighlightedString(search_full_name, full_name, queryPieces);
  const isDuplicate = !!list.find((i) => i !== item && i.search_full_name === search_full_name);

  return {
    ...item,
    isDuplicate,
    printLabel: highlightedFullName.map((textChunk, k) => (
      <CustomerDropdownItem
        dataTestid={`customer-${item.id}`}
        highlighted={textChunk.hl}
        key={k}
        text={textChunk.val}
      />
    )),
  };
};

const itemMatch = ({ queryPieces = null }, itemA, itemB) => {
  const { search_full_name = '', search_phone = '' } = itemA;

  return (
    !queryPieces ||
    queryPieces.every((piece) => search_full_name.indexOf(piece) >= 0 || search_phone.indexOf(piece) >= 0)
  );
};

const itemSort = ({ lowerCaseQuery = '' }, itemA, itemB) => {
  const {
    first_name: firstNameA,
    last_name: lastNameA,
    search_first_name: searchFirstNameA,
    search_last_name: searchLastNameA,
  } = itemA;
  const {
    first_name: firstNameB,
    last_name: lastNameB,
    search_first_name: searchFirstNameB,
    search_last_name: searchLastNameB,
  } = itemB;

  const first_nameA_index = (searchFirstNameA || (firstNameA || '').toLowerCase()).indexOf(lowerCaseQuery);
  const last_nameA_index = (searchLastNameA || (lastNameA || '').toLowerCase()).indexOf(lowerCaseQuery);
  const first_nameB_index = (searchFirstNameB || (firstNameB || '').toLowerCase()).indexOf(lowerCaseQuery);
  const last_nameB_index = (searchLastNameB || (lastNameB || '').toLowerCase()).indexOf(lowerCaseQuery);

  if (first_nameB_index === 0) return 1;
  if (first_nameA_index === 0) return -1;
  if (last_nameB_index === 0) return 1;
  if (last_nameA_index === 0) return -1;

  if (first_nameA_index === first_nameB_index) {
    return last_nameA_index < last_nameB_index ? (last_nameA_index === -1 ? 1 : -1) : 1;
  }

  return first_nameA_index < first_nameB_index ? (first_nameA_index === -1 ? 1 : -1) : 1;
};

const renderItemBadge = (item) => {
  const isBlacklisted = Boolean(item.blacklisted_at);
  const isOnline = Boolean(!item.by_venue);

  if (isBlacklisted) {
    return (
      <Section flex="none">
        <Badge size="short" fontSize={7} color="black">
          <Text color="white" uppercase intl="manager.blacklist" />
        </Badge>
      </Section>
    );
  }

  if (isOnline) {
    return (
      <Section flex="none">
        <Badge size="short" fontSize={7} color="gray">
          <Text color="text1" intl="manager.online" uppercase />
        </Badge>
      </Section>
    );
  }

  return null;
};

const itemRenderer = (item) => (
  <Section displayFlex flex="1" justifyContent="space-between" alignItems="center" data-testid={`customer-${item.id}`}>
    <Section flex="1">{item.printLabel}</Section>
    {item.isDuplicate ? (
      <Section flex="none">
        <Text color="text2" size={12}>
          {item.phone}
        </Text>
      </Section>
    ) : (
      renderItemBadge(item)
    )}
  </Section>
);

const queryFilter = replaceChars(" '0123456789");

const getCustomerId = (state, props) => (props && props.customer_id) || null;

const getCustomerName = (state, props) => (props && props.customer_name) || null;

const getPlaceholder = (state, props) => (props && props.placeholder) || null;

const emptyExcludeIds = [];

const getExcludeIds = (state, props) => (props && props.exclude_ids) || emptyExcludeIds;

const getFilteredItemsList = createSelector([getExcludeIds, getCustomersList], (exclude_ids, customersList) => {
  return customersList.filter(({ id }) => !~exclude_ids.indexOf(id));
});

const mapStateToProps = () =>
  createSelector(
    [
      getCurrentVenueId,
      getCurrentVenue,
      getCurrentVenueCustomersFirstLastName,
      getCustomerId,
      getCustomerName,
      getPlaceholder,
      getCustomersLoading,
      getCustomersById,
      getFilteredItemsList,
    ],
    (
      venue_id,
      venue,
      customers_first_last_name,
      customerId,
      customerName,
      placeholder,
      customersLoading,
      customerById,
      filteredItemsList
    ) => {
      const full_name =
        (customerId && customerById[customerId] ? customerById[customerId].full_name : customerName) || '';

      return {
        venue_id,
        value: full_name,
        queryFilter,
        placeholder:
          placeholder ||
          (customers_first_last_name
            ? 'agenda.customer_full_name_start_with_last_name'
            : 'agenda.customer_full_name_start_with_name'),
        itemsList: filteredItemsList,
        itemsLoading: customersLoading,
        itemMatch,
        itemMap,
        itemSort,
        itemRenderer,
      };
    }
  );

const mapDispatchToProps = (dispatch, props) => {
  /**
   * makeDispatchComponent implementation
   */
  const makeDispatchComponentMethods = {
    shouldDispatch(prevProps) {
      return !!(this.props.venue_id && this.props.venue_id !== prevProps.venue_id);
    },
    onDispatch() {
      if (canPersistCustomers()) {
        dispatch(fetchCustomersIfNeeded());
      }
    },
  };

  return {
    ...makeDispatchComponentMethods,

    // ModelDropdownRemote protocol
    itemsRemote: ({ query }) =>
      new Promise((resolve) => {
        dispatch(searchCustomer({ query: query.toLowerCase() })).then(({ customers }) => {
          resolve({ items: customers });
        });
      }),
  };
};

const Dropdown = ({ itemsRemote, itemsList, itemsLoading, ...props }) =>
  canPersistCustomers() ? (
    <ModelDropdown itemsList={itemsList} itemsLoading={itemsLoading} {...props} />
  ) : (
    <ModelDropdownRemote itemsRemote={itemsRemote} {...props} />
  );

const Container = connect(mapStateToProps, mapDispatchToProps)(makeDispatchComponent(Dropdown));

Container.propTypes = {
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  hideNoResults: PropTypes.bool,
  dataTestid: PropTypes.string,
};

Container.defaultProps = {
  readOnly: false,
  placeholder: undefined,
  hideNoResults: true,
  dataTestid: undefined,
};

Container.displayName = 'CustomerDropdown';

export default Container;
