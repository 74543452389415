import { createSelector } from 'reselect';

export const getVacancyTypologies = (state) => state.vacancyTypologies;

const emptyVacancyTypologies = [];

export const getVacancyTypologiesList = createSelector([getVacancyTypologies], (vacancyTypologies) => {
  return (vacancyTypologies && vacancyTypologies.items) || emptyVacancyTypologies;
});

export const getVacancyTypologiesLoading = createSelector([getVacancyTypologies], (vacancyTypologies) => {
  return vacancyTypologies && vacancyTypologies.isFetching ? true : false;
});
