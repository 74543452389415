import PropTypes from 'prop-types';
import { Component } from 'react';
import { FieldCheckbox as StyledFieldCheckbox, FieldCheckboxInput, FieldCheckboxLabel } from './theme';
import FieldIcon from '../FieldIcon';

class FieldCheckbox extends Component {
  state = {
    checked: this.props.checked || false,

    /**
     * if a second parameter is passed to `onClick`,
     * this component will be locked on click and unlocked only calling the second parameter
     */
    mustBeUnlocked: false,
  };

  constructor(props) {
    super(props);

    if (props.onChange) {
      if (props.locked) {
        this.gotUnlockArgument = true;

        this.unlock = (delay = 0) => {
          setTimeout(() => {
            this.setState({ mustBeUnlocked: false });
          }, delay);
        };
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.checked !== this.state.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  toggleChecked = () => {
    if (this.props.disabled || this.state.mustBeUnlocked) {
      return;
    }

    this.setState(
      ({ checked }) => {
        return {
          checked: !checked,
          mustBeUnlocked: this.gotUnlockArgument,
        };
      },
      () => {
        if (this.props.onChange) {
          return this.props.onChange(this.state.checked, this.unlock);
        }
      }
    );
  };

  render() {
    const { checked } = this.state;
    const { dataTestId, productTourId, ...rest } = this.props;

    return (
      <StyledFieldCheckbox
        className="FieldCheckbox"
        onClick={this.toggleChecked}
        data-testid={dataTestId}
        data-product-tour-id={productTourId}
        {...rest}
        data-checked={checked}
        aria-checked={checked ? 'true' : 'false'}
      >
        {this.props.children && <FieldCheckboxLabel>{this.props.children}</FieldCheckboxLabel>}
        {this.props.fieldIcon ? (
          <FieldIcon {...this.props.fieldIcon} size={this.props.size} active={checked} />
        ) : (
          <FieldCheckboxInput toggle={this.props.toggle} checked={checked} />
        )}
      </StyledFieldCheckbox>
    );
  }
}

FieldCheckbox.displayName = 'FieldCheckbox';

FieldCheckbox.defaultProps = {
  checked: false,
  disabled: false,
  dataTestId: undefined,
  productTourId: undefined,
};

FieldCheckbox.propTypes = {
  checked: PropTypes.bool,
  /**
   * onChange(checked[, unlock])
   * called when checked value changed, is `unlock` is passed as a parameter the field will be locked until `unlock()` called
   */
  onChange: PropTypes.func,
  fieldIcon: PropTypes.object,
  toggle: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  dataTestId: PropTypes.string,
  productTourId: PropTypes.string,
};

export default FieldCheckbox;
