import { TREATMENT_CATEGORIES_LIST_REQUEST, TREATMENT_CATEGORIES_LIST_RESPONSE } from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import { TreatmentCategoriesActions } from 'actions/treatmentCategories';
import { TreatmentCategory } from 'models';

type TreatmentCategoriesState = {
  items?: TreatmentCategory[];
  isFetching: boolean | number;
  didInvalidate: boolean;
};

const treatmentCategories = (
  state: TreatmentCategoriesState = reducerValuesOnInit(),
  action: TreatmentCategoriesActions
): TreatmentCategoriesState => {
  switch (action.type) {
    case TREATMENT_CATEGORIES_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case TREATMENT_CATEGORIES_LIST_RESPONSE:
      if (!action.payload?.treatment_categories || !action.payload?.treatment_categories.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse((state.items || []).concat(action.payload.treatment_categories || []), {
          avoidDuplicateIds: 1,
        }),
      };

    default:
      return state;
  }
};

type TreatmentCategoriesByVenue = {
  [venue_id: number]: TreatmentCategoriesState;
};

const treatmentCategoriesByVenue = (
  state: TreatmentCategoriesByVenue = {},
  action: TreatmentCategoriesActions
): TreatmentCategoriesByVenue => {
  let refState;

  switch (action.type) {
    case TREATMENT_CATEGORIES_LIST_REQUEST:
    case TREATMENT_CATEGORIES_LIST_RESPONSE: {
      if (!action.payload?.venue_id) {
        return state;
      }

      const { venue_id } = action.payload;
      refState = treatmentCategories(state[venue_id], action);

      if (refState === state[venue_id]) {
        return state;
      }

      return {
        ...state,
        [venue_id]: refState,
      };
    }

    default:
      return state;
  }
};

export { treatmentCategoriesByVenue };
