/* eslint-disable no-fallthrough */

import {
  VENUE_CUSTOMERS_WITH_APPOINTMENTS_REQUEST,
  VENUE_CUSTOMERS_WITH_APPOINTMENTS_RESPONSE,
  API_GET_RESPONSE,
  API_POST_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const customers = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_CUSTOMERS_WITH_APPOINTMENTS_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case 'VENUE_CUSTOMERS_START_COMPLETE_FETCH':
      return {
        ...state,
        isFetchingAllCustomers: true,
      };

    case 'VENUE_CUSTOMERS_FINISH_COMPLETE_FETCH':
      return {
        ...state,
        isFetchingAllCustomers: false,
        lastCompleteFetch: new Date().getTime(),
      };

    case VENUE_CUSTOMERS_WITH_APPOINTMENTS_RESPONSE:
      if (!action.customers) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.customers || []), {
          avoidDuplicateIds: 1,
          onlyIds: true,
        }),
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (!action.customers || !action.customers.length) {
        return state;
      }

      /**
       * performance optimization
       * don't update the state if customer is already in the list
       * avoid selector invalidation
       */
      if (
        !action.customers.find((comingCustomer) => !(state.items || []).find((item) => item.id === comingCustomer.id))
      ) {
        // can't find in state.items an item with the same ID, for every action.customers
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.customers || []), {
          avoidDuplicateIds: 1,
          onlyIds: 1,
        }),
      };

    default:
      return state;
  }
};

export const customersByVenue = (state = {}, action) => {
  const responseData = (action.response && action.response.data) || {};
  let refState;

  switch (action.type) {
    case VENUE_CUSTOMERS_WITH_APPOINTMENTS_REQUEST:
    case VENUE_CUSTOMERS_WITH_APPOINTMENTS_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = customers(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    /**
     * add new customers to the list of customers
     * add the customers coming from synch
     * if data.with_valid_appointments_at is set, is handled by previous case VENUE_CUSTOMERS_WITH_APPOINTMENTS_RESPONSE
     */
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
    case 'VENUE_CUSTOMERS_START_COMPLETE_FETCH':
    case 'VENUE_CUSTOMERS_FINISH_COMPLETE_FETCH':
      if (action.error || !action.venue_id || (action.data && action.data.with_valid_appointments_at)) {
        return state;
      }

      refState = customers(state[action.venue_id], {
        ...action,
        customers: [].concat(responseData.customer || []).concat(responseData.customers || []),
      });

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
