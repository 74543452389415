import { StaffMemberTreatment } from 'models';
import { VenueTreatmentOption } from 'models/venueTreatmentOption';

export const filterStaffMemberTreatments = (
  smtIds: { id: number }[] = [],
  deletedStaffMemberTreatments: StaffMemberTreatment[] = []
) => {
  return smtIds
    .filter(({ id }) => !deletedStaffMemberTreatments.find((smt) => smt.id === id))
    .map((item) => ({ id: item.id }));
};

export const getAllDeletedStaffMemberTreatmentsInVenueTreatments = (venue_treatments: VenueTreatmentOption[] = []) => {
  return (venue_treatments ?? [])
    .map((vt) => vt.staff_member_treatments)
    .flat()
    .filter((smt) => smt?._destroy);
};
