import { apiGet, apiPost } from './data_providers/api';
import { API_GET_RESPONSE, API_POST_RESPONSE } from 'actionTypes';
import { OrderDeveliveryNote } from 'models';
import { ActionCreator, ParametricActionCreator } from './types';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';

type OrderDeliveryNotesPayload = {
  venue_id: number;
  response?: { data?: { order_delivery_notes?: OrderDeveliveryNote[]; order_delivery_note?: OrderDeveliveryNote } };
};

type orderDeliveryNotesActionTypes = typeof API_GET_RESPONSE | typeof API_POST_RESPONSE;

export type OrderDeliveryNotesAction = OrderDeliveryNotesPayload & {
  type: orderDeliveryNotesActionTypes;
};

type AssociateOrderDeliveryNotePayload = {
  order_id?: number;
  order_delivery_note_id: number;
};
type AssociateOrderDeliveryNoteToNewOrderPayload = {
  order_delivery_note_id: number;
};
type UpdateOrderDeliveryNotePayload = {
  id: number;
  read: boolean;
};
type OnViewOrderDeliveryNotePayload = {
  order_delivery_note: OrderDeveliveryNote;
};

type FetchOrderDeliveryNotesPayload = {
  page?: number;
  per_page?: number;
  not_associated?: boolean;
  not_read?: boolean;
};

type FetchSingleOrderDeliveryNotesPayload = {
  order_delivery_note_id: number;
  options?: {
    with_supplier?: boolean;
    with_order?: boolean;
  };
};

type NotificationOrderDeliveryNotesActionCreator = ActionCreator<Promise<void>>;

type FetchOrderDeliveryNotesActionCreator = ParametricActionCreator<
  Partial<FetchOrderDeliveryNotesPayload> | void,
  Promise<void>
>;

type FetchSingleOrderDeliveryNoteActionCreator = ParametricActionCreator<
  FetchSingleOrderDeliveryNotesPayload,
  Promise<OrderDeveliveryNote>
>;

type UpdateOrderDeliveryNotesActionCreator = ParametricActionCreator<
  UpdateOrderDeliveryNotePayload,
  Promise<OrderDeveliveryNote>
>;
type DeleteOrderDeliveryNotesActionCreator = ParametricActionCreator<number, Promise<void>>;
type AssociateOrderDeliveryNotesActionCreator = ParametricActionCreator<
  AssociateOrderDeliveryNotePayload,
  Promise<OrderDeveliveryNote>
>;
type AssociateOrderDeliveryNoteToNewOrderActionCreator = ParametricActionCreator<
  AssociateOrderDeliveryNoteToNewOrderPayload,
  Promise<OrderDeveliveryNote>
>;
type OnViewOrderDeliveryNoteActionCreator = ParametricActionCreator<OnViewOrderDeliveryNotePayload, Promise<void>>;

export const fetchOrderDeliveryNotes: FetchOrderDeliveryNotesActionCreator =
  (params) =>
  (dispatch, getState): Promise<void> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiGet({
          path: `/venues/${venue_id}/order_delivery_notes.json`,
          data: params || {},
          onResponse(response) {
            if (!response.success) {
              return reject();
            }

            resolve();
          },
          onError() {
            return reject();
          },
        })
      );
    });

export const fetchSingleOrderDeliveryNotes: FetchSingleOrderDeliveryNoteActionCreator =
  (params) =>
  (dispatch, getState): Promise<OrderDeveliveryNote> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const { order_delivery_note_id, options = { with_order: true, with_supplier: true } } = params;

      const data: FetchSingleOrderDeliveryNotesPayload['options'] = {};

      if (options?.with_order) {
        data.with_order = true;
      }
      if (options?.with_supplier) {
        data.with_supplier = true;
      }

      if (!venue_id || !order_delivery_note_id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiGet({
          path: `/venues/${venue_id}/order_delivery_notes/${order_delivery_note_id}.json`,
          data,
          onResponse(response) {
            if (!response.success) {
              return reject();
            }

            const orderDeliveryNote = response?.data?.order_delivery_note;
            resolve(orderDeliveryNote as OrderDeveliveryNote);
          },
          onError() {
            return reject();
          },
        })
      );
    });

export const notificationOrderDeliveryNotes: NotificationOrderDeliveryNotesActionCreator =
  () =>
  (dispatch): Promise<void> =>
    dispatch(
      fetchOrderDeliveryNotes({
        page: 1,
        per_page: 5,
        not_associated: true,
        not_read: true,
      })
    );

export const associateOrderDeliveryNoteToOrder: AssociateOrderDeliveryNotesActionCreator =
  ({ order_id, order_delivery_note_id }) =>
  (dispatch, getState): Promise<OrderDeveliveryNote> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/order_delivery_notes/${order_delivery_note_id}/associate.json`,
          data: {
            order_id,
            order_delivery_note_id,
          },
          onResponse(response) {
            if (!response.success) {
              return reject();
            }
            const updatedOrderDeliveryNote = response.data.order_delivery_note as OrderDeveliveryNote;
            resolve(updatedOrderDeliveryNote);
          },
          onError() {
            return reject();
          },
        })
      );
    });

export const associateOrderDeliveryNoteToNewOrder: AssociateOrderDeliveryNoteToNewOrderActionCreator =
  ({ order_delivery_note_id }) =>
  (dispatch): Promise<OrderDeveliveryNote> =>
    dispatch(associateOrderDeliveryNoteToOrder({ order_delivery_note_id }));

export const updateOrderDeliveryNote: UpdateOrderDeliveryNotesActionCreator =
  (changes) =>
  (dispatch, getState): Promise<OrderDeveliveryNote> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id || !changes.id) {
        return reject({ code: 400 });
      }
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/order_delivery_notes/${changes.id}.json`,
          data: {
            order_delivery_note: changes,
          },
          onResponse(response) {
            if (!response.success) {
              return reject();
            }
            const updatedOrderDeliveryNote = response.data.order_delivery_note as OrderDeveliveryNote;
            resolve(updatedOrderDeliveryNote);
          },
          onError() {
            return reject();
          },
        })
      );
    });

export const onViewOrderDeliveryNote: OnViewOrderDeliveryNoteActionCreator =
  ({ order_delivery_note }) =>
  (dispatch): Promise<void> =>
    new Promise((resolve, reject) => {
      if (!order_delivery_note.read_at) {
        dispatch(updateOrderDeliveryNote({ id: order_delivery_note.id, read: true }))
          .then(() => {
            return resolve();
          })
          .catch((err) => {
            return reject(err);
          });
      }
      return resolve();
    });

export const deleteOrderDeliveryNotes: DeleteOrderDeliveryNotesActionCreator =
  (orderId) =>
  (dispatch, getState): Promise<void> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id || !orderId) {
        return reject({ code: 400 });
      }

      dispatch(
        apiPost({
          method: 'DELETE',
          path: `/venues/${venue_id}/order_delivery_notes/${orderId}.json`,
          onResponse(response) {
            if (!response.success) {
              return reject();
            }

            dispatch(fetchOrderDeliveryNotes());
            return resolve();
          },
          onError() {
            return reject();
          },
        })
      );
    });
