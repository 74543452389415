import { createElement, ReactElement } from 'react';
import { Box, Icon } from 'components/core/index';
import * as S from 'components/core/SortableList/SortableList.theme';

export type ArrowPosition = 'left' | 'right';

interface SortableListProps {
  children: ReactElement[];
  onSort: (oldPosition: number, newPosition: number) => void;
  arrowPosition?: ArrowPosition;
}

export const SortableList = ({ children, onSort, arrowPosition }: SortableListProps) => {
  const handleChangePosition = (currentIndex: number) => (increment: number) => {
    onSort(currentIndex, currentIndex + increment);
  };

  return (
    <>
      {children.map((sortableChild, i) =>
        createElement(SortableItem, {
          key: sortableChild.key,
          children: sortableChild,
          onChangePositionClick: handleChangePosition(i),
          isFirstElement: i === 0,
          isLastElement: i === children.length - 1,
          arrowPosition,
        })
      )}
    </>
  );
};

interface SortableItemProps {
  children: ReactElement;
  onChangePositionClick: (increment: number) => void;
  isLastElement: boolean;
  isFirstElement: boolean;
  arrowPosition?: ArrowPosition;
}

export const SortableItem = ({
  children,
  onChangePositionClick,
  isFirstElement,
  isLastElement,
  arrowPosition,
}: SortableItemProps) => (
  <Box display="flex" position="relative" data-testid="sortable-item">
    <S.SortableItemWrapper className={arrowPosition === 'left' ? 'ArrowsOnLeft' : 'ArrowsOnRight'}>
      {!isFirstElement && (
        <Icon
          type="angle-up"
          color="blue"
          data-testid="sortable-item-icon-up"
          size={12}
          onClick={() => onChangePositionClick(-1)}
        />
      )}
      {!isLastElement && (
        <Icon
          type="angle-down"
          color="blue"
          data-testid="sortable-item-icon-down"
          size={12}
          onClick={() => onChangePositionClick(1)}
        />
      )}
    </S.SortableItemWrapper>
    <Box flex={1} width="100%">
      {children}
    </Box>
  </Box>
);
