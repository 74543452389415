import LoadingCodeChunkFallback from 'components/lib/LoadingCodeChunkFallback';
import { UALA_FEATURE_FLAG } from 'env';
import {
  ACL_PERMISSION,
  ACL_PERMISSIONS_VALUES,
  CheckPermissionCallback,
  CMS_CONTENT_PAGE,
  Country,
  COUNTRY_FEATURE_FLAG,
  Venue,
  VENUE_ACTIVE_FEATURE_FLAG,
} from 'models';
import { ComponentClass, FunctionComponent, ReactNode, useCallback } from 'react';
import Loadable from '@loadable/component';
import { Redirect, Route, RouteComponentProps, withRouter } from 'react-router-dom';
import checkFeature from 'utils/checkFeature';
import { isAccountInBlockPeriod } from 'utils/selfprovisioning';

const ProvisioningUpsellingPage = Loadable(
  () => import('components/provisioning_next/upselling-page/ProvisioningUpsellingPage'),
  { fallback: LoadingCodeChunkFallback }
);

export type ProvisioningRouteProps = {
  path: string;
  exact?: boolean;
  featureComponent?: ComponentClass<RouteComponentProps<any> | any> | FunctionComponent<RouteComponentProps<any> | any>;
  envFeatureFlag?: UALA_FEATURE_FLAG;
  venue: Venue | null;
  country?: Country | null;
  venueActiveFeature?: {
    flag?: VENUE_ACTIVE_FEATURE_FLAG;
    upsellingPage: CMS_CONTENT_PAGE;
  };
  countryFeatureFlag?: COUNTRY_FEATURE_FLAG;
  checkPermission?: CheckPermissionCallback;
  aclPermissions?: ACL_PERMISSION[];
};

const ACL_PRIORITY_REDIRECTION: [ACL_PERMISSION, string][] = [
  [ACL_PERMISSION.CALENDAR, '/agenda'],
  [ACL_PERMISSION.CASH_REGISTER, '/checkout'],
  [ACL_PERMISSION.WAREHOUSE, '/warehouse'],
  [ACL_PERMISSION.ACCOUNT, '/account'],
  [ACL_PERMISSION.MANAGER_TREND_ANALYSIS, '/manager/trend'],
  [ACL_PERMISSION.MANAGER_COMPANY_REPORT, '/manager/company-report'],
  [ACL_PERMISSION.MANAGER_SALES_PROCEEDS, '/manager/company-report/sales-proceeds'],
  [ACL_PERMISSION.MANAGER_COLLABORATORS_REPORT, '/manager/collaborators-report'],
  [ACL_PERMISSION.MANAGER_CUSTOMERS_REPORT, '/manager/customers-report'],
  [ACL_PERMISSION.CUSTOMERS, '/customers'],
  [ACL_PERMISSION.SERVICES, '/treatments'],
  [ACL_PERMISSION.STAFF, '/staff_members'],
  [ACL_PERMISSION.MARKETING, '/promotions'],
  [ACL_PERMISSION.BUSINESS_HOURS, '/timetables'],
  [ACL_PERMISSION.EXPENSES, '/costs'],
];

const _ProvisioningRouteComponent: FunctionComponent<ProvisioningRouteProps & RouteComponentProps> = ({
  path,
  exact,
  featureComponent: FeatureComponent,
  envFeatureFlag,
  venueActiveFeature,
  venue,
  country,
  countryFeatureFlag,
  checkPermission,
  aclPermissions,
  history,
}) => {
  const mainPath = history.location.pathname.split('/')[1];

  const checkAclPermissions = useCallback(() => {
    if (!aclPermissions || !checkPermission) {
      return true;
    }
    const permissions = aclPermissions.map((aclPermission) => checkPermission(aclPermission));
    return !permissions.includes(ACL_PERMISSIONS_VALUES.NONE);
  }, [aclPermissions, checkPermission]);

  const getAclPriorityRedirection = useCallback(() => {
    if (!checkPermission) {
      return null;
    }
    return ACL_PRIORITY_REDIRECTION.filter(
      (aclRedirectionTuple) => checkPermission(aclRedirectionTuple[0]) !== ACL_PERMISSIONS_VALUES.NONE
    ).map((aclRedirectionTuple) => aclRedirectionTuple[1])[0];
  }, [checkPermission]);

  if (isAccountInBlockPeriod(venue) && mainPath !== 'account') {
    history.push('/account/profile');
  }

  if (
    (envFeatureFlag && !checkFeature(envFeatureFlag)) ||
    !venue ||
    !country ||
    (countryFeatureFlag && !country[countryFeatureFlag]) ||
    !checkAclPermissions()
  ) {
    const aclPriorityRedirection = getAclPriorityRedirection();
    if (!aclPriorityRedirection) {
      return null;
    }
    return <Redirect to={aclPriorityRedirection} />;
  }

  // TODO dome: check invoice cycle state, if it's failed (grace_period | blocked | terminated) we should redirect to /account
  return (
    <Route
      path={path}
      exact={exact}
      render={(routeProps): ReactNode => {
        if (
          venueActiveFeature &&
          (!venueActiveFeature.flag || (venueActiveFeature.flag && !venue[venueActiveFeature.flag]))
        ) {
          return <ProvisioningUpsellingPage cmsContentPage={venueActiveFeature.upsellingPage} />;
        }

        if (FeatureComponent) {
          return <FeatureComponent {...routeProps} />;
        }

        return <Redirect to="/" />;
      }}
    />
  );
};

export const ProvisioningRouteComponent = withRouter(_ProvisioningRouteComponent);
