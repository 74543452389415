import { Component } from 'react';
import PropTypes from 'prop-types';
import Shape from './theme';
import Text from '../Text';

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: !props.animated,
    };
  }

  componentDidMount() {
    if (this.props.animated) {
      this.visibleTimer = setTimeout(() => this.setState({ visible: true }), 600);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.visibleTimer);
  }

  render() {
    const { text, children, ...props } = this.props;
    return (
      <Shape className="Tooltip" {...props} visible={this.state.visible}>
        {text ? <Text {...text} /> : children}
      </Shape>
    );
  }
}

Tooltip.displayName = 'Tooltip';

Tooltip.propTypes = {
  text: PropTypes.object,
  animated: PropTypes.bool,
};

export default Tooltip;
