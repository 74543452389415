import { styled, css } from 'styles';
import { withTheme } from 'components/core';
import { Button } from 'components/core';
import { Theme } from 'styles';

export const PaymentCard = withTheme(
  styled('div')(
    ({
      theme: { colors },
      checked,
      onClick,
      error,
      withContent,
      remove,
    }: {
      theme: Theme;
      checked: boolean;
      onClick?: () => void;
      error: string;
      withContent: string;
      remove: boolean;
    }) => css`
      background: ${colors.snowWhite};
      display: flex;
      flex-direction: column;
      border-width: 1px;
      border-style: solid;
      border-bottom-width: 0;
      border-color: red;
      &:first-of-type {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      &:last-child {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-width: 1px;
      }

      border-color: ${colors.gray5};

      ${(onClick as unknown as boolean) &&
      `
        cursor: pointer;
      `}

      ${checked &&
      `
        border-color: ${colors.blue}; 
        background: ${colors.white};
        border-bottom-width: 1px;

        + div {
          border-top-width: 0;
        }
        `}

      ${error &&
      `
        ${!withContent && `border-color: ${colors.red};`} 
        border-bottom-width: 1px;

        + div {
          border-top-width: 0;
        }
        `}
      
      ${withContent &&
      `
        background: ${colors.white};
      `}

      &:hover {
        ${onClick && `background: ${colors.darkSnowWhite};`}
        ${checked && `background: ${colors.white};`}
        ${remove &&
        `& .PaymentCardRemoveButton {
          display: inline;
        }`}
      }
    `
  )
);

export const PaymentCardHeader = withTheme(
  styled('div')(
    () => css`
      padding: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
    `
  )
);

export const PaymentCardHeaderBrand = withTheme(
  styled('div')(
    () => css`
      margin: 0 16px 0 0;
      width: 40px;
      max-height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    `
  )
);

export const PaymentCardHeaderDetails = withTheme(
  styled('div')(
    () => css`
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
    `
  )
);

export const PaymentCardHeaderCheck = withTheme(
  styled('div')(
    ({ theme: { colors } }) => css`
      background: ${colors.blue};
      display: flex;
      flex-direction: column;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 36px;
      color: ${colors.white};
      fill: ${colors.white};
    `
  )
);

export const PaymentCardBody = withTheme(
  styled('div')(
    ({ theme: { colors } }) => css`
      padding: 16px;
      border-top: 1px solid ${colors.gray5};
    `
  )
);

export const PaymentCardError = withTheme(
  styled('div')(
    ({ theme: { colors }, withContent, noPadding }: { theme: Theme; withContent: boolean; noPadding: boolean }) => css`
      padding: ${noPadding ? '0' : '0 16px 16px 16px'};
      display: flex;
      flex-direction: column;
      color: ${colors.red};
      ${withContent && 'align-items: center;'}

      .Icon {
        color: ${colors.white};
        fill: ${colors.white};
      }
    `
  )
);

export const PaymentCardErrorTitle = withTheme(
  styled('div')(
    ({ theme: { colors } }) => css`
      padding: 0 0 10px 0;
      display: flex;
      align-items: center;

      .Icon {
        background: ${colors.red};
        border-radius: 32px;
        padding: 4px;
        margin: 0 10px 0 0;
      }
    `
  )
);

export const PaymentCardRemoveButton = withTheme(
  styled(Button)(
    ({ theme: { colors }, isAfterBrand }: { theme: Theme; isAfterBrand: boolean }) => css`
      padding: 0;
      color: ${colors.red};
      height: auto;
      line-height: inherit;
      font-size: inherit;
      border-radius: 0;
      display: none;

      ${isAfterBrand &&
      `
        margin-left: 8px;
      `}
    `
  )
);
