import { VENUE_REACHED_LIMIT } from 'models';
import { ParametricActionCreator, ParametricDispatch } from './types';
import { getCurrentVenue } from 'selectors/getCurrentVenue';
import { modalOpen, modalClose } from './modals';

type ReachedLimitPayload = {
  venue_reached_limit: VENUE_REACHED_LIMIT;
  config?: {
    compressed: boolean;
    close: boolean;
  };
  action?: () => void;
};

type ReachedLimitActionCreator = ParametricActionCreator<ReachedLimitPayload, void>;

export type ReachedLimitBookingsPayload = {
  compressed?: boolean;
  action?: () => void;
  close?: boolean;
};

export type ReachedLimitBookingsActionCreator = ParametricActionCreator<ReachedLimitBookingsPayload, void>;

export type ReachedLimitBookingsDispatch = ParametricDispatch<ReachedLimitBookingsPayload, void>;

export const ReachedLimitModalId = 'reached-limits-bookings';

export const reachedLimits: ReachedLimitActionCreator =
  ({ venue_reached_limit, config = {}, action }) =>
  (dispatch, getState): void => {
    const venue = getCurrentVenue(getState());

    if (!venue) {
      return;
    }

    const reachedLimit = venue.reached_limits && venue.reached_limits.find((rl) => Boolean(rl[venue_reached_limit]));

    if (!reachedLimit) {
      if (action) {
        action();
      }
      return;
    }

    switch (Object.keys(reachedLimit)[0]) {
      case VENUE_REACHED_LIMIT.BOOKINGS:
        if (config.close) {
          dispatch(modalClose({ id: ReachedLimitModalId }));
        } else {
          dispatch(
            modalOpen({
              id: ReachedLimitModalId,
              config: {
                component: 'reachedLimits/ReachedLimitsBookings',
                compressed: config.compressed,
                limit: reachedLimit[venue_reached_limit],
                noSpinner: true,
              },
            })
          );
        }
        break;
      case VENUE_REACHED_LIMIT.CUSTOMER_SMS_NOTIFICATIONS:
        break;
    }
  };

export const reachedLimitsBookings: ReachedLimitBookingsActionCreator = ({
  compressed = true,
  close = false,
  action,
}) => {
  return reachedLimits({
    venue_reached_limit: VENUE_REACHED_LIMIT.BOOKINGS,
    config: {
      compressed,
      close,
    },
    action,
  });
};
