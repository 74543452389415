import { StaffMemberTreatment } from 'models';
import { createSelector } from 'reselect';
import { getCurrentVenueId } from './getCurrentVenue';
import { getEntities } from './getEntities';
import filterDeletedItems from 'utils/filterDeletedItems';
import { State } from 'reducers/types';

export const getStaffMemberTreatmentsByVenue = (state: State) => state.staffMemberTreatmentsByVenue;

export const getStaffMemberTreatmentsLoading = createSelector(
  [getCurrentVenueId, getStaffMemberTreatmentsByVenue],
  (venue_id, staffMemberTreatmentsByVenue) => {
    return venue_id ? !!staffMemberTreatmentsByVenue[venue_id]?.isFetching : false;
  }
);

const emptyStaffMemberTreatments: StaffMemberTreatment[] = [];

export const getStaffMemberTreatmentsList = createSelector(
  [getCurrentVenueId, getStaffMemberTreatmentsByVenue, getEntities.staff_member_treatment, getEntities.venue_treatment],
  (venue_id, staffMemberTreatmentsByVenue, staffMemberTreatmentById, venueTreatmentsById) => {
    const staffMemberTreatments = venue_id
      ? staffMemberTreatmentsByVenue[venue_id]?.items || emptyStaffMemberTreatments
      : emptyStaffMemberTreatments;
    return staffMemberTreatments
      .map((staffMemberTreatment) => ({
        ...staffMemberTreatment,
        ...((staffMemberTreatmentById[staffMemberTreatment.id] &&
          staffMemberTreatmentById[staffMemberTreatment.id].data) ||
          {}),
      }))
      .filter(filterDeletedItems)
      .map((staffMemberTreatment) => {
        const venue_treatment =
          (venueTreatmentsById[staffMemberTreatment.venue_treatment_id] &&
            venueTreatmentsById[staffMemberTreatment.venue_treatment_id].data) ||
          {};

        return {
          ...staffMemberTreatment,
          venue_treatment: {
            ...venue_treatment,
            short_name: venue_treatment.short_name || (venue_treatment.computed_name || '').toUpperCase()[0],
          },
        };
      });
  }
);

export const getStaffMemberTreatmentsById = createSelector(
  [getStaffMemberTreatmentsList],
  (staffMemberTreatmentsList) => {
    return staffMemberTreatmentsList.reduce(
      (staffMemberTreatmentsById, staffMemberTreatment) => {
        if (staffMemberTreatment.id) {
          staffMemberTreatmentsById[staffMemberTreatment.id] = staffMemberTreatment;
        }
        return staffMemberTreatmentsById;
      },
      {} as { [key: number]: any }
    );
  }
);

export const getStaffMemberTreatmentsByVenueTreatmentId = createSelector(
  [getStaffMemberTreatmentsList],
  (staffMemberTreatmentsList) => {
    return staffMemberTreatmentsList.reduce(
      (staffMemberTreatmentsByVenueTreatmentId, staffMemberTreatment) => {
        if (staffMemberTreatment.venue_treatment_id) {
          staffMemberTreatmentsByVenueTreatmentId[staffMemberTreatment.venue_treatment_id] = (
            staffMemberTreatmentsByVenueTreatmentId[staffMemberTreatment.venue_treatment_id] || []
          ).concat(staffMemberTreatment);
        }
        return staffMemberTreatmentsByVenueTreatmentId;
      },
      {} as { [key: number]: any }
    );
  }
);
