import { compare, valid, coerce, SemVer } from 'semver';

export class InvalidVersionError extends Error {}

function assertVersionIsValid(
  version: string | SemVer | null | undefined,
  originalVersion: string
): asserts version is SemVer {
  if (!valid(version)) throw new InvalidVersionError(`${originalVersion} is not a valid Semver version`);
}

export const compareSemver = (_version_A: string, _version_B: string): number => {
  const version_A = coerce(_version_A);
  const version_B = coerce(_version_B);

  assertVersionIsValid(version_A, _version_A);
  assertVersionIsValid(version_B, _version_B);

  return compare(version_A, version_B);
};

export const compareVersion = (version_A: string, version_B: string): number =>
  version_A.localeCompare(version_B, undefined, { numeric: true });
