import { Venue } from 'models';
import { configureScope } from '@sentry/react';
import strategies from './checkVersionStrategies';
import ENV from 'env';
import versionJson from 'version.json';

const LOCAL_STORAGE_VERSION_KEY = 'UALA_LAST_VERSION_INSTALLED';
const CHECK_VERSION_INTERVAL = 60000;
const ONE_HOUR = 3600 * 1000;
const TWO_HOURS = 2 * ONE_HOUR;

let mostRecentVersion = '';
let lastCheckTime = 0;
let checkVersionTimer: NodeJS.Timeout;
const callbackTimeouts: Array<NodeJS.Timeout> = [];

/**
 * Schedule a callback,
 * and reset any already existing callback BUT ONLY when triggered the first
 * ("first fired reset all" principles)
 */
const scheduleCallback = (callback: () => void, milliseconds: number): void => {
  callbackTimeouts.push(
    setTimeout(() => {
      let timeout: NodeJS.Timeout | undefined;
      while ((timeout = callbackTimeouts.pop())) {
        clearTimeout(timeout);
      }
      callback();
    }, milliseconds)
  );
};

type Skip = () => void;

type CheckVersion = (args: {
  venue: Venue;
  onNewVersionAvailable: (version: string, skip: Skip) => void;
  onInit?: (version: string) => void;
  onNewVersionInstalled?: (version: string) => void;
}) => void;

/**
 * The content of version.json, from assets, is the real current assets version.
 * If a cached content is served, this version will be the old one.
 */
configureScope((scope) => {
  scope.setTag('app_version', versionJson.version);
});

const checkVersion: CheckVersion = ({ venue, onInit, onNewVersionInstalled, onNewVersionAvailable }) => {
  /**
   * If upgrade feature is disabled, stop
   */
  if (ENV.UALA_UPGRADE_DISABLED) {
    return;
  }

  /**
   * If empty, is the first call
   */
  if (!mostRecentVersion) {
    /**
     * init mostRecentVersion value with `versionJson.version`
     */
    mostRecentVersion = versionJson.version;

    /**
     * Check against localStorage the version number,
     * If is different from a previous version, log to Sentry
     */
    if (
      window.localStorage &&
      window.localStorage.getItem &&
      window.localStorage.getItem(LOCAL_STORAGE_VERSION_KEY) !== versionJson.version
    ) {
      window.localStorage.setItem(LOCAL_STORAGE_VERSION_KEY, versionJson.version);

      if (onNewVersionInstalled) {
        onNewVersionInstalled(versionJson.version);
      }
    }

    /**
     * Call onInit lifecycle method with current version
     */
    if (onInit) {
      onInit(versionJson.version);
    }
  }

  /**
   * Start the checkVersion polling
   */
  clearTimeout(checkVersionTimer);
  const repeat = () => checkVersion({ venue, onNewVersionAvailable });

  const t = new Date().getTime();
  fetch('/version.json?t=' + t)
    .then((response) => response.json())
    .then((response) => {
      // Save a local copy of `lastCheckTime` to check against in following steps,
      // and update the original value
      const previousLastCheckTime = lastCheckTime;
      lastCheckTime = new Date().getTime();

      if (response.version !== mostRecentVersion) {
        mostRecentVersion = response.version;

        if (!previousLastCheckTime || new Date().getTime() > previousLastCheckTime + TWO_HOURS) {
          /**
           * force refresh if device is disconnected from version.json polling by 2 or more hours.
           * or if the user lands on the manager with cached assets
           * (can't determine which strategy to be applied)
           */
          const skip: Skip = () => {
            scheduleCallback(() => {
              onNewVersionAvailable(mostRecentVersion, skip);
            }, 1000);
          };
          onNewVersionAvailable(mostRecentVersion, skip);
          return;
        }

        /**
         * Detect rollout strategy
         */
        let upgradeTimeStart = response.upgradeTimeStart;
        let upgradeTimeDistribution = response.upgradeTimeDistribution;

        for (let groupIndex = 0; groupIndex < response.rolloutGroups.length; groupIndex++) {
          const rolloutGroup = response.rolloutGroups[groupIndex];
          if (strategies.find((check) => check(rolloutGroup, venue))) {
            upgradeTimeStart = rolloutGroup.upgradeTimeStart;
            upgradeTimeDistribution = rolloutGroup.upgradeTimeDistribution;
            break;
          }
        }

        /**
         * Transform minutes in milliseconds
         */
        upgradeTimeStart = upgradeTimeStart * 60 * 1000;
        const deltaTimeDistribution = Math.random() * upgradeTimeDistribution * 60 * 1000;

        const skip: Skip = () => {
          scheduleCallback(() => {
            onNewVersionAvailable(mostRecentVersion, skip);
          }, CHECK_VERSION_INTERVAL);
        };

        scheduleCallback(() => {
          onNewVersionAvailable(mostRecentVersion, skip);
        }, upgradeTimeStart + deltaTimeDistribution);
      }

      /**
       * checkVersion Timeout always active
       */
      checkVersionTimer = setTimeout(repeat, CHECK_VERSION_INTERVAL);
    })
    .catch(() => {
      checkVersionTimer = setTimeout(repeat, CHECK_VERSION_INTERVAL);
    });
};

export default checkVersion;
