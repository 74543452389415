/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { VenueProductTransactionsResponse, VenueProductTransactionsPayload } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const TRANSACTIONS =
  ({ variables }: V<VenueProductTransactionsPayload>) =>
  (): Promise<VenueProductTransactionsResponse> =>
    Http()
      .post<VenueProductTransactionsResponse, never, VenueProductTransactionsPayload>(
        ENDPOINT.VENUE_PRODUCTS._TRANSACTIONS(variables.venue_product.id ?? 0)
      )({
        body: {
          venue_product: variables.venue_product,
          kind: variables.kind,
          quantity: variables.quantity,
        },
      })
      .then(maybeResponseToJSON);
