import { styled, css } from 'styles';
import { withTheme } from 'components/core';

export const HeaderWrapper = withTheme(
  styled('div')(
    ({ theme: { colors, sizes, media } }) => css`
      box-sizing: border-box;
      position: fixed;
      z-index: 1000;
      top: 0;
      left: ${sizes.multiVenueSidebarWidth}px;
      width: 100%;
      background-color: ${colors.bgWhite};
      border-bottom: 1px solid ${colors.inputBorder};
      display: flex;
      align-items: center;

      height: ${sizes.mobileHeaderHeight}px;

      ${media.tabletOrSmaller`
        left: 0;
      `};

      ${media.tablet`
        height: ${sizes.desktopHeaderHeight}px;
      `};

      > .Header__inner {
        display: flex;
        position: relative;
        width: 100%;
        height: 100%;
        outline: none;

        ${media.tabletOrSmaller`
          width: 100%;
        `}

        > .Clock {
          position: absolute;
          text-align: center;
          top: 50%;
          left: 50%;
          width: 120px;
          margin-left: -60px;
          height: 40px;
          margin-top: -20px;
          line-height: 40px;
        }
      }

      // only if multi venue
      ${sizes.multiVenueSidebarWidth &&
      css`
        ${media.desktop`
            width: calc(100% - ${sizes.multiVenueSidebarWidth}px);
          `}

        > .Header__inner {
          ${media.tabletOrSmaller`
              width: calc(100% - 69px);
            `}
        }
      `}
    `
  )
);
