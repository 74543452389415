import { styled } from 'styles';

export const TooltipContent = styled.div<{ readonly color: string; bottom: boolean }>`
  position: relative;
  height: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-left: ${({ bottom }): string => (bottom ? '10px solid transparent' : 'none')};
    border-right: ${({ color, bottom }): string => (bottom ? `10px solid transparent` : `8px solid ${color}`)};
    border-bottom: ${({ color, bottom }): string => (bottom ? `8px solid ${color}` : '10px solid transparent')};
    border-radius: 10%;
    top: ${({ bottom }): string => (bottom ? '-22px' : '0')};
    left: ${({ bottom }): string => (bottom ? '50%' : '-20px')};
    transform: translateX(-50%);
    border-top: ${({ bottom }): string => (bottom ? 'none' : '10px solid transparent')};
  }

  @media (max-width: 767px) {
    &::after {
      position: absolute;
      display: none;
    }
  }
`;

export const TooltipContainer = styled.div<{
  readonly top: number;
  readonly left: number;
  readonly width?: string;
  readonly color?: string;
  readonly bottom?: boolean;
}>`
  position: absolute;
  top: ${({ top }): number => top || 0}px;
  left: ${({ left }): number => left + 10 || 0}px;
  background: ${({ color }) => color};
  color: white;
  border-radius: 10px;
  padding: 16px;
  transform: ${({ bottom }) => (bottom ? 'translateX(-50%)' : 'translateX(-0)')};
  font-size: 1.2rem;
  max-width: 400px;

  @media (max-width: 767px) {
    font-size: 1rem;
    min-width: 80%;
    position: fixed;
    bottom: auto;
    top: 10%;
    transform: translateX(-50%);
    left: 50%;
    text-align: left;
    white-space: normal;
  }
`;
