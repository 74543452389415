import { NODE_ENV } from 'env';
import 'whatwg-fetch';

type Player = {
  id: string;
  success: boolean;
};

type Device = {
  device_model: string;
  device_type: number;
};

type DeviceType = 'ELECTRON' | 'IOS' | 'ANDROID';

const supportedDevices: { [key: string]: Device } = {
  ELECTRON: {
    device_model: 'Electron',
    device_type: 1,
  },
  IOS: {
    device_model: 'iOS',
    device_type: 0,
  },
  ANDROID: {
    device_model: 'Android',
    device_type: 1,
  },
};

export const registerOneSignalDevice = (
  deviceType: DeviceType,
  appId: string,
  deviceToken: string,
  venueDefaultLocale: string
): Promise<Player | void> => {
  const device = supportedDevices[deviceType];

  if (process.env.NODE_ENV === NODE_ENV.DEV) {
    console.log('registerOneSignalDevice', { appId, device, deviceToken });
  }

  if (!device || !appId || !deviceToken) {
    return new Promise((r) => r());
  }

  // add new device to one signal app
  return fetch('https://onesignal.com/api/v1/players', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      app_id: appId,
      identifier: deviceToken,
      device_model: device.device_model,
      device_type: device.device_type,
      language: venueDefaultLocale,
    }),
  }).then((res) => res.json());
};
