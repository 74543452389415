import { Venue, BILLING_DETAILS_STATE } from 'models';

const isAccountActive = (venue: Venue | null): boolean => {
  if (venue && venue.billing_details && venue.billing_details.state === BILLING_DETAILS_STATE.ACTIVE) {
    return true;
  }
  return false;
};

export default isAccountActive;
