type Environment = {
  fetch: Window['fetch'];
};
type Interceptor = (
  fetch: Window['fetch'],
  wrapper: Window['fetch'],
  ...args: Parameters<Window['fetch']>
) => ReturnType<Window['fetch']>;

class InterceptedError extends Error {
  public cause!: Error | unknown;

  constructor(public request: Request) {
    super(`Error Fetching "${request.url}"`);
    this.name = 'InterceptedError';
  }

  public setCauseAfterTheFact(cause: Error | unknown) {
    this.cause = cause;
    this.message += ` with error ${String(cause)}`;
  }
}

const interceptor: Interceptor = (fetch, wrapper, ...args) => {
  const request = new Request(...args);
  const betterError = new InterceptedError(request);

  if ('captureStackTrace' in Error) {
    Error.captureStackTrace(betterError, wrapper); // Will calculate the error stacktrace based on where Fetch was called
  }

  return new Promise<Response>((resolve, reject) => {
    fetch(request)
      .then(resolve) //
      .catch((thrownObj: unknown) => {
        betterError.setCauseAfterTheFact(thrownObj);
        console.error(betterError);

        if (thrownObj instanceof Error && thrownObj.message === 'Load failed') return reject(betterError);

        reject(thrownObj);
      });
  });
};

export const attach = (env: Environment) => {
  env.fetch = (function (fetch) {
    const fetchWrapper: Window['fetch'] = function (...args) {
      return interceptor(fetch, fetchWrapper, ...args);
    };
    return fetchWrapper;
  })(env.fetch);
};
