import PropTypes from 'prop-types';
import { styled } from 'styles';

import { withTheme } from 'components/core';
import { INPUT_INLINE, INPUT_STACKED, INPUT_BLOCK } from '../../config';

const StyledLabelWrapper = styled('div')`
  width: ${({ position }) => (position === INPUT_INLINE ? '40%' : 'auto')};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyledLabel = withTheme(styled('label')`
  color: ${({ theme }) => theme.colors.text2};
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.utils.fontFamily};
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  letter-spacing: 1.2px;
`);

const propTypes = {
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string,
};

const Label = ({ position = INPUT_STACKED, children }) => {
  const classNames = ['Label'];

  if (position === INPUT_STACKED) {
    classNames.push('Label--stacked');
  } else if (position === INPUT_BLOCK) {
    classNames.push('Label--block');
  } else {
    classNames.push('Label--inline');
  }

  return (
    <StyledLabelWrapper className={classNames.join(' ')}>
      <StyledLabel>{children}</StyledLabel>
    </StyledLabelWrapper>
  );
};

Label.displayName = 'Label';
Label.propTypes = propTypes;

export default Label;
