import { FunctionComponent, useState, useCallback, ReactElement } from 'react';
import { connectFormElement, ConnectedFormProps } from '@uala/react-forms';

import CustomerDropdown from 'containers/customers/CustomerDropdown';

import { BaseInputWrapper } from '../../shared';
import { INPUT_INLINE } from '../../config';
import { CustomerDropdownInput, CustomerDropdownControl } from './CustomerAutocomplete.theme';

interface CustomerReferrerFriendProps {
  onReferrerSelect?: ({ customerId }: { customerId: number }) => void;
  placeholder?: string;
  referrerId?: number;
  name: string;
  labelPosition?: string;
}

const CustomerReferrerFriend: FunctionComponent<CustomerReferrerFriendProps & ConnectedFormProps> = ({
  values = {},
  placeholder,
  name,
  emitChange = (): void => {},
  labelPosition = INPUT_INLINE,
}): ReactElement => {
  const [customerDropdownOpen, setCustomerDropDownOpen] = useState<boolean>(false);
  const value = values[name];

  const handleSelect = useCallback(
    ({ id }: { id: number | null }): void => {
      emitChange(name, id);
    },
    [emitChange, name]
  );

  const handleStateChange = useCallback(
    ({ editing, noResults, isQueryValid }: { editing: boolean; noResults: boolean; isQueryValid: boolean }): void => {
      console.log('editing...', editing);
      setCustomerDropDownOpen((editing && isQueryValid) || (editing && noResults));
    },
    [setCustomerDropDownOpen]
  );

  return (
    <CustomerDropdownControl labelPosition={labelPosition}>
      <CustomerDropdownInput labelPosition={labelPosition}>
        <BaseInputWrapper size="md" borderStyle={customerDropdownOpen ? 'rounded' : undefined}>
          <CustomerDropdown
            readOnly={false}
            customer_id={value}
            placeholder={placeholder}
            onStateChange={handleStateChange}
            onSelect={handleSelect}
            onCreateNewItem={(): void => handleSelect({ id: null })}
            createNewItemOnQueryChange
            hideNoResults={false}
          />
        </BaseInputWrapper>
      </CustomerDropdownInput>
    </CustomerDropdownControl>
  );
};

export default connectFormElement(CustomerReferrerFriend);
