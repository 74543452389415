import { getCurrentNativeWrapper, NATIVE_WRAPPER_TYPE } from '../cash/getCurrentNativeWrapper';

export type KeyChainCredentials = {
  email?: string;
  password?: string;
};

export type GetNativeCredentialsHandlerFunctions = {
  setCredentials: (credentials: KeyChainCredentials) => Promise<KeyChainCredentials | undefined>;
  getCredentials: () => Promise<KeyChainCredentials | undefined>;
  clearCredentials: () => Promise<KeyChainCredentials | undefined>;
  isKeyChainAvailable: boolean;
};

const noopPromise = (): Promise<undefined> => Promise.resolve(undefined);

const ON_CREDENTIALS_EVENT_NAME = 'credentials';
const createCredentialsEvent = (credentials: KeyChainCredentials): CustomEvent =>
  new CustomEvent(ON_CREDENTIALS_EVENT_NAME, { detail: credentials });

const ON_SET_CREDENTIALS_EVENT_NAME = 'setCredentials';
const setCredentialsEvent = (credentials: KeyChainCredentials): CustomEvent =>
  new CustomEvent(ON_SET_CREDENTIALS_EVENT_NAME, { detail: credentials });

const eventListener = {
  onCredentialsOnce: (fn: (credentials: KeyChainCredentials) => unknown): void =>
    window.document.addEventListener(
      ON_CREDENTIALS_EVENT_NAME,
      (e) => fn((e as CustomEvent).detail as KeyChainCredentials),
      { once: true }
    ),

  onSetCredentialsOnce: (fn: (credentials: KeyChainCredentials) => unknown): void =>
    window.document.addEventListener(
      ON_SET_CREDENTIALS_EVENT_NAME,
      (e) => fn((e as CustomEvent).detail as KeyChainCredentials),
      { once: true }
    ),
};

window.onCredentials = (c: KeyChainCredentials): void => {
  window.document.dispatchEvent(createCredentialsEvent(c));
};
window.onSetCredentials = (c: KeyChainCredentials): void => {
  window.document.dispatchEvent(setCredentialsEvent(c));
};

export const getNativeCredentialsHandler = (): GetNativeCredentialsHandlerFunctions => {
  const nativeWrapper = getCurrentNativeWrapper();

  if (nativeWrapper !== NATIVE_WRAPPER_TYPE.IOS) {
    return {
      setCredentials: noopPromise,
      getCredentials: noopPromise,
      clearCredentials: noopPromise,
      isKeyChainAvailable: false,
    };
  }

  const setCredentials = (credentials: KeyChainCredentials): Promise<KeyChainCredentials> =>
    new Promise((resolve) => {
      eventListener.onSetCredentialsOnce((c: KeyChainCredentials): void => {
        resolve(c);
      });

      window.webkit?.messageHandlers?.setCredentials?.postMessage(credentials);
    });

  const getCredentials = (): Promise<KeyChainCredentials> =>
    new Promise<KeyChainCredentials>((resolve) => {
      eventListener.onCredentialsOnce((c) => resolve(c));

      window.webkit?.messageHandlers?.getCredentials?.postMessage({});
    });

  return {
    setCredentials,
    getCredentials,
    clearCredentials: () => setCredentials({ email: undefined, password: undefined }),
    isKeyChainAvailable: Boolean(window.webkit?.messageHandlers?.getCredentials),
  } as GetNativeCredentialsHandlerFunctions;
};
