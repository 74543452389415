import React from 'react';

// Example

// const mapStateToProps = ...
//
// const mapDispatchToProps = (dispatch, props) => {
//   return {
//     shouldDispatch(prevProps) {
//       if (this.props.venue_id && this.props.venue_id !== prevProps.venue_id) {
//         return true;
//       }
//       return false;
//     },
//     onDispatch() {
//       dispatch(fetchVenueTreatmentsIfNeeded());
//     },
//   }
// }
// export default connect(mapStateToProps, mapDispatchToProps)(makeDispatchComponent(AddTreatmentCard));

export const makeDispatchComponent = (Component) => {
  return class DispatchComponent extends React.Component {
    static displayName = `Dispatcher(${Component.displayName || Component.name || 'Component'})`;

    static defaultProps = {
      onDispatch() {},

      shouldDispatch() {
        return false;
      },
    };

    constructor(props) {
      super(props);

      this.onDispatch = this.props.onDispatch.bind(this);
      this.shouldDispatch = this.props.shouldDispatch.bind(this);
    }

    componentDidMount() {
      this.onDispatch();
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.shouldDispatch(prevProps, prevState)) {
        this.onDispatch();
      }
    }

    render() {
      // Filter out extra props that are specific to this HOC and shouldn't be
      // passed through
      const { onDispatch, shouldDispatch, ...passThroughProps } = this.props;

      return <Component {...passThroughProps} />;
    }
  };
};
