import { useState, useEffect } from 'react';
import { Text, Icon } from 'components/core';
import { AvailableLocales } from 'providers/IntlProvider';

import { AvailableLanguage } from './LanguageSwitcher.config';
import { StyledLanguageSwitcher, StyledLanguageValue, StyledLanguageOptions } from './LanguageSwitcher.theme';

type Props = {
  position: 'top' | 'bottom';
  value: AvailableLocales;
  options?: ReadonlyArray<AvailableLanguage>;
  onChange?: (locale: AvailableLocales) => void;
};

const LanguageSwitcher = ({ position, value, options = [], onChange = (): void => {} }: Props): JSX.Element => {
  const [open, setOpen] = useState(false);

  const setLocale = (locale: AvailableLocales): void => {
    if (locale !== value) {
      onChange(locale);
    }
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const close = (): void => {
        setOpen(false);
      };
      window.addEventListener('click', close);

      return (): void => window.removeEventListener('click', close);
    }

    return () => {};
  }, [open]);

  const currentOption = options.find((option) => option.locale === value) || options[0];

  return (
    <StyledLanguageSwitcher>
      <StyledLanguageValue onClick={(): void => setOpen(true)} data-testid={`current-lang-${currentOption.locale}`}>
        <img src={require('svg/flags/' + currentOption.flag + '.svg')} alt={currentOption.locale} />
        <Text capitalize size={12}>
          {currentOption.label}
        </Text>
        <Icon type="angle-down" size={7} marginLeft />
      </StyledLanguageValue>
      {open && (
        <StyledLanguageOptions position={position}>
          {options.map((option) => (
            <StyledLanguageValue
              key={option.locale}
              onClick={(): void => setLocale(option.locale)}
              data-testid={`option-lang-${option.locale}`}
            >
              <img src={require('svg/flags/' + option.flag + '.svg')} alt={option.label} />
              <Text capitalize size={12}>
                {option.label}
              </Text>
            </StyledLanguageValue>
          ))}
        </StyledLanguageOptions>
      )}
    </StyledLanguageSwitcher>
  );
};

LanguageSwitcher.displayName = 'LanguageSwitcher';

export { LanguageSwitcher };
