import { apiGet, apiPost } from './data_providers/api';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { actionShouldFetchData } from 'utils/reducersUtils';
import {
  BILLING_COMPANY_PRODUCTS_LIST_REQUEST,
  BILLING_COMPANY_PRODUCTS_LIST_RESPONSE,
  BILLING_ADDONS_LIST_REQUEST,
  BILLING_ADDONS_LIST_RESPONSE,
  BILLING_COMPANY_INVOICES_LIST_REQUEST,
  BILLING_COMPANY_INVOICES_LIST_RESPONSE,
  VENUES_TRANSFERS_LIST_REQUEST,
  VENUES_TRANSFERS_LIST_RESPONSE,
} from 'actionTypes';

/**
 * https://docs.uala.it/api/v1/billing_companies
 */

export const fetchBillingCompany = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());

    if (!venue_id) {
      return;
    }

    // dispatch(fetchBillingCompanyRequestAction({ venue_id, auth }));

    // https://docs.uala.it/api/v1/billing_companies
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/billing_company.json`,
      })
    );
  };
};

export const fetchBillingCompanyInfo = (token) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(
      apiGet({
        path: `/credit_cards/billing_company_info.json`,
        data: {
          token,
        },
        guest: true,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve(response.data.billing_company);
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

export const fetchBillingCompanyIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());

    if (!venue_id) {
      return;
    }

    if (actionShouldFetchData(getState().billingCompanyByVenue[venue_id])) {
      return dispatch(fetchBillingCompany());
    }
  };
};

export const validateBillingCompany = (billingCompanyId) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    //https://api-docs.uala.dev/#tag/v1_misc/paths/~1v1~1billing_companies~1{id}~1validate/put

    const auth = getCurrentUserToken(getState());

    if (!billingCompanyId || !auth) {
      return;
    }

    dispatch(
      apiPost({
        method: 'PUT',
        path: `/billing_companies/${billingCompanyId}/validate_mandatory_fields.json`,
        auth,
        customErrorResponseStrategy: (response) => reject({ error: response.info && response.info[0], ...response }),
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve(response);
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

export const updateBillingCompany = (billingCompany) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());

    if (!venue_id) {
      return;
    }

    dispatch(
      apiPost({
        method: 'PUT',
        path: `/venues/${venue_id}/billing_company.json`,
        data: {
          billing_company: billingCompany,
        },
        onResponse(response) {
          if (!response.success) {
            return reject(response.error);
          }

          resolve(response.data.billing_company);
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

/**
 * https://docs.uala.it/api/v1/billing/billing_company_products
 */

const fetchBillingCompanyProductsRequestAction = ({ venue_id, auth }) => ({
  type: BILLING_COMPANY_PRODUCTS_LIST_REQUEST,
  venue_id,
  auth,
});

const fetchBillingCompanyProductsResponseAction = ({ error, venue_id, billing_company_products, response }) => ({
  type: BILLING_COMPANY_PRODUCTS_LIST_RESPONSE,
  venue_id,
  billing_company_products,
  response,
  error,
});

export const fetchBillingCompanyProducts = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(fetchBillingCompanyProductsRequestAction({ venue_id, auth }));

    // https://docs.uala.it/api/v1/billing/billing_company_products
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/billing/billing_company_products.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(fetchBillingCompanyProductsResponseAction({ error: response.info && response.info[0] }));
          }

          dispatch(
            fetchBillingCompanyProductsResponseAction({
              venue_id,
              billing_company_products: response.data.billing_company_products,
              response,
            })
          );
        },
        onError(error) {
          dispatch(fetchBillingCompanyProductsResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchBillingCompanyProductsIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().billingCompanyProductsByVenue[venue_id])) {
      return dispatch(fetchBillingCompanyProducts());
    }
  };
};

export const fetchBillingCompanyProductsActivePlan = () => {
  return (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiGet({
          path: `/venues/${venue_id}/billing/billing_company_products.json`,
          handleAllResponses: true,
          data: {
            current_plan_only: true,
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            return resolve({
              billing_company_products: response.data.billing_company_products,
            });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });
};

/**
 * https://docs.uala.it/api/v1/billing_addons
 */

const fetchBillingAddonsRequestAction = ({ venue_id, auth }) => ({
  type: BILLING_ADDONS_LIST_REQUEST,
  venue_id,
  auth,
});

const fetchBillingAddonsResponseAction = ({ error, venue_id, billing_addons, response }) => ({
  type: BILLING_ADDONS_LIST_RESPONSE,
  venue_id,
  billing_addons,
  response,
  error,
});

export const fetchBillingAddons = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(fetchBillingAddonsRequestAction({ venue_id, auth }));

    // https://docs.uala.it/api/v1/billing_addons
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/billing/billing_addons.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(fetchBillingAddonsResponseAction({ error: response.info && response.info[0] }));
          }

          dispatch(
            fetchBillingAddonsResponseAction({
              venue_id,
              billing_addons: response.data.billing_addons,
              response,
            })
          );
        },
        onError(error) {
          dispatch(fetchBillingAddonsResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchBillingAddonsIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().billingAddonsByVenue[venue_id])) {
      return dispatch(fetchBillingAddons());
    }
  };
};

/**
 * @see: https://docs.uala.it/api/v1/billing/billing_addons
 */
export const buyBillingAddon =
  ({ id, billing_addon_option_id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiPost({
          path: `/venues/${venue_id}/billing/billing_addons/${id}/buy.json`,
          auth: auth,
          data: {
            ...(billing_addon_option_id ? { billing_addon_option_id: billing_addon_option_id } : {}),
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            resolve({ ...response.data });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const confirmBillingAddon = (confirmation_token) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth || !confirmation_token) {
      return reject({ code: 400 });
    }

    dispatch(
      apiPost({
        method: 'PUT',
        path: `/venues/${venue_id}/billing/billing_addons/confirm.json`,
        auth: auth,
        data: {
          confirmation_token,
        },
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve({ ...response.data });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

/**
 * https://docs.uala.it/api/v1/billing/billing_company_invoices
 */

const fetchBillingCompanyInvoicesRequestAction = ({ venue_id, auth }) => ({
  type: BILLING_COMPANY_INVOICES_LIST_REQUEST,
  venue_id,
  auth,
});

const fetchBillingCompanyInvoicesResponseAction = ({ error, venue_id, billing_company_invoices, response }) => ({
  type: BILLING_COMPANY_INVOICES_LIST_RESPONSE,
  venue_id,
  billing_company_invoices,
  response,
  error,
});

export const fetchBillingCompanyInvoices = (params) => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(fetchBillingCompanyInvoicesRequestAction({ venue_id, auth }));

    // https://docs.uala.it/api/v1/billing/billing_company_invoices
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/billing/billing_company_invoices.json`,
        auth: auth,
        ...(params
          ? {
              data: {
                ...(params.uploaded_from_date ? { uploaded_from_date: params.uploaded_from_date } : null),
                ...(params.uploaded_to_date ? { uploaded_to_date: params.uploaded_to_date } : null),
                ...(params.paid ? { paid: params.paid } : null),
                ...(params.not_paid ? { not_paid: params.not_paid } : null),
              },
            }
          : null),
        onResponse(response) {
          if (!response.success) {
            return dispatch(fetchBillingCompanyInvoicesResponseAction({ error: response.info && response.info[0] }));
          }

          dispatch(
            fetchBillingCompanyInvoicesResponseAction({
              venue_id,
              billing_company_invoices: response.data.billing_company_invoices,
              response,
            })
          );
        },
        onError(error) {
          dispatch(fetchBillingCompanyInvoicesResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchBillingCompanyInvoicesIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().billingCompanyInvoicesByVenue[venue_id])) {
      return dispatch(fetchBillingCompanyInvoices());
    }
  };
};

export const scheduleBillingCompanyInvoiceAutomaticPayment = (invoice_id) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());

    if (!venue_id) {
      return reject({ code: 400 });
    }

    dispatch(
      apiPost({
        method: 'PUT',
        path: `/venues/${venue_id}/billing/billing_company_invoices/${invoice_id}/schedule_automatic_payment.json`,

        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve({ ...response.data });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

export const scheduleAllBillingCompanyInvoiceAutomaticPayment = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());

    if (!venue_id) {
      return reject({ code: 400 });
    }

    dispatch(
      apiPost({
        method: 'PUT',
        path: `/venues/${venue_id}/billing/billing_company_invoices/schedule_automatic_payment_all.json`,

        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve({ ...response.data });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

/**
 * https://docs.uala.it/api/v1/billing/venue_transfers
 */

const fetchVenuesTransfersRequestAction = ({ venue_id, auth }) => ({
  type: VENUES_TRANSFERS_LIST_REQUEST,
  venue_id,
  auth,
});

const fetchVenuesTransfersResponseAction = ({ error, venue_id, venue_transfers, response }) => ({
  type: VENUES_TRANSFERS_LIST_RESPONSE,
  venue_id,
  venue_transfers,
  response,
  error,
});

export const fetchVenuesTransfers = (params) => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(fetchVenuesTransfersRequestAction({ venue_id, auth }));

    // https://docs.uala.it/api/v1/billing/venue_transfers
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/billing/venue_transfers.json`,
        auth: auth,
        ...(params
          ? {
              data: {
                ...(params.from_date ? { from_date: params.from_date } : null),
                ...(params.to_date ? { to_date: params.to_date } : null),
                ...(params.disposed ? { disposed: params.disposed } : null),
                ...(params.not_disposed ? { not_disposed: params.not_disposed } : null),
                ...(params.notified ? { notified: params.notified } : null),
                ...(params.not_notified ? { not_notified: params.not_notified } : null),
              },
            }
          : null),
        onResponse(response) {
          if (!response.success) {
            return dispatch(fetchVenuesTransfersResponseAction({ error: response.info && response.info[0] }));
          }

          dispatch(
            fetchVenuesTransfersResponseAction({
              venue_id,
              venue_transfers: response.data.venue_transfers,
              response,
            })
          );
        },
        onError(error) {
          dispatch(fetchVenuesTransfersResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchVenuesTransfersIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().venuesTransfersByVenue[venue_id])) {
      return dispatch(fetchVenuesTransfers());
    }
  };
};
