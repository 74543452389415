import { Customer } from './customer';
import { StaffMember } from './staffMember';
import { ValueOf } from 'utils/utilityTypes';

const PATCH_TEST_STATUS = {
  PENDING: 'pending',
  PERFORMED: 'performed',
  SIGNED: 'signed',
  MISSING: 'missing',
  NOT_REQUIRED: 'not-required',
} as const;

export type PatchTestStatus = ValueOf<typeof PATCH_TEST_STATUS>;

export type PatchTest = {
  id: number;
  customer: Pick<Customer, 'first_name' | 'id' | 'last_name'>;
  expires_at: string;
  performed_at: null;
  result: null;
  signed_at: null;
  staff_member: Pick<StaffMember, 'id' | 'first_name' | 'last_name'>;
  state: PatchTestStatus;
  time: string; // TODO ISOTime['DateTime']
  venue_id: number;
  venue_treatment_id: number;
  time_no_tz: string; // TODO ISOTime['DateTimeNoTZ']
  /**
   * @description
   * metadata not used in the frontend, should be reviewed in: https://wahanda.atlassian.net/browse/TWPRO-6975
   */
  time_datepart: string;
  /**
   * @description
   * metadata not used in the frontend, should be reviewed in: https://wahanda.atlassian.net/browse/TWPRO-6975
   */
  time_timepart: string;
};

export { PATCH_TEST_STATUS };
