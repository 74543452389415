import { treatwellProTheme } from '@uala/ui-treatwell-pro';

const PALETTE_COLORS = {
  white: '#FFFFFF',
  snowWhite: '#FAFAFA',
  darkSnowWhite: '#F6F6F6',
  black: '#000000',
  blue: '#4A93F9',
  blue2: '#B2E4F1',
  blue3: '#25849E',
  blue4: '#8EBBFC',
  blue5: '#0F708A',
  blueTW: '#4036ED',
  blueScore: '#3B81E3',
  lightBlue: '#D1E4FE',
  lightBlue2: '#EBF3FF',
  lightBlue3: '#92DCF0',
  darkBlue: '#283648',
  darkBlue2: '#8E93AB',
  red: '#F96B6B',
  red2: '#FCA0A0',
  red3: '#FEE6E6',
  red4: '#D0021B',
  red5: 'rgba(208,2,27,0.7)',
  red6: '#F77778',
  transparentRed: 'rgba(249, 107, 107, 0.1)',
  green: '#56C699',
  green2: '#AFE5CF',
  green3: '#0B8856',
  green4: '#CBF1E2',
  green5: '#108A59',
  green6: '#2C9753',
  yellow: '#F8F398',
  yellow2: '#FAF8D2',
  yellow3: '#726D0E',
  yellow4: '#FDFABF',
  yellow5: '#F0D408',
  yellow6: '#FFEA30',
  yellow7: '#FFCB4D',
  orange: '#F5A623',
  orange2: '#FF8F00',
  orange3: '#EB7D17',
  gray: '#E9ECF2',
  gray2: '#F9F9FA',
  gray3: '#D6D6D6',
  gray4: '#989DB9',
  gray5: '#BBBBBB',
  gray6: '#DADCDE',
  gray7: '#BCC1CC',
  gray8: '#616975',
  gray9: '#ECEEF2',
  gray10: '#A5AABE',
  gray11: 'rgba(173, 193, 200, 0.9)',
  grayTW: '#838CA3',
  lightGray: '#F6F7F9',
  lightGray2: '#F1F3F5',
  lightGray3: '#DCE0E8',
  lightGrayTW: '#E6E8ED',
  lightGrayTW2: '#E6E6E6',
  'navy.100': treatwellProTheme.colors['navy.100'],
  'navy.200': treatwellProTheme.colors['navy.200'],
  'navy.500': treatwellProTheme.colors['navy.500'],
  'navy.700': treatwellProTheme.colors['navy.700'],
  'navy.800': treatwellProTheme.colors['navy.800'],
  'blue.600': treatwellProTheme.colors['blue.600'],
} as const;

const COMPONENT_COLORS = {
  blockBorder: PALETTE_COLORS.gray,
  bgWhite: PALETTE_COLORS.white,
  buttonPrimaryColor: PALETTE_COLORS.white,
  buttonPrimaryBackground: PALETTE_COLORS.blue,
  buttonPrimaryBorder: PALETTE_COLORS.blue,
  text1: PALETTE_COLORS.darkBlue,
  text2: PALETTE_COLORS.darkBlue2,
  bgLightGray: PALETTE_COLORS.lightGray,
  bgGray: PALETTE_COLORS.lightGray2,
  inputBorder: PALETTE_COLORS.lightGray3,
  inputPlaceHolder: PALETTE_COLORS.gray10,
  buttonSecondaryColor: PALETTE_COLORS.white,
  buttonSecondaryBackground: PALETTE_COLORS.black,
  buttonSecondaryBorder: PALETTE_COLORS.black,
  buttonDangerColor: PALETTE_COLORS.white,
  buttonDangerBackground: PALETTE_COLORS.red,
  buttonDangerBorder: PALETTE_COLORS.red,

  progressColor: PALETTE_COLORS.blue,
  progressBg: PALETTE_COLORS.lightGray3,
  progressBg2: PALETTE_COLORS.blueScore,

  /**
   * @deprecated
   * replace with lightGray
   */
  grey: PALETTE_COLORS.lightGray,

  // new Colors
  'Greys/200': '#DCE0E8',
} as const;

export const COLORS = { ...PALETTE_COLORS, ...COMPONENT_COLORS } as const;
