import PropTypes from 'prop-types';
import Icon from 'components/core/Icon';
import { FieldIcon as StyledFieldIcon } from './theme';

const FieldIcon = (props) => (
  <StyledFieldIcon className="FieldIcon" {...props}>
    <Icon {...props} data-testid={`${props['data-testid'] ?? 'FieldIcon'}-icon`} onClick={null} />
  </StyledFieldIcon>
);

FieldIcon.displayName = 'FieldIcon';

FieldIcon.defaultProps = {
  type: 'heart',
  disabled: false,
};

FieldIcon.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  background: PropTypes.string,
  button: PropTypes.bool,
  circle: PropTypes.bool,
  empty: PropTypes.bool,
  size: PropTypes.number,
  iconSize: PropTypes.number,
};

export default FieldIcon;
