export function memoize<K, V>(fn: (key: K) => V): (key: K) => V | undefined {
  const cache = new Map<K, V>();
  return (key): V | undefined => {
    if (key === null || key === undefined) {
      return;
    }
    if (!cache.has(key)) {
      cache.set(key, fn(key));
    }
    return cache.get(key);
  };
}
