import { styled, css } from 'styles';

import { withTheme } from 'components/core';

export const StyledInputTag = withTheme(styled('input')`
  outline: none;
  background: inherit;
  padding: 0;
  margin: 0;
  cursor: text;
  border: none;
  font-size: 16px;
  font-family: ${({ theme }) => theme.utils.fontFamily};
  flex: 1;
  width: 100%;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `}
`);
