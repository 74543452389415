/* eslint-disable no-restricted-imports */
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeResponseToJSON } from 'API/http.utils';
import { CustomersMissDetailsQuery, CustomersMissDetailsResponse, CustomersMissDetailsSearchParams } from 'API/types';

export const MISS_DETAILS =
  ({ variables: { id, date } }: V<CustomersMissDetailsQuery>) =>
  (): Promise<CustomersMissDetailsResponse> =>
    Http()
      .get<CustomersMissDetailsResponse, CustomersMissDetailsSearchParams>(ENDPOINT.CUSTOMERS._MISS_DETAILS(id))({
        searchParams: { date },
      })
      .then(maybeResponseToJSON);
