/* eslint-disable no-restricted-imports */
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeResponseToJSON } from 'API/http.utils';
import { ATCUDSearchParams, ATCUDResponse, ATCUDUpdateVariables, ATCUDCreateUpdatePayload } from 'API/types';

export const ATCUD_UPDATE =
  ({ variables: { id, venue_atcud_code } }: V<ATCUDUpdateVariables>) =>
  (): Promise<ATCUDResponse> =>
    Http()
      .put<ATCUDResponse, ATCUDSearchParams, ATCUDCreateUpdatePayload>(ENDPOINT.ATCUDS._UPDATE(id))({
        body: { venue_atcud_code },
      })
      .then(maybeResponseToJSON);
