import { useState } from 'react';
import { Rating } from 'react-simple-star-rating';
import EmptyStar from 'svg/empty-star';
import FullStar from 'svg/full-star';
import { RatingStarsWrapper } from './RatingStars.theme';

interface RatingStarsProps {
  value?: number;
  allowHalfIcon?: boolean;
  onRate: (rate: number) => void;
}

const RatingStars = ({ value = 0, onRate, allowHalfIcon = false }: RatingStarsProps): JSX.Element => {
  const [rating, setRating] = useState(value);

  const handleRating = (rate: number): void => {
    setRating(rate);
    onRate(rate);
  };

  return (
    <RatingStarsWrapper>
      <Rating
        onClick={handleRating}
        initialValue={rating}
        fillIcon={<FullStar />}
        emptyIcon={<EmptyStar />}
        allowFraction={allowHalfIcon}
      />
    </RatingStarsWrapper>
  );
};

export default RatingStars;
