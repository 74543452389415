import { PropsWithChildren, CSSProperties } from 'react';
import { Badge as StyledBadge } from './theme';

type BadgeProps = {
  onClick?: () => void;
  basic?: boolean;
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  color?: string;
  size?: string;
  floating?: string | boolean;
  fontSize?: number;
  block?: boolean;
  dataTestId?: string;
  style?: CSSProperties;
  width?: string;
};

const Badge = ({ children, dataTestId, ...rest }: PropsWithChildren<BadgeProps>): JSX.Element => (
  <StyledBadge className="Badge" {...rest} data-testid={dataTestId}>
    {children}
  </StyledBadge>
);

Badge.displayName = 'Badge';

export { Badge };
