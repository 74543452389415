/**
 * Increase precision by 1 and round twice the incoming value in order to avoid rounding errors.
 * @param {number} number - the number to be rounded
 *
 * @example
 * // x is the value to be rounded
 * safeRound(x * 100) / 100;
 *
 * @returns - the value double rounded
 */
function safeRound(number: number): number {
  return Math.round(Math.round(number * 10) / 10);
}

export default safeRound;
