export const hasPushNotificationsCapabilities = (): boolean => {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers['getDeviceToken']) {
    return true;
  }

  return false;
};

type InitArgs = {
  onTokenUpdated: (deviceToken: string) => void;
  onClickNotification: <A>(data: A) => void;
};

export const initPushNotifications = async ({ onTokenUpdated, onClickNotification }: InitArgs): Promise<boolean> => {
  if (hasPushNotificationsCapabilities()) {
    /**
     * Setup window method to receive deviceToken
     * iOS wrappper will call this method later:
     * `window.deviceTokenUpdated('the device token from iOS wrapper')`
     */
    window.deviceTokenUpdated = (deviceToken) => {
      onTokenUpdated(deviceToken);
      return;
    };

    /**
     * Setup window method to manage custom data from push notifications
     */
    window.pushNotificationOpened = (data) => {
      try {
        if (data && data.custom && data.custom.a) {
          onClickNotification(data.custom.a);
        }
      } catch (e) {
        console.error(encodeURIComponent);
      }
      return;
    };

    const getDeviceTokenHandler = window.webkit.messageHandlers['getDeviceToken'];
    getDeviceTokenHandler.postMessage({});
    return true;
  }

  return false;
};

export const stopPushNotifications = async () => {};
