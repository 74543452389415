import { css, styled } from 'styles';
import Field, { fieldSizes } from 'components/core/Field/theme';
import { withTheme } from '../Context';

const paddingSmall = 7;
const paddingLarge = 10;

const sizeStyles = {
  default: css`
    width: ${fieldSizes.height.default}px;
    padding: ${paddingSmall}px;
  `,
  small: css`
    width: ${fieldSizes.height.small}px;
    padding: ${paddingSmall}px;
  `,
  large: css`
    width: ${fieldSizes.height.large}px;
    padding: ${paddingLarge}px;
  `,
};

export const FieldIcon = withTheme(
  styled(Field)(
    ({
      theme: { colors },
      size,
      iconSize,
      flex,
      button,
      active,
      background,
      circle,
      color,
      empty,
      width,
      height,
    }) => css`
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${background ?? 'inherit'};
      text-align: center;
      ${(size && sizeStyles[size]) || sizeStyles.default};
      ${height && `height: ${height}`};
      ${width && `width: ${width}`};

      ${button || circle
        ? css`
            border-radius: 100%;
          `
        : css`
            border-radius: 0;
            border-color: transparent;

            &:last-child {
              margin-right: -${size === 'large' ? paddingLarge : paddingSmall}px;
            }

            &:first-of-type {
              margin-left: -${size === 'large' ? paddingLarge : paddingSmall}px;
            }

            &:first-of-type:last-child {
              margin-left: initial;
            }
          `}

      ${button
        ? css`
            border-color: ${colors[color] || colors.buttonPrimaryBackground};
          `
        : css``}


      flex: ${flex ? '1' : 'none'};

      .Icon {
        ${iconSize && `width: ${iconSize}px`};

        path {
          fill: ${button || active ? colors[color] || colors.buttonPrimaryBackground : empty ? 'none' : colors.text2};
        }
      }
    `
  )
);
