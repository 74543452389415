import { ValueOf } from 'utils/utilityTypes';

// Generic Entities
export enum AvailableValueTypes {
  AMOUNT = 'amount',
  NUMBER = 'number',
  PERCENTAGE = 'percentage',
  DAYS = 'days',
}

export type ComparableEntity = {
  main: number;
  comparison: number | null;
  difference_percentage: number | null;
};

export enum AvailableExtractionTypes {
  EXTRACTION_TYPE_FISCAL = 'fiscal',
  EXTRACTION_TYPE_APPOINTMENT = 'appointment',
}

export type CompanyProfitability = {
  venue_id: number;
  venue_name: string;
  revenues_amount: number;
  services_average_fiche_amount: number;
  products_purchases_over_passages_percentage: number;
  promotions_percentage: number;
};

// EP Request/Response related shapes

export type RequestCompanyDataArgs = {
  venue_ids: number[];
  main_period_from: string;
  main_period_to: string;
  comparison_period_from?: string;
  comparison_period_to?: string;
  typology_id?: number;
  with_quotes?: boolean;
};

export type CompanyRecapResponse = {
  average_fiche_amount: ComparableEntity;
  revenues_amount: ComparableEntity;
  num_passages_number: ComparableEntity;
  services_for_fiche_number: ComparableEntity;
  male_num_passages_number: ComparableEntity;
  female_num_passages_number: ComparableEntity;
};

export type CompanyProfitabilityResponse = CompanyProfitability[];

export type MultiServicesService = {
  service_name: string;
  revenues_percentage: number;
};

export type MultiServicesVenue = {
  venue_id: number;
  venue_name: string;
  services: MultiServicesService[];
};

export type MultiServicesTypology = {
  venue_typology_name: string;
  venues: MultiServicesVenue[];
};

export type MonoServiceCategory = {
  id: number;
  name: string;
  quantity_number: number;
  average_fiche_number: number;
  revenues_amount: number;
  treatments_over_passages_percentage: number;
  treatments_over_services_percentage: number;
};

export type MonoServicesTypology = {
  id: number;
  name: string;
  categories: MonoServiceCategory[];
  total_quantity_number: number;
  total_average_fiche_number: number;
  total_revenues_amount: number;
};

export type CompanyServicesResponse<Typology> = {
  typologies: Typology[];
};

export type MonoServiceDetails = {
  id: number;
  name: string;
  quantity_number: number;
  average_fiche_number: number;
  revenues_amount: number;
  treatments_over_services_percentage: number;
};

export type CompanyMonoServiceDetailsResponse = {
  category_id: number;
  category_name: string;
  services: MonoServiceDetails[];
};

export type CompanyRecapGenderResponse = Readonly<Record<ValueOf<typeof GENDER>, GenderDetail>>;

export type GenderDetail = Readonly<{
  average_frequency_days: number;
  average_fiche_amount: number;
  revenues_amount: number;
  num_passages_number: number;
  new_customers_number: number;
  num_passage_percentage: number;
  new_cherry_picked_staff_customers_number: number;
  total_cherry_picked_staff_customers_number: number;
}>;

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
} as const;

export type TreatmentTypology = {
  revenues_amount: number;
  num_passages_number: number;
  average_fiche_amount: number;
  products_revenues_amount: number;
  products_average_fiche_amount: number;
  average_frequency_days: number;
};

export type CompanyRecapByTreatmentTypology = {
  average_fiche_amount: ComparableEntity;
  revenues_amount: ComparableEntity;
  num_passages_number: ComparableEntity;
  spa_massages: Pick<TreatmentTypology, 'average_fiche_amount' | 'revenues_amount' | 'products_revenues_amount'>;
  hairdresser: TreatmentTypology;
  aestethic: TreatmentTypology;
  others: {
    products_revenues_amount: number;
    debts_revenues_amount: number;
  };
  promotions: {
    revenues_amount: number;
  };
};
