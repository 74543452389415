import { useEffect } from 'react';
import AlertBanner from 'components/lib/AlertBanner';
import { styled } from 'styles';

const BannerWrapper = styled('div')`
  .AlertBanner {
    position: fixed;
    top: 0;
    left: 15px;
    right: 15px;
    z-index: 10000;
  }
`;

const Banner = ({ closeModal, config }) => {
  useEffect(() => {
    setTimeout(() => closeModal(), 3000);
  });

  return (
    <BannerWrapper>
      <AlertBanner
        show
        type={config.type || 'red'}
        position="top"
        message={config.content}
        messageValues={config.contentValues}
      />
    </BannerWrapper>
  );
};

export default Banner;
