import { FunctionComponent } from 'react';
import { Text } from 'components/core';
import { Cell, CellTitle } from 'components/core/TableView';

type Props = {
  item: {
    title: string;
  };
};

const TableViewSectionTitle: FunctionComponent<Props> = ({ item }) => {
  return (
    <Cell separator>
      <CellTitle>
        <Text size={14} uppercase spacing={1}>
          {item.title}
        </Text>
      </CellTitle>
    </Cell>
  );
};

export default TableViewSectionTitle;
