export const BREAKPOINTS = {
  /**
   * @deprecated
   */
  smallMobile: 320,
  smartphone: 480,
  /**
   * @deprecated
   */
  breakpointM: 560,
  /**
   * @deprecated
   */
  mobileOrSmaller: 767,
  tablet: 768,
  /**
   * @deprecated
   */
  tabletOrSmaller: 1023,
  desktop: 1024,
  /**
   * @deprecated
   */
  desktopOrSmaller: 1479,
  largeDesktop: 1480,
  veryLargeDesktop: 1800,
} as const;
