import { AnalyticsBrowser } from '@segment/analytics-next';

const CHECKOUT = 'checkout';
const AGENDA = 'agenda';
const REVIEWS = 'reviews';
const TIMETABLES = 'timetables';
const TWSTORE = 'twstore';
const BROWSE = 'browse';

const isAgenda = (path: string) => path.includes(AGENDA);
const isCheckout = (path: string) => path.startsWith(CHECKOUT);
const isReviews = (path: string) => path.startsWith(REVIEWS);
const isTimeTables = (path: string) => path.includes(TIMETABLES);
const isStore = (path: string) => path.includes(TWSTORE);

const agendaRules = (path: string): string | undefined => {
  const browsePattern = /agenda\/(\d{4}-\d{2}-\d{2})/;
  if (path.match(browsePattern)) return `${AGENDA}/${BROWSE}`;
  if (isAgenda(path)) return AGENDA;
  return undefined;
};

const checkoutRules = (path: string): string | undefined => {
  if (path.includes(`${CHECKOUT}/cart`)) return `${CHECKOUT}/cart`;
  if (path.includes(`${CHECKOUT}/checkout`)) return `${CHECKOUT}/checkout`;
  if (path.includes('billable')) return `${CHECKOUT}/billable`;
  if (isCheckout(path)) return CHECKOUT;
  return undefined;
};

const reviewsRules = (path: string): string | undefined => {
  if (path.includes(`${REVIEWS}/`)) return `${REVIEWS}/${BROWSE}`;
  if (isReviews(path)) return REVIEWS;
  return undefined;
};

const timeTablesRules = (path: string): string | undefined => {
  if (path.includes(`${TIMETABLES}/day`)) return `${TIMETABLES}/${BROWSE}`;
  if (path.includes(`${TIMETABLES}/extra-openings`)) return path;
  if (path.includes(`${TIMETABLES}/extra-closing`)) return path;
  if (isTimeTables(path)) return TIMETABLES;
  return undefined;
};

const storeRules = (path: string): string | undefined => {
  if (isStore(path)) return replaceAlphanumericIds(path);
  return undefined;
};

const managerRules = (path: string): string | undefined => {
  if (path.includes('manager/company-report/sales-proceeds')) return 'manager/company-report/sales-proceeds';
  return undefined;
};

const replaceAlphanumericIds = (path: string) => {
  const lettersAndNumberRegex = /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]+$/;
  return path
    .split('/')
    .reduce((result, part) => {
      return [...result, lettersAndNumberRegex.test(part) ? BROWSE : part];
    }, [] as string[])
    .join('/');
};

const replaceURLIdsWithBrowseRule = (path: string) => {
  return path.replace(/\/(\d+)(?![\d-])/g, `/${BROWSE}`);
};

const removeSlashAtBoundaryRule = (path: string) => {
  if (path.startsWith('/')) {
    path = path.slice(1);
  }
  if (path.endsWith('/')) {
    path = path.slice(0, -1);
  }
  return path;
};

const stripQueryParamsFromPathnameRule = (pathname: string): string => {
  const queryStringIndex = pathname.indexOf('?');
  if (queryStringIndex !== -1) {
    return pathname.substring(0, queryStringIndex);
  }
  return pathname;
};

const testENVRule = (pathname: string): string => {
  const testEnvPatter = /version\/[^/]+\/[^/]+\//;
  return pathname.replace(testEnvPatter, '');
};

const sanitizeURL = (pathname: string): string => {
  const globalRules = [
    replaceURLIdsWithBrowseRule,
    removeSlashAtBoundaryRule,
    stripQueryParamsFromPathnameRule,
    testENVRule,
  ];
  return globalRules.reduce((result: string, rule) => rule(result), pathname);
};

const getPageNameByPathname = (pathname: string = ''): string => {
  const path = sanitizeURL(pathname);
  const rules = [agendaRules, checkoutRules, reviewsRules, timeTablesRules, storeRules, managerRules];
  const pathWithRules = rules.reduce(
    (result: undefined | string, rule) => (result !== undefined ? result : rule(path)),
    undefined
  );
  return pathWithRules ?? path;
};

const isTrackingServiceUp = (trackingService: AnalyticsBrowser | undefined): trackingService is AnalyticsBrowser =>
  typeof trackingService !== 'undefined';

const isValidVenueId = (id: number | null): id is number => typeof id === 'number';

export { isTrackingServiceUp, isValidVenueId, getPageNameByPathname };
