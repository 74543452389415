import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

export const serviceGoodCategories = (state = reducerValuesOnInit(), action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case 'SERVICE_GOOD_CATEGORIES_LIST_REQUEST':
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case 'SERVICE_GOOD_CATEGORIES_LIST_RESPONSE':
      if (!action.service_good_categories) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.service_good_categories), {
          overrideDuplicateIds: 1,
        }),
      };

    default:
      return state;
  }
};
