import moment from 'moment';
import {
  VENUE_CHART_DATA_REQUEST,
  VENUE_CHART_DATA_RESPONSE,
  VENUE_CHART_QUERY_PARAMS,
  VENUE_INVENTORY_DATA_REQUEST,
  VENUE_INVENTORY_DATA_RESPONSE,
  VENUE_INVENTORY_QUERY_PARAMS,
} from 'actionTypes';

/** MANAGER */

const chartData = (state = {}, action) => {
  switch (action.type) {
    case VENUE_CHART_DATA_REQUEST:
      return { ...state, loading: true };

    case VENUE_CHART_DATA_RESPONSE:
      if (!action.response) {
        return state;
      }
      return { ...state, loading: false, ...action.response };

    default:
      return state;
  }
};

export const chartDataByKey = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_CHART_DATA_REQUEST:
    case VENUE_CHART_DATA_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }
      const chartDataKey =
        action.venue_id +
        '/' +
        action.path +
        '?' +
        Object.keys(action.params || {})
          .map((k) => k + '=' + action.params[k])
          .join('&')
          .toLowerCase();

      refState = chartData(state[chartDataKey], action);

      if (refState === state[chartDataKey]) {
        return state;
      }

      return {
        ...state,
        [chartDataKey]: refState,
      };

    default:
      return state;
  }
};

const defaultChartQueryParams = {
  dateRangeType: 'lastmonth',
  startDate: moment().date(1).subtract(1, 'month'),
  endDate: moment().date(1).subtract(1, 'days'),
  dateComparison: false,
  withQuotes: false,
  dateComparisonType: '52weeksbefore',
};

defaultChartQueryParams.comparisonStartDate = defaultChartQueryParams.startDate.clone().subtract(52, 'weeks');
defaultChartQueryParams.comparisonEndDate = defaultChartQueryParams.endDate.clone().subtract(52, 'weeks');

export const chartQueryParams = (state = defaultChartQueryParams, action) => {
  const { params } = action;

  switch (action.type) {
    case VENUE_CHART_QUERY_PARAMS:
      if (!params) {
        return state;
      }

      return {
        ...state,
        ...params,
      };

    default:
      return state;
  }
};

/** INVENTORY */

const inventoryData = (state = {}, action) => {
  switch (action.type) {
    case VENUE_INVENTORY_DATA_REQUEST:
      return { ...state, loading: true };

    case VENUE_INVENTORY_DATA_RESPONSE:
      if (!action.response) {
        return state;
      }
      return { ...state, loading: false, ...action.response };

    default:
      return state;
  }
};

export const inventoryDataByKey = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_INVENTORY_DATA_REQUEST:
    case VENUE_INVENTORY_DATA_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }
      const inventoryDataKey =
        action.venue_id +
        '/' +
        action.path +
        '?' +
        Object.keys(action.params || {})
          .map((k) => k + '=' + action.params[k])
          .join('&')
          .toLowerCase();

      refState = inventoryData(state[inventoryDataKey], action);

      if (refState === state[inventoryDataKey]) {
        return state;
      }

      return {
        ...state,
        [inventoryDataKey]: refState,
      };

    default:
      return state;
  }
};

const defaultInventoryQueryParams = {
  date: moment(),
};

export const inventoryQueryParams = (state = defaultInventoryQueryParams, action) => {
  const { params } = action;

  switch (action.type) {
    case VENUE_INVENTORY_QUERY_PARAMS:
      if (!params) {
        return state;
      }

      return {
        ...state,
        ...params,
      };

    default:
      return state;
  }
};
