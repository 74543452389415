import { ISOTime } from 'utils/dates';
import { ValueOf } from 'utils/utilityTypes';

export const NEWSLETTER_STATES = {
  SCHEDULED: 'scheduled',
  TEMPLATE: 'template',
  DRAFT: 'draft',
  SENT: 'sent',
  DELETED: 'deleted',
} as const;

export const NEWSLETTER_SEND_MODES = {
  IMMEDIATE: 'immediate',
  SCHEDULED: 'scheduled',
  CYCLIC: 'cyclic',
} as const;

export type Newsletter = {
  id: number;
  name: string;
  state: ValueOf<typeof NEWSLETTER_STATES>;
  send_mode: Nullable<ValueOf<typeof NEWSLETTER_SEND_MODES>>;

  scheduled_at: Nullable<ISOTime['DateTime']>;

  draft: boolean;
  cyclic: Nullable<boolean>;
  enabled: boolean;

  content: string;

  copy_name?: string;
  copy?: boolean;

  filters?: NewsletterFilter[];
};

export type NewsletterFilter = {
  key: ValueOf<typeof NEWSLETTER_FILTERS_KEY>;
  values: string[];
};

export const NEWSLETTER_FILTERS_KEY = {
  EXCLUDE_CLIENT_IDS: 'exclude_client_ids',
} as const;
