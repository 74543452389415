export enum COUNTER_RESET_MODE {
  NONE = 'none',
  DAILY = 'daily',
  YEARLY = 'yearly',
  BILLING_MONTH = 'billing_month',
  BILLING_YEAR = 'billing_year',
}

export enum COUNTER_COUNTABLE_KEY {
  VENUE_BOOKINGS = 'venue.bookings',
  VENUE_CUSTOMER_SMS_NOTIFICATIONS = 'venue.customer_sms_notifications',
  CHECKOUT_RECEIPT = 'checkout.receipt',
  CHECKOUT_SERVICES_RECEIPT = 'checkout.services_receipt',
  CHECKOUT_PRODUCTS_RECEIPT = 'checkout.products_receipt',
  CHECKOUT_PROMOTIONS_RECEIPT = 'checkout.promotions_receipt',
  CHECKOUT_INVOICE = 'checkout.invoice',
  CHECKOUT_QUOTE = 'checkout.quote',
  CHECKOUT_CANCELLATION = 'checkout.cancellation',
  CHECKOUT_RECEIPT_CANCELLATION = 'checkout.receipt_cancellation',
  CHECKOUT_SERVICES_RECEIPT_CANCELLATION = 'checkout.services_receipt_cancellation',
  CHECKOUT_PRODUCTS_RECEIPT_CANCELLATION = 'checkout.products_receipt_cancellation',
  CHECKOUT_PROMOTIONS_RECEIPT_CANCELLATION = 'checkout.promotions_receipt_cancellation',
  CHECKOUT_INVOICE_CANCELLATION = 'checkout.invoice_cancellation',
}

export interface Counter {
  id: number;
  reset_mode: COUNTER_RESET_MODE;
  countable_key: COUNTER_COUNTABLE_KEY;
  countable_value: number;
  last_reset: string;
  created_at: string;
  updated_at: string;
}
