import * as React from 'react';
import tooltipReducer, { hide, initialState, show, TooltipState, update } from './TooltipContext.reducer';

interface TooltipContextValues extends TooltipState {
  readonly showTooltip: () => void;
  readonly hideTooltip: () => void;
  readonly setupTooltip: ({
    top,
    left,
    content,
    width,
    color,
    defaultPosition,
  }: {
    readonly top: number;
    readonly left: number;
    readonly content: string;
    readonly width?: string;
    readonly color: string;
    readonly defaultPosition: string;
  }) => void;
}

export const TooltipContext = React.createContext<TooltipContextValues | null>(null);

export const TooltipProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(tooltipReducer, initialState);

  const showTooltip = (): void => dispatch(show());

  const hideTooltip = (): void => dispatch(hide());

  const setupTooltip: TooltipContextValues['setupTooltip'] = ({ top, left, content, width, color, defaultPosition }) =>
    dispatch(update({ top, left, content, width, color, defaultPosition }));

  const providerValues = React.useMemo(() => ({ ...state, showTooltip, hideTooltip, setupTooltip }), [state]);

  return <TooltipContext.Provider value={providerValues}>{children}</TooltipContext.Provider>;
};
