/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { FullTextSearchQuery, VenueProductsResponse } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const SEARCH =
  ({ variables }: V<FullTextSearchQuery>) =>
  (): Promise<VenueProductsResponse> =>
    Http()
      .get<VenueProductsResponse, FullTextSearchQuery>(ENDPOINT.VENUE_PRODUCTS._SEARCH())({
        searchParams: variables,
      })
      .then(maybeResponseToJSON);
