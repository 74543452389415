import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import MultiVenueSidebar from './MultiVenueSidebar';
import {
  getUserVenues,
  isUserMultiVenue,
  getMultiVenueSidebarDisabled,
  getMultiVenueSidebarOpen,
  getMultiVenueSidebarIsHidden,
} from 'selectors/getUserMultiVenue';
import { toggleMultiVenueSidebar } from 'actions/multiVenueSidebarSettings';

const mapStateToProps = () =>
  createSelector(
    getUserVenues,
    isUserMultiVenue,
    getMultiVenueSidebarDisabled,
    getMultiVenueSidebarOpen,
    getMultiVenueSidebarIsHidden,
    (venues, isMultiVenue, isDisabled, isOpen, isHidden) => {
      return {
        venues,
        isMultiVenue,
        isDisabled,
        isOpen,
        isHidden,
      };
    }
  );

const mapDispatchToProps = {
  dispatchToggleMultiVenueSidebar: toggleMultiVenueSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiVenueSidebar);
