import { styled, css } from 'styles';

const Avatar = styled('div')(
  () => css`
    position: relative;
    width: 105px;
    height: 105px;
    overflow: hidden;
    border-radius: 50%;
    background-color: rgba(85, 85, 85, 0.15);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 100%;
    }
  `
);

export default Avatar;
