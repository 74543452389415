import { RefObject, useCallback, useRef, useState } from 'react';
import { useOnClickOutside } from 'utils/hooks';
import { dropDownMaxHeight, dropDownPosition } from './dropdown-placement';
import { ValueOf } from 'utils/utilityTypes';

const DROPDOWN_STATE = {
  POSITION: {
    TOP: 'top',
    BOTTOM: 'bottom',
  },
} as const;

interface DropDownState {
  readonly isOpen: boolean;
  readonly position?: ValueOf<typeof DROPDOWN_STATE.POSITION>;
  readonly maxHeight?: number;
  readonly maxWidth?: number;
}

type UseDropDownProps = {
  dropDownHeight: number;
  dropDownWidth?: number;
  onClickOutside?: () => void;
};

type UseDropDownReturn<Ref extends HTMLElement> = {
  toggleDropdown: () => void;
  dropdown: DropDownState;
  dropDownRef: RefObject<Ref> | undefined;
  position: DropDownState['position'];
  maxHeight: number | undefined;
  maxWidth: number | undefined;
};

export const useDropDown = <Ref extends HTMLElement>({
  dropDownHeight,
  dropDownWidth = 400,
  onClickOutside,
}: UseDropDownProps): UseDropDownReturn<Ref> => {
  const dropDownRef = useRef<Ref>(null);
  const position = dropDownPosition(dropDownRef);
  const maxHeight = dropDownMaxHeight(dropDownRef, position, dropDownHeight);
  const maxWidth = dropDownWidth; // To-do: Replace by custom hook

  const [dropdown, setDropDown] = useState<DropDownState>({
    position: DROPDOWN_STATE.POSITION.BOTTOM,
    isOpen: false,
    maxHeight: 200,
    maxWidth: 400,
  });

  const toggleDropdown = useCallback((): void => {
    setDropDown(({ isOpen, ...x }) => {
      if (!isOpen) {
        return {
          position,
          maxHeight,
          maxWidth,
          isOpen: true,
        };
      } else {
        return {
          isOpen: false,
          ...x,
        };
      }
    });
  }, [maxHeight, maxWidth, position]);

  // --- Pointer interactions
  useOnClickOutside(dropDownRef, () => {
    onClickOutside?.();
    setDropDown((prev) => ({ ...prev, isOpen: false }));
  });

  return {
    toggleDropdown,
    dropDownRef,
    dropdown,
    position,
    maxHeight,
    maxWidth,
  };
};
