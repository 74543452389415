import { apiGetResponseAction } from 'actions/data_providers/api';
import { agendaSetActiveCustomer, agendaResetActiveCustomer } from 'actions/agendaGrid';
import { fetchCustomer } from './customers';

export const executeSynchHooks =
  ({ error = null, venue_id, path = null, response }) =>
  (dispatch, getState) => {
    /**
     * In the caller box, if customer has `id` add it to the customers list
     */
    if (response.data && response.data.venue_actions && response.data.venue_actions.length) {
      response.data.venue_actions.forEach((venue_action) => {
        if (venue_action.action_object === 'caller_popup') {
          if (venue_action.data && venue_action.data.customer && venue_action.data.customer.id) {
            dispatch(
              apiGetResponseAction({
                venue_id,
                path,
                response: {
                  customers: [venue_action.data.customer],
                },
              })
            );
            dispatch(agendaSetActiveCustomer({ customer: venue_action.data.customer }));
          } else {
            dispatch(agendaResetActiveCustomer(true));
          }
        }
      });
    }

    /**
     * when a loyalty program points transactions occours, refresh customer data to GET updated redeemable_prizes_count value
     */
    if (
      response.data &&
      response.data.marketing_promotion_points_transactions &&
      response.data.marketing_promotion_points_transactions.length
    ) {
      response.data.marketing_promotion_points_transactions
        .reduce((customer_ids, trans) => {
          if (customer_ids.indexOf(trans.customer_id) < 0) {
            customer_ids.push(trans.customer_id);
          }
          return customer_ids;
        }, [])
        .forEach((customer_id) => {
          dispatch(fetchCustomer(customer_id));
        });
    }
  };
