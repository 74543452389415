import { FunctionComponent } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import generateDocumentTitle from 'utils/generateDocumentTitle';
import { useDocumentTitle } from 'utils/hooks';
import { TS_FIXME } from 'utils/utilityTypes';

type DocumentTitleProps<A> = {
  title?: string;
  intlValues?: Record<string, A>;
};

const DocumentTitle: FunctionComponent<DocumentTitleProps<TS_FIXME> & WrappedComponentProps> = ({
  title,
  intl,
  intlValues,
}) => {
  const { formatMessage } = intl;
  const dynamicTitle = generateDocumentTitle(title ? formatMessage({ id: title }, intlValues) : undefined);

  useDocumentTitle(dynamicTitle);

  return null;
};

export default injectIntl(DocumentTitle);
