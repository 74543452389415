import { useEffect, useCallback, useMemo } from 'react';
import { injectIntl } from 'react-intl';
import PromoFlashEditDiscountSelect from './PromoFlashEditDiscountSelect';
import PromoFlashEditFilterableList from './PromoFlashEditFilterableList';

const PromoFlashEditResources = ({
  dispatchFetchVenueTreatments,
  venueTreatmentsCategoriesList,
  venueTreatmentsList,
  promoFlash,
  setLocalChanges,
  intl,
}) => {
  const { formatMessage } = intl;

  const translations = useMemo(
    () => ({
      search: formatMessage({ id: 'search' }),
    }),
    [formatMessage]
  );

  useEffect(() => {
    dispatchFetchVenueTreatments();
  }, [dispatchFetchVenueTreatments]);

  const onGlobalDiscountChange = useCallback(
    (discount_percent_amount) => {
      setLocalChanges({
        applied_to: 'all',
        included_resources_attributes: [
          {
            table_name: null,
            record_id: null,
            discount_type: 'percent',
            discount_percent_amount,
          },
        ],
      });
    },
    [setLocalChanges]
  );

  return promoFlash.applied_to === 'all' ? (
    <PromoFlashEditDiscountSelect promoFlash={promoFlash} onGlobalDiscountChange={onGlobalDiscountChange} />
  ) : (
    <PromoFlashEditFilterableList
      promoFlash={promoFlash}
      setLocalChanges={setLocalChanges}
      treatmentsCategories={venueTreatmentsCategoriesList}
      treatments={venueTreatmentsList}
      searchPlaceholder={translations.search}
    />
  );
};

export default injectIntl(PromoFlashEditResources);
