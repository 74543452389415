import { connect } from 'react-redux';
import ProvisioningUpsellingButtonComponent, { ProvisioningUpsellingButtonProps } from './ProvisioningUpsellingButton';
import { createSelector, OutputParametricSelector } from 'reselect';
import { State } from 'reducers/types';
import { getCurrentVenue, getCurrentVenueCountrySelfProvisioningEnabled } from 'selectors/getCurrentVenue';
import { Venue } from 'models';
import { UnknownFunction } from 'utils/utilityTypes';

type ProvisioningUpsellingButtonStateProps = {
  venue: Venue | null;
};

const mapStateToProps = (): OutputParametricSelector<
  State,
  ProvisioningUpsellingButtonProps,
  ProvisioningUpsellingButtonStateProps,
  UnknownFunction
> =>
  createSelector([getCurrentVenue, getCurrentVenueCountrySelfProvisioningEnabled], (venue, selfProvisioningEnabled) => {
    return {
      venue,
      selfProvisioningEnabled,
    };
  });

export const ProvisioningUpsellingButton = connect(mapStateToProps)(ProvisioningUpsellingButtonComponent);
