import { FunctionComponent } from 'react';
import { Section, Text, Icon } from 'components/core';

type AddNewCardProps = {
  onClick: () => void;
};

const AddNewCard: FunctionComponent<AddNewCardProps> = ({ onClick }) => (
  <Section
    displayFlex
    justifyContent="center"
    alignItems="center"
    padding={1.5}
    style={{ cursor: 'pointer' }}
    onClick={onClick}
    data-testid="add-new-card-button"
  >
    <Section marginRight={0.5}>
      <Icon type="add-card" color="blue" size={28} />
    </Section>
    <Text size={14} color="blue" intl="account.payment_methods.add_new_card" />
  </Section>
);

export default AddNewCard;
