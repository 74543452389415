import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import reduxPersistStorage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import { createAppReducer } from 'reducers';
import rootReducer from 'reducers/root';
import canPersistCustomers from 'utils/check/canPersistCustomers';
import getBaseFromPathName from 'utils/upgrade/getBaseFromPathName';
import localforage from 'localforage';
import { LOCATION_CHANGED_EVENT, dispatchCustomEvent } from 'customEvents';

let storage = localforage;

const STORE_KEY = 'uala-0.0.5';
const IS_STORAGE_MIGRATED = 'is_storage_migrated';

// this function is used in /data-layer/index.js
export const clearOldStorageCallback = (callback) => {
  const isStorageMigrated = Boolean(JSON.parse(window.localStorage.getItem(IS_STORAGE_MIGRATED) || false));
  if (isStorageMigrated) {
    callback();
    window.localStorage.removeItem(`persist:${STORE_KEY}`);
    window.localStorage.removeItem(IS_STORAGE_MIGRATED);
  }
};

const isIndexedDBSupported = localforage.supports(localforage.INDEXEDDB);

const oldStateJSON = window.localStorage.getItem(`persist:${STORE_KEY}`);
const userHasMigratedStorage = !oldStateJSON
  ? true
  : Boolean(JSON.parse(window.localStorage.getItem(IS_STORAGE_MIGRATED) || false));

// if oldStateJSON doesn't exist, it means the user is either new or logged out, in this case, let's use indexedDB
const shouldUseLocalForage = !oldStateJSON || (isIndexedDBSupported && userHasMigratedStorage);

if (!shouldUseLocalForage) {
  storage = reduxPersistStorage;
}

// if the user hasn't migrated to indexedDB yet, copy the state from localStorage to indexedDB
if (oldStateJSON && isIndexedDBSupported && !userHasMigratedStorage) {
  const oldState = JSON.parse(oldStateJSON);
  const newState = Object.keys(oldState).reduce((state, key) => {
    state[key] = JSON.parse(oldState[key]);
    return state;
  }, {});
  localforage.setItem(`persist:${STORE_KEY}`, newState).then(() => {
    window.localStorage.setItem(IS_STORAGE_MIGRATED, true);
    window.location.reload();
  });
  /**
   * NOTE
   * we clear the old storage by calling clearOldStorageCallback() after submitting the segment event in /data-layer/index.js
   * because it's not posisble to send events within this file
   */
}

export const history = createBrowserHistory({ basename: getBaseFromPathName(window.location.pathname) });
history.listen((location) => dispatchCustomEvent(LOCATION_CHANGED_EVENT));

const appReducer = createAppReducer(history);

const reducer = (state, action) => {
  return appReducer(rootReducer(state, action), action);
};

const middlewares = [thunk];

middlewares.push(routerMiddleware(history));

const enhancers = [applyMiddleware(...middlewares)];

const mapObject = (obj, mapper) => {
  return Object.keys(obj || {}).reduce((output, k) => {
    output[k] = {
      ...mapper(obj[k], k),
    };
    return output;
  }, {});
};

const persistConfig = {
  key: STORE_KEY,
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'sessions',
    'vacancyTypologies',
    'venueVacanciesByVenue',
    'venueExtraOpeningsByVenue',
    'venueCustomTimeTablesByVenue',
    'hideTermsByVenue',
    'venueTreatmentsByVenue',
    'venueProductsByVenue',
    'staffMembersByVenue',
    'staffMemberTreatmentsByVenue',
    'workstationsByVenue',
    'workstationTreatmentsByVenue',
    'customerCartsByVenue',
    'customersByVenue',
    'entitiesDictionaryByVenue',
  ],
  serialize: shouldUseLocalForage
    ? (data) => data
    : (data) => JSON.stringify(data, (x, v) => (x !== '_ref' ? v : undefined)),
  deserialize: shouldUseLocalForage ? (data) => data : undefined,
  transforms: [
    /**
     * 1. Remove customers if can't persist
     * (multi-venue OR mobile)
     */
    createTransform(
      (inboundState) => {
        if (canPersistCustomers()) {
          return inboundState;
        }
        return [];
      },
      (outboundState) => outboundState,
      { whitelist: ['customersByVenue'] }
    ),
    /**
     * 2. Remove inactive venue from storage
     * and `isFetching` attribute from *ByVenue's reducers
     */
    createTransform(
      (inboundState, key, fullState) => {
        // authenticatedVenuesIDs: [5, 10, 237, 424, 1002]; this is altered by its reducer
        const activeVenuesIDs = fullState.sessions.activeVenuesIDs;

        const newInboundState = activeVenuesIDs.reduce((result, id) => {
          if (inboundState[id]) {
            result[id] = inboundState[id];
          }
          return result;
        }, {});

        return mapObject(newInboundState, ({ isFetching, ...item }) => item);
      },
      (outboundState, key, fullState) => {
        // authenticatedVenuesIDs: [5, 10, 237, 424, 1002]; this is altered by its reducer
        const activeVenuesIDs = shouldUseLocalForage
          ? (fullState.sessions || {}).activeVenuesIDs
          : JSON.parse(fullState.sessions || '{}').activeVenuesIDs;

        const newOutboundState = activeVenuesIDs.reduce((result, id) => {
          if (outboundState[id]) {
            result[id] = outboundState[id];
          }
          return result;
        }, {});

        return newOutboundState;
      },
      {
        whitelist: [
          'venueVacanciesByVenue',
          'venueExtraOpeningsByVenue',
          'venueCustomTimeTablesByVenue',
          'hideTermsByVenue',
          'venueTreatmentsByVenue',
          'venueProductsByVenue',
          'staffMembersByVenue',
          'staffMemberTreatmentsByVenue',
          'workstationsByVenue',
          'workstationTreatmentsByVenue',
          'customerCartsByVenue',
          'customersByVenue',
          'entitiesDictionaryByVenue',
        ],
      }
    ),
    /**
     * 3. Remove inactive venues from permissionsByVenue
     */
    createTransform(
      (inboundState, key, fullState) => {
        const currentVenueId = inboundState.currentVenueId;
        if (!currentVenueId) {
          return inboundState;
        }

        const newInboundState = {
          ...inboundState,
          permissionsByVenue: {
            [currentVenueId]: inboundState.permissionsByVenue[currentVenueId],
          },
        };

        return newInboundState;
      },
      (outboundState) => outboundState,
      {
        whitelist: ['sessions'],
      }
    ),
    createTransform(
      (inboundState, key) => {
        return mapObject(inboundState, (obj) => ({
          ...obj,
          items: (obj.items || []).map(({ id }) => ({
            id,
          })),
        }));
      },
      (outboundState, key) => {
        return mapObject(outboundState, ({ items, updatedAt, lastCompleteFetch }) => ({
          items,
          updatedAt,
          lastCompleteFetch,
        }));
      },
      {
        whitelist: [
          'venueTreatmentsByVenue',
          'venueProductsByVenue',
          'staffMembersByVenue',
          'staffMemberTreatmentsByVenue',
          'workstationsByVenue',
          'workstationTreatmentsByVenue',
          'customersByVenue',
        ],
      }
    ),
    createTransform(
      (inboundState, key) => {
        return mapObject(inboundState, (obj) => {
          const {
            vacancy,
            custom_time_table,
            extra_opening,
            venue_treatment,
            venue_product,
            staff_member,
            staff_member_treatment,
            workstation,
            workstation_treatment,
            customer,
          } = obj;

          return {
            vacancy,
            custom_time_table,
            extra_opening,
            venue_treatment,
            venue_product,
            staff_member,
            staff_member_treatment,
            workstation,
            workstation_treatment,
            customer: canPersistCustomers()
              ? mapObject(
                  customer,
                  ({
                    id,
                    data: { first_name, last_name, phone, by_venue, terms_of_services_accepted_at, blacklisted_at },
                  }) => ({
                    i: id,
                    // n: full_name,
                    f: first_name,
                    l: last_name,
                    p: phone,
                    b: by_venue ? 1 : 0,
                    t: terms_of_services_accepted_at,
                    bl: blacklisted_at,
                  })
                )
              : {},
          };
        });
      },
      (outboundState, key, fullState) => {
        const currentVenue = shouldUseLocalForage
          ? (fullState.sessions || {}).currentVenue
          : JSON.parse(fullState.sessions || '{}').currentVenue;

        const customer_full_name_starts_with_first_name = currentVenue ? !currentVenue.customers_first_last_name : true;
        return mapObject(outboundState, (obj) => ({
          ...obj,
          customer: canPersistCustomers()
            ? mapObject(obj.customer, ({ i, f, l, p, b, t, bl }) => ({
                type: 'customer',
                id: i,
                data: {
                  first_name: f,
                  last_name: l,
                  /**
                   * it depends on how full_name is built on the backend
                   */
                  full_name: (customer_full_name_starts_with_first_name ? [f, l] : [l, f]).filter((n) => n).join(' '),
                  phone: p,
                  by_venue: b ? true : false,
                  terms_of_services_accepted_at: t,
                  blacklisted_at: bl,
                },
              }))
            : obj.customer,
        }));
      },
      { whitelist: ['entitiesDictionaryByVenue'] }
    ),
  ],
};

const isObject = (theObject) => theObject instanceof Object;

const removeRefs = (obj, maxDepth = 8) => {
  if (maxDepth < 0) return '[MAX DEPTH EXCEED]';
  if (!isObject(obj)) return obj;
  const parsedObjects = [obj];

  const newEntries = Object.entries(obj)
    .map(([key, data]) => {
      if (key !== '_ref') {
        if (!isObject(data)) return [key, data];
        else {
          if (!parsedObjects.includes(data)) {
            parsedObjects.push(data);
            return [key, removeRefs(data, maxDepth - 1)];
          } else {
            return [key, '[CIRCULAR(already parsed object)]'];
          }
        }
      }
      return undefined;
    })
    .filter((entry) => entry !== undefined);

  return Object.fromEntries(newEntries);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      actionSanitizer: (a) => removeRefs(a),
      stateSanitizer: (s) => removeRefs(s),
      actionsBlacklist: ['UPDATED_SINCE_RECEIVED'], // Blacklisted as is happening every second mostly, and add a lot of lag and noise to redux-dev-tools and make impossible to debug
    })
  : compose;

/** @typedef {import('reducers/types').State} State */
/** @typedef {import('redux').Action} Action */
/** @typedef {import('redux').Store<State, Action>} BasicAppStore */
/** @typedef {import('action/types').ActionDispatch} ActionDispatch */
/** @typedef {BasicAppStore & {dispatch: ActionDispatch}} AppStore */
/** @typedef {(state: State, action: Action, newState: State) => void} ReducerObserver */

/**
 *  @param {import('redux').Reducer<State, Action>} mainReducer
 *  @param {ReducerObserver} observer
 */
const getObservedReducer = (mainReducer, observer) => {
  return (state, action) => {
    const newState = mainReducer(state, action);
    try {
      observer(state, action, newState);
    } catch (e) {
      console.error('Error observing reducer: ', e);
    }
    return newState;
  };
};

/**
 * Intended for Test actions, it will behave similar to the real store,
 * but this builder allows to set a initial state
 * **WARNING** it won't work in conjunction with useRemote, as it has a hardcoded dependency with the simpleton `store`
 * **WARNING 2** as per previous reason might not work as expected if injected as Provider in a RTL or component test with React
 *
 * Ideally as part of TWPRO-7796 this function will be used for test and production code
 *
 * @example
 * # Basic Test usage
 * ```ts
 * const store = appStoreCreator(initialState);
 *
 * await store.dispatch(myActionUnderTest());
 *
 * expect(sideEffects).toBe(true);
 *
 * ```
 * # Test With Thunks
 * ```ts
 * const store = appStoreCreator(initialState);
 *
 * await store.dispatch(myThunkActionThatOpenAModal());
 *
 * await waitFor(() => expect(getModals(store.getState())).not.toHaveLength(0));
 *
 * expect(sideEffects).toBe(true);
 *
 * ```
 * @param {State} initialState
 * @param {ReducerObserver} [observeReducer]
 * @returns {AppStore}
 */
export const appStoreCreator = (initialState, observeReducer = () => {}) =>
  createStore(getObservedReducer(reducer, observeReducer), initialState, composeEnhancers(...enhancers));

/** @type {AppStore} */
export const store = createStore(persistReducer(persistConfig, reducer), {}, composeEnhancers(...enhancers));
if (process.env.NODE_ENV === 'development') window.store = store;

export const persistor = persistStore(store);

// Make reducers hot reloadable, see http://mxs.is/googmo
if (module.hot) {
  module.hot.accept('../reducers', () => {
    import('../reducers').then((reducerModule) => {
      const createReducers = reducerModule.default;
      const nextReducers = createReducers(store.asyncReducers);

      store.replaceReducer(nextReducers);
    });
  });
}

export const exportIndexedDB = async () => {
  const s = await localforage.getItem(`persist:${STORE_KEY}`);
  return JSON.stringify(s, (k, v) => (['_ref'].includes(k) ? undefined : v));
};

export const importIndexedDB = async (s) => {
  await localforage.setItem(`persist:${STORE_KEY}`, s);
};
