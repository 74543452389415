import { Country } from 'models';

export const isFrenchFiscalityCompliant = (country: Country | null): boolean => {
  return country && country.code === 'fr' && country.fiscality_enabled ? true : false;
};

export const isGreekFiscalityCompliant = (country: Country | null): boolean => {
  return country && country.code === 'gr' ? true : false;
};

export const isPortugueseFiscalityCompliant = (country: Country | null): boolean => {
  return country && country.code === 'pt' && country.fiscality_enabled ? true : false;
};

export const isGermanFiscalityCompliant = (country: Country | null): boolean => {
  return country && country.code === 'de' && country.fiscality_enabled ? true : false;
};
