import { styled, css, treatwellProTheme } from '../theme';

import clearIcon from './clear.svg';

const {
  colors,
  typography: { fontFamilies, fontSizes },
} = treatwellProTheme;

export const Input = styled('input')(
  () => css`
    opacity: 1 !important;
    transition: all 0.2s ease;
    position: relative;
    margin: 0;
    border: 1px solid ${colors['navy.400']};
    outline-offset: 2px;
    outline: none;
    appearance: none;
    border-radius: 8px;
    height: 48px;
    width: 100%;
    padding: 12px 16px;
    font-size: ${fontSizes.body_md}px;
    font-family: ${fontFamilies.default};
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 0.2s;

    &:disabled {
      background-color: ${colors['navy.100']};
      border-color: ${colors['navy.100']};
      opacity: 1;
    }

    .left-icon ~ & {
      padding-left: 40px;
    }

    &:focus {
      border-color: ${colors['blue.600']};
      box-shadow: 0 2px 10px 0 ${colors['navy.300']};
    }

    &[aria-invalid='true'] {
      border-color: ${colors['coral.600']};
      box-shadow: 0 2px 10px 0 ${colors['navy.300']};
    }
  `
);

export const InputIcon = styled('div')<{ readonly placement: 'left' | 'right' }>(
  ({ placement }) => css`
    ${placement}: 0;
    width: 48px;
    height: 48px;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    -moz-box-pack: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    z-index: 2;
    pointer-events: none;
    color: ${colors['navy.500']};
    overflow: hidden;
  `
);

export const InputGroup = styled('div')<{ readonly inputWidth: string | number | undefined }>(
  ({ inputWidth }) => css`
    width: ${inputWidth ? `${inputWidth}px` : '100%'};
    display: flex;
    flex-direction: column;
    position: relative;

    input[type='search']::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 1em;
      width: 1em;
      border-radius: 50em;
      background: url(${clearIcon}) no-repeat 50% 50%;
      background-size: contain;
      pointer-events: none;
      cursor: pointer;
    }

    input[type='search']:focus::-webkit-search-cancel-button {
      pointer-events: all;
    }
    input::placeholder {
      opacity: 1 !important;
      color: ${colors['navy.500']}!important;
    }
    input:focus::placeholder {
      opacity: 1 !important;
      color: ${colors['navy.400']}!important;
    }
  `
);

export const ErrorMessage = styled('span')(
  () => css`
    color: ${colors['coral.600']};
    font-size: ${fontSizes.body_xs}px;
    font-family: ${fontFamilies.default};
    margin-left: 16px;
  `
);
