/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { VenueProductDeleteQuery, VenueProductDeleteResponse } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const DELETE =
  ({ variables: { product_id } }: V<VenueProductDeleteQuery>) =>
  (): Promise<VenueProductDeleteResponse> =>
    Http()
      .delete<VenueProductDeleteResponse, never>(ENDPOINT.VENUE_PRODUCTS._EDIT(product_id))({})
      .then(maybeResponseToJSON);
