import { createSelector } from 'reselect';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';

export const getHideTermsByVenue = (state) => state.hideTermsByVenue || {};

export const getHideTermsByCustomerId = createSelector(
  [getCurrentVenueId, getHideTermsByVenue],
  (venue_id, hideTermsByVenue) => {
    return (hideTermsByVenue && hideTermsByVenue[venue_id]) || {};
  }
);
