import { MODALS_OPEN, MODALS_CLOSE } from 'actionTypes';

export const modalOpenAction = ({ id, config }) => ({
  type: MODALS_OPEN,
  id,
  config,
});

export const modalCloseAction = ({ id }) => ({
  type: MODALS_CLOSE,
  id,
});
