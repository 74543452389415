import { ButtonHTMLAttributes, DetailedHTMLProps, ElementType, ReactElement, PropsWithChildren } from 'react';
import StyledButton from './theme';
import { TestProperties, maybeGetTestAttributes } from 'utils/tests/maybeGetTestAttributes';

type ButtonBaseProps = {
  block?: boolean;
  basic?: boolean;
  primary?: boolean;
  secondary?: boolean;
  danger?: boolean;
  unstyled?: boolean;
  icon?: boolean;
  disabled?: boolean;
  squared?: boolean;
  color?: string;
  shadow?: boolean | string;
  width?: number | string;
  minWidth?: number | string;
  heightFitContent?: boolean;
  fontSize?: number;
  /**
   * @deprecated
   */
  size?: 'small' | 'medium' | 'large';
  circle?: boolean;
  style?: object;
  className?: string;
  dataTestId?: string;
  dataTestClass?: string;
  productTourId?: string;
  loading?: boolean;
  relative?: boolean;
  as?: ElementType;
  bottomFixed?: boolean;
} & TestProperties<object>;

export type ButtonProps<P = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>> =
  ButtonBaseProps & P;

function Button<P>({
  children,
  dataTestId, // backwards-compatible
  testId,
  productTourId,
  testClass,
  testData,
  ...rest
}: PropsWithChildren<ButtonProps<P>>): ReactElement {
  return (
    <StyledButton
      className="Button"
      data-product-tour-id={productTourId}
      {...rest}
      {...maybeGetTestAttributes({
        testId: testId ?? dataTestId,
        testClass,
        testData,
      })}
    >
      {children}
    </StyledButton>
  );
}

export default Button;
