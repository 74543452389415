import { styled, css } from 'styles';
import { withTheme, Scrollable } from 'components/core';

export const NavBar = withTheme(
  styled('div')(
    ({ theme: { utils, colors, boxShadow }, open }) => css`
      position: fixed;
      z-index: 1000;
      right: -300px;
      top: 0;
      width: 300px;
      height: 100vh;
      background-color: ${colors.bgWhite};
      transition: ${utils.transition};

      ${open &&
      css`
        right: 0;
        box-shadow: ${boxShadow.default};
      `};
    `
  )
);

export const Overlay = withTheme(
  styled('div')(
    ({ theme: { sizes, colors, media }, isOpen }) => css`
      position: fixed;
      top: 0;
      left: 0;
      right: 300px;
      height: 100%;
      cursor: pointer;
      display: none;

      ${isOpen
        ? css`
            display: block;
          `
        : css``};
    `
  )
);

export const MenuTrigger = withTheme(
  styled('div')(
    ({ theme: { sizes, colors, media } }) => css`
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      width: ${sizes.mobileHeaderHeight}px;
      height: ${sizes.mobileHeaderHeight}px;

      ${media.tablet`
        width: ${sizes.desktopHeaderHeight}px;
        height: ${sizes.desktopHeaderHeight}px;
      `};

      background-color: ${colors.bgWhite};
      cursor: pointer;

      .Icon {
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `
  )
);

export const SidebarMenu = withTheme(
  styled(Scrollable)(
    ({ theme: { sizes, media, colors } }) => css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      margin-top: ${sizes.mobileHeaderHeight}px;

      ${media.tablet`
        margin-top: ${sizes.desktopHeaderHeight}px;
      `};

      overflow-y: scroll;

      ul {
        margin: 0 0 45px;
        padding: 0;
      }

      ul li {
        width: 100%;
        margin: 0;
        list-style: none;
      }

      .AccordionItem {
        &.is-open {
          .AccordionItem__title-wrapper {
            margin-left: 1px;
            border-left: 4px solid ${colors.blue};

            .AccordionItem__title {
              div:first-of-type {
                padding-left: 22px;
              }
            }
          }

          .Icon {
            path {
              fill: ${colors.blue};
            }
          }
        }

        .AccordionItem__title-wrapper {
          &:hover {
            background: ${colors.bgLightGray};
          }
        }

        .AccordionItem__icon {
          margin: 0;
          padding: 0 22px 0 0;
          width: 8px;
          height: 8px;
        }
      }
    `
  )
);

export const Chat = styled('div')`
  position: absolute;
  right: 20px;
  bottom: 66px;
`;

const sectionTitleStyles = css`
  display: flex;
  align-items: center;
  padding: 13px 27px;

  & > .Icon {
    margin-right: 16px;
  }
`;

export const buildSectionTitleLink = (styledComponent) =>
  withTheme(
    styled(styledComponent)(
      ({ theme: { colors, media }, active, disabled }) => css`
        ${sectionTitleStyles};
        cursor: pointer;
        ${active && `margin-left: 1px;`}
        ${active && `border-left: 4px solid ${colors.blue};`}
      ${active && `padding-left: 22px;`}
        
      .Icon {
          path {
            fill: ${disabled ? colors.red : active ? colors.blue : colors.text2};
          }
        }

        ${disabled &&
        css`
          pointer-events: none;
          &:hover {
            cursor: pointer;
          }
        `}

        ${media.tablet`
        ${
          !disabled &&
          css`
            &:hover {
              background: ${colors.bgLightGray};
            }
          `
        }
      `};

        & > .Text {
          /*flex: 0;*/
          /*background: red;*/
          color: ${disabled ? colors.inputPlaceHolder : colors.text1};
        }

        & > .Badge {
          margin: 0 0 0 10px;
        }
      `
    )
  );

export const SectionTitle = withTheme(
  styled('div')(
    ({ theme: { colors }, onClick, isDisabled }) => css`
      ${sectionTitleStyles};

      & > .Text {
        flex: 0 1 auto;
        /*background: blue;*/
        color: ${isDisabled ? colors.inputPlaceHolder : colors.text1};
      }

      & > .Badge {
        margin: 0 0 0 10px;
      }

      ${onClick && { cursor: 'pointer' }};
    `
  )
);

export const Actions = withTheme(
  styled('div')(
    ({ theme: { sizes, media } }) => css`
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 2;
      left: 0;
      top: 0;
      right: ${sizes.mobileHeaderHeight}px;
      height: ${sizes.mobileHeaderHeight}px;
      padding: 0 10px;

      ${media.tablet`
        right: ${sizes.desktopHeaderHeight}px;
        height: ${sizes.desktopHeaderHeight}px;
      `};
    `
  )
);

export const ActionItem = withTheme(
  styled('div')(
    ({ theme: { sizes, media }, desktop, smallDesktop }) => css`
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 50px;
      height: ${sizes.mobileHeaderHeight}px;
      cursor: pointer;

      ${!desktop &&
      media.tablet`
        display: none;
      `};

      ${smallDesktop &&
      media.smallDesktopOrSmaller`
          display: flex;
      `};

      ${media.mobileOrSmaller`
        min-width: 60px;
      `}

      &:empty {
        display: none;
      }
    `
  )
);
