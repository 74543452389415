/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { PatchTestSignResponse, PatchTestSignQuery, PatchTestSignPayload } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const SIGN =
  ({ variables: { id, result } }: V<PatchTestSignQuery & PatchTestSignPayload>) =>
  (): Promise<PatchTestSignResponse> =>
    Http()
      .put<PatchTestSignResponse, PatchTestSignQuery, PatchTestSignPayload>(ENDPOINT.PATCH_TESTS._SIGN(id))({
        searchParams: { id },
        body: { result },
      })
      .then(maybeResponseToJSON);
