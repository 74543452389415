import { IAddress } from 'models/_address';
import { ValueOf } from 'utils/utilityTypes';

export interface BillingCompany extends IAddress {
  id: number;
  name: string;
  iban: string;
  address: string;
  email: string;
  phone?: string;
  fiscal_unique_identifier?: string;
  fiscal_unique_email?: string;
  sync_vat_number_fiscal_code: boolean;

  // DAC7 Fields
  vat_number: string;
  fiscal_code?: string;
  siret_code?: string;
  tax_id_number?: string;
  company_number?: string;
}

export const BILLING_COMPANY_FIELD = {
  name: 'name',
  iban: 'iban',
  vat_number: 'vat_number',
  fiscal_code: 'fiscal_code',
  email: 'email',
  fiscal_unique_identifier: 'fiscal_unique_identifier',
  fiscal_unique_email: 'fiscal_unique_email',
  sync_vat_number_fiscal_code: 'sync_vat_number_fiscal_code',
  siret_code: 'siret_code',
  phone: 'phone',
  route: 'route',
  street_number: 'street_number',
  postal_code: 'postal_code',
  province: 'province',
  town: 'town',
} as const;

export type BillingCompanyField = ValueOf<typeof BILLING_COMPANY_FIELD>;

export interface BillingCompanyInvoice {
  id: number;
  discounted_due_amount: number;
  due_amount: number;
  due_date: string;
  external_doc: string;
  from_time: string;
  notified_at: string | null;
  number: string;
  paid_at: string | null;
  subject: string;
  to_time: string;
  automatic_payment_scheduled_at?: string | null;
  automatic_payment_schedulable?: boolean;
  uploaded_at: string;
}
