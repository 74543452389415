import { FunctionComponent, memo, ReactNode, useEffect, useState } from 'react';
import { createIntlCache, IntlProvider as ReactIntlProvider } from 'react-intl';
import { connect } from 'react-redux';

import { changeLocale } from 'actions/sessions';
import AutoRefreshModal from './AutoRefreshModal';
import { locales } from './IntlProvider.data';
import { LocaleProvider, AvailableLocales, DEFAULT_LOCALE } from './LocaleProvider';
import { IntlProvider, useIntlContext } from './IntlContext';
import { createIntlInstance } from './IntlProvider.utils';

// intl-locale polyfill
import '@formatjs/intl-locale/polyfill';

// intl-pluralrules polyfill - https://formatjs.io/docs/polyfills/intl-relativetimeformat/
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/it';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/ca';
import '@formatjs/intl-pluralrules/locale-data/el';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/de';
import '@formatjs/intl-pluralrules/locale-data/nl';
import '@formatjs/intl-pluralrules/locale-data/lt';
import '@formatjs/intl-pluralrules/locale-data/zh';
import '@formatjs/intl-pluralrules/locale-data/sl';
import '@formatjs/intl-pluralrules/locale-data/ru';

// intl-relativetimeformat - https://formatjs.io/docs/polyfills/intl-pluralrules/
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/it';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import '@formatjs/intl-relativetimeformat/locale-data/ca';
import '@formatjs/intl-relativetimeformat/locale-data/el';
import '@formatjs/intl-relativetimeformat/locale-data/fr';
import '@formatjs/intl-relativetimeformat/locale-data/pt';
import '@formatjs/intl-relativetimeformat/locale-data/de';
import '@formatjs/intl-relativetimeformat/locale-data/nl';
import '@formatjs/intl-relativetimeformat/locale-data/lt';
import '@formatjs/intl-relativetimeformat/locale-data/zh';
import '@formatjs/intl-relativetimeformat/locale-data/sl';
import '@formatjs/intl-relativetimeformat/locale-data/ru';

// intl-relativetimeformat - https://formatjs.io/docs/polyfills/intl-numberformat/
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/it';
import '@formatjs/intl-numberformat/locale-data/es';
import '@formatjs/intl-numberformat/locale-data/ca';
import '@formatjs/intl-numberformat/locale-data/el';
import '@formatjs/intl-numberformat/locale-data/fr';
import '@formatjs/intl-numberformat/locale-data/pt';
import '@formatjs/intl-numberformat/locale-data/de';
import '@formatjs/intl-numberformat/locale-data/nl';
import '@formatjs/intl-numberformat/locale-data/lt';
import '@formatjs/intl-numberformat/locale-data/zh';
import '@formatjs/intl-numberformat/locale-data/sl';
import '@formatjs/intl-numberformat/locale-data/ru';

// intl-datetimeformat - https://formatjs.io/docs/polyfills/intl-datetimeformat/
import '@formatjs/intl-datetimeformat/polyfill';
import '@formatjs/intl-datetimeformat/locale-data/en';
import '@formatjs/intl-datetimeformat/locale-data/it';
import '@formatjs/intl-datetimeformat/locale-data/es';
import '@formatjs/intl-datetimeformat/locale-data/ca';
import '@formatjs/intl-datetimeformat/locale-data/el';
import '@formatjs/intl-datetimeformat/locale-data/fr';
import '@formatjs/intl-datetimeformat/locale-data/pt';
import '@formatjs/intl-datetimeformat/locale-data/de';
import '@formatjs/intl-datetimeformat/locale-data/nl';
import '@formatjs/intl-datetimeformat/locale-data/lt';
import '@formatjs/intl-datetimeformat/locale-data/zh';
import '@formatjs/intl-datetimeformat/locale-data/sl';
import '@formatjs/intl-datetimeformat/locale-data/ru';

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

export const intl = createIntlInstance(window.APP_LOCALE as AvailableLocales, cache);

if (!locales.includes(window.APP_LOCALE)) {
  window.APP_LOCALE = locales[0];
}

type ProviderProps = {
  children: ReactNode;
  dispatchChangeLocale: () => void;
};

const ProviderComponent: FunctionComponent<ProviderProps> = ({ children, dispatchChangeLocale }) => {
  const [refreshEnabled, setRefreshEnabled] = useState(false);
  const { state, switchLanguage } = useIntlContext();

  useEffect(() => {
    switchLanguage(window.APP_LOCALE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLocaleChange = (newLocale: AvailableLocales): void => {
    if (window.localStorage && window.localStorage.setItem) {
      window.localStorage.setItem('uala-locale', newLocale);
    }
    dispatchChangeLocale();
    window.APP_LOCALE = newLocale;
    switchLanguage(newLocale);
    setRefreshEnabled(true);
  };

  return (
    <LocaleProvider value={{ locale: state.locale, setLocale: onLocaleChange }}>
      <ReactIntlProvider
        key={state.locale}
        defaultLocale={DEFAULT_LOCALE}
        locale={state.locale}
        messages={state.messages}
      >
        <>
          {children}
          {refreshEnabled && <AutoRefreshModal />}
        </>
      </ReactIntlProvider>
    </LocaleProvider>
  );
};

const Provider: FunctionComponent<ProviderProps> = ({ children, dispatchChangeLocale }) => (
  <IntlProvider>
    <ProviderComponent dispatchChangeLocale={dispatchChangeLocale}>{children}</ProviderComponent>
  </IntlProvider>
);

const mapDispatchToProps = {
  dispatchChangeLocale: changeLocale,
};

export default connect(null, mapDispatchToProps)(memo(Provider));
