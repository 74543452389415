import {
  VENUE_MANUFACTURERS_LIST_REQUEST,
  VENUE_MANUFACTURERS_LIST_RESPONSE,
  VENUE_MANUFACTURERS_CREATE_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const manufacturers = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_MANUFACTURERS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case VENUE_MANUFACTURERS_LIST_RESPONSE:
      if (!action.manufacturers) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.manufacturers || []).map((item) => ({ id: item.id }))),
      };

    case VENUE_MANUFACTURERS_CREATE_RESPONSE:
      if (!action.manufacturer) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.manufacturer || [])
            .map((item) => ({ id: item.id }))
        ),
      };
    default:
      return state;
  }
};

export const manufacturersByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_MANUFACTURERS_LIST_REQUEST:
    case VENUE_MANUFACTURERS_LIST_RESPONSE:
    case VENUE_MANUFACTURERS_CREATE_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = manufacturers(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
