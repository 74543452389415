import { useCallback, useState } from 'react';

export function useStorage<T>(key: string, storage: Storage = localStorage): [T, (value: T) => void, () => void] {
  const [item, setValue] = useState(() => {
    const value: string = storage.getItem(key) || JSON.stringify(null);
    storage.setItem(key, value);
    return JSON.parse(value);
  });

  const setItem = useCallback(
    (value: T): void => {
      setValue(value);
      storage.setItem(key, JSON.stringify(value));
    },
    [key, storage]
  );

  const clear = useCallback((): void => {
    storage.removeItem(key);
  }, [key, storage]);

  return [item, setItem, clear];
}
