import { styled, css, keyframes } from 'styles';
import hexToRgba from 'utils/hexToRgba';
// eslint-disable-next-line no-restricted-imports
import { theme } from 'styles';

const pulseDot = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${hexToRgba(theme.colors.red, 0.7)};
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 5px ${hexToRgba(theme.colors.red, 0)};
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${hexToRgba(theme.colors.red, 0)};
  }
`;

export const RecordButton = styled('div')(
  ({ theme: { colors } }) => css`
    height: 6px;
    width: 6px;
    border-radius: 1px;
    background-color: ${colors.red};
    z-index: 1;
    transform: scale(1);
    background: red;
    box-shadow: 0 0 0 0 ${colors.red};
    animation: ${pulseDot} 2s infinite;
  `
);

export const RecordButtonWrapper = styled('div')(
  ({ theme: { colors } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 18px;
    height: 18px;
    border: 1px solid ${colors.blue};
    border-radius: 50%;
  `
);
