import PropTypes from 'prop-types';
import { Shape } from './theme';
import { Component, createRef } from 'react';

export default class Scrollable extends Component {
  constructor(props) {
    super(props);

    this.body = createRef();
  }

  componentDidMount() {
    if (!this.props.fluid && this.body.current) {
      this.body.current.addEventListener(
        'touchstart',
        (this.onTouchStart = (e) => {
          this.touchStartY = e.touches[0].pageY;
        }),
        { passive: false }
      );

      this.body.current.addEventListener(
        'touchmove',
        (this.onTouchMove = (e) => {
          e.stopPropagation();

          const delta = this.touchStartY - e.touches[0].pageY;
          const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

          if (this.props.onScroll) {
            this.props.onScroll(scrollTop);
          }

          if ((scrollTop === 0 && delta < 0) || (scrollTop >= scrollHeight - offsetHeight && delta > 0)) {
            e.preventDefault();
            return false;
          }
        }),
        { passive: false }
      );

      this.body.current.addEventListener(
        'wheel',
        (this.onScroll = (e) => {
          e.stopPropagation();
          const { deltaY, deltaX } = e;
          const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

          const delta = deltaY === 0 ? deltaX : deltaY;

          if (this.props.onScroll) {
            this.props.onScroll(scrollTop);
          }

          if ((scrollTop === 0 && delta < 0) || (scrollTop >= scrollHeight - offsetHeight && delta > 0)) {
            e.preventDefault();
            return false;
          }
        }),
        { passive: false }
      );
    }
  }

  componentWillUnmount() {
    if (this.body.current) {
      this.body.current.removeEventListener('touchstart', this.onTouchStart);
      this.body.current.removeEventListener('touchmove', this.onTouchMove);
      this.body.current.removeEventListener('wheel', this.onScroll);
    }
  }

  render() {
    const { fluid, onScroll, ...props } = this.props;
    return <Shape ref={this.body} className="Scrollable" {...props} />;
  }
}

Scrollable.propTypes = {
  // render Scrollable like normal div
  fluid: PropTypes.bool,
  className: PropTypes.string,
};
