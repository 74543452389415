import { API_BASE } from 'actions/data_providers/api';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { store } from 'store';
import { ValueOf } from 'utils/utilityTypes';
import { getHeaderEntries, maybeResponseToJSON } from './http.utils';
import { AuthHeaderProvider } from './types';

const ENDPOINT_SCOPE = {
  VENUE: 'venues',
  MANAGER: 'manager',
} as const;

export const getVenueId = (): number | undefined => {
  try {
    const reduxStore = store.getState();
    const venue_id = getCurrentVenueId(reduxStore) || undefined;
    if (!venue_id) {
      console.error(new Error("Can't retrieve the venue_id from reduxStore"));
    }
    return venue_id;
  } catch (error) {
    return undefined;
  }
};

export const defaultAuthHeaderProvider: AuthHeaderProvider = () => {
  return `Token token="${getToken()}"`;
};

export const getToken = (): string | void => {
  try {
    const reduxStore = store.getState();
    const auth_token = getCurrentUserToken(reduxStore);

    if (!auth_token) {
      console.error(new Error("Can't retrieve the auth_token from reduxStore"));
    }
    return auth_token;
  } catch (error) {
    return undefined;
  }
};

export const getEndpoint =
  (scope?: ValueOf<typeof ENDPOINT_SCOPE>) =>
  (path: string): string | never => {
    switch (scope) {
      case ENDPOINT_SCOPE.VENUE: {
        const venue_id = getVenueId();
        return `${API_BASE()}/${ENDPOINT_SCOPE.VENUE}/${venue_id}/${path}`;
      }
      case ENDPOINT_SCOPE.MANAGER: {
        const venue_id = getVenueId();
        return `${API_BASE()}/${ENDPOINT_SCOPE.MANAGER}/venues/${venue_id}/${path}`;
      }
      default:
        return `${API_BASE()}/${path}`;
    }
  };

export const getEndpointByVenue = getEndpoint(ENDPOINT_SCOPE.VENUE);
export const getManagerEndpointByVenue = getEndpoint(ENDPOINT_SCOPE.MANAGER);

/**
 * @info
 * This is needed to support a "non standard" legacy way of using Headers improperly for pagination from the BackEnd
 **/
export const withLegacyHeaderPagination =
  (_maybeResponseToJSON: typeof maybeResponseToJSON) =>
  async <A extends Response>(response: A): Promise<A | (A & { total: number })> => {
    const entries = getHeaderEntries(response);
    const total = Number(entries?.['x-total']);
    if (isNaN(total)) return response;
    try {
      const res = await _maybeResponseToJSON(response);
      const enrichedResponse = {
        ...res,
        data: {
          ...(res as unknown as { data: A })?.data,
          total,
        },
      };
      return enrichedResponse;
    } catch (error) {
      console.error(error);
      return response;
    }
  };
