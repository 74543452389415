import { styled, css } from 'styles';
import { withTheme } from '../Context';
import Field, { fieldSizes } from 'components/core/Field/theme';

const sizeStyles = ({ sizes }) => ({
  default: css`
    padding: 5px 15px;

    > div {
      height: ${fieldSizes.innerHeight.default() - 10}px;
      line-height: ${fieldSizes.innerHeight.default() - 10}px;
    }
  `,
  small: css`
    padding: 5px 15px;

    > div {
      height: ${fieldSizes.innerHeight.small() - 10}px;
      line-height: ${fieldSizes.innerHeight.small() - 10}px;
    }
  `,
  large: css`
    padding: 10px 20px;
    margin-right: ${sizes.spacing}px;

    > div {
      height: ${fieldSizes.innerHeight.large() - 20}px;
      line-height: ${fieldSizes.innerHeight.large() - 20}px;
    }

    .Text {
      font-weight: normal;
    }
  `,
});

export const FieldLabel = withTheme(
  styled(Field)(
    ({ theme: { sizes, colors }, disabled, type, size, right, withPadding, separator }) => css`
      ${(size && sizeStyles({ sizes })[size]) || sizeStyles({ sizes }).default};
      ${!withPadding &&
      css`
        padding-left: 0;
        padding-right: 0;
      `};
      ${separator && `margin: -2px 8px 0 -2px;`};
      border-radius: 0;
      border-color: transparent;
      letter-spacing: 1px;

      > div {
        display: block;
        width: 100%;
        border: 0;
        background-color: transparent;
        ${(right && { 'text-align': 'right' }) || ''};
        font-family: Lato;
        font-weight: ${separator ? '100' : 'bold'};
        color: ${colors.text2};
        font-size: 10px;
        text-transform: uppercase;
      }
    `
  )
);
