import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connectFormElement, getErrorByName } from '@uala/react-forms';

import { FormControl, FormControlInput, Label, Helper, elementPropTypes } from '../../shared';
import { DefaultSettings, INPUT_INLINE, INPUT_SIZES } from '../../config';

import { RadioButton, RadioGroup, RadioLabel, RadioOption } from './Radio.theme';

const Radio = ({
  disabled,
  align,
  options,
  name,
  label,
  labelPosition,
  intl,
  emitChange,
  emitDidChange,
  values,
  value,
  errors,
  helper,
  size,
  helperPosition,
  tooltip,
  ...inputProps
}) => {
  // Constants
  /**
   * Giving `name` input is connected to values,
   * otherwise is controlled by `value` prop
   */
  const valueRef = name ? values[name] : value;
  const fieldError = getErrorByName(errors, name);
  const error = !!fieldError;
  helper = fieldError || helper || null;

  // State
  const [selectedValue, setSelectedValue] = useState(null);

  // Lifecycle
  useEffect(() => {
    setSelectedValue(valueRef);
  }, [valueRef]);

  // Handlers
  const handleClick = (value) => {
    setSelectedValue(value);

    emitChange(name, value);
    emitDidChange(name, value);
  };

  return (
    <FormControl labelPosition={labelPosition} className="RadioGroup">
      {name && <input type="hidden" value={valueRef} name={name} />}
      {label && (
        <Label htmlFor={name} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput>
        <RadioGroup error={error} size={size} {...inputProps} align={align}>
          {options.map((item, k) => (
            <RadioOption onClick={() => handleClick(item.value)} key={item.value} className="RadioOption">
              <RadioLabel>{item.component || item.label || item.value}</RadioLabel>
              <RadioButton selected={item.value === selectedValue} />
            </RadioOption>
          ))}
        </RadioGroup>
        {helper && <Helper position={helperPosition} tooltip={tooltip} error={error} text={helper} intl={intl} />}
      </FormControlInput>
    </FormControl>
  );
};

Radio.propTypes = {
  ...elementPropTypes,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    })
  ),
  size: PropTypes.oneOf(INPUT_SIZES),
  value: PropTypes.any,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};

Radio.defaultProps = {
  values: {},
  align: null,
  disabled: false,
  size: DefaultSettings.INPUT_SIZE,
  labelPosition: INPUT_INLINE,
  options: [],
  value: null,
  intl: true,
};

export default connectFormElement(Radio);
