/* eslint-disable no-fallthrough */
/* eslint-disable no-useless-computed-key */

import { API_GET_RESPONSE, API_POST_RESPONSE, API_DATA_SET, CHANGE_LOCALE } from 'actionTypes';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/dates';

/**
 * entitiesDictionaryByVenue reducer
 * contiene tutti le entità aggiornate grazie alle risposte di tutte le chiamate al server API
 *
 * Ogni view dovrebbe mostrare solo i dati contenuti in questo reducer
 */
export const entitiesDictionaryByVenue = (state = {}, action) => {
  if (action.error || !action.venue_id) {
    return state;
  }

  /**
   * prepare data
   */
  let refState = state;
  const { venue_id } = action;
  const responseData = (action.response && action.response.data) || {};

  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      //
      // parse every response
      //
      [
        'checkout',
        'cancellation_checkout',
        'customer',
        'line_item',
        'venue_product',
        'appointment',
        'venue_treatment',
        'venue_treatment_group',
        'staff_member_treatment',
        'staff_member',
        'supplier',
        'workstation',
        'workstation_treatment',
        'manufacturer',
        'order',
        'fiscal_day',
        'marketing_promotion',
        'appliable_marketing_promotion',
        'custom_time_table',
        'extra_opening',
        'vacancy',
        'venue_wizard',
        'acceptance_form',
        'internal_customer_details',
        'internal_appointment_details',
        'ecommerce_order',
        'order_delivery_note',
      ].forEach((name) => {
        const objectType =
          name === 'appliable_marketing_promotion'
            ? 'marketing_promotion'
            : name === 'cancellation_checkout'
            ? 'checkout'
            : name;

        /**
         * GET ENTITY STORE
         * entityDictionary = {
         *    customer: {
         *      1: {},
         *      2: {},
         *      ...
         *    }
         * }
         * */
        const entityDictionary = (objectType && refState[venue_id] && refState[venue_id][objectType]) || {};
        let newEntityDictionary = entityDictionary;

        /** SINGLE  */
        if (responseData[name]) {
          newEntityDictionary = getDictionaryWithMergedEntities(
            entityDictionary,
            newEntityDictionary,
            objectType,
            responseData[name]
          );
        }

        const multiple_name = name === 'vacancy' ? 'vacancies' : name + 's';

        /** MULTIPLE */
        if (responseData[multiple_name]) {
          [].concat(responseData[multiple_name]).forEach((entity) => {
            newEntityDictionary = getDictionaryWithMergedEntities(
              entityDictionary,
              newEntityDictionary,
              objectType,
              entity
            );
          });
        }

        /**
         * se c'è stato un cambiamento,
         * dovrò tornare uno state diverso per risvegliare tutti i meccanismi di update
         * evito se lo state è già diverso
         */
        if (newEntityDictionary !== entityDictionary) {
          refState = {
            ...refState,
            [venue_id]: {
              ...(refState[venue_id] || {}),
              [objectType]: newEntityDictionary,
            },
          };
        }
      });
      return refState;

    case API_DATA_SET:
      if (!action.object_type || !action.object) {
        return state;
      }
      const entityDictionary = (refState[venue_id] && refState[venue_id][action.object_type]) || {};
      let newEntityDictionary = entityDictionary;
      newEntityDictionary = getDictionaryWithMergedEntities(
        entityDictionary,
        newEntityDictionary,
        action.object_type,
        action.object,
        true
      );

      if (newEntityDictionary !== entityDictionary) {
        refState = {
          ...refState,
          [venue_id]: {
            ...(refState[venue_id] || {}),
            [action.object_type]: newEntityDictionary,
          },
        };
      }

      return refState;

    /**
     * Reset entities that are related to locale when locale changes
     */
    case CHANGE_LOCALE:
      return {
        ...state,
        [venue_id]: {
          ...(state[venue_id] || {}),
          venue_treatment: {},
          venue_product: {},
        },
      };

    default:
      return state;
  }
};

/**
 * Mappers
 */
const objectMapper = (objectType, object) => {
  let result;
  switch (objectType) {
    default:
      result = {
        type: objectType,
        id: object.id + '',
        data: object,
        updated_at: object.updated_at,
      };
      break;
    case 'appointment':
      result = {
        type: objectType,
        id: object.id + '',
        data: {
          ...object,
          ['YYYY-MM-DD']: moment(object.time).format(DATE_FORMAT.YearMonthDay),
        },
        updated_at: object.updated_at,
      };
      break;
  }

  /**
   * Add an attribute that uniquely identify this object,
   * the best reference is itself.
   * Everywhere in the project if you want to check if
   * different objects refer to the same version of the same entity
   * you can compare _ref attributes.
   * It doesn't matter if the 2 objects comes from different selectors or map/reduce functions
   * a._ref === b._ref always means that a and b are the same, unchanged.
   */
  result.data._ref = result.data;

  return result;
};

/**
 * Merger function
 */
const getDictionaryWithMergedEntities = (
  entityDictionary,
  newEntityDictionary,
  objectType,
  object,
  override = false
) => {
  /**
   * object = {
   *   id: 230971,
   *   type: 'checkout',
   *   data: {
   *     card_amount: 0,
   *     cash_amount: 30
   *     ...
   *   },
   *   updated_at: "2018-06-06T11:56:29+02:00"
   * }
   * state = {
   *   5: {
   *     checkout: {
   *       230971: {
   *         id: 230971,
   *         type: 'checkout',
   *         data: {
   *           card_amount: 15,
   *           cash_amount: 15
   *           ...
   *         },
   *         updated_at: "2018-06-06T11:30:10+02:00"
   *       }
   *     }
   *   }
   * }
   * 1. Riceve il dizionario della venue contenente le entità dello stesso tipo
   */

  /**
   * 2. Se non esiste inserisce, altrimenti confronta il valore di updated_at
   * se è impostato il parametro `override` sovrascrive sempre
   * è importante ritornare un nuovo oggetto per invalidare le cache nei selectors agganciate a state
   */
  if (
    object.id &&
    (override ||
      !entityDictionary[object.id] ||
      !entityDictionary[object.id].updated_at ||
      entityDictionary[object.id].updated_at < object.updated_at)
  ) {
    if (newEntityDictionary !== entityDictionary) {
      /**
       * The equality check of entityDictionary and newEntityDictionary fails
       * this means that an object is changed in some previous cycle (ex: in multiple loop)
       * in this case the ref is already changed, there's no need to create a new object
       */
      newEntityDictionary[object.id] = objectMapper(objectType, object);
    } else {
      // dictionary is the same, return a new one to trigger changes
      newEntityDictionary = {
        ...newEntityDictionary,
        [object.id]: objectMapper(objectType, object),
      };
    }
  }

  return newEntityDictionary;
};
