import { FunctionComponent, ReactElement } from 'react';
import StyledSpinner from './theme';
export interface SpinnerProps {
  centered?: boolean;
  fixed?: boolean;
  loading?: boolean;
}

export const Spinner: FunctionComponent<SpinnerProps> = ({
  loading = true,
  fixed = false,
  centered = false,
}: SpinnerProps): ReactElement => (
  <StyledSpinner
    className="Spinner"
    data-testid="Spinner"
    spinnerLoading={loading}
    fixed={fixed}
    centered={centered}
    // src={require('./Spinner.gif')}
  />
);

Spinner.displayName = 'Spinner';
export default Spinner;
