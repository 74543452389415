export type BaseColor = 'yellow' | 'teal' | 'blue' | 'coral' | 'navy' | 'purple';
export const VARIANT = (baseColor: BaseColor) =>
  ({
    primary: {
      color: {
        default: 'white',
        hover: 'white',
        active: 'white',
        disabled: 'navy.400',
      },
      backgroundColor: {
        default: `${baseColor}.600`,
        hover: `${baseColor}.400`,
        active: `${baseColor}.700`,
        disabled: 'navy.300',
      },
      border: {
        default: 'transparent',
      },
    },
    secondary: {
      color: {
        default: `${baseColor}.600`,
        hover: `${baseColor}.600`,
        active: 'white',
        disabled: 'navy.400',
      },
      backgroundColor: {
        default: `${baseColor}.100`,
        hover: `${baseColor}.200`,
        active: `${baseColor}.700`,
        disabled: 'navy.300',
      },
      border: {
        default: 'transparent',
      },
    },
    tertiary: {
      color: {
        default: `${baseColor}.600`,
        hover: `${baseColor}.400`,
        active: `${baseColor}.700`,
        disabled: 'navy.400',
      },
      backgroundColor: {
        default: 'white',
        hover: 'white',
        active: 'white',
        disabled: 'white',
      },
      border: {
        default: `${baseColor}.100`,
      },
    },
  }) as const;

export type Variant = keyof ReturnType<typeof VARIANT>;
