import { Component, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { Tabs as StyledTabs, TabsItem, BadgeButton } from './theme';
import { Text } from 'components/core';

class Tabs extends Component {
  state = { activeTab: 0 };

  componentDidUpdate(prevProps) {
    if (this.props.activeTab !== prevProps.activeTab && this.props.activeTab !== this.state.activeTab) {
      this.setActiveTab(this.props.activeTab);
    }
  }

  componentDidMount() {
    if (this.props.activeTab) this.setActiveTab(this.props.activeTab);
  }

  setActiveTab = (toIndex) => {
    const fromIndex = this.state.activeTab;
    this.setState({ activeTab: toIndex }, () => {
      this.props.onChange(toIndex, fromIndex);
    });
  };

  render() {
    const children = Children.map(this.props.children, (child) => child)
      .filter((child) => child && child.type)
      .map((child, index) => {
        if (child.type.displayName === 'Tabs.Item' || child.type.displayName === 'Tabs.Button') {
          return cloneElement(child, {
            active: index === this.state.activeTab,
            onClick: () => this.setActiveTab(index),
          });
        }
        return child;
      });
    return (
      <StyledTabs className="Tabs" {...this.props}>
        {children}
      </StyledTabs>
    );
  }
}

Tabs.defaultProps = {
  onChange: (toIndex, fromIndex) => {},
  activeTab: 0,
  fontSize: 10,
  size: 'default',
  bold: false,
};

Tabs.propTypes = {
  onChange: PropTypes.func,
  activeTab: PropTypes.number,
  justify: PropTypes.bool,
  scrollable: PropTypes.bool,
  fontSize: PropTypes.number,
  size: PropTypes.string,
  bold: PropTypes.bool,
};

export default Tabs;

const Item = (props) => (
  <TabsItem className="Tabs_item" {...props}>
    {props.children}
  </TabsItem>
);

Item.displayName = 'Tabs.Item';

const Badge = (props) => (
  <BadgeButton color="inputBorder" size="medium" {...props}>
    <Text size={10} color={props.active ? 'text1' : 'text2'} bold uppercase>
      {props.children}
    </Text>
  </BadgeButton>
);

Badge.displayName = 'Tabs.Button';

Tabs.Item = Item;
Tabs.Button = Badge;
