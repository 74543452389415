import { createSelector } from 'reselect';
import { getCurrentVenueId } from './getCurrentVenue';
import getEntities from './getEntities';
import filterDeletedItems from 'utils/filterDeletedItems';

export const getVenueCustomTimeTablesByVenue = (state) => state.venueCustomTimeTablesByVenue;

const emptyVenueCustomTimeTables = [];

export const getVenueCustomTimeTablesList = createSelector(
  getCurrentVenueId,
  getVenueCustomTimeTablesByVenue,
  getEntities.custom_time_table,
  (venue_id, venueCustomTimeTablesByVenue, venueCustomTimeTableById) => {
    const venueCustomTimeTables =
      (venueCustomTimeTablesByVenue[venue_id] && venueCustomTimeTablesByVenue[venue_id].items) ||
      emptyVenueCustomTimeTables;
    if (venueCustomTimeTables === emptyVenueCustomTimeTables) {
      return venueCustomTimeTables;
    }
    return venueCustomTimeTables
      .map((custom_time_table) => ({
        ...custom_time_table,
        ...(venueCustomTimeTableById[custom_time_table.id] ? venueCustomTimeTableById[custom_time_table.id].data : {}),
      }))
      .filter(filterDeletedItems);
  }
);

export const getVenueCustomTimeTablesById = createSelector(
  [getVenueCustomTimeTablesList],
  (venueCustomTimeTablesList) => {
    return venueCustomTimeTablesList.reduce((venueCustomTimeTablesById, venueCustomTimeTable) => {
      if (venueCustomTimeTable.id) {
        venueCustomTimeTablesById[venueCustomTimeTable.id] = venueCustomTimeTable;
      }
      return venueCustomTimeTablesById;
    }, {});
  }
);

export const getVenueCustomTimeTablesLoading = createSelector(
  [getCurrentVenueId, getVenueCustomTimeTablesByVenue],
  (venue_id, venueCustomTimeTablesByVenue) => {
    return (venueCustomTimeTablesByVenue[venue_id] && venueCustomTimeTablesByVenue[venue_id].isFetching) || false;
  }
);
