export enum NODE_ENV {
  TEST = 'test',
  DEV = 'development',
  PROD = 'production',
}

export enum UALA_FEATURE_FLAG {
  AGENDA = 'UALA_FEATURE_AGENDA',
  CUSTOMERS = 'UALA_FEATURE_CUSTOMERS',
  CHECKOUT = 'UALA_FEATURE_CHECKOUT',
  TREATMENTS = 'UALA_FEATURE_TREATMENTS',
  STAFF_MEMBERS = 'UALA_FEATURE_STAFF_MEMBERS',
  PROMOTIONS = 'UALA_FEATURE_PROMOTIONS',
  TIMETABLE = 'UALA_FEATURE_TIMETABLE',
  COSTS = 'UALA_FEATURE_COSTS',
  MANAGER_TREND = 'UALA_FEATURE_MANAGER_TREND',
  MANAGER_DASHBOARD = 'UALA_FEATURE_MANAGER_DASHBOARD',
  MANAGER_COMPANY_REPORT = 'UALA_FEATURE_MANAGER_COMPANY_REPORT',
  MANAGER_COLLABORATORS_REPORT = 'UALA_FEATURE_MANAGER_COLLABORATORS_REPORT',
  MANAGER_CUSTOMERS_REPORT = 'UALA_FEATURE_MANAGER_CUSTOMERS_REPORT',
  MANAGER_STOCK_REPORT = 'UALA_FEATURE_MANAGER_STOCK_REPORT',
  MANAGER_INVENTORY_REPORT = 'UALA_FEATURE_MANAGER_INVENTORY_REPORT',
  WAREHOUSE = 'UALA_FEATURE_WAREHOUSE',
  PROFILE = 'UALA_FEATURE_PROFILE',
  MARKETING = 'UALA_FEATURE_MARKETING',
  ASSISTANT = 'UALA_FEATURE_ASSISTANT',
}

const ENV = (window && window.REACT_APP_SETTINGS) || {};

if (process.env.NODE_ENV === NODE_ENV.DEV || process.env.NODE_ENV === NODE_ENV.TEST) {
  Object.keys(process.env).forEach((k?: string) => {
    if (k && /^REACT_APP/.test(k)) {
      const envValue: string | undefined = process.env[k];
      ENV[k.replace('REACT_APP_', '')] = envValue;
    }
  });
}

export default ENV;
