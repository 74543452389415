import {
  STAFF_MEMBERS_LIST_REQUEST,
  STAFF_MEMBERS_LIST_RESPONSE,
  STAFF_MEMBERS_UPDATE_RESPONSE,
  STAFF_MEMBERS_DELETE_RESPONSE,
  STAFF_MEMBERS_CREATE_RESPONSE,
} from 'actionTypes';

import { apiGet, apiPost } from './data_providers/api';
import { actionShouldFetchData } from 'utils/reducersUtils';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { updateVenueWizardIfNeeded } from './venueWizards';

const fetchStaffMembersRequestAction = ({ venue_id, auth }) => ({
  type: STAFF_MEMBERS_LIST_REQUEST,
  venue_id,
  auth,
});

const fetchStaffMembersResponseAction = ({ error, venue_id, staff_members, response }) => ({
  type: STAFF_MEMBERS_LIST_RESPONSE,
  venue_id,
  staff_members,
  response,
  error,
});

const updateStaffMembersResponseAction = ({ error, venue_id, staff_member }) => ({
  type: STAFF_MEMBERS_UPDATE_RESPONSE,
  venue_id,
  staff_member,
  error,
});

const deleteStaffMembersResponseAction = ({ error, venue_id, staff_member }) => ({
  type: STAFF_MEMBERS_DELETE_RESPONSE,
  venue_id,
  staff_member,
  error,
});

const createStaffMembersResponseAction = ({ error, venue_id, staff_member, response }) => ({
  type: STAFF_MEMBERS_CREATE_RESPONSE,
  venue_id,
  staff_member,
  response,
  error,
});

export const fetchStaffMembers = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!auth || !venue_id) {
      return reject({ code: 400 });
    }

    dispatch(fetchStaffMembersRequestAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/staff_members
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/staff_members.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            dispatch(fetchStaffMembersResponseAction({ error: response.info && response.info[0] }));
            return reject({ error: response.info && response.info[0] });
          }

          dispatch(fetchStaffMembersResponseAction({ venue_id, staff_members: response.data.staff_members, response }));
          resolve({ staff_members: response.data.staff_members });
        },
        onError(error) {
          dispatch(fetchStaffMembersResponseAction({ error }));
          reject({ error });
        },
      })
    );
  });

export const fetchStaffMembersIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());
    if (!auth || !venue_id) {
      return;
    }

    if (actionShouldFetchData(getState().staffMembersByVenue[venue_id])) {
      return dispatch(fetchStaffMembers());
    }
  };
};

export const updateStaffMember =
  ({ id, changes }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !id) {
        return reject({ code: 400 });
      }

      const formData = new FormData();

      for (const key in changes) {
        formData.append(`staff_member[${key}]`, changes[key]);
      }

      // http://docs.uala.it/api/v1/staff_members
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/staff_members/${id}.json`,
          formData,
          auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(updateStaffMembersResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              updateStaffMembersResponseAction({
                venue_id,
                staff_member: response.data.staff_member,
              })
            );

            dispatch(updateVenueWizardIfNeeded('staff_members_incomplete'));

            resolve({ staff_member: response.data.staff_member });
          },
          onError(error) {
            dispatch(updateStaffMembersResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const deleteStaffMember =
  ({ id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !id) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/staff_members
      dispatch(
        apiPost({
          method: 'DELETE',
          path: `/venues/${venue_id}/staff_members/${id}.json`,
          data: {},
          auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(deleteStaffMembersResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              deleteStaffMembersResponseAction({
                venue_id,
                staff_member: response.data.staff_member,
              })
            );

            dispatch(updateVenueWizardIfNeeded('staff_members_incomplete'));

            resolve({ staff_member: response.data.staff_member });
          },
          onError(error) {
            dispatch(deleteStaffMembersResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const createStaffMember =
  ({ staff_member }, role = 'stylist') =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth) {
        return reject({ code: 400 });
      }

      const default_staff_member = {
        role,
        day_override_1: '1,1,1,1,1,1,1',
        time_table_1: ',,,\n,,,\n,,,\n,,,\n,,,\n,,,\n,,,\n',
      };

      const values = {
        ...default_staff_member,
        ...staff_member,
      };

      const formData = new FormData();

      for (const key in values) {
        formData.append(`staff_member[${key}]`, values[key]);
      }

      // http://docs.uala.it/api/v1/staff_members
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/staff_members.json`,
          formData,
          auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              createStaffMembersResponseAction({ venue_id, staff_member: response.data.staff_member, response })
            );
            resolve({ staff_member: response.data.staff_member });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const sortStaffMembers =
  ({ ids }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !ids) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/staff_members
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/staff_members/sort.json`,
          data: {
            staff_members: ids,
          },
          auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            resolve({ staff_members: response.data.staff_members });
          },
          onError(error) {
            dispatch(updateStaffMembersResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });
