/* eslint-disable no-fallthrough */

import {
  API_GET_RESPONSE,
  API_POST_RESPONSE,
  VENUE_CHECKOUTS_LIST_REQUEST,
  VENUE_CHECKOUTS_LIST_RESPONSE,
  VENUE_CHECKOUTS_CREATE_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

/**
 * {
 *  id: 72165735,
 *  invoiced_at_day: "2018-6-11"
 * }
 */
const checkoutMapper = (item) => ({
  id: item.id,
  invoiced_at_day:
    item.invoiced_at_day ||
    (item.invoiced_at &&
      ((d) => [d.getFullYear(), d.getMonth() + 1, d.getDate()].join('-'))(new Date(item.invoiced_at))),
});

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const checkoutsList = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_CHECKOUTS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_CHECKOUTS_LIST_RESPONSE:
      if (!action.checkouts) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.checkouts).map(checkoutMapper), {
          avoidDuplicateIds: 1,
        }),
      };

    case VENUE_CHECKOUTS_CREATE_RESPONSE:
      if (!action.checkout && !action.checkouts) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.checkout || [])
            .concat(action.checkouts || [])
            .map(checkoutMapper),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (!action.cancellation_checkout && !action.checkout && (!action.checkouts || !action.checkouts.length)) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.checkout || [])
            .concat(action.checkouts || [])
            .map(checkoutMapper),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    default:
      return state;
  }
};

export const checkoutsListByVenue = (state = {}, action) => {
  const responseData = (action.response && action.response.data) || {};
  let refState;

  switch (action.type) {
    case VENUE_CHECKOUTS_LIST_REQUEST:
    case VENUE_CHECKOUTS_LIST_RESPONSE:
    case VENUE_CHECKOUTS_CREATE_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = checkoutsList(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    /**
     * add the newly created checkout to the list of emitted checkouts
     * add the checkouts coming from synch
     * if path === `/venues/${action.venue_id}/checkouts.json`, is handled by previuos case VENUE_CHECKOUTS_LIST_RESPONSE and case VENUE_CHECKOUTS_CREATE_RESPONSE
     */
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id || action.path === `/venues/${action.venue_id}/checkouts.json`) {
        return state;
      }

      refState = checkoutsList(state[action.venue_id], {
        ...action,
        checkouts: []
          .concat(responseData.cancellation_checkout || [])
          .concat(responseData.checkout || [])
          .concat(responseData.checkouts || []),
      });

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
