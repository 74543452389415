import { deepStrictEqual } from 'assert';

/**
 * @description
 * Accepts a generic object as input ( the snapshot ) ad returns a partial applicated function
 * that accepts an object to compare with the snapshot.
 *
 * @example
 * ```ts
 *   const remoteMock = { a: { b: { c: 'c' } } };
 *   const matchRemoteSnapshot = matchSnapshot(remoteMock);
 *   const hasLocalChanges = matchRemoteSnapshot({ a: { b: { c: 'c', d: 'd' } } });
 *   expect(hasLocalChanges).toEqual(false);
 * ```
 */
export const matchSnapshot =
  <B>(prev?: B) =>
  <A>(current: A): boolean => {
    try {
      deepStrictEqual(prev, current);
      return true;
    } catch (_) {
      if (process.env.NODE_ENV === 'development') {
        console.trace(_);
      }
      return false;
    }
  };
