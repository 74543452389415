/*
 * In this component you can render whatever you want to show in all pages of the app
 */

import GracePeriodBanner from 'containers/modals/grace_period';
import WizardAssistantContainer from 'containers/wizard-assistant';
import { PAGE_TEMPLATE } from 'models';

type NotificationCenterProps = {
  template?: PAGE_TEMPLATE;
};

const NotificationCenter = ({ template = PAGE_TEMPLATE.DEFAULT }: NotificationCenterProps) => {
  return (
    <>
      <WizardAssistantContainer />
      <GracePeriodBanner template={template} />
    </>
  );
};

export default NotificationCenter;
