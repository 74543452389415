import { Appointment } from './appointment';
import { Customer } from './customer';

export interface Reservation {
  id: number;
  customer_id: number;
  guest_customer_appointment_id: number | null;
  time: string;
  time_no_tz: string;
  customer: Customer;
  appointments: Appointment[];
}

export const reservationFromAppointment = (
  appointment: Appointment,
  customersById: { [id: number]: Customer }
): Reservation => {
  return {
    id: appointment.booking_token,
    customer_id: appointment.customer_id,
    guest_customer_appointment_id: appointment.customer_id > 0 ? null : appointment.id,
    time: appointment.time,
    time_no_tz: appointment.time_no_tz,
    customer: customersById[appointment.customer_id],
    appointments: [appointment],
  };
};

// 2 full_day_duration appointments + 2 normal appointments
//
// {
//   time: '2020-02-17T10:00:00',           <- Orario ingresso
//   customer_id: 1000,                     <- Marco
//   staff_member_id: 2000,                 <- Room "spa"
//   staff_member_treatment_id: 2010,       <- Ingresso Spa
//   children_appointments: []
//   additional_appointments: [{
//     time: '2020-02-17T18:00:00',         <- orario massaggio
//     customer_id: 1000,                   <- Marco
//     staff_member_id: 3000,               <- staff Giuseppe
//     staff_member_treatment_id: 3056,     <- Massaggio 1 ora
//     children_appointments: []
//   }, {
//     customer_id: 1000,                   <- Marco
//     staff_member_id: 4000,               <- staff Chiara
//     staff_member_treatment_id: 4056,     <- Trattamento viso <- nessun orario, va in coda al massaggio
//     children_appointments: []
//   }, {
//     time: '2020-02-17T10:00:00',         <- Orario ingresso (sempre uguale tra tutti i full_day)
//     customer_id: 1000,                   <- Marco
//     staff_member_id: 4000,               <- Room "Sauna"
//     staff_member_treatment_id: 4056,     <- Sauna
//     children_appointments: []
//   }]
// }

/**
 * ATTENZIONE
 * I children_appointments possono essere a prezzo 0 oppure uguale al parent in base ad un flag
 * venue_treatment.nullify_children_appointments_price: true|false
 */
// {
//   time: '2020....',
//   customer_id: 1000,                     <- Marco
//   staff_member_id: 2000,                 <- room
//   staff_member_treatment_id: 2010,       <- Ingresso Spa
//   children_appointments: [{
//     customer_id: -4,
//     customer_name: 'Moglie di Marco',
//     staff_member_id: 2000,               <- room
//     staff_member_treatment_id: 2010,     <- Ingresso Spa
//   }]
//   additional_appointments: [{
//     customer_id: 1000,                   <- Marco
//     staff_member_id: 3000,               <- staff Giuseppe
//     staff_member_treatment_id: 3056,     <- Massaggio di coppia
//     children_appointments: [{
//       customer_id: -4,
//       customer_name: 'Moglie di Marco',
//       staff_member_id: 4000,             <- staff Chiara
//       staff_member_treatment_id: 4056,   <- Massaggio di coppia
//       children: []
//     }]
//   }]
// }
