import { Appointment } from 'models';

export type AppointmentInfoSubset = {
  always_apply_extra_time_after?: Appointment['always_apply_extra_time_after'];
  extra_time_staff_member_after: Appointment['extra_time_staff_member_after'];
  time_no_tz: Appointment['time_no_tz'];
  customer_id: Appointment['customer_id'];
  staff_member_id: Appointment['staff_member_id'];
  'YYYY-MM-DD': Appointment['YYYY-MM-DD'];
};

const hasValidStaffMemberExtraTime = (extraTime: Appointment['extra_time_staff_member_after']): extraTime is number =>
  typeof extraTime === 'number';

const shouldAlwaysApplyExtraTime = (appointment: AppointmentInfoSubset): boolean =>
  Boolean(appointment.always_apply_extra_time_after && appointment.extra_time_staff_member_after);

const hasFollowingAppointmentByStaffMemberAndCustomer =
  (compareTo: AppointmentInfoSubset) =>
  (appointment: AppointmentInfoSubset): boolean =>
    appointment.customer_id === compareTo.customer_id &&
    appointment.staff_member_id === compareTo.staff_member_id &&
    appointment['YYYY-MM-DD'] === compareTo['YYYY-MM-DD'] &&
    appointment.time_no_tz > compareTo.time_no_tz &&
    Boolean(appointment.extra_time_staff_member_after);

const isLastAppointmentWithExtraTime = (
  appointment: AppointmentInfoSubset,
  appointmentList: AppointmentInfoSubset[]
): boolean => !appointmentList.find(hasFollowingAppointmentByStaffMemberAndCustomer(appointment));

const hasExtraTimeApplied = (appointment: AppointmentInfoSubset, appointmentList: AppointmentInfoSubset[]): boolean => {
  switch (true) {
    case shouldAlwaysApplyExtraTime(appointment):
      return true;
    case isLastAppointmentWithExtraTime(appointment, appointmentList):
      return true;
    default:
      return false;
  }
};

export { hasExtraTimeApplied, hasValidStaffMemberExtraTime };
