import { COLORS } from './colors';

export const TYPOGRAPHY = {
  fontFamilies: {
    default: "'Lato', sans-serif",
  },
  fontWeights: {
    normal: 400,
    bold: 700,
  },
  fontSizes: {
    '2xl': 32,
    xl: 28,
    lg: 24,
    md: 16,
    sm: 14,
    xs: 12,
    '2xs': 10,
  },
  colors: {
    default: COLORS.text1,
  },
} as const;
