/* eslint-disable no-restricted-imports */
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeResponseToJSON } from 'API/http.utils';
import {
  CancellationProtectionInfoSMSSearchParams,
  CancellationProtectionInfoSMSResponse,
  CancellationProtectionInfoSMSVariables,
} from 'API/types';

export const CANCELLATION_PROTECTION_INFO_SMS =
  ({ variables: { booking_token, secret_token, venue_id } }: V<CancellationProtectionInfoSMSVariables>) =>
  (): Promise<CancellationProtectionInfoSMSResponse> =>
    Http()
      .get<CancellationProtectionInfoSMSResponse, CancellationProtectionInfoSMSSearchParams>(
        ENDPOINT.CANCELLATION_PROTECTIONS._INFO_SMS(venue_id)
      )({
        searchParams: { booking_token, secret_token },
      })
      .then(maybeResponseToJSON);
