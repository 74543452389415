import { useState, useCallback, useEffect } from 'react';
import { BREAKPOINTS } from 'styles';

/**
 * if media query matches, trigger actions both when the component renders and window is resized
 */

export const useTriggerActionOnMediaQuery = <A>(
  mediaQuery: string,
  action: () => void,
  dependencies: A[] = []
): void => {
  const [hasTriggeredActions, setHasTriggeredActions] = useState(false);

  const triggerActions = useCallback(() => {
    if (!hasTriggeredActions && window.matchMedia(`(${mediaQuery})`).matches) {
      setHasTriggeredActions(true);
      action();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasTriggeredActions, mediaQuery, ...dependencies]);

  // on did mount
  useEffect(() => {
    triggerActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // on did mount & on resize
  useEffect(() => {
    window.addEventListener('resize', triggerActions);
    return (): void => {
      window.removeEventListener('resize', triggerActions);
    };
  }, [triggerActions]);

  // on action has been triggered
  useEffect(() => {
    if (hasTriggeredActions) {
      window.removeEventListener('resize', triggerActions);
    }
  }, [hasTriggeredActions, triggerActions]);
};

export const useTriggerActionIfDesktop = <A>(action: () => void, dependencies: A[] = []): void =>
  useTriggerActionOnMediaQuery(`min-width: ${BREAKPOINTS.desktop}px`, action, dependencies);
