import { FunctionComponent, MouseEventHandler, useCallback, useMemo } from 'react';
import { GracePeriodStateProps } from './GracePeriodBanner.container';
import AlertBanner, { Type, Position } from 'components/lib/AlertBanner';
import { Text, Section, Badge, Button, Icon } from 'components/core';
import { BannerWrapper, CloseButton, ContentWrapper, TextContent } from './GracePeriodBanner.theme';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import { shouldShowBanner } from './GracePeriodBanner.utils';
import { PAGE_TEMPLATE } from 'models';

export type GracePeriodBannerProps = GracePeriodStateProps & {
  template: string;
  goToPage: (path: string) => void;
  dispatchGracePeriodDismiss: () => void;
};

const GracePeriodBanner: FunctionComponent<GracePeriodBannerProps & WrappedComponentProps> = ({
  goToPage,
  dispatchGracePeriodDismiss,
  intl,
  gracePeriodDismiss,
  billingDetails,
  isInGracePeriod,
  template,
}) => {
  const position = useMemo(() => (template === PAGE_TEMPLATE.AGENDA ? Position.BOTTOM : Position.TOP), [template]);
  const daysLeft = useMemo(() => moment(billingDetails?.will_block_access_at).diff(moment(), 'days'), [billingDetails]);

  const handleClickButton: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();
      dispatchGracePeriodDismiss();
      goToPage('/account/billing');
    },
    [dispatchGracePeriodDismiss, goToPage]
  );

  const hanldeCloseModal = useCallback(
    (e: Event) => {
      e.stopPropagation();
      dispatchGracePeriodDismiss();
    },
    [dispatchGracePeriodDismiss]
  );

  if (isInGracePeriod && shouldShowBanner(gracePeriodDismiss)) {
    return (
      <BannerWrapper position={position}>
        <AlertBanner
          show={true}
          type={Type.BLACK}
          position={position}
          hasShadow={false}
          autoClose={false}
          maxWidth="1100"
        >
          <ContentWrapper>
            <Section marginRight={0.5}>
              <Badge color="red">
                <Text uppercase bold size={10} color="white" intl="grace_period.banner.button" />
              </Badge>
            </Section>
            <TextContent>
              <Text color="white" size={14} bold>
                {`${intl.formatMessage({ id: 'grace_period.banner.error_text' })} `}
                <u>
                  <FormattedMessage
                    values={{ days_count: daysLeft }}
                    id="grace_period.banner.left_time"
                    defaultMessage={`{days_count, plural, =0 {${intl.formatMessage({
                      id: 'grace_period.banner.left_time.zero',
                    })}} one {${intl.formatMessage(
                      {
                        id: 'grace_period.banner.left_time.one',
                      },
                      { days_count: daysLeft }
                    )}} other {${intl.formatMessage(
                      { id: 'grace_period.banner.left_time.other' },
                      { days_count: daysLeft }
                    )}}}`}
                  />
                </u>
              </Text>
            </TextContent>
            <Button color="white" size="medium" onClick={handleClickButton}>
              <Text color="black" size={11} bold uppercase spacing={0.67} intl="grace_period.banner.badge" />
            </Button>
          </ContentWrapper>
          <CloseButton onClick={hanldeCloseModal}>
            <Icon type="close" color="white" size={16} />
          </CloseButton>
        </AlertBanner>
      </BannerWrapper>
    );
  }

  return null;
};

export default injectIntl(GracePeriodBanner);
