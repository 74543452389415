import { RefObject } from 'react';

const calcBottomSpace = (clientHeight: number, triggerOptions: DOMRect, gap: number): number =>
  clientHeight - triggerOptions.bottom - gap;

const calcTopSpace = (triggerOptions: DOMRect, gap?: number): number =>
  gap ? triggerOptions.top - gap : triggerOptions.top;

export const dropDownPosition = <TR extends HTMLElement = HTMLButtonElement>(
  triggerRef: RefObject<TR>
): 'top' | 'bottom' | undefined => {
  if (triggerRef.current) {
    const trigger = triggerRef.current.getBoundingClientRect();

    const { innerHeight: clientHeight } = window;

    // Check if there's enough space on the bottom
    const moveTop = calcBottomSpace(clientHeight, trigger, 5) < calcTopSpace(trigger);

    return moveTop ? 'top' : 'bottom';
  }
  return;
};

export function dropDownMaxHeight<TR extends HTMLElement = HTMLButtonElement>(
  triggerRef: RefObject<TR>,
  position: 'top' | 'bottom' | undefined,
  customMaxHeight?: number
): number | undefined {
  if (triggerRef.current) {
    const trigger = triggerRef.current.getBoundingClientRect();

    const { innerHeight: clientHeight } = window;

    // Check if we need to set a max-height
    const maxSpace = position === 'top' ? calcTopSpace(trigger, 20) : calcBottomSpace(clientHeight, trigger, 20);
    const maxHeight = customMaxHeight && maxSpace > customMaxHeight ? customMaxHeight : maxSpace;

    return maxHeight;
  }
  return;
}
