import { styled, css } from 'styles';
import { FONTS, COLORS } from 'styles';
import Field, { fieldSizes } from 'components/core/Field/theme';

const sizeStyles = {
  small: css`
    padding: 2px;

    > div {
      height: ${fieldSizes.innerHeight.small() - 4}px;

      .SwitchLabel__Option {
        padding: 0 9px;
      }

      &:after {
        height: ${fieldSizes.innerHeight.small() - 4}px;
        border-radius: ${fieldSizes.innerHeight.small() - 4}px;
      }
    }
  `,
  default: css`
    padding: 3px;

    > div {
      height: ${fieldSizes.innerHeight.default() - 6}px;

      .SwitchLabel__Option {
        padding: 0 10px;
      }

      &:after {
        height: ${fieldSizes.innerHeight.default() - 6}px;
        border-radius: ${fieldSizes.innerHeight.default() - 6}px;
      }
    }
  `,
  large: css`
    padding: 3px;

    > div {
      height: ${fieldSizes.innerHeight.large() - 6}px;

      .SwitchLabel__Option {
        padding: 0 14px;
      }

      &:after {
        height: ${fieldSizes.innerHeight.large() - 6}px;
        border-radius: ${fieldSizes.innerHeight.large() - 6}px;
      }
    }
  `,
};

const SwitchLabel = styled(Field)(
  ({ disabled, size, width, selectedIndex, translateXValue, transition }) => css`
    ${(size && sizeStyles[size]) || sizeStyles.default};

    display: inline-block;
    width: ${width || 'auto'};
    background-color: #ffffff;

    > div {
      position: relative;
      display: flex;
      align-items: center;
      cursor: pointer;
      ${disabled &&
      css`
        pointer-events: none;
        opacity: 0.5;
      `};

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        background-color: ${disabled ? 'gray' : COLORS.blue};
        transition: ${transition ? 'transform 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86)' : 'none'};
        transform: translateX(${translateXValue}px);
      }

      .SwitchLabel__Option {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        color: #000;

        path {
          fill: #000;
        }

        :nth-of-type(${selectedIndex + 1}) {
          color: #fff;

          path {
            fill: #fff;
          }
        }
      }
    }
  `
);

const Option = styled('span')`
  position: relative;
  top: -1px;
  z-index: 1;
  display: inline-block;
  width: 50%;
  ${FONTS.switchDefault};
  text-align: center;
  transition: color 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);

  path {
    transition: fill 0.4s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  }
`;

SwitchLabel.Option = Option;

export default SwitchLabel;
