import { styled } from 'styles';

export const LogoWrapper = styled('div')`
  max-width: 320px;
  .Logo__uala svg {
    width: 159px;
    height: auto;
  }

  .Logo__business svg {
    margin-top: 15px;
    width: 75px;
    height: 8px;
  }

  .Logo__tw svg {
    width: 250px;
  }

  .Logo__funkmartini svg {
    width: 180px;
  }

  .Logo__inkdome svg {
    width: 160px;
  }

  .Logo__kadus > img {
    max-width: 65%;
  }

  .Logo__parrucchieredelfuturo {
    > img {
      width: 75%;
    }
  }
  .Logo__labiosthetique {
    > img {
      width: 300px;
    }
  }
  .Logo__fairynails {
    > img {
      width: 200px;
    }
  }

  .Logo__envx > img,
  .Logo__iol > img,
  .Logo__comfortzone > img,
  .Logo__davines > img {
    max-width: 180px;
  }

  .Logo__oreal {
    > img {
      width: 65%;
    }
  }

  .Logo__mygreatlengthsbusiness {
    > img {
      width: 100%;
    }
  }

  .Logo__twdavines {
    > img {
      width: 75%;
    }
  }
`;
