import { V } from 'API';
import { useCallback, useState } from 'react';

type Options<A, E> = {
  readonly onCompleted?: (response: A) => void;
  readonly onError?: (error: E) => void;
  readonly returnDataOnFailure?: boolean;
};

type Fun<Var, Res> = ({ variables }: V<Var>) => () => Promise<Res>;

export type ExecuteFn<Var, QueryResponse, QueryError> = (
  variables: V<Var>,
  options?: Options<QueryResponse, QueryError>
) => Promise<void>;

/**
 * @description Remote State
 *
 * @example
 * ```ts
 *
 * const [queryVenueProductsSearch, { loading, data }] = useRemote(API.VENUE_PRODUCTS._SEARCH);
 * const list = data?.data?.venue_products ?? [];
 *
 * const onSearch = (x: { query: string }): Promise<void> | void => {
 *  queryVenueProductsSearch({ variables: { text: x.query.trim(), page: '1' } }, {
 *    onCompleted: res => console.log(res.data.venue_products),
 *    onError: console.error,
 *  });
 * };
 *
 *```
 */
export const useRemote = <Var, QueryResponse, QueryError = unknown>(
  query: Fun<Var, QueryResponse>
): [
  ExecuteFn<Var, QueryResponse, QueryError>,
  { data: QueryResponse | undefined; loading: boolean; error: QueryError | undefined },
] => {
  const [data, setData] = useState<QueryResponse>();
  const [error, setError] = useState<QueryError>();
  const [loading, setLoading] = useState<boolean>(false);

  const execute: (variables: V<Var>, options?: Options<QueryResponse, QueryError>) => Promise<void> = useCallback(
    async (variables, options) => {
      const returnDataOnFailure = Boolean(options?.returnDataOnFailure);
      try {
        setLoading(true);
        const result = (await query(variables)()) as unknown as QueryResponse & { success?: boolean };
        returnDataOnFailure && setData(result);
        if (!result?.success) {
          options?.onError && options.onError(result as unknown as QueryError);
          setLoading(false);
          setError(true as unknown as QueryError);
          return;
        }
        !returnDataOnFailure && setData(result);
        options?.onCompleted && options.onCompleted(result);
        setError(undefined);
        setLoading(false);
      } catch (e) {
        options?.onError && options.onError(e as QueryError);
        setError(e as QueryError);
        setLoading(false);
      }
    },
    [query]
  );

  return [
    execute,
    {
      data,
      loading,
      error,
    },
  ];
};
