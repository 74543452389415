import { styled, css } from 'styles';

import { withTheme } from 'components/core';

export const StyledDatePickerValue = withTheme(
  styled('div')(
    ({ theme: { utils } }) => css`
      background: inherit;
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-family: ${utils.fontFamily};
      flex: 1;
      width: 100%;
    `
  )
);

export const StyledDatePickerWrapper = withTheme(
  styled('div')(
    ({ theme: { colors, utils } }) => css`
      position: relative;
      font-family: ${utils.fontFamily};

      .DayPicker {
        position: absolute;
        top: 0;
        z-index: 1000;
      }

      .CalendarDay__selected,
      .CalendarDay__selected:active {
        background: ${colors.blue};
        border: 1px solid ${colors.blue};
        color: ${colors.white};
      }

      .CalendarDay__hovered_span,
      .CalendarDay__hovered_span:hover {
        color: ${colors.text1};
        background: ${colors.buttonPrimaryBackground};
      }

      .CalendarDay__default {
        border: 1px solid ${colors.inputBorder};

        &:hover {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: ${colors.buttonPrimaryBackground};
            opacity: 0.4;
          }
        }
      }

      .CalendarDay__selected,
      .CalendarDay__selected_span {
        background-color: ${colors.buttonPrimaryBackground};
        border: 1px solid ${colors.inputBorder};
        color: ${colors.buttonPrimaryColor};

        &:hover {
          background-color: ${colors.buttonPrimaryBackground};
          &:after {
            background-color: ${colors.bgWhite};
          }
        }
      }
    `
  )
);
