import { styled } from 'styles';

import { withTheme } from 'components/core';

export const StyledTextArea = withTheme(styled('textarea')`
  outline: none;
  background: inherit;
  padding: 0.1rem 0 0;
  margin: 0;
  cursor: text;
  border: none;
  font-size: 16px;
  font-family: ${({ theme }) => theme.utils.fontFamily};
  line-height: calc(22 / 16);
  width: 100%;
  resize: none;
  overflow: hidden;
`);
