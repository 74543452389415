import PropTypes from 'prop-types';
import { connectFormElement, getErrorByName } from '@uala/react-forms';
import ReactTextMask from 'react-text-mask';
import createAutoCorrectedDatePipe from 'text-mask-addons/dist/createAutoCorrectedDatePipe';

import { DefaultSettings, INPUT_INLINE, INPUT_SIZES } from '../../config';
import { StyledInputTag } from './DateMask.theme';
import { BaseInputWrapper, FormControl, FormControlInput, Label, Helper, elementPropTypes } from '../../shared';

const InputMask = ({
  name,
  label,
  withInlineValidation,
  intl,
  pattern,
  delimiter,
  labelPosition,
  emitChange,
  emitDidChange,
  values,
  errors,
  helper,
  helperPosition,
  size,
  tooltip,
  ...inputProps
}) => {
  const fieldError = getErrorByName(errors, name);
  const error = !!fieldError;
  const value = values[name] || '';
  const autoCorrectionPipe = createAutoCorrectedDatePipe('dd/mm/yyyy');

  helper = (withInlineValidation && fieldError) || helper || null;

  return (
    <FormControl labelPosition={labelPosition} className="DateMask">
      {label && (
        <Label htmlFor={name} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput labelPosition={labelPosition}>
        <BaseInputWrapper error={error} size={size}>
          <ReactTextMask
            mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            {...inputProps}
            render={(ref, props) => <StyledInputTag {...props} name={name} ref={ref} type="text" />}
            value={value}
            onChange={(e) => emitChange(name, e.target.value)}
            onBlur={(e) => emitDidChange(name, e.target.value)}
            keepCharPositions
            placeholderChar={'\u2000'}
            pipe={autoCorrectionPipe}
          />
        </BaseInputWrapper>
        {helper && <Helper position={helperPosition} tooltip={tooltip} error={error} text={helper} intl />}
      </FormControlInput>
    </FormControl>
  );
};

InputMask.displayName = 'InputMask';

InputMask.propTypes = {
  ...elementPropTypes,
  withInlineValidation: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(INPUT_SIZES),
};

InputMask.defaultProps = {
  autoComplete: DefaultSettings.AUTOCOMPLETE,
  withInlineValidation: true,
  required: false,
  placeholder: '',
  labelPosition: INPUT_INLINE,
  size: DefaultSettings.INPUT_SIZE,
  intl: true,
};

export default connectFormElement(InputMask);
