import { ReactNode, FunctionComponent, useEffect, useState } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import HeaderTitleTheme from './HeaderTitle.theme';
import { Icon } from 'components/core';

type HeaderTitleProps = {
  isRoot: boolean;
  rootIcon?: string;
  rootTitle: ReactNode;
  onDetailClick?: (args: unknown) => void;
  detailIcon?: string;
  detailTitle?: unknown;
};

const HeaderTitle: FunctionComponent<HeaderTitleProps & WrappedComponentProps> = ({
  isRoot,
  rootIcon,
  rootTitle,
  onDetailClick = (): void => {},
  detailTitle,
  detailIcon,
}) => {
  const [currentDetailTitle, setCurrentDetailTitle] = useState(detailTitle);
  const [currentDetailIcon, setCurrentDetailIcon] = useState(detailIcon);

  useEffect(() => {
    setCurrentDetailTitle(detailTitle);
  }, [detailTitle]);

  useEffect(() => {
    setCurrentDetailIcon(detailIcon);
  }, [detailIcon]);

  return (
    <HeaderTitleTheme className="HeaderTitle" isRoot={isRoot}>
      <div className="HeaderTitle__root">
        {rootIcon && <Icon marginRight type={rootIcon} size={16} color="text2" />}
        {rootTitle}
      </div>
      <div className="HeaderTitle__detail" onClick={onDetailClick}>
        {currentDetailIcon && <Icon marginRight type={detailIcon} size={16} color="text2" />}
        {currentDetailTitle}
      </div>
    </HeaderTitleTheme>
  );
};

HeaderTitle.displayName = 'HeaderTitle';

export default injectIntl(HeaderTitle);
