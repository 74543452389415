/**
 * @example
 * ```
 * expect(arraysAreEquals(['a', 'b'], ['a', 'b'])).toEqual(true);
 * expect(arraysAreEquals([1, 2], [1, 2])).toEqual(true);
 * expect(arraysAreEquals([1, 2], [1, 3])).toEqual(false);
 * ```
 *
 * @description
 * checks strict equality between Arrays
 */
export const arraysAreEquals = <A>(a: ReadonlyArray<A>, b: ReadonlyArray<A>): boolean => {
  a = Array.isArray(a) ? a.sort() : [];
  b = Array.isArray(b) ? b.sort() : [];
  return a.length === b.length && a.every((el, ix) => el === b[ix]);
};
