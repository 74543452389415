import {
  Checkout,
  Counter,
  EcommerceOrder,
  ExtraOpening,
  OrderDeveliveryNote,
  Shipper,
  ShippingOption,
  StaffMember,
  StaffMemberTreatment,
  TaxOffice,
  Vacancy,
  Venue,
  VenueTreatment,
  CheckoutReprint,
  Cart,
  CheckoutLineItem,
  Customer,
  MarketingPromotion,
  VenueProduct,
  PatchTest,
  MarketingPromotionType,
  Appointment,
  Package,
  TreatmentCategory,
  VenueTreatmentGroup,
} from 'models';
import { CustomerPromotion } from 'models/customerPromotion';
import { Debt } from 'models/debt';
import { PointsTransaction } from 'models/pointsTransaction';
import { ValueOf } from 'utils/utilityTypes';
import { Request } from './requestPool';

export const METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
} as const;

export interface HeadersEntries {
  [key: string]: string;
}

export type ResponseData = {
  order_delivery_notes?: OrderDeveliveryNote[];
  order_delivery_note?: OrderDeveliveryNote;
  pdf_path: string;
  extra_openings: Array<ExtraOpening>;
  vacancies: Array<Vacancy>;
  staff_members: Array<StaffMember>;
  staff_member_treatments: Array<StaffMemberTreatment>;
  timestamp: string;
  venue_treatments: Array<VenueTreatment>;
  venue_treatment_group: VenueTreatmentGroup;
  venue_treatment_groups: VenueTreatmentGroup[];
  ecommerce_order: EcommerceOrder;
  ecommerce_orders: Array<EcommerceOrder>;
  ecommerce_shippers: Array<Shipper>;
  ecommerce_shipping_options?: Array<ShippingOption>;
  ecommerce_shipping_option: ShippingOption;
  tax_office_codes: Array<TaxOffice>;
  venue_resource_counters: Array<Counter>;
  checkouts: Array<Checkout>;
  checkout: Checkout;
  checkout_reprint: CheckoutReprint;
  customer: Customer;
  customers: Customer[];
  customers_count: number;
  line_items: CheckoutLineItem[];
  debt: Debt;
  customer_carts: Cart[];
  customer_cart: Cart;
  customer_promotions: CustomerPromotion[];
  marketing_promotions: MarketingPromotion[];
  marketing_promotion: MarketingPromotion;
  marketing_promotion_types: MarketingPromotionType[];
  points_transactions: PointsTransaction[];
  venue_products: VenueProduct[];
  patch_tests: PatchTest[];
  patch_test: PatchTest;
  appointments: Appointment[];
  venue: Venue;
  package: Package;
  packages: ReadonlyArray<Package>;
  treatment_categories: ReadonlyArray<TreatmentCategory>;
};
export interface BackendResponseEnvelope<T> {
  success?: boolean;
  error?: string;
  info?: string[];
  data: T;
}
export type Response = BackendResponseEnvelope<ResponseData>;
export interface ErrorResponse {
  success?: boolean;
  error?: {
    code: number;
    data?: {
      latest_fiscal_day?: {
        id: number;
      };
      marketing_promotion?: MarketingPromotion;
    };
  };
  info?: string[];
}

export type OnError = (obj?: { error: string; info: string[]; response?: Response }) => void | object;

export type OnResponse = (response: Response, headers?: HeadersEntries) => void | object;

export const REDUX_API_ACTION_TYPES = {
  GET_REQUEST: 'API_GET_REQUEST',
  GET_RESPONSE: 'API_GET_RESPONSE',
  POST_REQUEST: 'API_POST_REQUEST',
  POST_RESPONSE: 'API_POST_RESPONSE',
  DATA_SET: 'API_DATA_SET',
} as const;

export interface ApiAction {
  type: ValueOf<typeof REDUX_API_ACTION_TYPES>;
}

export type UniqueID = keyof Request;
export interface ApiRequest<D = unknown> {
  auth?: string;
  path: string;
  data?: { [key: string]: D } | FormData;
  onError?: OnError;
  handleAllErrors?: boolean;
  onResponse?: OnResponse;
  handleAllResponses?: boolean;
  uniqueId?: UniqueID;
  guest?: boolean;
  customErrorResponseStrategy?: (response: ErrorResponse) => void;
}

export type ApiGet = ApiRequest;

export interface ApiPost extends ApiRequest {
  unsetUniqueId?: string;
  formData?: FormData;
  method?: string;
  customErrorResponseStrategy?: (response: ErrorResponse) => void;
}

export interface ApiDataSetPayload {
  object_type: string;
  object: object;
}

export interface ApiDataSetAction extends ApiDataSetPayload {
  venue_id: Venue['id'];
}

export interface ApiRequestPayload {
  path: ApiRequest['path'];
  data?: ApiRequest['data'];
  venue_id?: Venue['id'];
  auth?: string;
  uniqueId?: ApiRequest['uniqueId'];
  response?: Response;
}

export type APIResponseAction = {
  type: typeof REDUX_API_ACTION_TYPES.POST_RESPONSE | typeof REDUX_API_ACTION_TYPES.GET_RESPONSE;
} & ApiRequestPayload;
