import { useCallback } from 'react';
import PropTypes from 'prop-types';
import { connectFormElement, getErrorByName } from '@uala/react-forms';

import { DefaultSettings, INPUT_INLINE } from '../../config';
import { StyledCheckbox } from './Checkbox.theme';
import { FormControl, FormControlInput, Label, Helper, elementPropTypes } from '../../shared';

const Checkbox = ({
  name,
  label,
  labelPosition,
  intl,
  emitChange,
  emitDidChange,
  values,
  errors,
  helper,
  helperPosition,
  tooltip,
  disabled,
  ...inputProps
}) => {
  const fieldError = getErrorByName(errors, name);
  helper = fieldError || helper || null;
  const error = !!fieldError;
  const checked = values[name] || false;

  const onClick = useCallback(() => {
    emitChange(name, !checked);
    emitDidChange(name, !checked);
  }, [checked, emitChange, emitDidChange, name]);

  return (
    <FormControl labelPosition={labelPosition} className="Checkbox">
      {label && (
        <Label htmlFor={name} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput labelPosition={labelPosition}>
        <StyledCheckbox
          {...inputProps}
          checked={checked}
          disabled={disabled}
          onClick={disabled ? undefined : onClick}
          error={error}
        />
        {helper && <Helper position={helperPosition} tooltip={tooltip} error={error} text={helper} intl />}
      </FormControlInput>
    </FormControl>
  );
};

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
  value: '',
  autoComplete: DefaultSettings.AUTOCOMPLETE,
  required: false,
  placeholder: '',
  labelPosition: INPUT_INLINE,
  intl: true,
};

Checkbox.propTypes = {
  ...elementPropTypes,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
};

export default connectFormElement(Checkbox);
