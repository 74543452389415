/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { VenueProductShowResponse, VenueProductShowQuery } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const SHOW =
  ({ variables: { product_id } }: V<VenueProductShowQuery>) =>
  (): Promise<VenueProductShowResponse> =>
    Http()
      .get<VenueProductShowResponse, never>(ENDPOINT.VENUE_PRODUCTS._SHOW(product_id))({})
      .then(maybeResponseToJSON);
