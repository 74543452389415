import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import {
  VENUE_WORKSTATIONS_LIST_REQUEST,
  VENUE_WORKSTATIONS_LIST_RESPONSE,
  VENUE_WORKSTATIONS_UPDATE_RESPONSE,
  VENUE_WORKSTATIONS_DELETE_RESPONSE,
  VENUE_WORKSTATIONS_CREATE_RESPONSE,
} from 'actionTypes';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const workstations = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_WORKSTATIONS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_WORKSTATIONS_LIST_RESPONSE:
      if (!action.workstations) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.workstations).map((item) => ({ id: item.id }))),
      };

    case VENUE_WORKSTATIONS_UPDATE_RESPONSE:
      if (!action.workstation) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.workstation || [])
            .map((item) => ({ id: item.id })),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    case VENUE_WORKSTATIONS_DELETE_RESPONSE:
      if (!action.workstation) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .filter(({ id }) => id !== action.workstation.id)
            .map((item) => ({ id: item.id }))
        ),
      };

    case VENUE_WORKSTATIONS_CREATE_RESPONSE:
      if (!action.workstation) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.workstation || [])
            .map((item) => ({ id: item.id }))
        ),
      };

    default:
      return state;
  }
};

export const workstationsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_WORKSTATIONS_LIST_REQUEST:
    case VENUE_WORKSTATIONS_LIST_RESPONSE:
    case VENUE_WORKSTATIONS_UPDATE_RESPONSE:
    case VENUE_WORKSTATIONS_CREATE_RESPONSE:
    case VENUE_WORKSTATIONS_DELETE_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = workstations(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
