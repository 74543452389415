import { useState, useCallback } from 'react';
import { Text } from 'components/core';
import ButtonLoader from 'components/lib/ButtonLoader';
import { injectIntl } from 'react-intl';

const PrimaryButton = ({ intl, disabled, onClick, loading }) => (
  <ButtonLoader primary disabled={disabled} onClick={onClick} loading={loading} width={258}>
    <Text block center size={12} uppercase bold color="white" spacing={0.72} intl={intl} />
  </ButtonLoader>
);

const DeleteButton = ({ intl, onClick, loading }) => (
  <ButtonLoader unstyled basic color="red" onClick={onClick} loading={loading} width={258}>
    <Text block center size={12} uppercase bold color="red" spacing={0.72} intl={intl} />
  </ButtonLoader>
);

const Actions = ({ touched, valid, onSave, onDelete, modalOpen, modalClose, intl }) => {
  const [loading, setLoading] = useState(false);

  const save = useCallback(() => {
    setLoading(true);
    onSave()
      .then(() => {
        setLoading(false);

        modalClose({ id: 'promo-flash' });

        modalOpen({
          id: 'confirm-promo-flash-activate',
          config: {
            component: 'Success',
            message: 'promotions.flash.activate.confirm',
            dismissLabel: 'close',
            headingMessage: 'promotions.activate',
            noResponsive: true,
          },
        });
      })
      .catch(() => setLoading(false));
  }, [modalOpen, onSave, modalClose]);

  const deletePromo = () => {
    modalOpen({
      id: 'confirm-promo-flash-delete',
      config: {
        component: 'Attention',
        content: intl.formatMessage({ id: 'promotions.flash.delete.confirm' }),
        onConfirm: () => {
          setLoading(true);
          onDelete()
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        },
      },
    });
  };

  return (
    <>
      {touched ? (
        <PrimaryButton loading={loading} intl="save" onClick={save} />
      ) : valid ? (
        <DeleteButton loading={loading} intl="delete" onClick={deletePromo} />
      ) : (
        <PrimaryButton intl="save" disabled />
      )}
    </>
  );
};

export default injectIntl(Actions);
