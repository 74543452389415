import { MouseEventHandler } from 'react';
import { styled, css, Colors } from 'styles';

const fontStyles = {
  h1: `
    font-size: 1.8rem;
  `,
  h2: `
    font-size: 1.6rem
  `,
  h3: `
    font-size: 1.4rem
  `,
  h4: `
    font-size: 1.2rem
  `,
  h5: `
    font-size: 1.1rem
  `,
  h6: `
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 1px;
  `,
  input: `
    font-size: 1.6rem
  `,
};

export type StyledHeadingProps = {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'input';
  editable?: boolean;
  bold?: boolean;
  flex?: boolean;
  inline?: boolean;
  uppercase?: boolean;
  color?: Colors;
  letterSpacing?: number;
  size?: number;
  ellipsis?: boolean;
  truncate?: boolean;
  onClick?: MouseEventHandler<HTMLHeadingElement>;
};

export const StyledHeading = styled('h1')<StyledHeadingProps>(
  ({
    theme: { colors },
    bold,
    flex,
    inline,
    uppercase,
    letterSpacing,
    as,
    color,
    size,
    ellipsis,
    truncate,
    onClick,
  }) => css`
    ${flex && { flex: 1 }};
    ${uppercase && `text-transform: uppercase;`};
    ${onClick && `cursor: pointer;`};
    ${inline
      ? `
          display: inline-block;
          vertical-align: middle;
        `
      : `
          display: block;
        `};

    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Lato;

    ${(ellipsis || truncate) &&
    `
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0 10px 0 0;
        `};

    ${as === 'input' &&
    css`
      display: block;
      border: 0;
      appearance: none;
      outline: 0;

      &::placeholder {
        color: ${colors.inputPlaceHolder};
      }
    `};

    ${
      // @ts-expect-error TS upgrade
      fontStyles[as || 'h2'] || fontStyles.h1
    };
    ${bold ? `font-weight: 700` : `font-weight: 400`};
    ${size && `font-size: ${size / 10}rem;`};
    ${letterSpacing && `letter-spacing: ${letterSpacing}px`};
    ${color &&
    css`
      color: ${colors[color] || colors.text1};
      path {
        fill: ${colors[color] || colors.text1};
      }
    `};
  `
);
