import { createSelector } from 'reselect';
import { getCurrentVenueId } from '../getCurrentVenue';
import filterDeletedItems from 'utils/filterDeletedItems';

const getPromotionsTypesByVenue = (state) => state.promotionsTypesByVenue;

export const getPromotionsTypesLoading = createSelector(
  [getCurrentVenueId, getPromotionsTypesByVenue],
  (venue_id, promotionsTypesByVenue) => {
    return promotionsTypesByVenue[venue_id] && promotionsTypesByVenue[venue_id].isFetching ? true : false;
  }
);

const emptyPromotionsTypes = [];
export const getPromotionsTypesList = createSelector(
  [getCurrentVenueId, getPromotionsTypesByVenue],
  (venue_id, promotionsTypesByVenue) => {
    const promotionsTypesList =
      (promotionsTypesByVenue[venue_id] && promotionsTypesByVenue[venue_id].items) || emptyPromotionsTypes;

    if (promotionsTypesList === emptyPromotionsTypes) {
      return promotionsTypesList;
    }
    return promotionsTypesList.filter(filterDeletedItems);
  }
);

export const getPromotionsTypesByType = createSelector([getPromotionsTypesList], (promotionsTypesList) => {
  return promotionsTypesList.reduce((byType, item) => {
    byType[item.type] = item;
    return byType;
  }, {});
});
