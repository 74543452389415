import { PatchTest } from 'models';
import { REQUEST_PATCH_TESTS, RESPONSE_PATCH_TESTS, UPDATE_PATCH_TEST } from 'actionTypes';

import { reducerValuesOnResponse, reducerValuesOnInit, reducerValuesOnRequest } from 'utils/reducersUtils';
import { StandardAction } from 'actions/types';
import {
  DefaultPayload,
  PatchTestActions,
  ResponsePatchTestsPayload,
  PerformPatchTestResultPayload,
} from 'actions/patchTest';

type PatchTestsState = {
  items?: PatchTest[];
  isFetching?: boolean | number;
  didInvalidate?: boolean;
};

const patchTests = (
  state: PatchTestsState = reducerValuesOnInit(),
  action: StandardAction<PatchTestActions, DefaultPayload | ResponsePatchTestsPayload | PerformPatchTestResultPayload>
): PatchTestsState => {
  switch (action.type) {
    case REQUEST_PATCH_TESTS:
      return { ...state, ...reducerValuesOnRequest() };
    case RESPONSE_PATCH_TESTS:
      return {
        ...state,
        ...reducerValuesOnResponse((action.payload as ResponsePatchTestsPayload).data?.patch_tests || [], {
          overrideDuplicateIds: 1,
        }),
      };
    case UPDATE_PATCH_TEST: {
      const updatedPatchTest = (action.payload as PerformPatchTestResultPayload).data?.patch_test;

      const filteredItems = state.items?.filter((patchTest) => patchTest.id !== updatedPatchTest?.id);
      const updatedList = [...(filteredItems ? filteredItems : [])];

      return {
        ...state,
        ...reducerValuesOnResponse(updatedPatchTest ? [...updatedList, updatedPatchTest] : filteredItems, {
          overrideDuplicateIds: 1,
        }),
      };
    }
    default:
      return state;
  }
};

export const patchTestsByVenue = (
  state: { [venue_id: number]: PatchTestsState } = {},
  action: StandardAction<PatchTestActions, DefaultPayload | ResponsePatchTestsPayload>
): PatchTestsState => {
  if (action.payload?.venue_id) {
    const { venue_id } = action.payload;
    const refState = patchTests(state[venue_id], action);

    if (refState === state[venue_id]) {
      return state;
    }

    return {
      ...state,
      [venue_id]: refState,
    };
  }

  return state;
};
