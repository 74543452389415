import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import {
  BILLING_COMPANY_PRODUCTS_LIST_REQUEST,
  BILLING_COMPANY_PRODUCTS_LIST_RESPONSE,
  BILLING_ADDONS_LIST_REQUEST,
  BILLING_ADDONS_LIST_RESPONSE,
  BILLING_COMPANY_INVOICES_LIST_REQUEST,
  BILLING_COMPANY_INVOICES_LIST_RESPONSE,
  VENUES_TRANSFERS_LIST_REQUEST,
  VENUES_TRANSFERS_LIST_RESPONSE,
  API_GET_RESPONSE,
  API_POST_RESPONSE,
} from 'actionTypes';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const billingCompany = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (!action.response || !action.response.data || !action.response.data.billing_company) {
        return state;
      }

      if (
        state.items &&
        state.items.length &&
        action.response.data.billing_company.updated_at <= state.items[0].updated_at
      ) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.response.data.billing_company)),
      };

    default:
      return state;
  }
};

export const billingCompanyByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = billingCompany(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

/**
 * billingCompanyProducts
 */

const billingCompanyProducts = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case BILLING_COMPANY_PRODUCTS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case BILLING_COMPANY_PRODUCTS_LIST_RESPONSE:
      if (!action.billing_company_products) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.billing_company_products)),
      };

    default:
      return state;
  }
};

export const billingCompanyProductsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case BILLING_COMPANY_PRODUCTS_LIST_REQUEST:
    case BILLING_COMPANY_PRODUCTS_LIST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = billingCompanyProducts(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

/**
 * billingAddons
 */

const billingAddons = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case BILLING_ADDONS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case BILLING_ADDONS_LIST_RESPONSE:
      if (!action.billing_addons) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.billing_addons)),
      };

    default:
      return state;
  }
};

export const billingAddonsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case BILLING_ADDONS_LIST_REQUEST:
    case BILLING_ADDONS_LIST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = billingAddons(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

/**
 * billingCompanyInvoices
 */

const billingCompanyInvoices = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case BILLING_COMPANY_INVOICES_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case BILLING_COMPANY_INVOICES_LIST_RESPONSE:
      if (!action.billing_company_invoices) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.billing_company_invoices || []), {
          overrideDuplicateIds: 1,
        }),
      };

    default:
      return state;
  }
};

export const billingCompanyInvoicesByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case BILLING_COMPANY_INVOICES_LIST_REQUEST:
    case BILLING_COMPANY_INVOICES_LIST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = billingCompanyInvoices(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

/**
 * venueTransfers
 */

const venuesTransfers = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUES_TRANSFERS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUES_TRANSFERS_LIST_RESPONSE:
      if (!action.venue_transfers) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.venue_transfers)),
      };

    default:
      return state;
  }
};

export const venuesTransfersByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUES_TRANSFERS_LIST_REQUEST:
    case VENUES_TRANSFERS_LIST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = venuesTransfers(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
