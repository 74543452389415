import React from 'react';
import { Component } from 'react';
import PropTypes from 'prop-types';
import StyledSwitchLabel from './theme';

class SwitchLabel extends Component {
  constructor(props) {
    super(props);

    const { children } = props;
    const value = props.value || children[0].props.value;
    const activeChild = children.filter((child) => child.props.value === value)[0];
    const index = activeChild ? children.indexOf(activeChild) : 0;
    const initialValue = { value, index };

    this.state = {
      selected: initialValue,
      width: 0,
      transition: false,
    };
  }

  componentDidMount() {
    this.setWidth();
  }

  componentDidUpdate({ children, size, value }) {
    if (this.props.children !== children || this.props.size !== size) {
      this.setWidth();
    }
    if (this.props.value !== value) {
      const options = this.props.children;
      const activeChild = options.filter((child) => child.props.value === this.props.value)[0];
      if (activeChild) {
        this.setState({ selected: { value: this.props.value, index: options.indexOf(activeChild) } });
      }
    }
  }

  setWidth = () => {
    this.setState(this.switch ? { width: this.switch.offsetWidth - this.switch.offsetWidth * 0.5 } : null);
  };

  toggle = ({ value, index }) => {
    if (!this.state.transition) {
      return this.setState({ selected: { value, index }, transition: true }, () => this.props.onChange(value));
    }
    return this.setState({ selected: { value, index } }, () => this.props.onChange(value));
  };

  render() {
    const { name, onChange, ...props } = this.props;
    const {
      selected: { value, index },
      width,
      transition,
    } = this.state;
    const children = React.Children.map(this.props.children, (child, index) => {
      return React.cloneElement(child, {
        className: 'SwitchLabel__Option',
        onClick: onChange ? this.toggle.bind(this, { value: child.props.value, index }) : null,
        index,
      });
    });

    return (
      <StyledSwitchLabel
        className="SwitchLabel"
        selectedIndex={index}
        translateXValue={width * index}
        transition={transition}
        {...props}
      >
        <input type="hidden" name={name} value={value} />
        <div ref={(r) => (this.switch = r)}>{children}</div>
      </StyledSwitchLabel>
    );
  }
}

SwitchLabel.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

SwitchLabel.defaultProps = {
  size: 'default',
  onChange: () => {},
  disabled: false,
};

const Option = (props) => <StyledSwitchLabel.Option {...props}>{props.children}</StyledSwitchLabel.Option>;

Option.propTypes = {
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  children: PropTypes.node,
};

Option.displayName = 'Select.Option';

SwitchLabel.Option = Option;

export default SwitchLabel;
