import { ValueOf } from 'utils/utilityTypes';

export const PRINTER_MODE = {
  EMAIL: 'email',
  BYPASS: 'bypass',
  NONE: 'none',
} as const;

export const FISCAL_PRINTER = {
  RCH: 'RCH',
  EPSON_FP: 'EPSON_FP',
} as const;

export const ESCPOS_PRINTER = {
  '200ROWS': '200ROWS', // Meteor printer
  '1': '1', // Meteor printer
  MPOP: 'MPOP',
  ZYWELL: 'ZYWELL',
  EPSON_TM: 'EPSON_TM',
  NONE: 'none', // default thermal printer
} as const;

export const PRINTER_TYPE = {
  /**
   * for greek printers(FISCAL_GR) we only write the receipt on the disk, we don't communicate with the printer itself
   */
  FISCAL_GR: 'FISCAL_GR',
  ...FISCAL_PRINTER,
  ...ESCPOS_PRINTER,
} as const;

export type PrinterType = ValueOf<typeof PRINTER_TYPE>;
