import ENV from 'env';

type MQTTOption = 'HOST' | 'PORT' | 'SSL';

export default function getMQTTOption(option: MQTTOption): string | null {
  const key = `UALA_MQTT_${option}`;

  if (ENV.UALA_ALLOW_PARAMS_CUSTOMIZATION && window.localStorage) {
    if (window.localStorage.getItem('UALA_OVERRIDE_PARAMS') === 'true') {
      return window.localStorage.getItem(key);
    }
  }

  return (ENV && ENV[key]) || null;
}
