import { Button, Text, TextInput } from 'components/core';
import { OnChangeArg } from 'components/core/TextInput';
import { useState } from 'react';
import { MobilePasswordManagerInputTextWrapper } from './MobilePasswordManager.theme';
import { PasswordManagerCredential } from './types';

export type MobilePasswordManagerFormProps = {
  credentials: PasswordManagerCredential;
  isNewPassword: boolean;
  onSaveCredential: (credential: PasswordManagerCredential) => void;
};

const SHOW_HIDE_PASSWORD_ICON_TYPES = {
  SHOW: 'show',
  HIDE: 'hide',
} as const;

export const MobilePasswordManagerForm = ({
  credentials,
  isNewPassword,
  onSaveCredential,
}: MobilePasswordManagerFormProps): JSX.Element => {
  const [email, setEmail] = useState(credentials.email);
  const [password, setPassword] = useState(credentials.password);

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = (): void => setShowPassword(!showPassword);
  // we should alias setEmail and setPassword to avoid typescript errors, because InputText is expecting an argument that can be string, number or an object
  const handleSetEmail = setEmail as unknown as (val: OnChangeArg) => void;
  const handleSetPassword = setPassword as unknown as (val: OnChangeArg) => void;

  const handleSaveCredential = (): void => {
    onSaveCredential({
      email,
      password,
    });
  };

  return (
    <>
      <MobilePasswordManagerInputTextWrapper display="flex" flexDirection="column" gap="8px" width="100%">
        <Text color="darkBlue2" size={12} intl="signin.email" uppercase spacing={1.2} />
        <TextInput
          type="email"
          value={email}
          onChange={handleSetEmail}
          borderColor="transparent"
          data-testid="mobile_password_manager_email_input"
        />
      </MobilePasswordManagerInputTextWrapper>
      <MobilePasswordManagerInputTextWrapper display="flex" flexDirection="column" gap="8px" width="100%">
        <Text color="darkBlue2" size={12} intl="signin.password" uppercase spacing={1.2} />
        <TextInput
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={handleSetPassword}
          borderColor="transparent"
          icon={{
            size: 16,
            color: 'text2',
            type: showPassword ? SHOW_HIDE_PASSWORD_ICON_TYPES.HIDE : SHOW_HIDE_PASSWORD_ICON_TYPES.SHOW,
            onClick: toggleShowPassword,
          }}
          data-testid="mobile_password_manager_password_input"
        />
      </MobilePasswordManagerInputTextWrapper>
      <Button
        onClick={handleSaveCredential}
        primary
        block
        dataTestId={
          isNewPassword ? 'mobile_password_manager_save_button' : 'mobile_password_manager_edit_password_button'
        }
      >
        <Text uppercase color="white" bold intl={isNewPassword ? 'save' : 'agenda.update'} />
      </Button>
    </>
  );
};
