import { State } from 'reducers/types';
import { AnyAction } from 'redux';

import { APIResponseAction, REDUX_API_ACTION_TYPES } from 'actions/data_providers/data_providers_api.types';
import { Appointment, AppointmentState, CancellationProtectionSetupStatus } from 'models';
import {
  FetchPendingProtectionAppointmentsForCurrentWeekRequestAction,
  FetchPendingProtectionAppointmentsForCurrentWeekResponseAction,
  FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_REQUEST,
  FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_RESPONSE,
} from 'models/pendingProtectionAppointments.types';
import { ValueOf } from 'utils/utilityTypes';
import { isCancellationProtectionPending } from 'utils/isCancellationProtectionPending';
import { getTodayPlus7DaysEndOfDay } from 'utils/dates';

type IncomingAction =
  | FetchPendingProtectionAppointmentsForCurrentWeekResponseAction
  | FetchPendingProtectionAppointmentsForCurrentWeekRequestAction
  | AnyAction;
type PPAState = State['pendingProtectionAppointmentsByVenue'];
type PPAReducer<A extends AnyAction> = (state: PPAState, action: A) => PPAState;

const isPPAResponseAction = (
  action: IncomingAction
): action is FetchPendingProtectionAppointmentsForCurrentWeekResponseAction =>
  action.type === FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_RESPONSE;

const isPPARequestAction = (
  action: IncomingAction
): action is FetchPendingProtectionAppointmentsForCurrentWeekRequestAction =>
  action.type === FETCH_PENDING_PROTECTION_APPOINTMENTS_FOR_CURRENT_WEEK_REQUEST;

const isLegacyAPIResponseAction = (action: AnyAction): action is APIResponseAction =>
  action.type === REDUX_API_ACTION_TYPES.GET_RESPONSE || action.type === REDUX_API_ACTION_TYPES.POST_RESPONSE;

const inBookedAppointments = (appointment: Appointment): boolean => appointment.state === AppointmentState.BOOKED;

const isBookedInAWeekTimeOrLess = (appointment: Appointment): boolean =>
  new Date(appointment.time) <= getTodayPlus7DaysEndOfDay();

const inBookedInAWeekTimeOrLess = isBookedInAWeekTimeOrLess;
const inBookingsPendingProtection = isCancellationProtectionPending; // filter wording syntactic sugar

type AppointmentId = number;
const getIds = (appointments: Appointment[]): AppointmentId[] => appointments.map(({ id }) => id);
const outAppointmentsWithIdIn =
  (idList: AppointmentId[]) =>
  (appointment: Appointment): boolean =>
    !idList.includes(appointment.id);

const mergeAndUniqueAppointments = (
  currentAppointments: Appointment[],
  newAppointments: Appointment[]
): Appointment[] => {
  const newIds = getIds(newAppointments);
  return [...currentAppointments.filter(outAppointmentsWithIdIn(newIds)), ...newAppointments];
};

type newStateRequest = {
  state: PPAState;
  venueId: number | null;
  appointments?: Appointment[];
  isFetching?: boolean;
};
const defaultNewState = (): newStateRequest => ({
  state: {},
  venueId: null,
  appointments: undefined,
  isFetching: true,
});
const newState = ({ state, venueId, appointments, isFetching } = defaultNewState()): PPAState => ({
  ...state,
  [venueId ?? Symbol('null')]: {
    items: appointments,
    isFetching,
  },
});

const APPOINTMENT_STATE_TO_BE_DELETED = [
  AppointmentState.CANCELED,
  AppointmentState.DELETED,
  AppointmentState.DISCARDED,
  AppointmentState.MISSED,
];
const CANCELLATION_PROTECTION_SETUP_STATUS_TO_BE_DELETED: ValueOf<typeof CancellationProtectionSetupStatus>[] = [
  CancellationProtectionSetupStatus.CONFIRMED,
  CancellationProtectionSetupStatus.NOT_CONFIGURED,
];
const outDeletableAppointments = (appointment: Appointment) => {
  if (APPOINTMENT_STATE_TO_BE_DELETED.includes(appointment.state)) return false;
  if (CANCELLATION_PROTECTION_SETUP_STATUS_TO_BE_DELETED.includes(appointment.cancellation_protection?.setup_status))
    return false;
  return true;
};

const handleCRUDAppointments = (state: PPAState, venueId: number, newAppointments: Appointment[] = []): PPAState => {
  if (!newAppointments || newAppointments.length === 0) return state;

  const currentAppointments: Appointment[] = state[venueId]?.items ?? [];

  const mergedAppointments = mergeAndUniqueAppointments(currentAppointments, newAppointments);

  const appointments = mergedAppointments
    .filter(inBookingsPendingProtection)
    .filter(outDeletableAppointments)
    .filter(inBookedAppointments)
    .filter(inBookedInAWeekTimeOrLess);

  return newState({ state, venueId: venueId, appointments });
};

const requestReducer: PPAReducer<FetchPendingProtectionAppointmentsForCurrentWeekRequestAction> = (
  state,
  { venueId }
) => {
  if (!venueId) return state;

  const appointments = state[venueId]?.items ?? [];
  return newState({ state, venueId, appointments: appointments, isFetching: true });
};

const responseReducer: PPAReducer<FetchPendingProtectionAppointmentsForCurrentWeekResponseAction> = (
  state,
  { error, success, venueId, appointments }
) => {
  if (error || !venueId || !success) return state;

  return handleCRUDAppointments(state, venueId, appointments);
};

const legacyAPIResponseAndMQTTReducer: PPAReducer<APIResponseAction> = (state, action) => {
  if (!action.venue_id) return state;
  if (!action.response?.success) return state;
  if (!action.response?.data?.appointments || !Array.isArray(action.response.data.appointments)) return state;

  const venueId = action.venue_id;
  return handleCRUDAppointments(state, venueId, action.response?.data?.appointments);
};

export const pendingProtectionAppointmentsByVenue: PPAReducer<IncomingAction> = (state = {}, action) => {
  if (isPPARequestAction(action)) return requestReducer(state, action);
  if (isPPAResponseAction(action)) return responseReducer(state, action);
  if (isLegacyAPIResponseAction(action)) return legacyAPIResponseAndMQTTReducer(state, action);

  return state;
};
