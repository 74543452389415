import { Brand } from 'models/brand';

import { sanitizeBrandName } from './sanitizeBrandName';

export const enabledBrandsByName = ['aveda', 'la_biosthetique'] as const;

const containsEnabledBrand = (brands: Brand[] = []): boolean =>
  brands.filter((brand) =>
    enabledBrandsByName.includes(sanitizeBrandName(brand.name) as (typeof enabledBrandsByName)[number])
  ).length > 0;

export { containsEnabledBrand };
