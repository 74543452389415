import { Modal, Card, Section, Text, Button } from 'components/core';
import { Component } from 'react';

class NewVersionAvailable extends Component {
  state = {};

  componentDidMount() {
    if (this.props.config.timeoutSeconds) {
      this.setState(() => ({
        seconds: this.props.config.timeoutSeconds,
      }));

      this.interval = setInterval(() => {
        if (this.state.seconds > 1) {
          this.setState(({ seconds }) => ({
            seconds: seconds - 1,
          }));
        } else {
          clearInterval(this.interval);
          this.props.config.onConfirm();
        }
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const {
      closeModal,
      config: { content = '', onConfirm, onClose },
    } = this.props;

    const onCancel = () => {
      onClose();
      closeModal();
    };

    return (
      <Modal onClose={onCancel} centered>
        <Card>
          <Card.Header collapse="bottom">
            <Section justifyContent="center">
              <Text bold color="red" intl="attention" />
            </Section>
          </Card.Header>
          <Card.Section collapse="top" paddingTop={1}>
            {[].concat(content).map((piece, k) => (
              <Section key={k} paddingBottom={0.5}>
                <Text block center color="text1" size={16}>
                  {piece}
                </Text>
              </Section>
            ))}
            {this.state.seconds ? (
              <Section paddingBottom={0.5}>
                <Text
                  block
                  bold
                  center
                  color="text1"
                  size={16}
                  intl="new_version_available.auto_update"
                  intlValues={{ seconds: this.state.seconds }}
                />
              </Section>
            ) : null}
            <Section flex justifyContent="space-between" paddingTop={1.25}>
              <Button onClick={onCancel} unstyled>
                <Text bold color="blue" spacing={1} uppercase intl="new_version_available.remind_me_later" />
              </Button>
              <Button onClick={onConfirm} color="blue" shadow="light">
                <Text bold color="white" spacing={1} uppercase intl="new_version_available.update" />
              </Button>
            </Section>
          </Card.Section>
        </Card>
      </Modal>
    );
  }
}

export default NewVersionAvailable;
