import {
  MULTI_VENUE_SIDEBAR_DISABLE,
  MULTI_VENUE_SIDEBAR_ENABLE,
  MULTI_VENUE_SIDEBAR_TOGGLE,
  MULTI_VENUE_SIDEBAR_HIDE,
  MULTI_VENUE_SIDEBAR_SHOW,
} from 'actionTypes';
import { ActionCreator, ParametricActionCreator } from './types';
import { MultiVenueSidebarSettings } from 'models';

export interface MultiVenueSettingsAction {
  type: typeof MULTI_VENUE_SIDEBAR_DISABLE | typeof MULTI_VENUE_SIDEBAR_ENABLE;
}

export interface MultiVenueSidebarToggleAction {
  type: typeof MULTI_VENUE_SIDEBAR_TOGGLE;
  isOpen: boolean;
}
export interface MultiVenueSidebarToggleVisibilityAction {
  type: typeof MULTI_VENUE_SIDEBAR_HIDE | typeof MULTI_VENUE_SIDEBAR_SHOW;
}

export type MultiVenueSidebarSettingsActionTypes =
  | MultiVenueSettingsAction
  | MultiVenueSidebarToggleAction
  | MultiVenueSidebarToggleVisibilityAction;

const disableMultiVenueSidebarAction = (): MultiVenueSidebarSettingsActionTypes => ({
  type: MULTI_VENUE_SIDEBAR_DISABLE,
});

const enableMultiVenueSidebarAction = (): MultiVenueSidebarSettingsActionTypes => ({
  type: MULTI_VENUE_SIDEBAR_ENABLE,
});

const toggleMultiVenueSidebarAction = (
  isOpen: MultiVenueSidebarSettings['isOpen']
): MultiVenueSidebarSettingsActionTypes => ({
  type: MULTI_VENUE_SIDEBAR_TOGGLE,
  isOpen,
});

const hideMultiVenueSidebarAction = (): MultiVenueSidebarSettingsActionTypes => ({
  type: MULTI_VENUE_SIDEBAR_HIDE,
});

const showMultiVenueSidebarAction = (): MultiVenueSidebarSettingsActionTypes => ({
  type: MULTI_VENUE_SIDEBAR_SHOW,
});

type MultiVenueSidebarActionCreator = ActionCreator<void>;

type ToggleMultiVenueSidebarActionCreator = ParametricActionCreator<boolean, void>;

type MultiVenueSidebarVisibilityActionCreator = ActionCreator<void>;

export const disableMultiVenueSidebar: MultiVenueSidebarActionCreator =
  () =>
  (dispatch): void => {
    dispatch(disableMultiVenueSidebarAction());
  };

export const enableMultiVenueSidebar: MultiVenueSidebarActionCreator =
  () =>
  (dispatch): void => {
    dispatch(enableMultiVenueSidebarAction());
  };

export const toggleMultiVenueSidebar: ToggleMultiVenueSidebarActionCreator =
  (isOpen: boolean) =>
  (dispatch): void => {
    dispatch(toggleMultiVenueSidebarAction(isOpen));
  };

export const hideMultiVenueSidebar: MultiVenueSidebarVisibilityActionCreator =
  () =>
  (dispatch): void => {
    dispatch(hideMultiVenueSidebarAction());
  };

export const showMultiVenueSidebar: MultiVenueSidebarVisibilityActionCreator =
  () =>
  (dispatch): void => {
    dispatch(showMultiVenueSidebarAction());
  };
