import {
  VENUE_CUSTOMER_CART_CREATE,
  VENUE_CUSTOMER_CART_UPDATE,
  VENUE_CUSTOMER_CARTS_LIST_REQUEST,
  VENUE_CUSTOMER_CARTS_LIST_RESPONSE,
  API_POST_RESPONSE,
  API_GET_RESPONSE,
  VENUE_CUSTOMER_CART_ITEM_UPDATE,
  VENUE_CUSTOMER_CART_ITEM_DELETE,
  VENUE_CUSTOMER_CART_APPLY_PROMOTIONS,
  VENUE_CUSTOMER_CART_ADD_LAST_STAFF_MEMBER_BY_CART,
  VENUE_CUSTOMER_CART_FLUSH_LAST_STAFF_MEMBER_BY_CART,
  VENUE_CUSTOMER_CART_REMOVE_LAST_STAFF_MEMBER_BY_CART,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit, isNewerThan } from 'utils/reducersUtils';
import { objectKeys } from 'utils/utilityTypes';
import { ACTIVE_FLAGS } from 'feature-flags/ACTIVE_FLAGS';
import { isFeatureFlagEnabled } from 'feature-flags/unleash';

/** @typedef {import('models').Cart['client_token']} ClientToken */
/** @typedef {{[key: ClientToken]: ClientToken}} CartsByClientToken */
/** @type {(newCarts: CartsByClientToken) => (cartFromState: Cart) => Cart} */
const toNewerCartMapper = (newCarts) => (cartFromState) => {
  if (!(cartFromState.client_token in newCarts)) return cartFromState; // no cart from backend representation (maybe local or not sync yet)

  const cartFromBE = newCarts[cartFromState.client_token];
  if (isNewerThan({ newer: cartFromBE, older: cartFromState })) return cartFromBE;

  return cartFromState;
};

/** @type {(newCarts: CartsByClientToken) => (cartFromState: Cart) => Cart} */
const toRemoteCartMapper = (cartsFromBEByClientToken) => (cartFromState) => {
  const isLocal = cartFromState.__isLocal;
  const isPresentInBEResponse = cartFromState.client_token in cartsFromBEByClientToken;

  if (isLocal && isPresentInBEResponse) return cartsFromBEByClientToken[cartFromState.client_token];

  return cartFromState;
};

const customerCarts = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_CUSTOMER_CART_CREATE:
      const { customer_id, client_token } = action;

      return {
        ...state,
        items: [
          ...(state.items || []),
          {
            __isCart: true,
            __isLocal: true,
            created_at: new Date().toISOString(),
            customer_id,
            client_token,
            id: 'local-' + client_token,
          },
        ],
      };

    case VENUE_CUSTOMER_CART_UPDATE:
      return {
        ...state,
        items: [
          ...(state.items || []).map((item) => {
            if (item.id !== action.customer_cart_id) {
              return item;
            }

            return {
              ...item,
              ...action.changes,
            };
          }),
        ],
      };

    case VENUE_CUSTOMER_CART_ITEM_UPDATE:
      return {
        ...state,
        items: [
          ...(state.items || []).map((item) => {
            if (item.id !== action.customer_cart_id) {
              return item;
            }

            return {
              ...item,
              customer_cart_items: (item.customer_cart_items || []).map((customer_cart_item) => {
                if (customer_cart_item.id !== action.customer_cart_item.id) {
                  return customer_cart_item;
                }

                return {
                  ...customer_cart_item,
                  ...action.changes,
                };
              }),
            };
          }),
        ],
      };

    case VENUE_CUSTOMER_CART_ITEM_DELETE:
      return {
        ...state,
        items: [
          ...(state.items || []).map((item) => {
            if (item.id !== action.customer_cart_id) {
              return item;
            }

            return {
              ...item,
              customer_cart_items: (item.customer_cart_items || []).filter(
                (customer_cart_item) => customer_cart_item.id !== action.customer_cart_item.id
              ),
            };
          }),
        ],
      };

    case VENUE_CUSTOMER_CARTS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_CUSTOMER_CARTS_LIST_RESPONSE:
      if (!action.customer_carts) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.customer_carts || []), {
          overrideDuplicateIds: 1,
        }),
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      return customerCartAPIResponseSubReducer(state, action);

    /**
     * SIDE-EFFECTS
     */
    case VENUE_CUSTOMER_CART_APPLY_PROMOTIONS:
      return {
        ...state,
        items: state.items.map((customer_cart) => ({
          ...customer_cart,
          card_amount: '0.00',
          cash_amount: '0.00',
          check_amount: '0.00',
          transfer_amount: '0.00',
          debt_amount: '0.00',
          discount_type: '',
          card_type: null,
          discount_percent_amount: 0,
          discount_absolute_amount: '0.00',
          discount_absolute_amount_cents: 0,
          customer_cart_items: (customer_cart.customer_cart_items || []).map((customer_cart_item) => {
            const affected_line_item = action.line_items.find(({ id }) => id === customer_cart_item.id);
            if (affected_line_item && affected_line_item.using_marketing_promotion_id) {
              return {
                ...customer_cart_item,
                discount_type: '',
                discount_percent_amount: 0,
                discount_absolute_amount: '0.00',
              };
            }

            return customer_cart_item;
          }),
        })),
      };

    default:
      return state;
  }
};

/** @type {import('redux').Reducer<import('./types').State['customerCartsByVenue'], Action>} */
export const customerCartsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_CUSTOMER_CART_CREATE:
      if (!action.customer_id || !action.venue_id || !action.client_token) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: customerCarts(state[action.venue_id], action),
      };

    case VENUE_CUSTOMER_CART_UPDATE:
    case VENUE_CUSTOMER_CART_ITEM_UPDATE:
      if (!action.customer_cart_id || !action.venue_id || !action.changes) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: customerCarts(state[action.venue_id], action),
      };

    case VENUE_CUSTOMER_CART_ITEM_DELETE:
      if (!action.customer_cart_id || !action.customer_cart_item || !action.venue_id) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: customerCarts(state[action.venue_id], action),
      };

    case VENUE_CUSTOMER_CARTS_LIST_REQUEST:
    case VENUE_CUSTOMER_CARTS_LIST_RESPONSE:
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      /**
       * if path === `/venues/${action.venue_id}/customer_carts.json`, is handled by previuos case VENUE_CUSTOMER_CARTS_LIST_RESPONSE
       */
      if (action.path === `/venues/${action.venue_id}/customer_carts.json` && action.type === API_GET_RESPONSE) {
        return state;
      }

      refState = customerCarts(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    case VENUE_CUSTOMER_CART_FLUSH_LAST_STAFF_MEMBER_BY_CART:
      return {
        ...state,
        [action.venue_id]: {
          ...state[action.venue_id],
          last_staff_member_by_cart: undefined,
        },
      };

    case VENUE_CUSTOMER_CART_REMOVE_LAST_STAFF_MEMBER_BY_CART:
      return {
        ...state,
        [action.venue_id]: {
          ...state[action.venue_id],
          last_staff_member_by_cart: {
            ...state[action.venue_id].last_staff_member_by_cart,
            [action.customer_cart_id]: undefined,
          },
        },
      };

    case VENUE_CUSTOMER_CART_ADD_LAST_STAFF_MEMBER_BY_CART:
      return {
        ...state,
        [action.venue_id]: {
          ...state[action.venue_id],
          last_staff_member_by_cart: {
            ...state[action.venue_id].last_staff_member_by_cart,
            [action.customer_cart_id]: {
              id: action.staff_member_id,
              timer: action.timer,
            },
          },
        },
      };

    /**
     * SIDE-EFFECTS
     */
    case VENUE_CUSTOMER_CART_APPLY_PROMOTIONS:
      if (!action.venue_id) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: customerCarts(state[action.venue_id], action),
      };

    default:
      return state;
  }
};

const customerCartAPIResponseSubReducer = (state, action) => {
  if (!isFeatureFlagEnabled(ACTIVE_FLAGS.TWPRO_8164)) return legacyCustomerCartAPIResponseSubReducer(state, action);

  if (
    !action.response ||
    !action.response.data ||
    (!action.response.data.customer_cart && !action.response.data.customer_carts)
  ) {
    return state;
  }

  const cartsFromBE = []
    .concat(action.response.data.customer_cart || [])
    .concat(action.response.data.customer_carts || []);
  if (!cartsFromBE.length) return state;

  const cartsFromState = state.items || [];
  const toObjectIndexedByClientToken = (acc, cart) => ({ ...acc, [cart.client_token]: cart });

  const cartsFromStateByClientToken = cartsFromState.reduce(toObjectIndexedByClientToken, {});
  const cartsFromBEByClientToken = cartsFromBE.reduce(toObjectIndexedByClientToken, {});
  const clientTokensInState = objectKeys(cartsFromStateByClientToken);
  const clientTokensInBEResponse = objectKeys(cartsFromBEByClientToken);
  const toNewerCart = toNewerCartMapper(cartsFromBEByClientToken);
  const toRemoteCart = toRemoteCartMapper(cartsFromBEByClientToken);

  const cartsInBENotInState = clientTokensInBEResponse
    .filter((tokenInBEResponse) => !clientTokensInState.includes(tokenInBEResponse))
    .map((tokenNotInState) => cartsFromBEByClientToken[tokenNotInState]);

  const newItems = cartsFromState //
    .map(toRemoteCart)
    .map(toNewerCart)
    .concat(cartsInBENotInState);

  return {
    ...state,
    ...reducerValuesOnResponse(newItems, {
      overrideDuplicateIds: 1,
      skipUpdate: 1,
    }),
  };
};

const legacyCustomerCartAPIResponseSubReducer = (state, action) => {
  if (
    !action.response ||
    !action.response.data ||
    (!action.response.data.customer_cart && !action.response.data.customer_carts)
  ) {
    return state;
  }

  if (![].concat(action.response.data.customer_cart || []).concat(action.response.data.customer_carts || []).length) {
    return state;
  }

  return {
    ...state,
    ...reducerValuesOnResponse(
      []
        /**
         * Remove from store state.items that have a representation on BE
         */
        .concat(
          (state.items || []).filter((cart) => {
            if (
              (action.response.data.customer_carts || [])
                .concat(action.response.data.customer_cart || [])
                .find(({ client_token }) => cart.__isLocal && client_token === cart.client_token)
            ) {
              /**
               * Replace local carts with remote
               * this client_token is present on BE, remove from local
               */
              return false;
            }
            return true;
          })
        )
        /**
         * Dont'replace state.items with older or same age items coming from response
         */
        .concat(
          []
            .concat(action.response.data.customer_cart || [])
            .concat(action.response.data.customer_carts || [])
            .filter((cart) => {
              if ((state.items || []).find(({ id, updated_at }) => cart.id === id && cart.updated_at <= updated_at)) {
                return false;
              }
              return true;
            })
        ),
      {
        overrideDuplicateIds: 1,
        skipUpdate: 1,
      }
    ),
  };
};
