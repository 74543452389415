import { css, CSSInterpolation, SerializedStyles } from 'styles';
import { BREAKPOINTS } from './breakpoints';

export const MEDIA = {
  /**
   * @deprecated
   */
  smallMobile: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (max-width: ${BREAKPOINTS.smallMobile}px) {
      ${css(template, ...args)};
    }
  `,
  smartphone: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (max-width: ${BREAKPOINTS.smartphone}px) {
      ${css(template, ...args)};
    }
  `,
  /**
   * @deprecated
   */
  breakpointM: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (min-width: ${BREAKPOINTS.breakpointM}px) {
      ${css(template, ...args)};
    }
  `,
  /**
   * @deprecated
   */
  mobileOrSmaller: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (max-width: ${BREAKPOINTS.mobileOrSmaller}px) {
      ${css(template, ...args)};
    }
  `,
  tablet: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (min-width: ${BREAKPOINTS.tablet}px) {
      ${css(template, ...args)};
    }
  `,
  /**
   * @deprecated
   */
  tabletOrSmaller: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (max-width: ${BREAKPOINTS.tabletOrSmaller}px) {
      ${css(template, ...args)};
    }
  `,
  desktop: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (min-width: ${BREAKPOINTS.desktop}px) {
      ${css(template, ...args)};
    }
  `,
  /**
   * @deprecated
   */
  desktopOrSmaller: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (max-width: ${BREAKPOINTS.desktopOrSmaller}px) {
      ${css(template, ...args)};
    }
  `,
  /**
   * @deprecated
   */
  smallDesktopOrSmaller: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (max-width: ${BREAKPOINTS.desktop}px) {
      ${css(template, ...args)};
    }
  `,
  largeDesktop: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (min-width: ${BREAKPOINTS.largeDesktop}px) {
      ${css(template, ...args)};
    }
  `,
  veryLargeDesktop: (template: TemplateStringsArray, ...args: Array<CSSInterpolation>): SerializedStyles => css`
    @media (min-width: ${BREAKPOINTS.veryLargeDesktop}px) {
      ${css(template, ...args)};
    }
  `,
} as const;

export type Media = keyof typeof MEDIA;
