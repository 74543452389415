import { useMemo } from 'react';
import Loadable from '@loadable/component';
import ENV from 'env';
import { LogoWrapper } from './CompanyLogo.theme';

const CompanyLogo = (): JSX.Element => {
  const logoPath = ENV.UALA_FAVICON_PATH || 'treatwell-connect-pro';

  const Logo = useMemo(
    () => Loadable(() => import(`components/logo/${logoPath}`), { fallback: () => null }),
    [logoPath]
  );

  return (
    <LogoWrapper>
      <Logo />
    </LogoWrapper>
  );
};

export default CompanyLogo;
