import {
  DELETE,
  EDIT,
  ADD,
  SEARCH,
  UNLOAD,
  SHOW,
  SUBCATEGORIES,
  CATEGORIES,
  LIST,
  LOOKUP,
  CORRECT_STOCK,
  TRANSACTIONS,
  TRANSACTION_HISTORY,
} from './VENUE_PRODUCTS';

import { SEARCH as PRODUCT_SEARCH, SHOW as PRODUCT_SHOW } from './PRODUCTS';
import { PATCH_TEST_STATUS, SIGN } from './PATCH_TESTS';
import { EXPORT, MISS_DETAILS } from './CUSTOMERS';
import { FISCAL_TRANSACTION_REPORT } from './MANAGER';
import { CANCELLATION_PROTECTION_AVAILABILITY, APPOINTMENTS } from './APPOINTMENTS';
import {
  CANCELLATION_PROTECTION_INFO_SMS,
  CANCELLATION_PROTECTION_CONFIRM,
  CANCELLATION_PROTECTION_DELETE,
} from './CANCELLATION_PROTECTIONS';

import { SEARCH as MANUFACTURERS_SEARCH } from './MANUFACTURERS';
import { ATCUD_CREATE, ATCUD_SHOW, ATCUD_UPDATE } from './ATCUDS';
import {
  TREATMENTS,
  SEARCH as VENUE_TREATMENTS_SEARCH,
  LOOKUP_DELETED as LOOKUP_DELETED_VENUE_TREATMENTS,
} from './VENUE_TREATMENTS';
import { LOOKUP_DELETED as LOOKUP_DELETED_STAFF_MEMBER_TREATMENT } from './STAFF_MEMBERS_TREATMENTS';
import { MOVEMENTS } from './VENUE_PRODUCTS/MOVEMENTS';
import { BILLING_BALANCE_SHOW } from './BILLING_BALANCE/SHOW';
import { SEARCH_UNLOADABLES } from './VENUE_PRODUCTS/SEARCH_UNLOADABLES';
import { KYC_VERIFICATION_CHECK } from './KYC_VERIFICATION/CHECK';
import { KYC_VERIFICATION_STRIPE_ONBOARDING } from './KYC_VERIFICATION/STRIPE_ONBOARDING';

export interface V<Variables> {
  readonly variables: Variables;
}

/**
 * @description all the APIs in a declarative way
 *
 * @example
 * ```ts
 *
 * const [queryVenueProductsSearch, { loading, data }] = useRemote(API.VENUE_PRODUCTS._SEARCH);
 * const list = data?.data?.venue_products ?? [];
 *
 * const onSearch = (x: { query: string }): Promise<void> | void => {
 *  queryVenueProductsSearch({ variables: { text: x.query.trim(), page: '1' } }, {
 *    onCompleted: res => console.log(res.data.venue_products),
 *    onError: console.error,
 *  });
 * };
 *
 *```
 */
export const API = {
  /**
   * @type API Domain
   * @see https://api-docs.uala.dev/#/Api::V1::VenueProducts
   * @description All the products related to a certain venue
   */
  VENUE_PRODUCTS: {
    /**
     * @type POST
     * @see https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AVenueProduct/post_api_v1_venues__venue_id__venue_products
     * @description Add new product
     */
    _ADD: ADD,
    /**
     * @type GET
     * @see https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AVenueProduct/get_api_v1_venues__venue_id__venue_products_search
     * @description FullTextSearch to retrieve venue_products
     */
    _SEARCH: SEARCH,
    /**
     * @type GET
     * @see
     * @description retrieve all the unloadable products
     */
    _SEARCH_UNLOADABLES: SEARCH_UNLOADABLES,
    /**
     * @type PUT
     * @see https://api-docs.uala.dev/#/Api::V1::VenueProduct/put_api_v1_venues__venue_id__venue_products__id__unload
     * @description Discharge a venue product by id
     */
    _UNLOAD: UNLOAD,
    /**
     * @type PUT
     * @see https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AVenueProduct/put_api_v1_venues__venue_id__venue_products__id_
     * @description update venue product details by id
     */
    _EDIT: EDIT,
    /**
     * @type GET
     * @see https://api-docs.uala.dev/#/Api::V1::VenueProduct/get_api_v1_venues__venue_id__venue_products__id_
     * @description Show venue product details by id
     */
    _SHOW: SHOW,
    /**
     * @type GET
     * @see  TODO
     * @description Show venue product subcategories
     */
    _MOVEMENTS: MOVEMENTS,
    /**
     * @type GET
     * @see  TODO
     * @description Show venue product subcategories
     */
    _SUBCATEGORIES: SUBCATEGORIES,
    /**
     * @type GET
     * @see  TODO
     * @description Show venue product categories
     */
    _CATEGORIES: CATEGORIES,
    /**
     * @type GET
     * @see  https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AVenueProduct/get_api_v1_venues__venue_id__venue_products_list
     * @description Show venue product list
     */
    _LIST: LIST,
    /**
     * @type DELETE
     * @see https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AVenueProduct/delete_api_v1_venues__venue_id__venue_products__id_
     * @description Delete venue product by id
     */
    _DELETE: DELETE,
    /**
     * @type GET
     * @see https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AVenueProduct/get_api_v1_venues__venue_id__venue_products_lookup
     * @description retrieve the prioritized venue product (last updated) scanned barcode
     */
    _LOOKUP: LOOKUP,
    /**
     * @type PUT
     * @see
     * @description
     */
    _CORRECT_STOCK: CORRECT_STOCK,
    /**
     * @type POST
     * @see
     * @description
     */
    _TRANSACTIONS: TRANSACTIONS,
    /**
     * @type GET
     * @see https://api-docs.uala.dev/
     * @description Last venue products transaction history
     */
    _TRANSACTION_HISTORY: TRANSACTION_HISTORY,
  },
  /**
   * @type API Domain
   * @see https://api-docs.uala.dev/#/Api::V1::PatchTest
   * @description Patch test related operations
   */
  PATCH_TESTS: {
    /**
     * @type PUT
     * @see https://api-docs.uala.dev/#/Api::V1::PatchTest/put_api_v1_venues__venue_id__patch_tests__id__sign
     * @description sign a patch test
     */
    _SIGN: SIGN,
    /**
     * @type GET
     * @see https://api-docs.uala.dev/#/Api::V1::PatchTest/get_api_v1_venues__venue_id__patch_test_status
     * @description given a customer_id and associated venue_treatment_ids return patch test status
     *
     */
    _PATCH_TEST_STATUS: PATCH_TEST_STATUS,
  },
  CUSTOMERS: {
    /**
     * @type GET
     * @see https://api-docs.uala.dev/#/Api::V1::Customers/get_api_v1_venues__venue_id__customers__id__miss_details
     * @description given a customer_id and a date returns details of missed appointments
     */
    _MISS_DETAILS: MISS_DETAILS,
    _EXPORT: EXPORT,
  },
  MANAGER: {
    /**
     * @type POST
     * @see https://api-docs.uala.dev/#/Api::V1::Manager::FiscalTransactionReport/post_api_v1_manager_venues__venue_id__fiscal_transactions_export
     * @description trigger a job which sends the fiscal transaction report by email
     */
    _FISCAL_TRANSACTION_REPORT: FISCAL_TRANSACTION_REPORT,
  },
  VENUE_TREATMENTS: {
    /**
     * @type GET
     * @see  TODO
     * @description TODO
     */
    _SEARCH: VENUE_TREATMENTS_SEARCH,
    /**
     * @type GET
     * @see https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3ATreatment/get_api_v1_treatments
     * @description retrieve all listing treatments
     */
    _TREATMENTS: TREATMENTS,
    _LOOKUP_DELETED: LOOKUP_DELETED_VENUE_TREATMENTS,
  },
  APPOINTMENTS: {
    /**
     * @type GET
     * @see https://api-docs.uala.dev/#/Api::V1::Appointments/get_api_v1_venues__venue_id__appointments__appointment_id__cancellation_protection_availability
     * @description given an appointment_id and a customer_id returns the availability which determines if the cancellation protection is configurable, not configurable or already configured for the given appointment
     */
    _CANCELLATION_PROTECTION_AVAILABILITY: CANCELLATION_PROTECTION_AVAILABILITY,
    _APPOINTMENTS: APPOINTMENTS,
  },
  CANCELLATION_PROTECTIONS: {
    /**
     * @type GET
     * @see
     * @description given a booking_token and a secret_token returns the info about venue and appointment needed by cancellation_protection_sms landing page
     */
    _INFO_SMS: CANCELLATION_PROTECTION_INFO_SMS,
    /**
     * @type POST
     * @see
     * @description given a booking_token and a confirm flag `setup_intent_confirm` allow to confirm an appointment with cancellation protection from a landing page
     */
    _CONFIRM: CANCELLATION_PROTECTION_CONFIRM,
    /**
     * @type DELETE
     * @see
     * @description given a cancellation_protection id it deletes the cancellation protection
     */
    _DELETE: CANCELLATION_PROTECTION_DELETE,
  },
  PRODUCTS: {
    /**
     * @type GET
     * @see
     * @description FullTextSearch to retrieve products
     */
    _SEARCH: PRODUCT_SEARCH,
    /**
     * @type GET
     * @see https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AProduct/get_api_v1_venues__venue_id__products__id_
     * @description product details by id
     */
    _SHOW: PRODUCT_SHOW,
  },
  MANUFACTURERS: {
    /**
     * @type GET
     * @see  https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AManufacturer/get_api_v1_venues__venue_id__manufacturers
     * @description Show manufacturers
     */
    _SEARCH: MANUFACTURERS_SEARCH,
  },
  ATCUDS: {
    /**
     * @type GET
     * @see  https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AAtcuds/get_api_v1_venues__venue_id__atcuds
     * @description Show atcud for a given venue
     */
    _SHOW: ATCUD_SHOW,

    /**
     * @type PUT
     * @see  https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AAtcuds/get_api_v1_venues__venue_id__atcuds
     * @description update the atcuds atcud for the current venue
     */
    _UPDATE: ATCUD_UPDATE,

    /**
     * @type POST
     * @see  https://api-docs.uala.dev/#/Api%3A%3AV1%3A%3AAtcuds/get_api_v1_venues__venue_id__atcuds
     * @description create the atcuds atcud for the current venue
     */
    _CREATE: ATCUD_CREATE,
  },
  BILLING_BALANCE: {
    _SHOW: BILLING_BALANCE_SHOW,
  },
  STAFF_MEMBERS_TREATMENTS: {
    _LOOKUP_DELETED: LOOKUP_DELETED_STAFF_MEMBER_TREATMENT,
  },
  KYC_VERIFICATION: {
    _CHECK: KYC_VERIFICATION_CHECK,
    _STRIPE_ONBOARDING: KYC_VERIFICATION_STRIPE_ONBOARDING,
  },
} as const;
