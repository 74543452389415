/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';
import { VenueProductLookupResponse, BarcodeLookupVariables } from 'API/types';

export const LOOKUP =
  ({ variables }: V<BarcodeLookupVariables>) =>
  (): Promise<VenueProductLookupResponse> =>
    Http()
      .get<VenueProductLookupResponse, BarcodeLookupVariables>(ENDPOINT.VENUE_PRODUCTS._LOOKUP())({
        searchParams: variables,
      })
      .then(maybeResponseToJSON);
