export const DAILY_VIEW_EVENT_TYPE = 'agendaNext:changeView:daily';
export const WEEKLY_VIEW_EVENT_TYPE = 'agendaNext:changeView:weekly';
export const VIEW_ALL_COLLABORATORS = 'agendaNext:changeView:allCollaborators';
export const VIEW_COLLABORATORS_AT_WORK = 'agendaNext:changeView:workCollaborators';
export const agendaNextCustomEvents = [
  DAILY_VIEW_EVENT_TYPE,
  WEEKLY_VIEW_EVENT_TYPE,
  VIEW_ALL_COLLABORATORS,
  VIEW_COLLABORATORS_AT_WORK,
] as const;
export type AgendaNextCustomEvents = (typeof agendaNextCustomEvents)[number];
