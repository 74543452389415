import { FunctionComponent, useState, useEffect } from 'react';
import getBaseFromPathName from 'utils/upgrade/getBaseFromPathName';
import AlertModal from 'components/lib/AlertModal';
import { Section, Text } from 'components/core';

const AutoRefreshModal: FunctionComponent = () => {
  const [countdown, setCountdown] = useState(3);

  const refresh = (): void => {
    const basePath = getBaseFromPathName(window.location.pathname);

    window.location.href = basePath;
  };

  useEffect((): void | (() => void | undefined) => {
    if (countdown) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);

      return (): void => clearTimeout(timer);
    } else {
      refresh();
    }
  }, [countdown]);

  return (
    <AlertModal hideOnClose onConfirm={refresh}>
      <Section center>
        <Text intl="refresh.needed" size={14}></Text>
        <Text size={14}> ({countdown})</Text>
      </Section>
    </AlertModal>
  );
};

export default AutoRefreshModal;
