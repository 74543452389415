import PropTypes from 'prop-types';
import FieldTextArea from './theme';
import { Component as ReactComponent, createRef } from 'react';

class Component extends ReactComponent {
  state = {};

  constructor(props) {
    super(props);
    this.textarea = createRef();
  }

  componentDidMount() {
    if (this.textarea.current) {
      if (this.props.autosize) {
        this.textarea.current.addEventListener('input', this.autosize, false);
        this.__autosize = setTimeout(this.autosize, 20);
      }
      if (this.props.autoFocus) {
        this.textarea.current.focus();
      }
    }
  }

  componentWillUnmount() {
    if (this.props.autosize && this.textarea.current) {
      this.textarea.current.removeEventListener('input', this.autosize, false);
      clearTimeout(this.__autosize);
    }
  }

  handleChange = (event) => {
    if (this.props.onChange) {
      this.props.onChange(event.target.value);
    }
  };

  resize = () => {
    const ta = this.textarea.current;

    ta.style.height = '';
    ta.style.height = ta.scrollHeight + 'px';
  };

  autosize = () => {
    if (!this.textarea.current) {
      return;
    }
    this.resize();

    const ta = this.textarea.current;
    let actualHeight = ta.offsetHeight;
    const styleHeight = Math.round(parseFloat(ta.style.height));

    if (actualHeight < styleHeight) {
      if (ta.style.overflowY === 'hidden') {
        ta.style.overflowY = 'scroll';
        this.resize();
        actualHeight = ta.offsetHeight;
      }
    } else {
      if (ta.style.overflowY !== 'hidden') {
        ta.style.overflowY = 'hidden';
        this.resize();
        actualHeight = ta.offsetHeight;
      }
    }
  };

  render() {
    const { rows, placeholder, maxHeight } = this.props;

    return (
      <FieldTextArea className="FieldTextArea" {...this.props} onChange={null}>
        <textarea
          ref={this.textarea}
          rows={rows}
          placeholder={placeholder || ''}
          value={this.props.value}
          onChange={this.handleChange}
          style={{ maxHeight }}
        />
      </FieldTextArea>
    );
  }
}

Component.displayName = 'FieldTextArea';

Component.defaultProps = {
  autoFocus: false,
  autosize: false,
  disabled: false,
  size: '',
  maxHeight: 'none',
};

Component.propTypes = {
  autoFocus: PropTypes.bool,
  autosize: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  rows: PropTypes.number,
  maxHeight: PropTypes.string,
  children: PropTypes.node,
};

export default Component;
