import { ESTIMATE_CUSTOMERS_LOADED, ESTIMATE_CUSTOMERS_REQUEST, ESTIMATE_CUSTOMERS_RESPONSE } from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnInit, reducerValuesOnResponse } from 'utils/reducersUtils';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const estimateCustomers = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case ESTIMATE_CUSTOMERS_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case ESTIMATE_CUSTOMERS_RESPONSE:
      if (!action.estimate_customers) {
        return state;
      }
      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.estimate_customers || []), {
          overrideDuplicateIds: 1,
        }),
        totalItemsCount: action.estimate_customers_count,
      };

    default:
      return state;
  }
};

export const estimateCustomersByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case ESTIMATE_CUSTOMERS_REQUEST:
    case ESTIMATE_CUSTOMERS_RESPONSE:
      if (!action.venue_id) {
        return state;
      }

      if (action.error) {
        return {
          ...state,
          [action.venue_id]: {
            ...state[action.venue_id],
            isFetching: false,
          },
        };
      }

      refState = estimateCustomers(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    case ESTIMATE_CUSTOMERS_LOADED:
      return {
        ...state,
        [action.venue_id]: {
          ...state[action.venue_id],
          isFetching: false,
        },
      };

    default:
      return state;
  }
};
