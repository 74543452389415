import IntlProvider, { intl } from './IntlProvider';
import { LocaleConsumer, AvailableLocales as Locales, DEFAULT_LOCALE } from './LocaleProvider';
import { useIntlContext } from './IntlContext';

export * from './IntlProvider.data';
export * from './IntlProvider.utils';
export { LocaleConsumer, useIntlContext, intl, DEFAULT_LOCALE };
export type AvailableLocales = Locales;

export default IntlProvider;
