import { Brand } from 'models/brand';
import { sanitizeBrandName } from './sanitizeBrandName';

const getActiveBrandName = (brands: Brand[] = []): string | undefined => {
  const enableBrand = brands[0];

  return sanitizeBrandName(enableBrand?.name);
};

export { getActiveBrandName };
