export enum ORDER_STATE {
  OVERDUE = 'overdue',
  DELIVERED = 'delivered',
  BILLED = 'billed',
  PENDING = 'pending',
  DRAFT = 'draft',
}

export interface OrderDetail {
  buying_price?: number | null;
  created_at?: string | null;
  deleted_at?: string | null;
  delivered_count?: number | null;
  id: number;
  ordered_count: number;
  updated_at?: string | null;
  vat_code?: string | null;
  vat_percentage?: number | null;
  product: {
    barcode_value?: string | null;
    id: number;
    item_number?: string | null;
    name?: string | null;
    original_name?: string | null;
  };
}

export interface Deadline {
  amount: number;
  created_at?: string | null;
  due_date?: string | null;
  id: number;
  paid_at?: string | null;
  payment_method?: string | null;
  payment_method_id?: number | null;
  state: ORDER_STATE;
  updated_at?: string | null;
}

export interface Order {
  tracking_url?: string;
  twstore: boolean;
  billed_at?: string | null;
  created_at?: string | null;
  deadline_on_end_of_month?: boolean | null;
  deadline_typology_id?: number | null;
  deadlines: Deadline[];
  deleted_at?: string | null;
  delivered_at?: string | null;
  details: OrderDetail[];
  draft?: boolean | null;
  external_id?: number | null;
  id: number;
  incremental_id?: number | null;
  invoice_date?: string | null;
  invoice_ref?: number | null;
  notes?: string | null;
  sent_at?: string | null;
  shipping_amount?: number | null;
  discount_amount?: number | null;
  state: ORDER_STATE;
  supplier_id: number;
  total_amount: number;
  updated_at?: string | null;
  vat_amounts?: string[] | null;
  saving?: boolean | null; // CHECKME
}
