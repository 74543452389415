import { styled, css } from 'styles';
import { withTheme, Scrollable } from 'components/core';

const item_height = 37;

export const Wrapper = withTheme(styled('div')`
  flex: 1;
  align-items: center;
  display: flex;

  > .Heading {
    flex: 1 1 auto;
    height: 100%;
    padding: 10px 0;
  }
`);

export const Dropdown = withTheme(
  styled('div')(
    ({
      top,
      open,
      resultsCount,
      hideNoResults,
      noBorder,
      theme: { sizes, colors, utils, boxShadow },
      noMargin = false,
    }) => css`
      position: absolute;
      z-index: 2;
      left: 0;
      right: 0;
      margin: ${noMargin ? '0' : `-${sizes.borderRadiusMedium}px`} -1px 0;
      box-sizing: border-box;
      border-radius: 0 0 ${sizes.borderRadiusMedium}px ${sizes.borderRadiusMedium}px;
      background: ${colors.bgWhite};
      box-shadow: ${boxShadow.block};
      transition: ${utils.transition};

      ${hideNoResults && resultsCount === 0 ? `display: none;` : ``};

      ${open
        ? css`
            opacity: 1;
            min-height: 80px;
            height: ${Math.min((resultsCount || 1) * item_height + 3, 200)}px;
          `
        : css`
            opacity: 0;
            height: 0;
          `};

      ${top
        ? css`
            bottom: 100%;
          `
        : css`
            top: 100%;
          `};

      ${noBorder && top
        ? css`
            bottom: calc(100% + 2px);
          `
        : css`
            top: calc(100% + 2px);
          `};

      &:before {
        content: '';
        display: block;
        position: relative;
        width: 100%;
        ${noMargin ? undefined : `height: ${sizes.borderRadiusMedium}px;`}
        border-left: 1px solid ${colors.blockBorder};
        border-right: 1px solid ${colors.blockBorder};
      }

      > div {
        position: relative;
        background: ${colors.bgWhite};
        width: 100%;
        height: 100%;
        border-width: 1px;
        border-style: solid;
        border-color: ${noBorder ? 'transparent' : colors.blockBorder};
        background-color: ${colors.white};
        border-bottom-left-radius: ${sizes.borderRadiusMedium}px;
        border-bottom-right-radius: ${sizes.borderRadiusMedium}px;
        overflow: hidden;

        > .Heading {
          width: 100%;
        }
      }
    `
  )
);

Dropdown.defaultProps = {
  top: false,
  resultsCount: 0,
};

export const Action = withTheme(
  styled('div')(
    ({ theme: { colors, sizes, media }, onClick }) => css`
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      width: 42px;
      height: 100%;
      background-color: ${colors.bgWhite};
      border-radius: ${sizes.borderRadiusMedium * 4}px;
      ${onClick && { cursor: 'pointer' }};

      ${media.tablet`width: 52px;`}

      .Icon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
      }

      &.ModelDropdownAction__clear-on-focus {
        position: relative;
        box-sizing: content-box;
        width: 16px;
        height: 16px;
        padding: 10px;

        .Icon {
          position: relative;
        }
      }
    `
  )
);

export const ActionClear = withTheme(
  styled(Action)(css`
    right: 40px;
  `)
);

export const Scroller = styled(Scrollable)`
  box-sizing: border-box;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
`;

export const Item = withTheme(
  styled('div')(
    ({ theme: { colors, utils, media }, hovered = false }) => css`
      position: relative;
      display: flex;
      justify-content: space-between;
      padding: 8px 10px;
      line-height: ${item_height - 8 * 2}px;
      cursor: pointer;
      background-color: ${hovered ? colors.bgGray : colors.bgWhite};
      transition: ${utils.transition};

      ${media.tablet`
        padding: 8px 20px;
      `}

      &:after {
        display: none;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: calc(100% - 20px);
        margin: 0 10px;
        height: 1px;
        background-color: ${colors.inputBorder};

        ${media.tablet`
          width: calc(100% - 40px);
          margin: 0 20px;
        `}
      }

      &:last-child:after {
        display: none;
      }
    `
  )
);

export default Wrapper;
