import ENV from 'env';
import { stringify } from 'qs';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import 'whatwg-fetch';

import { ActionDispatch, ParametricActionCreator, ParametricDispatch } from '../types';

import { modalOpen } from 'actions/modals';
import { logoutRedirect } from 'actions/sessions';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import {
  ApiAction,
  ApiDataSetAction,
  ApiDataSetPayload,
  ApiGet,
  ApiPost,
  ApiRequestPayload,
  ErrorResponse,
  HeadersEntries,
  OnError,
  OnResponse,
  REDUX_API_ACTION_TYPES,
  Response,
  UniqueID,
} from './data_providers_api.types';
import { addAuthorizationHeader } from './headers';
import {
  addToRequestsPool,
  registerResponseInRequestsPool,
  removeFromRequestsPool,
  requestsPoolContains,
} from './requestPool';

export * from './data_providers_api.types'; // Backwards Compatibility

const apiDataSetAction = ({ venue_id, object_type, object }: ApiDataSetAction): ApiAction & ApiDataSetAction => ({
  type: REDUX_API_ACTION_TYPES.DATA_SET,
  venue_id,
  object_type,
  object,
});

export const API_BASE = (): string => {
  if (ENV.UALA_ALLOW_PARAMS_CUSTOMIZATION && window.localStorage) {
    if (
      window.localStorage.getItem('UALA_OVERRIDE_PARAMS') === 'true' &&
      window.localStorage.getItem('UALA_API_ENDPOINT')
    ) {
      return window.localStorage.getItem('UALA_API_ENDPOINT') || '';
    }
  }

  return ENV.UALA_API_ENDPOINT
    ? ENV.UALA_API_ENDPOINT
    : 'https://' +
        window.location.hostname.replace(/(.*)\.(.*)\.(.*)$/gi, `${ENV.UALA_API_SUBDOMAIN}.$2.$3`) +
        '/api/v1';
};

export const APP_LOCALE = (): string => window.APP_LOCALE || window.navigator.userLanguage || window.navigator.language;

export const apiDataSet: ParametricActionCreator<ApiDataSetPayload, void> = ({ object_type, object }) => {
  return (dispatch, getState): void => {
    const venue_id = getCurrentVenueId(getState());
    if (!venue_id || !object_type || !object) {
      return;
    }
    dispatch(apiDataSetAction({ venue_id, object_type, object }));
  };
};

export const apiGetRequestAction = ({
  path,
  data,
  venue_id,
  auth,
  uniqueId,
}: ApiRequestPayload): ApiAction & ApiRequestPayload => ({
  type: REDUX_API_ACTION_TYPES.GET_REQUEST,
  path,
  data,
  venue_id,
  auth,
  uniqueId,
});

export const apiGetResponseAction = ({
  path,
  data,
  venue_id,
  auth,
  uniqueId,
  response,
}: ApiRequestPayload): ApiAction & ApiRequestPayload => ({
  type: REDUX_API_ACTION_TYPES.GET_RESPONSE,
  path,
  data,
  venue_id,
  auth,
  uniqueId,
  response,
});

export enum API_ERROR {
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  CONFLICT = 'CONFLICT',
  UNKNOWN = 'UNKNOWN',
}

export type ApiGetActionCreator = ParametricActionCreator<ApiGet, void>;

export type ApiGetDispatch = ParametricDispatch<ApiGet, void>;

export const apiGet: ApiGetActionCreator = ({
  path,
  data,
  onError = (): void => {},
  handleAllErrors,
  onResponse = (): void => {},
  customErrorResponseStrategy = undefined,
  handleAllResponses,
  uniqueId,
  guest,
}) => {
  return (dispatch, getState): void => {
    const auth = getCurrentUserToken(getState());
    const venue_id = getCurrentVenueId(getState());

    if ((!auth || !venue_id) && !guest) {
      onError({ error: API_ERROR.UNAUTHORIZED, info: ['Error Unauthorized'] });
      return;
    }

    const myHeaders = new Headers();
    myHeaders.append('Accept-Language', APP_LOCALE());
    if (ENV.UALA_API_KEY) {
      myHeaders.append('X-Client-Auth', ENV.UALA_API_KEY);
    }
    if (!guest) {
      addAuthorizationHeader(myHeaders, auth);
    }

    if (uniqueId) {
      if (requestsPoolContains(uniqueId)) return;
      addToRequestsPool(uniqueId, { path, data, auth });
    }

    if (window.UALA_DEBUG_MODE) {
      dispatch(apiGetRequestAction({ path, data, venue_id, auth, uniqueId }));
    }

    fetch(API_BASE() + path + '?' + stringify(data || {}), {
      headers: myHeaders,
    })
      .then((response) => {
        /**
         * check if the token used for the request is the current token
         * (to avoid having models of different env in the store)
         */
        if (!guest && auth !== getCurrentUserToken(getState())) {
          onError({ error: API_ERROR.UNAUTHORIZED, info: ['Error Unauthorized'] });
          return;
        }

        if (uniqueId) {
          if (response.status === 200) {
            registerResponseInRequestsPool(uniqueId);
          } else {
            removeFromRequestsPool(uniqueId);
          }
        }

        return handleResponse({
          response,
          handleAllResponses,
          dispatch,
          path,
          data,
          venue_id,
          auth,
          uniqueId,
          onResponse,
          handleAllErrors,
          onError,
          customErrorResponseStrategy,
          responseAction: apiGetResponseAction,
        });
      })
      .catch((e) => {
        if (uniqueId) removeFromRequestsPool(uniqueId);
        onError(e);
      });
  };
};

const apiPostRequestAction = ({
  path,
  data,
  venue_id,
  auth,
  uniqueId,
}: ApiRequestPayload): ApiAction & ApiRequestPayload => ({
  type: REDUX_API_ACTION_TYPES.POST_REQUEST,
  path,
  data,
  venue_id,
  auth,
  uniqueId,
});

export const apiPostResponseAction = ({
  path,
  data,
  venue_id,
  auth,
  uniqueId,
  response,
}: ApiRequestPayload): ApiAction & ApiRequestPayload => ({
  type: REDUX_API_ACTION_TYPES.POST_RESPONSE,
  path,
  data,
  venue_id,
  auth,
  uniqueId,
  response,
});

export type ApiPostActionCreator = ParametricActionCreator<ApiPost, void>;

export type ApiPostDispatch = ParametricDispatch<ApiPost, void>;

type AuthByToken = {
  auth_token: string;
};

const bypassAuthViaToken = (enpointPath: string, data: ApiRequestPayload['data']): data is AuthByToken =>
  enpointPath === '/sessions.json' && typeof data !== 'undefined' && data !== undefined && 'auth_token' in data;

export const apiPost: ApiPostActionCreator = ({
  path,
  data,
  formData,
  method,
  onError = (): void => {},
  handleAllErrors,
  onResponse = (): void => {},
  handleAllResponses,
  customErrorResponseStrategy = undefined,
  uniqueId,
  unsetUniqueId,
  guest,
}) => {
  return (dispatch, getState): void => {
    //FIXME: cannot assign type-guard to a variable unless upgrading TS to ^4.4.0; see: https://github.com/microsoft/TypeScript/issues/12184
    const auth = bypassAuthViaToken(path, data) ? data.auth_token : getCurrentUserToken(getState());
    const venue_id = getCurrentVenueId(getState());

    if ((!auth || !venue_id) && !guest && path !== '/sessions.json') {
      onError({ error: API_ERROR.UNAUTHORIZED, info: ['Error Unauthorized'] });
      return;
    }

    const myHeaders = new Headers();
    if (!formData) {
      myHeaders.append('Content-Type', 'application/json');
    }
    myHeaders.append('Accept-Language', APP_LOCALE());
    if (ENV.UALA_API_KEY) {
      myHeaders.append('X-Client-Auth', ENV.UALA_API_KEY);
    }
    if (!guest || bypassAuthViaToken(path, data)) {
      addAuthorizationHeader(myHeaders, auth);
    }

    if (uniqueId) {
      if (requestsPoolContains(uniqueId)) {
        console.error('Blocked multiple checkout requests', uniqueId, requestsPoolContains(uniqueId));
        onError({ error: API_ERROR.CONFLICT, info: ['Blocked multiple checkout requests'] });
        return;
      }
      addToRequestsPool(uniqueId, { path, data, auth });
    }

    if (window.UALA_DEBUG_MODE) {
      dispatch(apiPostRequestAction({ path, data, venue_id, auth, uniqueId }));
    }

    const reqBody = bypassAuthViaToken(path, data)
      ? null
      : formData || JSON.stringify(data || {}, (x, v) => (x !== '_ref' ? v : undefined));

    fetch(API_BASE() + path, {
      method: method || 'POST',
      body: reqBody,
      headers: myHeaders,
    })
      .then((response) => {
        /**
         * check if the token used for the request is the current token
         * (to avoid having models of different env in the store)
         */
        if (!guest && auth !== getCurrentUserToken(getState())) {
          onError({ error: API_ERROR.UNAUTHORIZED, info: ['Error Unauthorized'] });
          return;
        }

        if (uniqueId) {
          if (response.status === 200) {
            registerResponseInRequestsPool(uniqueId);
          } else {
            removeFromRequestsPool(uniqueId);
          }
        }

        if (unsetUniqueId) {
          if (response.status === 200) {
            removeFromRequestsPool(unsetUniqueId);
          }
        }

        return handleResponse({
          response,
          handleAllResponses,
          dispatch,
          path,
          data,
          venue_id,
          auth,
          uniqueId,
          onResponse,
          handleAllErrors,
          onError,
          customErrorResponseStrategy,
          responseAction: apiPostResponseAction,
        });
      })
      .catch((e) => {
        if (uniqueId) removeFromRequestsPool(uniqueId);
        onError(e);
      });
  };
};
const showServerErrorModal = (dispatch: ActionDispatch, message: string) => {
  dispatch(
    modalOpen({
      id: 'server-error',
      config: {
        component: 'ServerError',
        content: message,
      },
    })
  );
};
export const apiPostRaw: ApiPostActionCreator = ({
  path,
  formData,
  onError = (): void => {},
  onResponse = (): void => {},
  uniqueId,
  unsetUniqueId,
  guest,
}) => {
  return (_dispatch, getState): void => {
    const auth = getCurrentUserToken(getState());
    const venue_id = getCurrentVenueId(getState());

    if ((!auth || !venue_id) && !guest && path !== '/sessions.json') {
      onError({ error: API_ERROR.UNAUTHORIZED, info: ['Error Unauthorized'] });
      return;
    }

    const myHeaders = new Headers();

    myHeaders.append('Accept-Language', APP_LOCALE());

    if (ENV.UALA_API_KEY) {
      myHeaders.append('X-Client-Auth', ENV.UALA_API_KEY);
    }
    if (!guest) {
      addAuthorizationHeader(myHeaders, auth);
    }

    if (uniqueId) {
      if (requestsPoolContains(uniqueId)) {
        console.error('Blocked multiple checkout requests', uniqueId, requestsPoolContains(uniqueId));
        onError({ error: API_ERROR.CONFLICT, info: ['Blocked multiple checkout requests'] });
        return;
      }
      addToRequestsPool(uniqueId, { path, data: formData, auth });
    }

    fetch(API_BASE() + path, {
      method: 'POST',
      body: formData,
      headers: myHeaders,
    })
      .then((response) => {
        /**
         * check if the token used for the request is the current token
         * (to avoid having models of different env in the store)
         */
        if (!guest && auth !== getCurrentUserToken(getState())) {
          onError({ error: API_ERROR.UNAUTHORIZED, info: ['Error Unauthorized'] });
          return;
        }

        if (uniqueId) {
          if (response.status === 200) {
            registerResponseInRequestsPool(uniqueId);
          } else {
            removeFromRequestsPool(uniqueId);
          }
        }

        if (unsetUniqueId) {
          if (response.status === 200) {
            removeFromRequestsPool(unsetUniqueId);
          }
        }

        response.text().then((response) => {
          const responseData = { success: true, data: response };

          onResponse(responseData as unknown as Response);
        });
      })
      .catch((e) => {
        if (uniqueId) removeFromRequestsPool(uniqueId);
        onError(e);
      });
  };
};

export enum CMS_CONTENT_TYPE {
  PAGE = 'page',
  WIZARD = 'wizard',
  MODAL = 'modal',
}

export const cmsGetContent = async <Data>(contentKey: string, contentType: CMS_CONTENT_TYPE): Promise<Data> => {
  return new Promise((resolve, reject): void => {
    fetch(`${ENV.UALA_CMS_ENDPOINT}/${contentType}/${contentKey}?appLocale=${APP_LOCALE()}`) // TODO dome: add userGroup param in order to fetch A/B testing content
      .then((response) => {
        response.json().then((data) => {
          switch (response.status) {
            case 200:
              return data[0] ? resolve(data[0]) : reject();
            default:
              return reject();
          }
        });
      })
      .catch((e) => reject(e));
  });
};

export type APIResponseAction = {
  type: typeof REDUX_API_ACTION_TYPES.POST_RESPONSE | typeof REDUX_API_ACTION_TYPES.GET_RESPONSE;
} & ApiRequestPayload;

type HandleResponseProps = {
  response: globalThis.Response;
  handleAllResponses: boolean | undefined;
  dispatch: ActionDispatch;
  path: string;
  data: FormData | { [key: string]: unknown } | undefined;
  venue_id: number | null;
  auth: any;
  uniqueId?: UniqueID;
  onResponse: OnResponse;
  handleAllErrors: boolean | undefined;
  onError: OnError;
  customErrorResponseStrategy: ((response: ErrorResponse) => void) | undefined;
  responseAction: typeof apiPostResponseAction | typeof apiGetResponseAction;
};

function handleResponse({
  response,
  handleAllResponses,
  dispatch,
  path,
  data,
  venue_id,
  auth,
  uniqueId,
  onResponse,
  handleAllErrors,
  onError,
  customErrorResponseStrategy,
  responseAction,
}: HandleResponseProps) {
  const headers: HeadersEntries = {};

  if (response.headers && response.headers.entries) {
    for (const pair of response.headers.entries()) {
      headers[pair[0]] = pair[1];
    }
  }
  switch (response.status) {
    case 200:
      // OK
      response.json().then((response) => {
        if (!handleAllResponses) {
          dispatch(responseAction({ path, data, venue_id, auth, uniqueId, response }));
        }
        onResponse(response, headers);
      });
      break;
    case 401:
      // logout
      handleAllErrors
        ? response.json().then((response) => onError({ error: API_ERROR.UNAUTHORIZED, info: response.info }))
        : dispatch(logoutRedirect());
      break;
    case 403:
      if (response.headers.has('X-Client-Auth') && response.headers.get('X-Client-Auth') === 'KO') {
        // block
      } else {
        /**
         * User is logged in but trying to make forbidden operations
         */
        response.json().then((response) => {
          dispatch(
            modalOpen({
              id: 'forbidden',
              config: {
                component: 'ServerError',
                content:
                  [].concat(response.info || [])[0] ||
                  (response.error && response.error.formatted_message) ||
                  'Forbidden',
              },
            })
          );
        });
      }
      break;
    case 404:
      onResponse({ error: API_ERROR.NOT_FOUND, info: ['Not Found'] } as unknown as Response, headers);
      break;
    case 409:
      response.json().then((response) => {
        onError({ error: API_ERROR.CONFLICT, info: response.info, response });
      });
      break;
    default:
      response
        .json()
        .then((resp) => {
          if (!resp.success) {
            if (customErrorResponseStrategy) {
              customErrorResponseStrategy({ ...resp, status: response.status });
            } else {
              showServerErrorModal(
                dispatch,
                [].concat(resp.info || [])[0] || (resp.error && resp.error.formatted_message) || 'Server Error'
              );
            }
          }
          onResponse(resp, headers);
        })
        .catch((e) => {
          showServerErrorModal(dispatch, 'Server Error');
          onError({
            error: API_ERROR.UNKNOWN,
            info: ['Server Error', 'message' in e ? e.message : String(e)],
            response: { success: false, data: {} } as Response,
          });
          // sentry
        });
      return;
  }
}
