import { FunctionComponent, useState, useEffect, useRef, useCallback } from 'react';
import { PaymentMethodsList } from './';
import {
  paymentMethodsMachine,
  Machine,
  CLIENT,
  PM_OUTPUT_SYMBOL,
  Current,
  AvailableCreditCard,
  PmUtils,
} from '@uala/web-payments';
import { API_BASE } from 'actions/data_providers/api';
import ENV from 'env';
import { Section, Spinner } from 'components/core';
import { getApiOrigin } from 'utils/getApiOrigin';

export type PaymentMethodsProps = {
  billingCompanyId: number;
  authToken: string;
  guest?: boolean;
  venueId?: number | null;
};

const PaymentMethods: FunctionComponent<PaymentMethodsProps> = ({
  authToken,
  billingCompanyId,
  guest = false,
  venueId,
}) => {
  const [machine, setMachine] = useState<Machine | undefined>(undefined);
  const [currentState, setCurrentState] = useState<Current>();
  const [paymentMethods, setPaymentMethods] = useState<AvailableCreditCard[]>([]);
  const isMounted = useRef(true);

  const env = API_BASE().split('//')[1].split('.')[0];
  // web-payments lib need to receive the api origin as host to work on dev environments
  const host = getApiOrigin();

  useEffect(() => {
    return (): void => {
      isMounted.current = false;
    };
  }, []);

  const initMachine = useCallback((): Promise<void> | void => {
    if (authToken && billingCompanyId && env) {
      return paymentMethodsMachine({
        env,
        authToken,
        billingCompanyId,
        appendTokenToParams: guest,
        client: CLIENT.B2B,
        debug: process.env.NODE_ENV === 'development',
        apiKey: ENV.UALA_API_KEY || '',
        host,
        onTransition: (transitionProps) => {
          const { current } = transitionProps;
          setCurrentState(current);
        },
        onCreditCardsChange: (creditCards: AvailableCreditCard[]): void => {
          setPaymentMethods(creditCards);
        },
      })
        .then((pmMachine) => {
          if (isMounted.current) {
            setMachine(pmMachine);
          }
        })
        .catch(() => {});
    }
  }, [authToken, billingCompanyId, env, guest]);

  useEffect(() => {
    initMachine();
  }, [initMachine, authToken, billingCompanyId, env]);

  useEffect(() => {
    if (machine) {
      const paymentMethodsList = machine.memory.get(PM_OUTPUT_SYMBOL.availableCreditCards);
      if (paymentMethods !== paymentMethodsList) {
        setPaymentMethods(paymentMethodsList);
      }
    }
  }, [machine, currentState, paymentMethods]);

  if (PmUtils.isFetching(machine)) {
    return (
      <Section displayFlex alignItems="center" justifyContent="center" padding={1} width="100%" height={150}>
        <Spinner />
      </Section>
    );
  }

  if (!machine || !currentState) {
    return null;
  }

  return (
    <PaymentMethodsList
      paymentMethods={paymentMethods}
      machine={machine}
      currentState={currentState}
      venueId={venueId}
    />
  );
};

export default PaymentMethods;
