/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { CategoriesResponse } from 'API/types';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const CATEGORIES = () => (): Promise<CategoriesResponse> =>
  Http()
    .get<CategoriesResponse, Record<string, unknown>>(ENDPOINT.VENUE_PRODUCTS._CATEGORIES())({})
    .then(maybeResponseToJSON);
