import { styled, css } from 'styles';
import { withTheme } from 'components/core';
import { Position } from 'components/lib/AlertBanner';

export const BannerWrapper = withTheme(
  styled('div')<{ position: Position }>(
    ({ theme: { media }, position }) => css`
      position: fixed;
      display: flex;
      justify-content: center;
      left: 15px;
      right: 15px;
      z-index: 10000;
      ${position === Position.TOP ? 'top: 0;' : position === Position.BOTTOM && 'bottom: 0;'}

      .AlertBanner {
        cursor: default;

        ${position === Position.TOP ? 'margin-top: 15px;' : position === Position.BOTTOM && 'margin-bottom: 15px;'}
      }

      .Badge {
        text-align: center;
      }

      ${media.mobileOrSmaller`
        left: 0;
        right: 0;

        .AlertBanner {
          margin: 0;
          width: 100%;
          border-radius: 0;
        }
      `}
    `
  )
);

export const CloseButton = withTheme(
  styled('div')(
    ({ theme: { media } }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      height: 36px;
      width: 36px;
      cursor: pointer;

      ${media.mobileOrSmaller`
        position: absolute;
        top: 0;
        right: 0;
      `}
    `
  )
);

export const ContentWrapper = withTheme(
  styled('div')(
    ({ theme: { media } }) => css`
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;

      ${media.mobileOrSmaller`
        flex-direction: column;
        justify-content: center;
      `}
    `
  )
);

export const TextContent = withTheme(
  styled('div')(
    ({ theme: { media } }) => css`
      margin-right: 15px;
      text-align: center;

      ${media.mobileOrSmaller`
        margin: 15px 0;
      `}
    `
  )
);
