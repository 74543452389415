import { FunctionComponent } from 'react';
import { Section, Text, Icon } from 'components/core';

const TableViewRemoteModeAlert: FunctionComponent<{
  intl: string;
  intlValues?: { [key: string]: string | number };
  iconType?: string;
}> = ({ intl, intlValues, iconType }) => (
  <Section padding={1} data-testid={intl}>
    {iconType && (
      <Section displayFlex justifyContent="center" paddingBottom={1}>
        <Icon size={20} type={iconType} color="text2" style={{ opacity: 0.4 }} />
      </Section>
    )}
    <Text block center size={14} color="text2" intl={intl} intlValues={intlValues} />
  </Section>
);

export default TableViewRemoteModeAlert;
