import { Remote, RemoteModule } from './types';
import { initialiseRemote } from './webpack';

const scriptsCache = new Set();
const remotesCache = new Set();

type RegisterRemote = (register: Register) => void;
type Register = (remote: RemoteModule) => void;

type OnRemoteReady = () => void;
type OnRemoteInit = (registerRemote: RegisterRemote) => void;

type ScriptLoaderListeners = {
  [url: string]: Set<OnRemoteReady>;
};
const loadScriptListeners: ScriptLoaderListeners = {};

export const loadRemote = (remote: Remote, onInit: OnRemoteInit, onReady: OnRemoteReady): void => {
  const { url, scope } = remote;

  // If module is already loaded and initialized:
  // execute callback and return
  if (remotesCache.has(url)) {
    return onReady();
  }

  // Add this listener in the "loading" scripts queue for this URL
  loadScriptListeners[url] = loadScriptListeners[url] || new Set();
  loadScriptListeners[url].add(onReady);

  if (scriptsCache.has(url)) {
    return;
  }

  scriptsCache.add(url);

  const element = document.createElement('script');

  element.src = url;
  element.type = 'text/javascript';
  element.async = true;

  const emitReady = (): void => {
    loadScriptListeners[url].forEach((_onReady) => {
      _onReady();
    });
    loadScriptListeners[url].clear();
  };

  element.onload = (): void => {
    initialiseRemote(scope, async (registerRemote: RegisterRemote) => {
      await onInit(registerRemote);
      remotesCache.add(url);
      emitReady();
    });
  };

  element.onerror = (): void => {
    scriptsCache.delete(url);
    document.head.removeChild(element);
    emitReady();
  };

  document.head.appendChild(element);
};
