import { styled, css } from 'styles';
import { withTheme, Section } from 'components/core';

const Accordion = withTheme(
  styled(Section)(({ theme, isDisabled }) => {
    return css`
      .AccordionItem__icon {
        padding-left: 20px;
        margin-left: auto;
      }

      ${isDisabled &&
      css`
        pointer-events: none;
      `}
    `;
  })
);

const Content = withTheme(
  styled('div')(({ theme, opened }) => {
    return css`
      display: ${opened ? 'block' : 'none'};
      height: ${opened ? '100%' : 0};
      transition: height 0.5s ease-in;
    `;
  })
);

Accordion.Content = Content;

export default Accordion;
