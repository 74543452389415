import { FunctionComponent, useCallback, useState } from 'react';
import { Text, Checkbox, Button, Section, Spinner } from 'components/core';
import { StripeProviderForm, StripeCard, Machine, PmUtils, PM_INPUT_SYMBOL } from '@uala/web-payments';
import { Loading, CardContainer, CheckboxWrapper, StripeCardElement, StipeError } from './AddPaymentMethod.theme';
import { PaymentCardError, PaymentCardErrorTitle } from 'components/lib/PaymentCard/theme';

type AddPaymentMethodProps = {
  machine: Machine;
  canChangeDefaultMethodCheckbox?: boolean;
  venueId?: number | null;
};

const AddPaymentMethod: FunctionComponent<AddPaymentMethodProps> = ({
  machine,
  canChangeDefaultMethodCheckbox = true,
  venueId,
}) => {
  const [isDefault, setIsDefault] = useState(!canChangeDefaultMethodCheckbox);

  const stripeError = PmUtils.getStripeError(machine);

  const goBack = useCallback(() => machine.input(PM_INPUT_SYMBOL.CANCEL), [machine]);
  const onConfirm = useCallback(() => {
    machine.input(PM_INPUT_SYMBOL.CONFIRM);
  }, [machine, venueId]);
  const handleChackbox = useCallback(() => {
    if (canChangeDefaultMethodCheckbox) {
      setIsDefault(!isDefault);
      machine.input(PM_INPUT_SYMBOL.DEFAULT, { isDefault: !isDefault });
    }
  }, [canChangeDefaultMethodCheckbox, isDefault, machine]);

  return (
    <CardContainer>
      {PmUtils.isAddingNewCard(machine) && (
        <Loading>
          <Spinner />
        </Loading>
      )}
      <Section displayFlex justifyContent="center" maxWidth="170px" marginBottom={0.5}>
        <Text size={12} color="text1" bold center uppercase intl="account.payment_methods.popup.add_method" />
      </Section>
      <Text size={14} center color="text1" intl="account.payment_methods.popup.description" />
      <Section marginTop={1.5} marginBottom={1} width="100%">
        {PmUtils.canRenderStripe(machine) && (
          <StripeCardElement>
            <StripeProviderForm machine={machine}>
              <StripeCard machine={machine} />
            </StripeProviderForm>
          </StripeCardElement>
        )}
      </Section>
      <CheckboxWrapper>
        <Checkbox
          inverted
          name="default_payment_method"
          onChange={handleChackbox}
          checked={isDefault}
          disabled={!canChangeDefaultMethodCheckbox}
          size="small"
        >
          <Text as="span" size={14} color="text2" intl="account.payment_methods.set_default" />
        </Checkbox>
      </CheckboxWrapper>
      {stripeError && (
        <StipeError>
          <PaymentCardError withContent={true} noPadding>
            <PaymentCardErrorTitle>
              <Text size={11}>{stripeError.message}</Text>
            </PaymentCardErrorTitle>
            {stripeError.description && <Text size={10}>{stripeError.description}</Text>}
          </PaymentCardError>
        </StipeError>
      )}
      <Section displayFlex justifyContent="space-between" alignItems="center" width="100%" marginTop={2}>
        <Section onClick={goBack}>
          <Text intl="cancel" bold size={14} color="blue" uppercase spacing={1} />
        </Section>
        <Button
          dataTestId="add-payment-method-button"
          primary
          shadow
          onClick={onConfirm}
          disabled={!PmUtils.isConfirmButtonEnabled(machine)}
        >
          <Text intl="add" bold size={14} uppercase spacing={1} />
        </Button>
      </Section>
    </CardContainer>
  );
};

export default AddPaymentMethod;
