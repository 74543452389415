import { styled, css } from 'styles';

export const StyledTriggerWrapper = styled('div')(
  ({ theme: { sizes, media } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
    cursor: pointer;
    padding: 0 10px;
    min-width: ${sizes.mobileHeaderHeight}px;
    height: ${sizes.mobileHeaderHeight}px;

    ${media.tablet`
        min-width: ${sizes.desktopHeaderHeight}px;
        height: ${sizes.desktopHeaderHeight}px;
      `};
  `
);
