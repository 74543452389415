import { HostStore } from './store';

type EventListener<E = unknown> = (event: E) => void;

type EventEmitterEmit<D = unknown> = (eventType: string, data: D) => void;
type EventEmitterListen<D = unknown> = (eventType: string, listener: EventListener<D>) => () => void;
type EventEmitterGet<D = unknown> = (storeKey: string) => D;

export type EventEmitter = {
  get: EventEmitterGet;

  emit: EventEmitterEmit;
  listen: EventEmitterListen;
  detach: () => void;
};

export const createEventEmitter = (store: HostStore, scope?: string): EventEmitter => {
  const eventEmitter: EventEmitter = {
    get: <D = unknown>(storeKey: string): D => {
      return store.getItem<D>(storeKey);
    },
    emit: <D = unknown>(eventType: string, value: D) => {
      const event = {
        source: eventEmitter,
        target: null,
        scope: scope,
        type: eventType,
        value: value,
      };
      store.dispatchEvent<D>(event);
    },
    listen: (eventType, listener) => {
      return store.addEventListener(eventEmitter, eventType, listener);
    },
    detach: () => {
      return store.removeAllListeners(eventEmitter);
    },
  };

  return eventEmitter;
};
