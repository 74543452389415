import { styled, css } from 'styles';

const colorStyles = ({ theme: { colors, contrasts }, basic, color }) => {
  if (/^#/.test(color)) {
    return css`
      color: ${colors.text1};
      background-color: ${color};
      border-color: ${color};
    `;
  }

  switch (color) {
    case 'primary':
      return basic
        ? css`
            color: ${colors.buttonPrimaryBackground};
            background-color: transparent;
            border-color: ${colors.buttonPrimaryBorder};
          `
        : css`
            color: ${colors.buttonPrimaryColor};
            background-color: ${colors.buttonPrimaryBackground};
            border-color: ${colors.buttonPrimaryBorder};
          `;
    case 'secondary':
      return basic
        ? css`
            color: ${colors.buttonSecondaryBackground};
            background-color: transparent;
            border-color: ${colors.buttonSecondaryBorder};
          `
        : css`
            color: ${colors.buttonSecondaryColor};
            background-color: ${colors.buttonSecondaryBackground};
            border-color: ${colors.buttonSecondaryBorder};
          `;
    case 'danger':
      return basic
        ? css`
            color: ${colors.buttonDangerBackground};
            background-color: transparent;
            border-color: ${colors.buttonDangerBorder};
          `
        : css`
            color: ${colors.buttonDangerColor};
            background-color: ${colors.buttonDangerBackground};
            border-color: ${colors.buttonDangerBorder};
          `;
    default:
      return basic
        ? css`
            color: ${colors[color] || colors.buttonPrimaryColor};
            background-color: transparent;
            border-color: ${colors[color] || colors.buttonPrimaryBorder};
          `
        : css`
            color: ${colors[contrasts[color] || 'white']};
            background-color: ${colors[color] || colors.buttonPrimaryBackground};
            border-color: ${colors[color] || colors.buttonPrimaryBorder};
          `;
  }
};

const floatingStyles = ({ floating }) => {
  switch (floating) {
    case 'icon':
      return css`
        position: absolute;
        z-index: 1;
        right: auto;
        bottom: 50%;
        left: 50%;
        ${sizeStyles({ size: 'small' })};
      `;
    case 'left':
      return css`
        position: absolute;
        z-index: 1;
        left: 15px;
        top: 0;
        transform: translateY(-10px);
        ${sizeStyles({ size: 'condensed' })};
      `;
    case 'right':
      return css`
        position: absolute;
        z-index: 1;
        right: 5px;
        top: 0;
        transform: translateY(5px);
        ${sizeStyles({ size: 'condensed' })};
      `;
    default:
      return css`
        position: absolute;
        z-index: 1;
        right: 15px;
        top: 0;
        transform: translateY(-10px);
        text-align: right;
        ${sizeStyles({ size: 'condensed' })};
      `;
  }
};

const sizeStyles = ({ size }) => {
  switch (size) {
    case 'condensed':
      return css`
        padding: 3px 5px;
        border-radius: 9px;
        line-height: 10px;
      `;
    case 'small':
      return css`
        padding: 1px 3px;
        border-radius: 9px;
        line-height: 10px;
      `;
    case 'big':
      return css`
        padding: 8px 13px;
        border-radius: 15px;
        line-height: 10px;
      `;
    case 'short':
      return css`
        padding: 2px 6px;
        border-radius: 10px;
        line-height: 10px;
      `;
    default:
      return css`
        padding: 3px 13px;
        border-radius: 9px;
        line-height: 10px;
      `;
  }
};

export const Badge = styled('div')(
  ({ theme, basic, primary, secondary, danger, size, color, floating, onClick, fontSize }) => css`
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: ${theme.utils.fontFamily};
    font-weight: 700;
    font-size: ${(fontSize || 8) / 10}rem;

    ${onClick && { cursor: 'pointer' }};

    ${primary
      ? colorStyles({ color: 'primary', basic, theme })
      : secondary
      ? colorStyles({ color: 'secondary', basic, theme })
      : danger
      ? colorStyles({ color: 'danger', basic, theme })
      : color
      ? colorStyles({ color, basic, theme })
      : colorStyles({ color: 'primary', basic, theme })};

    ${floating ? floatingStyles({ floating }) : sizeStyles({ size })};
  `
);

export const BadgeContainer = styled('div')(
  ({ floating }) => css`
    display: flex;
    gap: 1rem;
    white-space: pre;

    ${floating && floatingStyles({ floating })};
  `
);
