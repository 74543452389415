import { ReactElement, FunctionComponent, CSSProperties, Ref, forwardRef } from 'react';
import { Section, StyledSectionProps } from './theme';

type Props = StyledSectionProps & {
  ref?: Ref<HTMLDivElement>;
  className?: string;
  style?: CSSProperties;
  'data-testid'?: string;
  'data-product-tour-id'?: string;
  css?: unknown;
};

/**
 * @deprecated
 * use Box component instead
 */
const SectionComponent: FunctionComponent<Props> = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, ...rest }, ref): ReactElement => (
    <Section className={className || 'Section'} onClick={onClick} ref={ref} {...rest} />
  )
);

SectionComponent.displayName = 'Section';

SectionComponent.defaultProps = {};

export default SectionComponent;
