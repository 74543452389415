import HeaderAction from './HeaderAction';

function HeaderActionSupport(props) {
  if (!props.segment_key) return null;

  return (
    <HeaderAction
      className="uala-chat-support"
      mobile={false}
      tablet={true}
      desktop={true}
      onClick={() => {}}
      icon={{ type: 'chat', color: 'text1' }}
      {...props}
    />
  );
}

HeaderActionSupport.displayName = 'HeaderActionSupport';

export default HeaderActionSupport;
