import { FISCAL_PRINTER_SET_FEATURES } from 'actionTypes';
import { State } from 'reducers/types';
import { FiscalPrinterActionTypes } from 'actions/printer/index';

const defaultFiscalPrinterState: State['fiscalPrinterData'] = {
  features: {
    canApplyVatOnUsage: false,
    canIssueReceiptLotteryCode: false,
    canUseDebtPaymentMethod: false,
  },
};

export const fiscalPrinterData = (
  state: State['fiscalPrinterData'] = defaultFiscalPrinterState,
  action: FiscalPrinterActionTypes
): State['fiscalPrinterData'] => {
  switch (action.type) {
    case FISCAL_PRINTER_SET_FEATURES:
      return { features: action.features };
    default:
      return state;
  }
};
