import { useCallback, useEffect, useRef } from 'react';

const DEFAULT_TIMEOUT = 300;

const useThrottledCallback = <A>(callback: (...x: A[]) => void, timeout: number = DEFAULT_TIMEOUT): (() => void) => {
  const inputTimeout = useRef<number | undefined>(undefined);

  const throttledCallback = useCallback(
    (...args) => {
      clearTimeout(inputTimeout.current);

      inputTimeout.current = window.setTimeout(() => {
        callback(...args);
      }, timeout);
    },
    [callback, timeout]
  );

  useEffect(() => {
    return (): void => clearTimeout(inputTimeout.current);
  });

  return throttledCallback;
};

export default useThrottledCallback;
