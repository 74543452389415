import { useEffect, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import Loadable from '@loadable/component';
import { modalClose, modalOpen } from 'actions/modals';
import { createSelector } from 'reselect';
import { getModals } from 'selectors/getModals';

import 'containers/modals/ServerError';
import 'containers/modals/Banner';
import 'containers/modals/DisconnectionBanner';
import LoadingCodeChunkFallback from 'components/lib/LoadingCodeChunkFallback';

const ModalsAndStatusProvider = ({ children, modals_list = [], dispatchModalClose, dispatchModalOpen }) => {
  const [modals, setModals] = useState([]);

  useEffect(() => {
    setModals(
      modals_list.map((modal) => {
        if (!modal.config || !modal.config.component || modal.Component) {
          return modal;
        }

        modal.Component = Loadable(() => import(`containers/modals/${modal.config.component}`), {
          fallback: (props) => (modal.config.noSpinner ? null : <LoadingCodeChunkFallback />),
        });

        return modal;
      })
    );

    if (modals_list.length > 0) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [modals_list, dispatchModalClose]);

  const renderModals = useCallback(
    () =>
      modals.map(
        (modal, k) =>
          modal.Component && (
            <modal.Component config={modal.config} closeModal={() => dispatchModalClose({ id: modal.type })} key={k} />
          )
      ),
    [dispatchModalClose, modals]
  );

  useEffect(() => {
    window.customConfirm = ({ content, onConfirm, onClose }) =>
      dispatchModalOpen({
        id: 'custom-confirm',
        config: {
          component: 'Confirm',
          content: content,
          onConfirm,
          onClose,
        },
      });
    window.customAlert = (content) =>
      dispatchModalOpen({
        id: 'custom-alert',
        config: {
          component: 'Confirm',
          content: content,
          hideOnClose: true,
          confirmIntl: 'close',
        },
      });
  }, [dispatchModalOpen]);

  return (
    <div className="modals-status-provider">
      {children}

      {renderModals()}
    </div>
  );
};

const mapDispatchToProps = {
  dispatchModalClose: modalClose,
  dispatchModalOpen: modalOpen,
};

const mapStateToProps = () =>
  createSelector(getModals, (modals_list) => {
    return {
      modals_list,
    };
  });

export default connect(mapStateToProps, mapDispatchToProps)(ModalsAndStatusProvider);
