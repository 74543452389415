import { captureMessage, configureScope, withScope } from '@sentry/core';
import { getCurrentHub } from '@sentry/react';
import { CancellationMode, CANCELLATION_MODE, PrinterType, PRINTER_TYPE } from 'models';

export const EPSON_PRINTER_ERROR_TITLE = 'epsonPrinterError';

export type SentryLogProps = {
  printerType: PrinterType | null;
  response?: Node;
  title?: string;
  commands?: string;
  raw?: string;
  debug?: boolean;
};

export function isFiscalPrinter(printerType?: PrinterType | null): boolean {
  switch (printerType?.toUpperCase()) {
    case PRINTER_TYPE.RCH:
    case PRINTER_TYPE.EPSON_FP:
      return true;
    default:
      return false;
  }
}

export function isReceiptRevertable(cancellationMode: CancellationMode): boolean {
  switch (cancellationMode) {
    case CANCELLATION_MODE.FISCAL_REVERT:
      return true;
    default:
      return false;
  }
}

export const trackFirmwareVersion = (printerType: PrinterType | null, firmwareVersion: string): void => {
  configureScope((scope) => {
    const printerName = printerType || '';
    const tag = `${printerName.toLowerCase()}_firmware_version`;
    scope.setTag(tag, firmwareVersion);
    captureMessage(`${printerName} Firmware Version`);
  });
};

export function sentryLog(extras: SentryLogProps): unknown {
  if ((extras.debug && !window.detailed_monitoring_enabled) || process.env.NODE_ENV === 'test') {
    return;
  }

  if (window.detailed_monitoring_enabled) {
    // @ts-expect-error TS upgrade
    if (!sentryLog.__removed_dedupe) {
      const sentryClient = (getCurrentHub && getCurrentHub() && getCurrentHub().getClient()) || {};
      // @ts-expect-error TS upgrade
      const { Dedupe, ...integrations } = sentryClient.integrations || {};
      // @ts-expect-error TS upgrade
      sentryClient.integrations = integrations;
      // @ts-expect-error TS upgrade
      sentryLog.__removed_dedupe = true;
    }
  }

  const { title = '', commands, response, raw } = extras;
  const serializer = new XMLSerializer();

  const defaultMessageTitle = window.detailed_monitoring_enabled
    ? `${extras.printerType} Detailed Monitoring ${window?.venue_id}`
    : title;

  const messageTitle =
    window.detailed_monitoring_enabled && title === EPSON_PRINTER_ERROR_TITLE
      ? `${extras.printerType} ${EPSON_PRINTER_ERROR_TITLE} ${window?.venue_id}`
      : defaultMessageTitle;

  try {
    withScope((scope) => {
      scope.setExtra(
        'response',
        (response && serializer.serializeToString(response)) || `Unknown response format: ${response}`
      );
      if (commands) {
        scope.setExtra('commands', commands);
      }
      if (raw) {
        scope.setExtra('raw', raw);
      }
      captureMessage(messageTitle);
    });
  } catch (error) {
    return error;
  }
  return undefined;
}
