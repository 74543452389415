import { useState, useEffect } from 'react';
import { BREAKPOINTS } from 'styles';

interface UseViewport {
  width: number;
  height: number;
}

export const useViewport = (): UseViewport => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleWindowResize = (): void => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleWindowResize);
    return (): void => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { width, height };
};

export const isDesktop = (width: number): boolean => width > BREAKPOINTS.desktop;
export const isMobile = (width: number): boolean => width < BREAKPOINTS.tablet;

export const useIsMobileViewport = (): boolean => {
  const { width } = useViewport();
  return isMobile(width);
};

export const useIsDesktopViewport = (): boolean => {
  const { width } = useViewport();
  return isDesktop(width);
};
