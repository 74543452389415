import { Text } from 'components/core';
import PropTypes from 'prop-types';

const TextMapComponent = (props) => (
  <Text size={14} color={props.highlighted ? 'text1' : 'text2'}>
    {props.text}
  </Text>
);

TextMapComponent.propTypes = {
  highlighted: PropTypes.bool,
  text: PropTypes.string,
};

export default TextMapComponent;
