import { createSelector } from 'reselect';
import { getCurrentVenueId, getCurrentVenueFeatureRoomStaffMember } from './getCurrentVenue';
import { getEntities } from './getEntities';
import { getStaffMemberTreatmentsByVenueTreatmentId } from './getStaffMemberTreatments';
import { getVTGList } from 'selectors/venueTreatmentGroups';
import filterDeletedItems from 'utils/filterDeletedItems';

export const LIFECYCLE_TYPOLOGY = {
  APPOINTMENT: 'appointment',
  ENTRANCE_TICKET: 'entrance-ticket',
};

export const getVenueTreatmentsByVenue = (state) => state.venueTreatmentsByVenue;

export const getVenueTreatmentsLoading = createSelector(
  [getCurrentVenueId, getVenueTreatmentsByVenue],
  (venue_id, venueTreatmentsByVenue) => {
    return venueTreatmentsByVenue[venue_id] && venueTreatmentsByVenue[venue_id].isFetching ? true : false;
  }
);

const emptyVenueTreatments = [];
export const getVenueTreatmentsList = createSelector(
  [getCurrentVenueId, getVenueTreatmentsByVenue, getEntities.venue_treatment, getCurrentVenueFeatureRoomStaffMember],
  (venue_id, venueTreatmentsByVenue, venueTreatmentById, venue_feature_room_staff_member) => {
    const venueTreatments =
      (venueTreatmentsByVenue[venue_id] && venueTreatmentsByVenue[venue_id].items) || emptyVenueTreatments;
    if (venueTreatments === emptyVenueTreatments) {
      return venueTreatments;
    }
    return venueTreatments
      .map((venueTreatment) => ({
        ...venueTreatment,
        ...((venueTreatmentById[venueTreatment.id] && venueTreatmentById[venueTreatment.id].data) || {}),
      }))
      .map((t) => ({
        ...t,
        short_name: t.short_name || (t.name || '').toUpperCase()[0],
      }))
      .filter(
        (t) =>
          !t.lifecycle_typology ||
          t.lifecycle_typology === LIFECYCLE_TYPOLOGY.APPOINTMENT ||
          (t.lifecycle_typology === LIFECYCLE_TYPOLOGY.ENTRANCE_TICKET && venue_feature_room_staff_member)
      )
      .filter(filterDeletedItems)
      .sort((a, b) => ((b.name || '').toLowerCase() > (a.name || '').toLowerCase() ? -1 : 1));
  }
);

/**
 * return a distinct list of treatments typologies
 */
export const getVenueTreatmentsByVenueTypologies = createSelector(
  getVenueTreatmentsList,

  (treatments) => {
    return [...new Set((treatments || []).map((treatment) => treatment.typology))].sort();
  }
);

export const getFavouriteVenueTreatmentsCount = createSelector([getVenueTreatmentsList], (venueTreatmentList) => {
  const favourites = venueTreatmentList.filter((treatment) => treatment.favorite === true);

  return favourites.length;
});

export const getVenueTreatmentsById = createSelector([getVenueTreatmentsList], (venueTreatmentsList) => {
  return venueTreatmentsList.reduce((venueTreatmentsById, venueTreatment) => {
    if (venueTreatment.id) {
      venueTreatmentsById[venueTreatment.id] = venueTreatment;
    }
    return venueTreatmentsById;
  }, {});
});

export const getVenueTreatmentsByTreatmentId = createSelector([getVenueTreatmentsList], (venueTreatmentsList) => {
  return venueTreatmentsList.reduce((venueTreatmentsByTreatmentId, venueTreatment) => {
    if (venueTreatment.treatment_id) {
      venueTreatmentsByTreatmentId[venueTreatment.treatment_id] = venueTreatment;
    }
    return venueTreatmentsByTreatmentId;
  }, {});
});

export const getVenueTreatmentsTypologiesById = createSelector([getVenueTreatmentsList], (venueTreatments) => {
  return venueTreatments.reduce((venueTreatmentsTypologies, venueTreatment) => {
    if (
      venueTreatment &&
      venueTreatment.label &&
      venueTreatment.label.treatment_typology &&
      venueTreatment.label.treatment_typology.venue_typology &&
      venueTreatment.label.treatment_typology.venue_typology.id
    ) {
      venueTreatmentsTypologies[venueTreatment.label.treatment_typology.venue_typology.id] =
        venueTreatment.label.treatment_typology.venue_typology;
    }
    return venueTreatmentsTypologies;
  }, {});
});

export const getVenueTreatmentsTypologiesList = createSelector(
  [getVenueTreatmentsTypologiesById],
  (venueTreatmentsTypologiesById) => {
    return Object.keys(venueTreatmentsTypologiesById).map((id) => venueTreatmentsTypologiesById[id]);
  }
);

export const getVenueTreatmentsCategoriesList = createSelector([getVenueTreatmentsList], (venueTreatments) => {
  return venueTreatments.reduce((venueTreatmentsCategories, venueTreatment) => {
    if (
      venueTreatment &&
      venueTreatment.label &&
      venueTreatment.label.category_id &&
      !venueTreatmentsCategories.filter((c) => c.id === venueTreatment.label.category_id).length
    ) {
      venueTreatmentsCategories.push({
        id: venueTreatment.label.category_id,
        name: venueTreatment.label.category_name,
      });
    }
    return venueTreatmentsCategories.sort((a, b) =>
      (b.name || '').toLowerCase() > (a.name || '').toLowerCase() ? -1 : 1
    );
  }, []);
});

export const getAvailableVenueTreatmentsList = createSelector(
  [getVenueTreatmentsList, getStaffMemberTreatmentsByVenueTreatmentId],
  (venueTreatments, staffMemberTreatmentsByVenueTreatmentId) => {
    return venueTreatments.filter(
      (venueTreatment) =>
        staffMemberTreatmentsByVenueTreatmentId[venueTreatment.id] &&
        staffMemberTreatmentsByVenueTreatmentId[venueTreatment.id].length
    );
  }
);

export const getAvailableVenueTreatmentGroupsList = createSelector(
  [getVTGList, getStaffMemberTreatmentsByVenueTreatmentId],
  (venueTreatmentGroups, staffMemberTreatmentsByVenueTreatmentId) => {
    return venueTreatmentGroups
      .map((vtg) => {
        return {
          ...vtg,
          venue_treatments: vtg.venue_treatments.filter((vt) => staffMemberTreatmentsByVenueTreatmentId[vt.id]?.length),
        };
      })
      .filter((vtg) => vtg.venue_treatments.length > 0);
  }
);

// @TODO: this is currently bugged and potentially causing unwanted behaviour in production. Needs to investigate @maurizio.vacca
export const getAvailableVenueTreatmentsCategoriesList = createSelector(
  [getAvailableVenueTreatmentsList],
  (availableVenueTreatments) => {
    return availableVenueTreatments.reduce((venueTreatmentsCategories, venueTreatment) => {
      if (
        venueTreatment &&
        venueTreatment.label &&
        venueTreatment.label.category_id &&
        !venueTreatmentsCategories.filter((c) => c.id === venueTreatment.label.category_id).length
      ) {
        venueTreatmentsCategories.push({
          id: venueTreatment.label.category_id,
          name: venueTreatment.label.category_name,
        });
      }
      return venueTreatmentsCategories.sort((a, b) =>
        (b.name || '').toLowerCase() > (a.name || '').toLowerCase() ? -1 : 1
      );
    }, []);
  }
);

export const getAvailableTreatmentsCategories = createSelector(
  [getAvailableVenueTreatmentGroupsList],
  (venueTreatmentGroups) => {
    return venueTreatmentGroups.reduce((categories, venueTreatmentGroup) => {
      if (
        venueTreatmentGroup &&
        venueTreatmentGroup.treatment_category &&
        venueTreatmentGroup.treatment_category_id &&
        !categories.filter((category) => category.id === venueTreatmentGroup.treatment_category_id).length
      ) {
        categories.push({
          id: venueTreatmentGroup.treatment_category_id,
          name: venueTreatmentGroup.treatment_category,
        });
      }
      return categories.sort((a, b) => ((b.name || '').toLowerCase() > (a.name || '').toLowerCase() ? -1 : 1));
    }, []);
  }
);
