import { createSelector, ParametricSelector, OutputParametricSelector } from 'reselect';
import { getCurrentVenueId } from './getCurrentVenue';
import { getEntities } from './getEntities';
import { getLocalAppointmentsById, getLocalAppointmentsList } from 'selectors/getLocalAppointments';
import filterDeletedItems from 'utils/filterDeletedItems';
import moment from 'moment';
import { ItemsByVenueSelector, State } from 'reducers/types';
import { Appointment, Customer } from 'models';
import discountedAmount from 'utils/number/discountedAmount';
import { sortAppointmentsByTime } from 'utils/appointments/sortAppointmentsByTime';
import { isAppointmentCanceled } from 'utils/appointments/isAppointmentCanceled';
import { getCustomersById } from './getCustomers';
import { groupArrayByFields } from 'utils/groupArrayByFields';
import { UnknownFunction } from 'utils/utilityTypes';
import { hasExtraTimeApplied, hasValidStaffMemberExtraTime } from 'utils/appointments/hasExtraTimeApplied';

export const getAppointmentsByVenue: ItemsByVenueSelector<Appointment> = (state) => state.appointmentsByVenue;

export const getAppointmentsLoading = createSelector(
  [getCurrentVenueId, getAppointmentsByVenue],
  (venue_id, appointmentsByVenue) => {
    return (venue_id && appointmentsByVenue[venue_id] && appointmentsByVenue[venue_id].isFetching) || false;
  }
);

/**
 * Always return an existing variable to leverage cache
 */
const empty_list: Appointment[] = [];

export const getAppointmentsList = createSelector(
  [getCurrentVenueId, getAppointmentsByVenue, getEntities.appointment],
  (venue_id, appointmentsByVenue, appointmentById) => {
    const appointments =
      (venue_id && appointmentsByVenue[venue_id] && appointmentsByVenue[venue_id].items) || empty_list;

    /**
     * If appointments list is empty, stop processing and return the empty var
     */
    if (appointments === empty_list) {
      return appointments;
    }

    return appointments
      .map((appointment) =>
        Object.assign({}, appointment, (appointmentById[appointment.id] && appointmentById[appointment.id].data) || {})
      )
      .filter(filterDeletedItems)
      .map((appointment) => {
        if (appointment.parent_id && !appointment.workstation_id) {
          const parentAppointment =
            appointmentById[appointment.parent_id] && appointmentById[appointment.parent_id].data;
          if (parentAppointment) {
            appointment.workstation_id = parentAppointment.workstation_id;
          }
        }
        return appointment;
      });
  }
);

export const getAppointmentsById: (state: State) => { [key: Appointment['id']]: Appointment } = createSelector(
  [getAppointmentsList],
  (appointmentsList) => {
    return appointmentsList.reduce((appointmentsById: { [id: number]: Appointment }, appointment: Appointment) => {
      if (appointment.id) {
        appointmentsById[appointment.id] = appointment;
      }
      return appointmentsById;
    }, {});
  }
);
export interface AppointmentsListProps {
  view_mode?: string;
  date?: string;
  staff_member_id?: number;
}

/**
 * Return R type or `undefined` from props
 */
type Sel<R> = ParametricSelector<State, AppointmentsListProps, R | undefined>;

const getViewMode: Sel<string> = (_state, props) => props.view_mode;

const getDate: Sel<string> = (_state, props) => props.date;

export const getAppointmentsListByProps = createSelector(
  [
    getCurrentVenueId,
    getAppointmentsByVenue,
    getLocalAppointmentsList,
    getEntities.appointment,
    getEntities.staff_member,
    getEntities.staff_member_treatment,
    getEntities.venue_treatment,
    getViewMode,
    getDate,
  ],
  (
    venue_id,
    appointmentsByVenue,
    localAppointmentsList: Appointment[],
    appointmentById,
    staffMemberById,
    staffMemberTreatmentById,
    venueTreatmentById,
    view_mode,
    date
  ) => {
    const appointments =
      (venue_id && appointmentsByVenue[venue_id] && appointmentsByVenue[venue_id].items) || empty_list;
    const momentDate = moment(date);

    /**
     * If appointments list is empty, stop processing and return the empty var
     */
    if (appointments === empty_list) {
      return appointments;
    }

    /**
     * Get localAppointmentsList with a reference to a remote appointment.
     * Those remote appointments will be hidden
     */
    const referredAppointments = localAppointmentsList.map((appo) => appo.remote_id);

    const childrenAppointmentsByParentId: { [id: number]: Appointment[] } = {};
    const parentAppointmentsById: { [id: number]: Appointment } = {};

    const out = localAppointmentsList
      .concat(
        appointments
          .map((appointment) => {
            const clonedAppointment: Appointment = Object.assign(
              {},
              appointment,
              (appointmentById[appointment.id] && appointmentById[appointment.id].data) || {}
            );

            if (clonedAppointment.parent_id) {
              /**
               * This appointment is a child,
               * init children list if not exists
               * and add itself in the children list
               */
              if (!childrenAppointmentsByParentId[clonedAppointment.parent_id]) {
                childrenAppointmentsByParentId[clonedAppointment.parent_id] = [];
              }
              childrenAppointmentsByParentId[clonedAppointment.parent_id].push(clonedAppointment);
            } else {
              /**
               * This appointment is a parent,
               * init children list if not exists and associate to it
               */
              clonedAppointment.children_appointments =
                childrenAppointmentsByParentId[clonedAppointment.id] ||
                (childrenAppointmentsByParentId[clonedAppointment.id] = []);

              parentAppointmentsById[clonedAppointment.id] = clonedAppointment;
            }

            return clonedAppointment;
          })
          .map((appointment) => {
            if (appointment.parent_id) {
              appointment.parent_appointment =
                (parentAppointmentsById && parentAppointmentsById[appointment.parent_id]) || null;
            }
            if (
              appointment.parent_appointment &&
              appointment.parent_appointment.workstation_id &&
              !appointment.workstation_id
            ) {
              appointment.workstation_id = appointment.parent_appointment.workstation_id;
            }
            return appointment;
          })
          .filter((appointment) => {
            /**
             * Check if this appointment is already referred in a local appointment
             */
            if (~referredAppointments.indexOf(appointment.id)) {
              return false;
            }

            /**
             * Check if appointments are contained in view_mode /date / staff_member_id
             */
            const appointment_date = appointment['YYYY-MM-DD'];

            switch (view_mode) {
              default:
              case 'daily':
                /**
                 * view_mode: daily
                 * All the appointments with the same date are included
                 */
                return appointment_date === date;
              case 'weekly':
                /**
                 * view_mode: weekly
                 * Filter appointments for the same week, with the staff_member_id
                 */
                return momentDate.isSame(appointment_date, 'isoWeek');
            }
          })
      )
      .map((appointment, _i, appointments) => {
        appointment.remote =
          (appointment.remote_id &&
            appointmentById[appointment.remote_id] &&
            appointmentById[appointment.remote_id].data) ||
          null;

        [appointment, appointment.remote].forEach((appo) => {
          if (!appo) return;

          appo.data = { ...appo.data };

          if (appo.staff_member_id) {
            appo.data.staff_member = Object.assign(
              {},
              appo.data.staff_member,
              (staffMemberById[appo.staff_member_id] && staffMemberById[appo.staff_member_id].data) || {}
            );
          }

          if (appo.staff_member_treatment_id && !appo.data.staff_member_treatment) {
            appo.data.staff_member_treatment =
              (staffMemberTreatmentById[appo.staff_member_treatment_id] &&
                Object.assign({}, staffMemberTreatmentById[appo.staff_member_treatment_id].data)) ||
              {};
          }

          if (
            appo.data.staff_member_treatment &&
            appo.data.staff_member_treatment.venue_treatment_id &&
            !appo.data.venue_treatment
          ) {
            appo.data.venue_treatment =
              (venueTreatmentById[appo.data.staff_member_treatment.venue_treatment_id] &&
                Object.assign({}, venueTreatmentById[appo.data.staff_member_treatment.venue_treatment_id].data)) ||
              {};
          }
        });

        appointment.calculated_duration =
          appointment.custom_duration ||
          (appointment.data.staff_member_treatment && appointment.data.staff_member_treatment.duration);

        /**
         * Inherit `always_apply_extra_time_after` from venue treatment
         */
        appointment.always_apply_extra_time_after =
          appointment.always_apply_extra_time_after ||
          (appointment.data.staff_member_treatment &&
            venueTreatmentById[appointment.data.staff_member_treatment.venue_treatment_id] &&
            venueTreatmentById[appointment.data.staff_member_treatment.venue_treatment_id].data
              .always_apply_extra_time_after) ||
          false;

        appointment.calculated_extra_time_after =
          hasValidStaffMemberExtraTime(appointment.extra_time_staff_member_after) &&
          hasExtraTimeApplied(appointment, appointments)
            ? appointment.extra_time_staff_member_after
            : 0;

        const total_price = appointment.data.custom_price
          ? appointment.data.custom_price || 0
          : (appointment.data.staff_member_treatment && appointment.data.staff_member_treatment.price) || 0;

        appointment.total_price = total_price;
        appointment.discounted_price = total_price;

        /**
         * apply discounts if any
         */
        if (appointment.discount_type === 'percent' && appointment.discount_percent_amount) {
          appointment.discounted_price = discountedAmount(total_price, appointment.discount_percent_amount);
        }

        if (appointment.discount_type === 'absolute' && appointment.discount_absolute_amount) {
          appointment.discounted_price = total_price - appointment.discount_absolute_amount;
        }

        return appointment;
      })
      .filter(filterDeletedItems)
      .filter((appointment) => appointment.state !== 'requested' || appointment.by_venue);

    return out;
  }
);

export const getCanceledAppointmentsByDate: OutputParametricSelector<
  State,
  AppointmentsListProps,
  Appointment[],
  UnknownFunction
> = createSelector(
  getCurrentVenueId,
  getAppointmentsByVenue,
  getDate,
  getEntities.appointment,
  getCustomersById,
  (venue_id, appointmentsByVenue, date, appointmentsById, customersById: { [key: Customer['id']]: Customer }) => {
    const allAppointments =
      (venue_id && appointmentsByVenue[venue_id] && appointmentsByVenue[venue_id].items) || empty_list;

    const cancelledAppointments = allAppointments.reduce((filteredAppointments, appointment) => {
      const fullAppointment = appointmentsById[appointment.id]?.data;

      if (fullAppointment && isAppointmentCanceled(fullAppointment) && fullAppointment['YYYY-MM-DD'] === date) {
        const fullAppointmentWithCustomerInfo = {
          ...fullAppointment,
          customer:
            !fullAppointment.customer && fullAppointment.customer_id && customersById[fullAppointment.customer_id]
              ? customersById[fullAppointment.customer_id]
              : // note: this is needed because mobile devices doesn't download all the customers, so we fallback to the basic customer fields we have in the appointments
                {
                  full_name: fullAppointment.customer_full_name,
                },
        };
        return [...filteredAppointments, fullAppointmentWithCustomerInfo] as Appointment[];
      }

      return filteredAppointments;
    }, [] as Appointment[]);

    return sortAppointmentsByTime(cancelledAppointments);
  }
);

export const getDailyCanceledAppointmentsGroupedByCancelation = createSelector(
  [getCanceledAppointmentsByDate],
  (appointments: Appointment[]) => {
    return groupArrayByFields(appointments, [
      'customer_id',
      'state',
      'cancellation_protection.charge_status',
      'cancellation_protection.charged_at',
    ]);
  }
);

export const getPendingProtectionAppointmentsByVenue: (
  state: State
) => State['pendingProtectionAppointmentsByVenue'] = (state) => state.pendingProtectionAppointmentsByVenue;

export const getPendingToProtectAppointments = createSelector(
  getPendingProtectionAppointmentsByVenue,
  getCurrentVenueId,
  (appointmentsByVenue, venue_id) => {
    if (!venue_id) return empty_list;
    if (!appointmentsByVenue[venue_id]?.items) return empty_list;

    return appointmentsByVenue[venue_id].items;
  }
);

export const getPendingToProtectAppointmentsIsFetching = createSelector(
  getPendingProtectionAppointmentsByVenue,
  getCurrentVenueId,
  (appointmentsByVenue, venue_id): boolean => {
    if (!venue_id) return false;
    if (!appointmentsByVenue[venue_id]?.isFetching) return false;

    return Boolean(appointmentsByVenue[venue_id].isFetching);
  }
);

export const getAppointmentFeePercentAmount: OutputParametricSelector<
  State,
  { appointment_id: Appointment['id'] },
  number,
  UnknownFunction
> = createSelector(
  [getEntities.internal_appointment_details, (_, props): Appointment['id'] => props.appointment_id],
  (appointmentsById, appointment_id) => {
    const appointment_marketplace_detail = appointmentsById[appointment_id]?.data?.appointment_marketplace_detail;
    return appointment_marketplace_detail?.fee_percent_amount || 0;
  }
);

export const getFullAppointmentsList = createSelector(
  getAppointmentsList,
  getLocalAppointmentsList,
  (appointmentsList, localAppointmentsList): Appointment[] => [...appointmentsList, ...localAppointmentsList]
);

export const getFullAppointmentsById = createSelector(
  getAppointmentsById,
  getLocalAppointmentsById,
  (remoteAppointmentsById, localAppointmentsById): { [key: Appointment['id']]: Appointment } => ({
    ...remoteAppointmentsById,
    ...localAppointmentsById,
  })
);
