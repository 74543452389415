import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { sessions } from 'reducers/sessions';
import { entitiesDictionaryByVenue } from 'reducers/entitiesDictionary';
import { modals } from 'reducers/modals';
import { staffMembersByVenue } from 'reducers/staffMembers';
import { promotionsByVenue, promotionsTypesByVenue } from 'reducers/promotions';
import { customerPromotionsByVenue } from 'reducers/customerPromotions';
import { appliableMarketingPromotionsByToken } from './appliableMarketingPromotions';
import { venueTreatmentsByVenue, staffMemberTreatmentsByVenue } from 'reducers/treatments';
import { productsByVenue } from 'reducers/products';
import { venueProductsByVenue } from 'reducers/venueProducts';
import { productsCategories, productsSubCategories } from 'reducers/productsCategories';
import { manufacturersByVenue } from 'reducers/manufacturers';
import { ordersByVenue } from 'reducers/orders';
import { serviceGoodCategories } from 'reducers/serviceGoods';
import { paymentMethods } from 'reducers/paymentMethods';
import { vats } from 'reducers/vats';
import { venueCostsByVenue } from 'reducers/costs';
import { appointmentsByVenue } from 'reducers/appointments';
import { agendaGridByVenue } from 'reducers/agendaGrid';
import { customersByVenue } from 'reducers/customers';
import { checkoutsListByVenue } from 'reducers/checkoutsListByVenue';
import { transientDataByVenue } from 'reducers/transientData';
import { customerCartsByVenue } from 'reducers/customerCarts';
import { customerCartItemsChangesByVenue } from 'reducers/customerCartItemsChanges';
import { lineItemsListByVenue } from 'reducers/lineItems';
import { transactionsByVenue } from 'reducers/transactions';
import { suppliersByVenue, suppliersCostItemsByVenue } from 'reducers/suppliers';
import { workstationsByVenue } from 'reducers/workstations';
import { workstationTreatmentsByVenue } from 'reducers/workstationTreatments';
import { venueVacanciesByVenue, venueExtraOpeningsByVenue, venueCustomTimeTablesByVenue } from 'reducers/timeTables';
import { localAppointmentsByVenue } from 'reducers/localAppointments';
import { chartQueryParams, chartDataByKey, inventoryQueryParams, inventoryDataByKey } from 'reducers/manager';
import { vacancyTypologies } from 'reducers/vacancyTypologies';
import { notificationCreditByVenue } from 'reducers/notificationCredit';
import { fiscalDaysByVenue } from 'reducers/fiscalDays';
import { callerBox } from 'reducers/callerbox';
import { hideTermsByVenue } from 'reducers/localCustomers';
import {
  billingCompanyByVenue,
  billingCompanyProductsByVenue,
  billingAddonsByVenue,
  billingCompanyInvoicesByVenue,
  venuesTransfersByVenue,
} from 'reducers/billing';
import { staffMemberJobTitlesByVenue } from 'reducers/staffMemberJobTitles';
import { newslettersByVenue } from 'reducers/newsletters';
import { newsletterTemplatesByVenue } from 'reducers/newsletterTemplates';
import { newsletterLayoutsByVenue } from 'reducers/newsletterLayouts';
import { treatmentTypologiesByVenue } from 'reducers/treatmentTypologies';
import { estimateCustomersByVenue } from 'reducers/estimateCustomers';
import { deadlineTypologies } from 'reducers/deadlines';
import looksByVenue from 'reducers/looks';
import { extraCustomerFields } from 'reducers/extraCustomerFields';
import { customerTagsByVenue } from 'reducers/customerTags';
import { countries } from 'reducers/countries';
import brandBenchmarkTargetsByVenue from 'reducers/brandBenchmarkTargets';
import { ecommerceOrdersByVenue } from 'reducers/ecommerceOrders';
import { agendaSettings } from 'reducers/agendaSettings';
import { shippingOptionsByVenue, shippersByVenue } from 'reducers/shippingOptions';
import { multiVenueSidebarSettings } from './multiVenueSidebarSettings';
import { orderDeliveryNotesByVenue } from 'reducers/orderDeliveryNotes';
import { fiscalPrinterData } from './fiscalPrinterData';
import { userAccessesByVenue } from './usersAccessesByVenue';
import { patchTestsByVenue } from 'reducers/patchTests';
import { venueTreatmentGroupsByVenue } from 'reducers/venueTreatmentGroups';
import { pendingProtectionAppointmentsByVenue } from 'reducers/pendingProtectionAppointments';
import { packagesByVenue } from 'reducers/packages';
import { checkout } from 'reducers/checkout';
import { treatmentCategoriesByVenue } from './treatmentCategories';

export const createAppReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    sessions,
    modals,
    callerBox,
    staffMembersByVenue,
    promotionsByVenue,
    customerPromotionsByVenue,
    appliableMarketingPromotionsByToken,
    promotionsTypesByVenue,
    venueTreatmentsByVenue,
    staffMemberTreatmentsByVenue,
    venueProductsByVenue,
    productsByVenue,
    productsCategories,
    productsSubCategories,
    manufacturersByVenue,
    ordersByVenue,
    serviceGoodCategories,
    paymentMethods,
    vats,
    hideTermsByVenue,
    venueCostsByVenue,
    customersByVenue,
    appointmentsByVenue,
    agendaGridByVenue,
    checkoutsListByVenue,
    checkout,
    transientDataByVenue,
    customerCartsByVenue,
    customerCartItemsChangesByVenue,
    lineItemsListByVenue,
    transactionsByVenue,
    suppliersByVenue,
    suppliersCostItemsByVenue,
    workstationsByVenue,
    workstationTreatmentsByVenue,
    venueVacanciesByVenue,
    venueExtraOpeningsByVenue,
    venueCustomTimeTablesByVenue,
    localAppointmentsByVenue,
    chartQueryParams,
    chartDataByKey,
    inventoryQueryParams,
    inventoryDataByKey,
    vacancyTypologies,
    notificationCreditByVenue,
    fiscalDaysByVenue,
    entitiesDictionaryByVenue,
    billingCompanyByVenue,
    billingCompanyProductsByVenue,
    billingAddonsByVenue,
    billingCompanyInvoicesByVenue,
    venuesTransfersByVenue,
    staffMemberJobTitlesByVenue,
    newslettersByVenue,
    newsletterTemplatesByVenue,
    newsletterLayoutsByVenue,
    treatmentTypologiesByVenue,
    estimateCustomersByVenue,
    deadlineTypologies,
    looksByVenue,
    extraCustomerFields,
    customerTagsByVenue,
    countries,
    brandBenchmarkTargetsByVenue,
    ecommerceOrdersByVenue,
    agendaSettings,
    shippingOptionsByVenue,
    shippersByVenue,
    multiVenueSidebarSettings,
    orderDeliveryNotesByVenue,
    fiscalPrinterData,
    userAccessesByVenue,
    patchTestsByVenue,
    venueTreatmentGroupsByVenue,
    packagesByVenue,
    pendingProtectionAppointmentsByVenue,
    treatmentCategoriesByVenue,
  });
