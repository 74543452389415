import itMessages from 'translations/it.json';
import enMessages from 'translations/en.json';
import esMessages from 'translations/es.json';
import caMessages from 'translations/ca.json';
import ptMessages from 'translations/pt.json';
import elMessages from 'translations/el.json';
import frMessages from 'translations/fr.json';
import deMessages from 'translations/de.json';
import nlMessages from 'translations/nl.json';
import ltMessages from 'translations/lt.json';
import zhMessages from 'translations/zh_Hans.json';
import ruMessages from 'translations/ru.json';
import slMessages from 'translations/sl_SI.json';

import { AvailableLocales } from './LocaleProvider';

export const rootMessages: { [key in AvailableLocales]: { [key: string]: string } } = {
  en: enMessages,
  it: itMessages,
  es: esMessages,
  ca: caMessages,
  el: elMessages,
  fr: frMessages,
  pt: ptMessages,
  de: deMessages,
  nl: nlMessages,
  lt: ltMessages,
  zh: zhMessages,
  sl: slMessages,
  ru: ruMessages,
};

export const locales = Object.keys(rootMessages) as ReadonlyArray<AvailableLocales>;
