import { NODE_ENV } from 'env';
import 'whatwg-fetch';
import {
  createAPI,
  registerOneSignalDevice,
  electronInitPushNotifications,
  electronStopPushNotifications,
  iOSInitPushNotifications,
  androidInitPushNotifications,
  iOSStopPushNotifications,
  androidStopPushNotifications,
  iOSHasPushNotificationsCapabilities,
  androidHasPushNotificationsCapabilities,
  electronHasPushNotificationsCapabilities,
} from './push-notifications';

const _dbg = (...args) =>
  process.env.NODE_ENV === NODE_ENV.DEV
    ? console.log('%c@PUSH_NOTIFICATIONS:pushNotifications.js>', 'color: skyblue; font-weight: bold;', ...args)
    : void 0;

/**
 * Initial value of `onTokenUpdated`
 * no-op
 */
let onTokenUpdated = async () => {};

/**
 * Global onError function, TODO
 */
const onError = (error) => {
  console.log('error', error);
};

const setupDeviceTokenUpdateListener = ({ session, apiEndpoint, appId, deviceType, venueDefaultLocale }) => {
  if (!session.auth_token || !appId) {
    return;
  }

  /**
   * From now on, if a new token comes in from native layer, onTokenUpdated is called.
   */
  onTokenUpdated = async (deviceToken) => {
    if (!deviceToken) {
      return;
    }

    if (deviceToken === window.PUSH_NOTIFICATIONS_TOKEN) {
      return;
    }

    window.PUSH_NOTIFICATIONS_TOKEN = deviceToken;

    const player = await registerOneSignalDevice(deviceType, appId, deviceToken, venueDefaultLocale);

    if (process.env.NODE_ENV === NODE_ENV.DEV) {
      console.log('onTokenUpdated player', { player });
    }

    const APIPostNewPlayer = createAPI('POST', session, apiEndpoint + '/one_signal_players.json');
    if (player && player.success) {
      await APIPostNewPlayer({
        app_id: appId,
        user_id: session.id,
        player_id: player.id,
      });
    }
  };

  if (window.PUSH_NOTIFICATIONS_TOKEN) {
    return onTokenUpdated(window.PUSH_NOTIFICATIONS_TOKEN);
  }
};

/**
 * Called every time a user Sign-In to Manager
 * @type {(
 *  apiEndpoint: string,
 *  session: import('./push-notifications/ualaAPI').APISession,
 *  onClickNotification: () => void,
 *  venueDefaultLocale: string
 * ) => Promise<void>}
 */
export const initPushNotifications = async ({
  apiEndpoint,
  session,
  onClickNotification = () => {},
  venueDefaultLocale,
}) => {
  _dbg(apiEndpoint, session, onClickNotification, venueDefaultLocale);
  /**
   * Look for push notifications capabilities
   * (usually enabled if inside a native wrapper)
   */
  let wrapperInit = false;
  let deviceType = null;

  if (iOSHasPushNotificationsCapabilities()) {
    _dbg('iOS Detected!');
    wrapperInit = iOSInitPushNotifications;
    deviceType = 'IOS';
  } else if (androidHasPushNotificationsCapabilities()) {
    _dbg('Android Detected!');
    wrapperInit = androidInitPushNotifications;
    deviceType = 'ANDROID';
  } else if (electronHasPushNotificationsCapabilities()) {
    _dbg('Electron Detected!');
    wrapperInit = electronInitPushNotifications;
    deviceType = 'ELECTRON';
  }

  /**
   * If NO push notifications capabilities were found, exit.
   */
  if (!wrapperInit) {
    _dbg('NO push notifications capabilities found');
    return;
  }

  _dbg('Creating API');
  /**
   * We are in an push-notifications-enabled device
   * ask for onesignal's app_id, sender_id to BE
   */
  const getAppInfo = createAPI('GET', session, apiEndpoint + '/one_signal_players/app_info.json');

  _dbg('getting AppInfo...');
  const { data } = await getAppInfo();
  _dbg('data obtained', data);
  /**
   * If NO push notifications settings were found, exit.
   */
  if (!data || !data.app_id) {
    _dbg('NO push notifications settings found');
    return;
  }

  const { app_id, sender_id } = data;

  /**
   * with UALA API and onesignal data, we can setup the listener for device token changes
   */
  setupDeviceTokenUpdateListener({ session, apiEndpoint, appId: app_id, deviceType, venueDefaultLocale });
  _dbg('calling wrapperInit...');
  await wrapperInit({
    sender_id,
    onTokenUpdated,
    onClickNotification,
    onError,
  });
  _dbg('wrapperInit started!');
};

export const stopPushNotifications = async () => {
  iOSStopPushNotifications();
  androidStopPushNotifications();
  electronStopPushNotifications();
};
