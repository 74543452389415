/* eslint-disable no-restricted-imports */
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeResponseToJSON } from 'API/http.utils';
import { ATCUDShowVariables, ATCUDResponse } from 'API/types';

export const ATCUD_SHOW =
  ({ variables: { year } }: V<ATCUDShowVariables>) =>
  (): Promise<ATCUDResponse> =>
    Http()
      .get<ATCUDResponse, ATCUDShowVariables>(ENDPOINT.ATCUDS._SHOW())({
        searchParams: {
          year,
        },
      })
      .then(maybeResponseToJSON);
