import { styled } from 'styles';

import { withTheme } from 'components/core';

const transitionFunc = 'cubic-bezier(0.23, 1, 0.32, 1)';

export const StyledCheckbox = withTheme(styled('div')`
  display: inline-block;
  position: relative;
  width: 58px;
  height: 32px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  box-sizing: border-box;
  background-color: ${({ theme, checked }) => (checked ? theme.colors.blue : theme.colors.inputBorder)};
  border-radius: 32px;
  transition: background-color 0.4s ${transitionFunc};
  opacity: ${({ disabled }) => (disabled ? '.5' : '1')};

  &:before {
    left: 4px;
    top: 50%;
    position: absolute;
    width: 24px;
    height: 24px;
    content: '';
    border-radius: 24px;
    transform: ${({ checked }) => (checked ? `translate(calc(100% + 2px), -50%)` : `translate(0, -50%)`)};
    background-color: ${({ theme }) => theme.colors.white};
    transition: transform 0.4s ${transitionFunc};
  }
`);
