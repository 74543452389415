import { styled } from 'styles';

export const RatingStarsWrapper = styled('div')`
  position: relative;

  svg {
    margin-right: 0.5rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
`;
