import { FunctionComponent } from 'react';
import { Button, ButtonProps } from 'components/core';
import TailSpinner from './TailSpinner';

type ButtonLoaderProps = ButtonProps;

const ButtonLoader: FunctionComponent<ButtonLoaderProps> = ({ loading, onClick, children, dataTestId, ...rest }) => (
  <Button
    disabled={loading}
    relative
    className="ButtonLoader"
    onClick={loading ? undefined : onClick}
    {...rest}
    dataTestId={dataTestId}
  >
    {loading ? <TailSpinner size={20} centered isLoading /> : children}
  </Button>
);

ButtonLoader.displayName = 'ButtonLoader';

export default ButtonLoader;
