import { byTestId } from 'utils/selectors';
import { EventType, TrackingRule } from 'utils/tracking/aspectTracking';
import { TrackingService } from 'utils/tracking/interfaces';
type ObjectWithTrackMethod = {
  track: TrackingService['track'];
};
type Props = {
  trackingService: ObjectWithTrackMethod;
};
const CLICK: EventType[] = ['click', 'pointerdown'];

export const getDailyOverviewRules = ({ trackingService }: Props) => {
  const dailyOverviewEventName = 'DailyOverview Clicked';
  let isDailyOverviewOpened = false;
  let interaction: 0 | 1 = 0;
  const dailyOverviewOpenButtonSelector = `${byTestId('daily-overview-button')} *`;
  const dailyOverviewTestId = byTestId('daily-overview');
  const dailyOverviewInsideSelector = `${dailyOverviewTestId} *`;
  const dailyOverviewWorkingAreaSelector = `${dailyOverviewTestId} *`;

  const dailyOverviewOpened = () => {
    isDailyOverviewOpened = true;
  };
  const dailyOverviewClosed = () => {
    isDailyOverviewOpened = false;
    interaction = 0;
  };
  const dailyOverviewInteractedWith = () => {
    interaction = 1;
  };

  const onClickOutside = () => {
    if (isDailyOverviewOpened) trackingService.track(dailyOverviewEventName, { any_interaction: interaction });
    dailyOverviewClosed();
  };

  const onDailyOverviewOpen = () => {
    dailyOverviewOpened();
  };

  const isClickEventAndNotALayerInsidePromoFlash = (is: Parameters<TrackingRule>[0]): boolean => {
    const isAnyLayer = is('*', CLICK);
    const isDailyOverviewOpenButton = is(dailyOverviewOpenButtonSelector, CLICK);
    const isInsideDailyOverview = is(dailyOverviewInsideSelector, CLICK);
    return !(isInsideDailyOverview || isDailyOverviewOpenButton) && isAnyLayer;
  };

  const clickOutsideRule: TrackingRule = (is) => {
    if (!isClickEventAndNotALayerInsidePromoFlash(is)) return;
    onClickOutside();
  };

  const dailyOverviewClickedRule: TrackingRule = (is) => {
    if (!is(dailyOverviewOpenButtonSelector, CLICK)) return;
    onDailyOverviewOpen();
  };

  const dailyOverviewInteractedWithRule: TrackingRule = (is) => {
    is(dailyOverviewWorkingAreaSelector, CLICK) && dailyOverviewInteractedWith();
  };

  return [clickOutsideRule, dailyOverviewClickedRule, dailyOverviewInteractedWithRule];
};
