import {
  VENUE_FETCH_ECOMMERCE_ORDERS_REQUEST,
  VENUE_FETCH_ECOMMERCE_ORDERS_RESPONSE,
  API_GET_RESPONSE,
  API_POST_RESPONSE,
} from 'actionTypes';
import { EcommerceOrder } from 'models';
import { reducerValuesOnInit, reducerValuesOnRequest, reducerValuesOnResponse } from 'utils/reducersUtils';

type EcommerceOrdersState = {
  items?: EcommerceOrder[];
  isFetching?: boolean | number;
  didInvalidate?: boolean;
};

type EcommerceOrderStateByVenue = { [venue_id: number]: EcommerceOrdersState };

type EcommerceOrdersAction = {
  type: string;
  ecommerceOrders?: EcommerceOrder[];
  response?: { data?: { ecommerce_orders?: EcommerceOrder[]; ecommerce_order?: EcommerceOrder } };
  venue_id: number;
};

function getValuesOnResponse(state: EcommerceOrdersState, action: EcommerceOrdersAction): EcommerceOrdersState {
  return {
    ...state,
    ...reducerValuesOnResponse(
      [
        ...(state.items || []),
        ...(action.response?.data?.ecommerce_orders ||
          [action.response?.data?.ecommerce_order].filter((i) => Boolean(i))),
      ],
      {
        overrideDuplicateIds: 1,
        onlyIds: true,
      }
    ),
  };
}

const ecommerceOrders = (
  state: EcommerceOrdersState = reducerValuesOnInit(),
  action: EcommerceOrdersAction
): EcommerceOrdersState => {
  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (
        !action.venue_id ||
        (!action.response?.data?.ecommerce_orders?.length && !action.response?.data?.ecommerce_order)
      ) {
        return state;
      }

      return getValuesOnResponse(state, action);

    case VENUE_FETCH_ECOMMERCE_ORDERS_RESPONSE:
      if (!action.venue_id) {
        return state;
      }
      return getValuesOnResponse(state, action);
    case VENUE_FETCH_ECOMMERCE_ORDERS_REQUEST:
      if (!action.venue_id) {
        return state;
      }
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    default:
      return state;
  }
};

export const ecommerceOrdersByVenue = (
  state: EcommerceOrderStateByVenue = {},
  action: EcommerceOrdersAction
): EcommerceOrdersState => {
  let refState: EcommerceOrderStateByVenue;

  switch (action.type) {
    case VENUE_FETCH_ECOMMERCE_ORDERS_RESPONSE:
    case VENUE_FETCH_ECOMMERCE_ORDERS_REQUEST:
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      refState = ecommerceOrders(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };
    default:
      return state;
  }
};
