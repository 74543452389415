import { API_GET_RESPONSE } from 'actionTypes';
import { Country } from 'models';

type CountryState = Country[];
type CountryAction = {
  type: string;
  response?: { data?: { countries?: Country[] } };
};

export const countries = (state: CountryState = [], action: CountryAction): CountryState => {
  switch (action.type) {
    case API_GET_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        !action.response.data.countries ||
        !action.response.data.countries.length
      ) {
        return state;
      }
      return action.response.data.countries;
    default:
      return state;
  }
};
