import { Venue } from 'models';

type UpgradeTime = {
  upgradeTimeStart: number;
  upgradeTimeDistribution: number;
};

type Check<R> = (rolloutGroup: R & UpgradeTime, venue: Venue) => boolean;

type CountryCheck = Check<{ country?: string }>;

const countryCheck: CountryCheck = (rolloutGroup, venue) =>
  !!(rolloutGroup.country && rolloutGroup.country === venue.country?.code);

type VenueGroupCheck = Check<{ venueGroup?: string }>;

const venueGroupCheck: VenueGroupCheck = (rolloutGroup, venue) =>
  !!(rolloutGroup.venueGroup && venue.venue_groups.find((venueGroup) => venueGroup.name === rolloutGroup.venueGroup));

type PrinterTypeCheck = Check<{ printerType?: string }>;

const printerTypeCheck: PrinterTypeCheck = (rolloutGroup, venue) =>
  !!(rolloutGroup.printerType && rolloutGroup.printerType === venue.main_printer_type);

const strategies = [countryCheck, venueGroupCheck, printerTypeCheck];

export default strategies;
