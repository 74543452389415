import { PasswordManagerCredential } from 'components/sessions/mobilePasswordManager/types';
import { useEffect, useState } from 'react';
import {
  getNativeCredentialsHandler,
  KeyChainCredentials,
} from 'utils/native/native-handler/getNativeCredentialsHandler';
import { ValueOf } from 'utils/utilityTypes';

export const PASSWORD_STORER_MODES = {
  newPassword: 'new_password',
  editPassword: 'edit_password',
} as const;

type UsePasswordManagerProps = {
  shouldFetchKeychainCredentials: boolean;
};

type PasswordManagerProps = {
  showPasswordManager: boolean;
  keyChainCredentials: KeyChainCredentials | undefined;
  showPasswordStorer: boolean;
  cancelPasswordStorer: () => void;
  togglePasswordManager: () => void;
  deletePasswordManagerCredentials: () => Promise<void>;
  updatePasswordManagerCredentials: (credential: KeyChainCredentials) => Promise<void>;
  showPasswordManagerStorer: (credentials: PasswordManagerCredential) => void;
  passwordStorerMode: ValueOf<typeof PASSWORD_STORER_MODES>;
};

export const usePasswordManager = ({
  shouldFetchKeychainCredentials,
}: UsePasswordManagerProps): PasswordManagerProps => {
  const { getCredentials, setCredentials, isKeyChainAvailable, clearCredentials } = getNativeCredentialsHandler();

  const [showPasswordManager, setShowPasswordManager] = useState(false);
  const [keyChainCredentials, setKeyChainCredentials] = useState<KeyChainCredentials | undefined>();
  const [showPasswordStorer, setShowPasswordStorer] = useState(false);
  const [passwordStorerMode, setPasswordStorerMode] = useState<ValueOf<typeof PASSWORD_STORER_MODES>>(
    PASSWORD_STORER_MODES.newPassword
  );

  useEffect(() => {
    async function getNativeCredentials(): Promise<void> {
      const credentials = await getCredentials();

      if (credentials?.email && credentials.password && shouldFetchKeychainCredentials) {
        setKeyChainCredentials(credentials);
      }
    }

    getNativeCredentials();
  }, [shouldFetchKeychainCredentials]);

  const togglePasswordManager = (): void => {
    setShowPasswordManager(!showPasswordManager);
  };

  const deletePasswordManagerCredentials = async (): Promise<void> => {
    await clearCredentials();
    togglePasswordManager();
    setKeyChainCredentials(undefined);
  };

  const updatePasswordManagerCredentials = async (credential: KeyChainCredentials): Promise<void> => {
    const savedCredentials = await setCredentials(credential);
    setKeyChainCredentials(savedCredentials);
    setShowPasswordStorer(false);
  };

  const showPasswordManagerStorer = (credentials: PasswordManagerCredential): void => {
    setShowPasswordManager(false);
    if (
      isKeyChainAvailable &&
      (keyChainCredentials?.email !== credentials.email || keyChainCredentials?.password !== credentials.password)
    ) {
      setPasswordStorerMode(
        keyChainCredentials ? PASSWORD_STORER_MODES.editPassword : PASSWORD_STORER_MODES.newPassword
      );
      setKeyChainCredentials(credentials);
      setShowPasswordStorer(true);
    } else {
      setKeyChainCredentials(undefined);
    }
  };

  const cancelPasswordStorer = (): void => {
    setShowPasswordStorer(false);
    setKeyChainCredentials(undefined);
  };

  return {
    showPasswordManager,
    keyChainCredentials,
    showPasswordStorer,
    cancelPasswordStorer,
    togglePasswordManager,
    deletePasswordManagerCredentials,
    updatePasswordManagerCredentials,
    showPasswordManagerStorer,
    passwordStorerMode,
  };
};
