import PropTypes from 'prop-types';
import { Modal, Card, Section, Button, Text } from 'components/core';

const AlertModal = ({
  title,
  color,
  centered,
  children,
  onClose,
  onConfirm,
  hideOnClose = false,
  confirmIntl = 'confirm',
  cancelIntl = 'cancel',
}) => (
  <Modal onClose={onClose} centered={centered}>
    <Card>
      <Card.Header collapse="bottom">
        <Section justifyContent="center">{title}</Section>
      </Card.Header>
      <Card.Section collapse="top" paddingTop={1}>
        {children}
        {onConfirm ? (
          <Section flex justifyContent={hideOnClose ? 'center' : 'space-between'} paddingTop={1.25}>
            {!hideOnClose && (
              <Button onClick={onClose} unstyled dataTestId="modal-close-button" productTourId="modal-close-button">
                <Text bold color="blue" spacing={1} uppercase intl={cancelIntl} />
              </Button>
            )}
            <Button
              onClick={onConfirm}
              color={color}
              width="50%"
              shadow="light"
              dataTestId="modal-confirm-button"
              productTourId="modal-confirm-button"
            >
              <Text bold color="white" spacing={1} uppercase intl={confirmIntl} />
            </Button>
          </Section>
        ) : null}
      </Card.Section>
    </Card>
  </Modal>
);

AlertModal.defaultProps = {
  color: 'blue',
};

AlertModal.propTypes = {
  title: PropTypes.node,
  color: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
  hideOnClose: PropTypes.bool,
  centered: PropTypes.bool,
  onConfirm: PropTypes.func,
};

export default AlertModal;
