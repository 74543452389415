/**
 * createOneShotCommand
 * @param {Function} command the command to execute only once
 */
const createOneShotCommand = () => {
  let alreadyCalled = false;

  return (command) => {
    if (!alreadyCalled) command();
    alreadyCalled = true;
  };
};

export default createOneShotCommand;
