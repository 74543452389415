import { memo } from 'react';
import PropTypes from 'prop-types';
import { connectFormElement } from '@uala/react-forms';

import { DefaultSettings, INPUT_SIZES } from '../../config';
import StyledButton from './Button.theme';

const ButtonComponent = ({ emitSubmit, name, value, ...props }) => {
  return <StyledButton onClick={() => emitSubmit(name, value)} {...props} />;
};

const Button = connectFormElement(memo(ButtonComponent));

Button.displayName = 'Button';

Button.propTypes = {
  size: PropTypes.oneOf(INPUT_SIZES),
};

Button.defaultProps = {
  size: DefaultSettings.INPUT_SIZE,
};

export default Button;
