import { FunctionComponent } from 'react';
import { Icon } from 'components/core';
import { NotificationIconAlert, NotificationIconWrapper } from './NotificationIcon.theme';

type NotificationIconProps = {
  iconType: string;
  iconSize?: number;
  iconColor?: string;
  className?: string;
  showNotificationAlert?: boolean;
};

const NotificationIcon: FunctionComponent<NotificationIconProps> = ({
  iconType,
  iconSize = 16,
  iconColor = 'text1',
  showNotificationAlert = false,
  className,
}) => (
  <NotificationIconWrapper className={className}>
    {showNotificationAlert && <NotificationIconAlert />}
    <Icon className={className ? `Icon ${className}` : undefined} type={iconType} color={iconColor} size={iconSize} />
  </NotificationIconWrapper>
);

export default NotificationIcon;
