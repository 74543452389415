import React from 'react';
import PropTypes from 'prop-types';
import ModelDropdown from './index';

class ModelDropdownRemote extends React.Component {
  state = {
    query: '',
    responses_cache: [],
    editing: false,
  };

  api_cache_limit = 50;

  itemMatch = () => true;

  onStateChange = ({ editing }) => {
    this.setState({ editing }, () => {
      this.props.onStateChange({ editing, noResults: false });
    });
  };

  onQueryChange = ({ query }) => {
    this.setState({ query }, () => {
      if (query.length < 3) return;

      const task = () => {
        this.props.itemsRemote({ query }).then(({ items }) => {
          this.setState(
            ({ responses_cache }) => ({
              responses_cache: [
                {
                  query,
                  items,
                  loading: false,
                },
                ...responses_cache,
              ].slice(0, this.api_cache_limit),
            }),
            () => {
              if (query === this.state.query) {
                this.props.onStateChange({ editing: this.state.editing, noResults: items.length <= 0 });

                if (this.props.createNewItemOnQueryChange)
                  if (!items.length) {
                    this.props.onStateChange({ editing: false, noResults: true });
                    this.props.onCreateNewItem({ query });
                  }
              }
            }
          );
        });
      };

      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (query === this.state.query) task();
      }, 300);
    });
  };

  getRemoteData() {
    return this.state.responses_cache.find(({ query }) => query === this.state.query) || {};
  }

  render() {
    const { onQueryChange, onQueryChangeTimeout, createNewItemOnQueryChange, hideNoResults, ...props } = this.props;

    const { items = [], loading = true } = this.getRemoteData();

    return (
      <ModelDropdown
        {...props}
        immediateOnQueryChange={true}
        onStateChange={this.onStateChange}
        onQueryChange={this.onQueryChange}
        createNewItemOnQueryChange={false}
        itemMatch={this.itemMatch}
        itemsList={items}
        itemsLoading={loading}
        hideNoResults={loading ? false : hideNoResults}
      />
    );
  }
}

ModelDropdownRemote.defaultProps = {
  ...ModelDropdown.defaultProps,
  onCreateNewItem: () => {},
};

ModelDropdownRemote.propTypes = {
  ...ModelDropdown.propTypes,
  itemsRemote: PropTypes.func,
};

ModelDropdownRemote.displayName = 'ModelDropdownRemote';

export default ModelDropdownRemote;
