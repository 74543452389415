import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchVenueTreatmentsIfNeeded } from 'actions/treatments';
import { getVenueTreatmentsCategoriesList, getVenueTreatmentsList } from 'selectors/getVenueTreatments';
import PromoFlashEditResources from './PromoFlashEditResources';

const mapDispatchToProps = {
  dispatchFetchVenueTreatments: fetchVenueTreatmentsIfNeeded,
};

const mapStateToProps = () =>
  createSelector(
    getVenueTreatmentsCategoriesList,
    getVenueTreatmentsList,
    (venueTreatmentsCategoriesList, venueTreatmentsList) => {
      return {
        venueTreatmentsCategoriesList,
        venueTreatmentsList,
      };
    }
  );

export default connect(mapStateToProps, mapDispatchToProps)(PromoFlashEditResources);
