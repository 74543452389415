import { StaffMemberTreatment, VenueTreatment } from 'models';

type RequestedAppointment = {
  id: number;
  time: string;
  customer_id: number;
  staff_member_id: number;
  staff_member_treatment_id: number;
  workstation_id?: number;
  children_appointments?: RequestedAppointment[];
  additional_appointments?: RequestedAppointment[];
};

type GetAppointmentBookData = (args: {
  requestedAppointment: RequestedAppointment;
  staffMemberTreatmentsList: StaffMemberTreatment[];
  staffMemberTreatmentsById: { [id: number]: StaffMemberTreatment };
  venueTreatmentsById: { [id: number]: VenueTreatment };
}) => RequestedAppointment;

export const getAppointmentBookData: GetAppointmentBookData = ({
  requestedAppointment,
  staffMemberTreatmentsList,
  staffMemberTreatmentsById,
  venueTreatmentsById,
}) => {
  const appoData: RequestedAppointment = { ...requestedAppointment };

  const staff_member_treatment =
    staffMemberTreatmentsById && staffMemberTreatmentsById[requestedAppointment.staff_member_treatment_id];

  if (staff_member_treatment) {
    appoData.staff_member_id = staff_member_treatment.staff_member_id;
    const venue_treatment = venueTreatmentsById && venueTreatmentsById[staff_member_treatment.venue_treatment_id];

    if (venue_treatment) {
      const customers_count = venue_treatment.min_customers_count || 1;
      const staff_members_count = venue_treatment.required_staff_members_count || 1;
      const appointments_count = Math.max(customers_count, staff_members_count);
      let additional_staff_member_treatments: StaffMemberTreatment[] = [];
      if (staff_members_count > 1) {
        additional_staff_member_treatments = staffMemberTreatmentsList.filter(
          (smt) => smt.venue_treatment_id === venue_treatment.id && smt.id !== staff_member_treatment.id
        );
      }

      if (appointments_count > 1) {
        /**
         * 1. Force appo data to have children
         */
        appoData.children_appointments = (requestedAppointment.children_appointments || []).map((child) => ({
          ...child,
        }));

        for (let index = 0; index < appointments_count - 1; index++) {
          const smt = additional_staff_member_treatments[index] || staff_member_treatment;

          appoData.children_appointments[index] = appoData.children_appointments[index] || {
            time: appoData.time,
            staff_member_treatment_id: smt.id,
            staff_member_id: smt.staff_member_id,
            customer_id: venue_treatment.min_customers_count === 1 ? appoData.customer_id : -4,
            workstation_id: appoData.workstation_id,
          };
        }
      }
    }
  }

  if (appoData.additional_appointments && appoData.additional_appointments.length) {
    appoData.additional_appointments = appoData.additional_appointments.map((additionalAppointment) =>
      getAppointmentBookData({
        requestedAppointment: additionalAppointment,
        staffMemberTreatmentsList,
        staffMemberTreatmentsById,
        venueTreatmentsById,
      })
    );
  }

  return appoData;
};
