import moment from 'moment';
import PropTypes from 'prop-types';
import { Section, Text, Icon } from 'components/core';
import CallerBoxShape from './CallerBox.theme';
import PriceAmount from 'components/lib/PriceAmount';

const CallerBox = ({ popup_data, close, minimized }) =>
  popup_data ? (
    <CallerBoxShape className="CallerBox" minimized={minimized} onClick={(e) => e.stopPropagation()}>
      <Section className="CallerBox__inner" displayFlex alignItems="center" justifyContent="space-between">
        <div className="CallerBox_phone_icon">
          <Icon type="incoming-call" color="white" size={16} />
        </div>
        <Section className="Section CallerBox__info">
          <Text block size={10} uppercase spacing={1} color="text2" intl="callerbox.incoming_call_from" />
          <Text block bold size={12} color="text1" truncate>
            {popup_data.customer ? popup_data.customer.full_name : popup_data.guest ? popup_data.guest.phone : ''}
          </Text>
        </Section>
        <Section displayFlex className="Section CallerBox__group hide_on_tablet">
          <Section className="Section CallerBox__info">
            <Text block size={10} uppercase spacing={1} color="text2" intl="callerbox.bookings_average_budget" />
            <Text block size={10} uppercase spacing={1} color="text2" intl="callerbox.last_checked_out_booking_time" />
            <Text block size={10} uppercase spacing={1} color="text2" intl="callerbox.appointments_frequency" />
          </Section>
          <Section className="Section CallerBox__info">
            {popup_data.customer && popup_data.customer.bookings_average_budget_cents ? (
              <PriceAmount
                value={popup_data.customer.bookings_average_budget_cents / 100}
                block
                bold
                size={12}
                color="text1"
              />
            ) : (
              <Text block bold size={12} color="text1" intl="callerbox.na" />
            )}
            {popup_data.customer && popup_data.customer.last_checked_out_booking_time ? (
              <Text block bold size={12} color="text1">
                {moment(popup_data.customer.last_checked_out_booking_time).fromNow()}
              </Text>
            ) : (
              <Text block bold size={12} color="text1" intl="callerbox.na" />
            )}
            {popup_data.customer && popup_data.customer.appointments_frequency ? (
              (() => {
                if (popup_data.customer.appointments_frequency <= 1) {
                  return (
                    <Text
                      block
                      bold
                      size={12}
                      color="text1"
                      intl="callerbox.frequency.<=1"
                      intlValues={{ count: popup_data.customer.appointments_frequency }}
                    />
                  );
                }
                if (popup_data.customer.appointments_frequency < 7) {
                  return (
                    <Text
                      block
                      bold
                      size={12}
                      color="text1"
                      intl="callerbox.frequency.<7"
                      intlValues={{ count: popup_data.customer.appointments_frequency }}
                    />
                  );
                }
                if (popup_data.customer.appointments_frequency < 14) {
                  return (
                    <Text
                      block
                      bold
                      size={12}
                      color="text1"
                      intl="callerbox.frequency.<14"
                      intlValues={{ count: popup_data.customer.appointments_frequency }}
                    />
                  );
                }
                if (popup_data.customer.appointments_frequency < 29) {
                  return (
                    <Text
                      block
                      bold
                      size={12}
                      color="text1"
                      intl="callerbox.frequency.<29"
                      intlValues={{ count: Math.round(popup_data.customer.appointments_frequency / 7) }}
                    />
                  );
                }
                if (popup_data.customer.appointments_frequency < 60) {
                  return (
                    <Text
                      block
                      bold
                      size={12}
                      color="text1"
                      intl="callerbox.frequency.<60"
                      intlValues={{ count: popup_data.customer.appointments_frequency }}
                    />
                  );
                }
                if (popup_data.customer.appointments_frequency < 366) {
                  return (
                    <Text
                      block
                      bold
                      size={12}
                      color="text1"
                      intl="callerbox.frequency.<366"
                      intlValues={{ count: Math.round(popup_data.customer.appointments_frequency / 30) }}
                    />
                  );
                }
                if (popup_data.customer.appointments_frequency < 730) {
                  return (
                    <Text
                      block
                      bold
                      size={12}
                      color="text1"
                      intl="callerbox.frequency.<730"
                      intlValues={{ count: popup_data.customer.appointments_frequency }}
                    />
                  );
                }
                if (popup_data.customer.appointments_frequency >= 730) {
                  return (
                    <Text
                      block
                      bold
                      size={12}
                      color="text1"
                      intl="callerbox.frequency.>=730"
                      intlValues={{ count: Math.round(popup_data.customer.appointments_frequency / 365) }}
                    />
                  );
                }
              })()
            ) : (
              <Text block bold size={12} color="text1" intl="callerbox.na" />
            )}
          </Section>
        </Section>
        <Section displayFlex flex="1" className="Section CallerBox__group hide_on_mobile">
          <Section className="Section CallerBox__info hide_on_tablet">
            <Text block size={10} uppercase spacing={1} color="text2" intl="callerbox.suggested_treatments" />
          </Section>
          <Section className="Section CallerBox__info">
            {popup_data.customer && popup_data.customer.favorite_treatment_categories ? (
              []
                .concat(popup_data.customer.favorite_treatment_categories)
                .map(({ id, treatment_category = {}, favorite_staff_member = {}, last_checked_out_booking_time }) => (
                  <Text block key={id}>
                    <Text bold size={12} color="text1">
                      {treatment_category.name}
                    </Text>
                    {favorite_staff_member ? ' ' : null}
                    {favorite_staff_member ? (
                      <Text className="Text TextStaffMember" size={12} color="blue" bold>
                        {favorite_staff_member.first_name}
                      </Text>
                    ) : null}
                    {last_checked_out_booking_time ? (
                      <Text size={12} color="text2">
                        {moment(last_checked_out_booking_time).fromNow()}
                      </Text>
                    ) : null}
                  </Text>
                ))
            ) : (
              <Text block bold size={12} color="text1" intl="callerbox.na" />
            )}
          </Section>
        </Section>
        <div className="CallerBox_close" onClick={close}>
          <Icon type="close" color="text1" size={11} />
        </div>
      </Section>
    </CallerBoxShape>
  ) : null;

CallerBox.defaultProps = {};

CallerBox.propTypes = {
  popup_data: PropTypes.object,
  close: PropTypes.func,
  minimized: PropTypes.bool,
};

export default CallerBox;
