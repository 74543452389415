import {
  VENUE_APPLIABLE_MARKETING_PROMOTIONS_REQUEST,
  VENUE_APPLIABLE_MARKETING_PROMOTIONS_RESPONSE,
} from 'actionTypes';

export const appliableMarketingPromotionsByToken = (state = {}, action) => {
  switch (action.type) {
    case VENUE_APPLIABLE_MARKETING_PROMOTIONS_REQUEST:
      if (!action.token) {
        return state;
      }

      return {
        ...state,
        [action.token]: {
          loading: true,
        },
      };

    case VENUE_APPLIABLE_MARKETING_PROMOTIONS_RESPONSE:
      if (
        !action.token ||
        (!action.appliable_marketing_promotions && !action.appliable_credits) ||
        !action.line_items
      ) {
        return state;
      }

      return {
        ...state,
        [action.token]: {
          loading: false,
          appliable_marketing_promotions: action.appliable_marketing_promotions,
          appliable_credits: action.appliable_credits,
          line_items: action.line_items,
        },
      };

    default:
      return state;
  }
};
