import { styled, css } from 'styles';
import { withTheme } from 'components/core';

const RadioButton = withTheme(styled('div')`
  width: 32px;
  height: 32px;
  border-radius: 32px;
  flex: 0 0 32px;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : theme.colors.inputBorder)};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 50%;
    background-color: transparent;
    transform: scale(0) translate(-50%, -50%);
    transform-origin: 0px 0px;
    transition: transform 0.3s ease 0s;
    ${({ selected }) =>
      selected &&
      `
        background-color: rgb(74, 147, 249);
        transform: scale(1) translate(-50%, -50%);
      `}
  }
`);

const RadioLabel = withTheme(styled('div')`
  display: inline-block;
  margin-right: 12px;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.text1};
  font-family: ${({ theme }) => theme.utils.fontFamily};
`);

const RadioOption = styled('div')`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const RadioGroup = styled('div')`
  display: flex;
  ${({ stacked }) =>
    stacked
      ? css`
          width: 100%;
          flex-direction: column;
          > * {
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        `
      : css`
          flex-direction: row;
          align-items: center;
          flex-wrap: wrap;
        `};
  justify-content: ${({ align }) => align || 'flex-start'};
`;

export { RadioButton, RadioLabel, RadioGroup, RadioOption };
