import {
  AgendaNextCustomEvents,
  dispatchCustomEvent,
  LOCATION_CHANGED_EVENT,
  VIEW_ALL_COLLABORATORS,
  VIEW_COLLABORATORS_AT_WORK,
} from 'customEvents';
import { TrackingRule } from 'utils/tracking/aspectTracking';
import { AgendaPageRulesMemory } from 'utils/tracking/trackingObservations/customAgendaPageTracking/agendaPageRulesMemory';

type Context = {
  agendaPageRulesMemory: AgendaPageRulesMemory;
};

export const getAgendaAllCollaboratorsWorkCollaboratorsViewRules = ({
  agendaPageRulesMemory,
}: Context): TrackingRule<AgendaNextCustomEvents>[] => {
  const agendaPageTrack = (value: 'all' | 'only_working') => {
    agendaPageRulesMemory.set('allOnlyWorkView', value);
    dispatchCustomEvent(LOCATION_CHANGED_EVENT);
  };
  const rules: TrackingRule<AgendaNextCustomEvents>[] = [
    (is) => is('document', VIEW_ALL_COLLABORATORS) && agendaPageTrack('all'),
    (is) => is('document', VIEW_COLLABORATORS_AT_WORK) && agendaPageTrack('only_working'),
  ];
  return rules;
};
