import {
  FETCH_BRAND_BENCHMARK_REQUEST,
  UPDATE_BRAND_BENCHMARK_REQUEST,
  FETCH_BRAND_BENCHMARK_RESPONSE,
  UPDATE_BRAND_BENCHMARK_RESPONSE,
} from 'actionTypes';

import { reducerValuesOnRequest, reducerValuesOnInit } from 'utils/reducersUtils';
import { BrandBenchmarkTargets } from 'models/brand';

type BrandBenchmarkTargetsState = {
  targets?: BrandBenchmarkTargets;
  isFetching?: boolean | number;
  didInvalidate?: boolean;
};

type BrandBenchmarkTargetsStateByVenue = { [venue_id: number]: BrandBenchmarkTargetsState };

interface BrandBenchmarkTargetsAction {
  type: string;
  error: object | boolean;
  venue_id: number;
  targets: BrandBenchmarkTargets;
}

const brandBenchmarkTargets = (
  state: BrandBenchmarkTargetsState = reducerValuesOnInit(),
  action: BrandBenchmarkTargetsAction
): BrandBenchmarkTargetsState => {
  switch (action.type) {
    case FETCH_BRAND_BENCHMARK_REQUEST:
    case UPDATE_BRAND_BENCHMARK_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case FETCH_BRAND_BENCHMARK_RESPONSE:
    case UPDATE_BRAND_BENCHMARK_RESPONSE:
      if (!action.targets) {
        return state;
      }

      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        targets: action.targets,
      };
    default:
      return state;
  }
};

const brandBenchmarkTargetsByVenue = (
  state: BrandBenchmarkTargetsStateByVenue = {},
  action: BrandBenchmarkTargetsAction
): BrandBenchmarkTargetsStateByVenue => {
  let refState: BrandBenchmarkTargetsState;

  switch (action.type) {
    case FETCH_BRAND_BENCHMARK_REQUEST:
    case UPDATE_BRAND_BENCHMARK_REQUEST:
    case FETCH_BRAND_BENCHMARK_RESPONSE:
    case UPDATE_BRAND_BENCHMARK_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = brandBenchmarkTargets(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

export default brandBenchmarkTargetsByVenue;
