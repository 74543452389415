import { ComponentType, FunctionComponent, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { createSelector, Selector } from 'reselect';
import { ModalActionDispatch, modalOpen } from 'actions/modals';
import { acceptForm } from 'actions/acceptanceForm';
import { getCurrentVenueAcceptanceForms } from 'selectors/venue/getAcceptanceForms';
import { AcceptanceForm } from 'models/acceptanceForm';
import { State } from 'reducers/types';
import moment from 'moment';
import getEntities from 'selectors/getEntities';

type StateProps = {
  acceptance_forms: AcceptanceForm[];
};

type AcceptanceFormsProviderProps = StateProps & {
  dispatchModalOpen: ModalActionDispatch;
  dispatchAcceptForm: (dispatch: any, getState: any) => Promise<undefined>;
  children: ReactElement;
};

//TODO [2022-08-07] Convert to useDispatch - useSelector
const AcceptanceFormsProvider: FunctionComponent<AcceptanceFormsProviderProps> = ({
  acceptance_forms,
  dispatchModalOpen,
  dispatchAcceptForm,
  children,
}): ReactElement => {
  const [skipped, setSkipped] = useState({});

  const pending_acceptance_forms: AcceptanceForm[] = useMemo(
    () => acceptance_forms.filter((a) => !skipped[a.id as keyof unknown]),
    [acceptance_forms, skipped]
  );

  const openAcceptanceForm = useCallback(
    (acceptance_form: AcceptanceForm) => {
      const acceptance_form_template_id = acceptance_form.id;
      dispatchModalOpen({
        id: 'acceptance-form-' + acceptance_form_template_id,
        config: {
          component: 'VenueAcceptanceForm',
          acceptance_form: acceptance_form,
          closable: !acceptance_form.deadline || moment().isBefore(acceptance_form.deadline),
          onClose: (): void => {
            setSkipped((s: object) => ({ ...s, [acceptance_form_template_id]: true }));
          },
          acceptForm: dispatchAcceptForm,
          onAccept: (): void => {
            setSkipped((s: object) => ({ ...s, [acceptance_form_template_id]: true }));
          },
        },
      });
    },
    [dispatchAcceptForm, dispatchModalOpen]
  );

  useEffect(() => {
    if (pending_acceptance_forms && pending_acceptance_forms.length) {
      openAcceptanceForm(pending_acceptance_forms[0]);
    }
  }, [pending_acceptance_forms, openAcceptanceForm]);

  return children;
};

AcceptanceFormsProvider.displayName = 'AcceptanceFormsProvider';

const mapStateToProps = (): Selector<State, StateProps> =>
  createSelector(
    getCurrentVenueAcceptanceForms,
    getEntities.acceptance_form,
    (acceptance_forms, acceptanceFormsById) => {
      return {
        acceptance_forms: acceptance_forms
          .map((a) => acceptanceFormsById[a.id] && acceptanceFormsById[a.id].data)
          .filter((a) => a && !a.accepted_at),
      };
    }
  );

const mapDispatchToProps = {
  dispatchModalOpen: modalOpen,
  dispatchAcceptForm: acceptForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptanceFormsProvider as ComponentType);
