import { FC, ReactNode, useState, useRef, useEffect, useCallback } from 'react';
import { Box, Icon, IconList } from 'components/ui-treatwell-pro';
import * as S from './Select.theme';
import { Color, treatwellProTheme } from '../theme';
// eslint-disable-next-line no-restricted-imports
import { Spinner } from 'components/warehouse_next/_partials/Spinner';
import { useDropDown } from 'ui-treatwell-pro/utils/useDropDown';

export interface SelectIconProps {
  readonly placement?: 'right' | 'left';
  readonly type?: IconList;
  readonly color?: Color;
  readonly show?: boolean;
  readonly size?: number;
}

interface SelectOptionState {
  readonly label: ReactNode;
  readonly value: string | number;
}

export interface SelectIconType {
  readonly type: IconList;
  readonly color?: Color;
}

export interface Option<V, L> {
  readonly optionLabel: L;
  readonly value: V;
  readonly optionIcon?: SelectIconType;
}
export type Options<V, L> = ReadonlyArray<Option<V, L>>;
export type DefaultOption<V extends string | number> = V;

export interface SelectProps<V extends string | number, I extends ReactNode> {
  readonly name?: string;
  readonly dataTestId?: string;
  readonly isDisabled?: boolean;
  readonly isReadOnly?: boolean;
  readonly isInvalid?: boolean;
  readonly label?: string;
  readonly icon?: SelectIconType;
  readonly optionsLabel?: string;
  readonly defaultOption?: DefaultOption<V>;
  readonly options: Options<V, I>;
  readonly width?: string | number;
  /**
   * @description pass a custom max-height to the dropdown: if it's too high compared to the space around select or equal to 0 it will be ignored
   */
  readonly dropDownHeight?: number;
  readonly errorMessage?: string;
  readonly onChange?: (selectedOption: SelectOptionState) => void;
  readonly isLoading?: boolean;
}

export const Select = <V extends string | number, I extends string | number | ReactNode>({
  name,
  isDisabled,
  isReadOnly,
  isInvalid,
  label,
  icon,
  optionsLabel,
  defaultOption,
  width,
  dropDownHeight = 250,
  errorMessage,
  onChange,
  isLoading,
  options,
  dataTestId,
}: SelectProps<V, I>): JSX.Element => {
  const { position, maxHeight, dropDownRef, toggleDropdown, dropdown } = useDropDown<HTMLDivElement>({
    dropDownHeight,
  });
  const [selected, setSelected] = useState<SelectOptionState | null>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOption = useCallback(
    (selectedOption: SelectOptionState): void => {
      if (!isDisabled && onChange) onChange(selectedOption);

      setValue(selectedOption);
      toggleDropdown();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDisabled, onChange]
  );

  const setValue = useCallback(
    (selectedOption: SelectOptionState | null): void => {
      if (inputRef.current) {
        inputRef.current.value = selectedOption?.value ? String(selectedOption.value) : '';
      }

      setSelected(selectedOption);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputRef.current]
  );

  function setMyDefault<O extends string | number, P>(
    currentOptions: Options<O, P>,
    defaultValue?: DefaultOption<O>
  ): Option<O, P> | undefined {
    return currentOptions.find((x) => x.value === defaultValue);
  }

  useEffect(() => {
    const currentDefaultOption = setMyDefault(options, defaultOption);

    currentDefaultOption
      ? setValue({
          value: currentDefaultOption.value,
          label: currentDefaultOption.optionLabel,
        })
      : setValue(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOption, options]);

  return (
    <Box ref={dropDownRef} p="5px">
      <S.SelectGroup width={width}>
        {/* Hidden HTML Input (useful for forms interoperability) */}
        <input ref={inputRef} type="hidden" id={name} name={name} value={selected?.value} />

        <SelectIcon placement="left" type={icon?.type} color={icon?.color} show={Boolean(icon)} />
        <S.SelectIcon placement="right" className="right-icon">
          {isLoading ? <Spinner size={24} data-testid="spinner" /> : <Icon name={dropdown?.isOpen ? 'Up' : 'Down'} />}
        </S.SelectIcon>
        <S.Select
          data-testid={dataTestId}
          type="button"
          disabled={isDisabled}
          aria-readonly={isReadOnly}
          aria-invalid={isInvalid}
          aria-selected={Boolean(selected?.label)}
          onClick={toggleDropdown}
          // eslint-disable-next-line jsx-a11y/aria-proptypes
          aria-expanded={dropdown?.isOpen}
        >
          <S.SelectLabel>{selected?.label || optionsLabel}</S.SelectLabel>
        </S.Select>
        {label ? <S.Label>{label}</S.Label> : null}

        <S.SelectDropdown width={width} position={position} maxHeight={maxHeight}>
          <ul className="select__list" aria-label={name}>
            {options.map(({ value, optionLabel, optionIcon }, index) => (
              <SelectOption
                data-testid={dataTestId + '-item-' + index}
                key={`${index}-option`}
                icon={optionIcon}
                selected={selected?.value === value}
                onClick={(): void => handleOption({ label: optionLabel, value })}
              >
                {optionLabel}
              </SelectOption>
            ))}
          </ul>
        </S.SelectDropdown>
      </S.SelectGroup>
      {isInvalid && errorMessage ? <S.ErrorMessage>{errorMessage}</S.ErrorMessage> : null}
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectIcon: FC<SelectIconProps> = ({ color, size = 16, type = '', show = true, placement = 'left' }) => {
  return show ? (
    <S.SelectIcon placement={placement} className={`${placement}-icon`}>
      <Icon name={type} color={color} size={size} />
    </S.SelectIcon>
  ) : null;
};

interface SelectOptionProps {
  readonly icon?: SelectIconType;
  readonly children?: ReactNode;
  readonly selected: boolean;
  readonly onClick: () => void;
  readonly dataTestId?: string;
}

export const SelectOption: FC<SelectOptionProps> = ({ children, icon, selected, onClick, ...rest }) => (
  // eslint-disable-next-line jsx-a11y/role-supports-aria-props
  <li aria-selected={selected}>
    <S.SelectOption type="button" onClick={onClick} {...rest}>
      {children}
    </S.SelectOption>
    <SelectIcon placement="right" type="Check" color={treatwellProTheme.colors['teal.600']} show={selected} />
    <SelectIcon placement="right" type={icon?.type} show={Boolean(icon) && !selected} />
  </li>
);
