import { FunctionComponent, ReactElement, useCallback, useEffect, useState } from 'react';
import { MarketingPromotion } from 'models';
import { Icon, Text } from 'components/core';
import { StyledTrigger } from './Trigger.theme';
import moment from 'moment';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ModalActionDispatch } from 'actions/modals';

interface TriggerProps {
  marketing_promotion: MarketingPromotion;
  promo_flash_enabled: boolean;
  dispatchFetchFlashPromotions: () => Promise<void>;
  dispatchModalOpen: ModalActionDispatch;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
  shouldUpsell: boolean;
  testId?: string;
}

const Trigger: FunctionComponent<TriggerProps & RouteComponentProps> = ({
  dispatchFetchFlashPromotions,
  dispatchModalOpen,
  marketing_promotion,
  promo_flash_enabled,
  mobile,
  tablet,
  desktop,
  shouldUpsell,
  history,
  testId = 'promo-flash-trigger',
}): ReactElement | null => {
  const [countdown, setCountdown] = useState<number | null>(null);

  useEffect(() => {
    const requestPromotions = async (): Promise<void> => {
      try {
        await dispatchFetchFlashPromotions();
      } catch (_e) {
        // Currently used to prevent node from raising errors while running tests.
      }
    };

    requestPromotions();
  }, [dispatchFetchFlashPromotions]);

  useEffect(() => {
    let timer: number;

    const update = (): void => {
      setCountdown(marketing_promotion ? moment(marketing_promotion.calculated_valid_to).diff({}, 'seconds') : null);

      timer = window.setTimeout(update, 60000);
    };

    update();

    return (): void => clearTimeout(timer);
  }, [marketing_promotion]);

  const modalOpen = useCallback(() => {
    if (shouldUpsell) {
      return history.push('/promotions');
    }
    dispatchModalOpen({
      id: 'promo-flash',
      config: {
        component: 'promo_flash/PromoFlashEdit',
      },
    });
  }, [dispatchModalOpen, history, shouldUpsell]);

  if (!promo_flash_enabled) {
    return null;
  }

  return (
    <StyledTrigger onClick={modalOpen} mobile={mobile} tablet={tablet} desktop={desktop} data-testid={testId}>
      {marketing_promotion && countdown ? (
        <>
          <Icon type="flash" color="blue" />
          <Text block size={10} color="blue">
            {Math.floor(countdown / 3600)}h {Math.floor((countdown % 3600) / 60)}m
          </Text>
        </>
      ) : (
        <Icon type="flash" color="text1" />
      )}
    </StyledTrigger>
  );
};

export default withRouter(Trigger);
