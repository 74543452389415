import {
  VENUE_CUSTOMERS_WITH_APPOINTMENTS_REQUEST,
  VENUE_CUSTOMERS_WITH_APPOINTMENTS_RESPONSE,
  VENUE_CUSTOMER_CHECKIN_REQUEST,
  VENUE_CUSTOMER_CHECKIN_RESPONSE,
  VENUE_CUSTOMER_CHECKOUT_REQUEST,
  VENUE_CUSTOMER_CHECKOUT_RESPONSE,
} from 'actionTypes';

import moment from 'moment';
import { apiGet, apiPost, apiDataSet } from './data_providers/api';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { agendaResetCutItems, agendaSetActiveCustomer } from './agendaGrid';
import { modalOpen, modalClose } from './modals';
import { getCustomersNeedFetch } from 'selectors/getCustomers';
import canPersistCustomers from 'utils/check/canPersistCustomers';

const customersStartCompleteFetchAction = ({ venue_id }) => ({
  type: 'VENUE_CUSTOMERS_START_COMPLETE_FETCH',
  venue_id,
});

const customersFinishCompleteFetchAction = ({ venue_id }) => ({
  type: 'VENUE_CUSTOMERS_FINISH_COMPLETE_FETCH',
  venue_id,
});

export const fetchCustomerExtraFields = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(
      apiGet({
        path: `/extra_customer_fields.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return resolve({ error: response.info && response.info[0] });
          }
          resolve({ venue_id, extra_fields: response.data, response });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

const internalFetchCustomer = ({ id, dataSetObjectType }) => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth || !id || id < 0) {
      return;
    }

    // http://docs.uala.it/api/v1/customers
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/customers/${id}.json`,
        data: {
          with_booking_stats: true,
          with_redeemable_prizes_count: true,
          with_favorite_venue_treatments: true,
        },
        handleAllResponses: true,
        onResponse(response) {
          if (!response.success) {
            return;
          }

          if (response.data && response.data.customer) {
            /**
             * Override model already in store
             * we can do this because we are sure this response contains all the attributes of the customer
             */
            dispatch(
              apiDataSet({
                object_type: dataSetObjectType,
                object: response.data.customer,
              })
            );
          }
        },
        onError(error) {},
      })
    );
  };
};

export const fetchCustomer = (id) =>
  internalFetchCustomer({
    id,
    dataSetObjectType: 'customer',
  });

export const fetchCustomerDetails = (id) =>
  internalFetchCustomer({
    id,
    dataSetObjectType: 'internal_customer_details',
  });

export const fetchCustomers = (page) => {
  return (dispatch, getState) => {
    page = page || 1;
    const per_page = 2000;
    const fields =
      'id,full_name,first_name,last_name,phone,email,by_venue,terms_of_services_accepted_at,blacklisted_at';
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(customersStartCompleteFetchAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/customers
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/customers.json`,
        data: {
          per_page,
          page,
          fields,
        },
        auth: auth,
        onResponse(response, headers) {
          if (!response.success) {
            dispatch(customersFinishCompleteFetchAction({ venue_id }));
            setTimeout(() => dispatch(fetchCustomers(page)), 1000);
            return;
          }

          if (
            response.data &&
            response.data.customers.length &&
            headers['x-total'] &&
            parseFloat(headers['x-total']) > page * per_page
          ) {
            setTimeout(() => dispatch(fetchCustomers(page + 1)), 100);
          } else {
            dispatch(customersFinishCompleteFetchAction({ venue_id }));
          }
        },
        onError(error) {
          dispatch(customersFinishCompleteFetchAction({ venue_id }));
        },
      })
    );
  };
};

export const fetchCustomersIdsByCustomerTagIds = (customerTagIds) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const fields = 'id';
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    // http://docs.uala.it/api/v1/customers
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/customers.json`,
        data: {
          fields,
          with_tag_ids: customerTagIds.join(','),
        },
        auth: auth,
        onResponse(response, headers) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve(response.data.customers.map((customer) => customer.id));
        },
        onError(error) {
          reject({ error });
        },
        handleAllResponses: true,
      })
    );
  });

export const fetchCustomersIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());
    if (!venue_id || !auth) {
      return;
    }

    const need_fetch = getCustomersNeedFetch(getState());
    if (need_fetch) {
      return dispatch(fetchCustomers());
    }
  };
};

const customersWithValidAppointmentsAtRequestAction = ({ date, venue_id, auth }) => ({
  type: VENUE_CUSTOMERS_WITH_APPOINTMENTS_REQUEST,
  date,
  venue_id,
  auth,
});

const customersWithValidAppointmentsAtResponseAction = ({ date, error, venue_id, customers, response }) => ({
  type: VENUE_CUSTOMERS_WITH_APPOINTMENTS_RESPONSE,
  date,
  venue_id,
  customers,
  response,
  error,
});

export const customersWithValidAppointments =
  ({ from_time, to_time } = {}) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      if (canPersistCustomers()) return;

      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth) {
        return;
      }

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/customers.json`,
          data: {
            with_valid_appointments_from: from_time,
            with_valid_appointments_to: to_time,
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            resolve({ customers: response.data.customers });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

const customersWithValidAppointmentsAt__fetched = {};

export const customersWithValidAppointmentsAt = (date) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth || customersWithValidAppointmentsAt__fetched[date || 'today']) {
      return;
    }

    dispatch(customersWithValidAppointmentsAtRequestAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/customers
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/customers.json`,
        data: {
          with_valid_appointments_at: date || moment().format('YYYY-MM-DD'),
          with_redeemable_prizes_count: true,
        },
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            dispatch(customersWithValidAppointmentsAtResponseAction({ error: response.info && response.info[0] }));
            return reject({ error: response.info && response.info[0] });
          }

          customersWithValidAppointmentsAt__fetched[date || 'today'] = true;

          dispatch(
            customersWithValidAppointmentsAtResponseAction({ venue_id, customers: response.data.customers, response })
          );
          resolve({ customers: response.data.customers });
        },
        onError(error) {
          dispatch(customersWithValidAppointmentsAtResponseAction({ error }));
          reject({ error });
        },
      })
    );
  });

export const createCustomer =
  ({ customer }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer || !customer.full_name) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/customers.json`,
          data: {
            customer,
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(agendaSetActiveCustomer({ customer: response.data.customer }));
            resolve({ customer: response.data.customer });
          },
          onError(error) {
            if (
              error.error === 'CONFLICT' &&
              error.response &&
              error.response.data &&
              error.response.data.conflictual_customer
            ) {
              return dispatch(
                modalOpen({
                  id: 'customer-conflict',
                  config: {
                    component: 'customers/CustomerConflict',
                    error: error.response,
                    customer,
                    onSwapCustomer: () => {
                      resolve({ customer: error.response.data.conflictual_customer });
                    },
                    didCustomerUpdate: (updatedCustomer) => {
                      resolve({ customer: updatedCustomer });
                    },
                  },
                })
              );
            }

            reject({ error });
          },
        })
      );
    });

export const deleteCustomer =
  ({ id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !id) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiPost({
          method: 'DELETE',
          path: `/venues/${venue_id}/customers/${id}.json`,
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            resolve({ customer: response.data.customer });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

const updateCustomerRequestAction = ({ venue_id, auth }) => ({
  type: 'VENUE_UPDATE_CUSTOMER_REQUEST',
  venue_id,
  auth,
});

const updateCustomerResponseAction = ({ error, venue_id, customer, response }) => ({
  type: 'VENUE_UPDATE_CUSTOMER_RESPONSE',
  venue_id,
  customer,
  response,
  error,
});

export const updateCustomer =
  ({ customer }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer) {
        return reject({ code: 400 });
      }

      dispatch(updateCustomerRequestAction({ venue_id, auth }));

      const tag_names = customer.tags
        ? customer.tags.length
          ? customer.tags.map((tag) => tag.name || tag.value)
          : ['']
        : undefined;

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/customers/${customer.id}.json`,
          data: {
            customer: {
              ...customer,
              tag_names,
            },
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(updateCustomerResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(updateCustomerResponseAction({ venue_id, customer: response.data.customer, response }));
            resolve({ customer: response.data.customer });
          },
          onError(error) {
            if (
              error.error === 'CONFLICT' &&
              error.response &&
              error.response.data &&
              error.response.data.conflictual_customer
            ) {
              return dispatch(
                modalOpen({
                  id: 'customer-conflict',
                  config: {
                    component: 'customers/CustomerConflict',
                    error: error.response,
                    customer,
                    onSwapCustomer: () => {
                      resolve({ customer: error.response.data.conflictual_customer });
                    },
                    didCustomerUpdate: (updatedCustomer) => {
                      resolve({ customer: updatedCustomer });
                    },
                  },
                })
              );
            } else {
              dispatch(updateCustomerResponseAction({ error }));
              reject({ error });
            }
          },
        })
      );
    });

export const customerDebts =
  ({ customer_id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer_id) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/customers/${customer_id}/debts.json`,
          data: {},
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }
            resolve({ debts: response.data.debts });
          },
          onError(error) {
            return reject({ error });
          },
        })
      );
    });

const customerCheckinCheckoutRequestAction = ({ checkin, date, venue_id, auth }) => ({
  type: checkin ? VENUE_CUSTOMER_CHECKOUT_REQUEST : VENUE_CUSTOMER_CHECKIN_REQUEST,
  date,
  venue_id,
  auth,
});

const customerCheckinCheckoutResponseAction = ({ checkin, date, error, venue_id, customers, response }) => ({
  type: checkin ? VENUE_CUSTOMER_CHECKIN_RESPONSE : VENUE_CUSTOMER_CHECKOUT_RESPONSE,
  date,
  venue_id,
  customers,
  response,
  error,
});

export const customerCheckinCheckout = ({ checked_in_at, checked_out_at, customer_id, time_no_tz }) => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const checkin = !checked_in_at;

    if (!venue_id) return;

    dispatch(customerCheckinCheckoutRequestAction({ checkin, time_no_tz, venue_id }));

    // http://docs.uala.it/api/v1/customers
    dispatch(
      apiPost({
        method: 'PUT',
        path: `/venues/${venue_id}/customers/${customer_id}/${checkin ? 'check_in' : 'check_out'}.json`,
        data: {
          date: moment(time_no_tz || {}).format('YYYY-MM-DD'),
        },
        onResponse(response) {
          if (!response.success) {
            return dispatch(
              customerCheckinCheckoutResponseAction({ checkin, error: response.info && response.info[0] })
            );
          }
          dispatch(
            customerCheckinCheckoutResponseAction({ checkin, venue_id, customer: response.data.customer, response })
          );
        },
        onError(error) {
          dispatch(customerCheckinCheckoutResponseAction({ checkin, error }));
        },
      })
    );
  };
};

export const customerCheckInOut =
  ({ customer_id, appointment_id, checked_in_at, checked_out_at }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id || (!customer_id && !appointment_id) || (!checked_in_at && !checked_out_at)) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiPost({
          method: 'PUT',
          path: customer_id
            ? `/venues/${venue_id}/customers/${customer_id}/${checked_in_at ? 'check_in' : 'check_out'}.json`
            : appointment_id
            ? `/venues/${venue_id}/appointments/${appointment_id}/${checked_in_at ? 'check_in' : 'check_out'}.json`
            : null,
          data: {
            date: moment(checked_in_at || checked_out_at || {}).format('YYYY-MM-DD'),
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }
            resolve({ customer: response.data.customer });
          },
          onError(error) {
            return reject({ error });
          },
        })
      );
    });

export const customerRevertCheckin =
  ({ customer_id, date }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id || !customer_id) {
        return reject({ code: 400 });
      }

      // https://api-docs.uala.dev/#tag/v1_venue_customers/paths/~1v1~1venues~1{venue_id}~1customers~1{id}~1revert_check_in/put
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/customers/${customer_id}/revert_check_in.json`,
          data: {
            date: moment(date || {}).format('YYYY-MM-DD'),
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }
            resolve({ customer: response.data.customer });
          },
          onError(error) {
            return reject({ error });
          },
        })
      );
    });

const searchCustomersRequestAction = ({ venue_id, auth, query }) => ({
  type: 'VENUE_SEARCH_CUSTOMERS_REQUEST',
  venue_id,
  auth,
  query,
});

const searchCustomersResponseAction = ({ error, venue_id, query, customers, response }) => ({
  type: 'VENUE_SEARCH_CUSTOMERS_RESPONSE',
  venue_id,
  query,
  customers,
  response,
  error,
});

export const searchCustomer =
  ({ query, fields, per_page = 20, page = 1, with_favorite_venue_treatments = null }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !query) {
        return reject({ code: 400 });
      }

      dispatch(searchCustomersRequestAction({ venue_id, auth }));

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/customers/search.json`,
          data: {
            q: query,
            per_page,
            page,
            fields,
            with_favorite_venue_treatments,
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(searchCustomersResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(searchCustomersResponseAction({ venue_id, customers: response.data.customers, response }));
            resolve({ customers: response.data.customers });
          },
          onError(error) {
            dispatch(searchCustomersResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

const customerMoveAppointmentsRequestAction = ({ venue_id, auth, customer_id, from_date, to_date, to_time }) => ({
  type: 'VENUE_CUSTOMER_MOVE_APPOINTMENTS_REQUEST',
  venue_id,
  auth,
  customer_id,
  from_date,
  to_date,
  to_time,
});

const customerMoveAppointmentsResponseAction = ({
  error,
  venue_id,
  customer_id,
  from_date,
  to_date,
  to_time,
  response,
}) => ({
  type: 'VENUE_CUSTOMER_MOVE_APPOINTMENTS_RESPONSE',
  venue_id,
  customer_id,
  from_date,
  to_date,
  to_time,
  response,
  error,
});

export const customerMoveAppointments =
  ({ customer_id, from_date, to_date, to_time }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer_id || !from_date || !to_date || !to_time) {
        return reject({ code: 400 });
      }

      dispatch(customerMoveAppointmentsRequestAction({ venue_id, auth, customer_id, from_date, to_date, to_time }));

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/customers/${customer_id}/move_appointments.json`,
          data: {
            from_date,
            to_date,
            to_time,
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(customerMoveAppointmentsResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(
              customerMoveAppointmentsResponseAction({
                venue_id,
                customers: response.data.customers,
                response,
                customer_id,
                from_date,
                to_date,
                to_time,
              })
            );
            dispatch(agendaResetCutItems());
            dispatch(modalClose({ id: 'customer-move-appointments' }));
            resolve({ customers: response.data.customers });
          },
          onError(error) {
            dispatch(customerMoveAppointmentsResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

const customerHistoryResponseAction = ({
  error,
  venue_id,
  customer_id,
  from_date,
  to_date,
  page,
  per_page,
  response,
}) => ({
  type: 'VENUE_CUSTOMER_HISTORY',
  venue_id,
  customer_id,
  from_date,
  to_date,
  page,
  per_page,
  response,
  error,
});

export const fetchCustomerHistory =
  ({
    customer_id,
    treatment_category_id,
    product_elements_filter,
    from_date,
    to_date,
    page,
    per_page,
    canceled_elements_only,
    missed_elements_only,
    with_archived = false,
  }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer_id) {
        return reject({ code: 400 });
      }

      if (!page) {
        page = 1;
      }
      if (!per_page) {
        per_page = 10;
      }

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/customers/${customer_id}/history.json`,
          data: {
            with_archived,
            per_page,
            page,
            ...(from_date ? { from_date } : null),
            ...(to_date ? { to_date } : null),
            ...(treatment_category_id &&
            treatment_category_id !== 'all' &&
            treatment_category_id !== 'all-treatments' &&
            treatment_category_id !== 'all-products' &&
            treatment_category_id !== 'all-promotions'
              ? { treatment_category_id }
              : null),
            product_elements_only: treatment_category_id === 'all-products',
            promotion_elements_only: treatment_category_id === 'all-promotions',
            treatment_elements_only: treatment_category_id === 'all-treatments',
            canceled_elements_only,
            missed_elements_only,
          },
          auth: auth,
          onResponse(response, headers) {
            if (!response.success) {
              dispatch(customerHistoryResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(
              customerHistoryResponseAction({
                venue_id,
                response,
                customer_id,
                treatment_category_id,
                from_date,
                to_date,
                page,
                per_page,
              })
            );
            resolve({
              history: response.data.history,
              totalItems: (headers && parseFloat(headers['x-total'])) || null,
            });
          },
          onError(error) {
            dispatch(customerHistoryResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

/**
 * @see: https://docs.uala.it/api/v1/customers#get-venuesvenueidcustomerscustomeridfichedatajson
 */
export const fetchFicheData =
  ({ appointment_date, customer_id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !appointment_date || !customer_id) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/customers
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/customers/${customer_id}/fiche_data.json`,
          data: {
            date: appointment_date,
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            resolve({ data: response.data });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const fetchCustomerWeights =
  ({ customer_id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer_id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiGet({
          path: `/venues/${venue_id}/customers/${customer_id}/customer_weights.json`,
          auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            // Safely deconstruct the API response
            const { data: { customer_weights = [] } = {} } = response;

            resolve({ customer_weights });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const addCustomerWeight =
  ({ customer_id, weight, date }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer_id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiPost({
          path: `/venues/${venue_id}/customers/${customer_id}/customer_weights.json`,
          auth,
          data: {
            value: weight,
            date,
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            // Safely deconstruct the API response
            const { data: { customer_weight = {} } = {} } = response;

            resolve({ customer_weight });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const updateCustomerWeight =
  ({ customer_id, weight_id, value }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer_id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/customers/${customer_id}/customer_weights/${weight_id}.json`,
          auth,
          data: {
            value,
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            // Safely deconstruct the API response
            const { data: { customer_weight = {} } = {} } = response;

            resolve({ customer_weight });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });
