import {
  CHANGE_LOCALE,
  VENUE_PRODUCTS_LIST_REQUEST,
  VENUE_PRODUCTS_LIST_RESPONSE,
  API_GET_RESPONSE,
  API_POST_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import { VenueProduct } from 'models';

interface Action {
  type: string;
  error?: object | boolean;
  venue_id: number;
  venue_products?: VenueProduct[];
  response?: {
    data: {
      venue_product?: VenueProduct;
      venue_products?: VenueProduct[];
    };
  };
}

type VenueState = {
  items?: VenueProduct[];
  isFetching?: boolean | number;
  didInvalidate?: boolean;
  completeFetchStarted?: boolean;
  completeFetchFinished?: boolean;
};

type State = { [key: number]: VenueState };

const emptyList: VenueProduct[] = [];

const getInitialState = (): VenueState => {
  return {
    ...reducerValuesOnInit(),
    completeFetchStarted: false,
    completeFetchFinished: false,
    items: emptyList,
  };
};

const venueProducts = (state: VenueState = getInitialState(), action: Action): VenueState => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return getInitialState();

    case VENUE_PRODUCTS_LIST_REQUEST:
      return {
        ...state,
        isFetching: true,
        completeFetchStarted: true,
        completeFetchFinished: false,
      };

    case VENUE_PRODUCTS_LIST_RESPONSE:
      return {
        ...state,
        isFetching: false,
        completeFetchStarted: false,
        completeFetchFinished: true,
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        (!action.response.data.venue_product &&
          (!action.response.data.venue_products || !action.response.data.venue_products.length))
      ) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          [
            ...(state.items || []),
            ...(action.response.data.venue_products || []),
            action.response.data.venue_product,
          ].filter((a) => a),
          {
            onlyIds: true,
            avoidDuplicateIds: true,
          }
        ),
      };

    default:
      return state;
  }
};

export const venueProductsByVenue = (state: State = {}, action: Action): State => {
  let refState;

  switch (action.type) {
    case CHANGE_LOCALE:
    case VENUE_PRODUCTS_LIST_REQUEST:
    case VENUE_PRODUCTS_LIST_RESPONSE:
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = venueProducts(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
