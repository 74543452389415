import { AvailableLocales } from 'providers/IntlProvider';
import { ValueOf } from 'utils/utilityTypes';
import { CancellationMode } from './checkout';
import { Vat } from './vat';

export interface DefaultDomain {
  default_locale: string;
  locales: string[];
  name: string;
  url: string;
  manager_url: string;
}

export enum COUNTRY_FEATURE_FLAG {
  SELF_PROVISIONING_ENABLED = 'self_provisioning_enabled',
}

export const CountryCodes = {
  IT: 'it',
  ES: 'es',
  FR: 'fr',
  PT: 'pt',
  CH: 'ch',
  GR: 'gr',
  BE: 'be',
  DE: 'de',
  /**
   * @deprecated
   * @see CountryCodes['GB']
   */
  UK: 'uk',
  IE: 'ie',
  AT: 'at',
  LT: 'lt',
  NL: 'nl',
  GB: 'gb',
} as const;

export interface Country {
  id: number;
  fiscality_enabled: boolean;
  split_vat_in_receipts: boolean;
  b2b_event_tracking_key?: string;
  separated_cancellation_counters: boolean;
  checkout_reprint_enabled: boolean;
  code: ValueOf<typeof CountryCodes>;
  available_vats: Vat[];
  default_domain?: DefaultDomain;
  default_locale?: AvailableLocales;
  self_provisioning_enabled: boolean;
  platform_version: string;
  patch_test_enabled?: boolean;
  nf525_certificate?: {
    category: string;
    number: number;
    full_number: string;
    file_url: string;
  };
  pt_pos_certificate?: {
    number: string;
  };
  checkout_cancellation_mode: CancellationMode;
}
