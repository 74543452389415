import { FunctionComponent, memo, useEffect, useState } from 'react';
import { Text, Section } from 'components/core';
import { Wrapper, VenueImage, VenueText, SynchedItem } from './VenueButton.theme';
import { Venue } from 'models';
import { Session } from 'reducers/sessions';
import moment from 'moment';

const ACTIVE_SYNCH_TIMEOUT = 60 * 1000;

export const dataTestIds = {
  logo: 'venue-button-picture',
};

export type VenueButtonProps = {
  venueId?: number;
  updatedSinceByVenue?: Session['updatedSinceByVenue'];
  imageSrc?: string | null;
  venueShortName?: string | null;
  venueFullName?: Venue['name'];
  isVenueSelected?: boolean;
  isDisabled?: boolean;
  isShortNameHidden?: boolean;
  dispatchSwitchCurrentVenue: (args: { venue_id: number }) => void;
  dispatchToggleMultiVenueSidebar: (params: boolean) => void;
};

const VenueButton: FunctionComponent<VenueButtonProps> = memo(
  ({
    venueId,
    updatedSinceByVenue,
    isVenueSelected = false,
    isDisabled = false,
    venueShortName,
    venueFullName,
    imageSrc,
    isShortNameHidden = false,
    dispatchSwitchCurrentVenue,
    dispatchToggleMultiVenueSidebar,
  }) => {
    const [isSynchActive, setIsSynchActive] = useState(false);
    const updatedSince = venueId && updatedSinceByVenue && updatedSinceByVenue[venueId];
    useEffect(() => {
      if (!updatedSince) {
        setIsSynchActive(false);
        return;
      }
      const diff = moment().diff(updatedSince, 'milliseconds');
      if (diff > ACTIVE_SYNCH_TIMEOUT) {
        setIsSynchActive(false);
        return;
      }
      setIsSynchActive(true);
      const timer = setTimeout(() => setIsSynchActive(false), ACTIVE_SYNCH_TIMEOUT - diff);
      return () => clearTimeout(timer);
    }, [updatedSince]);

    const onClick =
      isDisabled || isShortNameHidden
        ? null
        : () => {
            dispatchSwitchCurrentVenue({ venue_id: Number(venueId) });
            dispatchToggleMultiVenueSidebar(false);
          };

    return (
      <Wrapper
        className="VenueButton"
        onClick={onClick}
        title={venueFullName}
        isSelected={!isShortNameHidden && isVenueSelected}
        isDisabled={isDisabled}
      >
        <VenueImage
          data-testid={dataTestIds.logo}
          className="VenueButton__Image"
          src={imageSrc}
          isSelected={!isShortNameHidden && isVenueSelected}
        >
          {!isShortNameHidden && <SynchedItem isActive={isSynchActive} />}
          {!imageSrc && venueShortName && (
            <Text size={16} color="text2" bold uppercase>
              {venueShortName}
            </Text>
          )}
        </VenueImage>
        {venueShortName && !isShortNameHidden && (
          <Section flex justifyContent="center" width="100%" paddingTop={0.4}>
            <VenueText>{venueShortName}</VenueText>
          </Section>
        )}
      </Wrapper>
    );
  }
);

export default VenueButton;
