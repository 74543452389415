import { API_GET_RESPONSE, API_POST_RESPONSE } from 'actionTypes';
import { OrderDeveliveryNote } from 'models';
import { reducerValuesOnInit, reducerValuesOnResponse } from 'utils/reducersUtils';
import { OrderDeliveryNotesAction } from 'actions/orderDeliveryNotes';

type OrderDeveliveryNotesState = {
  items?: OrderDeveliveryNote[];
  isFetching?: boolean | number;
  didInvalidate?: boolean;
};

type OrderDeliveryNotesByVenue = { [venue_id: number]: OrderDeveliveryNotesState };

function getValuesOnResponse(
  state: OrderDeveliveryNotesState,
  action: OrderDeliveryNotesAction
): OrderDeveliveryNotesState {
  return {
    ...state,
    ...reducerValuesOnResponse(
      [
        ...(state.items || []),
        ...(action.response?.data?.order_delivery_notes ||
          [action.response?.data?.order_delivery_note].filter((order) => Boolean(order))),
      ],
      {
        overrideDuplicateIds: 1,
        onlyIds: true,
      }
    ),
  };
}

const orderDeliveryNotes = (
  state: OrderDeliveryNotesByVenue = reducerValuesOnInit(),
  action: OrderDeliveryNotesAction
): OrderDeveliveryNotesState => {
  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (!action.venue_id) {
        return state;
      }

      return getValuesOnResponse(state, action);
    default:
      return state;
  }
};

export const orderDeliveryNotesByVenue = (
  state: OrderDeliveryNotesByVenue = {},
  action: OrderDeliveryNotesAction
): OrderDeveliveryNotesState => {
  let refState: OrderDeliveryNotesByVenue;

  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      refState = orderDeliveryNotes(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };
    default:
      return state;
  }
};
