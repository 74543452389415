import { createContext } from 'react';

export type AvailableLocales = 'it' | 'en' | 'es' | 'ca' | 'el' | 'fr' | 'pt' | 'de' | 'lt' | 'nl' | 'zh' | 'sl' | 'ru';

type ContextType = {
  locale: AvailableLocales;
  setLocale: (locale: AvailableLocales) => void;
};

const DEFAULT_LOCALE = 'en';

const { Provider: LocaleProvider, Consumer: LocaleConsumer } = createContext<ContextType>({
  locale: DEFAULT_LOCALE,
  setLocale: () => {},
});

export { LocaleConsumer, LocaleProvider, DEFAULT_LOCALE };
