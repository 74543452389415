/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { VenueProductCorrectStockResponse, VenueProductCorrectStockPayload } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const CORRECT_STOCK =
  ({ variables }: V<VenueProductCorrectStockPayload['venue_product']>) =>
  (): Promise<VenueProductCorrectStockResponse> =>
    Http()
      .put<VenueProductCorrectStockResponse, never, VenueProductCorrectStockPayload>(
        ENDPOINT.VENUE_PRODUCTS._CORRECT_STOCK(variables.id ?? 0)
      )({
        body: { venue_product: variables, next: true },
      })
      .then(maybeResponseToJSON);
