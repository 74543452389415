import { Component } from 'react';
import PropTypes from 'prop-types';
import { Input } from './theme';

class InputComponent extends Component {
  state = {
    value: this.props.value || '',
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  render() {
    const { children } = this.props;
    const { value } = this.state;

    return (
      <Input onChange={this.handleChange} value={value} {...this.props}>
        {children}
      </Input>
    );
  }
}

InputComponent.displayName = 'Input';

InputComponent.defaultProps = {
  disabled: false,
  type: '',
  size: '',
};

InputComponent.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.node,
};

export default InputComponent;
