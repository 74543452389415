import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { apiPost, Response, apiGet } from '../data_providers/api';
import { ParametricActionCreator, ActionCreator } from '../types';
import {
  VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE,
  VENUE_MARKETING_PROMOTIONS_LIST_REQUEST,
  VENUE_MARKETING_PROMOTIONS_TYPES_LIST_REQUEST,
  VENUE_MARKETING_PROMOTIONS_TYPES_LIST_RESPONSE,
} from 'actionTypes';
import { MarketingPromotion, MarketingPromotionType } from 'models';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { actionShouldFetchData } from 'utils/reducersUtils';
import { addStartDateInPromotionIfMissing, getMarketingPromotionData } from 'actions/promotions.utils';
import { IntlShape } from 'react-intl/src/types';

/**
 * MARKETING
 */
export type DeleteMarketingPromotion<R> = ParametricActionCreator<{ id: number }, R>;
export const deleteMarketingPromotionWithAssociatedCustomers: DeleteMarketingPromotion<Promise<void>> =
  ({ id }) =>
  (dispatch, getState): Promise<void> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });

      dispatch(
        apiPost({
          method: 'DELETE',
          path: `/venues/${venue_id}/marketing_promotions/${id}/delete_for_everyone.json`,
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });
            resolve();
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const deleteMarketingPromotion: DeleteMarketingPromotion<Promise<void>> =
  ({ id }) =>
  (dispatch, getState): Promise<void> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });

      dispatch(
        apiPost({
          method: 'DELETE',
          path: `/venues/${venue_id}/marketing_promotions/${id}.json`,
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });
            resolve();
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const fetchMarketingPromotionTypesIfNeeded: ActionCreator<void> =
  () =>
  (dispatch, getState): Promise<void> | void => {
    const venue_id = getCurrentVenueId(getState());
    if (!venue_id) return new Promise((resolve) => resolve());
    if (actionShouldFetchData(getState()?.promotionsTypesByVenue[venue_id])) dispatch(fetchMarketingPromotionTypes());
  };

export const associateMarketingPromotion: ParametricActionCreator<
  {
    customer_id?: number;
    marketing_promotion_id?: number;
    serial_number?: number;
    resources_count_already_used?: number;
    redemption_amount_used_cents?: number;
  },
  Promise<{ marketing_promotion: MarketingPromotion; response: Response }>
> =
  ({
    customer_id,
    marketing_promotion_id,
    serial_number,
    resources_count_already_used,
    redemption_amount_used_cents,
  }) =>
  (dispatch, getState): Promise<{ marketing_promotion: MarketingPromotion; response: Response }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/marketing_promotions/associate.json`,
          data: {
            marketing_promotion_id,
            customer_id,
            serial_number,
            resources_count_already_used,
            redemption_amount_used_cents,
          },
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });

            resolve({ marketing_promotion: response.data.marketing_promotion, response });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

type FetchMarketingPromotionTypesResponse = {
  marketing_promotion_types?: Array<MarketingPromotionType>;
  venue_id?: number;
};

const fetchMarketingPromotionTypesRequestAction = ({
  venue_id,
  auth,
}: FetchMarketingPromotionTypesResponse & { auth: string }): FetchMarketingPromotionTypesResponse & {
  type: typeof VENUE_MARKETING_PROMOTIONS_TYPES_LIST_REQUEST;
  auth: string;
} => ({
  type: VENUE_MARKETING_PROMOTIONS_TYPES_LIST_REQUEST,
  venue_id,
  auth,
});

const fetchMarketingPromotionTypesResponseAction = ({
  error,
  venue_id,
  marketing_promotion_types,
  response,
}: FetchMarketingPromotionTypesResponse & {
  error?: unknown;
  response?: unknown;
}): FetchMarketingPromotionTypesResponse & {
  error?: unknown;
  response?: unknown;
  type: typeof VENUE_MARKETING_PROMOTIONS_TYPES_LIST_RESPONSE;
} => ({
  type: VENUE_MARKETING_PROMOTIONS_TYPES_LIST_RESPONSE,
  venue_id,
  marketing_promotion_types,
  response,
  error,
});

export const fetchMarketingPromotionTypes: ActionCreator<Promise<FetchMarketingPromotionTypesResponse>> =
  () =>
  (dispatch, getState): Promise<FetchMarketingPromotionTypesResponse> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!auth || !venue_id) return reject({ code: 400 });
      dispatch(fetchMarketingPromotionTypesRequestAction({ venue_id, auth }));

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/marketing_promotions/types.json`,
          onResponse(response) {
            if (!response.success) {
              dispatch(fetchMarketingPromotionTypesResponseAction({ error: response?.info?.[0] }));
              return reject({ error: response?.info?.[0] });
            }

            dispatch(
              fetchMarketingPromotionTypesResponseAction({
                venue_id,
                marketing_promotion_types: response.data.marketing_promotion_types,
                response,
              })
            );
            resolve({ marketing_promotion_types: response.data.marketing_promotion_types });
          },
          onError(error) {
            dispatch(fetchMarketingPromotionTypesResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const updateMarketingPromotion: ParametricActionCreator<
  {
    id: number;
    changes: {
      archived: boolean;
    };
  },
  Promise<{ marketing_promotion?: MarketingPromotion; response?: Response }>
> =
  ({ id, changes }) =>
  (dispatch, getState): Promise<{ marketing_promotion?: MarketingPromotion; response?: Response }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth) return reject({ code: 400 });

      const marketing_promotion = getMarketingPromotionData(changes);

      if (marketing_promotion?.included_resources_attributes?.length <= 0) return reject({ code: 400 });

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/marketing_promotions/${id}.json`,
          data: {
            marketing_promotion,
          },
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });
            resolve({ marketing_promotion: response.data.marketing_promotion, response });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const createMarketingPromotion: ParametricActionCreator<
  {
    changes: unknown;
  },
  Promise<{ marketing_promotion?: MarketingPromotion; response?: Response }>
> =
  ({ changes }) =>
  (dispatch, getState): Promise<{ marketing_promotion?: MarketingPromotion; response?: Response }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });

      const marketing_promotion = addStartDateInPromotionIfMissing(getMarketingPromotionData(changes));

      if (
        !marketing_promotion.included_resources_attributes ||
        marketing_promotion.included_resources_attributes.length <= 0
      ) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/marketing_promotions.json`,
          data: {
            marketing_promotion,
          },
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });
            resolve({ marketing_promotion: response.data.marketing_promotion, response });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const redeemMarketingPromotion: ParametricActionCreator<
  {
    customer_id: number;
    code: string;
    intl: IntlShape;
  },
  Promise<{ marketing_promotion?: MarketingPromotion; response?: Response; checkout_needed?: boolean }>
> =
  ({ customer_id, code }) =>
  (
    dispatch,
    getState
  ): Promise<{ marketing_promotion?: MarketingPromotion; response?: Response; checkout_needed?: boolean }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/marketing_promotions/redeem.json`,
          data: {
            code,
            customer_id,
          },
          customErrorResponseStrategy: (response) => {
            if (response.error && response.error.code === 2001 && response?.error?.data?.marketing_promotion) {
              resolve({ marketing_promotion: response?.error?.data?.marketing_promotion, checkout_needed: true });
            } else {
              reject({ error: response.error, info: response?.info?.[0] });
            }
          },
          handleAllErrors: true,
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });
            resolve({ marketing_promotion: response.data.marketing_promotion, response });
          },
          onError(error) {
            reject(error);
          },
        })
      );
    });

/**
 * PROMOTIONS
 */
type FetchPromotionsResponseAction = {
  error?: unknown;
  venue_id?: number;
  customer_id?: number;
  marketing_promotions?: Array<MarketingPromotion>;
  response?: Response;
  skip_update?: boolean;
};

const fetchPromotionsResponseAction = ({
  error,
  venue_id,
  customer_id,
  marketing_promotions,
  response,
  skip_update = false,
}: FetchPromotionsResponseAction): FetchPromotionsResponseAction & {
  type: typeof VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE;
} => ({
  type: VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE,
  venue_id,
  customer_id,
  marketing_promotions,
  response,
  skip_update,
  error,
});

export type FetchPromotionResponse = Response['data']['marketing_promotion'];
export const fetchPromotion: ParametricActionCreator<
  { id?: number; with_associated_customers?: boolean },
  Promise<{ marketing_promotion: FetchPromotionResponse }>
> =
  ({ id, with_associated_customers } = {}) =>
  (dispatch, getState): Promise<{ marketing_promotion: Response['data']['marketing_promotion'] }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/marketing_promotions/${id}.json`,
          data: {
            with_associated_customers,
          },
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });

            dispatch(
              fetchPromotionsResponseAction({
                venue_id,
                response,
                skip_update: true,
                marketing_promotions: [].concat(
                  (response.data.marketing_promotion as never) || []
                ) as Array<MarketingPromotion>,
              })
            );
            resolve({ marketing_promotion: response.data.marketing_promotion });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

type FetchPromotionsRequestAction = {
  readonly venue_id: number;
  readonly auth: string;
  readonly customer_id?: number;
};
const fetchPromotionsRequestAction = ({
  venue_id,
  auth,
  customer_id,
}: FetchPromotionsRequestAction): FetchPromotionsRequestAction & {
  type: typeof VENUE_MARKETING_PROMOTIONS_LIST_REQUEST;
} => ({
  type: VENUE_MARKETING_PROMOTIONS_LIST_REQUEST,
  venue_id,
  auth,
  customer_id,
});

export const fetchPromotions: ParametricActionCreator<
  {
    customer_id?: number;
    parent_marketing_promotion_id?: number;
    with_associated_customers?: boolean;
    per_page?: number;
    page?: number;
  },
  Promise<{ marketing_promotions: Array<MarketingPromotion> }>
> =
  ({ customer_id, per_page = 1000, page = 1, parent_marketing_promotion_id, with_associated_customers } = {}) =>
  (
    dispatch,
    getState
  ): Promise<{
    marketing_promotions: Array<MarketingPromotion>;
  }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!auth || !venue_id) return reject({ code: 400 });

      dispatch(fetchPromotionsRequestAction({ venue_id, auth, customer_id }));

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/marketing_promotions.json`,
          data: {
            customer_id,
            parent_marketing_promotion_id,
            with_associated_customers,
            per_page,
            page,
          },
          onResponse(response) {
            if (!response.success) {
              dispatch(fetchPromotionsResponseAction({ error: response?.info?.[0] }));
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              fetchPromotionsResponseAction({
                venue_id,
                customer_id,
                marketing_promotions: response.data.marketing_promotions,
                response,
              })
            );
            resolve({ marketing_promotions: response.data.marketing_promotions });
          },
          onError(error) {
            dispatch(fetchPromotionsResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const fetchCustomerPromotions: ParametricActionCreator<
  { customer_id?: number },
  Promise<{ marketing_promotions: Array<MarketingPromotion> }>
> =
  ({ customer_id } = {}) =>
  (dispatch, getState): Promise<{ marketing_promotions: Array<MarketingPromotion> }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!auth || !venue_id || !customer_id) {
        return reject({ code: 400 });
      }

      dispatch(fetchPromotions({ customer_id })).then(resolve).catch(reject);
    });

export const fetchChildPromotions: ParametricActionCreator<
  { parent_marketing_promotion_id?: number; page?: number },
  Promise<{ marketing_promotions: Array<MarketingPromotion> }>
> =
  ({ parent_marketing_promotion_id, page = 1 } = {}) =>
  (dispatch, getState): Promise<{ marketing_promotions: Array<MarketingPromotion> }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id || !parent_marketing_promotion_id) return reject({ code: 400 });

      dispatch(fetchPromotions({ parent_marketing_promotion_id, with_associated_customers: true, page, per_page: 100 }))
        .then(resolve)
        .catch(reject);
    });

export const fetchPromotionsIfNeeded: ActionCreator<void> = () => {
  return (dispatch, getState): void => {
    const venue_id = getCurrentVenueId(getState());
    if (!venue_id) return;

    if (actionShouldFetchData(getState()?.promotionsByVenue[venue_id])) dispatch(fetchPromotions({}));
  };
};

/**
 * FLASH
 */
export const fetchFlashPromotions: ActionCreator<void> =
  () =>
  (
    dispatch,
    getState
  ): Promise<{
    response: Response;
  }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/marketing_promotions/flash.json`,
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });

            dispatch(
              fetchPromotionsResponseAction({
                venue_id,
                marketing_promotions: response?.data?.marketing_promotions || [],
                response,
                skip_update: true,
              })
            );
            resolve({ response });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const createFlashPromotion: ParametricActionCreator<
  { marketing_promotion: MarketingPromotion },
  Promise<{
    marketing_promotion: MarketingPromotion;
    response: Response;
  }>
> =
  ({ marketing_promotion }) =>
  (
    dispatch,
    getState
  ): Promise<{
    marketing_promotion: MarketingPromotion;
    response: Response;
  }> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });
      if (!marketing_promotion?.included_resources_attributes) return reject({ code: 400 });

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/marketing_promotions/flash.json`,
          data: {
            marketing_promotion,
          },
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });
            resolve({ marketing_promotion: response.data.marketing_promotion, response });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const deleteFlashPromotion: ActionCreator<unknown> =
  () =>
  (dispatch, getState): Promise<unknown> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      if (!venue_id) return reject({ code: 400 });

      // http://docs.uala.it/api/v1/marketing_promotions
      dispatch(
        apiPost({
          method: 'DELETE',
          path: `/venues/${venue_id}/marketing_promotions/flash.json`,
          onResponse(response) {
            if (!response.success) return reject({ error: response?.info?.[0] });

            resolve({ marketing_promotion: response.data.marketing_promotion, response });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });
