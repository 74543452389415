import './polyfills';
import 'intl';

import { attach } from 'utils/fetch-interceptor';

import ReactDOM from 'react-dom';
import moment from 'moment';
import { init } from '@sentry/react';
import ENV, { NODE_ENV } from 'env';
import { AvailableLocales } from 'providers/IntlProvider';
import { startTWPROAnalyticsTrackingOnDOMReady } from 'utils/tracking/trackingObservations';
import 'utils/fixIntercomLunch';
/* eslint-disable @typescript-eslint/no-var-requires */

attach(window);

if (ENV.UALA_SENTRY_DSN && (process.env.NODE_ENV === NODE_ENV.PROD || process.env.REACT_APP_FORCE_SENTRY)) {
  init({
    dsn: ENV.UALA_SENTRY_DSN,
    release: 'uala-web-b2b@' + require('version.json').version,
    environment: ENV.UALA_SENTRY_ENV || 'undefined',
    integrations: [],
    tracesSampleRate: 0.2,
  });
}

// TODO: #TWPRO-10257 use require.context to get all the supported languages from translations https://wahanda.atlassian.net/browse/TWPRO-10257
const storedLocale = window.localStorage && window.localStorage.getItem && window.localStorage.getItem('uala-locale');
window.APP_LOCALE = (storedLocale || navigator.language.toLowerCase().split(/[_-]+/)[0]) as AvailableLocales;
window.UALA_ELECTRONIC_INVOICE_RECIPIENT_CODE = 'M5UXCR1';

if (window.APP_LOCALE === 'it') {
  require('moment/locale/it');
  moment.updateLocale('it', null);
}

if (window.APP_LOCALE === 'es') {
  require('moment/locale/es');
  moment.updateLocale('es', null);
}

if (window.APP_LOCALE === 'ca') {
  require('moment/locale/ca');
  moment.updateLocale('ca', null);
}

if (window.APP_LOCALE === 'pt') {
  require('moment/locale/pt');
  moment.updateLocale('pt', null);
}

if (window.APP_LOCALE === 'el') {
  require('moment/locale/el');
  moment.updateLocale('el', null);
}

if (window.APP_LOCALE === 'fr') {
  require('moment/locale/fr');
  moment.updateLocale('fr', null);
}

if (window.APP_LOCALE === 'de') {
  require('moment/locale/de');
  moment.updateLocale('de', null);
}

if (window.APP_LOCALE === 'nl') {
  require('moment/locale/nl');
  moment.updateLocale('nl', null);
}

if (window.APP_LOCALE === 'lt') {
  require('moment/locale/lt');
  moment.updateLocale('lt', null);
}

if (window.APP_LOCALE === 'zh') {
  require('moment/locale/zh-cn');
  moment.updateLocale('zh-cn', null);
}
if (window.APP_LOCALE === 'sl') {
  require('moment/locale/sl');
  moment.updateLocale('sl', null);
}

if (window.APP_LOCALE === 'ru') {
  require('moment/locale/ru');
  moment.updateLocale('ru', null);
}

const rootEl = document.getElementById('root');
const App = require('./App').default;

ReactDOM.render(<App />, rootEl);
startTWPROAnalyticsTrackingOnDOMReady();

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    ReactDOM.render(<NextApp />, rootEl);
  });
}
