import {
  VENUE_VACANCIES_REQUEST,
  VENUE_VACANCIES_RESPONSE,
  VENUE_EXTRA_OPENINGS_REQUEST,
  VENUE_EXTRA_OPENINGS_RESPONSE,
  VENUE_CREATE_EXTRA_OPENINGS_BULK_RESPONSE,
  VENUE_CREATE_EXTRA_OPENINGS_RESPONSE,
  VENUE_UPDATE_EXTRA_OPENINGS_RESPONSE,
  VENUE_DELETE_EXTRA_OPENINGS_RESPONSE,
  VENUE_CUSTOM_TIME_TABLES_REQUEST,
  VENUE_CUSTOM_TIME_TABLES_RESPONSE,
  VENUE_CREATE_VACANCY_RESPONSE,
  VENUE_UPDATE_VACANCY_RESPONSE,
  VENUE_DELETE_VACANCY_RESPONSE,
  VENUE_CREATE_CUSTOM_TIME_TABLES_RESPONSE,
  VENUE_UPDATE_CUSTOM_TIME_TABLES_RESPONSE,
  VENUE_DELETE_CUSTOM_TIME_TABLES_RESPONSE,
  API_GET_RESPONSE,
  API_POST_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

const venueVacancies = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_VACANCIES_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_VACANCIES_RESPONSE:
      if (!action.vacancies) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.vacancies), {}),
      };

    case VENUE_CREATE_VACANCY_RESPONSE:
    case VENUE_UPDATE_VACANCY_RESPONSE:
      if (!action.vacancy) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.vacancy), {
          overrideDuplicateIds: true,
        }),
      };

    case VENUE_DELETE_VACANCY_RESPONSE:
      if (!action.vacancy) {
        return state;
      }

      return {
        ...state,
        items: [...state.items.filter((item) => item.id !== action.vacancy.id)],
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        (!action.response.data.vacancy && (!action.response.data.vacancies || !action.response.data.vacancies.length))
      ) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.response.data.vacancy || [])
            .concat(action.response.data.vacancies || []),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    default:
      return state;
  }
};

export const venueVacanciesByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_VACANCIES_REQUEST:
    case VENUE_VACANCIES_RESPONSE:
    case VENUE_CREATE_VACANCY_RESPONSE:
    case VENUE_UPDATE_VACANCY_RESPONSE:
    case VENUE_DELETE_VACANCY_RESPONSE:
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = venueVacancies(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

const venueExtraOpenings = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_EXTRA_OPENINGS_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_EXTRA_OPENINGS_RESPONSE:
      if (!action.extra_openings) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.extra_openings), { onlyIds: true }),
      };

    case VENUE_CREATE_EXTRA_OPENINGS_BULK_RESPONSE:
      if (!action.extra_openings) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.extra_openings), {
          onlyIds: true,
          overrideDuplicateIds: true,
        }),
      };

    case VENUE_CREATE_EXTRA_OPENINGS_RESPONSE:
    case VENUE_UPDATE_EXTRA_OPENINGS_RESPONSE:
      if (!action.extra_opening) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.extra_opening), {
          onlyIds: true,
          overrideDuplicateIds: true,
        }),
      };

    case VENUE_DELETE_EXTRA_OPENINGS_RESPONSE:
      if (!action.extra_opening) {
        return state;
      }

      return {
        ...state,
        items: [...state.items.filter((item) => item.id !== action.extra_opening.id)],
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        (!action.response.data.extra_opening &&
          (!action.response.data.extra_openings || !action.response.data.extra_openings.length))
      ) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.response.data.extra_opening || [])
            .concat(action.response.data.extra_openings || []),
          {
            onlyIds: true,
            overrideDuplicateIds: true,
          }
        ),
      };

    default:
      return state;
  }
};

export const venueExtraOpeningsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_EXTRA_OPENINGS_REQUEST:
    case VENUE_EXTRA_OPENINGS_RESPONSE:
    case VENUE_CREATE_EXTRA_OPENINGS_RESPONSE:
    case VENUE_UPDATE_EXTRA_OPENINGS_RESPONSE:
    case VENUE_DELETE_EXTRA_OPENINGS_RESPONSE:
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = venueExtraOpenings(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

const venueCustomTimeTables = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_CUSTOM_TIME_TABLES_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_CUSTOM_TIME_TABLES_RESPONSE:
      if (!action.custom_time_tables) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.custom_time_tables), {
          onlyIds: true,
        }),
      };

    case VENUE_CREATE_CUSTOM_TIME_TABLES_RESPONSE:
    case VENUE_UPDATE_CUSTOM_TIME_TABLES_RESPONSE:
      if (!action.custom_time_table) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.custom_time_table), {
          onlyIds: true,
          overrideDuplicateIds: true,
        }),
      };

    case VENUE_DELETE_CUSTOM_TIME_TABLES_RESPONSE:
      if (!action.custom_time_table) {
        return state;
      }

      return {
        ...state,
        items: [...state.items.filter((item) => item.id !== action.custom_time_table.id)],
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        (!action.response.data.custom_time_table &&
          (!action.response.data.custom_time_tables || !action.response.data.custom_time_tables.length))
      ) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.response.data.custom_time_table || [])
            .concat(action.response.data.custom_time_tables || []),
          {
            onlyIds: true,
            overrideDuplicateIds: true,
          }
        ),
      };

    default:
      return state;
  }
};

export const venueCustomTimeTablesByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_CUSTOM_TIME_TABLES_REQUEST:
    case VENUE_CUSTOM_TIME_TABLES_RESPONSE:
    case VENUE_CREATE_CUSTOM_TIME_TABLES_RESPONSE:
    case VENUE_UPDATE_CUSTOM_TIME_TABLES_RESPONSE:
    case VENUE_DELETE_CUSTOM_TIME_TABLES_RESPONSE:
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = venueCustomTimeTables(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
