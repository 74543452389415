import { LOCATION_CHANGED_EVENT, StoreCustomEvents } from 'customEvents';
import { TrackingRule } from 'utils/tracking/aspectTracking';
import { TrackingService } from 'utils/tracking/interfaces';

export type PageRulePlugin = (url: string) => { [key: string]: string } | undefined;

export type PageRulesContext = {
  trackingService: {
    page: TrackingService['page'];
  };
  plugins?: PageRulePlugin[];
  getCurrentUrl: () => Location['href'];
  onException?: (error: PageRulesError) => void;
};

export const getPageRules = ({
  trackingService: { page },
  plugins = [],
  getCurrentUrl,
  onException = (e) => {
    throw e;
  },
}: PageRulesContext): TrackingRule<StoreCustomEvents>[] => {
  const getExtraParamsFromPlugins = () => {
    const currentURL = getCurrentUrl();
    return plugins.reduce((params, plugin, index) => {
      let pluginParams = {};
      try {
        pluginParams = plugin(currentURL) ?? {};
      } catch (e) {
        if (e instanceof Error) {
          onException(new PageRulesError(index, e));
        } else {
          onException(new PageRulesError(index, new Error(String(e))));
        }
      }
      return { ...params, ...pluginParams };
    }, {});
  };

  const rules: TrackingRule<StoreCustomEvents>[] = [
    (is) => is('document', LOCATION_CHANGED_EVENT) && page(getExtraParamsFromPlugins()),
  ];
  return rules;
};

export class PageRulesError extends Error {
  constructor(index: number, error: Error) {
    super(`'Error in plugin with index ${index}: ${error.message}`);
    this.stack = error.stack;
  }
}
