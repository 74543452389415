import PropTypes from 'prop-types';
import { connectFormElement, getErrorByName } from '@uala/react-forms';

import { DefaultSettings, INPUT_INLINE, INPUT_SIZES } from '../../config';
import { StyledInputTag } from './InputText.theme';
import {
  BaseInputWrapper,
  FormControl,
  FormControlInput,
  Label,
  Helper,
  AdornmentWrapper,
  elementPropTypes,
} from '../../shared';

const InputText = ({
  name,
  label,
  labelPosition,
  emitChange,
  emitDidChange,
  values,
  errors,
  helper,
  helperPosition,
  withMeasurementUnit,
  unit,
  intl,
  size,
  tooltip,
  number,
  withInlineValidation,
  disabled,
  extraAdornment: { prefix, suffix, hideSeparator },
  dataTestId,
  ...inputProps
}) => {
  const fieldError = getErrorByName(errors, name);
  helper = (withInlineValidation && fieldError) || helper || null;
  const error = !!fieldError;
  const withPrefix = typeof prefix !== 'undefined';
  const withSuffix = typeof suffix !== 'undefined';
  const value = withMeasurementUnit && values[name] ? `${values[name]} ${unit}` : values[name];
  const defaultEmptyValue = '';

  const handleChange = (inputName, targetValue) => {
    const newValue = number ? targetValue.replace(/[.,]+/gi, '.') : targetValue;

    emitChange(inputName, newValue);
  };

  return (
    <FormControl labelPosition={labelPosition} className="InputText">
      {label && (
        <Label htmlFor={name.toString()} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput labelPosition={labelPosition}>
        <BaseInputWrapper error={error} size={size}>
          {withPrefix && <AdornmentWrapper paddingRight={!hideSeparator && '12px'}>{prefix}</AdornmentWrapper>}
          <StyledInputTag
            {...inputProps}
            disabled={disabled}
            name={name}
            type="text"
            value={value || (typeof value === 'number' && number) ? value : defaultEmptyValue}
            onChange={(e) => handleChange(name, e.target.value)}
            onBlur={(e) => emitDidChange(name, e.target.value)}
            data-testid={dataTestId}
          />
          {withSuffix && <AdornmentWrapper paddingLeft={!hideSeparator && '12px'}>{suffix}</AdornmentWrapper>}
        </BaseInputWrapper>
        {helper && (
          <Helper
            position={helperPosition}
            tooltip={tooltip}
            error={error}
            text={helper}
            intl={intl}
            dataTestId={`${dataTestId ?? name}-error`}
          />
        )}
      </FormControlInput>
    </FormControl>
  );
};

InputText.displayName = 'InputText';

InputText.propTypes = {
  ...elementPropTypes,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  number: PropTypes.bool,
  withInlineValidation: PropTypes.bool,
  withMeasurementUnit: PropTypes.bool,
  type: PropTypes.string,
  unit: PropTypes.string,
  size: PropTypes.oneOf(INPUT_SIZES),
  disabled: PropTypes.bool,
  extraAdornment: PropTypes.shape({
    prefix: PropTypes.node,
    suffix: PropTypes.node,
    hideSeparator: PropTypes.bool,
  }),
};

InputText.defaultProps = {
  value: '',
  autoComplete: DefaultSettings.AUTOCOMPLETE,
  required: false,
  placeholder: '',
  labelPosition: INPUT_INLINE,
  size: DefaultSettings.INPUT_SIZE,
  number: false,
  withInlineValidation: true,
  withMeasurementUnit: false,
  unit: null,
  intl: true,
  disabled: false,
  extraAdornment: {
    prefix: undefined,
    suffix: undefined,
    hideSeparator: false,
  },
};

export default connectFormElement(InputText);
