export const platform = {
  windows: 'win32',
  mac: 'darwin',
  linux: 'linux',
} as const;

type Keys = keyof typeof platform;
type Values = (typeof platform)[Keys];

// only works inside the Electrop wrapper

export const checkPlatform = (): Values => window.require && window.require('os').platform();
