import { forwardRef, ReactElement } from 'react';
import BaseStyledButton, { buttonContentWrapper } from './Button.theme';

type ButtonProps = {
  shape?: 'circle' | 'pill' | 'rectangular';
  color?: string;
  filling?: 'filled' | 'outlined' | 'none';
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md';
  width?: string;
  flat?: boolean;
  children: ReactElement;
  onClick?: (props: unknown) => void;
  dataTestId?: string;
  type?: 'submit' | 'reset' | 'button';
};

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      shape = 'pill',
      color = 'primary',
      filling = 'filled',
      disabled = false,
      width = 'auto',
      size = 'md',
      flat = false,
      children,
      onClick = (): void => {},
      dataTestId,
      type = 'button',
    },
    ref
  ) => (
    <BaseStyledButton
      shape={shape}
      color={color}
      ref={ref}
      filling={filling}
      disabled={disabled}
      width={width}
      size={size}
      flat={flat}
      onClick={onClick}
      data-testid={dataTestId}
      type={type}
    >
      <span className={`${buttonContentWrapper}`}>{children}</span>
    </BaseStyledButton>
  )
);

export default Button;
