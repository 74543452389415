import { ChangeEvent, InputHTMLAttributes, useState, forwardRef } from 'react';
import * as S from './InputSearch.theme';
import { Icon } from '@uala/ui-treatwell-pro';

export type InputSearchProps = Pick<InputHTMLAttributes<HTMLInputElement>, 'width' | 'placeholder' | 'name'> & {
  readonly onChange?: (value: { readonly value: InputSearchProps['value'] }) => void;
  readonly value?: string | number;
  readonly dataTestId?: string;
  readonly isDisabled?: boolean;
  readonly autoFocus?: boolean;
  readonly isInvalid?: boolean;
  readonly errorMessage?: string;
};

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
  (
    { autoFocus, dataTestId, name, value, placeholder, width, onChange, isDisabled, isInvalid, errorMessage },
    forwardedRef
  ) => {
    const [inputValue, setValue] = useState<string | number>('');

    function handleChange(e: ChangeEvent<HTMLInputElement>): void {
      const val = e.target.value;
      const valAsNumber = e.target.valueAsNumber;
      const computedVal = isNaN(valAsNumber) ? val : valAsNumber;

      if (onChange) onChange({ value: computedVal });

      setValue(computedVal);
    }

    return (
      <S.InputGroup inputWidth={width}>
        <S.InputIcon placement="left" className="left-icon">
          <Icon name="Search" size={16} />
        </S.InputIcon>
        <S.Input
          autoFocus={autoFocus}
          data-testid={dataTestId}
          name={name}
          ref={forwardedRef}
          type="search"
          value={value ?? inputValue}
          onChange={handleChange}
          placeholder={placeholder}
          disabled={isDisabled}
          aria-invalid={isInvalid}
        />
        {isInvalid && errorMessage ? <S.ErrorMessage>{errorMessage}</S.ErrorMessage> : null}
      </S.InputGroup>
    );
  }
);
