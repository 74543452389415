import { DEADLINES_LIST_REQUEST, DEADLINES_LIST_RESPONSE } from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

export const deadlineTypologies = (state = reducerValuesOnInit(), action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case DEADLINES_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case DEADLINES_LIST_RESPONSE:
      if (!action.deadline_typologies) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.deadline_typologies || [])),
      };

    default:
      return state;
  }
};
