import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import CallerBox from 'components/agenda/callerbox/CallerBox';
import { getAgendaCallerPopup } from 'selectors/getAgendaCallerPopup';
import { agendaCloseCallerBox } from 'actions/callerbox';

const mapStateToProps = () =>
  createSelector(getAgendaCallerPopup, (popup_data) => {
    return {
      popup_data,
    };
  });

const mapDispatchToProps = (dispatch) => {
  return {
    close() {
      dispatch(agendaCloseCallerBox());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallerBox);
