import { AgendaSubPagesCustomEvents, agendaSubPagesCustomEvents } from 'components/agenda/customEvents';
import { AgendaNextCustomEvents, agendaNextCustomEvents } from 'components/agenda_next/customEvents';
import { StoreCustomEvents, storeCustomEvents } from 'store/customEvents';
import { VersionCheckCustomEvents, versionCheckCustomEvents } from 'utils/version_check/customEvents';

export * from 'components/agenda/customEvents';
export * from 'components/agenda_next/customEvents';
export * from 'store/customEvents';

type CustomEvents = AgendaSubPagesCustomEvents | AgendaNextCustomEvents | StoreCustomEvents | VersionCheckCustomEvents;
export const customEvents = [
  ...agendaSubPagesCustomEvents,
  ...agendaNextCustomEvents,
  ...storeCustomEvents,
  ...versionCheckCustomEvents,
] as const;

export const dispatchCustomEvent = (eventType: CustomEvents): void => {
  const event = new window.Event(eventType);
  document.dispatchEvent(event);
};
