import { useEffect, useRef } from 'react';

const getDocumentTitle = (): string => document.title;

const setDocumentTitle = (title: string): void => {
  document.title = title;
};

/**
 * Allow react components to set a custom document title via hook.
 *
 * @param {string} title - the new document title.
 * @param {boolean} resetOnUnmount - if `true`, reset the title to the previous one after the component has been unmounted.
 */
const useDocumentTitle = (title: string, resetOnUnmount = false): void => {
  const initialTitle = useRef(getDocumentTitle());

  useEffect(() => {
    const resetValue = initialTitle.current;
    setDocumentTitle(title);

    return (): void => {
      if (resetOnUnmount) {
        setDocumentTitle(resetValue);
      }
    };
  }, [title, resetOnUnmount]);
};

export default useDocumentTitle;
export { setDocumentTitle, getDocumentTitle };
