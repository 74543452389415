import { API_GET_RESPONSE, VENUE_FETCH_CUSTOMER_TAGS_START } from 'actionTypes';
import { reducerValuesOnResponse, reducerValuesOnInit, reducerValuesOnRequest } from 'utils/reducersUtils';
import { CustomerTag } from 'models';

interface CustomerTagsAction {
  type: string;
  error: object | boolean;
  venue_id: number;
  response?: {
    data?: {
      customer_tags_count: number;
      customer_tags: CustomerTag[];
    };
  };
}

interface VenueState {
  items?: CustomerTag[];
  isFetching?: boolean | number;
  didInvalidate?: boolean;
}

interface CustomerTagsState {
  [venue_id: number]: VenueState;
}

const customerTags = (state: VenueState = reducerValuesOnInit(), action: CustomerTagsAction): VenueState => {
  switch (action.type) {
    case VENUE_FETCH_CUSTOMER_TAGS_START:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case API_GET_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        !action.response.data.customer_tags ||
        !action.response.data.customer_tags.length
      ) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse((state.items || []).concat(action.response.data.customer_tags), {
          overrideDuplicateIds: 1,
        }),
      };

    default:
      return state;
  }
};

export const customerTagsByVenue = (state: CustomerTagsState = {}, action: CustomerTagsAction): CustomerTagsState => {
  let refState: VenueState;

  switch (action.type) {
    case VENUE_FETCH_CUSTOMER_TAGS_START:
    case API_GET_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = customerTags(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
