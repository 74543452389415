import { styled } from 'styles';

import { withTheme } from 'components/core';

import { borderRadius, selectOptions } from '../../shared/theme';

const StyledSelectWrapper = withTheme(styled('div')`
  outline: none;
  background: inherit;
  padding: 0;
  margin: 0;
  cursor: text;
  border: none;
  font-size: 16px;
  font-family: ${({ theme }) => theme.utils.fontFamily};
  color: ${({ theme }) => theme.colors.text1};
  width: 100%;
  position: relative;
  cursor: pointer;
  width: 100%;
`);

const StyledSelectedValue = styled('span')`
  display: flex;
  flex-direction: row;
  align-items: center;
  .Ctrl__icon {
    padding-left: 15px;
    margin-left: auto;
  }
`;

const SelectPlaceholder = withTheme(styled('div')`
  height: 0;
  opacity: 0;
  .Ctrl__icon {
    padding-left: 15px;
    margin-left: auto;
  }
`);

const Options = withTheme(styled('div')`
  position: absolute;
  display: none;
  opacity: 0;
  height: 0;
  background: ${({ theme }) => theme.colors.white};
  width: calc(100% + 26px);
  border: 1px solid ${({ theme }) => theme.colors.inputBorder};
  overflow: hidden;
  top: ${({ size }) => selectOptions[size].offsetTop};
  left: -13px;
  border-radius: ${({ size }) => borderRadius[size]};
  padding: ${({ size }) => selectOptions[size].padding};

  &.Options--visible {
    opacity: 1;
    display: block;
    z-index: 100;
    height: auto;
  }
`);

const OptionsFilterWrapper = withTheme(styled('div')`
  cursor: pointer;
  padding: 0 0 16px;
`);

const OptionsScrollArea = styled('div')`
  height: auto;
  max-height: 300px;
  overflow: auto;
`;

const OptionWrapper = withTheme(styled('div')`
  cursor: pointer;
  padding: 8px 0;

  &:first-of-type {
    padding: 0 0 8px;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }

  &.Option--selected {
    color: ${({ theme }) => theme.colors.blue};
  }
`);

export {
  StyledSelectedValue,
  StyledSelectWrapper,
  Options,
  OptionsScrollArea,
  OptionWrapper,
  OptionsFilterWrapper,
  SelectPlaceholder,
};
