import { styled, css, Theme, Colors } from 'styles';
import { withTheme } from '../Context';
import { IconProps } from './index';

const fillColor = ({ color, fillMode, theme }: Partial<IconProps> & { theme: Theme }): string | null => {
  const { colors } = theme;

  if (!color) {
    return null;
  }

  return `
    path {
      fill: ${colors[color as Colors]};
    }

    path[data-stroke] {
      fill: none;
      stroke: ${colors[color as Colors]};
    }

    circle,
    rect {
      stroke: ${fillMode === 'filled' ? 'transparent' : colors[color as Colors]};
      fill: ${fillMode === 'filled' ? colors[color as Colors] : 'transparent'};
    }
  `;
};

export const Icon = withTheme(
  styled('span')(
    ({
      theme,
      size,
      color,
      padding,
      marginRight,
      marginLeft,
      onClick,
      targetSize,
      flex,
      fillMode,
      noResize,
    }: Partial<IconProps> & { theme: Theme }) => css`
      position: relative;
      display: inline-block;
      box-sizing: content-box;
      vertical-align: middle;

      width: ${`${size || 16}${size !== 'auto' ? 'px' : ''}`};
      height: ${`${size || 16}${size !== 'auto' ? 'px' : ''}`};

      ${noResize ? `min-width: ${`${size || 16}${size !== 'auto' ? 'px' : ''}`};` : ''}
      ${noResize ? `max-width: ${`${size || 16}${size !== 'auto' ? 'px' : ''}`};` : ''}
      ${noResize ? `min-height: ${`${size || 16}${size !== 'auto' ? 'px' : ''}`};` : ''}
      ${noResize ? `max-height: ${`${size || 16}${size !== 'auto' ? 'px' : ''}`};` : ''}

      ${flex ? { flex } : ''};
      ${padding ? { padding } : ''};

      ${theme.media.mobileOrSmaller`
        ${marginRight && `margin-right: ${theme.sizes.padding / 2}px`};
        ${marginLeft && `margin-left: ${theme.sizes.padding / 2}px`};
      `};
      ${theme.media.tablet`
        ${marginRight && `margin-right: ${theme.sizes.padding}px`};
        ${marginLeft && `margin-left: ${theme.sizes.padding}px`};
      `};

      ${fillColor({ color, fillMode, theme })};
      ${onClick && { cursor: 'pointer' }};

      ${targetSize &&
      `
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -${targetSize / 2}px;
          margin-top: -${targetSize / 2}px;
          width: ${targetSize}px;
          height: ${targetSize}px;
        }
      `};

      svg {
        display: block;
        width: 100%;
        height: 100%;
      }
    `
  )
);
