import { createSelector } from 'reselect';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';

/**
 * billingCompany
 */
export const getBillingCompanyByVenue = (state) => state.billingCompanyByVenue;

export const getBillingCompanyLoading = createSelector(
  [getCurrentVenueId, getBillingCompanyByVenue],
  (venue_id, billingCompanyByVenue) => {
    return (billingCompanyByVenue[venue_id] && billingCompanyByVenue[venue_id].isFetching) || false;
  }
);

export const getBillingCompanyList = createSelector(
  [getCurrentVenueId, getBillingCompanyByVenue],
  (venue_id, billingCompanyByVenue) => {
    return ((billingCompanyByVenue[venue_id] && billingCompanyByVenue[venue_id].items) || [])[0] || null;
  }
);

/** @type {(state: import('store').State) => import('models').BillingCompany | null} */
export const getCurrentBillingCompany = getBillingCompanyList;

export const getBillingCompanyId = createSelector(
  [getBillingCompanyList],
  (billingCompany) => billingCompany?.id || null
);

/**
 * billingCompanyProducts
 */

export const getBillingCompanyProductsByVenue = (state) => state.billingCompanyProductsByVenue;

export const getBillingCompanyProductsLoading = createSelector(
  [getCurrentVenueId, getBillingCompanyProductsByVenue],
  (venue_id, billingCompanyProductsByVenue) => {
    return (billingCompanyProductsByVenue[venue_id] && billingCompanyProductsByVenue[venue_id].isFetching) || false;
  }
);

const emptyBillingCompanyProducts = [];
export const getBillingCompanyProductsList = createSelector(
  [getCurrentVenueId, getBillingCompanyProductsByVenue],
  (venue_id, billingCompanyProductsByVenue) => {
    return (
      (billingCompanyProductsByVenue[venue_id] && billingCompanyProductsByVenue[venue_id].items) ||
      emptyBillingCompanyProducts
    );
  }
);

/**
 * billingAddons
 */

export const getBillingAddonsByVenue = (state) => state.billingAddonsByVenue;

export const getBillingAddonsLoading = createSelector(
  [getCurrentVenueId, getBillingAddonsByVenue],
  (venue_id, billingAddonsByAddonByVenue) => {
    return (billingAddonsByAddonByVenue[venue_id] && billingAddonsByAddonByVenue[venue_id].isFetching) || false;
  }
);

const emptyBillingAddons = [];

export const getBillingAddonsList = createSelector(
  [getCurrentVenueId, getBillingAddonsByVenue],
  (venue_id, billingAddonsByVenue) => {
    return (
      (billingAddonsByVenue[venue_id] &&
        billingAddonsByVenue[venue_id].items &&
        billingAddonsByVenue[venue_id].items.filter((billingAddon) => {
          return billingAddon.plan_identifier === null; // pick billing addons that are not plans
        })) ||
      emptyBillingAddons
    );
  }
);

const PLAN_IDENTIFIER = ['basic', 'lite', 'full']; // HINT: it defines the list of possibile plan names

export const getBillingAddonsPlansList = createSelector(
  [getCurrentVenueId, getBillingAddonsByVenue],
  (venue_id, billingAddonsByVenue) => {
    return (
      (billingAddonsByVenue[venue_id] &&
        billingAddonsByVenue[venue_id].items &&
        billingAddonsByVenue[venue_id].items
          .filter((billingAddon) => {
            return PLAN_IDENTIFIER.includes(billingAddon.plan_identifier); // pick billing addons that are actual plans
          })
          .sort((a, b) => {
            return a.plan_priority > b.plan_priority ? 1 : -1;
          })) ||
      emptyBillingAddons
    );
  }
);

/**
 * billingCompanyInvoices
 */

export const getBillingCompanyInvoicesByVenue = (state) => state.billingCompanyInvoicesByVenue;

export const getBillingCompanyInvoicesLoading = createSelector(
  [getCurrentVenueId, getBillingCompanyInvoicesByVenue],
  (venue_id, billingCompanyInvoicesByVenue) => {
    return (billingCompanyInvoicesByVenue[venue_id] && billingCompanyInvoicesByVenue[venue_id].isFetching) || false;
  }
);

const emptyBillingCompanyInvoices = [];
export const getBillingCompanyInvoicesList = createSelector(
  [getCurrentVenueId, getBillingCompanyInvoicesByVenue],
  (venue_id, billingCompanyInvoicesByVenue) => {
    return (
      (billingCompanyInvoicesByVenue[venue_id] && billingCompanyInvoicesByVenue[venue_id].items) ||
      emptyBillingCompanyInvoices
    );
  }
);

/**
 * venuesTransfers
 */

export const getVenuesTransfersByVenue = (state) => state.venuesTransfersByVenue;

export const getVenuesTransfersLoading = createSelector(
  [getCurrentVenueId, getVenuesTransfersByVenue],
  (venue_id, venuesTransfersByVenue) => {
    return (venuesTransfersByVenue[venue_id] && venuesTransfersByVenue[venue_id].isFetching) || false;
  }
);

const emptyVenuesTrasfers = [];
export const getVenuesTransfersList = createSelector(
  [getCurrentVenueId, getVenuesTransfersByVenue],
  (venue_id, venuesTransfersByVenue) => {
    return (venuesTransfersByVenue[venue_id] && venuesTransfersByVenue[venue_id].items) || emptyVenuesTrasfers;
  }
);

/**
 * Generic selectors
 */

export const getBaseServices = createSelector([getBillingCompanyProductsList], (billing_company_products) => {
  return billing_company_products.filter((item) => {
    return item.billing_product.basic === true && item.state !== 'expired';
  });
});

export const getAddOnServices = createSelector([getBillingCompanyProductsList], (billing_company_products) => {
  return billing_company_products.filter((item) => {
    return item.billing_product.basic === false && item.state !== 'expired';
  });
});

export const getExpiredServices = createSelector([getBillingCompanyProductsList], (billing_company_products) => {
  return billing_company_products.filter((item) => {
    return item.state === 'expired';
  });
});

export const getUnsolvedDebitInvoicesSummary = createSelector(
  [getBillingCompanyInvoicesList],
  (billing_company_invoices) => {
    const unsolvedDebitInvoices = billing_company_invoices.filter(
      (invoice) => invoice.paid_at === null && !invoice.automatic_payment_scheduled_at
    );
    const paymentScheduledDebitInvoices = billing_company_invoices.filter(
      (invoice) => invoice.automatic_payment_scheduled_at
    );
    const total = unsolvedDebitInvoices.reduce((acc, invoice) => (acc += invoice.discounted_due_amount), 0);

    return {
      total,
      count: unsolvedDebitInvoices.length,
      paymentScheduled: !unsolvedDebitInvoices.length && paymentScheduledDebitInvoices.length,
    };
  }
);

export const getLastDebitInvoice = createSelector([getBillingCompanyInvoicesList], (billing_company_invoices) => {
  return billing_company_invoices.sort((a, b) => {
    return new Date(b.uploaded_at).getTime() - new Date(a.uploaded_at).getTime();
  })[0];
});

export const getLastCreditInvoice = createSelector([getVenuesTransfersList], (venues_transfers) => {
  return venues_transfers.sort((a, b) => {
    return new Date(b.disposed_at).getTime() - new Date(a.disposed_at).getTime();
  })[0];
});
