import { connect } from 'react-redux';
import NavBar from 'components/navbar/NavBar';
import { getCurrentUser } from 'selectors/getCurrentUser';
import { sessionsLogout } from 'actions/sessions';
import {
  CheckCurrentVenueActiveFlag,
  getCheckCurrentVenueActiveFlag,
  getCurrentVenue,
  getCurrentVenueCountrySegmentKey,
  getCurrentVenueDetailedMonitoringEnabled,
  isCurrentVenueNF525Compliant,
  isCurrentVenuePortugueseFinancialAuthorityCompliant,
} from 'selectors/getCurrentVenue';
import { createSelector, OutputParametricSelector } from 'reselect';
import { modalOpen } from 'actions/modals';
import { ActionDispatch } from 'actions/types';
import { State } from 'reducers/types';
import { getPermissionChecker } from 'selectors/venue/getVenuePermissions';
import { Venue } from 'models';
import { UnknownFunction } from 'utils/utilityTypes';

type NavBarProps = {
  isOpen: boolean;
  onClick: () => void;
};

type NavBarStateProps = {
  checkPermission: UnknownFunction;
  venue: Venue | null;
  segment_key: string | null;
  checkCurrentVenueActiveFlag: CheckCurrentVenueActiveFlag;
  detailed_monitoring_enabled: boolean;
};

const mapDispatchToProps = (
  dispatch: ActionDispatch
): {
  onLogout: () => void;
  onDetailedMonitorClick: () => void;
  openNf525CertificationInfoModal: (certificateNumber: string, certificateUrl: string) => void;
} => {
  return {
    onLogout(): void {
      dispatch(sessionsLogout());
    },
    onDetailedMonitorClick(): void {
      dispatch(
        modalOpen({
          id: 'detailed-monitoring',
          config: {
            component: 'detailed_monitoring/DetailedMonitoring',
          },
        })
      );
    },
    openNf525CertificationInfoModal(certificateNumber: string, certificateUrl: string): void {
      dispatch(
        modalOpen({
          id: 'nf525certification',
          config: {
            component: 'nf525/Nf525Certification',
            certificateNumber: certificateNumber,
            certificateUrl: certificateUrl,
          },
        })
      );
    },
  };
};

const mapStateToProps = (): OutputParametricSelector<State, NavBarProps, NavBarStateProps, UnknownFunction> =>
  createSelector(
    getPermissionChecker,
    getCurrentVenue,
    getCurrentVenueCountrySegmentKey,
    getCheckCurrentVenueActiveFlag,
    getCurrentVenueDetailedMonitoringEnabled,
    isCurrentVenueNF525Compliant,
    isCurrentVenuePortugueseFinancialAuthorityCompliant,
    getCurrentUser,
    (
      checkPermission,
      venue,
      segment_key,
      checkCurrentVenueActiveFlag,
      detailed_monitoring_enabled,
      isNf525Compilant,
      isPortugueseFinancialAuthorityCompliant,
      user
    ) => {
      return {
        checkPermission,
        venue,
        segment_key,
        checkCurrentVenueActiveFlag,
        detailed_monitoring_enabled,
        isNf525Compilant,
        isPortugueseFinancialAuthorityCompliant,
        user,
      };
    }
  );

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
