import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { createFlashPromotion, deleteFlashPromotion } from 'actions/promotions/promotions';
import { getFlashPromotionsList } from 'selectors/promotions/getPromotions';
import PromoFlashEdit from './PromoFlashEdit';
import withLocalChanges from 'utils/hoc/withLocalChanges';
import moment from 'moment';
import { modalOpen, modalClose } from 'actions/modals';

const mapDispatchToProps = {
  dispatchCreateFlashPromotion: createFlashPromotion,
  dispatchDeleteFlashPromotion: deleteFlashPromotion,
  dispatchModalOpen: modalOpen,
  dispatchModalClose: modalClose,
};

const mapStateToProps = () =>
  createSelector(getFlashPromotionsList, (promotionsList) => {
    const duration =
      (promotionsList[0] &&
        moment(promotionsList[0].calculated_valid_to).diff(promotionsList[0].calculated_valid_from, 'seconds')) ||
      0;

    const promoFlash = {
      duration: duration === 3600 * 72 ? 72 : duration === 3600 * 48 ? 48 : duration === 24 ? 24 : 'today',
      applied_to:
        promotionsList[0] &&
        promotionsList[0].included_resources[0] &&
        promotionsList[0].included_resources[0].record_id
          ? 'custom'
          : 'all',
      included_resources_attributes: promotionsList[0]
        ? promotionsList[0].included_resources
        : [
            {
              table_name: null,
              record_id: null,
              discount_type: 'percent',
              discount_percent_amount: 0,
            },
          ],
    };

    return {
      marketing_promotion: promotionsList[0],
      promoFlash,
    };
  });

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalChanges({ propName: 'promoFlash' })(PromoFlashEdit));
