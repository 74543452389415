import { http } from 'utils/http-request';
import { AuthHeaderProvider } from './types';
import { defaultAuthHeaderProvider } from './utils';

interface HttpMethods {
  readonly get: ReturnType<typeof http.get>;
  readonly post: ReturnType<typeof http.post>;
  readonly put: ReturnType<typeof http.put>;
  readonly delete: ReturnType<typeof http.delete>;
}

export const Http = (authHeaderProvider?: AuthHeaderProvider): HttpMethods | never => {
  const authHeader = authHeaderProvider ? authHeaderProvider() : defaultAuthHeaderProvider();

  return {
    get: http.get(authHeader),
    post: http.post(authHeader),
    put: http.put(authHeader),
    delete: http.delete(authHeader),
  } as const;
};
