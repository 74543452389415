import { Checkout } from 'models';

export enum ECOMMERCE_ORDER_STATE {
  PENDING = 'pending',
  PRINTING = 'printing',
  READY = 'ready',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  REJECTED = 'rejected',
  MODIFIED = 'modified',
  APPROVED = 'approved',
}

export type EcommerceOrderItem = {
  id: number;
  gross_price: {
    cents: number;
  };
  net_price: {
    cents: number;
  };
  quantity: number;
  unit_gross_price: {
    cents: number;
  };
  unit_net_price: {
    cents: number;
  };
  vat_percentage: number;
  venue_product: {
    id: number;
    name: string;
    barcode: string;
  };
};

export type EcommerceOrder = {
  created_at: string;
  id: number;
  order_number: string;
  tracking_number?: string;
  state: ECOMMERCE_ORDER_STATE;
  customer?: {
    id: number;
    full_name: string;
    email: string;
    phone: string;
  };
  total_price?: {
    cents: number;
  };
  items?: EcommerceOrderItem[];
  shipper_name?: string;
  tracking_url?: string;
  shipping_option?: {
    price: number;
    name: string;
    delivery_time: number;
    shipper: {
      name: string;
      email: string;
      phone: string;
    };
  };
  ship_to?: {
    email: string;
    phone?: string;
    address: string;
    city: string;
    country_code: string;
    created_at: string;
    full_name: string;
    secondary_address: string;
    state: string;
    street_number: string;
    zip: string;
  };
  checkout?: Checkout;
};
