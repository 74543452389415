import { byTestId } from 'utils/selectors';
import { EventType, TrackingRule } from 'utils/tracking/aspectTracking';
import { TrackingService } from 'utils/tracking/interfaces';
type ObjectWithTrackMethod = {
  track: TrackingService['track'];
};
type Props = {
  trackingService: ObjectWithTrackMethod;
};
const CLICK: EventType[] = ['click', 'pointerdown'];

export const getDailyProtectionRules = ({ trackingService }: Props) => {
  const dailyProtectionEventName = 'DailyProtection Clicked';
  let isDailyProtectionOpened = false;
  let interaction: 0 | 1 = 0;
  const dailyProtectionOpenButtonSelector = `${byTestId('daily-protection-button')} *`;
  const dailyProtectionInsideSelector = `${byTestId('daily-protection-modal')} *`;

  const dailyProtectionOpened = () => {
    isDailyProtectionOpened = true;
  };
  const dailyProtectionClosed = () => {
    isDailyProtectionOpened = false;
    interaction = 0;
  };
  const dailyProtectionInteractedWith = () => {
    interaction = 1;
  };

  const onClickOutside = () => {
    if (isDailyProtectionOpened) trackingService.track(dailyProtectionEventName, { any_interaction: interaction });
    dailyProtectionClosed();
  };

  const onDailyProtectionOpen = () => {
    dailyProtectionOpened();
  };

  const isClickEventAndNotALayerInsideDailyProtection = (is: Parameters<TrackingRule>[0]): boolean => {
    const isAnyLayer = is('*', CLICK);
    const isDailyProtectionOpenButton = is(dailyProtectionOpenButtonSelector, CLICK);
    const isInsideDailyProtection = is(dailyProtectionInsideSelector, CLICK);
    return !(isInsideDailyProtection || isDailyProtectionOpenButton) && isAnyLayer;
  };

  const clickOutsideRule: TrackingRule = (is) => {
    if (!isClickEventAndNotALayerInsideDailyProtection(is)) return;
    onClickOutside();
  };

  const dailyProtectionClickedRule: TrackingRule = (is) => {
    if (!is(dailyProtectionOpenButtonSelector, CLICK)) return;
    onDailyProtectionOpen();
  };

  const dailyProtectionInteractedWithRule: TrackingRule = (is) => {
    is(dailyProtectionInsideSelector, CLICK) && dailyProtectionInteractedWith();
  };

  return [clickOutsideRule, dailyProtectionClickedRule, dailyProtectionInteractedWithRule];
};
