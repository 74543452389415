import { FunctionComponent, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getCurrentUser, getCurrentUserToken } from 'selectors/getCurrentUser';
import { getCurrentVenue } from 'selectors/getCurrentVenue';
import { APP_LOCALE } from 'actions/data_providers/api';
import { ModuleFederationContext } from '../RegistryProvider/RegistryProvider';

export const HostContract: FunctionComponent = () => {
  useHostPubSub();

  return <></>;
};

const useHostPubSub = (): void => {
  const { store } = useContext(ModuleFederationContext);
  const venue = useSelector(getCurrentVenue);
  const token = useSelector(getCurrentUserToken);
  const user = useSelector(getCurrentUser);
  const history = useHistory();

  useEffect(() => {
    if (store) {
      return store.addEventListener<string>(null, 'location', (event) => {
        history.push(event.value);
      });
    }
    return;
  }, [store, history]);

  useEffect(() => {
    if (store) {
      store.setItem('locale', APP_LOCALE());
    }
  }, [store]);

  useEffect(() => {
    if (store) {
      store.setItem('venue', venue);
    }
  }, [store, venue]);

  useEffect(() => {
    if (store) {
      store.setItem('token', token);
    }
  }, [store, token]);

  useEffect(() => {
    if (store) {
      store.setItem('user', user);
    }
  }, [store, user]);
};
