import { LocaleConsumer, locales, AvailableLocales } from 'providers/IntlProvider';
import { LanguageSwitcher } from './LanguageSwitcher';

import { availableLanguages } from './LanguageSwitcher.config';

/**
 * LanguageSwitcher
 */
type Props = {
  position?: 'top' | 'bottom';
};

const LanguageSwitcherContainer = ({ position = 'bottom' }: Props): JSX.Element => (
  <LocaleConsumer>
    {({ locale, setLocale }): JSX.Element => {
      const onChange = (currentLocale: AvailableLocales): void => setLocale(currentLocale);

      return (
        <LanguageSwitcher
          position={position}
          value={locale}
          options={availableLanguages.filter((item) => locales.indexOf(item.locale) >= 0)}
          onChange={onChange}
        />
      );
    }}
  </LocaleConsumer>
);

LanguageSwitcherContainer.displayName = 'LanguageSwitcher';

export { LanguageSwitcherContainer };
