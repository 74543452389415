import React from 'react';
import { arraysAreEquals } from 'utils/arraysAreEquals';
import { toStringIfIsNumber } from 'utils/toStringIfIsNumber';

export const invalidateAllWithLocalChangesRefChecks = (object) => {
  object._ref = object;
  return object;
};

const compareSubjectsAreArrays = (a, b) => Array.isArray(a) || Array.isArray(b);

const WithLocalChanges =
  (localChangesProps = {}) =>
  (Target) => {
    /**
     * Default behavior, simple equality check
     */
    let checkIfInvalidationIsNeeded = (prevProps, thisProps) => {
      return prevProps[localChangesProps.propName] !== thisProps[localChangesProps.propName];
    };

    /**
     * _ref check behavior
     * for models that comes from getEntitiesDictionary
     */
    if (localChangesProps.checkRef) {
      checkIfInvalidationIsNeeded = (prevProps, thisProps) => {
        return (
          thisProps[localChangesProps.propName] &&
          (!prevProps[localChangesProps.propName] ||
            prevProps[localChangesProps.propName]._ref !== thisProps[localChangesProps.propName]._ref)
        );
      };
    }

    /**
     * attrs check behavior
     * check all the attributes in a list
     */
    if (localChangesProps.checkList) {
      checkIfInvalidationIsNeeded = (prevProps, thisProps) => {
        return (
          thisProps[localChangesProps.propName] &&
          (!prevProps[localChangesProps.propName] ||
            localChangesProps.checkList.find(
              (key) => prevProps[localChangesProps.propName][key] !== thisProps[localChangesProps.propName][key]
            ))
        );
      };
    }

    class WithLocalChangesComponent extends React.Component {
      state = {
        localChanges: null,
      };

      componentDidMount() {
        this._active = true;
      }

      componentWillUnmount() {
        this._active = false;
      }

      // TODO: dome -> we need to undestand a better way of doing this.
      componentDidUpdate(prevProps) {
        if (checkIfInvalidationIsNeeded(prevProps, this.props)) {
          this._cached = null;

          if (!localChangesProps.persistChanges) {
            this.resetLocalChanges();
          } else {
            this.forceUpdate();
          }
        }
      }

      getLocalChanges = () => {
        if (!this._active || !this.state.localChanges || !this.props[localChangesProps.propName]) return null;

        return Object.keys(this.state.localChanges).reduce((reallyChangedValues, key) => {
          // if value is not equals to the original value, add it to getLocalChanges
          // this check is to avoid returning localChanges when nothing is really changed
          const currentLocalChange = this.state.localChanges[key];
          const currentProps = this.props[localChangesProps.propName][key];

          const localKey = toStringIfIsNumber(currentLocalChange);
          const propsKey = toStringIfIsNumber(currentProps);

          if (localKey !== propsKey) {
            if (compareSubjectsAreArrays(localKey, propsKey) && arraysAreEquals(localKey, propsKey)) {
              return null;
            }
            return {
              ...reallyChangedValues,
              [key]: this.state.localChanges[key],
            };
          }

          return reallyChangedValues;
        }, null);
      };

      setLocalChanges = (changes) =>
        new Promise((resolve, reject) => {
          this._cached = null;

          if (!this._active) {
            return resolve();
          }
          this.setState(
            ({ localChanges }) => ({
              localChanges: {
                ...localChanges,
                ...changes,
              },
            }),
            () => {
              resolve();
            }
          );
        });

      resetLocalChanges = () =>
        new Promise((resolve, reject) => {
          this._cached = null;

          if (!this._active) {
            return resolve();
          }

          this.setState(
            {
              localChanges: null,
            },
            () => {
              resolve();
            }
          );
        });

      getOverridenProp = () => {
        if (localChangesProps.propName) {
          if (this._cached) {
            return this._cached;
          }

          this._cached = {
            [localChangesProps.propName]: {
              ...this.props[localChangesProps.propName],
              ...this.getLocalChanges(),
            },
          };
          return this._cached;
        }

        return null;
      };

      render() {
        return (
          <Target
            {...this.props}
            {...this.getOverridenProp()}
            getLocalChanges={this.getLocalChanges}
            setLocalChanges={this.setLocalChanges}
            resetLocalChanges={this.resetLocalChanges}
          />
        );
      }
    }

    WithLocalChangesComponent.displayName = `withLocalChanges(${Target.displayName || Target.name || 'Component'})`;

    return WithLocalChangesComponent;
  };

export default WithLocalChanges;
