import { styled, css } from 'styles';
import { COLORS, MEDIA, FONTS } from 'styles';

const sizeStyles = {
  lg: css`
    padding: 8px 20px;
    ${FONTS.txtSelectLg};
  `,
};

export const Input = styled('input')(
  ({ disabled, type, size }) => css`
    border: 1px solid ${COLORS.inputBorder};
    border-radius: 32px;
    padding: 7px 20px 6px;
    color: ${COLORS.text1};
    ${FONTS.txtMain3};
    ${size && sizeStyles[size]};
    ${disabled &&
    css`
      background-color: lightgray;
      border-color: lightgray;
      opacity: 0.3;
      pointer-events: none;
    `};

    ::placeholder {
      color: ${COLORS.inputPlaceHolder};
    }

    :-ms-input-placeholder {
      color: ${COLORS.inputPlaceHolder};
    }

    ::-ms-input-placeholder {
      color: ${COLORS.inputPlaceHolder};
    }

    ${MEDIA.desktop`
      width: 100px;
    `};
  `
);
