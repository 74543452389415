import { ValueOf } from 'utils/utilityTypes';

export enum CMS_CONTENT_PAGE {
  AGENDA = 'agenda',
  CUSTOMERS = 'customers',
  CHECKOUT = 'checkout',
  TREATMENTS = 'treatments',
  STAFF_MEMBERS = 'staff_members',
  WORKSTATIONS = 'workstations',
  PROMOTIONS = 'promotions',
  BASIC_PROMOTIONS = 'basic_promotions',
  ADVANCED_PROMOTIONS = 'advanced_promotions',
  TIMETABLES = 'timetables',
  COSTS = 'costs',
  MANAGER_TREND = 'manager_trend',
  MANAGER_COMPANY_REPORT = 'manager_company_report',
  MANAGER_COLLABORATORS_REPORT = 'manager_collaborators_report',
  MANAGER_CUSTOMERS_REPORT = 'manager_customers_report',
  MANAGER_STOCK_REPORT = 'manager_stock_report',
  MANAGER_INVENTORY_REPORT = 'manager_inventory_report',
  WAREHOUSE = 'warehouse',
  WAREHOUSE_ECOMMERCE = 'warehouse_ecommerce',
  MARKETING = 'marketing',
  REACHED_LIMIT_BOOKINGS = 'reached_limit_bookings',
  CHECKOUT_DISABLED = 'checkout_disabled_for_multivenue',
  PREORDER_GAMA = 'preorder_gama',
}

export enum CMS_CONTENT_MODAL {
  CHECKOUT_DISABLED_FOR_MULTIVENUE = 'multivenue_checkout_disabled_modal',
  SETTINGS_SENDER_NAME_INFO = 'settings_sender_name_info',
  ASSOCIATE_ORDER_DELIVERY_NOTE = 'associate_order_delivery_note',
  CHECKOUT_CUSTOMER_DEBTS_DISABLED = 'checkout_customer_debts_disabled',
  CHECKOUT_LINE_ITEM_INFO_DISCLAIMER = 'checkout_rch_multiuse_promotion_bundle_price',
  OPENING_HOURS_MODAL = 'opening_hours_modal',
  GDPR_CONSENT = 'gdpr_consent',
  PROMOTIONS_DISCOUNT_NOT_VISIBLE_ON_MARKETPLACE_FOR_STAFF_MEMBERS = 'promotions_discount_not_visible_on_marketplace_for_staff_members',
}

export enum CMS_CONTENT_WIZARD {} // TODO: populate with wizard sections

export enum CMS_BLOCK_NAME {
  HERO = 'uala/cms-hero',
  FOOTER = 'uala/cms-footer',
  IMAGE_CONTENT = 'uala/cms-image-content',
  CAROUSEL_CONTENT = 'uala/cms-carousel-content',
  VIDEO_CONTENT = 'uala/cms-video-content',
  SURVEY = 'uala/cms-survey-content',
  FEATURES_LIST = 'uala/cms-features-list-content',
  VIDEO_FULL_WIDTH = 'uala/cms-video-full-width',
}
interface CMSPageBlockBase {
  blockName: CMS_BLOCK_NAME;
  title?: string;
}
interface CMSPageBlockBanner extends CMSPageBlockBase {
  blockName: CMS_BLOCK_NAME.HERO | CMS_BLOCK_NAME.FOOTER;
  kickerText?: string;
  description?: string;
  ctaText?: string;
  ctaLink?: string;
}
export type CMSImage = {
  medium: string;
  large: string;
};
export const CMS_BLOCK_DESCRIPTION_STYLE = {
  LIST: 'list',
  TEXT: 'text',
} as const;

export type CmsBlockDescriptionStyle = ValueOf<typeof CMS_BLOCK_DESCRIPTION_STYLE>;

export const CMS_BLOCK_CTA_STYLE = {
  PRIMARY: 'primary',
  OUTLINE: 'outline',
} as const;

export type CmsBlockCtaStyle = ValueOf<typeof CMS_BLOCK_CTA_STYLE>;

export const CMS_BLOCK_IMAGE_ALIGNMENT = {
  LEFT: 'left',
  RIGHT: 'right',
} as const;

export type CmsBlockImageAlignment = ValueOf<typeof CMS_BLOCK_IMAGE_ALIGNMENT>;
export interface CMSPageBlockHero extends CMSPageBlockBanner {
  blockName: CMS_BLOCK_NAME.HERO;
}
export interface CMSPageBlockFooter extends CMSPageBlockBanner {
  blockName: CMS_BLOCK_NAME.FOOTER;
  footerText?: string;
}
export interface CMSPageBlockCarouselContentItem {
  title?: string;
  description?: string;
  descriptionStyle: CmsBlockDescriptionStyle;
  image?: CMSImage;
}
export interface CMSPageBlockCarouselContent extends CMSPageBlockBase {
  blockName: CMS_BLOCK_NAME.CAROUSEL_CONTENT;
  subtitle?: string;
  items?: CMSPageBlockCarouselContentItem[];
  paddingVertical?: number;
  paddingHorizontal?: number;
}
export interface CMSPageBlockImageContent extends CMSPageBlockBase {
  blockName: CMS_BLOCK_NAME.IMAGE_CONTENT;
  image?: CMSImage;
  description?: string;
  ctaStyle?: CmsBlockCtaStyle;
  ctaText?: string;
  ctaLink?: string;
  imageAlignment?: CmsBlockImageAlignment;
}
export interface CMSPageBlockVideoContent extends CMSPageBlockBase {
  blockName: CMS_BLOCK_NAME.VIDEO_CONTENT;
  title?: string;
  videoId?: string;
  videoType?: string;
  kickerText?: string;
  quoteText?: string;
  ctaDescription?: string;
  ctaText?: string;
  ctaLink?: string;
  ctaStyle?: CmsBlockCtaStyle;
}

export interface CMSPageBlockVideoFullWidth extends CMSPageBlockBase {
  blockName: CMS_BLOCK_NAME.VIDEO_FULL_WIDTH;
  title?: string;
  videoId?: string;
  videoType?: string;
  kickerText?: string;
  ctaDescription?: string;
  ctaText?: string;
  ctaLink?: string;
  ctaStyle?: CmsBlockCtaStyle;
}

export type CMSPageBlock =
  | CMSPageBlockHero
  | CMSPageBlockFooter
  | CMSPageBlockCarouselContent
  | CMSPageBlockImageContent
  | CMSPageBlockVideoContent
  | CMSPageBlockVideoFullWidth;

export type CMSPageData = {
  id: number;
  date: string;
  slug: string;
  title: string;
  blocks: CMSPageBlock[];
};

export type CMSBlockProps<CMSPageBlockModel> = Omit<Omit<Omit<CMSPageBlockModel, 'blockName'>, 'state'>, 'setState'>;

/**
 * Wizard
 * The interface of blocks from CMS
 */
export type CMSWizardData = {
  id: number;
  date: string;
  slug: string;
  title: string;
  blocks: CMSWizardBlock[];
};

/**
 * Wizard config
 * These are the interfaces of both props and config of wizard blocks
 */
type CMSWizardSharedBlockConfig = {
  blockIndex?: number;
  last?: boolean;
  primaryCTAText?: string;
  secondaryCTAText?: string;
  state?: Record<string, string | number>;
  setState?: (v: CMSWizardSharedBlockConfig['state']) => void;
  isValid?: boolean;
  setIsValid?: (valid: boolean) => void;
};

export type CMSWizardBlockIndexBased = CMSWizardSharedBlockConfig & {
  blockName?: string;
};

export type CMSWizardBlockSurvey = CMSWizardSharedBlockConfig & {
  blockName: CMS_BLOCK_NAME.SURVEY;
  kickerText: string;
  description: string;
  question: string;
  trackingKey: string;
  options?: Array<{
    value: string;
    label: string;
  }>;
  state: CMSWizardBlockSurveyState;
  setState: (v: Partial<CMSWizardBlockSurvey['state']>) => void;
};

export type CMSWizardBlockFeaturesList = CMSWizardSharedBlockConfig & {
  blockName: CMS_BLOCK_NAME.FEATURES_LIST;
  kickerText: string;
  title: string;
  description: string;
  meaning: string;
  featuresList: Array<{
    id: number;
    name: string;
  }>;
};

export type CMSWizardBlockConfig = CMSWizardBlockIndexBased | CMSWizardBlockSurvey | CMSWizardBlockFeaturesList;
export type CMSWizardBlock = CMSWizardBlockSurvey | CMSWizardBlockFeaturesList;

/**
 * Wizard State
 * These are the interfaces of the state of every block
 */
export type CMSWizardBlockSurveyState = {
  blockIndex: number;
  blockName: CMS_BLOCK_NAME.SURVEY;
  trackingKey: string;
  value: string;
};

export type CMSWizardBlockFeaturesListState = {
  blockIndex: number;
  blockName: CMS_BLOCK_NAME.FEATURES_LIST;
};

export type CMSWizardState = Array<CMSWizardBlockSurveyState | CMSWizardBlockFeaturesListState>;

/**
 * Modal
 */
export type CMSModalBlock = CMSPageBlockImageContent;

export type CMSModalData = {
  id: number;
  date: string;
  slug: string;
  title: string;
  blocks: CMSModalBlock[];
};
