/* eslint-disable no-restricted-imports */
import { AddVenueProductBody, AddVenueProductResponse } from 'API/types';
import { Http } from 'API/Http';
import { ENDPOINT } from 'API/ENDPOINT';
import { V } from 'API';
import { responseToJSON } from 'API/http.utils';

export const ADD =
  ({ variables }: V<AddVenueProductBody>) =>
  (): Promise<AddVenueProductResponse> =>
    Http()
      .post<AddVenueProductResponse, never, AddVenueProductBody>(ENDPOINT.VENUE_PRODUCTS._ADD())({
        body: {
          ...variables,
        },
      })
      .then(responseToJSON);
