import { styled, css } from 'styles';

import { withTheme } from 'components/core';

const FloatingActionButton = withTheme(styled('button')`
  outline: none;
  position: relative;
  border: none;
  cursor: pointer;
  padding: 9px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.25);
  margin-top: 15px;
`);

const NotificationBadge = withTheme(styled('div')`
  position: absolute;
  top: -5px;
  right: -5px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.red};
  padding: 1px 5px;
  font-size: 12px;
  border-radius: 50%;
`);

const configurationIconsMarginRight = css`
  margin-right: 10px;
`;

const WizardAssistantWrapper = styled('div')`
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 1000;
`;

const WizardModalWrapper = withTheme(styled('div')`
  width: 302px;

  ${({ theme: { media } }) => css`
    ${media.smallMobile`
      width: 290px;
    `};
  `}
`);

export {
  FloatingActionButton,
  NotificationBadge,
  configurationIconsMarginRight,
  WizardAssistantWrapper,
  WizardModalWrapper,
};
