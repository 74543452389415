// Init webpack remotes
// 1. each remote should expose a `remoteEntry` module
// 2. `remoteEntry` module should export a `registerRemote` function
//
// -----------------
//
// # [REMOTE] webpack.config.js
//
// new ModuleFederationPlugin({
//   name: "twstore",
//   filename: "remoteEntry.js",
//   exposes: {
//     "./remoteEntry": "./src/remoteEntry",
//
// -----------------
//
// # [REMOTE] remoteEntry.js
//
// export const registerRemote = (register) => {
//     register({
//

export async function initialiseRemote(scope, done) {
  //  Initializes the share scope. This fills it with known provided modules from this build and all remotes
  //  await window.__webpack_init_sharing__('default');
  const container = window[scope];
  await container.init();
  //   Initialize the container, it may provide shared modules
  //   await container.init(window.__webpack_share_scopes__.default);
  const factory = await container.get('./remoteEntry');
  const { registerRemote } = await factory();

  done(registerRemote);
}
