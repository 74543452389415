import { Shipper, ShippingOption } from 'models';
import { REQUEST_SHIPPERS, RESPONSE_SHIPPERS, REQUEST_SHIPPING_OPTIONS, RESPONSE_SHIPPING_OPTIONS } from 'actionTypes';

import { reducerValuesOnResponse, reducerValuesOnInit, reducerValuesOnRequest } from 'utils/reducersUtils';
import { StandardAction } from 'actions/types';
import {
  DefaultRequestPayload,
  ShippersActions,
  ShippingOptionsActions,
  ResponseShippersPayload,
  ResponseShippingOptionPayload,
} from 'actions/shippingOptions';

/*
 * Shippers reducer
 */
type ShippersState = {
  items?: Shipper[];
  isFetching?: boolean | number;
  didInvalidate?: boolean;
};

const shippers = (
  state: ShippersState = reducerValuesOnInit(),
  action: StandardAction<ShippersActions, DefaultRequestPayload | ResponseShippersPayload>
): ShippersState => {
  switch (action.type) {
    case REQUEST_SHIPPERS:
      return { ...state, ...reducerValuesOnRequest() };
    case RESPONSE_SHIPPERS:
      return {
        ...state,
        ...reducerValuesOnResponse((action.payload as ResponseShippersPayload).data?.ecommerce_shippers || [], {
          overrideDuplicateIds: 1,
        }),
      };
    default:
      return state;
  }
};

export const shippersByVenue = (
  state: { [venue_id: number]: ShippersState } = {},
  action: StandardAction<ShippersActions, DefaultRequestPayload | ResponseShippersPayload>
): ShippersState => {
  if (action.payload?.venue_id) {
    const { venue_id } = action.payload;
    const refState = shippers(state[venue_id], action);

    if (refState === state[venue_id]) {
      return state;
    }

    return {
      ...state,
      [venue_id]: refState,
    };
  }

  return state;
};

/*
 * Shipping options reducers
 */
type ShippingOptionsState = {
  items?: ShippingOption[];
  isFetching?: boolean | number;
  didInvalidate?: boolean;
};

const shippingOptions = (
  state: ShippingOptionsState = reducerValuesOnInit(),
  action: StandardAction<ShippingOptionsActions, DefaultRequestPayload | ResponseShippingOptionPayload>
): ShippingOptionsState => {
  switch (action.type) {
    case REQUEST_SHIPPING_OPTIONS:
      return { ...state, ...reducerValuesOnRequest() };
    case RESPONSE_SHIPPING_OPTIONS:
      return {
        ...state,
        ...reducerValuesOnResponse(
          (action.payload as ResponseShippingOptionPayload).data?.ecommerce_shipping_options || [],
          { overrideDuplicateIds: 1 }
        ),
      };
    default:
      return state;
  }
};

export const shippingOptionsByVenue = (
  state: { [venue_id: number]: ShippingOptionsState } = {},
  action: StandardAction<ShippingOptionsActions, DefaultRequestPayload | ResponseShippingOptionPayload>
): ShippingOptionsState => {
  if (action.payload?.venue_id) {
    const { venue_id } = action.payload;
    const refState = shippingOptions(state[venue_id], action);

    if (refState === state[venue_id]) {
      return state;
    }

    return {
      ...state,
      [venue_id]: refState,
    };
  }

  return state;
};
