import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

export const paymentMethods = (state = reducerValuesOnInit(), action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case 'PAYMENT_METHODS_LIST_REQUEST':
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case 'PAYMENT_METHODS_LIST_RESPONSE':
      if (!action.payment_methods) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.payment_methods || [])),
      };

    default:
      return state;
  }
};
