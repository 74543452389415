import {
  AGENDA_ITEM_CUT,
  AGENDA_RESET_CUT_ITEMS,
  AGENDA_SET_ACTIVE_CUSTOMER,
  AGENDA_RESET_ACTIVE_CUSTOMER,
  AGENDA_UPDATE_NEW_CUSTOMER_DATA,
  AGENDA_RESET_NEW_CUSTOMER_DATA,
} from 'actionTypes';
import { Customer } from 'models';

export type AgendaGridVenue = {
  activeCustomer: Customer | null;
  newCustomer: Partial<Customer> | null;
  cutItemId: number | null;
};

export type AgendaGrid = {
  [venue_id: number]: AgendaGridVenue;
};

type Action = {
  type: string;
  venue_id: number;
  id: number;
  customer: Customer;
};

const defaultVenueState: AgendaGridVenue = {
  cutItemId: null,
  activeCustomer: null,
  newCustomer: null,
};

const defaultState: AgendaGrid = {};

const agendaGrid = (state = defaultVenueState, action: Action): AgendaGridVenue => {
  switch (action.type) {
    case AGENDA_SET_ACTIVE_CUSTOMER:
      if (!action.customer) {
        return state;
      }
      return {
        ...state,
        activeCustomer: action.customer,
        newCustomer: null,
      };

    case AGENDA_RESET_ACTIVE_CUSTOMER:
      return {
        ...state,
        activeCustomer: null,
      };

    case AGENDA_UPDATE_NEW_CUSTOMER_DATA:
      return {
        ...state,
        newCustomer: action.customer ? { ...state.newCustomer, ...action.customer } : null,
        activeCustomer: null,
      };

    case AGENDA_RESET_NEW_CUSTOMER_DATA:
      return {
        ...state,
        newCustomer: null,
      };

    case AGENDA_ITEM_CUT:
      if (!action.id) {
        return state;
      }
      return {
        ...state,
        cutItemId: action.id,
      };

    case AGENDA_RESET_CUT_ITEMS:
      return {
        ...state,
        cutItemId: null,
      };

    default:
      return state;
  }
};

export const agendaGridByVenue = (state = defaultState, action: Action): AgendaGrid => {
  switch (action.type) {
    default:
      return state;

    case AGENDA_SET_ACTIVE_CUSTOMER:
    case AGENDA_RESET_ACTIVE_CUSTOMER:
    case AGENDA_ITEM_CUT:
    case AGENDA_RESET_CUT_ITEMS:
    case AGENDA_UPDATE_NEW_CUSTOMER_DATA:
    case AGENDA_RESET_NEW_CUSTOMER_DATA:
      if (!action.venue_id) {
        return state;
      }
      return {
        ...state,
        [action.venue_id]: agendaGrid(state[action.venue_id], action),
      };
  }
};
