import { FunctionComponent } from 'react';
import { MultiVenueLogoButton } from 'components/multi_venue_next';
import { Wrapper } from './HeaderTriggerMultiVenueSidebar.theme';
import { Venue } from 'models';

export type HeaderTriggerMultiVenueSidebarProps = {
  isMultiVenue: boolean;
  currentVenue: Venue | null;
  dispatchToggleMultiVenueSidebar?: (params: boolean) => void;
};

const HeaderTriggerMultiVenueSidebar: FunctionComponent<HeaderTriggerMultiVenueSidebarProps> = ({
  isMultiVenue,
  currentVenue,
  dispatchToggleMultiVenueSidebar = () => {},
}) => {
  if (!isMultiVenue || !currentVenue || !currentVenue.id) return null;

  return (
    <Wrapper
      data-testid="multi-venue-sidebar-trigger-button"
      onClick={(): void => dispatchToggleMultiVenueSidebar(true)}
    >
      <MultiVenueLogoButton
        venueId={currentVenue.id}
        venueFullName={currentVenue.name}
        venueShortName={currentVenue.short_code}
        imageSrc={currentVenue.logo_image_url}
        isShortNameHidden
      />
    </Wrapper>
  );
};

export default HeaderTriggerMultiVenueSidebar;
