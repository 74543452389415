import { styled, css } from 'styles';
import { withTheme } from 'components/core';

const height = 26;

export const StyledLanguageSwitcher = styled('div')(
  ({ display }) => css`
    display: ${display || 'inline-block'};
    position: relative;
    height: ${height}px;
  `
);

export const StyledLanguageValue = withTheme(
  styled('div')(
    ({ theme: { colors } }) => css`
      position: relative;
      box-sizing: border-box;
      display: flex;

      align-items: center;
      padding: 5px;
      width: 100%;
      height: ${height}px;
      line-height: ${height - 10}px;
      background-color: ${colors.gray};
      border-radius: ${height / 2}px;

      > * {
        display: block;
      }

      > img {
        margin-right: 5px;
        width: 16px;
        height: 16px;
        border-radius: 100%;
        flex: none;
      }
    `
  )
);

export const StyledLanguageOptions = withTheme(
  styled('div')(
    ({ theme: { colors }, position = 'bottom' }) => css`
      position: absolute;
      z-index: 2;
      box-sizing: border-box;
      width: 100%;
      bottom: ${position === 'top' ? 0 : 'auto'};
      top: ${position === 'bottom' ? 0 : 'auto'};
      background-color: ${colors.gray};
      border-radius: ${height / 2}px;
    `
  )
);
