import { StaffMember } from 'models';
import { createSelector } from 'reselect';
import { getCurrentVenueId } from './getCurrentVenue';
import { getEntities } from './getEntities';
import filterDeletedItems from 'utils/filterDeletedItems';
import { State } from 'reducers/types';

export const getStaffMembersByVenue = (state: State) => state.staffMembersByVenue;

export const getStaffMembersLoading = createSelector(
  [getCurrentVenueId, getStaffMembersByVenue],
  (venue_id, staffMembersByVenue) => {
    return venue_id ? !!staffMembersByVenue[venue_id]?.isFetching : false;
  }
);

const emptyStaffMembers: StaffMember[] = [];
export const getStaffMembersList = createSelector(
  [getCurrentVenueId, getStaffMembersByVenue, getEntities.staff_member],
  (venue_id, staffMembersByVenue, staffMemberById) => {
    const staffMembers = venue_id ? staffMembersByVenue[venue_id]?.items || emptyStaffMembers : emptyStaffMembers;
    if (staffMembers === emptyStaffMembers) {
      return staffMembers;
    }
    return staffMembers
      .map((staffMember: StaffMember) => ({
        ...staffMember,
        ...((staffMemberById[staffMember.id] && staffMemberById[staffMember.id].data) || {}),
      }))
      .filter(filterDeletedItems)
      .sort((a, b) => (a.position > b.position ? 1 : -1));
  }
);

export const getStaffMembersListCount = createSelector([getStaffMembersList], (staffMembersList) => {
  return staffMembersList.length;
});

export const getStaffMembersById = createSelector([getStaffMembersList], (staffMembersList) => {
  return staffMembersList.reduce(
    (staffMembersById, staffMember) => {
      if (staffMember.id) {
        staffMembersById[staffMember.id] = staffMember;
      }
      return staffMembersById;
    },
    {} as { [key: number]: StaffMember }
  );
});

export const getAgendaStaffMembersList = createSelector(getStaffMembersList, (staffMembersList) => {
  /**
   * staff_member roles:
   * junior senior stylist master top receptionist room
   * 2020-02-13 new staff member role 'room' hidden from agenda
   */
  return staffMembersList.filter(({ role }) => role !== 'receptionist' && role !== 'room');
});

export const getAgendaStaffMembersListCount = createSelector([getAgendaStaffMembersList], (agendaStaffMembersList) => {
  return agendaStaffMembersList.length;
});

export const getRoomsList = createSelector(getStaffMembersList, (staffMembersList) => {
  return staffMembersList.filter(({ role }) => role === 'room');
});

export const areAllStaffMembersReceptionists = createSelector(getStaffMembersList, (staffMembersList) => {
  return staffMembersList.every(({ role }) => role === 'receptionist' || role === 'room');
});
