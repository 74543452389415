import { isElectron } from 'utils/native/cash';

const ipcRenderer = isElectron() && window.require('electron').ipcRenderer;

export const ipcSend: <T>(eventName: string, data?: T) => void = (eventName, data) => {
  if (isElectron()) {
    ipcRenderer.send(eventName, data);
  }
};

export const electronGetGlobal = <T>(key: string): T =>
  (isElectron() && window.require('electron').remote.getGlobal(key)) || null;
