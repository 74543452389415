import { APIResponseAction, REDUX_API_ACTION_TYPES } from 'actions/data_providers/data_providers_api.types';
import { Package } from 'models';
import {
  API_GET_RESPONSE,
  API_POST_RESPONSE,
  RECEIVE_PACKAGE_LIST,
  REQUEST_PACKAGE_LIST,
  RECEIVE_NEW_PACKAGE,
  RECEIVE_DELETED_PACKAGE,
  RECEIVE_UPDATED_PACKAGE,
} from 'actionTypes';
import { StandardAction } from 'actions/types';
import { PackagesActions } from 'actions/packages';
import { standardiseAction } from 'utils/data-layer/standardiseAction';
import filterDeletedItems from 'utils/filterDeletedItems';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

type PackageState = {
  items?: ReadonlyArray<Package>;
  isFetching?: boolean | number;
  didInvalidate?: boolean;
};

type ObservedActions = PackagesActions | APIResponseAction;
type ReducedStandardActions =
  | PackagesActions
  | StandardAction<
      typeof REDUX_API_ACTION_TYPES.GET_RESPONSE | typeof REDUX_API_ACTION_TYPES.POST_RESPONSE,
      { packages: Package[]; venue_id: number }
    >;

const packages = (state: PackageState = reducerValuesOnInit(), action: ReducedStandardActions): PackageState => {
  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE: {
      if (!action.payload?.packages || !action.payload?.packages.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse((state.items || []).concat(action.payload.packages.filter(filterDeletedItems)), {
          avoidDuplicateIds: 1,
        }),
      };
    }
    case RECEIVE_NEW_PACKAGE:
      if (!action.payload?.package || !state.items) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse((state.items || []).concat(action.payload.package), {
          avoidDuplicateIds: 1,
        }),
      };
    case REQUEST_PACKAGE_LIST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case RECEIVE_PACKAGE_LIST:
      if (!action.payload?.packages) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([...action.payload.packages]),
      };

    case RECEIVE_UPDATED_PACKAGE: {
      if (!action.payload?.package || !state.items) {
        return state;
      }

      const index = state.items.findIndex((vtg) => vtg.id === action.payload?.package.id);
      const updatedItems = Object.assign([], state.items, { [index]: action.payload?.package });

      return {
        ...state,
        ...reducerValuesOnResponse(updatedItems),
      };
    }

    case RECEIVE_DELETED_PACKAGE:
      if (!action.payload?.package || !state.items) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(state.items.filter(({ id }) => id !== action.payload?.package.id)),
      };

    default:
      return state;
  }
};

type PackageStateByVenue = {
  [venue_id: number]: PackageState;
};

const packagesByVenue = (state: PackageStateByVenue = {}, action: ObservedActions): PackageStateByVenue => {
  let refState;

  switch (action.type) {
    case RECEIVE_PACKAGE_LIST:
    case REQUEST_PACKAGE_LIST:
    case RECEIVE_NEW_PACKAGE:
    case RECEIVE_DELETED_PACKAGE:
    case RECEIVE_UPDATED_PACKAGE: {
      const standardAction = standardiseAction(action) as PackagesActions;

      if (!standardAction.payload?.venue_id) {
        return state;
      }

      const { venue_id } = standardAction.payload;
      refState = packages(state[venue_id], standardAction);

      if (refState === state[venue_id]) {
        return state;
      }

      return {
        ...state,
        [venue_id]: refState,
      };
    }
    default:
      return state;
  }
};

export { packagesByVenue };
