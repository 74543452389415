import { loadRemote } from './remoteInit';
import { Remote, RemoteModule, RemoteNamespace } from './types';

//
// Load and Init remotes

type GetMatchingRemotes = (args: { remotes: Remote[]; scope?: string; namespace: RemoteNamespace }) => Remote[];

export const getMatchingRemotes: GetMatchingRemotes = ({ remotes, scope, namespace }) => {
  const matchingRemotes = remotes
    .filter((remote) => !scope || remote.scope === scope)
    .filter((remote) => !namespace || remote.namespaces.includes(namespace));

  return matchingRemotes;
};

type InitRM = (args: { remotes: Remote[]; scope?: string; namespace: RemoteNamespace }, done: () => void) => void;

export const initRemoteModulesByNamespace: InitRM = ({ remotes, scope, namespace }, done) => {
  const initializedRemotes = new Set();
  const matchingRemotes = getMatchingRemotes({ remotes, scope, namespace });

  matchingRemotes.forEach((remote) => {
    loadRemote(
      remote,
      function onInit(registerRemote) {
        try {
          registerRemote(registerWithinRemote(remote));
        } catch (error) {
          console.error('Remote `' + scope + '` registerRemote() has generated the following error:', error);
        }
      },
      function onReady() {
        initializedRemotes.add(remote);
        if (matchingRemotes.every((matchingRemote) => initializedRemotes.has(matchingRemote))) {
          done();
        }
      }
    );
  });
};

type GetMatchingRemoteModules = (args: { scope?: string; namespace: RemoteNamespace }) => RemoteModule[];

export const getInitializedRemoteModules: GetMatchingRemoteModules = ({ scope, namespace }) => {
  const matchingModules = [...registeredRemoteModules]
    .filter((module) => !scope || module.remote?.scope === scope)
    .filter((module) => !namespace || module.namespace === namespace);

  matchingModules.sort((a, b) => a.order - b.order);

  return matchingModules;
};

//
// REGISTRY:
// {
//   "url": "http://localhost:3004/remoteEntry.js",
//   "scope": "twstore",
//   "namespaces": [
//       "store.homepage"
//   ]
// }
//
// REMOTE:
// register({
//   // the following namespace must be in REGISTRY namespaces:
//   namespace: 'store.homepage',
//   order: 7,
//   render: ({ eventEmitter, rootNode, homepage, payload }) => {
//       ReactDOM.createRoot(rootNode).render(<Homepage eventEmitter={eventEmitter} basename={basename} />);
//   }
// })

interface CustomWindow extends Window {
  registeredRemoteModules: Set<RemoteModule>;
}
declare const window: CustomWindow;

window.registeredRemoteModules = new Set<RemoteModule>();
const registeredRemoteModules = new Set<RemoteModule>();

type RegisterWithinRemote = (remote: Remote) => (module: RemoteModule) => void;

export const registerWithinRemote: RegisterWithinRemote = (remote) => (module) => {
  // Check allowed namespaces:
  if (remote.namespaces.includes(module.namespace)) {
    module.remote = remote;
    registeredRemoteModules.add(module);
  }
};
