import { TS_FIXME } from './utilityTypes';

type PathImpl<T, Key extends keyof T> = Key extends string
  ? T[Key] extends Record<string, TS_FIXME>
    ? // our eslint doesn't handle correctly type interpolation, we have to disable it for the following line
      | `${Key}.${PathImpl<T[Key], Exclude<keyof T[Key], keyof TS_FIXME[]>> & string}` //eslint-disable-line
        | `${Key}.${Exclude<keyof T[Key], keyof TS_FIXME[]> & string}`
    : never
  : never;

type PathImpl2<T> = PathImpl<T, keyof T> | keyof T;

export type PathGetPath<T> = PathImpl2<T> extends string | keyof T ? PathImpl2<T> : keyof T;

type PathValue<T, P extends PathGetPath<T>> = P extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
    ? Rest extends PathGetPath<T[Key]>
      ? PathValue<T[Key], Rest>
      : never
    : never
  : P extends keyof T
  ? T[P]
  : never;

type GetIndexedField<T, K> = K extends keyof T
  ? T[K]
  : K extends `${number}`
  ? '0' extends keyof T
    ? undefined
    : number extends keyof T
    ? T[number]
    : undefined
  : undefined;

type FieldWithPossiblyUndefined<T, Key> = GetFieldType<Exclude<T, undefined>, Key> | Extract<T, undefined>;

type IndexedFieldWithPossiblyUndefined<T, Key> = GetIndexedField<Exclude<T, undefined>, Key> | Extract<T, undefined>;

export type GetFieldType<T, P> = P extends `${infer Left}.${infer Right}`
  ? Left extends keyof T
    ? FieldWithPossiblyUndefined<T[Left], Right>
    : Left extends `${infer FieldKey}[${infer IndexKey}]`
    ? FieldKey extends keyof T
      ? FieldWithPossiblyUndefined<IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>, Right>
      : undefined
    : undefined
  : P extends keyof T
  ? T[P]
  : P extends `${infer FieldKey}[${infer IndexKey}]`
  ? FieldKey extends keyof T
    ? IndexedFieldWithPossiblyUndefined<T[FieldKey], IndexKey>
    : undefined
  : undefined;

/**
 * it returns a value from an object given a string path expressed in dot notation
 *
 * example:
 *
 * const testObj = { name: 'name', treatment: { name: 'treatment_name' }}
 *
 * pathGet(testObj, 'name') // returns 'name'
 * pathGet(testObj, 'treatment.name') // returns 'treatment_name'
 */
export function pathGet<TData, TPath extends PathGetPath<TData>>(data: TData, path: TPath): PathValue<TData, TPath> {
  if (typeof path !== 'string') throw new Error('unsupported non-string paths');

  const value = path
    .split(/[.[\]]/)
    .filter(Boolean)
    .reduce<PathValue<TData, TPath>>((v, key) => (v as TS_FIXME)?.[key], data as TS_FIXME);

  return value;
}
