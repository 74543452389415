/* eslint-disable no-restricted-imports */
import { FiscalTransactionReportBody, FiscalTransactionReportResponse } from 'API/types';
import { Http } from 'API/Http';
import { ENDPOINT } from 'API/ENDPOINT';
import { V } from 'API';
import { maybeResponseToJSON } from 'API/http.utils';

export const FISCAL_TRANSACTION_REPORT =
  ({ variables: { start_date, end_date } }: V<FiscalTransactionReportBody>) =>
  (): Promise<FiscalTransactionReportResponse> =>
    Http()
      .post<FiscalTransactionReportResponse, never, FiscalTransactionReportBody>(
        ENDPOINT.MANAGER._FISCAL_TRANSACTION_REPORT()
      )({
        body: {
          start_date,
          end_date,
        },
      })
      .then(maybeResponseToJSON);
