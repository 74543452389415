import { enabledBrandsByName } from './containsEnabledBrand';

type AvailableSections = {
  diary: boolean;
  benchmark: boolean;
  salon: boolean;
  income_statement: boolean;
  staff_members: boolean;
};

type EnabledSectionsByBrand = Record<(typeof enabledBrandsByName)[number], AvailableSections>;

const enabledSectionsByBrand: EnabledSectionsByBrand = {
  aveda: {
    diary: true,
    benchmark: true,
    salon: false,
    income_statement: false,
    staff_members: false,
  },
  la_biosthetique: {
    diary: false,
    benchmark: false,
    salon: true,
    income_statement: true,
    staff_members: true,
  },
} as const;

const isSectionEnabledByBrand = (brandName: string | undefined, section: keyof AvailableSections): boolean =>
  brandName && brandName in enabledSectionsByBrand
    ? enabledSectionsByBrand[brandName as keyof typeof enabledSectionsByBrand][section]
    : false;

export { isSectionEnabledByBrand };
