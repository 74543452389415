import { Component } from 'react';
import PropTypes from 'prop-types';
import StyledSwitch from './theme';

class Switch extends Component {
  state = {
    checked: this.props.defaultChecked || false,
  };

  componentDidUpdate({ defaultChecked }) {
    if (this.props.defaultChecked !== defaultChecked) {
      this.setState({ checked: this.props.defaultChecked });
    }
  }

  handleChange = (event) => {
    const { beforeChange, onChange } = this.props;
    const tmp = event.target.checked;
    let checked = tmp;

    if (beforeChange) {
      checked = beforeChange(tmp);
    }
    this.setState({ checked }, () => onChange(checked));
  };

  render() {
    const { checked } = this.state;
    const { name } = this.props;

    return (
      <StyledSwitch {...this.props}>
        <label>
          <StyledSwitch.Input
            name={name}
            type="checkbox"
            checked={checked}
            onChange={this.props.onChange ? this.handleChange : null}
          />
          <StyledSwitch.Slider className="Switch__Slider" data-testid={this.props.dataTestId} />
        </label>
      </StyledSwitch>
    );
  }
}

Switch.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
