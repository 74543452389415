import { FunctionComponent, ReactElement, useCallback } from 'react';
import StyledButtonUpgrade from './ProvisioningUpsellingButton.theme';
import { Text, Icon } from 'components/core';
import { VENUE_ACTIVE_FEATURE_FLAG, Venue } from 'models';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export type ProvisioningUpsellingButtonProps = {
  noIcons?: boolean;
  venue?: Venue | null;
  selfProvisioningEnabled?: boolean;
};

export const ProvisioningUpsellingButton: FunctionComponent<ProvisioningUpsellingButtonProps & RouteComponentProps> = ({
  noIcons = false,
  history,
  venue,
  selfProvisioningEnabled,
}): ReactElement | null => {
  const handleOnClick = useCallback(() => {
    history.push('/plans');
  }, [history]);

  if (!venue || (venue && venue[VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_WAREHOUSE]) || !selfProvisioningEnabled) {
    return null;
  }

  return (
    <StyledButtonUpgrade noIcons={noIcons} onClick={handleOnClick}>
      {!noIcons && <Icon type="cart" />}
      <Text size={12} bold intl="upgrade.cta_2" />
    </StyledButtonUpgrade>
  );
};

export default withRouter(ProvisioningUpsellingButton);
