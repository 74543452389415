const toTitleCase = (str = '') => {
  if (str) {
    str = str.toLowerCase().split(' ');
    str.forEach((_, i) => {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    });
    return str.join(' ');
  }
  return str;
};

export default toTitleCase;
