import { Platform } from './interfaces';
import { getDevice } from './getUserAgentParsed';

export const getPlatform = (): Platform => {
  if (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) < 768) {
    return 'mobile';
  } else if (getDevice().type === 'tablet') {
    return 'tablet';
  }
  return 'desktop';
};
