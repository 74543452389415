import { devLogger } from 'utils/synch/utils';

type PollingConfig = {
  timeout: number;
  callback: () => Promise<void>;
  timer?: number;
  lastExecutionTime?: number;
};

type StartPolling = (
  key: string,
  options: { timeout: PollingConfig['timeout']; callback: PollingConfig['callback']; skipFirst?: boolean }
) => Promise<void>;

type StopPolling = (key?: string) => Promise<void>;
type PausePolling = (key?: string) => Promise<void>;
type ResumePolling = (key?: string) => Promise<void>;

type PollingPool = Record<string, PollingConfig>;

const pollingPool: PollingPool = {};

document.addEventListener('visibilitychange', () => {
  switch (document.visibilityState) {
    case 'hidden':
      pausePolling();
      devLog(`Polling Paused ⏸️ ${Object.keys(pollingPool).length} Venues`);
      break;
    case 'visible':
      resumePolling();
      devLog(`Polling Resumed ⏯️ ${Object.keys(pollingPool).length} Venues`);
      break;
  }
});

const start = (key: string, { skipFirst }: { skipFirst?: boolean }): void => {
  if (!(key in pollingPool)) return;

  const { callback, timeout, lastExecutionTime } = pollingPool[key];

  const handler = async (): Promise<void> => {
    if (!(key in pollingPool)) return;

    devLog(
      `Polling ${key}, last fetch was`,
      lastExecutionTime
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          `${(Date.now() - lastExecutionTime) / 1000}s ago`
        : `not done yet!`
    );
    pollingPool[key].lastExecutionTime = Date.now();
    await callback();
    pollingPool[key].timer = window.setTimeout(handler, timeout);
    return;
  };

  /**
   * execute callback if `skipFirst` option is false
   */
  if (!skipFirst || (lastExecutionTime && lastExecutionTime + timeout < Date.now())) {
    devLog('timeout exceeded, execute polling task', key);
    handler();
  } else {
    pollingPool[key].timer = window.setTimeout(handler, timeout);
  }
  devLog(`${key} Started`);
};

export const startPolling: StartPolling = (key, { timeout, callback, skipFirst }) =>
  new Promise((resolve, reject) => {
    if (pollingPool[key]) {
      return reject('Duplicate key ' + key);
    }

    pollingPool[key] = {
      timeout,
      callback,
    };
    start(key, { skipFirst });

    resolve();
  });

const stop = (key: string): void => {
  if (!pollingPool[key]) return;

  window.clearTimeout(pollingPool[key].timer);
  devLog(`${key} Stopped`);
};

export const stopPolling: StopPolling = (key) =>
  new Promise((resolve) => {
    if (key) {
      stop(key);
      delete pollingPool[key];
    } else {
      for (const k of Object.keys(pollingPool)) {
        stop(k);
        delete pollingPool[k];
      }
    }

    resolve();
  });

export const pausePolling: PausePolling = (key) =>
  new Promise((resolve) => {
    if (key) {
      stop(key);
    } else {
      for (const k of Object.keys(pollingPool)) {
        stop(k);
      }
    }

    resolve();
  });

export const resumePolling: ResumePolling = (key) =>
  new Promise((resolve) => {
    if (key) {
      start(key, { skipFirst: true });
    } else {
      for (const k of Object.keys(pollingPool)) {
        start(k, { skipFirst: true });
      }
    }

    resolve();
  });

const devLog = devLogger('POLLING');
