import { Venue, ATCUD, StaffMemberTreatment } from 'models';
import { PatchTestStatusQuery } from './types';
import { getEndpoint, getEndpointByVenue, getManagerEndpointByVenue } from './utils';

export const ENDPOINT = {
  PRODUCTS: {
    _SEARCH: () => getEndpointByVenue('products/search.json'),
    _SHOW: (id: number): string => getEndpointByVenue(`products/${id}`),
  },
  MANUFACTURERS: {
    _SEARCH: (): string => getEndpointByVenue(`manufacturers/search`),
  },
  VENUE_PRODUCTS: {
    _SEARCH: () => getEndpointByVenue('venue_products/search.json'),
    _SEARCH_UNLOADABLES: () => getEndpointByVenue('venue_products/unloadables'),
    _UNLOAD: (id: number): string => getEndpointByVenue(`venue_products/${id}/unload.json`),
    _CORRECT_STOCK: (id: number): string => getEndpointByVenue(`venue_products/${id}/correct_stock.json`),
    _SHOW: (id: number): string => getEndpointByVenue(`venue_products/${id}?next=true`),
    _MOVEMENTS: (id: number): string => getEndpointByVenue(`venue_products/${id}/history`),
    _EDIT: (id: number): string => getEndpointByVenue(`venue_products/${id}?next=true`),
    _LIST: () => getEndpointByVenue('venue_products/list'),
    _LOOKUP: () => getEndpointByVenue('venue_products/lookup'),
    _CATEGORIES: () => getEndpoint()('warehouse/categories.json'),
    _SUBCATEGORIES: ({ by_category_id }: { by_category_id: number }): string =>
      getEndpoint()(`warehouse/subcategories.json?by_category_id=${by_category_id}`),
    _ADD: () => getEndpointByVenue('venue_products/?next=true'),
    _TRANSACTIONS: (id: number): string => getEndpointByVenue(`venue_products/${id}/transactions.json?next=true`),
    _DELETE: (id: number): string => getEndpointByVenue(`venue_products/${id}?next=true`),
    _TRANSACTION_HISTORY: () => getEndpointByVenue(`transactions/recent.json`),
  },
  PATCH_TESTS: {
    _SIGN: (id: number): string => getEndpointByVenue(`patch_test/${id}/sign.json`),
    _PATCH_TEST_STATUS: ({ customer_id, venue_treatment_ids, valid_at }: PatchTestStatusQuery): string =>
      getEndpointByVenue(
        `patch_test_status?customer_id=${customer_id}&venue_treatment_ids=${venue_treatment_ids}&valid_at=${valid_at}`
      ),
  },
  CUSTOMERS: {
    _MISS_DETAILS: (id: number): string => getEndpointByVenue(`customers/${id}/miss_details`),
    _EXPORT: (): string => getEndpointByVenue(`customers/export`),
  },
  MANAGER: {
    _FISCAL_TRANSACTION_REPORT: (): string => getManagerEndpointByVenue('fiscal_transactions_export'),
  },
  VENUE_TREATMENTS: {
    _SEARCH: (): string => getEndpoint()('treatments/search.json'),
    _TREATMENTS: (): string => getEndpoint()('treatments.json'),
    _LOOKUP_DELETED: (id: number): string => getEndpointByVenue(`venue_treatments/${id}`),
  },
  APPOINTMENTS: {
    _CANCELLATION_PROTECTION_AVAILABILITY: (appointmentId: number): string =>
      getEndpointByVenue(`appointments/${appointmentId}/cancellation_protection_availability`),
    _APPOINTMENTS: (): string => getEndpointByVenue(`appointments`),
  },
  CANCELLATION_PROTECTIONS: {
    _INFO_SMS: (venueId: Venue['id']): string =>
      getEndpoint()(`venues/${venueId}/cancellation_protections/info_sms.json`),
    _CONFIRM: (venueId: Venue['id']): string =>
      getEndpoint()(`venues/${venueId}/cancellation_protections/confirm.json`),
    _DELETE: (id: number): string => getEndpointByVenue(`cancellation_protections/${id}`),
  },
  ATCUDS: {
    _SHOW: (): string => getEndpointByVenue('venue_atcud_codes/current'),
    _CREATE: (): string => getEndpointByVenue(`venue_atcud_codes.json`),
    _UPDATE: (id: ATCUD['id']): string => getEndpointByVenue(`venue_atcud_codes/${id}`),
  },
  BILLING_BALANCE: {
    _SHOW: (): string => getEndpointByVenue('billing_company/invoice_summary.json'),
  },
  STAFF_MEMBERS_TREATMENTS: {
    _LOOKUP_DELETED: (id: StaffMemberTreatment['id']) => getEndpointByVenue(`staff_member_treatments/${id}`),
  },
  KYC_VERIFICATION: {
    _CHECK: (): string => getEndpointByVenue('kyc_verifications/check'),
    _STRIPE_ONBOARDING: (): string => getEndpointByVenue('kyc_verifications/stripe_onboarding_url'),
  },
} as const;
