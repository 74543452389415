import { styled, css } from 'styles';
import { withTheme } from '../Context';
import Field, { fieldSizes } from 'components/core/Field/theme';

const sizeStyles = {
  default: css`
    padding: 5px 15px;

    > input {
      height: ${fieldSizes.innerHeight.default() - 10}px;
      line-height: ${fieldSizes.innerHeight.default() - 10}px;
      ${fieldSizes.font.default};
    }
  `,
  small: css`
    padding: 5px 15px;

    > input {
      height: ${fieldSizes.innerHeight.small() - 10}px;
      line-height: ${fieldSizes.innerHeight.small() - 10}px;
      ${fieldSizes.font.small};
    }
  `,
  large: css`
    padding: 10px 15px;

    > input {
      height: ${fieldSizes.innerHeight.large() - 20}px;
      line-height: ${fieldSizes.innerHeight.large() - 20}px;
      ${fieldSizes.font.large};
    }
  `,
};

export const FieldDate = withTheme(
  styled(Field)(
    ({ theme: { utils, colors }, disabled, type, size, right }) => css`
      ${(size && sizeStyles[size]) || sizeStyles.default};
      background-color: ${colors.bgWhite};

      .CalendarDay__selected,
      .CalendarDay__selected_span {
        background-color: ${colors.buttonPrimaryBackground};
        border-color: ${colors.buttonPrimaryBackground};
        color: ${colors.buttonPrimaryColor};
      }

      > input {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        width: 100%;
        border: 0;
        appearance: none;
        outline: none;
        background-color: transparent;
        font-family: ${utils.fontFamily};
      }

      > input::placeholder {
        color: ${colors.inputPlaceHolder};
      }

      .Modal {
        .Card {
          .DayPicker {
            margin: 0 auto;
          }
        }
      }
    `
  )
);
