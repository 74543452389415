import { Venue, BILLING_DETAILS_STATE } from 'models';

const isAccountInGracePeriod = (venue: Venue | null): boolean => {
  if (venue && venue.billing_details && venue.billing_details.state === BILLING_DETAILS_STATE.GRACE_PERIOD) {
    return true;
  }
  return false;
};

export default isAccountInGracePeriod;
