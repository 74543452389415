import { Component, ClassAttributes, ElementType, InputHTMLAttributes, ReactNode } from 'react';
import StyledRadio from './theme';
import Group from './Group';
import { Theme } from 'styles';

export interface RadioProps {
  size: string;
  inverted: boolean;
  disabled: boolean;
  value: string;
  checked: boolean;
  onChange?: (value?: string, order?: number) => void;
  group: boolean;
  dataTestId: string;
  order: number;
  className?: string;
}

interface RadioState {
  checked: boolean;
}

class Radio extends Component<RadioProps, RadioState> {
  static defaultProps: Partial<RadioProps> = {
    size: 'default',
    onChange: () => {},
    group: false,
  };

  state: RadioState = {
    checked: this.props.checked || false,
  };

  static Input: typeof Input;
  static Group: typeof Group;

  componentDidUpdate(prevProps: { checked: boolean }): void {
    if (!this.props.onChange) return;
    const { group, checked, onChange, value, order } = this.props;

    if (!group && prevProps.checked !== checked) {
      return this.setState({ checked }, () => onChange(value, order));
    }
  }

  shouldComponentUpdate(nextProps: { disabled: boolean; checked: boolean }, nextState: { checked: boolean }): boolean {
    return (
      this.props.disabled !== nextProps.disabled ||
      this.props.checked !== nextProps.checked ||
      this.state.checked !== nextState.checked
    );
  }

  handleChange = (event: { target: { checked: boolean; value: string }; stopPropagation: () => void }): void | null => {
    if (!this.props.onChange) return;

    const { checked, value } = event.target;
    const { group, onChange, order } = this.props;

    if (group) {
      event.stopPropagation();
      return onChange(value, order);
    }
    return this.setState({ checked }, () => onChange(value));
  };

  render(): JSX.Element {
    const { group, value, children, className, checked, dataTestId, ...restOfProps } = this.props;

    return (
      <StyledRadio className={`Radio ${checked ? 'isChecked' : ''} ${className ?? ''}`} {...restOfProps}>
        <StyledRadio.Label data-testid={dataTestId}>
          {children}
          <StyledRadio.Input
            type="radio"
            value={value}
            checked={group ? checked : this.state.checked}
            onChange={this.handleChange}
          />
          <StyledRadio.Value className="Radio__Value" />
        </StyledRadio.Label>
      </StyledRadio>
    );
  }
}

const Input = (
  props: JSX.IntrinsicAttributes & {
    theme?: Theme | undefined;
    as?: ElementType<unknown> | undefined;
  } & ClassAttributes<HTMLInputElement> &
    InputHTMLAttributes<HTMLInputElement> & { children?: ReactNode }
): JSX.Element => <StyledRadio.Input {...props} />;

Input.displayName = 'Radio.Input';

Radio.Input = Input;
Radio.Group = Group;

export default Radio;
