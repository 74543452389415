import { VENUE_NOTIFICATION_CREDIT_REQUEST, VENUE_NOTIFICATION_CREDIT_RESPONSE } from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnInit } from 'utils/reducersUtils';

const notificationCredit = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_NOTIFICATION_CREDIT_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_NOTIFICATION_CREDIT_RESPONSE:
      if (!action.notification_credit) {
        return state;
      }

      return {
        ...state,
        notification_credit: action.notification_credit,
      };

    default:
      return state;
  }
};

export const notificationCreditByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_NOTIFICATION_CREDIT_REQUEST:
    case VENUE_NOTIFICATION_CREDIT_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = notificationCredit(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
