/* eslint-disable no-fallthrough */

import {
  VENUE_APPOINTMENTS_LIST_REQUEST,
  VENUE_APPOINTMENTS_LIST_RESPONSE,
  API_GET_RESPONSE,
  API_POST_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

const appointments = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_APPOINTMENTS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_APPOINTMENTS_LIST_RESPONSE:
      if (!action.appointments) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.appointments)
            .map((item) => ({ id: item.id })),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };

    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (!action.appointments || !action.appointments.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.appointments), {
          onlyIds: 1,
          avoidDuplicateIds: 1,
          skipUpdate: 1,
        }),
      };

    default:
      return state;
  }
};

export const appointmentsByVenue = (state = {}, action) => {
  const responseData = (action.response && action.response.data) || {};
  let refState;

  switch (action.type) {
    case VENUE_APPOINTMENTS_LIST_REQUEST:
    case VENUE_APPOINTMENTS_LIST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = appointments(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    /**
     * add all teh appointments coming from other requests in appointments list
     * if path === `/venues/${action.venue_id}/appointments.json`, is handled by previous case VENUE_APPOINTMENTS_LIST_RESPONSE
     */
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      const items = [].concat(responseData.appointment || []).concat(responseData.appointments || []);
      if (!items.length) {
        return state;
      }

      refState = appointments(state[action.venue_id], {
        ...action,
        appointments: items,
      });

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
