import { ReactNode } from 'react';
import { Icon } from 'components/core';
import { Box, Text } from 'components/ui-treatwell-pro';
import { treatwellProTheme } from '../theme';
import { ValueOf } from '../../utils/utilityTypes';

export const TOAST_TYPE = {
  DEFAULT: 'DEFAULT',
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
} as const;

const CloseIcon = ({ onClick }: { readonly onClick: () => void }): JSX.Element => (
  <Box cursor="pointer" onClick={onClick}>
    <Icon type="close" color={treatwellProTheme.colors['navy.200']} size={12} />
  </Box>
);

const getColor = (type: ValueOf<typeof TOAST_TYPE>): string => {
  switch (type) {
    case TOAST_TYPE.SUCCESS:
      return treatwellProTheme.colors['teal.100'];
    case TOAST_TYPE.WARNING:
      return treatwellProTheme.colors['yellow.200'];
    case TOAST_TYPE.ERROR:
      return treatwellProTheme.colors['coral.100'];
    case TOAST_TYPE.DEFAULT:
    default:
      return treatwellProTheme.colors['navy.200'];
  }
};

const getBorderColor = (type: ValueOf<typeof TOAST_TYPE>): string => {
  switch (type) {
    case TOAST_TYPE.SUCCESS:
      return treatwellProTheme.colors['teal.600'];
    case TOAST_TYPE.WARNING:
      return treatwellProTheme.colors['yellow.700'];
    case TOAST_TYPE.ERROR:
      return treatwellProTheme.colors['coral.600'];
    case TOAST_TYPE.DEFAULT:
    default:
      return treatwellProTheme.colors['navy.400'];
  }
};

const getIcon = (type: ValueOf<typeof TOAST_TYPE>): JSX.Element | null => {
  switch (type) {
    case TOAST_TYPE.SUCCESS:
      return <Icon type="tw-success" />;
    case TOAST_TYPE.WARNING:
      return <Icon type="tw-warning" />;
    case TOAST_TYPE.ERROR:
      return <Icon type="tw-error" />;
    case TOAST_TYPE.DEFAULT:
      return <Icon type="tw-default" />;
    default:
      return null;
  }
};

interface ToastProps {
  readonly config: {
    readonly type: ValueOf<typeof TOAST_TYPE>;
    readonly content: ReactNode;
    readonly onClose: () => void;
  };
}
export const Toast = ({ config: { type, content, onClose } }: ToastProps): JSX.Element => (
  <Box
    p="16px 24px"
    width="100%"
    minHeight="69px"
    maxWidth="682px"
    bg={getColor(type)}
    borderRadius="8px"
    border={`1px solid ${getBorderColor(type)}`}
    display="flex"
    flexDirection="row"
    alignItems="center"
  >
    <Box display="flex" mR="16px">
      {getIcon(type)}
    </Box>
    <Box display="flex" flexGrow={4}>
      <Text
        data-testid="toast-notification"
        fontSize="sm"
        fontWeight="bold"
        color={treatwellProTheme.colors['navy.800']}
        textAlign="left"
      >
        {content}
      </Text>
    </Box>
    <Box display="flex">
      <CloseIcon onClick={onClose} />
    </Box>
  </Box>
);
