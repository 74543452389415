type TestableValue = Array<unknown> | Record<string, unknown> | string | null | undefined;

const isEmpty = (test: TestableValue): boolean => {
  if (test) {
    return Array.isArray(test) || typeof test === 'string' ? !test.length : !Object.keys(test).length;
  }

  return true;
};

export default isEmpty;
