import { AnchorHTMLAttributes, DetailedHTMLProps, FunctionComponent } from 'react';
import Button, { ButtonProps } from '../Button';

type ButtonLinkProps = ButtonProps<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>>;

/**
 * @description
 * This component extends Button component as `<a>` tag and anchor html attributes(href, target, etc)
 */
const ButtonLink: FunctionComponent<ButtonLinkProps> = ({ children, ...rest }) => (
  <Button as="a" {...rest}>
    {children}
  </Button>
);

export default ButtonLink;
