import { styled, css, Theme } from 'styles';
import { SpinnerProps } from './index';

export const Spinner = styled('svg')(
  ({ theme: { colors }, background, foreground }: SpinnerProps & { theme: Theme }) => css`
    --animation-duration: 0.65s;
    transform-origin: center center;
    animation: nc-loop-circle-2-anim var(--animation-duration) infinite cubic-bezier(0.645, 0.045, 0.355, 1);

    circle {
      stroke: ${background && colors[background]};
    }

    path {
      stroke: ${foreground && colors[foreground]};
    }

    @keyframes nc-loop-circle-2-anim {
      0% {
        transform: rotate(0);
      }
      to {
        transform: rotate(360deg);
      }
    }
  `
);
