import { FieldShape as StyledFieldShape } from './theme';

const FieldShape = (props) => <StyledFieldShape className="FieldShape" {...props} />;

FieldShape.displayName = 'FieldShape';

FieldShape.defaultProps = {};

FieldShape.propTypes = {};

export default FieldShape;
