import { FunctionComponent } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';
import { Option } from './MultiSelect';
import { Icon } from 'components/core';

const DropdownIndicator: FunctionComponent<DropdownIndicatorProps<Option, boolean>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon type="search" color="inputPlaceHolder" size={16} />
    </components.DropdownIndicator>
  );
};

export default DropdownIndicator;
