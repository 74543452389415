import { PrinterType } from 'models';
import { Session } from 'reducers/sessions';

/**
 * check if a venue related override param is set in localstorage and return a new venue object with the override
 */
export const applyOverrideParamsToVenue = (venue: Session['currentVenue']): Session['currentVenue'] => {
  const overrideParamsEnabled = window.localStorage.getItem('UALA_OVERRIDE_PARAMS') === 'true';
  const mainPrinterTypeOverrideParam = window.localStorage.getItem('UALA_MAIN_PRINTER_TYPE') as PrinterType;
  const mainPrinterIpOverrideParam = window.localStorage.getItem('UALA_MAIN_PRINTER_IP_ADDRESS');
  const shouldApplyOverride = overrideParamsEnabled && (mainPrinterTypeOverrideParam || mainPrinterIpOverrideParam);

  if (!venue || !shouldApplyOverride) return venue;
  return {
    ...venue,
    main_printer_type: mainPrinterTypeOverrideParam || venue.main_printer_type,
    main_printer_ip_address: mainPrinterIpOverrideParam || venue.main_printer_ip_address,
  };
};
