import { MouseEventHandler } from 'react';
import { styled, css, COLORS } from 'styles';

export const TableView = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;

  .Card & {
    height: 100%;
  }

  .ReactVirtualized__Grid.ReactVirtualized__List {
    outline: none;
  }
`;

type HeaderProps = {
  fullInput?: boolean;
  withSectionIndex?: boolean;
};

export const Header = styled('div')<HeaderProps>(
  ({ fullInput, theme: { utils, sizes }, withSectionIndex }) => css`
    display: flex;
    flex: none;
    padding: ${sizes.spacing - 2}px ${sizes.spacing * 1.5}px;
    background: #fff;
    ${withSectionIndex ? 'width: calc(100% - 24px)' : undefined};

    .FieldIcon:first-of-type {
      margin-right: 10px;
    }

    .FieldIcon:last-child {
      transition: ${utils.transition};
      margin-left: ${sizes.spacing / 2}px;
      overflow: hidden;
    }

    .TextInput {
      transition: ${utils.transition};
    }

    ${fullInput &&
    `
        .TextInput {
          margin-right: 0;
        }

        .FieldIcon:last-child {
          transform: translateX(20px);
          margin-left: 0;
          border: 0;
          padding-left: 0;
          padding-right: 0;
          width: 0;
          opacity: 0;
        }
      `};
  `
);

type OuterScrollerProps = {
  withSectionIndex?: boolean;
};

export const OuterScroller = styled('div')<OuterScrollerProps>(
  ({ withSectionIndex }) => css`
    flex: 1;
    width: ${withSectionIndex ? 'calc(100% - 24px)' : '100%'};
    overflow-x: auto;
    overflow-y: auto;
  `
);

export const InnerScroller = styled('div')`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;

  .TableView__loader {
    position: relative;
    padding: 10px;
  }

  &.TableView__autosizer {
    overflow: hidden;
  }
`;

const cellHeight = 57;
const cellPaddingV = 20;

type CellProps = {
  active?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  height?: number;
  collapse?: boolean;
  separator?: boolean;
};

export const Cell = styled('div')<CellProps>(
  ({ theme: { sizes, colors }, active, onClick, height, collapse = false, separator }) => css`
    position: relative;
    display: flex;
    align-items: center;
    ${separator ? 'font-weight: bold;' : undefined}
    padding: ${cellPaddingV}px;
    ${collapse ? `padding-bottom: 0;` : ''};
    background: ${colors.bgWhite};
    ${height && `height: ${height}px`};

    ${separator &&
    `
        &:before {
          display: block;
          content: '';
          position: absolute;
          top: -1px;
          left: 0;
          right: 0;
          margin: 0 ${sizes.padding}px;
          height: 1px;
          background-color: ${colors.bgWhite};
        }
      `}

    ${!collapse &&
    !separator &&
    `
        &:after {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 ${sizes.padding}px;
          height: 1px;
          background-color: ${colors.blockBorder};
        }
        
        .ReactVirtualized__Grid__innerScrollContainer > div:last-child &:after,
        .TableView__scroller > div:last-child &:after {
          display: none;
        }
      `};

    ${onClick &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${colors.bgLightGray};
      }
    `};

    ${active
      ? `
          color: ${colors.blue};
          font-weight: 700;
        `
      : `
          color: ${separator ? colors.inputPlaceHolder : colors.text1};
        `};
  `
);

export const CellTitle = styled('div')`
  line-height: ${cellHeight - 2 * cellPaddingV}px;
  flex: 1;
`;

export const CellInfo = styled('div')`
  line-height: ${cellHeight - 2 * cellPaddingV}px;
  color: ${COLORS.inputPlaceHolder};
  font-weight: 400;
  padding-left: 8px;

  path {
    fill: currentColor;
  }

  .Icon {
    margin-right: 5px;
  }
`;

export const CellInfoSeparator = styled('span')`
  color: ${COLORS.gray};
`;

const labelSize = 10;

export const CellBadge = styled('div')`
  position: absolute;
  top: 50%;
  left: ${30 - labelSize}px;
  width: ${labelSize}px;
  height: ${labelSize}px;
  border-radius: 100%;
  background-color: #${(props) => props.color || 'ffffff'};
  transform: translate(0, -50%);
`;

export default TableView;
