import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { fetchFlashPromotions } from 'actions/promotions/promotions';
import { getFlashPromotionsList } from 'selectors/promotions/getPromotions';
import { getPermissionChecker } from 'selectors/venue/getVenuePermissions';
import { getCheckCurrentVenueActiveFlag } from 'selectors/getCurrentVenue';
import Trigger from './Trigger';
import { modalOpen } from 'actions/modals';
import { VENUE_ACTIVE_FEATURE_FLAG } from 'models';

const mapDispatchToProps = {
  dispatchFetchFlashPromotions: fetchFlashPromotions,
  dispatchModalOpen: modalOpen,
};

const mapStateToProps = () =>
  createSelector(
    getFlashPromotionsList,
    getPermissionChecker,
    getCheckCurrentVenueActiveFlag,
    (promotionsList, checkPermission, checkCurrentVenueActiveFlag) => {
      return {
        marketing_promotion: promotionsList[0] || null,
        promo_flash_enabled: checkPermission('marketing') === 'full',
        shouldUpsell: !checkCurrentVenueActiveFlag(VENUE_ACTIVE_FEATURE_FLAG.ACTIVE_BASIC_PROMOTIONS),
      };
    }
  );

export default connect(mapStateToProps, mapDispatchToProps)(Trigger);
