import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../Modal';
import Card from '../Card';
import Section from '../Section';
import Radio from '../Radio';
import { FieldDate as StyledFieldDate } from './theme';
import { DayPickerSingleDateController } from 'react-dates';

/* eslint-disable */

moment.locale(window.APP_LOCALE);

class FieldDate extends Component {
  constructor(props) {
    super(props);
    const startDate = props.value && moment(props.value).isValid() ? moment(props.value) : moment();

    this.state = {
      isOpen: false,
      startDate,
      value: props.value ? startDate.format(props.format) : '',
      hideCalendar: props.toggable && !props.value,
    };
  }

  componentDidUpdate(prevProps) {
    const startDate = this.props.value && moment(this.props.value).isValid() ? moment(this.props.value) : moment();

    if (this.props.value !== prevProps.value || !this.state.startDate.isSame(startDate, 'day')) {
      this.setState({
        startDate,
        value: this.props.value ? startDate.format(this.props.format) : '',
        hideCalendar: this.props.toggable && !this.props.value,
      });
    }
  }

  onDateChange = (startDate) => {
    this.setState(
      {
        startDate,
        value: startDate.format(this.props.format),
        isOpen: false,
      },
      () => {
        if (this.props.onDateChange) {
          this.props.onDateChange(startDate);
        }
      }
    );
  };

  onDateReset = () => {
    this.setState(
      {
        value: '',
        isOpen: false,
      },
      () => {
        if (this.props.onDateChange) {
          this.props.onDateChange(null);
        }
      }
    );
  };

  onMonthYearChange = (startDate) => {
    if (this.props.onDateChange) {
      this.props.onDateChange(startDate);
    }
  };

  isOutsideRange = (date) => {
    if (this.props.maxDate instanceof moment) {
      return date.isAfter(this.props.maxDate);
    }

    if (this.props.minDate instanceof moment) {
      return date.isBefore(this.props.minDate);
    }

    return false;
  };

  renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div>
        <select
          value={month.month()}
          onChange={(e) => {
            onMonthSelect(month, e.target.value);
            this.onMonthYearChange(this.state.startDate.clone().month(e.target.value));
          }}
        >
          {moment.months().map((label, value) => (
            <option key={'month-' + value} value={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          value={month.year()}
          onChange={(e) => {
            onYearSelect(month, e.target.value);
            this.onMonthYearChange(this.state.startDate.clone().year(e.target.value));
          }}
        >
          {(() => {
            const years = [];
            for (let index = 0; index < 100; index++) {
              years.push(
                <option key={'year-' + index} value={moment().year() - index}>
                  {moment().year() - index}
                </option>
              );
            }
            return years;
          })()}
        </select>
      </div>
    </div>
  );

  render() {
    const { isOpen, value, startDate } = this.state;
    const { onDateChange, ...passedProps } = this.props;
    return (
      <StyledFieldDate className="FieldDate" {...passedProps}>
        <input
          ref="input"
          type="text"
          placeholder={this.props.placeholder || ''}
          value={value}
          onChange={() => {}}
          onFocus={() => this.setState({ isOpen: true })}
        />
        {isOpen && (
          <Modal onClose={() => this.setState({ isOpen: false })} centered>
            <Card>
              {this.props.header ? <Card.Header collapse="bottom">{this.props.header}</Card.Header> : null}
              {this.props.toggable ? (
                <Section displayFlex justifyContent="center" padding={1}>
                  <Radio.Group
                    value={value ? 'show' : 'hide'}
                    onChange={(toggleState) => {
                      if (toggleState === 'hide') {
                        this.onDateReset();
                      }
                      this.setState({ hideCalendar: toggleState === 'hide' });
                    }}
                  >
                    <Radio type="radio" value="show">
                      {this.props.toggable[0]}
                    </Radio>
                    <Radio type="radio" value="hide">
                      {this.props.toggable[1]}
                    </Radio>
                  </Radio.Group>
                </Section>
              ) : null}
              {this.state.hideCalendar ? null : (
                <DayPickerSingleDateController
                  noBorder
                  hideKeyboardShortcutsPanel
                  date={startDate}
                  focused={true}
                  renderMonthElement={this.props.selectYear ? this.renderMonthElement : null}
                  onDateChange={(startDate) => this.onDateChange(startDate)}
                  isOutsideRange={this.isOutsideRange}
                />
              )}
            </Card>
          </Modal>
        )}
      </StyledFieldDate>
    );
  }

  static displayName = 'FieldDate';

  static defaultProps = {
    disabled: false,
    size: '',
    format: 'DD/MM/YYYY',
  };

  static propTypes = {
    disabled: PropTypes.bool,
    size: PropTypes.string,
    format: PropTypes.string,
    children: PropTypes.node,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    toggable: PropTypes.array,
  };
}

export default FieldDate;
