import { apiPost } from './data_providers/api';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { ParametricActionCreator, ParametricDispatch } from './types';

interface Params {
  acceptance_form_template_id: number;
}

export type AcceptFormActionCreator = ParametricActionCreator<Params, Promise<void>>;

export type AcceptFormActionDispatch = ParametricDispatch<Params, Promise<void>>;

export const acceptForm: AcceptFormActionCreator =
  ({ acceptance_form_template_id }) =>
  (dispatch, getState): Promise<void> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id) {
        return reject({ code: 400 });
      }

      dispatch(
        apiPost({
          path: `/venues/${venue_id}/accept_form.json`,
          data: {
            acceptance_form_template_id,
          },
          onResponse(response) {
            if (!response.success) {
              return reject();
            }
            resolve();
          },
          onError() {
            reject();
          },
        })
      );
    });
