import React, {
  Component,
  createRef,
  FocusEvent,
  MouseEvent,
  ChangeEvent,
  ReactNode,
  RefObject,
  KeyboardEvent,
  HTMLInputTypeAttribute,
} from 'react';
import { TextInput as StyledTextInput } from './theme';
import { Text, Icon, Spinner, Box } from 'components/core';
import { IconProps } from 'components/core/Icon';
import Currency from 'components/lib/Currency';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TextInputOptionsProps {}

type EventListener<K extends keyof HTMLElementEventMap> = (this: HTMLDivElement, ev: HTMLElementEventMap[K]) => void;

class TextInputOptions extends Component<TextInputOptionsProps> {
  body: RefObject<HTMLDivElement>;
  onTouchStart!: EventListener<'touchstart'>;
  onTouchMove!: EventListener<'touchmove'>;
  onScroll!: EventListener<'wheel'>;
  touchStartY: number;

  constructor(props: TextInputOptionsProps) {
    super(props);

    this.body = createRef();
    this.touchStartY = 0;
  }

  componentDidMount(): void {
    this.body.current?.addEventListener(
      'touchstart',
      (this.onTouchStart = (e): void => {
        this.touchStartY = e.touches[0].pageY;
      }),
      { passive: false }
    );

    this.body.current?.addEventListener(
      'touchmove',
      (this.onTouchMove = (e): void | boolean => {
        e.stopPropagation();

        const delta = this.touchStartY - e.touches[0].pageY;
        const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget as HTMLDivElement;

        if ((scrollTop === 0 && delta < 0) || (scrollTop >= scrollHeight - offsetHeight && delta > 0)) {
          e.preventDefault();
          return false;
        }
      }),
      { passive: false }
    );

    this.body.current?.addEventListener(
      'wheel',
      (this.onScroll = (e): void | boolean => {
        e.stopPropagation();
        const { deltaY, deltaX } = e;
        const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget as HTMLDivElement;

        const delta = deltaY === 0 ? deltaX : deltaY;

        if ((scrollTop === 0 && delta < 0) || (scrollTop >= scrollHeight - offsetHeight && delta > 0)) {
          e.preventDefault();
          return false;
        }
      }),
      { passive: false }
    );
  }

  componentWillUnmount(): void {
    this.body.current?.removeEventListener('touchstart', this.onTouchStart);
    this.body.current?.removeEventListener('touchmove', this.onTouchMove);
    this.body.current?.removeEventListener('wheel', this.onScroll);
  }

  render(): JSX.Element {
    return <div ref={this.body} className="TextInput__options" {...this.props} />;
  }
}

type Option = {
  label: string;
  value: string;
};

export interface TextInputPropsStyle {
  readOnly?: boolean;
  prefix?: ReactNode;
  placeholder?: string;
  maxLength?: number;
  hideOnMobile?: boolean;
  hideOnTablet?: boolean;
  hideOnDesktop?: boolean;
  flex?: boolean;
  disabled?: boolean;
  collapsed?: boolean;
  size?: 'default' | 'small' | 'large';
  width?: number;
  height?: number;
  minWidth?: number;
  maxWidth?: number;
  borderColor?: string;
  fontSize?: number;
  error?: boolean;
  textAlign?: string;
  type?: HTMLInputTypeAttribute;
  icon?: (IconProps & { position?: 'left' | 'right' }) | null;
  options?: Option[];
}

export type OnChangeArg = string | number | Option;

export interface TextInputProps extends TextInputPropsStyle {
  onChange?: (v: OnChangeArg) => void;
  onSubmit?: () => void;
  replace?: (s: string) => string;
  onBlur?: () => void;
  onFocus?: () => void;
  onAddNewOption?: (query: string, options?: Option[]) => void;
  decimal?: number;
  children: ReactNode;
  value: string | number;
  autocomplete?: string;
  optionsLoading?: boolean;
  camelCase?: boolean;
  testId?: string;
  productTourId?: string;
  autoFocus?: boolean;
  minOptionsQuery: number;
  allowCustomValue?: boolean;
  inputMode?: 'numeric' | 'decimal' | 'none' | 'text' | 'tel' | 'search' | 'email' | 'url';
  showCurrency?: boolean;
  optionsResetQueryOnSelect?: string;
  optionsLimit?: number;
  block?: true;
}

interface TextInputState {
  query: string;
  focused: boolean;
  reserved: boolean;
}

class TextInput extends Component<TextInputProps, TextInputState> {
  static defaultProps: Partial<TextInputProps> = {
    disabled: false,
    type: 'text',
    decimal: 0,
    icon: null,
    showCurrency: false,
    allowCustomValue: false,
    minOptionsQuery: 1,
    autoFocus: false,
  };

  state: TextInputState = {
    query: '',
    focused: false,
    reserved: false,
  };

  static displayName: string;
  inputRef: React.RefObject<HTMLInputElement>;
  _mounted: boolean;

  constructor(props: TextInputProps) {
    super(props);
    this.inputRef = createRef();
    this._mounted = false;
  }

  componentDidMount(): void {
    this._mounted = true;
  }

  componentWillUnmount(): void {
    this._mounted = false;
  }

  focus = (): void => {
    if (this.inputRef.current) this.inputRef.current.focus();
  };

  onValueChange = (targetValue: string): void => {
    const { replace, type, camelCase, onChange, options, allowCustomValue } = this.props;

    if (replace) targetValue = replace(targetValue);
    if (type === 'number') targetValue = targetValue.replace(/[.,]+/gi, '.');
    if (camelCase) targetValue = this.toTitleCase(targetValue);

    if (onChange) {
      if (options) this.setState({ query: targetValue, focused: true });

      if (options && allowCustomValue) return onChange({ label: targetValue, value: targetValue });
      if (type === 'percent') return onChange(Number(targetValue.replace(/[^\d]*/gi, '')) / 100);
      if (type === 'number') return onChange(targetValue.replace(/[^\d.,]*/gi, ''));
      onChange(targetValue);
    }
  };

  onInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    this.onValueChange(event.target.value);
  };

  onInputBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const { options, onChange, type, decimal } = this.props;
    if (options) setTimeout(() => this._mounted && this.setState({ focused: false }), 10);
    if (type === 'number' && decimal) {
      const adjustedValue = (parseFloat(event.target.value) || 0).toFixed(decimal);
      if (onChange) onChange(adjustedValue);
      this.inputRef.current?.value && (this.inputRef.current.value = adjustedValue);
    }
    if (this.props.onBlur) this.props.onBlur();
  };

  onInputFocus = (event: FocusEvent<HTMLInputElement>): void => {
    const { onFocus, options, type } = this.props;
    if (onFocus) onFocus();
    if (type === 'number' && (parseFloat(event.target.value) || 0) === 0) this.onValueChange('');
    if (options) this.setState({ focused: true, reserved: false, query: event.target.value });
  };

  onInputClick = (event: MouseEvent<HTMLInputElement>): void => {
    const { type } = this.props;
    const eventTarget = event.target as HTMLInputElement;
    if (type === 'number' || type === 'percent') {
      setTimeout(() => {
        if (eventTarget.selectionStart === eventTarget.selectionEnd) eventTarget.select();
      }, 0);
    }
  };

  onInputKeyPress = (event: KeyboardEvent<HTMLInputElement>): void => {
    const { onSubmit } = this.props;
    if (event.key === 'Enter' && onSubmit) onSubmit();
  };

  formatValue = (value: string | number): string | number => {
    const { type } = this.props;
    if (type === 'percent') {
      return Math.round((Number(value) || 0) * 100);
    }
    return value;
  };

  toTitleCase = (str: string): string =>
    (str || '')
      .split(' ')
      .map((piece) =>
        piece
          .split('')
          .map((c, k) => (k ? c.toLowerCase() : c.toUpperCase()))
          .join('')
      )
      .join(' ');

  showOptions = (): boolean => {
    const { minOptionsQuery } = this.props;
    const { query, focused, reserved } = this.state;
    return (focused || reserved) && query.length >= minOptionsQuery;
  };

  reserve = (): void => {
    const { reserved } = this.state;
    if (!reserved) this.setState({ reserved: true });
  };

  onSelect = (option: { label: string; value: string }): void => {
    const { onChange, optionsResetQueryOnSelect } = this.props;

    if (this.inputRef.current?.value) this.inputRef.current.value = option.label;

    setTimeout(() => {
      this.setState({ query: optionsResetQueryOnSelect ? '' : option.label, reserved: false });
    }, 10);

    if (onChange) onChange(option);
  };

  renderOptions = (): JSX.Element | null => {
    const { options, optionsLoading, optionsLimit, allowCustomValue } = this.props;

    if (!this.showOptions()) return null;

    if (optionsLoading) {
      return (
        <TextInputOptions>
          <Spinner loading />
        </TextInputOptions>
      );
    }

    if (!options || !options.length) return null;

    const query = (this.state.query || '').toLowerCase();
    let found = false;

    return (
      <TextInputOptions>
        {options
          .filter((option) => option.label.toLowerCase().indexOf(query) >= 0)
          .sort(({ label: labelA }, { label: labelB }) =>
            labelA.toLowerCase().indexOf(query) > labelB.toLowerCase().indexOf(query) ? 1 : -1
          )
          .filter((_option, k) => (optionsLimit ? k < optionsLimit : k < 20))
          .map(
            (option, k) =>
              (found = true) && (
                <div
                  key={k}
                  className="TextInput__options-option"
                  onMouseDown={this.reserve}
                  onClick={(e): void => {
                    e.stopPropagation();
                    this.onSelect(option);
                  }}
                >
                  {option.label}
                </div>
              )
          )}
        {!found && !allowCustomValue && (
          <div className="TextInput__options-option">
            <Text color="text2" size={14} intl="no.results.for" intlValues={{ query }} />
          </div>
        )}
      </TextInputOptions>
    );
  };

  render(): JSX.Element {
    let { value, icon } = this.props;
    const {
      children,
      type,
      prefix,
      readOnly,
      maxLength,
      options,
      onChange,
      onBlur,
      onFocus,
      onSubmit,
      onAddNewOption,
      minOptionsQuery,
      placeholder,
      autoFocus,
      testId,
      productTourId,
      inputMode,
      showCurrency,
      autocomplete,
      disabled,
      ...styledPassedProps
    } = this.props;

    const { focused, query } = this.state;

    if (options) {
      value = focused ? query : (options.filter((option) => option.value === value)[0] || {}).label || value;
      icon = { position: 'right', type: 'select', size: 11 };
    }

    if (onAddNewOption && focused && query.length >= minOptionsQuery) {
      icon = {
        position: 'right',
        color: 'blue',
        type: 'plus',
        size: 14,
        onForceClick: onAddNewOption.bind(null, query, options),
      };
    }

    if (readOnly) {
      return (
        <StyledTextInput
          {...styledPassedProps}
          type={type}
          readOnly={readOnly}
          options={options}
          disabled={disabled}
          className="TextInput"
        >
          {prefix && (
            <div className="TextInput__prefix">
              {prefix}
              <span>{this.formatValue(value)}</span>
            </div>
          )}
          <div className="input">{this.formatValue(value)}</div>
        </StyledTextInput>
      );
    }

    return (
      <StyledTextInput
        {...styledPassedProps}
        type={type}
        readOnly={readOnly}
        options={options}
        disabled={disabled}
        showOptions={this.showOptions()}
        className="TextInput"
        onClick={this.focus}
        data-product-tour-id={productTourId}
      >
        {this.renderOptions()}
        {prefix && (
          <div className="TextInput__prefix">
            {prefix}
            <span>{this.formatValue(value)}</span>
          </div>
        )}
        <input
          ref={this.inputRef}
          autoComplete={autocomplete || 'nope'}
          value={this.formatValue(value)}
          type={type === 'number' || type === 'percent' ? 'text' : type || 'text'}
          placeholder={placeholder}
          readOnly={!onChange}
          maxLength={maxLength}
          onChange={onChange && this.onInputChange}
          onKeyPress={this.onInputKeyPress}
          onClick={this.onInputClick}
          onFocus={this.onInputFocus}
          onBlur={this.onInputBlur}
          data-testid={testId}
          disabled={disabled}
          inputMode={inputMode}
          autoFocus={autoFocus}
        />
        {icon ? <Icon {...icon} /> : null}
        {showCurrency ? (
          <Box position="absolute" top="50%" transform="translateY(-50%)" right="0" margin="0 12px" opacity={0.3}>
            <Text size={20} color="darkBlue">
              <Currency />
            </Text>
          </Box>
        ) : null}
        {children}
      </StyledTextInput>
    );
  }
}

TextInput.displayName = 'TextInput';

export default TextInput;
