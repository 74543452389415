import { treatwellProTheme } from '@uala/ui-treatwell-pro';
import { styled } from 'styles';

export * from '@uala/ui-treatwell-pro';

export const TwProFontFamily = styled.div`
  font-family: ${treatwellProTheme.typography.fontFamilies.default}!important;
`;

export const SPACING = {
  xsm: '16px',
  sm: '24px',
  md: '32px',
  lg: '48px',
} as const;
