import * as S from './Spinner.theme';
import { Colors } from 'styles';

export interface SpinnerProps {
  background?: Colors;
  foreground?: Colors;
  size?: string | number;
}

export const Spinner = ({
  background = 'lightGrayTW',
  foreground = 'blueTW',
  size = 64,
  ...rest
}: SpinnerProps): JSX.Element => {
  return (
    <S.Spinner
      role="img"
      background={background}
      foreground={foreground}
      width={size}
      height={size}
      viewBox="0 0 64 64"
      strokeWidth="4"
      fill="none"
      {...rest}
    >
      <title>Loading...</title>
      <circle cx="32" cy="32" r="30" />
      <path d="M62 32C62 15.4315 48.5685 2 32 2" strokeLinecap="round" />
    </S.Spinner>
  );
};
