import { styled, css } from 'styles';

type ProvisioningUpsellingButtonProps = {
  noIcons?: boolean;
};

const ProvisioningUpsellingButton = styled('button')<ProvisioningUpsellingButtonProps>(
  ({ theme: { colors }, noIcons }) => css`
    box-sizing: border-box;
    outline: none;
    appearance: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    padding: 4px 14px 4px ${noIcons ? '14px' : '8px'};
    border-radius: 16px;
    line-height: 14px;
    cursor: pointer;
    font-size: ${12 / 10}rem;
    background: linear-gradient(90deg, ${colors.red} 0%, ${colors.blue} 100%);
    border: 0;
    color: ${colors.white};

    .Icon + span {
      margin-left: 5px;
    }
    span + .Icon {
      margin-left: 2px;
    }
  `
);

export default ProvisioningUpsellingButton;
