import { APIResponseAction, REDUX_API_ACTION_TYPES } from 'actions/data_providers/data_providers_api.types';
import { StandardAction } from 'actions/types';
import { VTGActions } from 'actions/venueTreatmentGroup';
import {
  API_GET_RESPONSE,
  API_POST_RESPONSE,
  VENUE_TREATMENT_GROUP_CREATE_RESPONSE,
  VENUE_TREATMENT_GROUP_DELETE_RESPONSE,
  VENUE_TREATMENT_GROUP_LIST_REQUEST,
  VENUE_TREATMENT_GROUP_LIST_RESPONSE,
  VENUE_TREATMENT_GROUP_UPDATE_RESPONSE,
} from 'actionTypes';
import { VenueTreatmentGroup } from 'models';
import { standardiseAction } from 'utils/data-layer/standardiseAction';
import filterDeletedItems from 'utils/filterDeletedItems';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

type VTGState = {
  items?: VenueTreatmentGroup[];
  isFetching: boolean | number;
  didInvalidate: boolean;
};

type ObservedActions = VTGActions | APIResponseAction;
type ReducedStandardActions =
  | VTGActions
  | StandardAction<
      typeof REDUX_API_ACTION_TYPES.GET_RESPONSE | typeof REDUX_API_ACTION_TYPES.POST_RESPONSE,
      { venue_treatment_groups: VenueTreatmentGroup[]; venue_id: number }
    >;

const venueTreatmentGroups = (state: VTGState = reducerValuesOnInit(), action: ReducedStandardActions): VTGState => {
  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE: {
      if (!action.payload?.venue_treatment_groups || !action.payload?.venue_treatment_groups.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          (state.items || []).concat(action.payload.venue_treatment_groups.filter(filterDeletedItems)),
          {
            avoidDuplicateIds: 1,
          }
        ),
      };
    }
    case VENUE_TREATMENT_GROUP_CREATE_RESPONSE:
      if (!action.payload?.venue_treatment_group || !state.items) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse((state.items || []).concat(action.payload.venue_treatment_group), {
          avoidDuplicateIds: 1,
        }),
      };
    case VENUE_TREATMENT_GROUP_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case VENUE_TREATMENT_GROUP_LIST_RESPONSE:
      if (!action.payload?.venue_treatment_groups) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([...action.payload.venue_treatment_groups]),
      };

    case VENUE_TREATMENT_GROUP_UPDATE_RESPONSE: {
      if (!action.payload?.venue_treatment_group || !state.items) {
        return state;
      }

      const vtgIndex = state.items.findIndex((vtg) => vtg.id === action.payload?.venue_treatment_group.id);
      const updatedItems = Object.assign([], state.items, { [vtgIndex]: action.payload?.venue_treatment_group });

      return {
        ...state,
        ...reducerValuesOnResponse(updatedItems),
      };
    }

    case VENUE_TREATMENT_GROUP_DELETE_RESPONSE:
      if (!action.payload?.venue_treatment_group || !state.items) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(state.items.filter(({ id }) => id !== action.payload?.venue_treatment_group.id)),
      };

    default:
      return state;
  }
};

type VTGStateByVenue = {
  [venue_id: number]: VTGState;
};

const venueTreatmentGroupsByVenue = (state: VTGStateByVenue = {}, action: ObservedActions): VTGStateByVenue => {
  let refState;

  switch (action.type) {
    case API_GET_RESPONSE:
    case API_POST_RESPONSE:
    case VENUE_TREATMENT_GROUP_CREATE_RESPONSE:
    case VENUE_TREATMENT_GROUP_DELETE_RESPONSE:
    case VENUE_TREATMENT_GROUP_LIST_REQUEST:
    case VENUE_TREATMENT_GROUP_LIST_RESPONSE:
    case VENUE_TREATMENT_GROUP_UPDATE_RESPONSE: {
      const standardAction = standardiseAction(action) as ReducedStandardActions;

      if (!standardAction.payload?.venue_id) {
        return state;
      }

      const { venue_id } = standardAction.payload;
      refState = venueTreatmentGroups(state[venue_id], standardAction);

      if (refState === state[venue_id]) {
        return state;
      }

      return {
        ...state,
        [venue_id]: refState,
      };
    }
    default:
      return state;
  }
};

export { venueTreatmentGroupsByVenue };
