import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import { CHANGE_LOCALE } from 'actionTypes';

export const vacancyTypologies = (state = reducerValuesOnInit(), action) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...reducerValuesOnInit(),
      };
    case 'VACANCY_TYPOLOGIES_LIST_REQUEST':
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case 'VACANCY_TYPOLOGIES_LIST_RESPONSE':
      if (!action.vacancy_typologies) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.vacancy_typologies || [])),
      };

    default:
      return state;
  }
};
