import {
  VENUE_MARKETING_PROMOTIONS_LIST_REQUEST,
  VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE,
  VENUE_MARKETING_PROMOTIONS_TYPES_LIST_REQUEST,
  VENUE_MARKETING_PROMOTIONS_TYPES_LIST_RESPONSE,
  API_POST_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import { MarketingPromotion, MarketingPromotionType } from 'models';

interface Action {
  type: string;
  venue_id: number;
  error: object | boolean;
  skip_update: boolean;
  marketing_promotions?: MarketingPromotion[];
  marketing_promotion_types?: MarketingPromotionType[];
  customer_id?: number;
  response?: {
    data: {
      marketing_promotion?: MarketingPromotion;
      marketing_promotions?: MarketingPromotion[];
      appliable_marketing_promotions?: MarketingPromotion[];
      appliable_credits?: MarketingPromotion[];
    };
  };
}

interface State {
  [venue_id: number]: VenueState;
}

interface VenueState {
  items?: MarketingPromotion[];
  isFetching?: boolean | number;
  didInvalidate?: boolean;
}

// marketing_promotion and customer_marketing_promotion are in the same table on BE.
// this reducer handles only the venue marketing promotion, not associated with customers and not associated with a package
// see also src/reducers/customerPromotions.js
const filterItem = (item: MarketingPromotion): boolean =>
  !item.customer_id && !item.parent_customer_id && !item.package_option_id;

const promotions = (state: VenueState = reducerValuesOnInit(), action: Action): VenueState => {
  let items = [];

  switch (action.type) {
    case VENUE_MARKETING_PROMOTIONS_LIST_REQUEST:
      // TODO: this condition is necessary to avoid the rendering of DataProvider component, that is listening to changes in the `isFetching` flag in promotions reducer
      // need to find a smarter way to do this.
      if (action.customer_id) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE:
      if (!action.marketing_promotions) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          [...(action.marketing_promotions || null)].filter(filterItem).concat(state.items || []),
          {
            overrideDuplicateIds: 1,
            skipUpdate: action.skip_update || false,
            onlyIds: 1,
          }
        ),
      };

    case API_POST_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        (!action.response.data.marketing_promotion &&
          !action.response.data.marketing_promotions &&
          !action.response.data.appliable_marketing_promotions &&
          !action.response.data.appliable_credits)
      ) {
        return state;
      }

      items = [
        ...(action.response.data.marketing_promotion ? [action.response.data.marketing_promotion] : []),
        ...(action.response.data.marketing_promotions || []),
        ...(action.response.data.appliable_marketing_promotions || []),
        ...(action.response.data.appliable_credits || []),
      ];

      if (!items.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(items.filter(filterItem).concat(state.items || []), {
          overrideDuplicateIds: 1,
          skipUpdate: 1,
          onlyIds: 1,
        }),
      };

    default:
      return state;
  }
};

export const promotionsByVenue = (state: State = {}, action: Action): State => {
  let refState;

  switch (action.type) {
    case VENUE_MARKETING_PROMOTIONS_LIST_REQUEST:
    case VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = promotions(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

const promotionsTypes = (state: VenueState = reducerValuesOnInit(), action: Action): VenueState => {
  switch (action.type) {
    case VENUE_MARKETING_PROMOTIONS_TYPES_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_MARKETING_PROMOTIONS_TYPES_LIST_RESPONSE:
      if (!action.marketing_promotion_types) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(action.marketing_promotion_types || []),
      };

    default:
      return state;
  }
};

export const promotionsTypesByVenue = (state: State = {}, action: Action): State => {
  let refState;

  switch (action.type) {
    case VENUE_MARKETING_PROMOTIONS_TYPES_LIST_REQUEST:
    case VENUE_MARKETING_PROMOTIONS_TYPES_LIST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = promotionsTypes(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
