/* eslint-disable no-restricted-imports */
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeResponseToJSON } from 'API/http.utils';
import { AppointmentsQuery, AppointmentsSearchParams, AppointmentsResponse } from 'API/types';

export const APPOINTMENTS =
  ({ variables }: V<AppointmentsQuery>) =>
  (): Promise<AppointmentsResponse> =>
    Http()
      .get<AppointmentsResponse, AppointmentsSearchParams>(ENDPOINT.APPOINTMENTS._APPOINTMENTS())({
        searchParams: { ...variables },
      })
      .then(maybeResponseToJSON);
