import React from 'react';
import StyledAvatar from './theme';
import Venue from 'svg/venue';

interface AvatarProp {
  image?: string | null;
  alt?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Avatar = ({ image, alt = '', onClick = (): void => {}, style }: AvatarProp): JSX.Element => (
  <StyledAvatar className="Avatar" onClick={onClick} style={style}>
    {image ? <img src={image} alt={alt} /> : <Venue width={32} height={32} opacity={0.3} fillColor="#3B3B3B" />}
  </StyledAvatar>
);

export default Avatar;
