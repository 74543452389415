import 'polyfills';
import { DOMElement, EventType, MatchingInteractionChecker, ProperEvent, Selector } from './types';

const sanitizeEventsToCheck = <T>(eventName: string | string[] | T | T[]): EventType[] =>
  [eventName].flat().map(_sanitizeEventToCheck);
const _sanitizeEventToCheck = <T>(eventName: string | T): EventType => {
  if (eventName === 'blur') return 'focusout';
  if (eventName === 'focus') return 'focusin';
  return eventName as EventType;
};

type Layer = HTMLElement | Document;
type ElmMatcher = (element: Layer, selector: Selector) => boolean;

const matchesDocument: ElmMatcher = (e, s) => s === 'document' && 'documentElement' in e && !!e.documentElement;
const matchesNoLayer: ElmMatcher = (e, s) => s === '*' && !('matches' in e);
const matchesSpecialSelectors: ElmMatcher = (e, s) => matchesDocument(e, s) || matchesNoLayer(e, s);

const isElement = (e: Layer): e is HTMLElement => 'matches' in e;

const matchingSelectorChecker =
  (element: Layer) =>
  (selector: Selector): boolean =>
    matchesSpecialSelectors(element, selector) || (isElement(element) && element.matches(selector));

const matchingEventTypeChecker =
  <CustomEvents = never>(event: Event) =>
  (eventNameOrEventNames: EventType | EventType[] | CustomEvents | CustomEvents[]) =>
    eventNameOrEventNames === '*' ||
    sanitizeEventsToCheck<CustomEvents>(eventNameOrEventNames).includes(event.type as EventType);

export const matchingInteractionChecker = <T = never>(event: Event): MatchingInteractionChecker<T> => {
  const isMatchingSelector = matchingSelectorChecker(event.target as Layer);
  const isMatchingEventType = matchingEventTypeChecker<T>(event);

  return (selector, eventType) => isMatchingSelector(selector) && isMatchingEventType(eventType);
};

export const eventsToWatch = Object.keys(Document.prototype) //
  .filter((x) => x.startsWith('on'))
  .map((x) => x.substring(2))
  .concat(['focusin', 'focusout']);

export const sanitizeError = (any: any) => {
  let message = 'Unknown Error';
  try {
    message = JSON.stringify(any);
  } catch (_) {
    try {
      message = String(any);
    } catch (_2) {
      // dont'care
    }
  }
  return message;
};

export const debugEvent = ({ target, type }: ProperEvent) => `Event(${type})->${getElementSelector(target)}`;
function getElementSelector(target: DOMElement): string {
  if (!target || !target.tagName || !target.classList) return 'NO-ELEMENT';

  const tagName = (target.tagName ?? '?').toLowerCase();
  const idSelector = target.id ? `#${target.id}` : '';
  const classSelector = target.classList.length > 0 ? `.${Array.from(target.classList).join('.')}` : '';
  const dataTestId = ['data-testid']
    .map((attr) => (target.getAttribute(attr) ? `[${attr}="${target.getAttribute(attr)}"]` : ''))
    .join('');
  const otherSelectors = ['aria-role', 'aria']
    .map((attr) => (target.getAttribute(attr) ? `[${attr}="${target.getAttribute(attr)}"]` : ''))
    .join('');
  const elementSelector = `${tagName}${idSelector}${classSelector}${dataTestId}${otherSelectors}`;
  const hasSpecificSelector = !!idSelector || !!dataTestId;

  if (!hasSpecificSelector && target.parentNode && target.getRootNode() !== target.parentNode)
    return `${getElementSelector(target.parentNode as Element)} > ${elementSelector}`;
  return elementSelector;
}
