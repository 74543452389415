import { styled, css } from 'styles';
import { withTheme } from 'components/core';

export const Toolbar = withTheme(
  styled('div')(
    ({ theme }) => css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 30px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.08);
      display: flex;
      justify-content: center;

      ${theme.media.breakpointM`
        box-shadow: none;
      `}
    `
  )
);

export const PromoEditShape = withTheme(styled('div')`
  background-color: ${({ theme }) => theme.colors.white};

  .RadioGroup {
    max-width: 360px;
  }

  .RadioOption {
    margin-top: 10px;
  }

  .RadioOption + .RadioOption {
    margin-left: 38px;
  }

  ${({ theme }) => theme.media.breakpointM`
    .RadioGroup {
      max-width: initial;
    }
    
    .RadioOption {
      margin-top: 0;
    }
  `}
`);

export const Row = withTheme(styled('div')`
  > div:first-of-type {
    padding-bottom: ${({ theme }) => theme.sizes.padding * 0.3}px;
  }

  ${({ theme }) => theme.media.breakpointM`
      display: flex;
      padding-left: ${theme.sizes.padding}px;

      > div:first-of-type {
        padding-bottom: 0;
      }
  `};
`);

export const OriginalPrice = withTheme(styled('div')`
  ${({ theme }) => theme.media.breakpointM`
    display: flex;
    align-items: center;
    text-align: center;
    flex: 1;
  `};
`);

export const Desktop = withTheme(styled('div')`
  display: none;

  ${({ theme }) => theme.media.breakpointM`
    display: block;
  `};
`);
