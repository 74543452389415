import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import {
  VENUE_SUPPLIERS_CREATE_RESPONSE,
  VENUE_SUPPLIERS_LIST_REQUEST,
  VENUE_SUPPLIERS_LIST_RESPONSE,
} from 'actionTypes';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const suppliers = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_SUPPLIERS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_SUPPLIERS_LIST_RESPONSE:
      if (!action.suppliers) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.suppliers).map((item) => ({ id: item.id }))),
      };

    case VENUE_SUPPLIERS_CREATE_RESPONSE:
      if (!action.supplier) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.supplier || [])
            .map((item) => ({ id: item.id }))
        ),
      };

    default:
      return state;
  }
};

export const suppliersByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_SUPPLIERS_LIST_REQUEST:
    case VENUE_SUPPLIERS_LIST_RESPONSE:
    case VENUE_SUPPLIERS_CREATE_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = suppliers(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};

const suppliersCostItems = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case 'VENUE_SUPPLIERS_COST_ITEMS_REQUEST':
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case 'VENUE_SUPPLIERS_COST_ITEMS_RESPONSE':
      if (!action.products && !action.service_goods) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(action.products.map((p) => ({ ...p, product: true })))
            .concat(action.service_goods.map((p) => ({ ...p, service_good: true })))
            .concat(action.similar_venue_cost_items.map((p) => ({ ...p, similar_venue_cost_item: true })))
            .concat(action.venue_cost_item_suggestions.map((p) => ({ ...p, venue_cost_item_suggestion: true })))
        ),
      };

    default:
      return state;
  }
};

const suppliersCostItemsByQuery = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case 'VENUE_SUPPLIERS_COST_ITEMS_REQUEST':
    case 'VENUE_SUPPLIERS_COST_ITEMS_RESPONSE':
      if (action.error || !action.q) {
        return state;
      }

      refState = suppliersCostItems(state[action.q], action);

      if (refState === state[action.q]) {
        return state;
      }

      return {
        ...state,
        [action.q]: refState,
      };

    default:
      return state;
  }
};

export const suppliersCostItemsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case 'VENUE_SUPPLIERS_COST_ITEMS_REQUEST':
    case 'VENUE_SUPPLIERS_COST_ITEMS_RESPONSE':
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = suppliersCostItemsByQuery(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
