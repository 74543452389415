import { ComponentProps, PropsWithChildren } from 'react';
import { FieldLabel as StyledFieldLabel } from './theme';

type StyledFieldLabelProps = ComponentProps<typeof StyledFieldLabel>;

type FieldSpacerProps = PropsWithChildren<
  {
    right?: boolean;
    withPadding?: boolean;
    disabled?: boolean;
    size?: string;
    flex?: string | boolean;
    separator?: boolean;
  } & StyledFieldLabelProps
>;

const FieldLabel = ({
  children,
  right = false,
  withPadding = false,
  disabled = false,
  size = '',
  ...props
}: FieldSpacerProps): JSX.Element => (
  <StyledFieldLabel
    className="FieldLabel"
    right={right}
    withPadding={withPadding}
    disabled={disabled}
    size={size}
    {...props}
  >
    <div>{children}</div>
  </StyledFieldLabel>
);

export const FieldSpacer = ({ children, ...props }: FieldSpacerProps): JSX.Element => {
  return <FieldLabel {...props} />;
};

export default FieldLabel;
FieldLabel.displayName = 'FieldLabel';
FieldSpacer.displayName = 'FieldSpacer';
