import { PAYMENT_METHOD } from '@uala/web-payments';
import { ValueOf } from 'utils/utilityTypes';
import { COUNTER_COUNTABLE_KEY, COUNTER_RESET_MODE } from './counter';
import { Customer, CustomerDebt } from './customer';
import { CustomerCartItem } from './customerCartItem';

import { CheckoutDiscountType } from './CheckoutDiscountType';
import { ISOTime } from 'utils/reducersUtils';
export * from './CheckoutDiscountType'; // backwards compatibility

export const CHECKOUT_TOKEN_PREFIX = 'checkoutCreate-';

interface BackendEntity {
  created_at: ISOTime['DateTime'];
  updated_at: ISOTime['DateTime'];
}

export const PAYMENT_BRAND = {
  UNKNOWN: 'unknown',
  VISA: 'visa',
  MASTERCARD: 'mastercard',
  JCB: 'jcb',
  DINERS_CLUB: 'diners',
  DISCOVER: 'discover',
  AMERICAN_EXPRESS: 'amex',
  UNION_PAY: 'unionpay',
  PAYPAL: 'paypal',
  CREDIT_CARD: 'credit-card',
} as const;

export type PaymentBrand = ValueOf<typeof PAYMENT_BRAND>;

export const BRAND_ICONS = {
  unknown: 'generic-credit-card',
  visa: 'visa',
  mastercard: 'mastercard',
  jcb: 'generic-credit-card',
  diners: 'diners-club',
  discover: 'discover',
  amex: 'amex',
  unionpay: 'generic-credit-card',
  paypal: 'paypal',
  'credit-card': 'discount_card',
} as const;

export type BrandIcons = ValueOf<typeof BRAND_ICONS>;

export const BRAND_NAMES = {
  unknown: 'Unknown',
  visa: 'Visa',
  mastercard: 'Mastercard',
  jcb: 'JCB',
  diners: 'Diners Club',
  discover: 'Discover',
  amex: 'American Express',
  unionpay: 'Union Pay',
  paypal: 'PayPal',
  'credit-card': 'Credit Card',
} as const;

export type BrandNames = ValueOf<typeof BRAND_NAMES>;

export const CHECKOUT_TYPE = {
  quote: 'quote',
  receipt: 'receipt',
  invoice: 'invoice',
  cancellation: 'cancellation',
  canceled: 'canceled',
} as const;

export type CheckoutType = ValueOf<typeof CHECKOUT_TYPE>;

export const PAYMENT_METHOD_STATE = {
  VALID: 'valid',
  DELETED: 'deleted',
  EXPIRED: 'expired',
  IS_EXPIRING: 'is_expiring',
  PENDING: 'pending',
} as const;

export type PaymentMethodState = ValueOf<typeof PAYMENT_METHOD_STATE>;

export const CANCELLATION_MODE = {
  CANCEL: 'cancel',
  REVERT: 'revert',
  FISCAL_REVERT: 'fiscal_revert',
} as const;

export type CancellationMode = ValueOf<typeof CANCELLATION_MODE>;

export const CARD_PAYMENT_TYPE = {
  debit_card: 'debit_card',
  credit_card: 'credit_card',
} as const;

export type CardPaymentType = ValueOf<typeof CARD_PAYMENT_TYPE>;

export const PROMO_TYPE = {
  SINGLE_PURPOSE: 'single_purpose',
  MULTI_PURPOSE: 'multi_purpose',
} as const;

export type PromoType = ValueOf<typeof PROMO_TYPE> | null;

type GiftCard = {
  id: number;
  amount_cents: number;
};

export type CheckoutLineItem = {
  id: number;
  quote: boolean;
  quantity: Nullable<number>;
  product_id: Nullable<number>;
  product_name: Nullable<string>;
  unit_price: Nullable<number>;
  total_price: Nullable<number>;
  discounted_price: Nullable<number>;
  vat_percentage: Nullable<number>;
  vat_id: Nullable<number>;
  vat_code: Nullable<string>;
  customer_cart_item_id: CustomerCartItem['id'] | null;
  appointment_id: CustomerCartItem['appointment_id'] | null;
  staff_member_id: CustomerCartItem['staff_member_id'] | null;
  using_marketing_promotion_id: Nullable<number>;
  preferred_marketing_promotion_id: CustomerCartItem['preferred_marketing_promotion_id'] | null;
  using_marketing_promotion_price: CustomerCartItem['using_marketing_promotion_price'] | null;
  gift_cards: CustomerCartItem['gift_cards'];
  applied_marketing_promotion_resource_id: Nullable<number>;
  discount_type: CheckoutDiscountType | null;
  discount_percent_amount: Nullable<number>;
  discount_absolute_amount: Nullable<number>;
  promo_code: Nullable<string>;
  venue_treatment_id: Nullable<number>;
  selling_marketing_promotion_id: Nullable<number>;
  recharge_marketing_promotion_id: Nullable<number>;
  selling_promotion_id: Nullable<number>;
  recharge_customer_promotion_id: Nullable<number>;
  accounting_marketing_promotion_id: Nullable<number>;
  taxable_credit_amount_cents: Nullable<number>;
  checkout_id: number;
  proposed_by_staff_member: CustomerCartItem['proposed_by_staff_member'];
  associate_promotion_to_customer?: CheckoutMarketingPromotion['associate_promotion_to_customer'];
  serial_number?: CheckoutMarketingPromotion['serial_number'];

  venue_product_name?: Nullable<string>;
  venue_product_id?: Nullable<string>;
  workstation_id?: Nullable<number>;
  notes?: Nullable<string>;
  state?: Nullable<string>;
  extra_staff_member_ids?: Nullable<number[]>;
  line_number?: Nullable<number>;

  paid_online?: Nullable<boolean>;
  paid_online_at?: Nullable<ISOTime['DateTime']>;
  paid_online_amount?: Nullable<number>;

  cash_draw_for_debt_id?: Nullable<number>;
  cash_draw_amount?: Nullable<number>;

  using_customer_promotion_id?: Nullable<number>;
  using_promotion_id?: Nullable<number>;

  stats_fiscal_price?: Nullable<number>;
  stats_promo_price?: Nullable<number>;
} & BackendEntity;

export interface VatAmount {
  percentage: number;
  total_amount_cents: number;
  taxable_amount_cents: number;
  tax_amount_cents: number;
  items_count: number;
  name?: string;
  code?: Nullable<string>;
}

export type RelatedCheckout = {
  id?: number;
  invoice?: boolean;
  receipt?: boolean;
  counter_key?: COUNTER_COUNTABLE_KEY | `${COUNTER_COUNTABLE_KEY}`;
  fiscal_year?: number;
  counter_value?: number;
  counter_reset_mode?: COUNTER_RESET_MODE | `${COUNTER_RESET_MODE}`;
};

/*
 * TODO: this Checkout type is a mix of what is returned from the BE and what is calculated from the FE, we need at least create two different
 * types (one for the FE and the other one for the BE models) at first, and then try to have a single source of truth for all the data
 */
export interface Checkout extends BackendEntity {
  id: number;
  customer_id: number;
  invoiced_at?: Nullable<string>;
  checkout_type: CheckoutType;
  /**
   * @deprecated
   */
  invoice: boolean;
  /**
   * @deprecated
   */
  receipt: boolean;
  cash_amount: number;
  cash_change_amount: number;
  total_cash_received_amount: number;
  card_amount: number;
  check_amount: number;
  transfer_amount: number;
  online_amount: number;
  credit_amount: number;
  debt_amount: number;
  total_price: number;
  discounted_price: number;
  discount_type: CheckoutDiscountType | null;
  discount_percent_amount: number;
  discount_absolute_amount: number;

  line_items?: CheckoutLineItem[];
  gift_cards?: GiftCard[];

  vat_amounts?: VatAmount[];

  qr_code?: Nullable<string>;
  short_signature?: Nullable<string>;
  fiscal_day_id?: Nullable<number>;
  fiscal_year?: Nullable<number>;
  invoice_day_counter?: Nullable<number>;
  invoice_year_counter?: Nullable<number>;
  invoice_lifetime_counter?: Nullable<number>;
  receipt_day_counter?: Nullable<number>;
  receipt_year_counter?: Nullable<number>;
  receipt_lifetime_counter?: Nullable<number>;
  quote_day_counter?: Nullable<number>;
  checkout_reprint?: Nullable<{
    created_at: string;
    reprint_count: number;
    short_signature: string;
  }>;
  print_count?: Nullable<number>;
  cancellation_mode?: Nullable<CancellationMode>;
  cancellation_reason?: Nullable<string>;
  /**
   * @deprecated
   */
  cancellation: boolean;
  cancellation_day_counter?: Nullable<number>;
  cancellation_year_counter?: Nullable<number>;
  cancellation_lifetime_counter?: Nullable<number>;
  counter_key?: Nullable<COUNTER_COUNTABLE_KEY | `${COUNTER_COUNTABLE_KEY}`>;
  counter_reset_mode?: Nullable<COUNTER_RESET_MODE | `${COUNTER_RESET_MODE}`>;
  counter_value?: Nullable<number>;
  card_type?: Nullable<CardPaymentType>;
  receipt_lottery_code?: Nullable<string>;
  header_data?: {
    venue?: {
      name?: string;
      company_name?: string;
      address?: string;
      postal_code?: string;
      town?: string;
      province?: string;
      country?: string;
      legal_address?: string;
      phone?: string;
      vat_number?: string;
      siret_code?: string;
      naf_code?: string;
      extended_typology_name?: string;
      tax_office_code?: string;
      tax_office_name?: Nullable<string>;
      trade_registration_number?: string;
      city_registration_number?: string;
      profession_registration_number?: string;
      company_id?: Nullable<string>;
      tax_country_region?: Nullable<string>;
    };
    customer?: {
      town?: Nullable<string>;
      address?: Nullable<string>;
      full_name?: Nullable<string>;
      siret_code?: Nullable<string>;
      vat_number?: Nullable<string>;
      fiscal_code?: Nullable<string>;
      postal_code?: Nullable<string>;
      company_name?: Nullable<string>;
      country_code?: Nullable<string>;
      tax_office_code?: Nullable<string>;
      tax_office_name?: Nullable<string>;
    };
    platform_name?: Nullable<string>;
    canceled_checkout?: RelatedCheckout;
    credit_note?: RelatedCheckout;
    creation_user_id?: number;
    creation_user_full_name?: Nullable<string>;
    platform_version?: Nullable<string>;
    receipt_lottery_code?: Nullable<string>;
    pt_identifier?: Nullable<string>;
    atcud?: Nullable<string>;
    billing_company?: {
      share_capital?: Nullable<number>;
      legal_form?: Nullable<string>;
    };
    fiskaly?: {
      error?: Nullable<string>;
      transaction?: {
        signature?: {
          value: string;
          algorithm: string;
          counter: number;
          public_key: string;
        };
        number: number;
        time_start: number;
        time_end: number;
        tss_serial_number: string;
        timestamp_format: string;
      };
    };
  };

  thermal_print?: Nullable<boolean>;
  email_print?: Nullable<boolean>;
  preview?: Nullable<string>;
  state?: Nullable<string>;

  points_transactions?: Nullable<
    {
      marketing_promotion_id: number;
      marketing_promotion_name: string;
      checkout_points_count: number;
      total_points_count: number;
    }[]
  >;
  token: string;
  bypass_print: boolean;

  cancelled_checkout?: Checkout;
  promotion_type?: PromoType;
  total_due?: number; // TODO: this field is calculated on the FE side, need to understand if we need it or we can rid of it and use the discounted_price instead

  cancellation_checkout_id?: Nullable<number>;
  cancellable?: boolean; // TODO: check if this field is optional or not

  /**
   * @warning **This is NOT RETURNED BY BACKEND**
   * @see {@link Checkout.header_data.customer }
   * @deprecated
   */
  customer?: Customer; // NOT RETURNED BY BACKEND

  customer_full_name?: Nullable<string>;
  pdf_path?: Nullable<string>;

  sum_of_discounts?: Nullable<number>;
}

export interface CheckoutReprint {
  id: number;
  customer_id: number;
  checkout_id: number;
  creation_user_id: number;
  print_count: number;
  reprint_count: number;
  reason: string;
  created_at: string;
  updated_at: string;
  short_signature: string;
}

export type AvailableCreditCard = {
  id: number;
  card_holder_name: string;
  card_number: string;
  card_brand: PaymentBrand;
  expiry_date_year: number;
  expiry_date_month: number;
  state: PaymentMethodState;
  default: boolean;
  default_backup: boolean;
};

export type AvailableOnlinePaymentMethod = {
  name: PAYMENT_METHOD;
  publishable_key?: Nullable<string>; // stripe
  client_id?: Nullable<string>; // paypal
  available_credit_cards: AvailableCreditCard[]; // stripe
};

export type AvailableOnlinePaymentMethods = AvailableOnlinePaymentMethod[];

export interface OnlineCheckoutDetail {
  net_price: number;
  vat_percentage: number;
  vat_amount: number;
}

export type OnlineCheckoutDetails = {
  details: OnlineCheckoutDetail[];
  total_paid_amount: number;
};

export type GenericLineItem = {
  discounted_price: string;
  product_name: string;
  total_price: string;
  unit_price: string;
  staff_member_id: number;
};

export interface CheckoutMarketingPromotion {
  venue_treatment_id: Nullable<number>;
  appointment_id: Nullable<number>;
  product_id: Nullable<number>;
  selling_marketing_promotion_id: Nullable<number>;
  recharge_customer_promotion_id: Nullable<number>;
  accounting_marketing_promotion_id: Nullable<number>;
  associate_promotion_to_customer: boolean;
  serial_number: Nullable<number>;
  staff_member_id: number;
  product_name: Nullable<string>;
  quantity: number;
  unit_price: number;
  total_price: number;
  discounted_price: number;
  proposed_by_staff_member: boolean;
}

export interface CheckoutCart extends Checkout {
  client_token: string;
  customer_cart_items?: CustomerCartItem[];
  cashDrawForDebts?: CustomerDebt[];
  genericLineItems?: GenericLineItem[];
  marketingPromotions?: CheckoutMarketingPromotion[];
  debt_staff_member_id: Nullable<number>;
  debt_remind_every: Nullable<number>;
  cancellation_protection_booking_token_charged?: Nullable<number>;
  __isCart: boolean;
}
