import { Venue, AcceptanceForm, User, SessionVenue, ACL_PERMISSIONS_VALUES, ACL_PERMISSION } from 'models';

import {
  SESSIONS_LOGOUT,
  SESSIONS_SIGNIN_REQUEST,
  SESSIONS_SIGNIN_RESPONSE,
  USER_ACL_PERMISSIONS_RESPONSE,
  UPDATED_SINCE_RECEIVED,
  SWITCH_CURRENT_VENUE,
  API_GET_RESPONSE,
  API_POST_RESPONSE,
  LOCAL_GRACE_PERIOD,
  SESSIONS_RESET_VENUE_DATA,
} from 'actionTypes';
import { ISOTime } from 'utils/dates';
import { API_ERROR } from 'actions/data_providers/api';

/**
 * max number of venues that can be synched concurrently when user is multi venue
 */
const MAX_ACTIVE_VENUES_ALLOWED = 2;

interface ResponseData {
  timestamp: string;
  venue?: Venue;
  acceptance_forms: AcceptanceForm[];
  user: User;
}

interface Response {
  data?: ResponseData;
}

type ByVenue<T> = { [key: number]: T };

export type Permission = {
  unique_key: ACL_PERMISSION;
  mode: ACL_PERMISSIONS_VALUES;
};
export type Permissions = ReadonlyArray<Permission>;

export type LastRequest = {
  timestamp?: number;
  error?: API_ERROR;
};

export interface Session {
  currentUser: Omit<User, 'venues'> | null;

  currentVenue: Venue | null;
  currentVenueId: number | null;
  authenticatedVenues: Venue[];
  activeVenuesIDs: number[];

  acceptanceFormsByVenue: ByVenue<AcceptanceForm[]>;
  updatedSinceByVenue: ByVenue<ISOTime['DateTime']>;

  permissionsByVenue: ByVenue<Permissions>;
  gracePeriodBannerDismissDateByVenue?: ByVenue<number>;
  lastRequest?: LastRequest;
  updated_since?: string;
  sessionVenues?: Omit<SessionVenue, 'acl_group'>[] | null;
}

interface Action {
  type: string;
  response?: Response;
  error?: API_ERROR;
  user?: User;
  venue_id?: number;
  gracePeriodBannerDismissDate?: number;
  timestamp?: number;
}

const defaultSessions: Session = {
  activeVenuesIDs: [],
  currentUser: null,

  currentVenue: null,
  currentVenueId: null,
  authenticatedVenues: [],

  sessionVenues: [],
  gracePeriodBannerDismissDateByVenue: {},
  permissionsByVenue: {},
  acceptanceFormsByVenue: {},
  updatedSinceByVenue: {},
};

const getVenuesAndPermissionsFromSession = (action: Action): [Session['sessionVenues'], ByVenue<Permissions>] => {
  const venues: SessionVenue[] = (action.user && action.user.venues) || [];

  const sessionVenues: Session['sessionVenues'] =
    venues.map((venue) => {
      // remove acl_group since it's already in permissionsByVenue
      const { acl_group, ...rest } = venue;
      return rest;
    }) || [];

  /**
   * permissionsByVenue = {
   *    "5": [
   *      {
   *        assignable: true
   *        created_at: "2019-02-06T12:07:38+01:00"
   *        hidden: false
   *        id: 121
   *        mode: "full"
   *        name: "Notifiche Titolare"
   *        nesting_level: 0
   *        parent_id: null
   *        state: null
   *        unique_key: "manager-notifications"
   *        updated_at: "2019-02-06T12:07:38+01:00"
   *      },
   *      ...
   *    ]
   * }
   */
  const permissionsByVenue = venues.reduce((p, sessionVenue) => {
    return {
      ...p,
      [sessionVenue.id]: ((sessionVenue.acl_group && sessionVenue.acl_group.acl_permissions) || []).map(
        (aclPermission) => ({
          unique_key: aclPermission.unique_key,
          mode: aclPermission.mode,
        })
      ),
    };
  }, {});

  return [sessionVenues, permissionsByVenue];
};

const getCurrentUser = (user: User): Session['currentUser'] => {
  const { venues, ...rest } = user;
  return rest;
};

export const sessions = (state: Session = defaultSessions, action: Action): Session => {
  let currentUser: Session['currentUser'];

  switch (action.type) {
    case SESSIONS_LOGOUT:
      return {
        ...state,
        ...defaultSessions,

        lastRequest: undefined,
      };

    case SESSIONS_SIGNIN_REQUEST:
      return {
        ...state,
        lastRequest: { timestamp: new Date().getTime() },
      };

    case SESSIONS_SIGNIN_RESPONSE: {
      if (action.error || !action.user) {
        return {
          ...state,
          lastRequest: { error: action.error },
        };
      }

      currentUser = getCurrentUser(action.user);

      const [sessionVenues, permissionsByVenue] = getVenuesAndPermissionsFromSession(action);

      return {
        ...state,
        currentUser,
        currentVenueId: currentUser?.venue_id || null,
        permissionsByVenue,
        lastRequest: undefined,
        sessionVenues,
        activeVenuesIDs: currentUser?.venue_id ? [currentUser?.venue_id] : [],
      };
    }

    case USER_ACL_PERMISSIONS_RESPONSE: {
      if (!action.user || !action.user.venues) {
        return state;
      }

      const [sessionVenues, permissionsByVenue] = getVenuesAndPermissionsFromSession(action);

      return {
        ...state,
        sessionVenues,
        permissionsByVenue,
      };
    }

    case UPDATED_SINCE_RECEIVED: {
      if (!action.venue_id || !action.timestamp) {
        return state;
      }

      const updated_since = new Date(action.timestamp).toISOString();

      return {
        ...state,
        updatedSinceByVenue: {
          ...state.updatedSinceByVenue,
          [action.venue_id]: updated_since,
        },
      };
    }

    case SESSIONS_RESET_VENUE_DATA:
      if (!action.venue_id) {
        return state;
      }

      return {
        ...state,
        updatedSinceByVenue: ((): Session['updatedSinceByVenue'] => {
          const byVenue = { ...state.updatedSinceByVenue };
          delete state.updatedSinceByVenue[action.venue_id];
          return byVenue;
        })(),
      };

    case API_POST_RESPONSE:
    case API_GET_RESPONSE: {
      /**
       * process only response with `venue`
       */
      if (!action.response || !action.response.data || !action.response.data.venue) {
        return state;
      }

      const inputVenue = action.response.data.venue;

      /**
       * process only more recent venues
       */
      if (
        state.authenticatedVenues.find(
          (venue) => venue.id === inputVenue.id && inputVenue.updated_at <= venue.updated_at
        )
      ) {
        return state;
      }

      const newAuthenticatedVenues = [
        ...state.authenticatedVenues.filter((venue) => venue.id !== inputVenue.id),
        inputVenue,
      ];

      const newCurrentVenue = newAuthenticatedVenues.find((venue) => venue.id === state.currentVenueId) || null;

      let acceptanceFormsByVenue = state.acceptanceFormsByVenue;
      if (inputVenue.id) {
        acceptanceFormsByVenue = {
          ...acceptanceFormsByVenue,
          [inputVenue.id]: action.response.data.acceptance_forms || [],
        };
      }

      return {
        ...state,
        authenticatedVenues: newAuthenticatedVenues,
        acceptanceFormsByVenue,
        currentVenue: newCurrentVenue,
      };
    }

    case SWITCH_CURRENT_VENUE: {
      if (!action.venue_id || !state.sessionVenues?.find((venue) => venue.id === action.venue_id)) {
        return state;
      }

      const newCurrentVenue = state.authenticatedVenues.find((venue) => venue.id === action.venue_id) || null;

      const activeVenuesIDs = [action.venue_id, ...state.activeVenuesIDs.filter((id) => id !== action.venue_id)].slice(
        0,
        MAX_ACTIVE_VENUES_ALLOWED
      );

      return {
        ...state,
        currentVenue: newCurrentVenue,
        currentVenueId: action.venue_id,
        activeVenuesIDs,
      };
    }

    case LOCAL_GRACE_PERIOD: {
      if (action.venue_id && action.gracePeriodBannerDismissDate) {
        return {
          ...state,
          gracePeriodBannerDismissDateByVenue: {
            ...state.gracePeriodBannerDismissDateByVenue,
            [action.venue_id]: action.gracePeriodBannerDismissDate,
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};
