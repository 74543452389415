/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { Paginated, PaginationVariables, SearchVenueTreatmentsResponse } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';
import { withLegacyHeaderPagination } from 'API/utils';

const maybeLegacyHeaderPaginationToJSON = withLegacyHeaderPagination(maybeResponseToJSON);

export const TREATMENTS =
  ({ variables }: V<PaginationVariables>) =>
  (): Promise<Paginated<SearchVenueTreatmentsResponse>> =>
    Http()
      .get<Paginated<SearchVenueTreatmentsResponse>, PaginationVariables>(ENDPOINT.VENUE_TREATMENTS._TREATMENTS())({
        searchParams: variables,
      })
      .then(maybeLegacyHeaderPaginationToJSON);
