import { styled, css } from 'styles';
import { withTheme } from 'components/core';

const sizeStyles = {
  md: css`
    height: 40px;
    border-radius: 20px;
    line-height: 38px;
    font-size: 1.2rem;
  `,
};

const styleAttributes = {
  basic: (theme, color) => css`
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors[color] || theme.colors.blue};
    color: ${theme.colors[color] || theme.colors.blue};
  `,
  primary: (theme, color) => css`
    background-color: ${theme.colors[color] || theme.colors.blue};
    border: 1px solid ${theme.colors[color] || theme.colors.blue};
    color: ${theme.colors.white};
  `,
};

const StyledButton = withTheme(styled('div')`
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  ${({ size }) => sizeStyles[size] || sizeStyles.md};
  ${({ type, theme, color }) => styleAttributes[type](theme, color) || styleAttributes.basic(theme, color)};

  align-items: center;
  ${({ display = 'inline-block' }) => display && { display }};

  ${({ disabled }) =>
    disabled &&
    css`
      background-color: lightgray;
      border-color: lightgray;
      opacity: 0.3;
      pointer-events: none;
    `};
`);

export default StyledButton;
