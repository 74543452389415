/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { responseToJSON } from 'API/http.utils';
import { Paginated, PaginationVariables, VenueProductsTransactionHistory } from 'API/types';

type VenueProductsTransactionHistoryPayload = {
  readonly limit?: number;
  readonly direction?: 'inward' | 'outward';
};

export const TRANSACTION_HISTORY =
  ({ variables }: V<Partial<PaginationVariables> & VenueProductsTransactionHistoryPayload>) =>
  (): Promise<Paginated<VenueProductsTransactionHistory>> =>
    Http()
      .get<
        Paginated<VenueProductsTransactionHistory>,
        Partial<PaginationVariables> & VenueProductsTransactionHistoryPayload
      >(ENDPOINT.VENUE_PRODUCTS._TRANSACTION_HISTORY())({
        searchParams: variables,
      })
      .then(responseToJSON);
