import { apiGet } from './data_providers/api';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { actionShouldFetchData } from 'utils/reducersUtils';
import { ParametricActionCreator } from './types';
import { Venue } from 'models/venue';
import { VENUE_FETCH_CUSTOMER_TAGS_START } from 'actionTypes';

export type FetchCustomerTagsActionCreator = ParametricActionCreator<void, Promise<void>>;

interface FetchCustomerTagsLifecycleActionPayload {
  venue_id: Venue['id'];
  auth: string;
}

interface FetchCustomerTagsLifecycleAction extends FetchCustomerTagsLifecycleActionPayload {
  type: string;
}

const fetchCustomerTagsStart = ({
  venue_id,
  auth,
}: FetchCustomerTagsLifecycleActionPayload): FetchCustomerTagsLifecycleAction => ({
  type: VENUE_FETCH_CUSTOMER_TAGS_START,
  venue_id,
  auth,
});

export const fetchCustomerTags: FetchCustomerTagsActionCreator =
  () =>
  (dispatch, getState): Promise<void> =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth: string = getCurrentUserToken(getState());

      if (!venue_id || !auth) {
        return reject({ code: 400 });
      }

      dispatch(fetchCustomerTagsStart({ venue_id, auth }));

      dispatch(
        apiGet({
          path: `/venues/${venue_id}/customer_tags.json`,
          onResponse(response) {
            if (!response.success) {
              return reject();
            }
            return resolve();
          },
          onError() {
            return reject();
          },
        })
      );
    });

export const fetchCustomerTagsIfNeeded: ParametricActionCreator<void, undefined | Promise<void>> =
  () =>
  (dispatch, getState): undefined | Promise<void> => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());
    if (!venue_id || !auth) {
      return;
    }

    const need_fetch = actionShouldFetchData(getState().customerTagsByVenue[venue_id]);
    if (need_fetch) {
      return dispatch(fetchCustomerTags());
    }

    return;
  };
