import { createSelector } from 'reselect';
import { getCurrentVenueId } from '../getCurrentVenue';
import { getEntities } from '../getEntities';
import filterDeletedItems from 'utils/filterDeletedItems';
import { getPromotionsTypesByType } from './getPromotionsTypes';

const getPromotionsByVenue = (state) => state.promotionsByVenue;

const getCustomerPromotionsByVenue = (state) => state.customerPromotionsByVenue;

export const getPromotionsLoading = createSelector(
  [getCurrentVenueId, getPromotionsByVenue],
  (venue_id, promotionsByVenue) => {
    return promotionsByVenue[venue_id] && promotionsByVenue[venue_id].isFetching ? true : false;
  }
);

export const getCustomerPromotionsLoading = createSelector(
  [getCurrentVenueId, getCustomerPromotionsByVenue],
  (venue_id, customerPromotionsByVenue) => {
    return customerPromotionsByVenue[venue_id] && customerPromotionsByVenue[venue_id].isFetching ? true : false;
  }
);

const addPromotionTypeName = (promotion, promotionTypeByType) => ({
  ...promotion,
  marketing_promotion_type_name: promotionTypeByType[promotion.marketing_promotion_type]
    ? promotionTypeByType[promotion.marketing_promotion_type].name
    : promotion.marketing_promotion_type,
});

const sortPromotionsByName = (a, b) => ((b.name || '').toLowerCase() < (a.name || '').toLowerCase() ? 1 : -1);

const emptyPromotionsList = [];

const getAllPromotionsList = createSelector(
  [getCurrentVenueId, getPromotionsByVenue, getEntities.marketing_promotion, getPromotionsTypesByType],
  (venue_id, promotionsByVenue, promotionById, promotionTypeByType) => {
    const promotionsList = (promotionsByVenue[venue_id] && promotionsByVenue[venue_id].items) || emptyPromotionsList;

    if (promotionsList === emptyPromotionsList) {
      return promotionsList;
    }

    return promotionsList
      .map((promotion) => ({
        ...promotion,
        ...((promotionById[promotion.id] && promotionById[promotion.id].data) || {}),
      }))
      .filter(filterDeletedItems)
      .sort(sortPromotionsByName)
      .map((promotion) => addPromotionTypeName(promotion, promotionTypeByType));
  }
);

export const getPromotionsList = createSelector(getAllPromotionsList, (promotionsList) => {
  if (promotionsList === emptyPromotionsList) {
    return promotionsList;
  }

  return promotionsList.filter(
    ({ customer_id, parent_customer_id, marketing_promotion_type }) =>
      !customer_id && !parent_customer_id && marketing_promotion_type !== 'flash'
  );
});

export const getFlashPromotionsList = createSelector(getAllPromotionsList, (promotionsList) => {
  if (promotionsList === emptyPromotionsList) {
    return promotionsList;
  }

  return promotionsList.filter(({ marketing_promotion_type }) => marketing_promotion_type === 'flash');
});

export const getCustomerPromotionsList = createSelector(
  [getCurrentVenueId, getCustomerPromotionsByVenue, getEntities.marketing_promotion, getPromotionsTypesByType],
  (venue_id, customerPromotionsByVenue, promotionById, promotionTypeByType) => {
    const promotionsList =
      (customerPromotionsByVenue[venue_id] && customerPromotionsByVenue[venue_id].items) || emptyPromotionsList;

    if (promotionsList === emptyPromotionsList) {
      return promotionsList;
    }

    return promotionsList
      .map((promotion) => ({
        ...promotion,
        ...((promotionById[promotion.id] && promotionById[promotion.id].data) || {}),
      }))
      .filter(filterDeletedItems)
      .filter(
        ({ customer_id, parent_customer_id, marketing_promotion_type }) =>
          (customer_id || parent_customer_id) && marketing_promotion_type !== 'flash'
      )
      .sort(sortPromotionsByName)
      .map((promotion) => addPromotionTypeName(promotion, promotionTypeByType));
  }
);

export const getPromotionsById = createSelector(
  [getPromotionsList, getCustomerPromotionsList],
  (promotionsList, customerPromotionsList) => {
    return promotionsList.concat(customerPromotionsList).reduce((promotionsById, promotion) => {
      if (promotion.id) {
        promotionsById[promotion.id] = promotion;
      }
      return promotionsById;
    }, {});
  }
);
