import { useCallback, memo, useState, useEffect } from 'react';
import { Section, Text, Select } from 'components/core';
import { discountOptions } from './utils';
import PriceAmount from 'components/lib/PriceAmount';
import { Row, OriginalPrice } from './PromoFlashEdit.theme';

const VenueTreatment = memo(({ venueTreatment, setLocalChanges, promoFlash }) => {
  const thisVenueTreatmentResource = promoFlash.included_resources_attributes.filter(
    (i) =>
      (i.table_name === 'treatments' && i.record_id === venueTreatment.treatment_id) ||
      (i.table_name === 'venue_treatments' && i.record_id === venueTreatment.id)
  )[0];
  /**
   * Category select is disabled if in included venue treatments, a value differs
   */
  const categoryValue = thisVenueTreatmentResource ? thisVenueTreatmentResource.discount_percent_amount : 0;

  const [touched, setTouched] = useState(categoryValue > 0);

  const onTreatmentDiscountChange = useCallback(
    (id, discount_percent_amount) => {
      setTouched(discount_percent_amount > 0);
      setLocalChanges({
        included_resources_attributes: [
          /**
           * Remove from included resources the parent category and the treatment itself if already exists
           */
          ...promoFlash.included_resources_attributes.filter(
            (i) =>
              !(
                (i.table_name === 'treatment_categories' && i.record_id === venueTreatment.label.category_id) ||
                ((i.table_name === 'treatments' || i.table_name === 'venue_treatments') && i.record_id === id)
              )
          ),
          {
            table_name: 'venue_treatments',
            record_id: id,
            discount_type: 'percent',
            discount_percent_amount,
          },
        ],
      });
    },
    [promoFlash.included_resources_attributes, setLocalChanges, venueTreatment.label.category_id]
  );

  // Reset touched if discount is changed to 0 from the outside
  useEffect(() => {
    setTouched(categoryValue > 0);
  }, [categoryValue]);

  return (
    <Section borderTop padding={0.3} paddingTop={0.6} paddingBottom={0.6} background={touched ? 'lightBlue' : 'white'}>
      <Row>
        <Section flex={1} displayFlex alignItems="center" paddingBottom={0.3}>
          <Text block flex size={12} color="text1">
            {venueTreatment.computed_name}
          </Text>
          <OriginalPrice>
            <PriceAmount size={10} color="text1" value={venueTreatment.price} />
            <Text size={10} color="text1">
              {' / '}
              {Math.floor(venueTreatment.duration / 3600)}h {Math.floor((venueTreatment.duration % 3600) / 60)}m
            </Text>
          </OriginalPrice>
        </Section>
        <Section displayFlex justifyContent="flex-end" alignItems="center">
          <Text bold uppercase size={10} color="text2" intl="checkout.discount" />
          <Section width={160} paddingLeft={1}>
            <Select value={categoryValue} onChange={(v) => onTreatmentDiscountChange(venueTreatment.id, v)}>
              <Select.Option value={0}>-</Select.Option>
              {discountOptions.map((v) => (
                <Select.Option key={v} value={v / 100}>
                  {v + '% | ' + (venueTreatment.price * (1 - v / 100)).toFixed(2)}
                </Select.Option>
              ))}
            </Select>
          </Section>
        </Section>
      </Row>
    </Section>
  );
});

export default VenueTreatment;
