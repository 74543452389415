import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';
import {
  NEWSLETTERS_LIST_REQUEST,
  NEWSLETTERS_LIST_RESPONSE,
  NEWSLETTERS_CREATE_RESPONSE,
  NEWSLETTERS_UPDATE_RESPONSE,
  NEWSLETTERS_DELETE_RESPONSE,
} from 'actionTypes';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const newsletters = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case NEWSLETTERS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case NEWSLETTERS_LIST_RESPONSE:
      if (!action.newsletters || !action.newsletters.length) {
        return {
          ...state,
          ...reducerValuesOnResponse([].concat(state.items || [])),
        };
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.newsletters || []), {
          overrideDuplicateIds: 1,
        }),
      };

    case NEWSLETTERS_UPDATE_RESPONSE:
      if (!action.newsletter) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.newsletter || []), {
          overrideDuplicateIds: 1,
        }),
      };

    case NEWSLETTERS_DELETE_RESPONSE:
      if (!action.newsletter) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).filter(({ id }) => id !== action.newsletter.id)),
      };

    case NEWSLETTERS_CREATE_RESPONSE:
      if (!action.newsletter) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.newsletter || [])),
      };

    default:
      return state;
  }
};

export const newslettersByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case NEWSLETTERS_LIST_REQUEST:
    case NEWSLETTERS_LIST_RESPONSE:
    case NEWSLETTERS_UPDATE_RESPONSE:
    case NEWSLETTERS_DELETE_RESPONSE:
    case NEWSLETTERS_CREATE_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = newsletters(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
