import { COLORS, BOX_SHADOW, MEDIA, SIZES, UTILS, CONTRASTS, FONTS, BREAKPOINTS, TYPOGRAPHY } from './tokens';
import {
  Colors as _Colors,
  Typography as _Typography,
  FontColors as _FontColors,
  FontSizes as _FontSizes,
  FontFamilies as _FontFamilies,
  FontWeights as _FontWeights,
  Breakpoint as _Breakpoint,
  ValueOf,
} from '@uala/ui-core';

export const theme = {
  colors: COLORS,
  boxShadow: BOX_SHADOW,
  media: MEDIA,
  sizes: SIZES,
  utils: UTILS,
  contrasts: CONTRASTS,
  fonts: FONTS,
  breakpoints: BREAKPOINTS,
  typography: TYPOGRAPHY,
} as const;

export type Theme = typeof theme;

export type Colors = _Colors;
export type Typography = _Typography;
export type FontColors = _FontColors;
export type FontSizes = _FontSizes;
export type FontFamilies = _FontFamilies;
export type FontWeights = _FontWeights;
export type Breakpoint = _Breakpoint;

/**
 * @see: {@link https://stackoverflow.com/questions/19799777/how-to-add-transparency-information-to-a-hex-color-code}
 */
export function addAlpha(color: ValueOf<typeof COLORS>, opacity: number): string {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
}

export default theme;
