import { UserACLGroup } from 'models';

const byName = (a: UserACLGroup, b: UserACLGroup) => (a.name < b.name ? -1 : 1);
const toName = (g: UserACLGroup) => g.name;

export type SegregatedState = {
  sessions: {
    currentUser: {
      acl_groups: UserACLGroup[];
    } | null;
  };
};

export const getEmployeeTitle = (state: SegregatedState): string =>
  state.sessions.currentUser?.acl_groups //
    .sort(byName)
    .map(toName)
    .join(', ') ?? '';
