import { FunctionComponent, useEffect, useState } from 'react';
import { Text, Section, Card, Avatar, Spinner } from 'components/core';
import { Header, HeaderTitle } from 'components/header';
import PaymentMethods from './PaymentMethods';
import { BillingCompany } from 'models';

type TemplateProps = {
  fetchBillingCompanyInfo: (token: string) => Promise<BillingCompany>;
};

const Template: FunctionComponent<TemplateProps> = ({ fetchBillingCompanyInfo }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [billingCompany, setBillingCompany] = useState<BillingCompany | null>(null);
  const authToken = (window.location.href.split('?credit_card_token=')[1] || '').split('&')[0];

  useEffect(() => {
    (async (): Promise<void> => {
      setIsLoading(true);
      const fetchedBillingCompany = await fetchBillingCompanyInfo(authToken);
      setBillingCompany(fetchedBillingCompany);
      setIsLoading(false);
    })();
  }, [authToken, fetchBillingCompanyInfo]);

  if (!authToken) return null;

  return (
    <>
      {/* HEADER SECTION */}
      <Header>
        <HeaderTitle
          isRoot
          rootTitle={<Text size={12} bold uppercase spacing={1} intl="account.payment_methods.popup.add_method" />}
          rootIcon="add-card"
        />
      </Header>

      <Section
        width="100%"
        paddingTop={5}
        paddingBottom={2}
        displayFlex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {billingCompany && !isLoading ? (
          <Section width="100%" maxWidth={800}>
            <Section width="100%" marginBottom={1.5}>
              <Section flex>
                <Section>
                  <Avatar />
                </Section>
                <Section displayFlex flexDirection="column" paddingLeft={1} justifyContent="space-around">
                  <Text bold size={20} color="text1">
                    {billingCompany.name || '-'}
                  </Text>
                  <Section marginTop={0.25}>
                    <Text size={14} color="text2">
                      {billingCompany.address || '-'}
                    </Text>
                  </Section>
                </Section>
              </Section>
            </Section>
            <Card width="100%">
              <Section padding={1} paddingBottom={0}>
                <PaymentMethods authToken={authToken} billingCompanyId={billingCompany.id} guest />
              </Section>
            </Card>
          </Section>
        ) : (
          <Section width="100%" paddingTop={4} displayFlex justifyContent="center">
            <Spinner />
          </Section>
        )}
      </Section>
    </>
  );
};

export default Template;
