import { PLAN_IDENTIFIER } from './venue';

export enum BILLING_ADDON_PLAN_TYPE {
  CURRENT = 'current',
  UPGRADE = 'upgrade',
  DOWNGRADE = 'downgrade',
}

export enum BILLING_ADDON_OPTIONS_BILLED_EVERY {
  MONTH = 1,
  YEAR = 12,
}

export type BillingAddonOption = {
  id: number;
  name: string;
  price: number;
  discounted_price: number;
  bill_every: BILLING_ADDON_OPTIONS_BILLED_EVERY;
  monthly_price: number;
  buyable: boolean;
  discounted_monthly_price: number;
};

export type BillingAddonFeature = {
  id: number;
  name: string;
};

export type BillingAddon = {
  id: number;
  name: string;
  description: string;
  promo_label: string;
  action_label: string;
  country_id: number;
  options_popup_title: string;
  options_popup_description: string;
  options_popup_select: string;
  options_cart_item_name: string;
  billing_addon_options: BillingAddonOption[];
  current_plan: boolean;
  most_popular: boolean;
  is_upgrade: boolean;
  plan_features: BillingAddonFeature[];
  plan_identifier: Exclude<PLAN_IDENTIFIER, PLAN_IDENTIFIER.FREE> | null;
};
