/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { VenueProductMovementsResponse, VenueProductShowQuery } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const MOVEMENTS =
  ({ variables: { product_id } }: V<VenueProductShowQuery>) =>
  (): Promise<VenueProductMovementsResponse> =>
    Http()
      .get<VenueProductMovementsResponse, never>(ENDPOINT.VENUE_PRODUCTS._MOVEMENTS(product_id))({})
      .then(maybeResponseToJSON);
