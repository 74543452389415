export enum ACL_PERMISSION {
  CALENDAR = 'calendar',
  CASH_REGISTER = 'cash-register',
  CUSTOMERS = 'customers',
  MARKETING = 'marketing',
  WAREHOUSE = 'warehouse',
  WAREHOUSE_ECOMMERCE = 'warehouse_ecommerce',
  EXPENSES = 'expenses',
  BUSINESS_HOURS = 'business-hours',
  SERVICES = 'services',
  STAFF = 'staff',
  MANAGER = 'manager',
  MANAGER_TREND_ANALYSIS = 'manager_trend-analysis',
  MANAGER_COMPANY_REPORT = 'manager_company-report',
  MANAGER_SALES_PROCEEDS = 'manager_sales-proceeds',
  MANAGER_COLLABORATORS_REPORT = 'manager_collaborators-report',
  MANAGER_CUSTOMERS_REPORT = 'manager_customers-report',
  MANAGER_STOCK_REPORT = 'manager_stock-report',
  MANAGER_INVENTORY_REPORT = 'manager_inventory-report',
  ACCOUNT = 'account',
  CALENDAR_DISPLAY_PAST_DAYS = 'calendar_appointment-list_display-previous-days',
  REVIEWS = 'reviews',
  WAREHOUSE_PRODUCTS_DETAILS_OPENING = 'warehouse_products-details_opening-product-details',
  WAREHOUSE_DELETE_PRODUCT = 'warehouse_products-details_opening-product-details_edit-product_delete-product',

  CASH_REGISTER_PETTY_CASH = 'cash-register_petty-cash',
  CASH_REGISTER_RECEIPTS_ISSUED_DELETE_RECEIPTS = 'cash-register_receipts-issued_delete-receipts',
  CALENDAR_APPOINTMENT_LIST_DISPLAY_NAME_SURNAME_ON_APPOINTMENT_BOX = 'calendar_appointment-list_display-name-surname-on-the-appointment-box',
  CALENDAR_APPOINTMENT_LIST_OPENING_APPOINTMENT_CHECKOUT = 'calendar_appointment-list_opening-appointment_check-out',
  CALENDAR_APPOINTMENT_LIST_OPENING_APPOINTMENT_APPOINTMENTS_HISTORY = 'calendar_appointment-list_opening-appointment_appointments-history',
  CALENDAR_APPOINTMENT_LIST_OPENING_APPOINTMENT_MODIFY_APPOINTMENT = 'calendar_appointment-list_opening-appointment_modify-appointment',
  CALENDAR_APPOINTMENT_LIST_OPENING_APPOINTMENT_CUSTOMER_DETAILS = 'calendar_appointment-list_opening-appointment_opening-customer-details',
  CALENDAR_APPOINTMENT_LIST_OPENING_APPOINTMENT_DELETE_APPOINTMENT = 'calendar_appointment-list_opening-appointment_delete-appointment',
  CALENDAR_APPOINTMENT_LIST_OPENING_APPOINTMENT_PRINT_APPOINTMENT_DETAILS = 'calendar_appointment-list_opening-appointment_print-appointment-detail',
  CALENDAR_APPOINTMENT_LIST_OPENING_APPOINTMENT_REVERT_CHECK_IN = 'calendar_appointment-list_opening-appointment_revert-check-in',

  CUSTOMERS_EXPORT = 'customers_customers-export',
  EDIT_PROFILE = 'account_details-edit',

  STORE_ACCESS = 'store_access',
  ACCOUNT_BILLING = 'account_billing',
}

export type SimplePermissionsMap = {
  [key in ACL_PERMISSION]: ACL_PERMISSIONS_VALUES;
};

export enum ACL_PERMISSIONS_VALUES {
  FULL = 'full',
  RESTRICT = 'restrict',
  NONE = 'none',
}

export type CheckPermissionCallback = (unique_key: ACL_PERMISSION) => ACL_PERMISSIONS_VALUES;
