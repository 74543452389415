export enum RECAP_RANGE {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  WEEK = 'this_week',
}

export enum RECAP_COMPARE_OPTIONS {
  THIS_DAY_LAST_WEEK = 'this_day_last_week',
  THIS_DAY_LAST_YEAR = 'this_day_last_year',
  LAST_WEEK = 'last_week',
  THIS_WEEK_LAST_YEAR = 'this_week_last_year',
  TOMORROW_LAST_WEEK = 'tomorrow_last_week',
  TOMORROW_LAST_YEAR = 'tomorrow_last_year',
}

type RecapPopularTreatment = {
  name: string;
  bookings: number;
  bookings_percentage: number;
};

type RecapItem = {
  main: number;
  comparison: number | null;
  difference_percent: number | null;
};

type AgendaPercentage = RecapItem & {
  appointments_count: number;
  reference_last_90_days: number;
};
export interface AppointmentsRecap {
  appointments: {
    appointments_count: RecapItem;
    online_percentage: RecapItem;
    offline_percentage: RecapItem;
    most_popular_treatments: {
      main: RecapPopularTreatment[];
      comparison: RecapPopularTreatment[] | null;
    };
  };
  agenda_percentage: AgendaPercentage;
  availabilities: {
    available_time_minutes: RecapItem;
    busy_time_percentage: RecapItem;
    workable_time_minutes: RecapItem;
  };
  revenues: {
    bookings_revenues_amount: RecapItem;
    potential_revenues_amount: RecapItem;
    bookings_revenues_percentage: RecapItem;
  };
}
