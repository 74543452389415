import { styled, css } from 'styles';
import { withTheme } from 'components/core';

const SwapFieldsCtrlWrapper = withTheme(
  styled('div')(
    ({ theme: { media } }) => css`
      position: absolute;
      top: 50%;
      right: -50px;
      transform: translateY(-50%);

      display: none;

      ${media.tablet`
        display: block;
      `}
    `
  )
);

const SwappableFields = styled('div')<{ withPadding?: boolean }>(
  ({ withPadding = false }) => css`
    position: relative;
    ${withPadding && 'padding-bottom: 10px;'}
  `
);

export { SwapFieldsCtrlWrapper, SwappableFields };
