import { createPortal } from 'react-dom';
import * as S from './Tooltip.theme';
import { useTooltip } from './useTooltip.hook';

export const TOOLTIP_POSITION = {
  BOTTOM: 'BOTTOM',
  RIGHT: 'RIGHT',
  LEFT: 'LEFT',
  TOP: 'TOP',
} as const;

export const TOOLTIP_DOM_ID = 'tooltip-root';

export interface TooltipProps {
  readonly defaultWidth?: string;
  readonly type?: string;
}

export function Tooltip({ defaultWidth }: TooltipProps): JSX.Element {
  const portalRef = document.getElementById(TOOLTIP_DOM_ID) as HTMLElement;
  const { isTooltipVisible, top, left, content, width, color, defaultPosition } = useTooltip({ width: defaultWidth });
  const isContentAtTheBottom = defaultPosition === TOOLTIP_POSITION.BOTTOM;

  return createPortal(
    isTooltipVisible ? (
      <S.TooltipContainer
        data-testid="tooltip-container"
        top={top}
        left={left}
        width={width}
        color={color}
        bottom={isContentAtTheBottom}
      >
        <S.TooltipContent bottom={isContentAtTheBottom} color={color}>
          {content}
        </S.TooltipContent>
      </S.TooltipContainer>
    ) : null,
    portalRef
  );
}
