/* eslint-disable no-restricted-imports */
import { PatchTestStatusQuery, VenueProductsResponse } from 'API/types';
import { Http } from 'API/Http';
import { ENDPOINT } from 'API/ENDPOINT';
import { V } from 'API';
import { maybeResponseToJSON } from 'API/http.utils';

export const PATCH_TEST_STATUS =
  ({ variables: { customer_id, venue_treatment_ids, valid_at } }: V<PatchTestStatusQuery>) =>
  (): Promise<VenueProductsResponse> =>
    Http()
      .get<VenueProductsResponse, PatchTestStatusQuery>(
        ENDPOINT.PATCH_TESTS._PATCH_TEST_STATUS({ customer_id, venue_treatment_ids, valid_at })
      )({
        searchParams: { customer_id, venue_treatment_ids, valid_at },
      })
      .then(maybeResponseToJSON);
