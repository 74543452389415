export type AgendaPageRulesMemoryData = {
  dailyWeeklyView: 'daily' | 'weekly';
  allOnlyWorkView: 'all' | 'only_working';
  selection: 'default' | 'spa';
  datepicker: 'swipe' | 'monthly';
};
export interface AgendaPageRulesMemory {
  get: () => AgendaPageRulesMemoryData;
  set: (p: keyof AgendaPageRulesMemoryData, v: string) => void;
}

export const createAgendaPageRulesMemory = (): AgendaPageRulesMemory => {
  const mem: AgendaPageRulesMemoryData = {
    dailyWeeklyView: 'daily',
    selection: 'default',
    datepicker: 'swipe',
    allOnlyWorkView: 'all',
  };

  const x: AgendaPageRulesMemory = {
    get: (): typeof mem => JSON.parse(JSON.stringify(mem)),
    set: (p, v: string) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore TODO fix types
      mem[p] = v;
    },
  };
  return x;
};
