import { Environment } from './interfaces';
import { isElectron } from '../native/cash';
import { isAndroidNativeWrapper, isiOsNativeWrapper } from '../native/cash/getCurrentNativeWrapper';

export const getEnvironment = (): Environment => {
  if (isElectron()) {
    return 'Electron';
  } else if (isiOsNativeWrapper()) {
    return 'iOs App';
  } else if (isAndroidNativeWrapper()) {
    return 'Android App';
  }
  return 'web';
};
