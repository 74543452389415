import { byTestId } from 'utils/selectors';
import { EventType, TrackingRule } from 'utils/tracking/aspectTracking';
import { TrackingService } from 'utils/tracking/interfaces';
type ObjectWithTrackMethod = {
  track: TrackingService['track'];
};
type Props = {
  trackingService: ObjectWithTrackMethod;
};
const CLICK: EventType[] = ['click', 'pointerdown'];

export const getPromoFlashRules = ({ trackingService }: Props) => {
  const promoFlashEventName = 'PromoFlash Clicked';
  let isPromoFlashOpened = false;
  let interaction: 0 | 1 = 0;
  const promoFlashOpenButtonSelector = `${byTestId('promo-flash-trigger')}, ${byTestId('promo-flash-trigger')} *`;
  const promoFlashTestId = byTestId('promo-flash');
  const promoFlashInsideSelector = `${promoFlashTestId}, ${promoFlashTestId} *`;
  const promoFlashWorkingAreaSelector = `${promoFlashTestId} .RadioGroup *`;

  const promoFlashOpened = () => {
    isPromoFlashOpened = true;
  };
  const promoFlashClosed = () => {
    isPromoFlashOpened = false;
    interaction = 0;
  };
  const promoFlashInteractedWith = () => {
    interaction = 1;
  };

  const onClickOutside = () => {
    if (isPromoFlashOpened) trackingService.track(promoFlashEventName, { any_interaction: interaction });

    promoFlashClosed();
  };

  const onPromoFlashOpen = () => {
    promoFlashOpened();
  };

  const isClickEventAndNotALayerInsidePromoFlash = (is: Parameters<TrackingRule>[0]): boolean => {
    const isAnyLayer = is('*', CLICK);
    const isPromoFlashOpenButton = is(promoFlashOpenButtonSelector, CLICK);
    const isInsidePromoFlash = is(promoFlashInsideSelector, CLICK);

    return !(isInsidePromoFlash || isPromoFlashOpenButton) && isAnyLayer;
  };

  const clickOutsideRule: TrackingRule = (is) => {
    if (!isClickEventAndNotALayerInsidePromoFlash(is)) return;

    onClickOutside();
  };

  const promoFlashClickedRule: TrackingRule = (is) => {
    if (!is(promoFlashOpenButtonSelector, CLICK)) return;

    onPromoFlashOpen();
  };
  const promoFlashInteractedWithRule: TrackingRule = (is) => {
    is(promoFlashWorkingAreaSelector, CLICK) && promoFlashInteractedWith();
  };

  return [clickOutsideRule, promoFlashClickedRule, promoFlashInteractedWithRule];
};
