import { connect } from 'react-redux';
import PaymentMethods, { PaymentMethodsProps } from './PaymentMethods';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { getBillingCompanyList } from 'selectors/billing/getBilling';
import { State } from 'reducers/types';
import { createSelector, OutputParametricSelector } from 'reselect';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';

interface PaymentMethodsStateProps {
  authToken?: string;
  billingCompany?: string;
  venueId?: number | null;
}

const mapStateToProps = (): OutputParametricSelector<State, PaymentMethodsStateProps, PaymentMethodsProps, any> =>
  createSelector(
    getCurrentUserToken,
    getBillingCompanyList,
    getCurrentVenueId,
    (authToken, billingCompany, venueId) => {
      return {
        authToken,
        billingCompanyId: billingCompany?.id,
        venueId,
      };
    }
  );

export default connect(mapStateToProps)(PaymentMethods);
