import { styled } from 'styles';

import { FormControl, FormControlInput } from '../../shared';

const CustomerDropdownControl = styled(FormControl)`
  width: 66.6%;
  margin-left: auto;
`;

const CustomerDropdownInput = styled(FormControlInput)`
  .ModelDropdownAction__clear {
    width: 32px;
    right: 10px;
  }
`;

export { CustomerDropdownControl, CustomerDropdownInput };
