import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import { Color } from '../theme';
import { Box, Icon, IconList } from 'components/ui-treatwell-pro';
import * as S from './Button.theme';
import { BaseColor, Variant } from './variants.theme';

type ButtonIcon = { readonly type: IconList; readonly color?: Color };
export type ButtonSharedProps = React.HtmlHTMLAttributes<HTMLButtonElement> & {
  readonly children: ReadonlyArray<ReactNode> | ReactNode;
  readonly disabled?: boolean;
  readonly size?: 'md' | 'lg';
  /**
   * @description add icon from IconList to the left side of the button
   */
  readonly leftIcon?: ButtonIcon;
  /**
   * @description add icon from IconList to the right side of the button
   */
  readonly rightIcon?: ButtonIcon;
  readonly dataTestId?: string;
  readonly width?: string;
};

type ButtonLinkProps = {
  readonly variant: Extract<Variant, 'tertiary'>;
  readonly kind: 'link';
  readonly baseColor: BaseColor;
};

type ButtonDefaultProps = {
  readonly variant: Variant;
  readonly kind: 'default';
  readonly baseColor: BaseColor;
  readonly type?: ButtonHTMLAttributes<HTMLElement>['type'];
};

export interface ButtonIconProps {
  readonly placement?: 'right' | 'left';
  readonly type?: IconList;
  readonly show?: boolean;
  readonly size?: number;
}

type IconButtonProps = {
  readonly variant: Variant;
  readonly kind?: 'icon';
  readonly baseColor: BaseColor;
  readonly type?: ButtonHTMLAttributes<HTMLElement>['type'];
  readonly children: JSX.Element | string | ReactNode;
};

export type ButtonProps =
  | (ButtonSharedProps & ButtonDefaultProps)
  | (ButtonSharedProps & ButtonLinkProps)
  | (Omit<ButtonSharedProps, 'children' | 'leftIcon' | 'rightIcon'> & IconButtonProps);

type ButtonIconComponent = (props: ButtonIconProps) => JSX.Element | null;
export const ButtonIcon: ButtonIconComponent = ({ size = 16, type = '', show = true }) => {
  return show ? (
    <S.ButtonIcon data-testid="button-icon">
      <Icon name={type} color="current" size={size} />
    </S.ButtonIcon>
  ) : null;
};

type ButtonComponent = (props: ButtonProps) => JSX.Element | null;
export const Button: ButtonComponent = ({ children, size = 'lg', dataTestId = 'button', ...props }) => {
  return (
    <>
      {((): JSX.Element | null => {
        switch (props.kind) {
          case 'icon':
            return (
              <S.Button {...props} size={size} data-testid={dataTestId}>
                <Box display="flex" alignItems="center">
                  {children}
                </Box>
              </S.Button>
            );

          case 'link':
            return (
              <S.Button {...props} size={size} data-testid={dataTestId}>
                <ButtonIcon type={props?.leftIcon?.type} show={Boolean(props.leftIcon)} />
                <Box display="flex" alignItems="center">
                  {children}
                </Box>
                <ButtonIcon type={props?.rightIcon?.type} show={Boolean(props.rightIcon)} />
              </S.Button>
            );
          case 'default':
            return (
              <S.Button {...props} size={size} data-testid={dataTestId}>
                <ButtonIcon type={props?.leftIcon?.type} show={Boolean(props.leftIcon)} />
                <Box display="flex" alignItems="center">
                  {children}
                </Box>
                <ButtonIcon type={props?.rightIcon?.type} show={Boolean(props.rightIcon)} />
              </S.Button>
            );
          default:
            return null;
        }
      })()}
    </>
  );
};
