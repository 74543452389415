import moment from 'moment';

/** @typedef {import('models').User} User **/
/** @typedef {import('reducers/types').State} State **/
/** @type {(state: State) => User} **/
export const getCurrentUser = (state) => state.sessions && state.sessions.currentUser;

export const getCurrentUserToken = (state) => state?.sessions?.currentUser?.auth_token;

export const getCurrentUserIntercomUserHash = (state) => state?.sessions?.currentUser?.intercom_user_hash;

export const getCurrentUserVenueId = (state) => {
  const venue_id = state?.sessions?.currentUser?.venue_id;
  return venue_id;
};

export const getCurrentUserEmail = (state) =>
  state.sessions && state.sessions.currentUser && state.sessions.currentUser.email;

export const getCurrentUserValidVenueWizardId = (state) =>
  state.sessions &&
  state.sessions.currentUser &&
  state.sessions.currentUser.venue_wizard_created_at &&
  moment().diff(moment(state.sessions.currentUser.venue_wizard_created_at), 'days') < 15 &&
  state.sessions.currentUser.venue_wizard_id;
