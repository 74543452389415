import { HttpGetMethod, HttpPostMethod, HTTP_METHODS, SearchParams, Payload } from './types';
import { handleRequestMethod, buildPath } from './utils';

const __get =
  (authorization?: string) =>
  <R, S extends SearchParams, P extends FormData = never>(
    path: TemplateStringsArray | string,
    ...args: (string | number)[]
  ) =>
  (config: HttpGetMethod<S>): Promise<R> =>
    handleRequestMethod<R, S, P>({
      method: HTTP_METHODS.GET,
      path: buildPath(path, args),
      ...{ Authorization: authorization },
      ...config,
    });

const __put =
  (authorization?: string) =>
  <R, S extends SearchParams, P extends Payload>(path: TemplateStringsArray | string, ...args: (string | number)[]) =>
  (config: HttpPostMethod<S, P>): Promise<R> =>
    handleRequestMethod<R, S, P>({
      method: HTTP_METHODS.PUT,
      path: buildPath(path, args),
      ...{ Authorization: authorization },
      ...config,
    });

const __post =
  (authorization: string) =>
  <R, S extends SearchParams, P extends Payload>(path: TemplateStringsArray | string, ...args: (string | number)[]) =>
  (config: HttpPostMethod<S, P>): Promise<R> =>
    handleRequestMethod<R, S, P>({
      method: HTTP_METHODS.POST,
      path: buildPath(path, args),
      ...{ Authorization: authorization },
      ...config,
    });

const __delete =
  (authorization: string) =>
  <R, S extends SearchParams, P extends FormData = never>(
    path: TemplateStringsArray | string,
    ...args: (string | number)[]
  ) =>
  (config: HttpGetMethod<S>): Promise<R> =>
    handleRequestMethod<R, S, P>({
      method: HTTP_METHODS.DELETE,
      path: buildPath(path, args),
      ...{ Authorization: authorization },
      ...config,
    });

const http = {
  get: __get,
  post: __post,
  delete: __delete,
  put: __put,
} as const;

export { http };
