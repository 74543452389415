import React, { FunctionComponent } from 'react';
import { SessionVenue } from 'models';
import { Wrapper, Sidebar } from './MultiVenueSidebar.theme';
import { VenueButton } from './partials';

export type MultiVenueSidebarProps = {
  venues?: SessionVenue[] | null;
  isMultiVenue: boolean;
  isOpen: boolean;
  isDisabled: boolean;
  isHidden: boolean;
  dispatchToggleMultiVenueSidebar: (params: boolean) => void;
};

const MultiVenueSidebar: FunctionComponent<MultiVenueSidebarProps> = ({
  venues,
  isMultiVenue = false,
  dispatchToggleMultiVenueSidebar = (): void => {},
  isOpen,
  isDisabled,
  isHidden = false,
}) => {
  if (isHidden) {
    return null;
  }

  const handleClose = (e: React.TouchEvent<HTMLDivElement>): void => {
    if (e.target !== e.currentTarget) return;
    dispatchToggleMultiVenueSidebar(false);
  };

  if (!isMultiVenue) {
    return null;
  }

  return (
    <Wrapper isMobileSidebarOpen={isOpen} onClick={handleClose} data-testid="multi-venue-sidebar-background">
      <Sidebar isMobileSidebarOpen={isOpen} data-testid="multi-venue-sidebar">
        {venues?.map((venue, index) => (
          <VenueButton
            venueId={venue.id}
            key={`venue-button-${venue.id}`}
            isDisabled={isDisabled}
            venueFullName={venue.name}
            venueShortName={venue.short_code || `#${index + 1}`}
            imageSrc={venue.logo_image_url}
          />
        ))}
      </Sidebar>
    </Wrapper>
  );
};

export default MultiVenueSidebar;
