import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { styled, css } from 'styles';
import { withTheme } from '../Context';

/**
 * Detect touch capabilities, if device is touch, we don't need JS to scroll
 */
let DEVICE_HAS_TOUCH_CAPABILITIES = false;

if (window) {
  window.addEventListener(
    'touchstart',
    function onFirstTouch() {
      DEVICE_HAS_TOUCH_CAPABILITIES = true;
      window.removeEventListener('touchstart', onFirstTouch, false);
    },
    false
  );
}

const StyledSwipeable = withTheme(
  styled('div')(
    ({ theme: { sizes }, padding, paddingBottom, paddingTop, paddingLeft, paddingRight, rounded, width }) => css`
      white-space: nowrap;
      overflow-x: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        display: none;
      }

      ${rounded && { borderRadius: sizes.borderRadiusMedium }};
      ${padding && { padding: padding * sizes.padding }};
      ${paddingBottom && { paddingBottom: paddingBottom * sizes.padding }};
      ${paddingTop && { paddingTop: paddingTop * sizes.padding }};
      ${paddingLeft && { paddingLeft: paddingLeft * sizes.padding }};
      ${paddingRight && { paddingRight: paddingRight * sizes.padding }};
      ${width && { width: width }};
    `
  )
);

export default class Swipeable extends Component {
  constructor(props) {
    super(props);

    this.myRef = createRef();
  }

  componentWillUnmount() {
    if (this.props.snapStep) {
      this.myRef.current.removeEventListener('scroll', this.scroll, 0);
      clearTimeout(this.snapTimeout);
      clearInterval(this.snapInterval);
    }

    if (DEVICE_HAS_TOUCH_CAPABILITIES) return;

    this.myRef.current.removeEventListener('mousedown', this.md, 0);
    window.removeEventListener('mouseup', this.mu, 0);
    window.removeEventListener('mousemove', this.mm, 0);
  }

  componentDidMount() {
    const _this = this;
    let pushed;
    let expectedValue = null;

    /**
     * Timeout and Interval triggers for SNAP functionality
     */
    const snapInterval = () => {
      if (pushed || (expectedValue && this.myRef.current.scrollLeft !== expectedValue)) {
        return clearInterval(_this.snapInterval);
      }

      const delta = _this.targetScrollLeft - this.myRef.current.scrollLeft;
      if (delta === 0) {
        return clearInterval(_this.snapInterval);
      }

      const delta_sign = _this.targetScrollLeft > this.myRef.current.scrollLeft ? 1 : -1;
      expectedValue = _this.targetScrollLeft - delta_sign * Math.floor(Math.abs(delta) * 0.9);
      this.myRef.current.scrollLeft = expectedValue;

      if (this.myRef.current.scrollLeft === _this.targetScrollLeft) {
        clearInterval(_this.snapInterval);
      }
    };

    const snapTimeout = () => {
      _this.targetScrollLeft = Math.round(this.myRef.current.scrollLeft / this.props.snapStep) * this.props.snapStep;
      clearInterval(_this.snapInterval);
      expectedValue = null;
      _this.snapInterval = setInterval(snapInterval, 16);
    };

    if (this.props.snapStep) {
      this.myRef.current.addEventListener(
        'scroll',
        (_this.scroll = function (e) {
          clearTimeout(_this.snapTimeout);
          if (!pushed) {
            _this.snapTimeout = setTimeout(snapTimeout, 100);
          }
        })
      );
    }
    // END SNAP

    if (DEVICE_HAS_TOUCH_CAPABILITIES) return;

    if (this.props.scrollLeft) {
      this.myRef.current.scrollLeft = this.props.scrollLeft;
    }

    let newScrollX;
    let newScrollY;
    let lastClientX;
    let lastClientY;
    let scroller;
    const el = _this.myRef.current;

    el.addEventListener(
      'mousedown',
      (_this.md = function (e) {
        pushed = 1;
        lastClientX = e.clientX;
        lastClientY = e.clientY;

        e.preventDefault();
      }),
      0
    );

    window.addEventListener(
      'mouseup',
      (_this.mu = function () {
        if (pushed && _this.props.snapStep) {
          clearTimeout(_this.snapTimeout);
          _this.snapTimeout = setTimeout(snapTimeout, 100);
        }
        pushed = 0;
      }),
      0
    );

    window.addEventListener(
      'mousemove',
      (_this.mm = function (e) {
        if (pushed) {
          (scroller = el.scroller || el).scrollLeft -= newScrollX = -lastClientX + (lastClientX = e.clientX);
          scroller.scrollTop -= newScrollY = -lastClientY + (lastClientY = e.clientY);
          if (el === document.body) {
            (scroller = document.documentElement).scrollLeft -= newScrollX;
            scroller.scrollTop -= newScrollY;
          }
        }
      }),
      0
    );
  }

  render() {
    return <StyledSwipeable className="Swipeable" ref={this.myRef} {...this.props} />;
  }
}

Swipeable.displayName = 'Swipeable';

Swipeable.propTypes = {
  scrollLeft: PropTypes.number,
  snapStep: PropTypes.number,
};
