import moment from 'moment';

export function shouldShowBanner(bannerDismissedAt: number | null): boolean {
  const nowDate = moment();
  const todayMorning = nowDate.set({ hour: 8, minute: 0, second: 0 });

  // check if current time is after today 8AM and last bannerDismissedAt is before today 8AM
  if (
    !bannerDismissedAt ||
    (moment(nowDate).isAfter(todayMorning) && moment(bannerDismissedAt).isBefore(todayMorning))
  ) {
    return true;
  }

  return false;
}
