import {
  appointmentsRequestAction,
  appointmentEditRequestAction,
  appointmentEditResponseAction,
  appointmentDeleteResponseAction,
  createLocalAppointmentAction,
  createAppointmentAction,
  editLocalAppointmentAction,
  deleteLocalAppointmentAction,
  startLocalAppointmentEditAction,
  leaveLocalAppointmentEditAction,
  appointmentBulkRepeatRequestAction,
  appointmentBulkRepeatResponseAction,
} from './appointments.actions';

import { apiGet, apiPost, apiDataSet } from './data_providers/api';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { getLocalAppointmentsById } from 'selectors/getLocalAppointments';
import moment from 'moment';
import { getStaffMemberTreatmentsById, getStaffMemberTreatmentsList } from 'selectors/getStaffMemberTreatments';
import { getVenueTreatmentsById } from 'selectors/getVenueTreatments';
import { getAgendaActiveCustomer } from 'selectors/getAgendaGrid';
import { getAppointmentBookData } from './appointments.utils';

export const fetchAppointment = (appointment_id) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return reject({ code: 400 });
    }

    // http://docs.uala.it/api/v1/appointments
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/appointments/${appointment_id}.json`,
        data: {
          with_customer_favorite_venue_treatments: true,
          with_customer_favorite_treatment_categories: true,
          with_look: true,
          with_marketplace_details: true,
        },
        handleAllResponses: true,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          if (response.data.appointment) {
            /**
             * Override model already in store
             * we can do this because we are sure this response contains all the attributes of the appointment
             */
            dispatch(
              apiDataSet({
                object_type: 'internal_appointment_details',
                object: response.data.appointment,
              })
            );
          }

          if (response.data.customer) {
            /**
             * Override model already in store
             * we can do this because we are sure this response contains all the attributes of the customer
             */
            dispatch(
              apiDataSet({
                object_type: 'internal_customer_details',
                object: response.data.customer,
              })
            );
          }

          resolve({ appointment: response.data.appointment, customer: response.data.customer });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

export const fetchAppointments =
  ({ from_time, to_time } = {}) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth) {
        return reject({ code: 400 });
      }

      dispatch(appointmentsRequestAction({ venue_id, auth }));

      // http://docs.uala.it/api/v1/appointments
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/appointments.json`,
          data: {
            from_time,
            to_time,
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              // dispatch(appointmentsResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }

            // dispatch(appointmentsResponseAction({ venue_id, appointments: response.data.appointments, response }));
            resolve({ appointments: response.data.appointments });
          },
          onError(error) {
            // dispatch(appointmentsResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const appointmentEdit = (appointment) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());
    if (!venue_id || !auth) {
      return reject({ code: 400 });
    }

    dispatch(appointmentEditRequestAction({ venue_id, auth, appointment }));

    /**
     * Extract "state" from appointments and remove it from attributes to pass to backend
     */
    let { state, cancellation_protection, ...attributesToUpdate } = appointment;
    let bookMultiBook = '';

    if (state === 'requested') {
      /**
       * If state is "requested", if you pass customer_id and staff_member_treatment_id call /book.json
       */

      if (appointment.customer_id && appointment.staff_member_treatment_id) {
        bookMultiBook = '/book';

        const staffMemberTreatmentsList = getStaffMemberTreatmentsList(getState());
        const staffMemberTreatmentsById = getStaffMemberTreatmentsById(getState());
        const venueTreatmentsById = getVenueTreatmentsById(getState());

        attributesToUpdate = getAppointmentBookData({
          requestedAppointment: attributesToUpdate,
          staffMemberTreatmentsList,
          staffMemberTreatmentsById,
          venueTreatmentsById,
        });

        if (attributesToUpdate.additional_appointments && attributesToUpdate.additional_appointments.length) {
          bookMultiBook = '/multi_book';
        }

        if (attributesToUpdate.children_appointments && attributesToUpdate.children_appointments.length) {
          bookMultiBook = '/multi_book';
        }
      }
    }

    // http://docs.uala.it/api/v1/appointments
    dispatch(
      apiPost({
        method: 'PUT',
        path: `/venues/${venue_id}/appointments/${appointment.id}${bookMultiBook}.json`,
        data: {
          cancellation_protection,
          appointment: {
            ...attributesToUpdate,
          },
          next: true, // TODO: next parameter enable the next version of the API, we should remove when all the clients are updated to the latest version
        },
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            dispatch(appointmentEditResponseAction({ error: response.info && response.info[0] }));
            return reject({ error: response.info && response.info[0] });
          }

          dispatch(appointmentEditResponseAction({ venue_id, response }));
          const isDraft = []
            .concat(response.data.appointment)
            .concat(response.data.appointments || [])
            .every((appo) => appo && appo.state === 'requested');
          resolve({ appointment: response.data.appointment, appointments: response.data.appointments, isDraft });
        },
        onError(error) {
          dispatch(appointmentEditResponseAction({ error }));
          reject({ error });
        },
      })
    );
  });

/**
 *
 * @param {object} param0
 * @param {number} param0.id
 * @param {string} [param0.state]
 * @param {boolean} [param0.skip_check_on_hard_deletion]
 * @returns
 */
export const deleteAppointment =
  ({ id, state = 'deleted', skip_check_on_hard_deletion = false }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id || !id) {
        return reject({ code: 400 });
      }

      let method = 'DELETE';
      let path = `/venues/${venue_id}/appointments/${id}.json`;
      const data = {};

      switch (state) {
        default:
        case 'deleted':
          /**
           * First of all, if we are deleting an appointment we have to be sure that is `requested`
           */
          if (!skip_check_on_hard_deletion) {
            dispatch(
              apiGet({
                path: path,
                onResponse(response) {
                  if (!response.success) {
                    return reject({ error: response.info && response.info[0] });
                  }

                  if (
                    !response ||
                    !response.data ||
                    !response.data.appointment ||
                    response.data.appointment.state !== 'requested'
                  ) {
                    return reject({ code: 401 });
                  }
                  /**
                   * Re-enter this function with the flag `skip_check_on_hard_deletion` true
                   */
                  dispatch(deleteAppointment({ id, state, skip_check_on_hard_deletion: true }))
                    .then(resolve)
                    .catch(reject);
                },
              })
            );
            /**
             * Don't go over
             */
            return;
          }

          method = 'DELETE';
          path = `/venues/${venue_id}/appointments/${id}.json`;
          break;
        case 'discarded':
          method = 'PUT';
          path = `/venues/${venue_id}/appointments/${id}/discard.json`;
          break;
        case 'canceled':
          method = 'PUT';
          path = `/venues/${venue_id}/appointments/${id}/cancel.json`;
          break;
        case 'canceled_all':
          method = 'PUT';
          path = `/venues/${venue_id}/appointments/${id}/cancel_all.json`;
          break;
        case 'missed':
          method = 'PUT';
          path = `/venues/${venue_id}/appointments/${id}/miss.json?next=true`; // TODO: next parameter enable the next version of the API, we should remove when all the clients are updated to the latest version
          break;
      }

      // http://docs.uala.it/api/v1/appointments
      dispatch(
        apiPost({
          method,
          path,
          data,
          onResponse(response) {
            if (!response.success) {
              dispatch(appointmentDeleteResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(
              appointmentDeleteResponseAction({
                venue_id,
                response,
                id,
                appointment: response.data.appointment,
              })
            );
            resolve({ response });
          },
          onError(error) {
            dispatch(appointmentDeleteResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const deleteCustomerAppointments =
  ({ id, date, state = 'deleted', cancel_charge_requested = false, charge_amount_cents = null }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !id) {
        return reject({ code: 400 });
      }

      let method = 'GET';
      let path = `/venues/${venue_id}/customers/${id}.json`;
      let data = {};

      switch (state) {
        default:
          method = 'GET';
          path = `/venues/${venue_id}/customers/${id}.json`;
          break;
        case 'canceled':
          method = 'PUT';
          path = `/venues/${venue_id}/customers/${id}/cancel.json`;
          data = { date };
          break;
        case 'missed':
          method = 'PUT';
          path = `/venues/${venue_id}/customers/${id}/miss.json?next=true`; // TODO: next parameter enable the next version of the API, we should remove when all the clients are updated to the latest version
          data = { date, cancel_charge_requested, charge_amount_cents };
          break;
        case 'archived':
          method = 'PUT';
          path = `/venues/${venue_id}/customers/${id}/archive.json`;
          data = { date };
          break;
      }

      // http://docs.uala.it/api/v1/appointments
      dispatch(
        apiPost({
          method,
          path,
          data,
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(appointmentDeleteResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(
              appointmentDeleteResponseAction({
                venue_id,
                response,
                id,
                appointments: response.data.appointments,
              })
            );
            resolve({ response });
          },
          onError(error) {
            dispatch(appointmentDeleteResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const createAppointment =
  ({ customer_id, staff_member_id, time }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      if (!venue_id || !time || !staff_member_id) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/appointments
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/appointments.json`,
          data: {
            appointment: {
              customer_id,
              staff_member_id,
              time: time.format(),
            },
          },
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }
            resolve({ appointment: response.data.appointment });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const createLocalAppointment =
  ({ id, customer_id, staff_member_id, time }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !time || !staff_member_id) {
        return reject({ code: 400 });
      }

      if (!id) {
        id = 'local-appointment-' + new Date().getTime();
      }

      dispatch(createLocalAppointmentAction({ venue_id, id, customer_id, staff_member_id, time }));

      // http://docs.uala.it/api/v1/appointments
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/appointments.json`,
          data: {
            appointment: {
              /**
               * don't send customer_id on create new appointment
               * useless at the moment.
               */
              // customer_id,
              staff_member_id,
              time: time.format(),
            },
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(createAppointmentAction({ venue_id, appointment: response.data.appointment, id }));
            resolve({ venue_id, appointment: response.data.appointment });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

export const editLocalAppointment =
  ({ appointment, changes, updateChildren = false }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      /**
       * Date check
       * Cannot edit if in the past
       */
      if (appointment.time && moment(appointment.time).isBefore(moment().startOf('day'))) {
        return resolve();
      }

      const venue_id = getCurrentVenueId(getState());
      const { __isLocal, id, remote } = appointment;

      if (!__isLocal) {
        /**
         * if appointment is NOT local, update on backend
         */
        if (updateChildren) {
          const { children_appointments } = appointment;
          const promises = [
            dispatch(appointmentEdit({ id, state: appointment.state, time: appointment.time, ...changes })),
            ...children_appointments.map((child) =>
              dispatch(appointmentEdit({ id: child.id, customer_id: child.customer_id }))
            ),
          ];
          Promise.all(promises)
            .then((res) => {
              resolve(res);
            })
            .catch(({ error }) => {
              reject({ error });
            });
        } else {
          dispatch(appointmentEdit({ id, state: appointment.state, time: appointment.time, ...changes }))
            .then(({ appointment, appointments, isDraft }) => {
              resolve({ appointment, appointments, isDraft });
            })
            .catch(({ error }) => {
              reject({ error });
            });
        }
        return;
      }

      /**
       * if customer_id is changed, pass changes.customer_id
       * if customer_id is local, pass the local version,
       * if customer_id is remote, passe the remote version
       */
      const customer_id = changes.customer_id || appointment.customer_id || null;

      /**
       * if appointment is local, dispatch action
       */
      dispatch(editLocalAppointmentAction({ venue_id, id, customer_id, remote, changes }));

      /**
       * if local appointment has a reference to remote appointment, update it
       */
      if (!remote) {
        return resolve();
      }

      /**
       * merge with appointment data if local
       */
      const { staff_member_id = null, staff_member_treatment_id = null } = remote;

      dispatch(
        appointmentEdit({
          id: remote.id,
          state: remote.state,
          time: remote.time,
          customer_id,
          staff_member_id,
          staff_member_treatment_id,
          ...changes,
        })
      )
        .then(({ appointment, appointments, isDraft }) => {
          /**
           * If appointment is local and has a connected remote appointment,
           * when the response is a booked appointment, we can delete the local version
           */
          if (!isDraft) {
            dispatch(deleteLocalAppointmentAction({ venue_id, id }));
          }

          resolve({ appointment, appointments, isDraft });
        })
        .catch(({ error }) => {
          reject({ error });
        });
    });

export const deleteLocalAppointment =
  ({ id }) =>
  (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const appointmentsById = getLocalAppointmentsById(getState());
    const appointment = appointmentsById[id];

    if (appointment && appointment.remote_id) {
      dispatch(deleteAppointment({ id: appointment.remote_id }));
    }

    dispatch(deleteLocalAppointmentAction({ venue_id, id }));
  };

const deleteLocalAppointmentTimeouts = {};

export const startLocalAppointmentEdit =
  ({ id, customer_id }) =>
  (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    if (!customer_id) {
      const activeCustomer = getAgendaActiveCustomer(getState());
      customer_id = activeCustomer ? activeCustomer.id : null;
    }
    dispatch(startLocalAppointmentEditAction({ venue_id, id, customer_id }));
    clearTimeout(deleteLocalAppointmentTimeouts[id]);
  };

export const leaveLocalAppointmentEdit =
  ({ id, customer_id }) =>
  (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const pendingTimeout = 15000;
    dispatch(
      leaveLocalAppointmentEditAction({
        venue_id,
        id,
        customer_id,
        pendingTimeout,
      })
    );
    if (!customer_id) {
      dispatch(deleteLocalAppointment({ id }));
    } else {
      deleteLocalAppointmentTimeouts[id] = setTimeout(() => {
        dispatch(deleteLocalAppointment({ id }));
      }, pendingTimeout);
    }
  };

export const repeatAppointment =
  ({ customer_id, appointment_ids, frequency, from_date, to_date }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !customer_id || !appointment_ids || !frequency || !from_date || !to_date) {
        return reject({ code: 400 });
      }

      dispatch(appointmentBulkRepeatRequestAction({ venue_id, auth, appointment_ids }));

      // http://docs.uala.it/api/v1/appointments
      dispatch(
        apiPost({
          method: 'POST',
          path: `/venues/${venue_id}/appointments/bulk_repeat.json`,
          data: {
            customer_id,
            appointment_ids,
            frequency,
            from_date,
            to_date,
          },
          auth: auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(appointmentBulkRepeatResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }
            dispatch(
              appointmentBulkRepeatResponseAction({
                venue_id,
                response,
                appointment_ids,
                appointments: response.data.appointments,
              })
            );
            resolve({ appointments: response.data.appointments });
          },
          onError(error) {
            dispatch(appointmentBulkRepeatResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

export const fetchAppointmentsDailyRecap = (date) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return reject({ code: 400 });
    }

    // http://docs.uala.it/api/v1/appointments
    dispatch(
      apiGet({
        auth: auth,
        ...(date
          ? {
              data: {
                ...(date ? { date } : null),
              },
            }
          : null),
        path: `/venues/${venue_id}/appointments/daily_recap.json`,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve({ appointments_daily_recap: response.data.appointments_daily_recap });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });

/**
 *
 * @param {*} param0
 * @param {Number} param0.appointment_id - The ID of the appointment
 * @param {Object} param0.customer_informed_consent - The Informed Consent object
 * @param {Boolean} param0.customer_informed_consent.send_as_email - Flag to send the consent via e-mail
 * @param {Boolean} param0.customer_informed_consent.send_as_sms - Flag to send the consent via sms
 */
export const appointmentSendConsent =
  ({ appointment_id, customer_informed_consent }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/appointments
      dispatch(
        apiPost({
          method: 'PUT',
          auth: auth,
          data: {
            customer_informed_consent,
          },
          path: `/venues/${venue_id}/appointments/${appointment_id}/send_consent.json`,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            resolve({ customer_informed_consent: response.data.customer_informed_consent });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });
