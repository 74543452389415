import { createSelector } from 'reselect';
import { getCurrentVenueId } from './getCurrentVenue';

const getLocalAppointmentsByVenue = (state) => state.localAppointmentsByVenue;

const emptylocalAppointmentList = [];

/** @typedef {import('models').Appointment} Appointment **/
/** @typedef {import('reducers/types').State} State **/
/** @type {(state: State) => Appointment[]} **/
export const getLocalAppointmentsList = createSelector(
  [getCurrentVenueId, getLocalAppointmentsByVenue],
  (venue_id, localAppointmentsByVenue) => {
    const localAppointments =
      (localAppointmentsByVenue[venue_id] && localAppointmentsByVenue[venue_id].items) || emptylocalAppointmentList;

    if (localAppointments === emptylocalAppointmentList) {
      return localAppointments;
    }

    return localAppointments.map((localAppointment) => ({ ...localAppointment }));
  }
);

/** @typedef {import('models').Appointment} Appointment **/
/** @typedef {import('reducers/types').State} State **/
/** @type {(state: State) => {[key: number]: Appointment}} **/
export const getLocalAppointmentsById = createSelector([getLocalAppointmentsList], (localAppointmentsList) => {
  return localAppointmentsList.reduce((appointmentsById, appointment) => {
    if (appointment.id) {
      appointmentsById[appointment.id] = appointment;
    }
    return appointmentsById;
  }, {});
});
