import React, { useState, useEffect } from 'react';
import ToastNotification from 'containers/modals/warehouse/Toast';
import { TOAST_TYPE } from '@uala/ui-treatwell-pro';
import { FormattedMessage } from 'react-intl';

export const NetworkStatusProvider: React.FC = ({ children }) => {
  const [notification, setNotification] = useState<boolean | null>(null);

  const [onlineStatus, setOnlineStatus] = useState<boolean | null>(
    typeof navigator !== 'undefined' ? navigator.onLine : null
  );

  const [prevOnlineStatus, setPrevOnlineStatus] = useState<boolean | null>(
    typeof navigator !== 'undefined' ? navigator.onLine : null
  );

  const setOnline = (): void => {
    setOnlineStatus(true);
    setPrevOnlineStatus((prev) => !prev);
  };

  const setOffline = (): void => {
    setOnlineStatus(false);
    setPrevOnlineStatus((prev) => !prev);
  };

  useEffect(() => {
    window.addEventListener('offline', setOffline);
    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('offline', setOffline);
      window.removeEventListener('online', setOnline);
    };
  }, []);

  useEffect(() => {
    if (prevOnlineStatus !== null && onlineStatus !== prevOnlineStatus) setNotification(true);
  }, [onlineStatus, prevOnlineStatus]);

  return (
    <>
      {notification && (
        <ToastNotification
          config={{
            type: onlineStatus ? TOAST_TYPE.SUCCESS : TOAST_TYPE.WARNING,
            fixed: !onlineStatus,
            content: onlineStatus ? <FormattedMessage id="online" /> : <FormattedMessage id="offline" />,
            onClose: function (): void {
              setNotification(false);
            },
          }}
        />
      )}
      {children}
    </>
  );
};
