/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';
import { StaffMemberTreatmentLookupResponse, StaffMemberTreatmentLookupParams } from 'API/types';

export const LOOKUP_DELETED =
  ({ variables: { id } }: V<StaffMemberTreatmentLookupParams>) =>
  (): Promise<StaffMemberTreatmentLookupResponse> =>
    Http()
      .get<StaffMemberTreatmentLookupResponse, { deleted: boolean }>(
        ENDPOINT.STAFF_MEMBERS_TREATMENTS._LOOKUP_DELETED(id)
      )({
        searchParams: {
          deleted: true,
        },
      })
      .then(maybeResponseToJSON);
