import { PropsWithChildren, ReactElement, SyntheticEvent } from 'react';
import { UnknownFunction } from 'utils/utilityTypes';
import { ModalOverlay, ModalWrapper } from './theme';

const stopPropagation = (e: SyntheticEvent): void => e.stopPropagation();

type Props = PropsWithChildren<{
  size?: 'small' | 'large' | number;
  fullHeight?: boolean;
  isFullscreen?: boolean;
  centered?: boolean;
  maxHeight?: number;
  height?: string;
  defaultSpacing?: number;
  onClose?: UnknownFunction;
  'data-testid'?: string;
}>;

let modalIx = 0;
const Modal = ({
  children,
  size,
  fullHeight,
  isFullscreen,
  centered,
  maxHeight,
  onClose,
  height,
  defaultSpacing,
  'data-testid': testId,
}: Props): ReactElement => (
  <ModalOverlay className="Modal" data-testid={testId ?? 'modal'} data-modal-id={modalIx++} onClick={onClose}>
    <ModalWrapper
      onClick={stopPropagation} // avoid to trigger the on close when interacting with modal layers
      className="Modal__wrapper"
      size={size}
      fullHeight={fullHeight}
      isFullscreen={isFullscreen}
      centered={centered}
      maxHeight={maxHeight}
      height={height}
      data-testid="modal-container"
      aria-modal="true"
      role="dialog"
      defaultSpacing={defaultSpacing}
    >
      {children}
    </ModalWrapper>
  </ModalOverlay>
);

export default Modal;
