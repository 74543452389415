import { ISOTime } from 'utils/dates';

export enum MarketingPromotionTypes {
  GENERIC_DISCOUNT = 'generic_discount',
  DISCOUNT_CARD = 'discount_card',
  BUNDLE_DISCOUNT = 'bundle_discount',
  LOYALTY_PROGRAM = 'loyalty_program',
  VOUCHER = 'voucher',
  SUBSCRIPTION_BUNDLE = 'subscription_bundle',
  PREPAID_CREDIT = 'prepaid_credit',
}

export interface MarketingPromotionResource {
  id: number;
  association_type: string | null;
  is_service_type: boolean;
  table_name: string | null;
  record_id: number | null;

  discount_type: string | null;
  discount_percent_amount?: number;
  points_count?: number | null;
  original_price_cents?: number | null;
  discounted_price?: string | null;
  discounted_price_cents?: number | null;
  redemptions_count_possible: number | null;
  redemptions_count_potential: number | null;
  redemptions_count_used: number | null;
  resources_group_token?: string | null;
  created_at: string | null;
  updated_at: string | null;
  position?: number | null;
  text?: string;

  discount_absolute_amount_cents?: number | null;
  frequency?: string | null;
  staff_member_id?: string | null;
  valid_in_time_table?: string | null;
}

export interface MarketingPromotionType {
  id: number;
}

export interface MarketingPromotionTimeTable {
  day: number;
  ranges: Array<{
    start_time: string;
    end_time: string;
  }>;
}

export interface MarketingPromotion {
  id: number;
  automatic?: boolean;
  code?: string | null;
  marketing_promotion_type: MarketingPromotionTypes;
  name: string;
  description?: string | null;
  country_id: number;
  venue_id: number;
  customer_id?: number | null;
  parent_customer_id?: number | null;
  resources_group_token?: string;
  // treatments/items/resources included in the promotion
  included_resources: MarketingPromotionResource[];
  // treatments/items/resources excluded in the promotion
  excluded_resources: MarketingPromotionResource[];
  required_resources: MarketingPromotionResource[];
  included_resources_attributes?: unknown;

  calculated_valid_from: string;
  calculated_valid_to?: string | null;

  // time range when the promotion can be used
  valid_in_time_table: MarketingPromotionTimeTable[];
  valid_from?: string | null;
  valid_to?: string | null;
  valid_for?: number | null;
  valid_for_unit?: string | null;

  serial_number?: string | number | null;
  checkout_ref_invoice?: number | null | false;
  checkout_ref_counter?: number | null;
  seller_staff_member_id?: number | null;
  unique_promo_for_customer: boolean;
  enabled: boolean;
  only_new_customers: boolean;
  paying_online_only: boolean;
  billable: boolean;
  selling_price_cents: number | null;

  redeemed_at?: string | null;
  redeemable_from?: string | null;
  redeemable_to?: string | null;

  redemptions_count_possible?: number | null;
  redemptions_count_potential?: number | null;
  redemptions_count_used?: number | null;
  redemption_amount_possible_cents?: number | null;
  redemption_amount_used_cents?: number | null;

  global_redemptions_count_possible?: number | null;
  global_redemptions_count_potential?: number | null;
  global_redemptions_count_used?: number | null;
  global_redemption_amount_possible_cents?: number | null;
  global_redemption_amount_used_cents?: number | null;

  points_earning_from?: null;
  points_earning_to?: null;
  points_earning_for?: null;
  points_earning_for_unit?: null;
  points_valid_from?: null;
  points_valid_to?: null;
  points_valid_for?: null;
  points_valid_for_unit?: null;
  loyalty_program_enabled?: true;

  created_at: ISOTime['DateTime'];
  updated_at: ISOTime['DateTime'];
  deleted_at?: ISOTime['DateTime'] | null;

  calculated_points_valid_from?: ISOTime['DateTime'] | null;
  calculated_points_valid_to?: ISOTime['DateTime'] | null;
  calculated_points_earning_from?: ISOTime['DateTime'] | null;
  calculated_points_earning_to?: ISOTime['DateTime'] | null;

  read_only: boolean;
  purchasable_from_web: boolean;
  has_discount: boolean;
  parent_marketing_promotion?: number | null;
  marketing_promotion_prizes: [];
  venue_treatment_group_related_for_deletion: number | null;

  /**
   * List of associated customers
   */
  associated_customers?: AssociatedCustomer[];
  draft?: boolean;
  archived_at?: string | null;
  /**
   * Indicates wether the vat should be applied on usage or not.
   * If false, the vat was already applied on the original price when the promotion was issued.
   */
  apply_vat_on_usage: boolean;
  package_option_id: Nullable<number>;
}

export interface AssociatedCustomer extends MarketingPromotion {
  parent_customer_name: string;
  customer_name: string;
  customer_marketing_promotion_id: number;
  gifted: boolean;
  points_availability: number | null;
  archived_at?: string;
}
