import * as React from 'react';
import { TooltipContext } from './TooltipContext';
import { TOOLTIP_TYPE } from 'ui-treatwell-pro/tooltip/TooltipTypes';
import { COLORS, useTheme } from 'styles';

type TooltipSetting = {
  readonly topPosition: number;
  readonly leftPosition: number;
  readonly tooltipContent: string;
  readonly color: string;
  readonly defaultPosition: string;
};
type ShowTooltip = ({ topPosition, leftPosition, tooltipContent, color, defaultPosition }: TooltipSetting) => void;

interface UseTooltipProps {
  readonly width?: string;
  readonly type?: string;
  readonly position?: string;
}
interface UseTooltip {
  readonly showTooltip: ShowTooltip;
  readonly hideTooltip: () => void;
  readonly isTooltipVisible: boolean;
  readonly top: number;
  readonly left: number;
  readonly content: string;
  readonly width?: string;
  readonly color: string;
  readonly defaultPosition: string;
}

export const getColor = (colors: typeof COLORS) => (tooltipType: string) => {
  switch (tooltipType) {
    case TOOLTIP_TYPE.SUCCESS:
      return colors.green;
    case TOOLTIP_TYPE.WARNING:
      return colors.yellow;
    case TOOLTIP_TYPE.DANGER:
      return colors.red;
    default:
      return colors['navy.700'];
  }
};

export function useTooltip({ width, type = TOOLTIP_TYPE.INFO }: UseTooltipProps): UseTooltip {
  const context = React.useContext(TooltipContext);
  if (!context) throw new Error('useTooltip must be used within a TooltipProvider');
  const { colors } = useTheme();

  const {
    isVisible,
    setupTooltip,
    showTooltip: show,
    top,
    left,
    content,
    color,
    hideTooltip,
    defaultPosition,
  } = context;

  const showTooltip = React.useCallback(
    ({ topPosition, leftPosition, tooltipContent, defaultPosition }: TooltipSetting): void => {
      setupTooltip({
        top: topPosition,
        left: leftPosition,
        content: tooltipContent,
        width,
        color: getColor(colors)(type),
        defaultPosition,
      });
      show();
    },
    [width, type]
  );

  return {
    showTooltip,
    hideTooltip,
    isTooltipVisible: isVisible,
    top,
    left,
    content,
    width,
    color: color as string,
    defaultPosition: defaultPosition as string,
  };
}
