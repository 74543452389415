import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import HelperInline from './HelperInline';
import HelperTooltip from './HelperTooltip';

const Helper = ({ tooltip = false, position = 'top', error = false, intl, text, dataTestId }) => {
  const helperMessage = intl ? <FormattedMessage id={text} /> : text;

  if (tooltip) {
    return (
      <HelperTooltip position={position} error={error} dataTestId={dataTestId}>
        {helperMessage}
      </HelperTooltip>
    );
  }

  return (
    <HelperInline error={error} dataTestId={dataTestId}>
      {helperMessage}
    </HelperInline>
  );
};

Helper.displayName = 'Helper';
Helper.propTypes = {
  tooltip: PropTypes.bool,
  intl: PropTypes.bool,
  text: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  error: PropTypes.bool,
};

Helper.defaultProps = {
  tooltip: false,
  intl: false,
  position: 'top',
  error: false,
};

export default Helper;
