import { styled, css } from 'styles';
import { withTheme } from '../Context';

// TODO: remove `underline` prop and change textDecoration into ${textDecoration && { textDecoration }};
export const createText = (as) =>
  withTheme(
    styled(as)(
      ({
        theme: { colors, sizes },
        size,
        bold,
        italic,
        wordBreak,
        whiteSpace,
        black,
        flex,
        uppercase,
        lowercase,
        capitalize,
        spacing,
        padding,
        block,
        color,
        center,
        right,
        truncate,
        onClick,
        tableCaption,
        verticalAlign,
        userSelect,
        lineHeight,
        textAlign,
        textDecoration = 'none',
        underline, // @deprecated - use textDecoration instead. This will be removed soon.
      }) => css`
        ${wordBreak && { wordBreak }}
        ${whiteSpace && { whiteSpace }}
        ${flex && (flex === true ? { flex: 1 } : { flex })};
        ${bold && `font-weight: 700;`};
        ${italic && `font-style: italic;`};
        ${black && `font-weight: 900;`};
        ${uppercase && `text-transform: uppercase;`};
        ${lowercase && `text-transform: lowercase;`};
        ${capitalize && `text-transform: capitalize;`};
        ${center && `text-align: center;`};
        ${right && `text-align: right;`};
        ${spacing && `letter-spacing: ${spacing}px;`};
        ${size && `font-size: ${size / 10}rem;`};
        ${block && `display:block;`};
        ${onClick && `cursor: pointer;`};
        ${lineHeight && `line-height: ${lineHeight}px;`};
        ${userSelect && { userSelect }};
        ${textAlign && { textAlign }};
        ${tableCaption &&
        css`
          span {
            display: table-caption;
          }
        `};
        ${color &&
        css`
          color: ${colors[color] || colors.text1};
          path {
            fill: ${colors[color] || colors.text1};
          }
        `};
        ${truncate &&
        `
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        `};

        box-sizing: border-box;
        margin: 0;
        padding: 0 ${padding ? padding * sizes.padding : 0}px;
        font-family: Lato;
        ${verticalAlign ? `vertical-align: ${verticalAlign};` : `vertical-align: middle;`};
        text-decoration: ${underline ? 'underline' : textDecoration};
      `
    )
  );

export const TextCurrency = withTheme(
  styled('span')(
    ({ theme: { colors }, currencyColor }) => css`
      margin-right: 0.18em;
      ${currencyColor && `color: ${colors[currencyColor]}`};
    `
  )
);

export const TextPrefix = styled('span')`
  margin-right: 0.18em;
`;

export const TextSuffix = styled('span')`
  margin-left: 0.18em;
`;

const defaultTexts = {
  span: createText('span'),
  div: createText('div'),
  p: createText('p'),
  a: createText('a'),
};

export default defaultTexts;
