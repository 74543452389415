/**
 * getStateWithRehydratedFlag
 * return an object with the "rehydrated" flag set to true
 * if the app is closed before receiving the RESPONSE of a call, the "isFetching" flag could be left set to true,
 * @param {Object} state
 */
import avoidDuplicatesByKey from './avoidDuplicatesByKey';
import overrideDuplicatesByKey from './overrideDuplicatesByKey';

export const getStateWithRehydratedFlag = (state) =>
  Object.keys(state).reduce((itemsById, k) => {
    itemsById[k] = {
      ...state[k],
      rehydrated: true,
    };
    return itemsById;
  }, {});

//
// These action/reducer utils are based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

export const actionShouldFetchData = (state, fetchTimeout = 30000) => {
  if (!state) {
    return true;
  } else if (state.isFetching && state.isFetching > new Date().getTime() - fetchTimeout) {
    return false;
  } else {
    return !state.updatedAt || state.didInvalidate;
  }
};

export const reducerValuesOnInit = () => ({
  isFetching: false,
  didInvalidate: false,
});

export const reducerValuesOnRequest = () => ({
  isFetching: new Date().getTime(),
  didInvalidate: false,
});

export const reducerValuesOnResponse = (data, options) => {
  const { avoidDuplicateIds, skipDuplicateIds, overrideDuplicateIds, onlyIds, skipUpdate } = options || {};

  if (onlyIds) {
    data = data.map((item) => ({
      id: item.id,
    }));
  }

  if (avoidDuplicateIds || skipDuplicateIds) {
    data = avoidDuplicatesByKey('id', data);
  }

  if (overrideDuplicateIds) {
    data = overrideDuplicatesByKey('id', data);
  }

  return {
    isFetching: false,
    didInvalidate: false,
    ...(skipUpdate
      ? null
      : {
          updatedAt: new Date().getTime(),
        }),
    items: data,
  };
};

/** @typedef {import('utils/dates').ISOTime} ISOTime */
/** @typedef {{updated_at: ISOTime['DateTime']}} UpdatableBackendEntity */
/** @type {(newer: UpdatableBackendEntity, older: UpdatableBackendEntity ) => boolean} */
export const isNewerThan = ({ newer, older }) =>
  new Date(newer.updated_at).getTime() > new Date(older.updated_at).getTime();
