import { NotificationIcon } from 'components/core';
import { StyledTriggerWrapper } from './ProductStash.theme';

const TRIGGER_CLASS_NAME = 'twpro-product-news';

const ProductStashTrigger = (): JSX.Element => {
  return (
    <StyledTriggerWrapper className={TRIGGER_CLASS_NAME}>
      <NotificationIcon className={TRIGGER_CLASS_NAME} iconType="bell" iconColor="blue" />
    </StyledTriggerWrapper>
  );
};

export default ProductStashTrigger;
