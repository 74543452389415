/* eslint-disable @typescript-eslint/no-redeclare */
import { ChangeEvent, InputHTMLAttributes, useState, forwardRef, FC } from 'react';
import { Box, Icon, IconList } from 'components/ui-treatwell-pro';
import * as S from './Input.theme';
import { Color } from '../theme';
import { ValueOf } from '../../utils/utilityTypes';

const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  PASSWORD: 'password',
  EMAIL: 'email',
} as const;

type InputIcon = { readonly type: IconList; readonly color?: Color };

export type InputProps = Pick<InputHTMLAttributes<HTMLInputElement>, 'width' | 'placeholder' | 'name'> & {
  readonly isReadOnly?: boolean;
  readonly isDisabled?: boolean;
  readonly isInvalid?: boolean;
  /**
   * @description add icon from IconList to the left side of the input
   */
  readonly leftIcon?: InputIcon;
  /**
   * @description add icon from IconList to the right side of the input
   */
  readonly rightIcon?: InputIcon;
  /**
   * @description show validation icons if rightIcon is not already provided
   */
  readonly showValidationIcon?: boolean;
  readonly label?: string;
  readonly errorMessage?: string;
  readonly onChange?: (value: { readonly value: string | number }) => void;
  readonly type?: ValueOf<typeof INPUT_TYPES>;
  readonly value?: string | number;
  readonly dataTestId?: string;
};

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      name,
      label,
      isReadOnly,
      isDisabled,
      isInvalid,
      value,
      placeholder,
      leftIcon,
      rightIcon,
      width,
      errorMessage,
      onChange,
      showValidationIcon = false,
      dataTestId = 'input',
      type = 'text',
    },
    forwardedRef
  ) => {
    const [inputValue, setValue] = useState<string | number>('');

    function handleChange(e: ChangeEvent<HTMLInputElement>): void {
      const val = e.target.value;
      const valAsNumber = e.target.valueAsNumber;
      const computedVal = isNaN(valAsNumber) ? val : valAsNumber;

      if (onChange) onChange({ value: computedVal });

      setValue(computedVal);
    }

    // input validation UI rules
    const validationIconType = isInvalid ? 'Cancel' : 'Check';
    const validationIconColor = isInvalid ? 'coral.600' : 'teal.600';
    const showValidationIcons = showValidationIcon && !(isReadOnly || isDisabled);

    return (
      <Box p="5px" display="flex" flexDirection="column" width="100%">
        <S.InputGroup inputWidth={width}>
          <InputIcon placement="left" type={leftIcon?.type} color={leftIcon?.color} show={Boolean(leftIcon)} />
          <InputIcon placement="right" type={rightIcon?.type} color={rightIcon?.color} show={Boolean(rightIcon)} />
          <InputIcon
            placement="right"
            type={validationIconType}
            color={validationIconColor}
            show={showValidationIcons && !rightIcon}
            size={16}
          />
          <S.Input
            name={name}
            ref={forwardedRef}
            type={type}
            value={value ?? inputValue}
            onChange={handleChange}
            readOnly={isReadOnly}
            disabled={isDisabled}
            aria-invalid={isInvalid}
            placeholder={placeholder}
            data-testid={dataTestId}
          />
          {label ? (
            <S.Label hasLeftIcon={Boolean(leftIcon?.type)} value={value || inputValue}>
              {label}
            </S.Label>
          ) : null}
        </S.InputGroup>
        {isInvalid && errorMessage ? <S.ErrorMessage data-testid="error-message">{errorMessage}</S.ErrorMessage> : null}
      </Box>
    );
  }
);

export interface InputIconProps {
  readonly placement?: 'right' | 'left';
  readonly type?: IconList;
  readonly color?: Color;
  readonly show?: boolean;
  readonly size?: number;
}

export const InputIcon: FC<InputIconProps> = ({ color, size = 16, type = '', show = true, placement = 'left' }) => {
  return show ? (
    <S.InputIcon placement={placement} className={`${placement}-icon`} data-testid="icon">
      <Icon name={type} color={color} size={size} />
    </S.InputIcon>
  ) : null;
};
