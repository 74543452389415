/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { VenueProductUnloadResponse, VenueProductUnloadQuery, VenueProductUnloadPayload } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const UNLOAD =
  ({
    variables: { product_id, quantity, kind, staff_member_id },
  }: V<VenueProductUnloadQuery & VenueProductUnloadPayload>) =>
  (): Promise<VenueProductUnloadResponse> =>
    Http()
      .put<VenueProductUnloadResponse, VenueProductUnloadQuery, VenueProductUnloadPayload>(
        ENDPOINT.VENUE_PRODUCTS._UNLOAD(product_id ?? 0)
      )({
        searchParams: { product_id },
        body: { quantity, kind, staff_member_id },
      })
      .then(maybeResponseToJSON);
