import { useState, useCallback, useMemo, useEffect } from 'react';
import { Section, Text, Icon, Select } from 'components/core';
import { discountOptions } from './utils';
import VenueTreatment from './PromoFlashEditResourceVenueTreatment';

const ResourceCategory = ({
  category,
  venueTreatmentsList,
  promoFlash,
  setLocalChanges,
  treatmentsFilter,
  onTreatmentsFilterChange,
}) => {
  const [touched, setTouched] = useState(false);

  const thisCategoryVenueTreatments = useMemo(() => {
    const query = (treatmentsFilter || '').toLowerCase();
    return venueTreatmentsList.filter(
      (vt) =>
        vt.label &&
        vt.label.category_id === category.id &&
        (!treatmentsFilter || vt?.computed_name?.toLowerCase().indexOf(query) >= 0)
    );
  }, [category.id, venueTreatmentsList, treatmentsFilter]);

  const thisCategoryByTreatmentId = useMemo(() => {
    return thisCategoryVenueTreatments.reduce((map, vt) => {
      map[vt.id] = vt.label && vt.label.category_id;
      return map;
    }, {});
  }, [thisCategoryVenueTreatments]);

  const thisCategoryResource = promoFlash.included_resources_attributes.filter(
    (i) => i.table_name === 'treatment_categories' && i.record_id === category.id
  )[0];

  /**
   * Category select is disabled if in included venue treatments, a value differs
   */
  const categoryValue = thisCategoryResource ? thisCategoryResource.discount_percent_amount : 0;

  const categoryChildren = promoFlash.included_resources_attributes.filter(
    (i) =>
      (i.table_name === 'treatments' || i.table_name === 'venue_treatments') && thisCategoryByTreatmentId[i.record_id]
  );
  const categoryDisabled =
    categoryChildren.filter((i, k, list) => i.discount_percent_amount !== list[0].discount_percent_amount).length > 0;

  const [open, setOpen] = useState(categoryChildren.length > 0);

  const onCategoryDiscountChange = useCallback(
    (discount_percent_amount) => {
      setTouched(discount_percent_amount > 0);
      setLocalChanges({
        included_resources_attributes: [
          /**
           * Remove from included resources the category if already exists and all the treatments with this category
           */
          ...promoFlash.included_resources_attributes.filter(
            (i) =>
              !(
                (i.table_name === 'treatment_categories' && i.record_id === category.id) ||
                ((i.table_name === 'treatments' || i.table_name === 'venue_treatments') &&
                  thisCategoryByTreatmentId[i.record_id])
              )
          ),
          /**
           * Manually add all the venue treatments to the included resources
           */
          ...thisCategoryVenueTreatments.map((vt) => ({
            table_name: 'venue_treatments',
            record_id: vt.id,
            discount_type: 'percent',
            discount_percent_amount,
          })),
          /**
           * and finally add explicit reference to treatment categories
           */
          {
            table_name: 'treatment_categories',
            record_id: category.id,
            discount_type: 'percent',
            discount_percent_amount,
          },
        ],
      });
    },
    [
      category,
      promoFlash.included_resources_attributes,
      setLocalChanges,
      thisCategoryByTreatmentId,
      thisCategoryVenueTreatments,
    ]
  );

  const handleSectionToggle = useCallback(() => setOpen(!open), [open]);

  // Treatments should be visible either on click or on filter
  const isCategoryOpenOrFiltered = open || treatmentsFilter;

  // Set touched to true from outside
  useEffect(() => {
    const discountedVenueTreatments = categoryChildren.filter((resource) => resource.discount_percent_amount > 0);

    setTouched(discountedVenueTreatments.length > 0);
  }, [categoryChildren]);

  useEffect(() => {
    onTreatmentsFilterChange(category.id, thisCategoryVenueTreatments.length);
  }, [thisCategoryVenueTreatments.length, category.id, onTreatmentsFilterChange]);

  if (thisCategoryVenueTreatments.length === 0) return null;

  return (
    <Section shape="card" background={touched ? 'lightBlue' : 'white'} marginTop={0.25}>
      <Section displayFlex alignItems="center" onClick={handleSectionToggle} padding={0.6}>
        <Text block flex={1} size={14} color="text1">
          {category.name}
        </Text>
        <Section width={100} onClick={(e) => e.stopPropagation()}>
          <Select value={categoryValue} disabled={categoryDisabled} onChange={onCategoryDiscountChange}>
            <Select.Option value={0}>-</Select.Option>
            {discountOptions.map((v) => (
              <Select.Option key={v} value={v / 100}>
                {v}%
              </Select.Option>
            ))}
          </Select>
        </Section>
        <Icon type={open ? 'angle-up' : 'angle-down'} color="blue" size={12} marginLeft marginRight />
      </Section>
      {isCategoryOpenOrFiltered &&
        thisCategoryVenueTreatments.map((vt) => (
          <VenueTreatment key={vt.id} promoFlash={promoFlash} venueTreatment={vt} setLocalChanges={setLocalChanges} />
        ))}
    </Section>
  );
};

export default ResourceCategory;
