import { VENUE_TRANSIENT_DATA_RESET, VENUE_TRANSIENT_DATA_SET } from 'actionTypes';

// transientData = {
//   5: [
//     {
//       "key": "cart-123456",
//       "value": "ABC12345",
//     }
//   }
// }

interface TransientDataAction<R> {
  type: string;
  venue_id: number;
  key: string;
  value: R;
}

export interface TransientDataState<R> {
  key: string;
  value: R;
}

interface TransientDataByVenueState<R> {
  [venue_id: number]: TransientDataState<R>[];
}

type TransientData<R = unknown> = (
  state: TransientDataState<R>[],
  action: TransientDataAction<R>
) => TransientDataState<R>[];

type TransientDataByVenue<R = unknown> = (
  state: TransientDataByVenueState<R>,
  action: TransientDataAction<R>
) => TransientDataByVenueState<R>;

export const transientData: TransientData = (state = [], action) => {
  switch (action.type) {
    case VENUE_TRANSIENT_DATA_SET:
      return [
        ...state.filter((data) => data.key !== action.key),
        {
          key: action.key,
          value: action.value,
        },
      ];
    case VENUE_TRANSIENT_DATA_RESET:
      return state.filter((data) => data.key !== action.key);
    default:
      return state;
  }
};

export const transientDataByVenue: TransientDataByVenue = (state = {}, action) => {
  switch (action.type) {
    case VENUE_TRANSIENT_DATA_SET:
    case VENUE_TRANSIENT_DATA_RESET:
      if (!action.venue_id || !action.key) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: transientData(state[action.venue_id], action),
      };

    default:
      return state;
  }
};
