import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

const defaultCost = {
  invoice: false,
  invoice_ref: '',
  invoice_date: new Date(),
  supplier_id: '',
  payment_method_id: '',
  execution_date: '',
};

const defaultLocalCostItem = {
  isLocal: true,
  name: '',
  product_id: '',
  product_category_id: '',
  service_good_id: '',
  service_good_category_id: '',
  amount_cents: 0,
  quantity: 1,
  vat_id: '',
};

const forceVenueCostItemsToExists = (venue_cost) => {
  if (venue_cost.venue_cost_items && venue_cost.venue_cost_items.length) {
    return venue_cost;
  }

  venue_cost.venue_cost_items = [].concat({
    id: 'local-costitem-' + new Date().getTime(),
    ...defaultLocalCostItem,
  });

  return venue_cost;
};

const checkRequestId = (venue_cost, lastRequestId) => {
  if (venue_cost && venue_cost.lastRequestId && (!lastRequestId || venue_cost.lastRequestId !== lastRequestId)) {
    return false;
  }

  return true;
};

const venueCosts = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case 'VENUE_COSTS_LIST_REQUEST':
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };
    case 'VENUE_COSTS_LIST_RESPONSE':
      if (!action.venue_costs) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.venue_costs).map(forceVenueCostItemsToExists)),
      };
    case 'VENUE_UPDATE_COST_REQUEST':
      if (!action.venue_cost) {
        return state;
      }

      return {
        ...state,
        isSavingCost: true,
      };

    case 'VENUE_UPDATE_COST_RESPONSE':
      if (!action.venue_cost) {
        return state;
      }

      // Controllo che non ci sia una requestId salvata nel modello,
      //  se sì allora deve essere la stessa requestId di questa risposta delle API
      const currentVenueCost = (state.items || []).find(({ id }) => id === action.venue_cost.id);
      if (!checkRequestId(currentVenueCost, action.lastRequestId)) {
        return state;
      }

      return {
        ...state,
        isSavingCost: false,
        ...reducerValuesOnResponse(
          []
            .concat(state.items || [])
            .concat(action.venue_cost)
            .map(forceVenueCostItemsToExists),
          {
            overrideDuplicateIds: 1,
          }
        ),
      };

    case 'API_GET_RESPONSE':
    case 'API_POST_RESPONSE':
      const responseData = (action.response && action.response.data) || {};

      if (!responseData.venue_cost && (!responseData.venue_costs || !responseData.venue_costs.length)) {
        return state;
      }

      const updated_venue_costs = [].concat(responseData.venue_costs || []).concat(responseData.venue_cost || []);

      return {
        ...state,
        items: (state.items || []).map((item) => {
          const updatedItem = updated_venue_costs.find(({ id }) => id === item.id);

          // per usi futuri: non è possibile avere lastRequestId
          // nelle action generiche API_GET_RESPONSE, API_POST_RESPONSE
          if (!checkRequestId(item, action.lastRequestId)) {
            return item;
          }

          return updatedItem
            ? {
                ...updatedItem,
                venue_cost_items: (updatedItem.venue_cost_items || []).concat(
                  (item.venue_cost_items || []).filter(({ isLocal }) => isLocal)
                ),
              }
            : item;
        }),
      };

    // Creates a new local cost, setting it as an invoice by default
    case 'VENUE_LOCAL_VENUE_COSTS_CREATE':
      const now = new Date();
      const id = action.id || 'local-' + now.getTime();

      return {
        ...state,
        items: [
          ...(state.items || []),
          {
            isLocal: true,
            id,
            ...defaultCost,
            invoice: true,
            venue_cost_items: [],
          },
        ],
      };

    case 'VENUE_LOCAL_VENUE_COSTS_DELETE':
      return {
        ...state,
        items: [...state.items.filter((item) => item.id !== action.id)],
      };

    case 'VENUE_LOCAL_VENUE_COSTS_EDIT':
      return {
        ...state,
        items: state.items.map((item) => (item.id === action.id ? { ...item, ...action.changes } : item)),
      };

    case 'VENUE_LOCAL_VENUE_COSTS_ITEM_ADD':
      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.id
            ? {
                ...item,
                venue_cost_items: [
                  ...item.venue_cost_items,
                  {
                    id: 'local-costitem-' + new Date().getTime(),
                    ...defaultLocalCostItem,
                  },
                ],
              }
            : item
        ),
      };

    case 'VENUE_LOCAL_VENUE_COSTS_ITEM_DELETE':
      if (!action.costItemId) {
        return state;
      }

      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.id
            ? {
                ...item,
                venue_cost_items: [...(item.venue_cost_items || []).filter((item) => item.id !== action.costItemId)],
              }
            : item
        ),
      };

    case 'VENUE_LOCAL_VENUE_COSTS_ITEM_EDIT':
      if (!action.costItemId) {
        return state;
      }

      if (!action.changes) {
        return state;
      }

      return {
        ...state,
        items: state.items.map((item) =>
          item.id === action.id
            ? {
                ...item,
                venue_cost_items: [
                  ...(item.venue_cost_items || []).map((item) =>
                    item.id !== action.costItemId ? item : { ...item, ...action.changes }
                  ),
                ],
              }
            : item
        ),
      };

    default:
      return state;
  }
};

export const venueCostsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case 'VENUE_COSTS_LIST_REQUEST':
    case 'VENUE_COSTS_LIST_RESPONSE':
    case 'VENUE_UPDATE_COST_REQUEST':
    case 'VENUE_UPDATE_COST_RESPONSE':
    case 'API_GET_RESPONSE':
    case 'API_POST_RESPONSE':
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = venueCosts(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    case 'VENUE_LOCAL_VENUE_COSTS_CREATE':
      if (!action.venue_id) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: venueCosts(state[action.venue_id], action),
      };

    case 'VENUE_LOCAL_VENUE_COSTS_EDIT':
    case 'VENUE_LOCAL_VENUE_COSTS_DELETE':
    case 'VENUE_LOCAL_VENUE_COSTS_ITEM_ADD':
    case 'VENUE_LOCAL_VENUE_COSTS_ITEM_DELETE':
    case 'VENUE_LOCAL_VENUE_COSTS_ITEM_EDIT':
      if (!action.venue_id || !action.id) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: venueCosts(state[action.venue_id], action),
      };

    default:
      return state;
  }
};
