import { createIntl, IntlShape, IntlCache } from 'react-intl';
import { rootMessages } from './IntlProvider.data';

import { AvailableLocales, DEFAULT_LOCALE } from './LocaleProvider';

export const createIntlInstance = (locale: AvailableLocales, cache?: IntlCache): IntlShape => {
  return createIntl(
    {
      locale: locale || DEFAULT_LOCALE,
      messages: rootMessages[locale],
    },
    cache
  );
};
