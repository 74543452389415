import PropTypes from 'prop-types';
import createText, { TextCurrency, TextPrefix, TextSuffix } from './theme';
import { FormattedMessage, FormattedNumber } from 'react-intl';

const Text = (props) => {
  const StyledText = props.component ? createText(props.component) : createText[props.as];

  if (props.intl) {
    if (props.pure) {
      return (
        <FormattedMessage id={props.intl} defaultMessage={props.defaultMessage} values={props.intlValues || {}}>
          {(txt) => txt}
        </FormattedMessage>
      );
    }

    return (
      <StyledText className="Text" {...props}>
        {props.prefix && <TextPrefix>{props.prefix}</TextPrefix>}
        <FormattedMessage id={props.intl} defaultMessage={props.defaultMessage} values={props.intlValues || {}} />
        {props.suffix && <TextSuffix>{props.suffix}</TextSuffix>}
      </StyledText>
    );
  }

  if (props.number || props.number === 0) {
    return (
      <StyledText className="Text" {...props}>
        {props.prefix && <TextPrefix>{props.prefix}</TextPrefix>}
        {props.currency && <TextCurrency currencyColor={props.currencyColor}>{props.currency}</TextCurrency>}
        <FormattedNumber value={props.number} {...props.numberProps}>
          {(txt) => txt}
        </FormattedNumber>
        {props.percent && '%'}
        {props.suffix && <TextSuffix>{props.suffix}</TextSuffix>}
      </StyledText>
    );
  }

  return (
    <StyledText className="Text" {...props}>
      {props.prefix && <TextPrefix>{props.prefix}</TextPrefix>}
      {props.children}
      {props.suffix && <TextSuffix>{props.suffix}</TextSuffix>}
    </StyledText>
  );
};

Text.displayName = 'Text';

Text.defaultProps = {
  as: 'span',
  size: null,
  spacing: null,
  bold: false,
  black: false,
  flex: false,
  inline: false,
  uppercase: false,
  lowercase: false,
  capitalize: false,
  center: false,
  underline: false,
  userSelect: undefined,
  whiteSpace: undefined,
  tableCaption: false,
  percent: false,
  truncate: false,
  textAlign: undefined,
  verticalAlign: undefined,
};

Text.propTypes = {
  as: PropTypes.oneOf(['span', 'div', 'p', 'a']),
  component: PropTypes.node,
  children: PropTypes.any,
  size: PropTypes.number,
  /** Letter Spacing */
  spacing: PropTypes.number,
  bold: PropTypes.bool,
  black: PropTypes.bool,
  block: PropTypes.bool,
  flex: PropTypes.any,
  inline: PropTypes.bool,
  uppercase: PropTypes.bool,
  lowercase: PropTypes.bool,
  capitalize: PropTypes.bool,
  truncate: PropTypes.bool,
  color: PropTypes.string,
  center: PropTypes.bool,
  underline: PropTypes.bool,
  padding: PropTypes.number,
  paddingRight: PropTypes.number,
  paddingLeft: PropTypes.number,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  userSelect: PropTypes.string,

  /** If is setup react-intl Renderer, you can pass the translation id and values to show the localized string without importing FormattedMessage */
  pure: PropTypes.bool,
  intl: PropTypes.string,
  intlValues: PropTypes.object,

  /** If is setup react-intl Renderer, you can pass the number without importing FormattedNumber */
  number: PropTypes.number,
  numberProps: PropTypes.object,

  prefix: PropTypes.any,
  suffix: PropTypes.any,

  onClick: PropTypes.func,
  className: PropTypes.string,
  whiteSpace: PropTypes.string,
  lineHeight: PropTypes.number,
  target: PropTypes.string,
  href: PropTypes.string,
  tableCaption: PropTypes.bool,
  percent: PropTypes.bool,
  italic: PropTypes.bool,
  defaultMessage: PropTypes.string,
  right: PropTypes.bool,
  textAlign: PropTypes.string,
  verticalAlign: PropTypes.string,
  width: PropTypes.string,
};

export default Text;
