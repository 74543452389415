/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { SubcategoriesQuery, SubcategoriesResponse } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const SUBCATEGORIES =
  ({ variables: { by_category_id } }: V<SubcategoriesQuery>) =>
  (): Promise<SubcategoriesResponse> =>
    Http()
      .get<SubcategoriesResponse, Record<string, unknown>>(ENDPOINT.VENUE_PRODUCTS._SUBCATEGORIES({ by_category_id }))(
        {}
      )
      .then(maybeResponseToJSON);
