import { ACL_PERMISSION, ACL_PERMISSIONS_VALUES, CheckPermissionCallback } from 'models';
import { Permission, Permissions, Session } from 'reducers/sessions';
import { State } from 'reducers/types';
import { createSelector } from 'reselect';
import { getCurrentVenueId, StateWithCurrentVenueId } from 'selectors/getCurrentVenue';

// solId: Interface Segregation
export type StateWithACL = StateWithCurrentVenueId & {
  sessions?: {
    permissionsByVenue?: State['sessions']['permissionsByVenue'];
  };
};

export const getPermissionsByVenue = (state: StateWithACL): Session['permissionsByVenue'] =>
  state.sessions?.permissionsByVenue ?? [];

const emptyPermissions: Permission[] = [];
export type getPermissionsByCurrentVenue = CheckPermissionCallback;

const getUniqueKey = (permissions: Permissions, unique_key: ACL_PERMISSION): keyof typeof ACL_PERMISSIONS_VALUES =>
  ((permissions.find((perm) => perm.unique_key === unique_key) || {}).mode?.toUpperCase() ||
    'NONE') as keyof typeof ACL_PERMISSIONS_VALUES;

export const getPermissionChecker: (state: StateWithACL) => getPermissionsByCurrentVenue = createSelector(
  getCurrentVenueId,
  getPermissionsByVenue,
  (venue_id, permissionsByVenue) => {
    const permissions = (venue_id && permissionsByVenue && permissionsByVenue[venue_id]) || emptyPermissions;
    return (unique_key) => ACL_PERMISSIONS_VALUES[getUniqueKey(permissions, unique_key)];
  }
);
