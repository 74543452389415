/**
 * @description
 * given an array of objects and one of its keys, remove duplicate objects by key within the array
 *
 * @example
 * ts```
 * const arr = [{id: 1, name: 'John'},{id: 2,name: 'Wayne'},{id: 1,name: 'Billy'}];
 * expect(avoidDuplicatesByKey('id', arr)).toStrictEqual([{id: 1, name: 'John'},{id: 2,name: 'Wayne'}])
 * ```
 * @param key {String} key of object
 * @param arr {Array} array of objects
 */
export const avoidDuplicatesByKey = <A = Record<string, unknown>>(
  key: keyof A,
  arr: ReadonlyArray<A>
): ReadonlyArray<A> =>
  Array.from(
    arr
      .reduce(
        // eslint-disable-next-line no-sequences
        (acc, item) => (item && item[key] && !acc.has(item[key]) && acc.set(item[key], item), acc),
        new Map()
      )
      .values()
  );
