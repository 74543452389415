import entities from './entities.json';
import { apiGet } from 'actions/data_providers/api';
import { updatedSinceReceived } from 'actions/sessions';
import { ParametricActionCreator } from 'actions/types';
import { getUpdatedSinceByVenue } from 'selectors/getCurrentVenue';
import { executeSynchHooks } from 'actions/synch-hooks';

type HTTPSynch = ParametricActionCreator<{ venue_id: number }, Promise<void>>;

export const httpSynch: HTTPSynch =
  ({ venue_id }) =>
  (dispatch, getState): Promise<void> =>
    new Promise((resolve, reject) => {
      const path = `/venues/${venue_id}/synch.json`;
      const updatedSinceByVenue = getUpdatedSinceByVenue(getState());
      const updated_since = updatedSinceByVenue[venue_id];

      /**
       * If updated_since is not set:
       * This is the first time httpSynch is called, all data comes from fresh pre-fetch
       */
      if (!updated_since) {
        dispatch(updatedSinceReceived({ venue_id, timestamp: new Date().toISOString() }));
        return resolve();
      }

      dispatch(
        apiGet({
          path,
          data: {
            updated_since,
            only: entities.join(',').toLowerCase(),
          },
          onError: () => {
            reject();
          },
          onResponse: (response) => {
            if (response.success) {
              if (response.data.timestamp) {
                dispatch(updatedSinceReceived({ venue_id, timestamp: response.data.timestamp }));
              }
              dispatch(executeSynchHooks({ venue_id, response }));
            }
            resolve();
          },
        })
      );
    });
