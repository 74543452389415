import { rootMessages, locales } from './IntlProvider.data';
import { AvailableLocales, DEFAULT_LOCALE } from './LocaleProvider';

export enum ACTION_TYPE {
  ADD_MESSAGE = 'ADD_MESSAGE',
  SWITCH_LANGUAGE = 'SWITCH_LANGUAGE',
}

export type State = {
  messages: { [key: string]: string };
  locale: AvailableLocales;
};

type SwitchLanguageAction = {
  type: ACTION_TYPE.SWITCH_LANGUAGE;
  payload: {
    locale: State['locale'];
  };
};

type AddMessagesAction = {
  type: ACTION_TYPE.ADD_MESSAGE;
  payload: {
    messages: State['messages'];
  };
};

export type IntlReducerAction = SwitchLanguageAction | AddMessagesAction;

export const IntlReducer = (state: State, action: IntlReducerAction): State => {
  switch (action.type) {
    case ACTION_TYPE.ADD_MESSAGE: {
      const { messages } = action.payload;
      return { ...state, messages: { ...state.messages, ...messages } };
    }
    case ACTION_TYPE.SWITCH_LANGUAGE: {
      const { locale } = action.payload;
      // check if we support that language
      if (locales.includes(locale)) {
        return { ...state, locale, messages: rootMessages[locale] };
      }
      return { ...state, locale: DEFAULT_LOCALE, messages: rootMessages[DEFAULT_LOCALE] };
    }
    default:
      return state;
  }
};
