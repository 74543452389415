import { FunctionComponent, ReactElement, useCallback, useMemo } from 'react';
import moment from 'moment';
import { connectForm } from '@uala/react-forms';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { Icon, Section, Text } from 'components/core';
import { Radio } from 'components/ui-elements/form';
import { PromoEditShape, Toolbar } from './PromoFlashEdit.theme';
import Actions from './PromoFlashEditActions';
import { MarketingPromotion } from 'models';
import PromoFlashEditResources from './PromoFlashEditResources.container';
import { ModalParams } from 'actions/modals';

type ResourcesAttributes = {
  table_name: string | null;
  record_id: number | null;
  discount_type: string;
  discount_percent_amount: number;
};

type PromoFlash = {
  applied_to: string;
  duration: string | number;
  included_resources_attributes: ResourcesAttributes[];
};

interface PromoFlashEditProps {
  marketing_promotion: MarketingPromotion;
  promoFlash: PromoFlash;
  dispatchCreateFlashPromotion: (obj: { marketing_promotion: any }) => Promise<void>;
  dispatchDeleteFlashPromotion: () => Promise<void>;
  dispatchModalOpen: (args: ModalParams) => Promise<void>;
  dispatchModalClose: (config: { id: string }) => Promise<void>;
  getLocalChanges: () => unknown;
  setLocalChanges: (config: Partial<PromoFlash>) => void;
}

const durationOptions = [
  {
    value: 'today',
    label: <Text pure intl="promotions.flash.only-for-today"></Text>,
  },
  {
    value: 24,
    label: <Text pure intl="promotions.flash.24hrs"></Text>,
  },
  {
    value: 48,
    label: <Text pure intl="promotions.flash.48hrs"></Text>,
  },
  {
    value: 72,
    label: <Text pure intl="promotions.flash.72hrs"></Text>,
  },
];

const typologyOptions = [
  {
    value: 'all',
    label: <Text pure intl="promotions.form.typology.all"></Text>,
  },
  {
    value: 'custom',
    label: <Text pure intl="promotions.form.typology.select"></Text>,
  },
];

const PromoFlashEdit: FunctionComponent<PromoFlashEditProps & WrappedComponentProps> = ({
  dispatchCreateFlashPromotion,
  dispatchDeleteFlashPromotion,
  dispatchModalOpen,
  dispatchModalClose,
  promoFlash,
  marketing_promotion,
  getLocalChanges,
  setLocalChanges,
  intl,
}): ReactElement => {
  const valid = useMemo(() => {
    return Boolean(
      marketing_promotion &&
        marketing_promotion.calculated_valid_to &&
        moment(marketing_promotion.calculated_valid_to).isAfter({})
    );
  }, [marketing_promotion]);

  const onDurationChange = useCallback(
    (duration: number | string) => {
      setLocalChanges({ duration });
    },
    [setLocalChanges]
  );

  const onTypologyChange = useCallback(
    (applied_to: string) => {
      setLocalChanges({
        applied_to,
        included_resources_attributes:
          applied_to === 'custom'
            ? []
            : [
                {
                  table_name: null,
                  record_id: null,
                  discount_type: 'percent',
                  discount_percent_amount: 0,
                },
              ],
      });
    },
    [setLocalChanges]
  );

  const onSave = (): Promise<void> => {
    return dispatchCreateFlashPromotion({
      marketing_promotion: {
        duration: promoFlash.duration,
        included_resources_attributes: [
          ...promoFlash.included_resources_attributes.filter(
            // BE Formatting rule: filter out promotions without discount percent amount
            // Currently, the check on discount type is unnecessary, but it will be useful to support absolute discounts
            (i) =>
              (i.table_name === null || i.table_name === 'treatments' || i.table_name === 'venue_treatments') &&
              (i.discount_percent_amount > 0 || i.discount_type !== 'percent')
          ),
        ],
      },
    });
  };

  const haveValidDiscountApplied =
    promoFlash.included_resources_attributes.filter((resource) => resource.discount_percent_amount > 0).length > 0;

  return (
    <PromoEditShape data-testid="promo-flash">
      {marketing_promotion ? (
        <Section
          padding={1}
          paddingTop={0}
          displayFlex
          alignItems="center"
          justifyContent="center"
          borderBottom
          background="bgGray"
        >
          <Section marginRight={1}>
            <Icon type="tick-circle-2" size={50} color="blue" />
          </Section>
          <Section displayFlex flexDirection="column" alignItems="flex-start">
            <Section displayFlex>
              <Text block center bold size={14} color="text1" intl="promotions.valid_to" />
              <Section marginRight={0.125} />
              <Text color="blue" center bold size={14}>
                {moment(marketing_promotion.calculated_valid_to).format('lll')}
              </Text>
            </Section>
            <Text
              block
              center
              size={14}
              color="text1"
              intl="marketing.status.info.draft"
              intlValues={{ created_at: moment(marketing_promotion.calculated_valid_from).format('lll') }}
            />
          </Section>
        </Section>
      ) : (
        <Section padding={1} paddingTop={0} borderBottom background="bgGray">
          {intl
            .formatMessage({ id: 'promotions.flash.description' })
            .split('|')
            .map((p, k) => (
              <Text key={k} block center size={14} color="text1">
                {p}
              </Text>
            ))}
        </Section>
      )}
      <Section>
        <Section
          displayFlex
          alignItems="center"
          paddingTop={1.5}
          paddingBottom={1.5}
          flexDirection="column"
          borderBottom
        >
          <Text flex={1} size={12} color="text2" intl="promotions.flash.duration" uppercase spacing={1.2} />

          <Section marginTop={1.5} displayFlex alignItems="center" paddingLeft={0.625} paddingRight={2.1875}>
            <Radio
              options={durationOptions}
              onChange={(_name: string, value: string): void => onDurationChange(value)}
              value={durationOptions[0].value}
              align="center"
            />
          </Section>
        </Section>
        <Section
          displayFlex
          alignItems="center"
          paddingTop={1.5}
          paddingBottom={1.5}
          flexDirection="column"
          borderBottom
        >
          <Text flex={1} size={12} color="text2" intl="promotions.flash.applied_to" uppercase spacing={1.2} />
          <Section marginTop={1.5}>
            <Radio
              options={typologyOptions}
              onChange={(_name: string, value: string): void => onTypologyChange(value)}
              value={promoFlash.applied_to === 'all' ? typologyOptions[0].value : typologyOptions[1].value}
              align="center"
            />
          </Section>
        </Section>
        <PromoFlashEditResources promoFlash={promoFlash} setLocalChanges={setLocalChanges} />
        <Section displayFlex alignItems="center" paddingTop={1.5} paddingBottom={1.5} flexDirection="column">
          <Toolbar>
            <Actions
              valid={valid && haveValidDiscountApplied}
              touched={getLocalChanges() && haveValidDiscountApplied}
              onSave={onSave}
              onDelete={dispatchDeleteFlashPromotion}
              modalOpen={dispatchModalOpen}
              modalClose={dispatchModalClose}
            />
          </Toolbar>
        </Section>
      </Section>
    </PromoEditShape>
  );
};

export default connectForm()(injectIntl(PromoFlashEdit));
