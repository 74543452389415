import { FunctionComponent, ReactElement, useMemo, useCallback, ReactNode } from 'react';
import Select, { OnChangeValue, Options } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { FormControl, Label, FormControlInput } from '../../shared';
import { INPUT_INLINE } from '../../config';
import { connectFormElement, ConnectedFormProps } from '@uala/react-forms';
import DropdownIndicator from './DropdownIndicator';
import Theme from './theme';
import { injectIntl, WrappedComponentProps } from 'react-intl';

export interface Option {
  value: string;
  label: string;
}

interface MultiSelectProps extends WrappedComponentProps {
  options: Option[];
  creatable?: boolean;
  clearable?: boolean;
  labelPosition?: string;
  label?: string | ReactElement;
  name: string;
  valueMap: (v: unknown) => string;
  newOptionMinLength?: number;
  placeholderIntl?: string;
  noOptionsIntl?: string;
  addIntl?: string;
}

const MultiSelectComponent: FunctionComponent<MultiSelectProps & ConnectedFormProps> = ({
  creatable = true,
  clearable = false,
  options,
  labelPosition = INPUT_INLINE,
  label,
  name,
  values = {},
  valueMap,
  emitChange,
  emitDidChange,
  newOptionMinLength = 0,
  placeholderIntl = 'search',
  noOptionsIntl = 'no_options',
  addIntl = 'add',
  intl,
}): ReactElement => {
  const value = useMemo(() => values[name] || [], [values, name]);

  const onChange = useCallback(
    (changedValues: OnChangeValue<Option, boolean>) => {
      changedValues = changedValues || [];
      emitChange(name, changedValues);
      emitDidChange(name, changedValues);
    },
    [emitChange, emitDidChange, name]
  );

  const selectProps = useMemo(
    () => ({
      options,
      isMulti: true,
      isClearable: clearable,
      name,
      value: value.map(valueMap).map((v: string) => ({ value: v, label: v })),
      onChange,
      isValidNewOption: (inputValue: string, _: unknown, selectOptions: Options<Option>): boolean => {
        selectOptions = selectOptions || [];
        return (
          inputValue.length >= newOptionMinLength && !selectOptions.map((option) => option.value).includes(inputValue)
        );
      },
      components: { DropdownIndicator },
      styles: Theme,
      placeholder: intl.formatMessage({ id: placeholderIntl }),
      noOptionsMessage: (): string => intl.formatMessage({ id: noOptionsIntl }),
      formatCreateLabel: creatable
        ? (input: string): ReactNode => {
            return `${intl.formatMessage({ id: addIntl })} "${input}"`;
          }
        : undefined,
    }),
    [
      options,
      clearable,
      name,
      value,
      valueMap,
      onChange,
      intl,
      placeholderIntl,
      creatable,
      newOptionMinLength,
      noOptionsIntl,
      addIntl,
    ]
  );

  return (
    <FormControl labelPosition={labelPosition} className="MultiSelect">
      {label && (
        <Label htmlFor={name} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput labelPosition={labelPosition}>
        {/* these types don't match the new theme interface */}
        {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
        {creatable ? <CreatableSelect {...(selectProps as any)} /> : <Select {...(selectProps as any)} />}
      </FormControlInput>
    </FormControl>
  );
};

export const MultiSelect = connectFormElement(injectIntl(MultiSelectComponent));
