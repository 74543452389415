import { ValueOf } from 'utils/utilityTypes';
import { isElectron } from './electron';

// TODO should be moved to a global place related to native wrappers not cash
export const NATIVE_WRAPPER_TYPE = {
  IOS: 'ios',
  ANDROID: 'android',
  ELECTRON: 'electron',
} as const;
export type NATIVE_WRAPPER_TYPE_VALUES = ValueOf<typeof NATIVE_WRAPPER_TYPE>;

export type NativeWrapperType = ValueOf<typeof NATIVE_WRAPPER_TYPE> | null;

export const getCurrentNativeWrapper = (): NativeWrapperType => {
  if (window.webkit && window.webkit.messageHandlers) return NATIVE_WRAPPER_TYPE.IOS;
  if (window.androidHandler) return NATIVE_WRAPPER_TYPE.ANDROID;
  if (isElectron()) return NATIVE_WRAPPER_TYPE.ELECTRON;

  return null;
};

export const isMobileNativeWrapper = (): boolean => {
  const currentNativeWrapper = getCurrentNativeWrapper();
  return currentNativeWrapper === NATIVE_WRAPPER_TYPE.ANDROID || currentNativeWrapper === NATIVE_WRAPPER_TYPE.IOS;
};

export const isiOsNativeWrapper = (): boolean => {
  const currentNativeWrapper = getCurrentNativeWrapper();
  return currentNativeWrapper === NATIVE_WRAPPER_TYPE.IOS;
};

export const isAndroidNativeWrapper = (): boolean => {
  const currentNativeWrapper = getCurrentNativeWrapper();
  return currentNativeWrapper === NATIVE_WRAPPER_TYPE.ANDROID;
};
