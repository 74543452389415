import { Section, Icon, Box } from 'components/core';
import PropTypes from 'prop-types';
import Accordion from './theme';
import { Component as ReactComponent } from 'react';

class Component extends ReactComponent {
  state = { active: this.props.startOpen };

  toggle = (e) => {
    e.stopPropagation();

    this.setState({ active: !this.state.active }, () => {
      this.props.activeCallback(this.state.active);
    });
  };

  render() {
    const { title, content, borderBottom, showIcon, isDisabled } = this.props;

    return (
      <Accordion
        className={`AccordionItem ${this.state.active ? 'is-open' : 'is-close'} ${this.props.className}`}
        flex
        flexDirection="column"
        onClick={(e) => this.toggle(e)}
        isDisabled={isDisabled}
      >
        <Section className="AccordionItem__title-wrapper" flex alignItems="center" borderBottom={!!borderBottom}>
          <Section className="AccordionItem__title">{title}</Section>
          {showIcon ? (
            <Icon
              size={12}
              className="AccordionItem__icon"
              type={`angle-${this.state.active ? 'up' : 'down'}`}
              color={`${this.state.active ? 'blue' : 'text2'}`}
            />
          ) : (
            <Box h="12px" w="12px" mL="20px" />
          )}
        </Section>
        <Accordion.Content className="AccordionItem__content" opened={this.state.active}>
          {content}
        </Accordion.Content>
      </Accordion>
    );
  }
}

Component.displayName = 'AccordionItem';

Component.propTypes = {
  title: PropTypes.node,
  content: PropTypes.node,
  showIcon: PropTypes.bool,
  startOpen: PropTypes.bool,
  borderBottom: PropTypes.bool,
  activeCallback: PropTypes.func,
  isDisabled: PropTypes.bool,
};

Component.defaultProps = {
  showIcon: true,
  startOpen: false,
  activeCallback: () => {},
  isDisabled: false,
};

export default Component;
