import { STAFF_MEMBER_JOB_TITLES_LIST_REQUEST, STAFF_MEMBER_JOB_TITLES_LIST_RESPONSE } from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const staffMemberJobTitles = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case STAFF_MEMBER_JOB_TITLES_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case STAFF_MEMBER_JOB_TITLES_LIST_RESPONSE:
      if (!action.staff_member_job_titles || !action.staff_member_job_titles.length) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(state.items || []).concat(action.staff_member_job_titles || []), {
          avoidDuplicateIds: 1,
        }),
      };

    default:
      return state;
  }
};

export const staffMemberJobTitlesByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case STAFF_MEMBER_JOB_TITLES_LIST_REQUEST:
    case STAFF_MEMBER_JOB_TITLES_LIST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = staffMemberJobTitles(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
