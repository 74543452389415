/* eslint-disable no-restricted-imports */
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeResponseToJSON } from 'API/http.utils';
import { CancellationProtectionDeletePathParams, CancellationProtectionDeleteResponse } from 'API/types';

export const CANCELLATION_PROTECTION_DELETE =
  ({ variables: { id } }: V<CancellationProtectionDeletePathParams>) =>
  (): Promise<CancellationProtectionDeleteResponse> =>
    Http()
      .delete<CancellationProtectionDeleteResponse, CancellationProtectionDeletePathParams>(
        ENDPOINT.CANCELLATION_PROTECTIONS._DELETE(id)
      )({})
      .then(maybeResponseToJSON);
