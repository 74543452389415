import { TimeTable } from './timeTables';
import { Workstation } from './workstation';
import { ValueOf } from 'utils/utilityTypes';

export const staffMemberRoles = {
  JUNIOR: 'junior',
  SENIOR: 'senior',
  ROOM: 'room',
  STYLIST: 'stylist',
  MASTER: 'master',
  TOP: 'top',
  RECEPTIONIST: 'receptionist',
} as const;

type StaffMemberRoles = ValueOf<typeof staffMemberRoles>;

export interface StaffMember {
  id: number;
  first_name: string;
  last_name?: string;
  full_name: string;
  role: StaffMemberRoles;
  position: number;
  time_table?: TimeTable[];
  time_table_1?: TimeTable[];
  time_table_2?: TimeTable[];
  workstation_id: number | null;
  medium_image_url?: string | null;
  image?: File | string;
  short_name?: string;
  _workstation?: Partial<Workstation>;
  phone?: string;
  email?: string;
  commission_percentage?: number;
  updated_at?: string;
}
