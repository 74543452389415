import { styled, css } from 'styles';
import { SerializedStyles } from '@emotion/serialize';
import { CSSProperties } from 'react';

export type OpacityLayerWrapperProps = {
  isDisabled: boolean;
  opacity: number;
  showCursor: boolean;
  display: CSSProperties['display'];
  alignItems?: CSSProperties['alignItems'];
};

export const OpacityLayerWrapper = styled('div')<OpacityLayerWrapperProps>`
  ${({ isDisabled, opacity, showCursor, display, alignItems }): SerializedStyles => css`
    ${showCursor &&
    `
        cursor: pointer;
      `}
    ${isDisabled &&
    `
          cursor: default;
          opacity: ${opacity};
          pointer-events: none;
      `}
      ${display &&
    `
          display: ${display};
      `}
      ${alignItems &&
    `
          align-items: ${alignItems};
      `}
  `}
`;
