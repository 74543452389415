import { StylesConfig } from 'react-select';
// eslint-disable-next-line no-restricted-imports
import { theme } from 'styles';

const { colors, utils } = theme;

const Theme: StylesConfig<unknown, boolean> = {
  control: (b) => ({
    ...b,
    padding: '4px 11px 0 0',
    minHeight: '42px',
    borderRadius: '21px',
    borderColor: colors.inputBorder,
    fontSize: '14px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: colors.inputBorder,
    },
  }),
  valueContainer: (b) => ({
    ...b,
    padding: '0 11px 0 0',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (b) => ({
    ...b,
    color: colors.inputPlaceHolder,
    margin: '-2px 0 4px 11px',
  }),
  input: (b) => ({
    ...b,
    margin: '0 0 4px 11px',
    input: {
      fontFamily: utils.fontFamily,
    },
  }),
  dropdownIndicator: (b) => ({
    ...b,
    padding: 0,
  }),
  multiValue: (b) => ({
    ...b,
    background: colors.lightBlue,
    color: colors.blue,
    borderRadius: '20px',
    margin: '0 0 4px 4px',
    padding: 0,
  }),
  multiValueLabel: (b) => ({
    ...b,
    color: colors.blue,
    whiteSpace: 'normal',
    padding: '8px 0 9px 10px',
    paddingLeft: '10px',
  }),
  multiValueRemove: (b) => ({
    ...b,
    padding: '0 10px',
    color: colors.blue4,
    cursor: 'pointer',
    margin: 0,
    ' svg': {
      width: '16px',
      height: '16px',
    },
    '&:hover': {
      color: colors.blue4,
      background: 'transparent',
    },
  }),
  menu: (b) => ({
    ...b,
    margin: `-1px 0 0 0`,
    borderRadius: '0 0 21px 21px',
    boxShadow: 'none',
    border: `1px solid ${colors.inputBorder}`,
    fontSize: '14px',
  }),
  menuList: (b) => ({
    ...b,
    padding: 0,
  }),
  option: (b) => ({
    ...b,
    cursor: 'pointer',
    padding: '18px 0',
    margin: '0 11px',
    borderBottom: `1px solid ${colors.inputBorder}`,
    background: 'transparent',
    width: 'auto',
    '&:hover': {
      background: 'transparent',
      color: colors.blue,
    },
    '&:last-child': {
      borderBottom: 0,
    },
  }),
  noOptionsMessage: (b) => ({
    ...b,
    textAlign: 'left',
    padding: '18px 0',
    margin: '0 11px',
  }),
};

export default Theme;
