// Data Store
type StoreKey = string;
type StoreValue = unknown;
type DataStore = {
  [key: StoreKey]: StoreValue;
};

// Getter, Setter
type GetItem = <D>(key: StoreKey) => D;
type SetItem = <D>(key: StoreKey, value: D) => void;

// Events
type EventType = string;
type EventSource = null | unknown;
type EventTarget = null | unknown;

type MessageEvent<V = unknown> = {
  source: EventSource;
  target: EventTarget;
  scope?: string;
  type: EventType;
  value: V;
};

type StorageEvent<V = StoreValue> = {
  source: EventSource;
  target: EventTarget;
  scope?: string;
  type: 'storage';
  key: StoreKey;
  value: V;
};

type StoreEvent<V> = MessageEvent<V> | StorageEvent<V>;

type EventListener<D = unknown> = (event: StoreEvent<D>) => void;

type EventListeners = {
  [eventType: EventType]: Set<{
    target: EventTarget;
    listener: EventListener<any>;
  }>;
};

type DispatchEvent = <D>(event: StoreEvent<D>) => void;
type AddEventListener = <D>(
  target: EventTarget,
  eventType: EventType,
  listener: EventListener<D>
) => UndoAddEventListener;
type UndoAddEventListener = () => void;
type RemoveAllListeners = (target: EventTarget) => void;

export type HostStore = {
  getItem: GetItem;
  setItem: SetItem;

  dispatchEvent: DispatchEvent;
  addEventListener: AddEventListener;
  removeAllListeners: RemoveAllListeners;
};

export const createStore = (initialDataStore?: DataStore): HostStore => {
  const listeners: EventListeners = {};
  const dataStore: DataStore = { ...initialDataStore };

  function getItem<D = unknown>(key: StoreKey): D {
    return dataStore[key] as D;
  }

  function setItem<D = unknown>(key: StoreKey, value: D): void {
    dataStore[key] = value;
    const eventType = 'storage';
    const event: StoreEvent<D> = {
      source: null,
      target: null,
      type: eventType,
      key: key,
      value,
    };
    dispatchEvent<D>(event);
  }

  function dispatchEvent<D = unknown>(event: StoreEvent<D>): void {
    const error = authorizationErrors(event);
    if (error) {
      throw error;
    }
    const eventType = event.type;
    listeners[eventType] = listeners[eventType] || new Set();
    listeners[eventType].forEach((item) => {
      if (!event.target || event.target === item.target) {
        item.listener(event);
      }
    });
  }

  function addEventListener<D = unknown>(
    target: EventTarget,
    eventType: EventType,
    listener: EventListener<D>
  ): UndoAddEventListener {
    listeners[eventType] = listeners[eventType] || new Set();
    listeners[eventType].add({
      target: target,
      listener: listener,
    });

    return () => removeEventListener<D>(target, eventType, listener);
  }

  function removeEventListener<D = unknown>(
    target: EventTarget,
    eventType: EventType,
    listener?: EventListener<D>
  ): void {
    listeners[eventType] = listeners[eventType] || new Set();
    for (const item of listeners[eventType]) {
      if (item.target === target && (!listener || item.listener === listener)) {
        listeners[eventType].delete(item);
      }
    }
  }

  function removeAllListeners(target: EventTarget): void {
    for (const eventType in listeners) {
      removeEventListener(target, eventType);
    }
  }

  const store: HostStore = {
    getItem,
    setItem,

    dispatchEvent,
    addEventListener,
    removeAllListeners,
  };

  return store;
};

// Utils

const eventTypesBlacklist = ['storage', 'unmount'];
const eventTypesWhitelist = ['location'];

export function authorizationErrors(event: StoreEvent<unknown>): Error | null {
  const eventType = event.type;
  const sourceIsAnEventEmitter = event.source !== null;
  if (sourceIsAnEventEmitter) {
    const isEventTypeInBlacklist = eventTypesBlacklist.find((t) => t === eventType);
    if (isEventTypeInBlacklist) {
      return new Error('You are not authorized to emit a `' + eventType + '` event.');
    }
    const isEventTypeInWhitelist = eventTypesWhitelist.find((t) => t === eventType);
    if (!isEventTypeInWhitelist && event.scope) {
      const eventTypeStartsWithRemoteScope = eventType.indexOf(event.scope) !== 0;
      if (eventTypeStartsWithRemoteScope) {
        return new Error('Events emitted from remote `' + event.scope + '` must have `' + event.scope + '.` prefix');
      }
    }
  }
  return null;
}
