import { styled } from 'styles';

import { withTheme } from 'components/core';

export const StyledInputTag = withTheme(styled('input')`
  outline: none;
  background: inherit;
  padding: 0;
  margin: 0;
  cursor: text;
  border: none;
  font-size: 16px;
  font-family: ${({ theme }) => theme.utils.fontFamily};
  width: 100%;
`);
