const isElectron = (): boolean => {
  if (typeof window !== 'object' || typeof window.require !== 'function') {
    return false;
  }
  try {
    const electron = window.require('electron');
    if (typeof electron !== 'object') {
      return false;
    }
  } catch (e) {
    return false;
  }
  return true;
};

const getOperativeSystemType = (): string | undefined => {
  if (typeof window !== 'object' || typeof window.require !== 'function') {
    return undefined;
  }

  try {
    const os = window.require('os');

    if (typeof os !== 'object' || typeof os.type !== 'function') {
      return undefined;
    }

    return os.type();
  } catch (e) {
    return undefined;
  }
};

const getRelease = (): string | undefined => {
  if (typeof window !== 'object' || typeof window.require !== 'function') {
    return undefined;
  }

  try {
    const os = window.require('os');

    if (typeof os !== 'object' || typeof os.release !== 'function') {
      return undefined;
    }

    return os.release();
  } catch (e) {
    return undefined;
  }
};

export { isElectron, getOperativeSystemType, getRelease };
