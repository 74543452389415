import { Appointment } from 'models';
import moment from 'moment';
import { createSelector } from 'reselect';
import { getFullAppointmentsById, getFullAppointmentsList } from './getAppointments';
import { getAgendaCallerPopup } from './getAgendaCallerPopup';
import { getCurrentVenueId } from './getCurrentVenue';
import { State } from 'reducers/types';

export const getAgendaGridByVenue = (state: State) => state.agendaGridByVenue;

const emptyCutItems: Appointment[] = [];

export const getAgendaCutItems = createSelector(
  getAgendaGridByVenue,
  getCurrentVenueId,
  getFullAppointmentsById,
  getFullAppointmentsList,
  (agendaGridByVenue, venueId, appointmentsById, appointmentsList) => {
    const cutItemId = (venueId && agendaGridByVenue[venueId] && agendaGridByVenue[venueId].cutItemId) || null;
    if (!cutItemId) return emptyCutItems;
    const cutItem = appointmentsById[cutItemId];

    if (!cutItem) return emptyCutItems;

    const cutItemCustomerId =
      cutItem.customer_id > 0 ? cutItem.customer_id : appointmentsById[cutItem.parent_id]?.customer_id;

    return appointmentsList.reduce((cutItemAppointments, appointment) => {
      const { customer_id, time, parent_id } = appointment;
      const appointmentCustomerId = customer_id > 0 ? customer_id : appointmentsById[parent_id]?.customer_id;

      if (appointmentCustomerId !== cutItemCustomerId || !moment(time).isSame(cutItem.time, 'day'))
        return cutItemAppointments;

      const appointmentWithCustomerId = {
        ...appointment,
        customer_id: appointmentCustomerId,
      };

      return [...cutItemAppointments, appointmentWithCustomerId];
    }, emptyCutItems);
  }
);

export const getAgendaActiveCustomer = createSelector(
  [getCurrentVenueId, getAgendaGridByVenue, getAgendaCallerPopup],
  (venueId, agendaGridByVenue, agendaCallerPopup) => {
    const activeCustomer = (venueId && agendaGridByVenue[venueId] && agendaGridByVenue[venueId].activeCustomer) || null;
    /**
     * If user select a customer, this is the active customer (for 15 seconds)
     */
    if (activeCustomer) {
      return activeCustomer;
    }

    /**
     * If there's no active customer, but a customer from the caller box, return the one from the callerbox.
     */
    if (agendaCallerPopup && agendaCallerPopup.customer) {
      const { customer } = agendaCallerPopup;
      return customer;
    }

    return null;
  }
);

export const getAgendaNewCustomer = createSelector(
  [getCurrentVenueId, getAgendaGridByVenue],
  (venueId, agendaGridByVenue) => {
    const newCustomer = (venueId && agendaGridByVenue[venueId] && agendaGridByVenue[venueId].newCustomer) || null;
    return newCustomer && newCustomer.full_name ? { ...newCustomer } : null;
  }
);
