import { Component } from 'react';
import PropTypes from 'prop-types';
import StyledCheckbox from './theme';

class Checkbox extends Component {
  state = {
    checked: this.props.checked || false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  handleChange = (event) => {
    const checked = event.target.checked;
    this.setState({ checked }, () => this.props.onChange(checked));
  };

  render() {
    const { name, value, inverted, onChange, children } = this.props;
    const props = { ...this.props, onChange: null };

    return (
      <StyledCheckbox {...props}>
        <StyledCheckbox.Label inverted={inverted}>
          {children}
          <StyledCheckbox.Input
            type="checkbox"
            name={name}
            value={value}
            checked={this.state.checked}
            onChange={onChange ? this.handleChange : null}
          />
          <StyledCheckbox.Value className="Checkbox__Value" />
        </StyledCheckbox.Label>
      </StyledCheckbox>
    );
  }
}

Checkbox.propTypes = {
  size: PropTypes.string,
  inverted: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  size: 'default',
  onChange: () => {},
};

const Input = (props) => <StyledCheckbox.Input {...props} />;

Input.displayName = 'Checkbox.Input';

Checkbox.Input = Input;

export default Checkbox;
