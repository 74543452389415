import { apiGet } from './data_providers/api';

export const fetchCountries = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    // https://docs.uala.it/api/v1/fiscal_days
    dispatch(
      apiGet({
        path: `/countries.json`,
        guest: true,
        onResponse(response) {
          if (!response.success) {
            return reject({ error: response.info && response.info[0] });
          }

          resolve({ countries: response.data.countries });
        },
        onError(error) {
          reject({ error });
        },
      })
    );
  });
