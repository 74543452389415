import PropTypes from 'prop-types';
import * as Layout from './theme';

export const Container = (props) => <Layout.Container className="Container" {...props} />;

Container.displayName = 'Layout.Container';
Container.propTypes = {
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

export const Row = (props) => <Layout.Row className="Row" {...props} />;
Row.displayName = 'Layout.Row';

Row.propTypes = {
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

export const Column = (props) => <Layout.Column className="Column" {...props} />;
Column.displayName = 'Layout.Column';

Column.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const FlexRow = (props) => <Layout.FlexRow className="FlexRow" {...props} />;
FlexRow.displayName = 'Layout.FlexRow';

FlexRow.propTypes = {
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
};

export const FlexColumn = (props) => <Layout.FlexColumn className="FlexColumn" {...props} />;
FlexColumn.displayName = 'Layout.FlexColumn';

FlexColumn.propTypes = {
  flex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
