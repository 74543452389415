import { useEffect } from 'react';
import { modalOpen } from 'actions/modals';
import { useDispatch, useSelector } from 'react-redux';
import { ActionDispatch } from 'store';
import { getModals } from 'selectors/getModals';
import { NativeWrapperType, getCurrentNativeWrapper } from 'utils/native/cash/getCurrentNativeWrapper';
import { electronGetGlobal } from 'utils/native/electronUtils';
import { compareSemver } from 'utils/compareVersion';
import { useStorage } from 'utils/hooks/useStorage';
import { compareSameDay } from 'utils/dates';
import { checkPlatform, platform } from './checkPlatform';
import { isVersionBelowRequired } from './checkVersion';
import { TWPRO_WIN_DESKTOP_INSTALLER_LINK, WIN_DESKTOP_APP_LATEST_VERSION } from './constants';
import { dispatchCustomEvent } from 'customEvents';
import * as CustomEvents from './customEvents';

export const WIN_DESKTOP_NOTIFY_DATE_LOCALSTORAGE_KEY = 'WIN_DESKTOP_NOTIFY_DATE';

export const WIN_DESKTOP_FLASH_MESSAGE_ID = 'win-desktop-flash-message';
const KYC_FLASH_MESSAGE_ID = 'kyc-verification-flash-message';

export type VersionUpdateNotifierLocalStorageObject = {
  date: number;
} | null;

type Params = {
  getElectronVersion: () => string | null;
  getCurrentNativeWrapper: () => NativeWrapperType;
  getToday: () => Date;
  checkPlatform: () => 'win32' | 'darwin' | 'linux';
  cutVersion: string;
};

export const handleConfirm = () => {
  window.open(TWPRO_WIN_DESKTOP_INSTALLER_LINK, '_blank');
  dispatchCustomEvent(CustomEvents.CONFIRMED);
};

export const createUseCheckWinDesktopVersion =
  ({ getElectronVersion, getCurrentNativeWrapper, getToday, checkPlatform, cutVersion }: Params) =>
  (): void => {
    const wrapperType = getCurrentNativeWrapper();
    const [lastNotifiedDate, setLastNotifiedDate] = useStorage<VersionUpdateNotifierLocalStorageObject>(
      WIN_DESKTOP_NOTIFY_DATE_LOCALSTORAGE_KEY
    );
    const dispatch: ActionDispatch = useDispatch();
    const modals = useSelector(getModals);

    const handleClose = (): void => {
      setLastNotifiedDate({ date: Date.now() });
      dispatchCustomEvent(CustomEvents.CLOSED);
    };

    const showCheckWinVersionFlashMessage = (): void => {
      dispatchCustomEvent(CustomEvents.SHOWN);
      if (modals?.some((m) => m.type === WIN_DESKTOP_FLASH_MESSAGE_ID || m.type === KYC_FLASH_MESSAGE_ID)) return;
      const now = getToday();
      if (!compareSameDay(now, new Date(Number(lastNotifiedDate?.date)))) {
        dispatch(
          modalOpen({
            id: WIN_DESKTOP_FLASH_MESSAGE_ID,
            config: {
              component: 'version_update/WinDesktopUpdateVersionBanner',
              onConfirm: handleConfirm,
              onClose: handleClose,
            },
          })
        );
      }
    };

    useEffect(() => {
      if (wrapperType === 'electron') {
        const electronVersion = getElectronVersion();
        const isWindows = checkPlatform() === platform.windows;

        if (isWindows && (!electronVersion || isVersionBelowRequired(cutVersion, electronVersion, compareSemver))) {
          showCheckWinVersionFlashMessage();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  };

export const useCheckWinDesktopVersion = createUseCheckWinDesktopVersion({
  getElectronVersion: () => electronGetGlobal('version'),
  getCurrentNativeWrapper,
  getToday: () => new Date(),
  checkPlatform,
  cutVersion: WIN_DESKTOP_APP_LATEST_VERSION,
});
