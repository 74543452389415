import React, { useContext, createContext, FunctionComponent, useState, useMemo, useEffect } from 'react';
import { Reservation } from 'models';

interface Props<A> {
  value: Changes<A>;
  cacheKeys: Array<unknown>;
}

type Changes<A> = A;

export type UseLocalChanges<T> = {
  get: () => T;
  getInitial: () => T;
  getChanges: () => T | null;
  set: (changes: T) => void;
  setAttr: (a: string, b: unknown) => void;
  reset: () => void;
} | null;

const LocalChangesContext = createContext<UseLocalChanges<Reservation>>(null);

const ContextProvider: FunctionComponent<Props<Reservation>> = ({ value, cacheKeys, children }) => {
  const [changes, setChanges] = useState<Changes<Reservation> | null>(null);

  const context = useMemo(() => {
    const localValue: Changes<Reservation> = {
      ...value,
      ...(changes || {}),
    };

    return {
      get: () => localValue,
      getInitial: () => value,
      getChanges: () => changes,
      set: setChanges,
      reset: (): void => setChanges(null),
      setAttr: (k: string, v: unknown): void => setChanges({ [k]: v } as unknown as Changes<Reservation>),
    };
  }, [changes, value]);

  useEffect(() => {
    if (changes) {
      setChanges(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, cacheKeys);

  return <LocalChangesContext.Provider value={context}>{children}</LocalChangesContext.Provider>;
};

function useLocalChanges(): {
  handle: UseLocalChanges<Reservation>;
  LocalChangesProvider: React.FunctionComponent<Props<Reservation>>;
} {
  const handle = useContext(LocalChangesContext);
  return {
    handle,
    LocalChangesProvider: ContextProvider,
  };
}

export default useLocalChanges;
