import { useEffect } from 'react';
import { connect } from 'react-redux';
import { modalOpen } from 'actions/modals';
import { Text } from 'components/core';
import { configureScope } from '@sentry/core';
import { createSelector } from 'reselect';
import { getCurrentVenueLocale, getCurrentVenue } from 'selectors/getCurrentVenue';
import { isUserMultiVenue } from 'selectors/getUserMultiVenue';
import checkVersion from 'utils/upgrade/checkVersion';
import { useTheme, MULTI_VENUE_SIDEBAR_WIDTH, ThemeProvider } from 'styles';
import { MultiVenueSidebar } from 'components/multi_venue_next';

import 'containers/modals/NewVersionAvailable';
import { useCheckWinDesktopVersion } from 'utils/version_check/useCheckWinDesktopVersion';

const LayoutProvider = ({ venue, venue_locale, isMultiVenue, dispatchModalOpen, children }) => {
  const theme = useTheme();
  useCheckWinDesktopVersion();
  /**
   * This themeData line is crucial, many components are not connected to Redux store to know if the user is multi venue.
   * They only use the sizes.multiVenueSidebarWidth value from the theme and this is the entry point.
   * If the user is NOT multi venue, its value must be 0.
   * eg:
   * margin-left: ${theme.sizes.multiVenueSidebarWidth}px;
   * If NOT multi venue, margin-left will be 0, if multi venue, margin-left will have the original value from the theme.
   */
  const themeData = isMultiVenue
    ? { ...theme, sizes: { ...theme.sizes, multiVenueSidebarWidth: MULTI_VENUE_SIDEBAR_WIDTH } }
    : theme;

  useEffect(() => {
    if (window.APP_LOCALE && venue_locale) {
      configureScope((scope) => {
        scope.setTag('client_venue.locale', window.APP_LOCALE + '_' + venue_locale);
      });
    }
  }, [venue_locale]);

  useEffect(() => {
    checkVersion({
      venue: venue,
      onNewVersionAvailable: (version, skip) => {
        dispatchModalOpen({
          id: 'new-version-available',
          config: {
            component: 'NewVersionAvailable',
            content: [
              <Text key="version" intl="new_version_available.version" intlValues={{ version: version }} />,
              <Text key="content" intl="new_version_available.content" />,
            ],
            timeoutSeconds: 60,
            onClose: () => skip(),
            onConfirm: () => {
              window.location.href = window.location.href.split('#').shift().split('?').shift() + '?v=' + version;
            },
          },
        });
      },
    });
  }, [venue, dispatchModalOpen, children]);

  if (isMultiVenue) {
    return (
      <ThemeProvider theme={themeData}>
        <div className="layout-provider">
          <MultiVenueSidebar />
          {children}
        </div>
      </ThemeProvider>
    );
  }

  return <div className="layout-provider">{children}</div>;
};

const mapStateToProps = createSelector(
  getCurrentVenueLocale,
  getCurrentVenue,
  isUserMultiVenue,
  (venue_locale, venue, isMultiVenue) => {
    return { venue_locale, venue, isMultiVenue };
  }
);

const mapDispatchToProps = {
  dispatchModalOpen: modalOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutProvider);
