import isMobile from 'ismobilejs';
import { store } from 'store';
import { State } from 'reducers/types';

const canPersistCustomers = (): boolean => {
  const reduxStore: State = store.getState();
  let isUserMultiVenue = false;
  if (reduxStore && reduxStore.sessions && reduxStore.sessions.sessionVenues) {
    isUserMultiVenue = reduxStore.sessions.sessionVenues.length > 1 ? true : false;
  }
  return !isUserMultiVenue && !isMobile().any;
};

export default canPersistCustomers;
