import { styled, css } from 'styles';
import { withTheme } from '../Context';

const transitionFunc = 'cubic-bezier(0.78, 0.14, 0.15, 0.86)';

const Shape = withTheme(
  styled('div')(
    ({ theme, floating, visible, color }) => css`
      ${
        floating
          ? `
            position: absolute;
            left: 20px;
            top: -20px;
          `
          : `
            position: relative;
          `
      };
      display: inline-block;
      cursor: pointer;
      padding: 2px 9px;
      background: ${(color && theme.colors[color]) || theme.colors.blue};
      border-radius: 4px;
      box-shadow 0 3px 4px rgba(0, 0, 0, 0.30);

      transition: all, 0.4s ${transitionFunc};
      ${
        visible
          ? `
        opacity: 1;
        transform: translateY(0px);
      `
          : `
        opacity: 0;
        transform: translateY(10px);
      `
      }

      font-family: ${theme.utils.fontFamily};
      font-size: 12px;
      font-weight: 700;
      color: ${theme.colors.white};

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 20%;
        top: calc(100% - 4px);
        width: 8px;
        height: 8px;
        background: ${(color && theme.colors[color]) || theme.colors.blue};
        transform: rotate(45deg);
        z-index: -1;
      }
    `
  )
);

export default Shape;
