import {
  AgendaSubPagesCustomEvents,
  LOCATION_CHANGED_EVENT,
  VIEW_DEFAULT_EVENT,
  VIEW_SPA_EVENT,
  dispatchCustomEvent,
} from 'customEvents';
import { TrackingRule } from 'utils/tracking/aspectTracking';
import { AgendaPageRulesMemory } from './agendaPageRulesMemory';

type Context = {
  agendaPageRulesMemory: AgendaPageRulesMemory;
};

export const getAgendaSubPagesRules = ({
  agendaPageRulesMemory,
}: Context): TrackingRule<AgendaSubPagesCustomEvents>[] => {
  const agendaPageTrack = (selection: 'default' | 'spa') => {
    agendaPageRulesMemory.set('selection', selection);
    dispatchCustomEvent(LOCATION_CHANGED_EVENT);
  };

  const rules: TrackingRule<AgendaSubPagesCustomEvents>[] = [
    (is) => is('document', VIEW_DEFAULT_EVENT) && agendaPageTrack('default'),
    (is) => is('document', VIEW_SPA_EVENT) && agendaPageTrack('spa'),
  ];
  return rules;
};
