import {
  AgendaNextCustomEvents,
  DAILY_VIEW_EVENT_TYPE,
  LOCATION_CHANGED_EVENT,
  WEEKLY_VIEW_EVENT_TYPE,
  dispatchCustomEvent,
} from 'customEvents';
import { TrackingRule } from 'utils/tracking/aspectTracking';
import { AgendaPageRulesMemory } from 'utils/tracking/trackingObservations/customAgendaPageTracking/agendaPageRulesMemory';

type Context = {
  agendaPageRulesMemory: AgendaPageRulesMemory;
};

export const getAgendaDailyWeeklyViewRules = ({
  agendaPageRulesMemory,
}: Context): TrackingRule<AgendaNextCustomEvents>[] => {
  const agendaPageTrack = (value: 'daily' | 'weekly') => {
    agendaPageRulesMemory.set('dailyWeeklyView', value);
    dispatchCustomEvent(LOCATION_CHANGED_EVENT);
  };

  const rules: TrackingRule<AgendaNextCustomEvents>[] = [
    (is) => is('document', DAILY_VIEW_EVENT_TYPE) && agendaPageTrack('daily'),
    (is) => is('document', WEEKLY_VIEW_EVENT_TYPE) && agendaPageTrack('weekly'),
  ];

  return rules;
};
