import { AvailableCreditCard } from '@uala/web-payments';

export function getLastFourDigits(cardNumber: string): string {
  return cardNumber.slice(cardNumber.length - 4, cardNumber.length);
}

function compareCreditCardsById(a: AvailableCreditCard, b: AvailableCreditCard): number {
  const bandA = a.id;
  const bandB = b.id;

  let comparison = 0;
  if (bandA > bandB) {
    comparison = 1;
  } else if (bandA < bandB) {
    comparison = -1;
  }
  return comparison;
}

export function sortCreditCardsBtId(cards: AvailableCreditCard[]): AvailableCreditCard[] {
  return cards.sort(compareCreditCardsById);
}
