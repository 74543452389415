import { styled, css } from 'styles';
import { withTheme } from 'components/core';

const HeaderAction = styled('div')(
  ({
    theme: { colors, sizes, media, utils },
    mobile,
    tablet,
    desktop,
    smallDesktop,
    fluid,
    borderless,
    tag,
    disabled,
    onClick,
  }) => css`
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 3;
    ${onClick && { cursor: 'pointer' }};

    display: ${mobile ? 'flex' : 'none'};

    ${media.tablet`
      display: ${tablet ? 'flex' : 'none'};
    `};

    ${media.desktop`
      display: ${desktop ? 'flex' : 'none'};
    `};

    ${smallDesktop !== undefined &&
    media.smallDesktopOrSmaller`
        display: ${smallDesktop ? 'flex' : 'none'};   
    `}

    position: relative;
    width: ${fluid ? 'auto' : sizes.mobileHeaderHeight + 'px'};
    height: ${sizes.mobileHeaderHeight}px;
    border-left: 1px solid ${colors.blockBorder};
    ${borderless && `border-left: 0;`};
    ${disabled && `opacity: 0.3; cursor: default;`};
    ${fluid && `padding: 0 20px`};

    ${tag &&
    media.mobileOrSmaller`
      margin-right: 20px;
      width: auto;
      border-left: 0;

      .HeaderAction__icon {
        padding: 4px 10px;
        border-radius: 13px;
        border: 1px solid ${colors.blue};
      }
    `};

    ${media.tablet`
      margin-right: 0;
      width: ${fluid ? 'auto' : sizes.desktopHeaderHeight + 'px'};
      height: ${sizes.desktopHeaderHeight}px;
      ${
        !fluid &&
        css`
          flex: 0 0 ${sizes.desktopHeaderHeight}px;
        `
      }
    `};

    &:empty {
      display: none;
    }
  `
);

HeaderAction.displayName = 'HeaderAction';

export default withTheme(HeaderAction);
