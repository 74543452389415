import { styled, css } from 'styles';
import { withTheme } from 'components/core';
import hexToRgba from 'utils/hexToRgba';

type SidebarProps = {
  isMobileSidebarOpen: boolean;
};

export const Wrapper = withTheme(
  styled('div')<SidebarProps>(
    ({ theme: { media, colors }, isMobileSidebarOpen }) => css`
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;

      ${media.tabletOrSmaller`
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            background-color: ${hexToRgba(colors.text1, 0.3)};
          
            ${
              isMobileSidebarOpen
                ? css`
                    width: 100%;
                    visibility: visible;
                    opacity: 1;
                    transition:
                      visibility 0s linear 0s,
                      opacity 0.1s;
                  `
                : css`
                    visibility: hidden;
                    opacity: 0;
                    transition:
                      visibility 0s linear 0.3s,
                      opacity 0.3s;
                  `
            }
          }
        `}

      ${media.tabletOrSmaller`
        width: 100%;

        ${
          isMobileSidebarOpen
            ? css`
                visibility: visible;
                transition: visibility 0s linear 0s;
              `
            : css`
                visibility: hidden;
                transition: visibility 0s linear 0.3s;
              `
        }
      `};
    `
  )
);

export const Sidebar = withTheme(
  styled('div')<SidebarProps>(
    ({ theme: { colors, sizes, media }, isMobileSidebarOpen }) => css`
      display: flex;
      flex-direction: column;
      background: ${colors.text2};
      padding: 12px 10px;
      width: ${sizes.multiVenueSidebarWidth}px;
      height: 100vh;
      overflow: auto;
      transition: 0.2s ease-in;

      ${media.tabletOrSmaller`
        background: ${colors.white};
        box-shadow: 2px 0 4px 0 rgba(0,0,0,0.20);

        ${
          isMobileSidebarOpen
            ? css`
                transform: translateX(0%);
              `
            : css`
                transform: translateX(-100%);
              `
        }
      `};
    `
  )
);
