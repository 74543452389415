import 'whatwg-fetch';
import { RequestHandlerParams, HTTP_METHODS, ContentTypes, Payload, SearchParams } from './types';

export const handleRequestMethod = async <R, S extends SearchParams, P extends Payload>({
  method,
  path,
  body,
  mediaType = 'application/json',
  headers,
  searchParams,
  Authorization,
}: RequestHandlerParams<S, P>): Promise<R> => {
  const urlSearchParams = new URLSearchParams();

  if (searchParams) {
    for (const [key, value] of Object.entries(searchParams as Record<string, unknown>)) {
      // build queryString params from array
      // name=foo&name=bar&name=baz
      if (Array.isArray(value)) (value as ReadonlyArray<string>).map((v) => urlSearchParams.append(key + '[]', v));
      // build unique build queryString params
      else typeof value !== 'undefined' && urlSearchParams.append(key, value as string);
    }
  }

  const uri = method === HTTP_METHODS.GET ? `${path}?${urlSearchParams}` : path;

  // Build headers
  const requestHeaders = new Headers();
  requestHeaders.append('Accept-Language', _getLocale());
  requestHeaders.append('Content-Type', mediaType);

  if (headers) {
    /*
     * https://developer.mozilla.org/en-US/docs/Web/API/Headers/entries
     * The Headers.entries() method returns an iterator allowing to go through all key/value pairs contained in this object.
     * The both the key and value of each pairs are ByteString objects.
     * append('Content-Type', mediaType)
     */
    for (const keys of headers.entries()) {
      requestHeaders.append(keys[0], keys[1]);
    }
  }

  if (Authorization) _addAuthorizationHeader(requestHeaders, Authorization);

  const hasBody = method === HTTP_METHODS.PUT || method === HTTP_METHODS.POST;

  return window.fetch(uri, {
    method,
    headers: requestHeaders,
    ...(hasBody ? { body: _getBodyByMediaType(body || {}, mediaType) } : undefined),
  }) as unknown as Promise<R>;
};

const _getBodyByMediaType = (body: Record<string, unknown> | FormData, mediaType: ContentTypes): BodyInit => {
  if (body instanceof FormData && mediaType === 'multipart/form-data') return body;

  const content = { ...body };
  return JSON.stringify(content, (x, v) => (x !== '_ref' ? v : undefined));
};

/**
 * Create a valid path starting from a `TemplateStringsArray`.
 *
 * @example
 * ```ts
 * // apply to your function
 * const fn = (strings: TemplateStringsArray, ...args: (string | number)[]): string => buildPath(strings, args);
 *
 * // prints out as /path/with/1/variable/
 * const pathWithNumber = fn`/path/with/${1}/variable/`;
 * ```
 *
 * @param path - the strings part of the path.
 * @param args - the dynamic parts of the path.
 * @returns - a path built interpolating static strings and dynamic parts.
 */
export const buildPath = (path: TemplateStringsArray | string, args?: (string | number)[]): string => {
  if (args) {
    if (!Array.isArray(path)) return path.toString();
    return path.reduce((acc, currentString, index) => acc + currentString + (args[index] || ''), '');
  }

  return path.toString();
};

const _addAuthorizationHeader = (headers: Headers, authToken: string): void =>
  headers.append('Authorization', `${authToken}`);

const _getLocale = (): string => window.APP_LOCALE || window.navigator.userLanguage || window.navigator.language;
