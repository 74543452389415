import { styled, css, Theme, SerializedStyles, Colors, Contranst } from 'styles';
import { ButtonProps } from './index';

const colorStyles = ({
  theme: { colors, contrasts },
  basic,
  color,
}: Pick<ButtonProps, 'basic' | 'color'> & { theme: Theme }): SerializedStyles => {
  switch (color) {
    case 'primary':
      return basic
        ? css`
            color: ${colors.buttonPrimaryBackground};
            background-color: transparent;
            border-color: ${colors.buttonPrimaryBorder};
          `
        : css`
            color: ${colors.buttonPrimaryColor};
            background-color: ${colors.buttonPrimaryBackground};
            border-color: ${colors.buttonPrimaryBorder};
          `;
    case 'secondary':
      return basic
        ? css`
            color: ${colors.buttonSecondaryBackground};
            background-color: transparent;
            border-color: ${colors.buttonSecondaryBorder};
          `
        : css`
            color: ${colors.buttonSecondaryColor};
            background-color: ${colors.buttonSecondaryBackground};
            border-color: ${colors.buttonSecondaryBorder};
          `;
    case 'danger':
      return basic
        ? css`
            color: ${colors.buttonDangerBackground};
            background-color: transparent;
            border-color: ${colors.buttonDangerBorder};
          `
        : css`
            color: ${colors.buttonDangerColor};
            background-color: ${colors.buttonDangerBackground};
            border-color: ${colors.buttonDangerBorder};
          `;
    case 'unstyled':
      return css`
        border: 0;
        background-color: transparent;
      `;
    default:
      return basic
        ? css`
            color: ${colors[color as Colors] || colors.buttonPrimaryColor};
            background-color: transparent;
            border-color: ${colors[color as Colors] || colors.buttonPrimaryBorder};
          `
        : css`
            color: ${colors[contrasts[color as Contranst] || 'white']};
            background-color: ${colors[color as Colors] || colors.buttonPrimaryBackground};
            border-color: ${colors[color as Colors] || colors.buttonPrimaryBorder};
          `;
  }
};

const sizeStyles = ({
  size,
  circle,
  heightFitContent,
}: Pick<ButtonProps, 'size' | 'circle'> & { heightFitContent?: boolean }): SerializedStyles => {
  const getHeight = (height: string): string => (heightFitContent ? 'fit-content' : height);
  switch (size) {
    case 'small':
      return css`
        height: ${getHeight('24px')};
        ${circle && `width: 24px;`};
        padding: 4px 17px;
        border-radius: 17px;
        line-height: 14px;
      `;
    case 'medium':
      return css`
        height: ${getHeight('32px')};
        ${circle && `width: 32px;`};
        padding: 4px 17px;
        border-radius: 17px;
        line-height: 14px;
      `;
    case 'large':
      return css`
        height: ${getHeight('40px')};
        ${circle ? `width: 40px;` : `min-width: 300px;`};
        padding: 11px 19px;
        border-radius: 40px;
        line-height: 16px;
      `;
    default:
      return css`
        height: ${getHeight('40px')};
        ${circle && `width: 40px;`};
        padding: 11px 19px;
        border-radius: 40px;
        line-height: 16px;
      `;
  }
};

const shadowStyles = ({
  theme: { boxShadow },
  shadow,
}: Pick<ButtonProps, 'shadow'> & { theme: Theme }): SerializedStyles => {
  switch (shadow) {
    case 'light':
      return css`
        box-shadow: ${boxShadow.light};

        &:active {
          transform: translateY(1px);
          box-shadow: ${boxShadow.lightActive};
        }
      `;
    default:
      return css`
        box-shadow: ${boxShadow.default};

        &:active {
          transform: translateY(1px);
          box-shadow: ${boxShadow.defaultActive};
        }
      `;
  }
};

const Button = styled('button')(
  ({
    theme,
    block,
    relative,
    bottomFixed,
    width,
    minWidth,
    heightFitContent,
    fontSize,
    onClick,
    disabled,
    basic,
    primary,
    secondary,
    danger,
    unstyled,
    icon,
    color,
    size,
    squared,
    circle,
    shadow,
    loading,
  }: ButtonProps & { theme: Theme }) => css`
    box-sizing: border-box;
    outline: none;
    appearance: none;

    ${onClick && `cursor: pointer;`};

    ${icon &&
    `
      display: flex;
      justify-content: center;
    `};

    ${relative && `position: relative;`};

    ${bottomFixed &&
    `
      position:fixed;
      right: 25px;
      bottom: 25px;
    `};

    ${block
      ? css`
          display: block;
          width: 100%;
        `
      : width
      ? { width }
      : ''};

    ${minWidth && `min-width: ${minWidth};`}

    ${sizeStyles({ size, circle, heightFitContent })};

    font-size: ${(fontSize || 12) / 10}rem;

    border: 1px solid ${theme.colors.inputBorder};
    color: ${theme.colors.black};

    ${disabled &&
    css`
      background-color: lightgray;
      border-color: lightgray;
      opacity: 0.3;
      pointer-events: none;
    `};

    ${squared &&
    css`
      border-radius: 4px;
    `};

    ${circle &&
    css`
      padding-left: 0;
      padding-right: 0;
    `} ${shadow && shadowStyles({ shadow, theme })};

    ${primary
      ? colorStyles({ color: 'primary', basic, theme })
      : secondary
      ? colorStyles({ color: 'secondary', basic, theme })
      : danger
      ? colorStyles({ color: 'danger', basic, theme })
      : unstyled
      ? colorStyles({ color: 'unstyled', basic, theme })
      : color
      ? colorStyles({ color, basic, theme })
      : ''};

    .Icon + span,
    span + .Icon {
      margin-left: 12px;
    }

    ${loading &&
    `
      .Text, .Icon {
        opacity: 0;
      }
    `};
  `
);

export default Button;
