import PropTypes from 'prop-types';
import { INPUT_INLINE, INPUT_STACKED, INPUT_BLOCK } from '../config';

const propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelPosition: PropTypes.oneOf([INPUT_STACKED, INPUT_INLINE, INPUT_BLOCK]),
  helper: PropTypes.node,
  helperPosition: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  tooltip: PropTypes.bool,
  intl: PropTypes.bool,
};

export default propTypes;
