import { useHistory, useLocation } from 'react-router';
import { useCallback } from 'react';

export const ROUTE = {
  PRODUCT: '/warehouse_v2/product', // to be updated with `/warehouse/product` when the new product session will be released
} as const;

/**
 * @description - this layer will help us to migrate our code from react-router to an updated version
 * @see https://reactrouter.com/docs/en/v6/upgrading/v5#use-usenavigate-instead-of-usehistory
 *
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useRouter = () => {
  const history = useHistory();
  const location = useLocation();
  const push = useCallback((path: string) => history?.push(path), [history]);
  const replace = useCallback((path: string) => history?.replace(path), [history]);
  const go = useCallback((n: number) => history?.go(n), [history]);
  const goBack = useCallback(() => history?.goBack(), [history]);
  const goForward = useCallback(() => history?.goForward(), [history]);
  return {
    push,
    replace,
    go,
    goBack,
    goForward,
    location,
  };
};
