import { ReactNode, ReactElement } from 'react';
import * as S from './InputSelect.theme';
import { Box } from 'ui-treatwell-pro/theme';
import { Spinner } from 'components/warehouse_next/_partials/Spinner';
import { useDropDown } from 'ui-treatwell-pro/utils/useDropDown';
import { SelectOption } from 'ui-treatwell-pro/select';
import { Option, Options, SelectIcon, SelectIconType } from 'ui-treatwell-pro/select/Select';
import { Input } from 'ui-treatwell-pro';

export interface InputSelectProps<V, I extends ReactNode> {
  readonly name?: string;
  readonly isDisabled?: boolean;
  readonly isReadOnly?: boolean;
  readonly isInvalid?: boolean;
  readonly label?: string;
  readonly icon?: SelectIconType;
  readonly optionsLabel?: string;
  readonly options: Options<V, I>;
  readonly selectedOptions?: ReadonlyArray<Omit<Option<V, I>, 'optionLabel'>>;
  readonly width?: string | number;
  readonly dropDownHeight?: number;
  readonly errorMessage?: string;
  readonly onSelect: (selectedOption: Options<V, I>[number]) => void;
  readonly onInputChange: (x: string) => void;
  readonly isLoading?: boolean;
  readonly dataTestId?: string;
  readonly inputValue?: string;
  readonly EmptyDropdownItem?: ReactElement;
  readonly onClickOutside?: () => void;
}

const NothingFound = (): ReactElement => <div>Nothing found</div>;

export const InputSelect = <V extends string | number | undefined, I extends string | number | ReactNode>({
  name,
  isReadOnly,
  isInvalid,
  label,
  icon,
  width,
  errorMessage,
  onSelect,
  isLoading,
  options,
  selectedOptions,
  dataTestId,
  isDisabled = false,
  dropDownHeight = 250,
  onInputChange,
  inputValue,
  EmptyDropdownItem = <NothingFound />,
  onClickOutside,
  ...inputProps
}: InputSelectProps<V, I>): JSX.Element => {
  const { position, maxHeight, dropDownRef, toggleDropdown, dropdown } = useDropDown<HTMLDivElement>({
    dropDownHeight,
    onClickOutside: () => {
      if (dropdown.isOpen) onClickOutside?.();
    },
  });

  /**
   * @description check if the value of the input is already between the available options
   * */
  const isNewItem = (currentOptions: Options<V, I>, currentValue: string): boolean =>
    !currentOptions
      ?.map(({ optionLabel }) => optionLabel?.toString().toUpperCase())
      .includes(currentValue.toUpperCase());

  return (
    <>
      <Box
        onClick={() => !dropdown.isOpen && toggleDropdown()}
        ref={dropDownRef}
        display="flex"
        flexDirection="column"
        w="100%"
      >
        <S.SelectGroup width={width}>
          <SelectIcon placement="left" type={icon?.type} color={icon?.color} show={Boolean(icon)} />
          <S.SelectIcon placement="right" className="right-icon">
            {isLoading && <Spinner size={24} data-testid="spinner" />}
          </S.SelectIcon>
          <Input
            {...inputProps}
            isDisabled={isDisabled}
            label={label}
            width={width}
            value={inputValue}
            dataTestId={dataTestId}
            onChange={(x): void => {
              if (!dropdown.isOpen) toggleDropdown();
              onInputChange(x?.value as string);
            }}
            isInvalid={isInvalid}
          />
          <S.SelectDropdown
            id={`inputSelect-${name}`}
            width={width}
            position={position}
            maxHeight={maxHeight}
            data-testid="InputSelect-dropdown-scrollable"
          >
            {dropdown.isOpen && (
              <ul className="select__list" aria-label={name} data-testid="InputSelect-dropdown">
                {options?.map((x, index) => (
                  <SelectOption
                    selected={Boolean(options.find(({ value }) => value === inputValue))}
                    key={`${index}-option`}
                    icon={x.optionIcon}
                    onClick={(): void => {
                      onSelect(x);
                      toggleDropdown();
                    }}
                    data-testid={
                      options.find(({ value }) => value === inputValue)
                        ? 'InputSelect-option-selected'
                        : `InputSelect-option-${index}`
                    }
                  >
                    {x.optionLabel}
                  </SelectOption>
                ))}
                {isNewItem(options, String(inputValue)) ? (
                  <SelectOption
                    selected={false}
                    key="not-found-option"
                    onClick={toggleDropdown}
                    data-testid="InputSelect-option-not-found"
                  >
                    {EmptyDropdownItem}
                  </SelectOption>
                ) : null}
              </ul>
            )}
          </S.SelectDropdown>
        </S.SelectGroup>
        {isInvalid && errorMessage ? <S.ErrorMessage data-testid="error-message">{errorMessage}</S.ErrorMessage> : null}
      </Box>
    </>
  );
};
