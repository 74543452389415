import moment, { Moment, MomentInput } from 'moment';

enum DatePeriod {
  PAST = 'past',
  SAME_DAY = 'same_day',
  SAME_WEEK = 'same_week',
  SAME_MONTH = 'same_month',
  FUTURE = 'future',
}

export const DATE_FORMAT = {
  DayMonthYear: 'L',
  YearMonthDay: 'YYYY-MM-DD',
  YearMonthDayWithHours: 'YYYY-MM-DD HH:mm:ss',
  ExtendedLocale: 'LL',
};

export const APPOINTMENT_WINDOW_TITLE_FORMAT = 'ddd D - LT';

export const getDatePeriod = (date: string, reference_date?: string): DatePeriod | void => {
  const formatDate: string | undefined = date.split('T').shift();
  const formatReferenceDate: string | undefined = reference_date && reference_date.split('T').shift();

  const given_date = moment(formatDate);
  const reference = moment(formatReferenceDate);

  if (given_date.isBefore(reference, 'day')) {
    return DatePeriod.PAST;
  }

  if (given_date.isSame(reference, 'day')) {
    return DatePeriod.SAME_DAY;
  }

  if (given_date.format('W') === reference.format('W')) {
    return DatePeriod.SAME_WEEK;
  }

  if (given_date.format('M') === reference.format('M')) {
    return DatePeriod.SAME_MONTH;
  }

  if (given_date.isAfter(reference)) {
    return DatePeriod.FUTURE;
  }
};

export const getMinuteSlots = (
  timeSlot = 900
): ReadonlyArray<number> & {
  0?: '00';
} => {
  const slots = 3600 / timeSlot;
  const interval = 60 / slots;

  const init = new Array(Math.round(slots)).fill('00');

  init.forEach((value, key) => {
    if (key !== 0) {
      init[key] = parseInt(value, 10) + interval * key;
    }
  });

  return init;
};

export const getHoursRange = (): number[] => {
  return [...Array(24).keys()];
};

export const secondsToMoment = (duration: number): Moment => {
  return moment.utc(duration * 1000);
};

export const momentToSeconds = (duration: Moment): number => {
  return parseInt(duration.format('HH'), 10) * 3600 + parseInt(duration.format('mm'), 10) * 60;
};

export const getHoursDiffFromDate = (dateTo: MomentInput): number => {
  return moment.duration(moment(dateTo).diff(moment())).asHours();
};

const _7_DAYS = 7 * 24 * 60 * 60 * 1000;
export const getTodayPlus7DaysEndOfDay = () => {
  const day = new Date(Date.now() + _7_DAYS);
  day.setUTCHours(23);
  day.setUTCMinutes(59);
  day.setUTCSeconds(59);
  day.setUTCMilliseconds(999);
  return day;
};

export const compareSameDay = (dateToCheck: Date, actualDate: Date): boolean =>
  dateToCheck.getDate() === actualDate.getDate() &&
  dateToCheck.getMonth() === actualDate.getMonth() &&
  dateToCheck.getFullYear() === actualDate.getFullYear();

/**
 * Prettier accepts Typescript Type Literals since 2.2
 * Current Version 1.19.1
 * @see: <a href='https://github.com/prettier/eslint-plugin-prettier/issues/372#issuecomment-922557470'>Github Issue</a>
 */

/**
 *
 * Represents a string like `2021-01-08T14:42:34.678Z` (format: ISO 8601).
 * Also:
 * Represents a string like `2021-01-08T15:42:34+01:00` (used by uala b2b backend)
 *
 * @example ```ts
 *   const test1: ISOTime['DateTime'] = '2021-01-08T14:42:34.678Z'; // Approved
 *   const test2: ISOTime['DateTime'] = '2021-01-08T15:42:34+01:00'; // Approved
 *   const test3: ISOTime['DateTime'] = '2021-01-08'; // error
 *   console.log(test1, test2, test3);
 * ```
 *
 * @see {@link https://gist.github.com/MrChocolatine/367fb2a35d02f6175cc8ccb3d3a20054}
 */
export interface ISOTime {
  Year: `${number}${number}${number}${number}`;
  Month: `${number}${number}`;
  Day: `${number}${number}`;
  Hours: `${number}${number}`;
  Minutes: `${number}${number}`;
  Seconds: `${number}${number}`;
  Milliseconds: `${number}${number}${number}`;
  Date: `${ISOTime['Year']}-${ISOTime['Month']}-${ISOTime['Day']}`;
  Time:
    | `${ISOTime['Hours']}:${ISOTime['Minutes']}:${ISOTime['Seconds']}.${ISOTime['Milliseconds']}`
    | `${ISOTime['Hours']}:${ISOTime['Minutes']}:${ISOTime['Seconds']}`;
  Timezone: `Z` | `+${ISOTime['Hours']}:${ISOTime['Minutes']}` | `-${ISOTime['Hours']}:${ISOTime['Minutes']}`;
  TimeWithTimezone: `${ISOTime['Time']}${ISOTime['Timezone']}`;
  DateTime: `${ISOTime['Date']}T${ISOTime['TimeWithTimezone']}`;
  DateTimeNoTZ: `${ISOTime['Date']}T${ISOTime['Time']}`;
}

export interface HumanTime {
  Time: `${ISOTime['Hours']}:${ISOTime['Minutes']}`;
  Date: `${ISOTime['Day']}/${ISOTime['Month']}/${ISOTime['Year']}`;
}

export type ValidTime = Date | ISOTime['DateTime'] | ISOTime['DateTimeNoTZ'] | moment.Moment;
export type ValidDate = Date | ISOTime['DateTime'] | ISOTime['DateTimeNoTZ'] | moment.Moment;

type DateMatch = {
  day: ISOTime['Day'];
  month: ISOTime['Month'];
  year: ISOTime['Year'];
};

export interface DateUtils {
  toLocalHumanTimeTime: (time: ValidTime) => HumanTime['Time'];
  toLocalHumanTimeDate: (time: ValidTime) => HumanTime['Date'];
  toISOTimeDate: (date: ValidDate) => ISOTime['Date'];
  toISOTimeDateTime: (date: ValidDate) => ISOTime['DateTime'];

  humanTimeDateToDate: (humanTimeDate: HumanTime['Date']) => Date;
  humanTimeDateToISOTimeDate: (humanTimeDate: HumanTime['Date']) => ISOTime['Date'];

  toBeginningDate: (date: Date) => Date;
  toEndDate: (date: Date) => Date;
}

export const toLocalHumanTimeTime: DateUtils['toLocalHumanTimeTime'] = (time) =>
  moment(time).local().format('HH:mm') as HumanTime['Time'];

export const toLocalHumanTimeDate: DateUtils['toLocalHumanTimeDate'] = (date) =>
  moment(date).local().format('DD/MM/YYYY') as HumanTime['Date'];

export const toISOTimeDate: DateUtils['toISOTimeDate'] = (date) =>
  moment(date).local().format('YYYY-MM-DD') as ISOTime['Date'];
export const toISOTimeDateTime: DateUtils['toISOTimeDateTime'] = (date) =>
  moment(date).local().toISOString() as ISOTime['DateTime'];

export const humanTimeDateToISOTimeDate: DateUtils['humanTimeDateToISOTimeDate'] = (date) =>
  toISOTimeDate(humanTimeDateToDate(date));

export const toBeginningDate: DateUtils['toBeginningDate'] = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);

export const toEndDate: DateUtils['toEndDate'] = (date) =>
  new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);

export const humanTimeDateToDate: DateUtils['humanTimeDateToDate'] = (humanTimeDate) => {
  const match = humanTimeDate.match(/(?<day>[0-9]{2})\/(?<month>[0-9]{2})\/(?<year>[0-9]{4})/);
  if (!match || !match.groups)
    throw new Error(`humanTimeDateToDate: ${humanTimeDate} is not a valid HumanTime['Date']`);

  const groups = match.groups as DateMatch;
  return new Date(`${groups.year}-${groups.month}-${groups.day}T12:00:00Z`); // use 12 to avoid issues when translated to local;
};
