const APP_LOCALE = (): string => window.APP_LOCALE || window.navigator.userLanguage || window.navigator.language;

export const getCurrentDomain = (): string => {
  const url_parts = window.location.hostname.split('.');

  return url_parts[url_parts.length - 2];
};

export const getHelpdeskUrl = (): string => {
  const locale = APP_LOCALE();
  switch (locale) {
    case 'zh':
      return `https://help.treatwell.pro/zh-CN`;

    default:
      return `https://help.treatwell.pro/${locale}`;
  }
};
