import { ValueOf } from 'utils/utilityTypes';
import { ISOTime } from 'utils/dates';
import { Customer } from './customer';
import { StaffMember } from './staffMember';
import { StaffMemberTreatment } from './staffMemberTreatment';
import { VenueTreatment } from './venueTreatment';

export enum AppointmentState {
  REQUESTED = 'requested',
  BOOKED = 'booked',
  CHECKED_IN = 'checked_in',
  CHECKED_OUT = 'checked_out',
  DELETED = 'deleted',
  ARCHIVED = 'archived',
  CANCELED = 'canceled',
  CANCELED_ALL = 'canceled_all',
  MISSED = 'missed',
  DISCARDED = 'discarded',
}

export const SourceMarketplace = {
  UALA: 'uala',
  TREATWELL: 'treatwell',
} as const;

export const CancellationprotectionStatus = {
  REQUESTED: 'requested',
  CHARGED: 'charged',
  REJECTED: 'rejected',
  NOT_CHARGED: 'not_charged',
  RECEIPT_ISSUED: 'receipt_issued',
} as const;

export const CancellationProtectionSetupStatus = {
  NOT_CONFIGURED: 'not_configured',
  PENDING: 'pending',
  CONFIRMED: 'confirmed',
} as const;

export interface Appointment {
  id: number;
  parent_id: number;
  booking_token: number;
  customer: Customer;
  customer_id: number;
  customer_full_name: string | null;
  customer_phone_number: string | null;
  staff_member_id: number;
  staff_member_treatment_id: number;
  workstation_id: number;
  checkout_id: number | null;
  time: ISOTime['DateTime'];
  time_no_tz: ISOTime['DateTimeNoTZ'];
  time_datepart?: ISOTime['Date'];
  custom_duration?: number;
  duration?: number;
  extra_time_staff_member_after: number | null;
  extra_time_workstation_after: number | null;
  cherry_picked_staff_member?: boolean;
  notes?: string;
  state: AppointmentState;
  checked_in_at: string;
  checked_out_at: string;
  deleted_at?: string;
  by_venue: boolean;
  'YYYY-MM-DD': string;
  calculated_duration?: number;
  calculated_extra_time_after?: number;
  always_apply_extra_time_after?: boolean;
  remote_id?: number;
  remote?: Nullable<Appointment>;
  parent_appointment: Appointment | null;
  children_appointments: Appointment[];
  data: {
    venue_treatment?: VenueTreatment;
    staff_member?: StaffMember;
    staff_member_treatment?: StaffMemberTreatment;
    custom_price: number | null;
  };
  appointment_marketplace_detail?: Nullable<{
    fee_percent_amount: number;
  }>;
  total_price?: number;
  discount_type?: 'percent' | 'absolute';
  discount_percent_amount?: number | null;
  discount_absolute_amount?: number | null;
  discounted_price?: number;
  paid_online: boolean;

  pendingCancel?: number;
  size?: number;
  rowValue?: number;
  extraTimeSize?: number;
  look?: {
    media_attachments: [];
  };
  source_marketplace?: keyof typeof SourceMarketplace;

  rating?: number;
  cancel_charge_requested?: boolean;

  external_id?: number;
  unsatisfied?: boolean;
  proposed_by_staff_member?: boolean;
  canceled_by_venue?: boolean;

  created_at: ISOTime['DateTime'];

  payment_protection_enabled: boolean;
  cancellation_protection: {
    id?: number;
    stripe_setup_intent_confirmed: boolean;
    max_chargeable_amount_cents?: number;
    charge_status: ValueOf<typeof CancellationprotectionStatus> | null;
    charged_amount_cents?: number;
    charged_at?: string | null;
    checkout_id?: number | null;
    setup_status: ValueOf<typeof CancellationProtectionSetupStatus>;
    send_setup_sms?: boolean;
    sms_sent_at?: ISOTime['DateTime'];
    stripe_client_secret?: string | null;
    setup_intent_confirmed?: boolean;
  };
  __isLocal?: boolean;
  patch_test_requested_at?: string | null;
  patch_test_performed_at?: string | null;
  additional_appointments?: Appointment[];
}

export interface AppointmentPostData {
  customer_id: number;
  staff_member_id: number;
  staff_member_treatment_id: number;
  workstation_id?: number;
  time?: string;
  custom_duration?: number;
  notes?: string;

  children_appointments?: AppointmentPostData[];
  additional_appointments?: AppointmentPostData[];
}

export const appointmentPostDataFromAppointment = (appointments: Appointment[]): AppointmentPostData | null => {
  const mainAppointment = appointments && appointments[0];
  if (!mainAppointment) {
    return null;
  }

  const {
    time,
    customer_id,
    staff_member_id,
    staff_member_treatment_id,
    workstation_id,
    notes,
    custom_duration,
    children_appointments,
  } = mainAppointment;

  const postData: AppointmentPostData = {
    time,
    customer_id,
    staff_member_id,
    staff_member_treatment_id,
    workstation_id,
    notes,
    custom_duration,
  };

  /**
   * This is ONLY for appointment edit
   */
  if (mainAppointment.state !== 'requested') {
    return postData;
  }

  /**
   * This is for multi_book
   */
  if (children_appointments && children_appointments.length > 0) {
    postData.children_appointments = children_appointments;
  }

  if (appointments.length > 1) {
    postData.additional_appointments = appointments
      .filter((_appo, k) => k)
      .map((additional_appointment) => appointmentPostDataFromAppointment([additional_appointment]))
      .filter((appo) => appo !== null) as AppointmentPostData[];
  }

  return postData;
};
