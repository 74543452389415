/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';
import { VenueKYCVerificationStripeOnboardingResponse, VenueKYCVerificationStripeOnboardingPayload } from 'API/types';
import { V } from 'API';

export const KYC_VERIFICATION_STRIPE_ONBOARDING =
  ({ variables }: V<VenueKYCVerificationStripeOnboardingPayload>) =>
  (): Promise<VenueKYCVerificationStripeOnboardingResponse> =>
    Http()
      .post<VenueKYCVerificationStripeOnboardingResponse, never, VenueKYCVerificationStripeOnboardingPayload>(
        ENDPOINT.KYC_VERIFICATION._STRIPE_ONBOARDING()
      )({
        body: { ...variables },
      })
      .then(maybeResponseToJSON);
