import { styled, css } from 'styles';
import TailSpin from 'svg/tail-spin';
import { withTheme } from 'components/core';

const TailSpinner = withTheme(
  styled('div')(
    ({ theme: { colors }, isLoading, centered, size, color }) => css`
      display: block;
      width: ${size || 40}px;
      height: ${size || 40}px;
      opacity: 0;
      transform: scale(0);

      path,
      circle {
        fill: ${color ? colors[color] : 'currentColor'};
      }

      ${isLoading &&
      `
      opacity: 1;
      transform: scale(1);
    `};

      ${centered &&
      `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: scale(${isLoading ? 1 : 0}) translateX(-50%) translateY(-50%);
    `};
    `
  )
);

const spinnerFactory = (props) => (
  <TailSpinner {...props}>
    <TailSpin />
  </TailSpinner>
);

export default spinnerFactory;
