import { captureMessage, Severity } from '@sentry/react';

const overrideDuplicatesByKey = <A>(key: keyof A, arr: ReadonlyArray<A>): ReadonlyArray<A> =>
  Array.from(
    arr
      .reduce((acc, item, index) => {
        if (item && item[key]) {
          acc.set(item[key], item);
        } else {
          if (index === 0) {
            captureMessage(
              `Invalid usage of overrideDuplicatesByKey: objects in array should have a valid ${String(key)}`,
              Severity.Warning
            );
          }
          acc.set(0, item);
        }

        return acc;
      }, new Map())
      .values()
  );

export default overrideDuplicatesByKey;
