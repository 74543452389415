export interface TooltipState {
  readonly isVisible: boolean;
  readonly top: number;
  readonly left: number;
  readonly content: string;
  readonly width?: string;
  readonly color?: string;
  readonly defaultPosition?: string;
}

const TOOLTIP_ACTION_TYPES = {
  SHOW: '@tooltip/SHOW',
  UPDATE_TOOLTIP: '@tooltip/UPDATE',
  HIDE: '@tooltip/HIDE',
} as const;

type Show = {
  readonly type: typeof TOOLTIP_ACTION_TYPES.SHOW;
};

const show = (): Show => ({
  type: TOOLTIP_ACTION_TYPES.SHOW,
});

type Hide = {
  readonly type: typeof TOOLTIP_ACTION_TYPES.HIDE;
};

const hide = (): Hide => ({
  type: TOOLTIP_ACTION_TYPES.HIDE,
});

type Update = {
  readonly type: typeof TOOLTIP_ACTION_TYPES.UPDATE_TOOLTIP;
  readonly payload: Partial<TooltipState>;
};

const update = (payload: Exclude<Partial<TooltipState>, 'isVisible'>): Update => ({
  type: TOOLTIP_ACTION_TYPES.UPDATE_TOOLTIP,
  payload,
});

type TooltipActions = Show | Hide | Update;

const initialState: TooltipState = {
  top: 0,
  left: 0,
  content: '',
  isVisible: false,
};

const tooltipReducer = (state: TooltipState, action: TooltipActions): TooltipState => {
  switch (action.type) {
    case TOOLTIP_ACTION_TYPES.SHOW:
      return { ...state, isVisible: true };
    case TOOLTIP_ACTION_TYPES.HIDE:
      return { ...state, isVisible: false };
    case TOOLTIP_ACTION_TYPES.UPDATE_TOOLTIP:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default tooltipReducer;
export { initialState, show, hide, update };
