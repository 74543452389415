import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Heading, Text, Spinner, Icon, Box } from 'components/core';
import { Wrapper, Scroller, Action, Dropdown, Item, ActionClear } from './theme';
import { createSelector } from 'reselect';
import toTitleCase from 'utils/stringToTitleCase';

const CREATE_NEW_ITEM_POSITION = {
  INLINE: 'inline',
  AS_MENU_ITEM: 'as-menu-item',
};
class ModelDropdown extends React.Component {
  state = {
    query: '',
    isComposing: undefined,
    hovered: 0,
  };

  constructor(props) {
    super(props);

    /**
     * calculate filteredItems only if query or itemsList change
     */
    this.getFilteredItemsSelector = createSelector(
      (state, props) => (state.query || '').toLowerCase().trim(),
      (state, props) => props.itemsList,
      (lowerCaseQuery, items) => {
        const queryPieces = lowerCaseQuery.split(' ');
        const { itemMatch, itemMap, itemSort } = this.props;
        return (items || [])
          .filter(itemMatch.bind(null, { lowerCaseQuery, queryPieces }))
          .sort(itemSort.bind(null, { lowerCaseQuery }))
          .slice(0, 20)
          .map(itemMap.bind(null, { lowerCaseQuery, queryPieces }));
      }
    );
  }

  componentDidMount() {
    this._mounted = true;
    if (this._input && this.props.autofocus) this._input.focus();
  }

  componentWillUnmount() {
    this._mounted = false;
    clearTimeout(this.timer);
  }

  onStateChange = () => {
    this.props.onStateChange({
      editing: this.state.reserved || (this.state.focused && this.state.changed) ? true : false,
      noResults: this.isQueryValid() && this.getFilteredItems().length <= 0,
      isQueryValid: this.isQueryValid(),
      hasFocus: this.state.focused,
    });
  };

  onFocus = () => {
    this.setState(
      { focused: true, editing: true, reserved: false, query: this.props.value || '', changed: false },
      this.onStateChange
    );
  };

  onBlur = () => {
    const filteredItems = this.getFilteredItems();
    const hovered = this.state.hovered || 0;
    if (
      !this.props.skipFilteredItems &&
      !this.state.reserved &&
      this.props.selectFirstOnBlur &&
      filteredItems[hovered]
    ) {
      this.setState({ focused: false, editing: false }, () => {
        this.onSelect(filteredItems[hovered]);
      });
    } else if (
      !this.state.reserved &&
      this.state.query &&
      this.state.changed &&
      this.props.createNewItemOnBlur &&
      this.props.onCreateNewItem
    ) {
      this.setState({ focused: false, editing: false }, () => {
        this.onCreateNewItem({ query: this.state.query, eventType: 'blur' });
      });
    } else {
      this.setState({ focused: false, editing: false }, this.onStateChange);
    }
  };

  onKeyPress = (event) => {
    if (event.key === 'Enter' && this.props.selectFirstOnSubmit) {
      clearTimeout(this.timer);

      const filteredItems = this.getFilteredItems();
      const hovered = this.state.hovered || 0;
      if (filteredItems[hovered] && !this.props.skipFilteredItems) {
        this.setState({ editing: false, changed: false }, () => {
          this.onSelect(filteredItems[hovered]);
        });
      } else if (
        this.state.query &&
        this.state.changed &&
        this.props.createNewItemOnSubmit &&
        this.props.onCreateNewItem
      ) {
        this.setState({ editing: false, changed: false }, () => {
          this.onCreateNewItem({ query: this.state.query });
        });
      }
    }
  };

  onKeyUp = (event) => {
    // FIXME: the proper way to that would be using `compositionstart` and `compositionend` events.
    // Unfortunately, this is not currently possible because `compositionend` is not triggered
    // @see: https://github.com/facebook/react/issues/20065
    const { isComposing } = event.nativeEvent;
    if (typeof this.state.isComposing === 'undefined') {
      this.setState({ isComposing });
    }
  };

  onKeyDown = (event) => {
    if (event.keyCode === 38) {
      event.preventDefault();
      this.setState(({ hovered }) => ({ hovered: Math.max(hovered - 1, 0) }));
    } else if (event.keyCode === 40) {
      event.preventDefault();
      this.setState(({ hovered }) => ({ hovered: Math.min(hovered + 1, this.getFilteredItems().length - 1) }));
    }
  };

  onSelect = (item) => {
    this.setState({ reserved: false, changed: false }, this.onStateChange);
    this.props.onSelect(item);
  };

  onCreateNewItem = ({ query, eventType = 'submit' }) => {
    this.setState({ reserved: false }, this.onStateChange);
    this.props.onCreateNewItem({ query, eventType });
  };

  onQueryChange = (e) => {
    let query =
      this.props.queryFilter && !this.state.isComposing ? this.props.queryFilter(e.target.value) : e.target.value;
    if (this.props.camelCase) {
      query = toTitleCase(query);
    }

    this.setState({ query, editing: true, changed: true, hovered: 0 }, this.onStateChange);

    clearTimeout(this.timer);

    if (this.props.immediateOnQueryChange) {
      if (this.props.onQueryChange) {
        this.props.onQueryChange({ query });
      }
    }

    this.timer = setTimeout(() => {
      if (this.props.createNewItemOnQueryChange && this.props.onCreateNewItem) {
        if (this._mounted) {
          this.props.onCreateNewItem({ query });
        }
      }

      if (!this.props.immediateOnQueryChange && this.props.onQueryChange) {
        if (this._mounted) {
          this.props.onQueryChange({ query });
        }
      }
    }, 200);
  };

  onQueryDelete = (e) => {
    this.setState({ reserved: false }, () => {
      this._input.focus();
      this.setState({ query: '', changed: true, hovered: 0, clearingOnFocus: false });
      if (this.props.onQueryDelete) {
        this.props.onQueryDelete();
      }
    });
  };

  onHoverItem = (hovered) => {
    this.setState({ hovered });
  };

  getFilteredItems = () => {
    if (!this.isActive() || !this.isQueryValid()) return [];
    return this.getFilteredItemsSelector(this.state, this.props);
  };

  showCreateNewItemButton = () => this.props.onCreateNewItem && this.props.createNewItemOnSubmit && this.state.changed;

  isActive = () => this.state.editing || this.state.reserved;

  isQueryValid = () => this.state.query.length >= this.props.minQueryLength;

  shouldShowClearButtonOnFocus = () => {
    const { changed, focused, clearingOnFocus, query } = this.state;
    const { showClearButtonOnFocus } = this.props;
    return Boolean(
      (!changed && focused && query && showClearButtonOnFocus) || (clearingOnFocus && showClearButtonOnFocus)
    );
  };

  render() {
    const { editing, reserved, query, changed, hovered } = this.state;
    const {
      createNewItemPosition,
      itemsLoading,
      value,
      itemRenderer,
      placeholder,
      readOnly,
      hideNoResults,
      selectFirstOnSubmit,
      noMargin,
      style,
      noBorder,
      dataTestid,
    } = this.props;
    const filteredItems = this.getFilteredItems();
    const validChanges = Boolean(this.isActive() && this.isQueryValid() && changed);
    const isInlineCreate = createNewItemPosition === CREATE_NEW_ITEM_POSITION.INLINE;

    return (
      <Wrapper>
        <Dropdown
          open={(editing && this.isQueryValid() && changed) || reserved}
          hideNoResults={hideNoResults && !(this.showCreateNewItemButton() && !isInlineCreate)}
          resultsCount={filteredItems.length}
          noMargin={noMargin}
          noBorder={noBorder}
        >
          <div>
            {itemsLoading ? (
              <Spinner centered loading />
            ) : (
              <Scroller>
                {filteredItems.length ? (
                  filteredItems.map((item, k) => (
                    <Item
                      data-testid="search-result"
                      hovered={hovered === k && selectFirstOnSubmit}
                      key={item.id}
                      onMouseEnter={() => this.onHoverItem(k)}
                      onMouseDown={() => this.setState({ reserved: true })}
                      onClick={() => this.onSelect(item)}
                    >
                      {itemRenderer(item, this.props)}
                    </Item>
                  ))
                ) : hideNoResults ? null : (
                  <Item>
                    <Text color="text2" size={14} intl="no.results.for" intlValues={{ query }} />
                  </Item>
                )}
                {this.showCreateNewItemButton() && !isInlineCreate && !itemsLoading && (
                  <Item
                    data-testid="create-new-menu"
                    onMouseDown={() => this.setState({ reserved: true })}
                    onClick={this.onCreateNewItem.bind(this, { query })}
                  >
                    <Box display="flex" alignItems="center">
                      <Icon type="plus-circle" color="blue" size={14} />
                      <Box mR="5px" />
                      <Text color="blue" size={14} intl="create_new_item" intlValues={{ item: query }} />
                    </Box>
                  </Item>
                )}
              </Scroller>
            )}
          </div>
        </Dropdown>
        <FormattedMessage id={placeholder}>
          {(placeholder) => (
            <Heading
              as="input"
              style={style}
              data-testid={dataTestid}
              {...(readOnly
                ? {
                    readOnly: true,
                  }
                : {
                    onFocus: this.onFocus,
                    onBlur: this.onBlur,
                    onKeyUp: this.onKeyUp,
                    onKeyPress: this.onKeyPress,
                    onKeyDown: this.onKeyDown,
                    onChange: this.onQueryChange,
                  })}
              ref={(ref) => (this._input = ref)}
              placeholder={placeholder}
              value={this.isActive() ? query : value || ''}
              disabled={this.props.disabled}
            />
          )}
        </FormattedMessage>
        {validChanges &&
          (this.showCreateNewItemButton() && isInlineCreate ? (
            <>
              <ActionClear onMouseDown={() => this.setState({ reserved: true })} onClick={this.onQueryDelete}>
                <Icon type="clear" fillMode="filled" color="inputPlaceHolder" size={14} />
              </ActionClear>
              <Action
                onMouseDown={() => this.setState({ reserved: true })}
                onClick={this.onCreateNewItem.bind(this, { query })}
              >
                <Icon type="plus" color="blue" size={14} data-testid={`${dataTestid}-add-product-button`} />
              </Action>
            </>
          ) : !this.shouldShowClearButtonOnFocus() ? (
            <Action
              onMouseDown={() => this.setState({ reserved: true })}
              onClick={this.onQueryDelete}
              className="ModelDropdownAction__clear"
            >
              <Icon type="clear" fillMode="filled" color="inputPlaceHolder" size={14} />
            </Action>
          ) : null)}
        {this.shouldShowClearButtonOnFocus() && (
          <Action
            onMouseDown={() => this.setState({ clearingOnFocus: true })}
            onClick={this.onQueryDelete}
            className="ModelDropdownAction__clear-on-focus"
          >
            <Icon type="clear" fillMode="filled" color="inputPlaceHolder" size={14} />
          </Action>
        )}
      </Wrapper>
    );
  }
}

ModelDropdown.defaultProps = {
  value: '',
  minQueryLength: 3,
  selectFirstOnSubmit: false,
  placeholder: 'Search',
  readOnly: false,
  itemsList: [],
  itemsLoading: false,
  onStateChange: () => {},
  onSelect: () => {},
  createNewItemOnBlur: false,
  createNewItemOnSubmit: false,
  createNewItemOnQueryChange: false,
  createNewItemPosition: CREATE_NEW_ITEM_POSITION.INLINE,
  noMargin: false,

  itemMatch: () => true,
  itemMap: (options, item) => item,
  itemSort: (options, item) => item,
  itemRenderer: (item) => item.id,
};

ModelDropdown.propTypes = {
  minQueryLength: PropTypes.number,
  selectFirstOnSubmit: PropTypes.bool,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  itemsList: PropTypes.array,
  itemsLoading: PropTypes.bool,
  immediateOnQueryChange: PropTypes.bool,
  onStateChange: PropTypes.func,
  onSelect: PropTypes.func,
  onCreateNewItem: PropTypes.func,
  createNewItemPosition: PropTypes.oneOf([CREATE_NEW_ITEM_POSITION.AS_MENU_ITEM, CREATE_NEW_ITEM_POSITION.INLINE]),
  createNewItemOnBlur: PropTypes.bool,
  createNewItemOnSubmit: PropTypes.bool,
  createNewItemOnQueryChange: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default ModelDropdown;
export { CREATE_NEW_ITEM_POSITION };
