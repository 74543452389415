import { css, styled, withTheme } from 'styles';
import Field, { fieldSizes } from 'components/core/Field/theme';
import { TextInputPropsStyle } from './index';

const sizeStyles = {
  default: css`
    padding: 5px 15px;

    > input,
    > .input,
    > .TextInput__prefix {
      height: ${fieldSizes.innerHeight.default() - 10}px;
      line-height: ${fieldSizes.innerHeight.default() - 10}px;
      ${fieldSizes.font.default};
    }

    .TextInput__options {
      padding-top: ${fieldSizes.innerHeight.default() / 2}px;
      border-radius: 0 0 ${fieldSizes.innerHeight.default() / 2}px ${fieldSizes.innerHeight.default() / 2}px;

      .TextInput__options-option {
        padding: 8px 15px;
        ${fieldSizes.font.default};
      }
    }
  `,
  small: css`
    padding: 5px 15px;

    > input,
    > .input,
    > .TextInput__prefix {
      height: ${fieldSizes.innerHeight.small() - 10}px;
      line-height: ${fieldSizes.innerHeight.small() - 10}px;
      ${fieldSizes.font.small};
    }

    .TextInput__options {
      padding-top: ${fieldSizes.innerHeight.small() / 2}px;
      border-radius: 0 0 ${fieldSizes.innerHeight.small() / 2}px ${fieldSizes.innerHeight.small() / 2}px;

      .TextInput__options-option {
        padding: 8px 15px;
        ${fieldSizes.font.small};
      }
    }
  `,
  large: css`
    padding: 6px 20px;
    display: flex;

    > input,
    > .input,
    > .TextInput__prefix {
      height: ${fieldSizes.innerHeight.large() - 12}px;
      line-height: ${fieldSizes.innerHeight.large() - 12}px;
      ${fieldSizes.font.large};
    }

    > .TextInput__prefix {
      width: min-content !important;
      position: relative;
      left: -6px;
    }

    > .TextInput__prefix span {
      display: none;
    }

    > .TextInput__prefix div {
      position: relative;
      z-index: 1;
      left: 0px;
    }

    .TextInput__options {
      padding-top: ${fieldSizes.innerHeight.large() / 2}px;
      border-radius: 0 0 ${fieldSizes.innerHeight.large() / 2}px ${fieldSizes.innerHeight.large() / 2}px;

      .TextInput__options-option {
        padding: 10px 20px;
        ${fieldSizes.font.large};
      }
    }
  `,
};

export const TextInput = withTheme(
  styled(Field)<TextInputPropsStyle & { showOptions: boolean }>(
    ({
      type,
      size,
      readOnly,
      icon,
      options,
      fontSize,
      error,
      textAlign,
      showOptions,
      theme: { colors, utils, boxShadow },
    }) => css`
      box-sizing: border-box;
      ${(size && sizeStyles[size]) || sizeStyles.default};
      background-color: ${colors.bgWhite};
      ${showOptions && `position: relative; z-index: 1;`};
      ${readOnly && `border: 1px solid transparent;`};
      ${icon &&
      (icon.position === 'left'
        ? `padding-left: ${(icon.margin || 0) + 22}px;`
        : `padding-right: ${(icon.margin || 0) + 32}px;`)};

      ${error && `border-color: ${colors.red};`}
      > input,
      > .input {
        position: relative;
        ${showOptions && `z-index: 1;`};
        display: block;
        margin: 0;
        padding: 0;
        width: ${options ? 'calc(100% - 12px)' : '100%'};
        border: 0;
        appearance: none;
        outline: none;
        background-color: 'transparent';
        ${(type === 'number' || type === 'percent') && { textAlign: 'right' }};
        font-family: ${utils.fontFamily};
        ${fontSize && `font-size: ${fontSize}px;`};
        ${textAlign && `text-align: ${textAlign}`};
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &::-ms-clear {
          display: none;
        }

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }

      > .TextInput__prefix {
        width: 100%;
        height: 0 !important;
        color: ${colors.text2};
        ${(type === 'number' || type === 'percent') && { textAlign: 'right' }};
        ${fontSize && `font-size: ${fontSize}px;`};

        > span {
          margin-left: 3px;
          color: transparent;
        }

        + input {
          padding-left: 0;
        }
      }

      > input::placeholder {
        color: ${error ? colors.red : colors.inputPlaceHolder};
      }

      ${(icon || options) &&
      `
        .Icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          ${icon && icon.position === 'left' ? 'left: 0;' : 'right: 0;'};
          margin: 0 ${(icon && (icon.margin || 0)) || '12'}px;
          color: ${error ? colors.red : 'inherit'}
        }
      `};

      .TextInput__options {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 -1px;
        max-height: 150px;
        overflow-y: auto;
        background-color: ${colors.bgWhite};
        box-shadow: ${boxShadow.default};
        border: 1px solid ${colors.inputBorder};
        border-top: 0;

        .Spinner {
          display: block;
          margin: 0 auto;
        }

        .TextInput__options-option {
          cursor: pointer;
          ${(type === 'number' || type === 'percent') && { textAlign: 'right' }};

          &:hover {
            color: ${colors.buttonPrimaryBackground};
          }
        }
      }
    `
  )
);
