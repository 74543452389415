import { FunctionComponent, ReactElement, useCallback, useMemo, useState } from 'react';
import ResourceCategory from './PromoFlashEditResourceCategory';
import { Icon, Section, Text } from 'components/core';
import { InputText } from 'components/ui-elements/form';
import useThrottledCallback from '../../utils/hooks/useThrottledCallback';

interface VenueTreatmentCategory {
  id: number;
  name: string;
}

type ResourceCategoryListPropTypes = {
  treatmentsCategories: Array<VenueTreatmentCategory>;
  treatments: Array<unknown>;
  treatmentsFilter?: string;
  promoFlash: unknown;
  setLocalChanges: (args: { included_resources_attributes: Record<string, unknown> }) => void;
};

const ResourceCategoryList: FunctionComponent<ResourceCategoryListPropTypes> = ({
  treatmentsCategories,
  treatmentsFilter,
  treatments,
  promoFlash,
  setLocalChanges,
}: ResourceCategoryListPropTypes): ReactElement => {
  const [matchesTable, setMatchesTable] = useState({});

  const handleTreatmentsFilterChange = useCallback((categoryId, matches) => {
    setMatchesTable((prevState) => ({ ...prevState, [categoryId]: matches }));
  }, []);

  const matchesValues = Object.values(matchesTable);
  const noMatches = matchesValues.filter((value) => value === 0);

  return (
    <>
      {noMatches.length === matchesValues.length && (
        <Text intl="no.results.for" intlValues={{ query: treatmentsFilter }} size={12} />
      )}
      {treatmentsCategories.map((category) => (
        <ResourceCategory
          key={category.id}
          treatmentsFilter={treatmentsFilter}
          category={category}
          venueTreatmentsList={treatments}
          promoFlash={promoFlash}
          setLocalChanges={setLocalChanges}
          onTreatmentsFilterChange={handleTreatmentsFilterChange}
        />
      ))}
    </>
  );
};

type PromoFlashEditFilterableListPropTypes = {
  searchPlaceholder: string;
};

const PromoFlashEditFilterableList: FunctionComponent<
  PromoFlashEditFilterableListPropTypes & ResourceCategoryListPropTypes
> = ({ searchPlaceholder, promoFlash, treatments, treatmentsCategories, setLocalChanges }): ReactElement => {
  const [treatmentsFilter, setTreatmentsFilter] = useState('');
  const handleSearchTreatment = useThrottledCallback((_name: string, value: string) => {
    setTreatmentsFilter(value);
  });

  const cachedResourceCategoryList = useMemo(
    () => (
      <ResourceCategoryList
        promoFlash={promoFlash}
        treatments={treatments}
        treatmentsCategories={treatmentsCategories}
        treatmentsFilter={treatmentsFilter}
        setLocalChanges={setLocalChanges}
      />
    ),
    [promoFlash, treatments, treatmentsCategories, setLocalChanges, treatmentsFilter]
  );

  return (
    <Section displayFlex alignItems="center" paddingBottom={1.5} paddingTop={1.5} flexDirection="column">
      <Text flex={1} size={12} color="text2" intl="manager.services" uppercase spacing={1.2} />
      <Section marginTop={1.5} paddingLeft={3.125} paddingRight={3.125} width="100%">
        <Section marginBottom={1.5} width={215}>
          <InputText
            name="treatmentCategoriesSearch"
            size="sm"
            placeholder={searchPlaceholder}
            extraAdornment={{ suffix: <Icon type="search" color="text2" />, hideSeparator: true }}
            onChange={handleSearchTreatment}
          />
        </Section>
        {cachedResourceCategoryList}
      </Section>
    </Section>
  );
};

export default PromoFlashEditFilterableList;
