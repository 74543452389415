import { styled, css, keyframes } from 'styles';
import { withTheme } from 'components/core';
import hexToRgba from 'utils/hexToRgba';
// eslint-disable-next-line no-restricted-imports
import { theme } from 'styles';

const pulseDot = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${hexToRgba(theme.colors.red, 0.7)};
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px ${hexToRgba(theme.colors.red, 0)};
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 ${hexToRgba(theme.colors.red, 0)};
  }
`;

export const NotificationIconAlert = withTheme(
  styled('div')(
    ({ theme: { colors } }) => css`
      position: absolute;
      top: 0;
      right: -4px;
      height: 9px;
      width: 9px;
      background-color: ${colors.red};
      border-radius: 50%;
      z-index: 1;
      transform: scale(1);
      background: red;
      box-shadow: 0 0 0 0 ${colors.red};
      animation: ${pulseDot} 2s infinite;
    `
  )
);

export const NotificationIconWrapper = styled('div')`
  position: relative;
  display: inline-block;
`;
