import { COLORS } from './colors';

const mainFontFamily = `
  font-family: Lato;
`;

export const mainFontRegular = `
  ${mainFontFamily};
  font-weight: 400;
`;

export const mainFontBold = `
  ${mainFontFamily};
  font-weight: 700;
`;

export const FONTS = {
  mainFontFamily,
  mainFontRegular,
  mainFontBold,

  body: `
    ${mainFontRegular};
    font-size: 10px;
  `,

  headerTitle: `
    ${mainFontBold};
    color: ${COLORS.text1};
    font-size: 12px;
    text-transform: uppercase;
  `,

  sectionTitle: `
  ${mainFontRegular};
  color: ${COLORS.text1};
  font-size: 10px;
  text-transform: uppercase;
  `,

  txtHeader1: `
    ${mainFontBold};
    color: ${COLORS.black};
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
  `,

  txtHeader2: `
    ${mainFontRegular};
    color: ${COLORS.text1};
    font-size: 10px;
    text-transform: uppercase;
  `,

  txtHeader3: `
    ${mainFontBold};
    color: ${COLORS.inputPlaceHolder};
    font-size: 10px;
    text-transform: uppercase;
  `,

  txtMain1: `
    ${mainFontRegular};
    font-size: 18px;
    color: ${COLORS.text1};
  `,

  txtMain2: `
    ${mainFontRegular};
    font-size: 16px;
    color: ${COLORS.text1};
  `,

  txtMain3: `
    ${mainFontRegular};
    font-size: 14px;
    color: ${COLORS.text1};
  `,

  txtMain4: `
    ${mainFontRegular};
    font-size: 12px;
    color: ${COLORS.text1};
  `,

  txtSelectLg: `
    ${mainFontRegular};
    font-size: 20px;
  `,

  txtSecondary1: `
    ${mainFontBold};
    font-size: 14px;
    color: ${COLORS.blue};
  `,

  txtSecondary2: `
    ${mainFontRegular};
    font-size: 14px;
    color: ${COLORS.blue};
  `,

  txtSecondary3: `
    ${mainFontRegular};
    font-size: 12px;
    color: ${COLORS.blue};
  `,

  txtSecondary4: `
    ${mainFontRegular};
    font-size: 10px;
    color: ${COLORS.blue};
  `,

  inputPlaceholder: `
    ${mainFontFamily};
    color: ${COLORS.inputPlaceHolder};
    font-size: 12px;
  `,

  badgeSmall: `
    ${mainFontBold};
    font-size: 8px;
  `,

  tabsSmall: `
    ${mainFontBold};
    font-size: 10px;
  `,

  tabsMedium: `
    ${mainFontBold};
    font-size: 10px;
    letter-spacing: 1px;
  `,

  switchDefault: `
    ${mainFontBold};
    color: ${COLORS.inputPlaceHolder};
    font-size: 12px;
  `,
} as const;

export type Fonts = keyof typeof FONTS;
