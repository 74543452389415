import { AvailableLocales } from 'providers/IntlProvider';

export type AvailableLanguage = {
  flag: string;
  locale: AvailableLocales;
  label: string;
};

const availableLanguages: ReadonlyArray<AvailableLanguage> = [
  { flag: 'it', locale: 'it', label: 'it' },
  { flag: 'gb', locale: 'en', label: 'en' },
  { flag: 'gr', locale: 'el', label: 'el' },
  { flag: 'fr', locale: 'fr', label: 'fr' },
  { flag: 'es', locale: 'es', label: 'es' },
  { flag: 'ca', locale: 'ca', label: 'ca' },
  { flag: 'pt', locale: 'pt', label: 'pt' },
  { flag: 'de', locale: 'de', label: 'de' },
  { flag: 'nl', locale: 'nl', label: 'nl' },
  { flag: 'lt', locale: 'lt', label: 'lt' },
  { flag: 'cn', locale: 'zh', label: 'cn' },
  { flag: 'ru', locale: 'ru', label: 'ru' },
  { flag: 'sl', locale: 'sl', label: 'sl' },
] as const;

export { availableLanguages };
