import { VenueTreatment, VenueTreatmentGroup } from 'models';

export const vtgToVenueTreatments = (vtg?: VenueTreatmentGroup): VenueTreatment[] => {
  if (!vtg) {
    return [];
  }
  const { venue_treatments, official_treatment, ...rest } = vtg;
  return venue_treatments.map((vt) => ({
    ...rest,
    label: official_treatment,
    ...vt,
  }));
};
