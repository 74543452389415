export type TimeTable = {
  start_time: string | null;
  end_time: string | null;
  end_break: string | null;
  start_break: string | null;
  notes: string | null;
  state: string | null;
  day: number;
  id?: number;
};

export type ExtraOpening = TimeTable & {
  id: number;
  date: string;

  created_at: string;
  state: string | null;
  updated_at: string;
};

export enum VACANCY_FREQUENCIES {
  WEEKLY = 'weekly',
  BIWEEKLY = 'biweekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export type Vacancy = {
  id: number;

  from_date: string | null;
  to_date: string | null;
  time_no_tz: string | null;
  duration: number;

  time: string | null;
  created_at: string | null;
  notes: string | null;
  staff_member_id: number;
  state: string | null;
  time_datepart: string | null;
  time_timepart: string | null;
  typology: string | null;
  updated_at: string | null;
  frequency?: VACANCY_FREQUENCIES;
  repetition_token?: string;
};
