import { VENUE_ORDERS_LIST_REQUEST, VENUE_ORDERS_LIST_RESPONSE, API_POST_RESPONSE } from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

// This reducer is based on this example:
// https://github.com/reduxjs/redux/blob/6baa290b57d455af7d8c2a2318cd7c0aab6dacfd/examples/async/src/reducers/index.js

const orders = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_ORDERS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_ORDERS_LIST_RESPONSE:
      if (!action.orders) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse([].concat(action.orders).map((item) => ({ id: item.id }))),
      };

    case API_POST_RESPONSE:
      if (!action.response || !action.response.data || !action.response.data.order) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(action.response.data.order)
            .concat(state.items || [])
            .map((item) => ({ id: item.id })),
          {
            overrideDuplicateIds: 1,
          }
        ),
      };

    default:
      return state;
  }
};

export const ordersByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_ORDERS_LIST_REQUEST:
    case VENUE_ORDERS_LIST_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = orders(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
