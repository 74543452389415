import { Box } from 'components/core';
import { ModalOverlay } from 'components/core/Modal/theme';
import { FC, SyntheticEvent } from 'react';
import { styled, useTheme } from 'styles';
import { MobilePasswordsManagerProps } from './types';

export const CredentialsAppImage = styled.img`
  width: 16px;
  height: 16px;
`;

// TODO: this is a style used only in the mobile password manager, we should discuss with the design team
// to see if we want to use it in other places or not, and if we need it to be portable we can create a portable high order component
// to wrap the input and apply this style
// https://wahanda.atlassian.net/browse/TWPRO-8204
export const MobilePasswordManagerInputTextWrapper = styled(Box)`
  > .TextInput {
    border: 0;
    padding: 0;

    > input {
      min-height: 22px;
    }
  }
`;

export const MobilePasswordManagerWrapper: FC<Pick<MobilePasswordsManagerProps, 'onClose'>> = ({
  onClose,
  children,
}) => {
  const stopPropagationCb = (e: SyntheticEvent): void => e.stopPropagation();

  const { colors } = useTheme();
  return (
    <ModalOverlay onClick={onClose}>
      <Box
        position="absolute"
        background={colors.white}
        borderRadius="8px 8px 0px 0px"
        padding="32px"
        width="100%"
        bottom="0"
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="24px"
        onClick={stopPropagationCb}
      >
        {children}
      </Box>
    </ModalOverlay>
  );
};
