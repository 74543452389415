import { createSelector } from 'reselect';
import { getCurrentVenueId } from './getCurrentVenue';
import getEntities from './getEntities';
import filterDeletedItems from 'utils/filterDeletedItems';
import moment from 'moment';

export const getVenueVacanciesByVenue = (state) => state.venueVacanciesByVenue;

const emptyVenueVacancies = [];
export const getVenueVacanciesList = createSelector(
  getCurrentVenueId,
  getVenueVacanciesByVenue,
  getEntities.vacancy,
  (venue_id, venueVacanciesByVenue, venueVacancyById) => {
    const venueVacancies =
      (venueVacanciesByVenue[venue_id] && venueVacanciesByVenue[venue_id].items) || emptyVenueVacancies;
    if (venueVacancies === emptyVenueVacancies) {
      return venueVacancies;
    }
    return venueVacancies
      .map((vacancy) => ({
        ...vacancy,
        ...(venueVacancyById[vacancy.id] ? venueVacancyById[vacancy.id].data : {}),
      }))
      .filter(filterDeletedItems)
      .map((vacancy) => {
        if (!vacancy.from_date || !vacancy.to_date) {
          return vacancy;
        }

        const dates = {};
        const from_date = moment(vacancy.from_date);
        const to_date = moment(vacancy.to_date);

        if (vacancy.duration) {
          /**
           * If duration is set, vacancy is contained in one day
           */
          dates[from_date.format('YYYY-MM-DD')] = {
            start_time: from_date.format('HH:mm'),
            end_time: to_date.format('HH:mm'),
          };
        } else {
          /**
           * Vacancy is across multiple days
           */
          const current_date = from_date.clone();
          /**
           * Security check
           */
          if (current_date.isBefore(to_date)) {
            const start_end_date = {
              start_date: from_date.format('YYYY-MM-DD'),
              end_date: to_date.format('YYYY-MM-DD'),
            };
            while (current_date.isBefore(to_date)) {
              dates[current_date.format('YYYY-MM-DD')] = {
                ...start_end_date,
                start_time: null,
                end_time: null,
              };
              current_date.add(1, 'days');
            }
          }
        }

        return {
          dates,
          ...vacancy,
        };
      });
  }
);

export const getVenueVacanciesById = createSelector([getVenueVacanciesList], (venueVacanciesList) => {
  return venueVacanciesList.reduce((venueVacanciesById, venueVacancy) => {
    if (venueVacancy.id) {
      venueVacanciesById[venueVacancy.id] = venueVacancy;
    }
    return venueVacanciesById;
  }, {});
});

export const getVenueVacanciesLoading = createSelector(
  [getCurrentVenueId, getVenueVacanciesByVenue],
  (venue_id, venueVacanciesByVenue) => {
    return (venueVacanciesByVenue[venue_id] && venueVacanciesByVenue[venue_id].isFetching) || false;
  }
);
