import { styled, css } from 'styles';
import { withTheme } from 'components/core';

const HeaderTitle = styled('div')(
  ({ theme: { colors, sizes, media, utils }, isRoot }) => css`
    position: relative;
    flex: 1;
    height: 100%;

    & .Text {
      color: ${colors.text1};
      height: 100%;
      line-height: 24px;
    }

    > .HeaderTitle__root,
    > .HeaderTitle__detail {
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;

      padding: ${(sizes.mobileHeaderHeight - 24) / 2}px;

      ${media.tablet`
        padding: ${(sizes.desktopHeaderHeight - 24) / 2}px;
      `};

      transition: ${utils.transition2};
    }

    > .HeaderTitle__detail {
      transform: translateX(40px);
      opacity: 0;
      z-index: 0;

      ${!isRoot &&
      media.mobileOrSmaller`
          transform: translateX(0px);
          opacity: 1;
          z-index: 1;
        `};
    }

    > .HeaderTitle__root {
      transform: translateX(0px);
      opacity: 1;
      z-index: 1;

      ${!isRoot &&
      media.mobileOrSmaller`
          transform: translateX(-40px);
          opacity: 0;
          z-index: 0;
        `};
    }

    ${sizes.multiVenueSidebarWidth &&
    css`
      ${media.mobileOrSmaller`
          > .HeaderTitle__root,
          > .HeaderTitle__detail {
                padding: 18px 8px 18px 4px;

          }
        `}
    `}
  `
);

HeaderTitle.displayName = 'HeaderTitle';

export default withTheme(HeaderTitle);
