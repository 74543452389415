import { FunctionComponent, useContext } from 'react';
import { ModuleFederationContext } from 'host/RegistryProvider/RegistryProvider';
import { Remote, RemoteNamespace } from 'host/types';

type P = {
  scope?: string;
  namespace?: RemoteNamespace;
  children: (remote: Remote) => unknown;
};

export const Remotes: FunctionComponent<P> = ({ children, scope, namespace }) => {
  const { remotes } = useContext(ModuleFederationContext);

  if (!children || !remotes) return null;

  const matchingRemotes = remotes
    .filter((remote) => !namespace || remote.namespaces.includes(namespace))
    .filter((remote) => !scope || remote.scope === scope);

  return <>{matchingRemotes.map((remote) => children(remote))}</>;
};
