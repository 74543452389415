import {
  VENUE_CUSTOMER_CART_APPLY_PROMOTIONS,
  VENUE_CUSTOMER_CART_APPLY_GIFT_CARDS,
  VENUE_CUSTOMER_CART_ITEM_CREATE,
  VENUE_CUSTOMER_CART_ITEM_DELETE,
  VENUE_CUSTOMER_CART_UPDATE,
} from 'actionTypes';

const customerCartItemsChanges = (state = { items: [] }, action) => {
  switch (action.type) {
    case VENUE_CUSTOMER_CART_APPLY_PROMOTIONS:
      return {
        items: action.line_items,
      };

    case VENUE_CUSTOMER_CART_APPLY_GIFT_CARDS:
      return {
        items: state.items.map((line_item) => {
          const line_item_with_gift_card = action.line_items_with_gift_cards.find(({ id }) => id === line_item.id);
          return {
            ...line_item,
            gift_cards: (line_item_with_gift_card && line_item_with_gift_card.gift_cards) || [],
          };
        }),
      };

    case VENUE_CUSTOMER_CART_UPDATE:
      if (!action.changes || action.changes.receipt !== false) {
        return state;
      }

      return {
        items: state.items.map((line_item) => {
          return {
            ...line_item,
            /**
             * every change to customer_cart resets promo
             */
            using_marketing_promotion_id: null,
            using_marketing_promotion_price: null,
          };
        }),
      };

    case VENUE_CUSTOMER_CART_ITEM_CREATE:
    case VENUE_CUSTOMER_CART_ITEM_DELETE:
      return {
        items: state.items.map((line_item) => {
          return {
            ...line_item,
            /**
             * every change to customer_cart_item trigger a new recalculation of promo
             */
            using_marketing_promotion_id: null,
            using_marketing_promotion_price: null,
          };
        }),
      };

    default:
      return state;
  }
};

export const customerCartItemsChangesByVenue = (state = {}, action) => {
  switch (action.type) {
    case VENUE_CUSTOMER_CART_UPDATE:
    case VENUE_CUSTOMER_CART_ITEM_CREATE:
    case VENUE_CUSTOMER_CART_ITEM_DELETE:
      if (!action.venue_id) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: customerCartItemsChanges(state[action.venue_id], action),
      };

    case VENUE_CUSTOMER_CART_APPLY_PROMOTIONS:
      if (!action.venue_id || !action.line_items || !action.customer_cart) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: customerCartItemsChanges(state[action.venue_id], action),
      };

    case VENUE_CUSTOMER_CART_APPLY_GIFT_CARDS:
      if (!action.venue_id || !action.line_items_with_gift_cards || !action.customer_cart) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: customerCartItemsChanges(state[action.venue_id], action),
      };

    default:
      return state;
  }
};
