import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connectFormElement, getErrorByName } from '@uala/react-forms';

import { DefaultSettings, INPUT_INLINE } from '../../config';
import { StyledSwitch, SwitchItem } from './Switch.theme';
import { FormControl, FormControlInput, Label, Helper, elementPropTypes } from '../../shared';

const Switch = ({
  name,
  label,
  labelPosition,
  intl,
  align,
  emitChange,
  emitDidChange,
  values,
  value,
  errors,
  helper,
  helperPosition,
  tooltip,
  options,
  size,
  flatStyle,
  ...inputProps
}) => {
  const fieldError = getErrorByName(errors, name);
  helper = fieldError || helper || null;
  const error = !!fieldError;

  const refs = useRef([]);
  const [selectedLeft, setSelectedLeft] = useState(0);
  const [selectedWidth, setSelectedWidth] = useState(0);

  const onClick = (value) => {
    emitChange(name, value);
    emitDidChange(name, value);
  };

  /**
   * Giving `name` input is connected to values,
   * otherwise is controlled by `value` prop
   */
  const the_value = name ? values[name] : value;

  const selectedKey = options.reduce((selectedKey, option, k) => (option.value === the_value ? k : selectedKey), -1);

  useEffect(() => {
    if (refs.current[selectedKey]) {
      setSelectedLeft(refs.current[selectedKey].offsetLeft);
      setSelectedWidth(refs.current[selectedKey].offsetWidth);
    } else {
      setSelectedLeft(0);
      setSelectedWidth(0);
    }
  }, [selectedKey]);

  return (
    <FormControl labelPosition={labelPosition} align={align} className="Switch">
      {label && (
        <Label htmlFor={name} position={labelPosition}>
          {label}
        </Label>
      )}
      <FormControlInput inline>
        <StyledSwitch
          {...inputProps}
          flatStyle={flatStyle}
          size={size}
          selectedLeft={selectedLeft}
          selectedWidth={selectedWidth}
        >
          {options.map((item, k) => (
            <SwitchItem
              key={item.value}
              size={size}
              custom={!!item.component}
              selected={item.value === the_value}
              ref={(ref) => (refs.current[k] = ref)}
              onClick={() => onClick(item.value)}
              className={flatStyle ? 'SwitchItemFlat' : 'SwitchItem'}
            >
              {item.component || item.label || item.value}
            </SwitchItem>
          ))}
        </StyledSwitch>
        {helper && <Helper position={helperPosition} tooltip={tooltip} error={error} text={helper} intl={intl} />}
      </FormControlInput>
    </FormControl>
  );
};

Switch.displayName = 'Switch';

Switch.propTypes = {
  ...elementPropTypes,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  align: PropTypes.string,
  flatStyle: PropTypes.bool,
};

Switch.defaultProps = {
  values: {},
  align: null,
  autoComplete: DefaultSettings.AUTOCOMPLETE,
  required: false,
  placeholder: '',
  labelPosition: INPUT_INLINE,
  options: [],
  intl: true,
  flatStyle: false,
};

export default connectFormElement(Switch);
