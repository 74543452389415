import { FunctionComponent } from 'react';
import { css, Global, useTheme } from 'styles';

export const GlobalStyles: FunctionComponent = () => {
  const { colors, fonts } = useTheme();

  return (
    <Global
      styles={css`
        :root {
          --containerMaxWidth: 1152px;
          --containerPadding: 30px;
        }

        html {
          font-size: 62.5%;
          // override sanitze.css values fot html tag
          line-height: normal;
          word-break: normal;
          tab-size: 8;
        }

        body {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: ${colors.bgGray};
          ${fonts.body}
        }

        body.prevent-scroll {
          overflow: hidden;
        }

        a {
          text-decoration: none;
        }

        .Modal-Portal {
          .datepicker-modal {
            position: fixed;
            z-index: 9999;
          }
          .datepicker.default {
            .datepicker-wheel {
              border-color: ${colors.blue};
            }
            .datepicker-navbar-btn {
              color: ${colors.blue};
            }
          }
        }

        .slick-slide > div:active {
          outline: none;
        }

        @media print {
          // hide disconnection modal when printing
          .AlertBanner {
            display: none;
          }
        }
      `}
    />
  );
};
