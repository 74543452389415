import { NODE_ENV } from 'env';
import { UnknownFunction } from './utilityTypes';

/**
 * Chaos Monkey
 * https://en.wikipedia.org/wiki/Chaos_engineering
 *
 *   d O O b
 *      O
 *
 */
type ChaosMonkey = (args: {
  task: UnknownFunction;
  ratePercentage: number;
  maxTimeout: number;
  minTimeout: number;
}) => { stop: () => void };

const chaosMonkey: ChaosMonkey = ({ task, ratePercentage = 1, maxTimeout = 0, minTimeout = 0 }) => {
  let timer: number;
  const cb = () => {
    if (process.env.NODE_ENV === NODE_ENV.DEV) {
      console.log('### chaosMonkey: running task');
    }
    task();
  };

  const selectedForTest = Math.random() * 100 < ratePercentage;
  if (selectedForTest) {
    if (process.env.NODE_ENV === NODE_ENV.DEV) {
      console.log('### chaosMonkey: selected for testing');
    }
    if (minTimeout) {
      if (maxTimeout > minTimeout) {
        setTimeout(cb, minTimeout + Math.random() * (maxTimeout - minTimeout));
      } else {
        setTimeout(cb, minTimeout);
      }
    } else {
      cb();
    }
  }

  return {
    run: () => cb(),
    stop: () => {
      window.clearTimeout(timer);
    },
  };
};

export default chaosMonkey;
