/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { FullTextSearchQuery, VenueProductsUnloadableResponse } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const SEARCH_UNLOADABLES =
  ({ variables }: V<FullTextSearchQuery & { barcode_exact_match?: boolean }>) =>
  (): Promise<VenueProductsUnloadableResponse> =>
    Http()
      .get<VenueProductsUnloadableResponse, FullTextSearchQuery>(ENDPOINT.VENUE_PRODUCTS._SEARCH_UNLOADABLES())({
        searchParams: variables,
      })
      .then(maybeResponseToJSON);
