import PropTypes from 'prop-types';

import { OptionWrapper } from './Select.theme';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const Option = ({ children, onClick, value, ...rest }) => {
  return (
    <OptionWrapper onClick={() => onClick({ target: { value } })} {...rest}>
      {children}
    </OptionWrapper>
  );
};

Option.displayName = 'Option';
Option.propTypes = propTypes;

export default Option;
