import React from 'react';
import { FunctionComponent } from 'react';
import HeaderAction from './HeaderAction.theme';
import { Icon } from 'components/core';
import { Colors } from 'styles';

interface HeaderComponentProps {
  className?: string;
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
  smallDesktop?: boolean;
  fluid?: boolean;
  tag?: boolean;
  borderless?: boolean;
  icon?: { type: string; color: Colors };
  disabled?: boolean;
  onClick?: () => void;
  testId?: number | string;
  children?: React.ReactChild;
}
const HeaderActionComponent: FunctionComponent<HeaderComponentProps> = ({
  className,
  mobile,
  tablet,
  desktop,
  smallDesktop,
  fluid,
  tag,
  borderless,
  icon,
  disabled,
  onClick,
  testId,
  children,
}) => (
  <HeaderAction
    className={`HeaderAction${className ? ' ' + className : ''}`}
    data-testid={testId}
    {...{ mobile, tablet, desktop, tag, fluid, borderless, disabled, onClick, smallDesktop }}
  >
    {children || (
      <div className="HeaderAction__icon">
        <Icon {...icon} />
      </div>
    )}
  </HeaderAction>
);

HeaderActionComponent.displayName = 'HeaderAction';

export default HeaderActionComponent;
