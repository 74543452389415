import { captureException } from '@sentry/core';
import { customEvents } from 'customEvents';
import { startAspectTracking } from 'utils/tracking/aspectTracking';
import { getCustomPageRulesAndPagePlugins } from 'utils/tracking/trackingObservations/customAgendaPageTracking';
import { getPageRules } from 'utils/tracking/trackingObservations/pageRules';
import { getPromoFlashRules } from 'utils/tracking/trackingObservations/promoFlashRules';
import * as trackingService from 'utils/tracking/trackingService';
import { getDailyOverviewRules } from './dailyOverview';
import { getDailyProtectionRules } from './dailyProtection';
import { store } from 'store';

function onDOMReady(fn: (event?: Event) => void): void {
  if (document.readyState !== 'loading') {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

const noDebug = ((): void => {}) as unknown as Console['debug'];

export const startTWPROAnalyticsTrackingOnDOMReady = () =>
  onDOMReady(() => {
    const { agendaPlugins, agendaRules } = getCustomPageRulesAndPagePlugins({ store });
    startAspectTracking(
      window.document,
      [
        ...getPageRules({
          trackingService,
          getCurrentUrl: () => window.location.href,
          plugins: [...agendaPlugins],
        }),
        ...agendaRules,
        ...getDailyOverviewRules({ trackingService }),
        ...getPromoFlashRules({ trackingService }),
        ...getDailyProtectionRules({ trackingService }),
      ],
      {
        logger: { debug: noDebug },
        customEvents: [
          ...customEvents, //
        ],
        // logger: console,
        onException: captureException,
      }
    );
  });
