import { COLORS, css, FONTS, styled, Theme } from 'styles';
import { withTheme } from '../Context';
import CoreIcon from '../Icon';
import Scrollable from '../Scrollable';
import Field, { fieldSizes } from '../Field/theme';

const selectPaddingV = 4;

const sizeStyles = ({ size }: { size: string }) => {
  switch (size) {
    case 'small':
      return css`
        padding: ${selectPaddingV}px 15px;

        > div {
          height: ${fieldSizes.innerHeight.small() - 2 * selectPaddingV}px;
          line-height: ${fieldSizes.innerHeight.small() - 2 * selectPaddingV}px;
          ${fieldSizes.font.default};
        }

        > div > div {
          padding: 5px 15px;
          border-radius: 12px;
        }

        .Icon {
          right: 15px;
        }

        .Selected {
          left: 15px;
        }
      `;
    case 'large':
      return css`
        padding: ${selectPaddingV}px 15px;

        > div {
          height: ${fieldSizes.innerHeight.large() - 2 * selectPaddingV}px;
          line-height: ${fieldSizes.innerHeight.large() - 2 * selectPaddingV}px;
          ${fieldSizes.font.large};
        }

        > div > div {
          padding: 5px 15px;
          border-radius: 12px;

          > div {
            font-size: 2rem;
          }
        }

        .Selected {
          font-size: 2rem;
        }

        .Icon {
          right: 15px;
        }

        .Selected {
          left: 15px;
        }
      `;
    default:
      return css`
        padding: ${selectPaddingV}px 12px;

        > div {
          height: ${fieldSizes.innerHeight.default() - 2 * selectPaddingV}px;
          line-height: ${fieldSizes.innerHeight.default() - 2 * selectPaddingV}px;
          ${fieldSizes.font.default};
        }

        > div > div {
          padding: 4px 12px;
          border-radius: 8px;
        }

        .Icon {
          right: 12px;
        }

        .Selected {
          left: 12px;
        }
      `;
  }
};

export const FieldSelect = withTheme(
  styled(Field)(
    ({
      theme: { colors },
      disabled,
      size = 'default',
      textRight,
      error,
    }: {
      theme: Theme;
      disabled: boolean;
      size: string;
      textRight: boolean;
      error: boolean;
    }) => css`
      ${sizeStyles({ size: size })};
      ${disabled &&
      css`
        background-color: ${colors.bgGray};
        border-color: ${colors.bgGray};
        opacity: 0.3;
        pointer-events: none;
      `};

      position: relative;
      background-color: #fff;
      ${error && `border-color: ${colors.red};`};

      > div {
        display: block;
        width: 100%;
      }

      .PriceAmount {
        vertical-align: baseline;
      }

      &.leftSeparator {
        display: flex;
        align-items: center;
        height: 100%;
        border-left: 1px solid ${colors.inputBorder};
      }

      &.rightSeparator {
        display: flex;
        align-items: center;
        height: 100%;
        border-left: 1px solid ${colors.inputBorder};
      }

      ${textRight &&
      `
      .Selected, > div > div {
        text-align: right;
      }
    `};
    `
  )
);

export const Toggle = styled('div')`
  width: 100%;
  color: ${COLORS.text1};
  ${FONTS.txtMain3};
  user-select: none;
`;

export const Selected = styled('span')(
  ({ value }: { value?: string | number }) => css`
    position: absolute;
    display: inline-block;
    right: 0;
    padding-right: 32px;
    box-sizing: border-box;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${value === '' ? COLORS.inputPlaceHolder : 'inherit'};
  `
);

export const List = styled(Scrollable)`
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  z-index: 1;
  max-height: 272px;
  margin-top: -8px;
  border: 1px solid ${COLORS.gray};
  background-color: #fff;
  overflow-y: auto;
`;

type OptionProps = { selected?: boolean; disabled?: boolean };

export const Option = styled('div')<OptionProps>`
  padding: 8px 0;
  color: ${({ selected }): string => (selected ? COLORS.blue : 'initial')};
  cursor: pointer;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
  &:hover {
    color: ${COLORS.blue};
  }
`;

export const Icon = styled(CoreIcon)`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  pointer-events: none;
  display: ${({ open }: { open: boolean }): string => (open ? 'none' : 'block')};
`;

export default FieldSelect;
