import { connect } from 'react-redux';
import GracePeriodBanner from './GracePeriodBanner';
import { createSelector } from 'reselect';
import {
  getCurrentVenueGracePeriodDismiss,
  getCurrentVenueBillingDetails,
  isCurrentVenueInGracePeriod,
} from 'selectors/getCurrentVenue';
import { setGracePeriodBannerDismiss } from 'actions/sessions';
import { replace } from 'connected-react-router';
import { Venue } from 'models';

export type GracePeriodStateProps = {
  billingDetails: Venue['billing_details'];
  gracePeriodDismiss: number | null;
  isInGracePeriod: boolean;
};

const mapStateToProps = () =>
  createSelector(
    getCurrentVenueGracePeriodDismiss,
    getCurrentVenueBillingDetails,
    isCurrentVenueInGracePeriod,
    (gracePeriodDismiss, billingDetails, isInGracePeriod) => {
      return {
        gracePeriodDismiss,
        billingDetails,
        isInGracePeriod,
      };
    }
  );

const mapDispatchToProps = {
  goToPage: replace,
  dispatchGracePeriodDismiss: setGracePeriodBannerDismiss,
};

export default connect(mapStateToProps, mapDispatchToProps)(GracePeriodBanner);
