/* eslint-disable no-restricted-imports */
import { Http } from 'API/Http';
import { SearchVenueTreatmentsRequest, SearchVenueTreatmentsResponse } from 'API/types';
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { maybeResponseToJSON } from 'API/http.utils';

export const SEARCH =
  ({ variables }: V<SearchVenueTreatmentsRequest>) =>
  (): Promise<SearchVenueTreatmentsResponse> =>
    Http()
      .get<SearchVenueTreatmentsResponse, SearchVenueTreatmentsRequest>(ENDPOINT.VENUE_TREATMENTS._SEARCH())({
        searchParams: variables,
      })
      .then(maybeResponseToJSON);
