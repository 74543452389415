import {
  VENUE_TREATMENTS_LIST_REQUEST,
  VENUE_TREATMENTS_LIST_RESPONSE,
  VENUE_STAFF_MEMBER_TREATMENTS_LIST_REQUEST,
  VENUE_STAFF_MEMBER_TREATMENTS_LIST_RESPONSE,
  VENUE_TREATMENTS_CREATE_RESPONSE,
  VENUE_STAFF_MEMBER_TREATMENTS_CREATE_RESPONSE,
  VENUE_STAFF_MEMBER_TREATMENTS_UPDATE_RESPONSE,
  VENUE_STAFF_MEMBER_TREATMENTS_DELETE_RESPONSE,
} from 'actionTypes';

import { apiGet, apiPost } from './data_providers/api';
import { actionShouldFetchData } from 'utils/reducersUtils';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getCurrentUserToken } from 'selectors/getCurrentUser';
import { updateVenueWizardIfNeeded } from './venueWizards';

const fetchVenueTreatmentsRequestAction = ({ venue_id, auth }) => ({
  type: VENUE_TREATMENTS_LIST_REQUEST,
  venue_id,
  auth,
});

const fetchVenueTreatmentsResponseAction = ({ error, venue_id, venue_treatments, response }) => ({
  type: VENUE_TREATMENTS_LIST_RESPONSE,
  venue_id,
  venue_treatments,
  response,
  error,
});

export const fetchVenueTreatments = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(fetchVenueTreatmentsRequestAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/venue_treatments
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/venue_treatments.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(fetchVenueTreatmentsResponseAction({ error: response.info && response.info[0] }));
          }

          dispatch(
            fetchVenueTreatmentsResponseAction({ venue_id, venue_treatments: response.data.venue_treatments, response })
          );

          resolve({ venue_treatments: response.data.venue_treatments });
        },
        onError(error) {
          dispatch(fetchVenueTreatmentsResponseAction({ error }));

          reject({ error });
        },
      })
    );
  });

export const fetchVenueTreatmentsIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().venueTreatmentsByVenue[venue_id])) {
      return dispatch(fetchVenueTreatments());
    }
  };
};

/**
 * Staff Member Treatments
 * A staff_member can be associated with one or more venue_treatment in order to create a staff_member_treatment.
 * The association is meant to represent the fact that the staff member can offer those treatments.
 */

const fetchStaffMemberTreatmentsRequestAction = ({ venue_id, auth }) => ({
  type: VENUE_STAFF_MEMBER_TREATMENTS_LIST_REQUEST,
  venue_id,
  auth,
});

const fetchStaffMemberTreatmentsResponseAction = ({ error, venue_id, staff_member_treatments, response }) => ({
  type: VENUE_STAFF_MEMBER_TREATMENTS_LIST_RESPONSE,
  venue_id,
  staff_member_treatments,
  response,
  error,
});

export const fetchStaffMemberTreatments = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    dispatch(fetchStaffMemberTreatmentsRequestAction({ venue_id, auth }));

    // http://docs.uala.it/api/v1/staff_member_treatments
    dispatch(
      apiGet({
        path: `/venues/${venue_id}/staff_member_treatments.json`,
        auth: auth,
        onResponse(response) {
          if (!response.success) {
            return dispatch(fetchStaffMemberTreatmentsResponseAction({ error: response.info && response.info[0] }));
          }

          dispatch(
            fetchStaffMemberTreatmentsResponseAction({
              venue_id,
              staff_member_treatments: response.data.staff_member_treatments,
              response,
            })
          );
        },
        onError(error) {
          dispatch(fetchStaffMemberTreatmentsResponseAction({ error }));
        },
      })
    );
  };
};

export const fetchStaffMemberTreatmentsIfNeeded = () => {
  return (dispatch, getState) => {
    const venue_id = getCurrentVenueId(getState());
    const auth = getCurrentUserToken(getState());

    if (!venue_id || !auth) {
      return;
    }

    if (actionShouldFetchData(getState().staffMemberTreatmentsByVenue[venue_id])) {
      return dispatch(fetchStaffMemberTreatments());
    }
  };
};

export const createVenueTreatmentResponseAction = ({ error, venue_id, venue_treatment, response }) => ({
  type: VENUE_TREATMENTS_CREATE_RESPONSE,
  venue_id,
  venue_treatment,
  response,
  error,
});

export const getVenueTreatment =
  ({ id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());

      // http://docs.uala.it/api/v1/venue_treatments
      dispatch(
        apiGet({
          path: `/venues/${venue_id}/venue_treatments/${id}.json`,
          onResponse(response) {
            if (!response.success) {
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              createVenueTreatmentResponseAction({ venue_id, venue_treatment: response.data.venue_treatment, response })
            );

            /**
             * Wizard Assistant
             */
            dispatch(updateVenueWizardIfNeeded('treatments_incomplete'));

            resolve({ venue_treatment: response.data.venue_treatment });
          },
          onError(error) {
            reject({ error });
          },
        })
      );
    });

const createStaffMemberTreatmentResponseAction = ({ error, venue_id, staff_member_treatment, response }) => ({
  type: VENUE_STAFF_MEMBER_TREATMENTS_CREATE_RESPONSE,
  venue_id,
  staff_member_treatment,
  response,
  error,
});

export const createStaffMemberTreatment =
  ({ staff_member_treatment }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !staff_member_treatment) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/staff_member_treatments
      dispatch(
        apiPost({
          path: `/venues/${venue_id}/staff_member_treatments.json`,
          data: {
            staff_member_treatment: {
              ...staff_member_treatment,
            },
          },
          auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(createStaffMemberTreatmentResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              createStaffMemberTreatmentResponseAction({
                venue_id,
                staff_member_treatment: response.data.staff_member_treatment,
                response,
              })
            );

            /**
             * Wizard Assistant
             */
            dispatch(updateVenueWizardIfNeeded('treatments_incomplete'));

            resolve({ staff_member_treatment: response.data.staff_member_treatment });
          },
          onError(error) {
            dispatch(createStaffMemberTreatmentResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

const updateStaffMemberTreatmentResponseAction = ({ error, venue_id, staff_member_treatment }) => ({
  type: VENUE_STAFF_MEMBER_TREATMENTS_UPDATE_RESPONSE,
  venue_id,
  staff_member_treatment,
  error,
});

export const updateStaffMemberTreatment =
  ({ id, changes }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !id) {
        return reject({ code: 400 });
      }

      let time_table = null;
      if (changes.time_table) {
        time_table = changes.time_table.map(({ ranges }) =>
          ranges.map((range) => range.start_time + ',' + range.end_time).join(',')
        );
        const wholeDaysLength = time_table.filter((item) => {
          return item === '00:00,23:59';
        }).length;
        if (wholeDaysLength === 7) {
          time_table = null;
        } else {
          time_table = time_table.join('\n') + '\n';
        }
      }

      // http://docs.uala.it/api/v1/staff_member_treatments
      dispatch(
        apiPost({
          method: 'PUT',
          path: `/venues/${venue_id}/staff_member_treatments/${id}.json`,
          data: {
            staff_member_treatment: {
              ...changes,
              time_table,
            },
          },
          auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(updateStaffMemberTreatmentResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              updateStaffMemberTreatmentResponseAction({
                venue_id,
                staff_member_treatment: response.data.staff_member_treatment,
              })
            );

            /**
             * Wizard Assistant
             */
            dispatch(updateVenueWizardIfNeeded('treatments_incomplete'));

            resolve({ staff_member_treatment: response.data.staff_member_treatment });
          },
          onError(error) {
            dispatch(updateStaffMemberTreatmentResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });

const deleteStaffMemberTreatmentResponseAction = ({ error, venue_id, staff_member_treatment }) => ({
  type: VENUE_STAFF_MEMBER_TREATMENTS_DELETE_RESPONSE,
  venue_id,
  staff_member_treatment,
  error,
});

export const deleteStaffMemberTreatment =
  ({ id }) =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      const venue_id = getCurrentVenueId(getState());
      const auth = getCurrentUserToken(getState());

      if (!venue_id || !auth || !id) {
        return reject({ code: 400 });
      }

      // http://docs.uala.it/api/v1/staff_member_treatments
      dispatch(
        apiPost({
          method: 'DELETE',
          path: `/venues/${venue_id}/staff_member_treatments/${id}.json`,
          data: {},
          auth,
          onResponse(response) {
            if (!response.success) {
              dispatch(deleteStaffMemberTreatmentResponseAction({ error: response.info && response.info[0] }));
              return reject({ error: response.info && response.info[0] });
            }

            dispatch(
              deleteStaffMemberTreatmentResponseAction({
                venue_id,
                staff_member_treatment: response.data.staff_member_treatment,
              })
            );

            /**
             * Wizard Assistant
             */
            dispatch(updateVenueWizardIfNeeded('treatments_incomplete'));

            resolve({ staff_member_treatment: response.data.staff_member_treatment });
          },
          onError(error) {
            dispatch(deleteStaffMemberTreatmentResponseAction({ error }));
            reject({ error });
          },
        })
      );
    });
