import { styled, css } from 'styles';
import { withTheme } from 'components/core';

export const PageWrapper = withTheme(
  styled('div')(
    ({ theme: { sizes, media } }) => css`
      display: block;
      margin: 0 auto;
      padding-top: ${sizes.mobileHeaderHeight}px;

      .remote-homepage-wrapper .remote-module-renderer > div {
        min-height: calc(100vh - ${sizes.mobileHeaderHeight}px);
      }

      ${media.desktop`
        padding-left: ${sizes.multiVenueSidebarWidth}px;
      `}

      ${media.tablet`
        padding-top: ${sizes.desktopHeaderHeight}px;

        .remote-homepage-wrapper .remote-module-renderer > div {
          min-height: calc(100vh - ${sizes.desktopHeaderHeight}px);
        }
      `};
    `
  )
);
