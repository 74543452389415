const getHighlightedTextChunk = (x1, x2, string, highlighted) => {
  return {
    val: string.substring(x1, x2),
    hl: highlighted,
  };
};

const getHighlightedChunks = (searchString, queryPieces) => {
  const hlChunks = [];

  if (!queryPieces.length) {
    return;
  }

  queryPieces.forEach((piece) => {
    if (!piece) {
      return;
    }

    const startChunk = searchString.indexOf(piece);

    if (startChunk < 0) {
      return;
    }

    const endChunk = startChunk + piece.length;
    const isChunkHighlighted = hlChunks.some((chunk) => startChunk >= chunk[0] && endChunk <= chunk[1]);

    if (!isChunkHighlighted) {
      hlChunks.push([startChunk, endChunk]);
    }
  });

  return hlChunks.sort((hlChunkA, hlChunkB) => {
    return hlChunkA[0] - hlChunkB[0];
  });
};

export const getHighlightedString = (searchString, resultString, queryPieces) => {
  const hlChunks = getHighlightedChunks(searchString, queryPieces);

  let textChunks = [
    {
      val: resultString,
      hl: false,
    },
  ];

  if (hlChunks && hlChunks.length) {
    textChunks = [];
    hlChunks.forEach((hlChunk, i) => {
      const isFirst = i === 0;
      const isLast = i === hlChunks.length - 1;
      const next = hlChunks[i + 1];
      const x1 = hlChunk[0];
      const x2 = hlChunk[1];

      // initial chunk (from 0 to hl chunk)
      if (isFirst && x1 !== 0) {
        textChunks.push(getHighlightedTextChunk(0, x1, resultString, false));
      }

      // this hl chunk
      textChunks.push(getHighlightedTextChunk(x1, x2, resultString, true));

      // the non hl chunk between 2 hl ones
      if (next) {
        const x1Next = next[0];
        textChunks.push(getHighlightedTextChunk(x2, x1Next, resultString, false));
      }

      // final non hl chunk (from hl chunk to string.length)
      if (isLast && x2 !== resultString.length) {
        textChunks.push(getHighlightedTextChunk(x2, resultString.length, resultString, false));
      }
    });
  }

  return textChunks;
};
