import {
  VENUE_MARKETING_PROMOTIONS_LIST_REQUEST,
  VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE,
  API_POST_RESPONSE,
} from 'actionTypes';
import { reducerValuesOnRequest, reducerValuesOnResponse, reducerValuesOnInit } from 'utils/reducersUtils';

// marketing_promotion and customer_marketing_promotion are in the same table on BE.
// this reducer handles only the venue marketing promotion, not associated with customers
// see also src/reducers/promotions.js
const filterItem = (item) => item.customer_id || item.parent_customer_id;

const customerPromotions = (state = reducerValuesOnInit(), action) => {
  switch (action.type) {
    case VENUE_MARKETING_PROMOTIONS_LIST_REQUEST:
      return {
        ...state,
        ...reducerValuesOnRequest(),
      };

    case VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE:
      if (!action.marketing_promotions) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(action.marketing_promotions || [])
            .filter(filterItem)
            .concat(state.items || []),
          {
            overrideDuplicateIds: 1,
            onlyIds: 1,
          }
        ),
      };

    case API_POST_RESPONSE:
      if (
        !action.response ||
        !action.response.data ||
        (!action.response.data.marketing_promotion &&
          !action.response.data.marketing_promotions &&
          !action.response.data.appliable_marketing_promotions)
      ) {
        return state;
      }

      if (
        ![]
          .concat(action.response.data.marketing_promotion || [])
          .concat(action.response.data.marketing_promotions || [])
          .concat(action.response.data.appliable_marketing_promotions || []).length
      ) {
        return state;
      }

      return {
        ...state,
        ...reducerValuesOnResponse(
          []
            .concat(action.response.data.marketing_promotion || [])
            .concat(action.response.data.marketing_promotions || [])
            .concat(action.response.data.appliable_marketing_promotions || [])
            .filter(filterItem)
            .concat(state.items || []),
          {
            overrideDuplicateIds: 1,
            skipUpdate: 1,
            onlyIds: 1,
          }
        ),
      };

    default:
      return state;
  }
};

export const customerPromotionsByVenue = (state = {}, action) => {
  let refState;

  switch (action.type) {
    case VENUE_MARKETING_PROMOTIONS_LIST_REQUEST:
    case VENUE_MARKETING_PROMOTIONS_LIST_RESPONSE:
    case API_POST_RESPONSE:
      if (action.error || !action.venue_id) {
        return state;
      }

      refState = customerPromotions(state[action.venue_id], action);

      if (refState === state[action.venue_id]) {
        return state;
      }

      return {
        ...state,
        [action.venue_id]: refState,
      };

    default:
      return state;
  }
};
