const getTrendColor = (trend) => {
  const value = Number(trend.replace('%', ''));
  if (value > 0) {
    return 'green';
  } else if (value < 0) {
    return 'red';
  }
  return 'text2';
};

export default getTrendColor;
