import { getCurrentVenueCountryCode, getCurrentVenueId } from 'selectors/getCurrentVenue';
import { getPageNameByPathname } from 'utils/tracking/trackingService.utils';
import { AnalyticsContext, StoreContext, WindowDataContext } from 'utils/tracking/types';
import { getPlatform } from './getPlatform';
import { getDevice, getOS } from './getUserAgentParsed';
import { getEnvironment } from './getEnvironment';

// TODO make createPage Generic and make this model owned by trackingService
export type PageExtraParameters = {
  selection?: string;
  agenda_view_mode?: 'daily' | 'weekly'; // TODO to be renamed
  agenda_view_collaborators?: 'all' | 'only_working';
};

type PageContext = AnalyticsContext & StoreContext & WindowDataContext;
export const createPage = ({ getAnalyticsBrowser, getStore, getWindowData }: PageContext) => {
  let lastDataSent = '';
  return (extraParams: PageExtraParameters = {}) => {
    const analytics = getAnalyticsBrowser();
    if (!analytics) return;

    const state = getStore().getState();
    const pageName = getPageNameByPathname(getWindowData().href);
    const params = {
      venue_id: getCurrentVenueId(state),
      country: getCurrentVenueCountryCode(state),
      platform: getPlatform(),
      environment: getEnvironment(),
      OS: getOS(),
      device: getDevice(),
      trackingVersion: 'v2',
      selection: 'default',

      ...extraParams,
    };
    const currentData = JSON.stringify([pageName, params]);

    if (currentData !== lastDataSent) {
      analytics.page(pageName, params);
      lastDataSent = currentData;
    }
  };
};
