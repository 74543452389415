type VenueIconProps = {
  width?: number;
  height?: number;
  fillColor?: string;
  opacity?: number;
};

export const Venue = ({ width = 32, height = 32, fillColor, opacity }: VenueIconProps): JSX.Element => (
  <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity={opacity}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.75 15.4205C22.0811 15.7953 21.3061 16 20.5 16C19.6939 16 18.9189 15.7953 18.25 15.4205C17.5811 15.7953 16.8061 16 16 16C15.1939 16 14.4189 15.7953 13.75 15.4205C13.0811 15.7953 12.3061 16 11.5 16C10.6939 16 9.91893 15.7953 9.25002 15.4205C8.86423 15.6366 8.44316 15.7962 8.00118 15.8926L8.00002 26.663L10.6667 26.6627V19.996C10.6667 19.2618 11.268 18.6667 11.9877 18.6667H17.3456C18.0752 18.6667 18.6667 19.2707 18.6667 19.996V26.664L24.0079 26.6667L24.0014 18.6653L26.6667 18.6667V26.663C26.6667 28.1378 25.474 29.3333 24.0079 29.3333H7.9921C6.52372 29.3333 5.33335 28.1419 5.33335 26.663L5.33348 15.6934C3.77943 15.0963 2.66669 13.6871 2.66669 12C2.66669 11.6267 2.72234 11.2592 2.8306 10.9061L3.79799 5.10183C4.02677 3.72914 5.27458 2.66666 6.66785 2.66666H25.3322C26.7197 2.66666 27.9747 3.73781 28.2021 5.10183L29.1694 10.9061C29.2777 11.2592 29.3334 11.6267 29.3334 12C29.3334 14.2464 27.3606 16 25 16C24.1939 16 23.4189 15.7953 22.75 15.4205ZM16 21.3333H13.3334V26.664H16V21.3333ZM25.3322 5.33332H6.66785C6.58101 5.33332 6.44332 5.45057 6.42837 5.54023L5.44486 11.4413L5.39105 11.6542C5.35279 11.7659 5.33335 11.8813 5.33335 12C5.33335 12.6991 6.04685 13.3333 7.00002 13.3333C7.51958 13.3333 7.99003 13.1405 8.30388 12.8244L9.25002 11.8715L10.1962 12.8244C10.51 13.1405 10.9805 13.3333 11.5 13.3333C12.0196 13.3333 12.49 13.1405 12.8039 12.8244L13.75 11.8715L14.6962 12.8244C15.01 13.1405 15.4805 13.3333 16 13.3333C16.5196 13.3333 16.99 13.1405 17.3039 12.8244L18.25 11.8715L19.1962 12.8244C19.51 13.1405 19.9805 13.3333 20.5 13.3333C21.0196 13.3333 21.49 13.1405 21.8039 12.8244L22.75 11.8715L23.6962 12.8244C24.01 13.1405 24.4805 13.3333 25 13.3333C25.9532 13.3333 26.6667 12.6991 26.6667 12C26.6667 11.8813 26.6473 11.7659 26.609 11.6542L26.5552 11.4413L25.5717 5.54023C25.558 5.45796 25.4119 5.33332 25.3322 5.33332Z"
        fill={fillColor}
      />
    </g>
  </svg>
);

export default Venue;
