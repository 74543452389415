import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { RecordButtonWrapper, RecordButton } from './StateRecorder.theme';
import { useIpcListener } from 'utils/native/electronHooks';
import { electronGetGlobal, ipcSend } from 'utils/native/electronUtils';
import { getCurrentVenueId } from 'selectors/getCurrentVenue';
import { RECORDING_FLAG, RECORDING_EVENTS } from './config';
import { exportIndexedDB } from 'store';

const StateRecorder = (): JSX.Element | null => {
  const recordingState: boolean = electronGetGlobal(RECORDING_FLAG);
  const [isRecording, setIsRecording] = useState(recordingState);

  const venueId = useSelector(getCurrentVenueId);

  const stopRecordingHandler = (): void => {
    setIsRecording(false);
  };

  const stopRecording = async (e: React.MouseEvent<HTMLElement>): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();
    setIsRecording(false);
    const localStateExport = await exportIndexedDB();
    const currentDate = moment().format('DDMMYYYYHHMMss');
    const fileName = `${venueId}-${currentDate}`;
    ipcSend(RECORDING_EVENTS.TRIGGER_STATE_DOWNLOAD, {
      fileName,
      venueId,
      localStateExport,
    });
  };

  useIpcListener({
    eventName: RECORDING_EVENTS.TRIGGER_STOP_STATE_RECORDING,
    handler: stopRecordingHandler,
  });

  return isRecording ? (
    <RecordButtonWrapper onClick={stopRecording}>
      <RecordButton />
    </RecordButtonWrapper>
  ) : null;
};

export default StateRecorder;
