import Loadable from '@loadable/component';
import LoadingCodeChunkFallback from 'components/lib/LoadingCodeChunkFallback';

export const Agenda = Loadable(
  async () => {
    const { AgendaContainer } = await import('containers/agenda/Agenda');
    return AgendaContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Customers = Loadable(
  async () => {
    const { CustomersContainer } = await import('containers/customers/Customers');
    return CustomersContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const DailyAppointmentsPrint = Loadable(
  async () => {
    const { AppointmentsPrintContainer } = await import('components/agenda/DailyAppointmentsPrint');
    return AppointmentsPrintContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const DailyAppointmentsPrintFiche = Loadable(
  async () => {
    const { DailyAppointmentsPrintFicheContainer } = await import('components/agenda/DailyAppointmentsPrintFiche');
    return DailyAppointmentsPrintFicheContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Checkout = Loadable(
  async () => {
    const { CheckoutContainer } = await import('containers/checkout/Checkout');
    return CheckoutContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Treatments = Loadable(
  async () => {
    const { TreatmentsContainer } = await import('containers/treatments/Treatments');
    return TreatmentsContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const StaffMembers = Loadable(
  async () => {
    const { StaffMembersContainer } = await import('containers/staff/StaffMembers');
    return StaffMembersContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Workstations = Loadable(
  async () => {
    const { WorkstationsContainer } = await import('containers/workstations/Workstations');
    return WorkstationsContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Promotions = Loadable(
  async () => {
    const { PromotionsContainer } = await import('containers/promotions/Promotions');
    return PromotionsContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Costs = Loadable(
  async () => {
    const { CostsContainer } = await import('containers/costs/Costs');
    return CostsContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Manager = Loadable(
  async () => {
    const { ManagerContainer } = await import('containers/Manager');
    return ManagerContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Performance = Loadable(
  async () => {
    const { PerformanceContainer } = await import('containers/Performance');
    return PerformanceContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Company = Loadable(
  async () => {
    const { RecapContainer } = await import('components/manager_next/company/recap/Recap.container');
    return RecapContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const SalesProceeds = Loadable(
  async () => {
    const { CompanySalesProceedsAnalysisContainer } = await import(
      'containers/manager/company/analyses/CompanySalesProceedsAnalysis'
    );
    return CompanySalesProceedsAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CompanyCostsAnalysis = Loadable(
  async () => {
    const { CompanyCostsAnalysisContainer } = await import('containers/manager/company/analyses/CompanyCostsAnalysis');
    return CompanyCostsAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CompanyProfitabilityAnalysis = Loadable(
  async () => {
    const { ProfitabilityAnalysisContainer } = await import(
      'components/manager_next/company/profitability_analysis/ProfitabilityAnalysis.container'
    );
    return ProfitabilityAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CompanyAppointmentsAnalysis = Loadable(
  async () => {
    const { CompanyAppointmentsAnalysisContainer } = await import(
      'containers/manager/company/analyses/CompanyAppointmentsAnalysis'
    );
    return CompanyAppointmentsAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CollaboratorsProfitabilityAnalysis = Loadable(
  async () => {
    const { CollaboratorsProfitabilityAnalysisContainer } = await import(
      'containers/manager/collaborators/analyses/CollaboratorsProfitabilityAnalysis'
    );
    return CollaboratorsProfitabilityAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CollaboratorsReviewsAndLoyaltyAnalysis = Loadable(
  async () => {
    const { CollaboratorsReviewsAndLoyaltyAnalysisContainer } = await import(
      'containers/manager/collaborators/analyses/CollaboratorsReviewsAndLoyaltyAnalysis'
    );
    return CollaboratorsReviewsAndLoyaltyAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CollaboratorsPromoAnalysis = Loadable(
  async () => {
    const { CollaboratorsPromoAnalysisContainer } = await import(
      'components/manager/collaborators/analyses/CollaboratorsPromoAnalysis.container'
    );
    return CollaboratorsPromoAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const StockHandlingAnalysis = Loadable(
  async () => {
    const { StockHandlingAnalysisContainer } = await import('containers/manager/stock/analyses/StockHandlingAnalysis');
    return StockHandlingAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const RotationAnalysis = Loadable(
  async () => {
    const { RotationAnalysisContainer } = await import('containers/manager/stock/analyses/RotationAnalysis');
    return RotationAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Collaborators = Loadable(
  async () => {
    const { CollaboratorsContainer } = await import('containers/Collaborators');
    return CollaboratorsContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const ManagerCustomers = Loadable(
  async () => {
    const { CustomersContainer } = await import('containers/manager/Customers');
    return CustomersContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CustomerAnalysis = Loadable(
  async () => {
    const { CustomerAnalysisContainer } = await import('containers/manager/customers/analysis/CustomerAnalysis');
    return CustomerAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CustomerDebtsAnalysis = Loadable(
  async () => {
    const { CustomersDebtsAnalysisContainer } = await import(
      'containers/manager/customers/analysis/CustomersDebtsAnalysis'
    );
    return CustomersDebtsAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const CustomersFrequencyAnalysis = Loadable(
  async () => {
    const { CustomersFrequencyAnalysisContainer } = await import(
      'containers/manager/customers/analysis/CustomersFrequencyAnalysis'
    );
    return CustomersFrequencyAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Stock = Loadable(
  async () => {
    const { StockContainer } = await import('containers/manager/Stock');
    return StockContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Inventory = Loadable(
  async () => {
    const { InventoryContainer } = await import('containers/manager/Inventory');
    return InventoryContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Diary = Loadable(
  async () => {
    const { DiaryContainer } = await import('components/manager/diary/Template');
    return DiaryContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Benchmark = Loadable(
  async () => {
    const { ManagerContainer } = await import('components/manager/benchmark/Template');
    return ManagerContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const SalonAnalysis = Loadable(
  async () => {
    const { SalonAnalysisContainer } = await import('components/manager_next/brand/salon-analysis/Template');
    return SalonAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const IncomeStatementAnalysis = Loadable(
  async () => {
    const { IncomeStatementAnalysisContainer } = await import(
      'components/manager_next/brand/income-statement-analysis/Template'
    );
    return IncomeStatementAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const StaffMemberAnalysis = Loadable(
  async () => {
    const { StaffMembersAnalysisContainer } = await import(
      'components/manager_next/brand/staff-members-analysis/Template'
    );
    return StaffMembersAnalysisContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const WarehouseProducts = Loadable(
  async () => {
    const { WarehouseProductsContainer } = await import('containers/warehouse/WarehouseProducts');
    return WarehouseProductsContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const WarehouseProductsV2 = Loadable(
  async () => {
    const { TemplateWarehouseProduct } = await import('components/warehouse/products/TemplateWarehouseProduct');
    return TemplateWarehouseProduct;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const WarehouseOrders = Loadable(
  async () => {
    const { OrdersContainer } = await import('components/warehouse_next/orders/Orders.container');
    return OrdersContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const WarehouseTickler = Loadable(
  async () => {
    const { WarehouseTicklerComntainer } = await import('containers/warehouse/WarehouseTickler');
    return WarehouseTicklerComntainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const WarehouseManufacturers = Loadable(
  async () => {
    const { WarehouseManufacturersContainer } = await import('containers/warehouse/WarehouseManufacturers');
    return WarehouseManufacturersContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Timetables = Loadable(
  async () => {
    const { TimetablesContainer } = await import('containers/timetables/Timetables');
    return TimetablesContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const TimetablesExtraOpenings = Loadable(
  async () => {
    const { TimetablesExtraOpeningsContainer } = await import('containers/timetables/TimetablesExtraOpenings');
    return TimetablesExtraOpeningsContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Account = Loadable(
  async () => {
    const { AccountContainer } = await import('containers/account/Account');
    return AccountContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Charts = Loadable(
  async () => {
    const { Charts } = await import('containers/Charts');
    return Charts;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Marketing = Loadable(
  async () => {
    const { MarketingContainer } = await import('containers/marketing/Marketing');
    return MarketingContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Plans = Loadable(
  async () => {
    const { ProvisioningPlanSelectionContainer } = await import(
      'components/provisioning_next/plan-selection/ProvisioningPlanSelection.container'
    );
    return ProvisioningPlanSelectionContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Plan = Loadable(
  async () => {
    const { ProvisioningPlanContainer } = await import('components/provisioning_next/plan/ProvisioningPlan.container');
    return ProvisioningPlanContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const EcommerceOrders = Loadable(
  async () => {
    const { EcommerceOrderContainer } = await import('components/ecommerce_next/EcommerceOrder.container');
    return EcommerceOrderContainer;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const ShippingOptions = Loadable(
  async () => {
    const { ShippingOptionsContainer } = await import('components/ecommerce_next/ShippingOptions.container');
    return ShippingOptionsContainer;
  },
  { fallback: LoadingCodeChunkFallback }
);

export const Assistant = Loadable(
  async () => {
    const { AssistantNextComponent } = await import('components/assistant_next/Template');
    return AssistantNextComponent;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const FiscalDayReportPrint = Loadable(
  async () => {
    const { FiscalDayReportPrint } = await import(
      'components/checkout_next/fiscal-day-report-print/FiscalDayReportPrint'
    );
    return FiscalDayReportPrint;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const Reviews = Loadable(
  async () => {
    const { TemplateReviews } = await import('components/reviews/TemplateReviews');
    return TemplateReviews;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const RemoteHomepage = Loadable(
  async () => {
    const Template = await import('host/RemoteHomepage');
    return Template;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);

export const KycOnboardingRefreshPage = Loadable(
  async () => {
    const Template = await import('components/payments/KycOnboardingRefreshTemplate');
    return Template;
  },
  {
    fallback: <LoadingCodeChunkFallback />,
  }
);
