import { styled, css } from 'styles';

import { withTheme } from 'components/core';

const transitionFunc = 'cubic-bezier(0.78, 0.14, 0.15, 0.86)';

const sizes = {
  height: {
    sm: 33,
    md: 40,
  },
  itemHeight: {
    sm: 25,
    md: 32,
  },
};

const getSize = (k, size) => sizes[k][size] || sizes[k].md;

export const StyledSwitch = withTheme(styled('div')`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4px;
  height: ${({ size }) => getSize('height', size)}px;
  border-radius: ${({ size }) => getSize('height', size) / 2}px;
  cursor: pointer;
  background-color: ${({ theme, flatStyle }) => (flatStyle ? theme.colors.gray9 : theme.colors.white)};
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.red : theme.colors.inputBorder)};

  ${({ flatStyle }) =>
    flatStyle &&
    css`
      border: 0;
      padding: 0;
    `}

  ${({ selectedLeft, selectedWidth, theme, flatStyle, size }) =>
    (selectedLeft || selectedWidth) &&
    css`
      &:before {
        left: ${selectedLeft}px;
        top: 50%;
        width: ${selectedWidth}px;
        height: ${flatStyle ? '100%' : getSize('itemHeight', size) + 'px'};
        position: absolute;
        content: '';
        border-radius: ${flatStyle ? 21 : getSize('itemHeight', size) / 2}px;
        transform: translate(0, -50%);
        transition: all 0.4s ${transitionFunc};
        background-color: ${flatStyle ? theme.colors.white : theme.colors.blue};
      }
    `}
`);

export const SwitchItem = withTheme(styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ size }) => getSize('height', size) / 2}px;
  padding: 0 12px;
  ${({ custom, selected, theme, size, flatStyle }) =>
    custom
      ? css`
          min-width: ${getSize('itemHeight', size)}px;
          min-height: ${getSize('itemHeight', size)}px;

          path {
            transition: fill 0.4s ${transitionFunc};
            fill: ${selected ? theme.colors.white : theme.colors.text1};
          }
        `
      : css`
          height: ${getSize('itemHeight', size)}px;
          line-height: ${getSize('itemHeight', size)}px;
          font-size: 16px;
          color: ${selected ? theme.colors.white : theme.colors.text1};
          transition: color 0.4s ${transitionFunc};

          &.SwitchItemFlat {
            color: ${selected ? theme.colors.blue : theme.colors.text1};
            font-size: 14px;
          }
        `}
`);
