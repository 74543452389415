import { SESSIONS_LOGOUT, SESSIONS_RESET_VENUE_DATA } from 'actionTypes';
import { State } from './types';
import { Reducer } from 'redux';

interface Action {
  type: string;
  venue_id?: number;
}

const rootReducer = ((state: State, action: Action): State => {
  switch (action.type) {
    case SESSIONS_LOGOUT:
      return {} as State; // TODO should return an empty state

    case SESSIONS_RESET_VENUE_DATA: {
      if (!action.venue_id) {
        return state;
      }

      const newState: State = { ...state };
      for (const storeKey in newState) {
        if (/ByVenue$/.test(storeKey)) {
          const storeK = newState[storeKey as keyof typeof newState];
          if (storeK) {
            delete storeK[action.venue_id as keyof typeof storeK];
            // delete ((newState[storeKey as keyof typeof newState] as unknown) as Array<number>)[action.venue_id];
          }
        }
      }

      return state;
    }

    default:
      return state;
  }
}) as unknown as Reducer<State, Action>;

export default rootReducer;
