import { FunctionComponent, ReactElement, ReactNode, useEffect, useState } from 'react';

import { connectFormElement, ConnectedFormProps } from '@uala/react-forms';

import { Text, Section } from 'components/core';

import { INPUT_INLINE } from '../../config';
import { FormControl, Label } from '../../shared';
import InputText from './InputText';
import { ReactElementLike } from 'prop-types';

type CollapsibleInputTextProps = {
  collapsed?: boolean;
  collapsedText?: ReactNode;
  label?: ReactNode;
  name: string;
  labelPosition?: string;
};

const CollapsibleInputText: FunctionComponent<ConnectedFormProps & CollapsibleInputTextProps> = ({
  collapsed,
  labelPosition = INPUT_INLINE,
  collapsedText,
  name,
  label,
}): ReactElement => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed ?? true);

  useEffect(() => {
    setIsCollapsed(collapsed ?? true);
  }, [collapsed]);

  return (
    <>
      {isCollapsed ? (
        <FormControl labelPosition={labelPosition} className="InputText">
          {label && (
            <Label htmlFor={name} position={labelPosition}>
              {label as ReactElementLike}
            </Label>
          )}
          <Section flex={1} marginLeft={0.625} paddingTop={0.5} paddingBottom={0.5}>
            <Text color="blue" bold size={12} onClick={(): void => setIsCollapsed(false)}>
              {collapsedText}
            </Text>
          </Section>
        </FormControl>
      ) : (
        <InputText name={name} label={label} />
      )}
    </>
  );
};

export default connectFormElement(CollapsibleInputText);
