import { useCallback, useState, useEffect, useRef } from 'react';
import { bool, func } from 'prop-types';

import WizardFloatingButton from './WizardFloatingButton';
import WizardModal from './WizardModal';

import { WizardAssistantWrapper } from './WizardAssistant.theme';

const WizardAssistant = ({
  staff_members_incomplete,
  timetables_incomplete,
  treatments_incomplete,
  onTimeTablesSet,
  onTreatmentsSet,
  onStaffSet,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const wizardDOMRef = useRef(null);

  const getIncompleteCount = useCallback(
    () => Number(staff_members_incomplete) + Number(timetables_incomplete) + Number(treatments_incomplete),
    [staff_members_incomplete, timetables_incomplete, treatments_incomplete]
  );

  const onClickOutside = useCallback(
    (event) => {
      event.stopPropagation();
      event.stopImmediatePropagation();

      if (!wizardDOMRef.current.contains(event.target) && isModalOpened) {
        setIsModalOpened(false);
      }
    },
    [isModalOpened]
  );

  const toggleModal = useCallback(() => {
    setIsModalOpened(!isModalOpened);
  }, [isModalOpened]);

  const handleButtonClick = (eventName) => {
    setIsModalOpened(false);

    switch (eventName) {
      case 'treatments':
        onTreatmentsSet();
        break;
      case 'staff':
        onStaffSet();
        break;
      case 'timetables':
        onTimeTablesSet();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  });

  return (
    <WizardAssistantWrapper ref={wizardDOMRef}>
      {isModalOpened && (
        <WizardModal
          treatments_incomplete={treatments_incomplete}
          timetables_incomplete={timetables_incomplete}
          staff_members_incomplete={staff_members_incomplete}
          onStaffSet={() => handleButtonClick('staff')}
          onTimeTablesSet={() => handleButtonClick('timetables')}
          onTreatmentsSet={() => handleButtonClick('treatments')}
        />
      )}
      <WizardFloatingButton onClick={toggleModal} notificationsCount={getIncompleteCount()} />
    </WizardAssistantWrapper>
  );
};

WizardAssistant.displayName = 'WizardAssistant';

WizardAssistant.propTypes = {
  staff_members_incomplete: bool,
  timetables_incomplete: bool,
  treatments_incomplete: bool,
  onTimeTablesSet: func,
  onTreatmentsSet: func,
  onStaffSet: func,
};

WizardAssistant.defaultProps = {
  staff_members_incomplete: false,
  timetables_incomplete: false,
  treatments_incomplete: false,
  onTimeTablesSet: () => {},
  onTreatmentsSet: () => {},
  onStaffSet: () => {},
};

export default WizardAssistant;
