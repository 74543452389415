import { Icon, Box } from 'components/core';
import { Button } from 'ui-treatwell-pro/button';

import * as S from './Counter.theme';

export const Counter: (p: {
  value: number;
  onChange: (x: number) => void;
  maxValue: number;
  minValue?: number;
  width?: string;
  isDisabled?: boolean;
}) => JSX.Element = ({ value, onChange, maxValue, width = '150px', minValue = 0, isDisabled = false }) => {
  const handleChange = (x: number): void => {
    if (x > maxValue || x < minValue) return;
    onChange(x);
  };

  const isDecrementDisabled = isDisabled || value <= minValue;
  const isIncrementDisabled = isDisabled || value >= maxValue;

  return (
    <S.CounterWrapper
      data-testid="counter"
      overMaxDischargeableValue={Boolean(value > maxValue)}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width={width}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Button
          baseColor="blue"
          kind="icon"
          variant="tertiary"
          dataTestId="counter-decrement"
          onClick={(): void => handleChange(value - 1)}
          disabled={isDecrementDisabled}
        >
          <Icon type="decrement" color={isDisabled ? 'navy.500' : ''} />
        </Button>
        <Box fontSize="lg" width="80px" textAlign="center" alignItems="center" display="flex" justifyContent="center">
          <input
            data-testid="counter-input"
            type="number"
            value={value}
            onChange={(e): void => handleChange(e?.target?.valueAsNumber)}
            readOnly={isDisabled}
          />
        </Box>
        <Button
          baseColor="blue"
          kind="icon"
          dataTestId="counter-increment"
          variant="tertiary"
          onClick={(): void => handleChange(value + 1)}
          disabled={isIncrementDisabled}
        >
          <Icon type="increment" color={isDisabled ? 'navy.500' : ''} />
        </Button>
      </Box>
    </S.CounterWrapper>
  );
};
