/**
 * @example
 * ```
 * expect(toStringIfIsNumber(1)).toStrictEqual('1');
 * expect(toStringIfIsNumber('1')).toStrictEqual('1');
 * expect(toStringIfIsNumber([1, 2])).toStrictEqual([1, 2]);
 * ```
 *
 * @description
 * return a string only if the input is a number otherwise return the input
 */

export const toStringIfIsNumber = <A>(maybeNumber: number | A): string | A =>
  isNaN(maybeNumber as number) ? (maybeNumber as unknown as string) : String(maybeNumber);
