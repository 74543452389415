import { createSelector } from 'reselect';
import { State } from 'reducers/types';
import { Session } from 'reducers/sessions';
import { SessionVenue } from 'models';

export const getUserVenues = (state: State): SessionVenue[] | null | undefined =>
  state.sessions && state.sessions.sessionVenues;

export const getUserVenuesIds = createSelector(getUserVenues, (venues) => venues?.map((venue) => venue.id));

export const isUserMultiVenue = createSelector(getUserVenues, (venues: SessionVenue[] | null | undefined): boolean =>
  venues && venues.length > 1 ? true : false
);

export const getUserActiveVenuesIDs = (state: State): Session['activeVenuesIDs'] =>
  state.sessions && state.sessions.activeVenuesIDs;

export const getMultiVenueSidebarOpen = (state: State): boolean => state.multiVenueSidebarSettings.isOpen;

export const getMultiVenueSidebarDisabled = (state: State): boolean =>
  state.multiVenueSidebarSettings.isSwitchingVenueDisabled > 0 ? true : false;

export const getMultiVenueSidebarIsHidden = (state: State): boolean =>
  state.multiVenueSidebarSettings.isHidden > 0 ? true : false;
