/* eslint-disable no-restricted-imports */
import { V } from 'API';
import { ENDPOINT } from 'API/ENDPOINT';
import { Http } from 'API/Http';
import { maybeResponseToJSON } from 'API/http.utils';
import {
  AppointmentCancellationProtectionAvailabilityQuery,
  AppointmentCancellationProtectionAvailabilityResponse,
  AppointmentCancellationProtectionAvailabilitySearchParams,
} from 'API/types';

export const CANCELLATION_PROTECTION_AVAILABILITY =
  ({ variables: { appointment_id, customer_id } }: V<AppointmentCancellationProtectionAvailabilityQuery>) =>
  (): Promise<AppointmentCancellationProtectionAvailabilityResponse> =>
    Http()
      .get<
        AppointmentCancellationProtectionAvailabilityResponse,
        AppointmentCancellationProtectionAvailabilitySearchParams
      >(ENDPOINT.APPOINTMENTS._CANCELLATION_PROTECTION_AVAILABILITY(appointment_id))({
        searchParams: { customer_id, next: true }, // TODO: next parameter enable the next version of the API, we should remove when all the clients are updated to the latest version
      })
      .then(maybeResponseToJSON);
